import { CapacityStatisticsService } from "@core/services/admin/capacityAndBumps/capacityStatistics/CapacityStatisticsService";
import { ActionTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { CapacityStatisticsState } from "@core/store/types/admin/capacityAndBumps/capacityStatistics";
import {
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_SORTINGS,
  SET_CAPACITY_STATISTIC
} from "@core/store/mutation-constants";
import { GET_CAPACITY_STATISTIC } from "@core/store/action-constants";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";
import { StatisticGroupsEnum } from "@core/store/types/admin/capacityAndBumps/emuns/statisticGroupsEnum";

class CapacityStatistics implements Module<CapacityStatisticsState, RootState> {
  public namespaced: boolean;
  public modules?: ModuleTree<RootState>;
  public state: CapacityStatisticsState;
  
  public mutations: MutationTree<CapacityStatisticsState> = {
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
    
    [UPDATE_SORTINGS] (state, { sortField, direction }) {
      state.sort = sortField;
      state.order = direction.toUpperCase();
    },
  
    [SET_CAPACITY_STATISTIC] (state, payload) {
      state.data = { ...state.data, ...payload };
    }
  }
  
  public actions: ActionTree<CapacityStatisticsState, RootState> = {
    async [GET_CAPACITY_STATISTIC] ({ state, commit }) {
      const { datepicker, groups, ...filters } = state.filters;
      const { limit, offset } = pagination();

      const capacityStatistics = await CapacityStatisticsService.capacityStatistics(
        limit,
        offset,
        state.sort,
        state.order,
        { ...filters, ...datepicker },
        groups?.filter(Boolean)
      );
      
      commit(SET_CAPACITY_STATISTIC, capacityStatistics);
      return capacityStatistics;
    },
    
    [UPDATE_FILTERS] ({ commit }, payload) {
      commit(UPDATE_FILTERS, payload);
    },
    
    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
    },
  
    [SET_EMPTY] ({ commit }, target?: string) {
      commit(SET_EMPTY, target);
    }
  }
  
  private readonly initState: () => CapacityStatisticsState;
  
  constructor (modules?: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): CapacityStatisticsState => {
      return {
        data: null,
        filters: {
          groups: [StatisticGroupsEnum.MANAGER, null],
          datepicker: datepicker({ amount: 1, unit: "weeks" }),
          advertiserId: null,
          managerId: null,
          offerId: null,
          webmasterId: null,
          countryId: null
        },
        sort: null,
        order: null
      };
    };
  
    this.mutations[SET_EMPTY] = (state, target?: keyof CapacityStatisticsState) => {
      if (target) {
        state[target] = this.initState()[target] as keyof Nullable<CapacityStatisticsState>;
      } else {
        Object.assign(state, this.initState());
      }
    };
    
    this.state = this.initState();
    this.modules = modules;
    
    return this;
  }
}

export const statistics = new CapacityStatistics();