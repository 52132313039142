






























import ColoredStatus from "@/components/Common/ColoredStatus.vue";
import { withDefaults, defineProps } from "@vue/runtime-dom";
interface Props {
  tooltip: string;
  type: string;
  iconType: string | null;
  tooltipPosition: string;
  maxWidth: number | string;
  flipBehavior: "flip" | string | string[];
  boundary: "scrollParent" | "window" | "viewport" | HTMLElement;
  icon: string;
  pack: string;
  hide: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  tooltip: {
    key: "tooltip",
    required: true,
    type: String
  },
  type: {
    key: "type",
    required: false,
    type: String,
    default: "is-info"
  },
  iconType: {
    key: "iconType",
    required: false,
    type: [String, null],
    default: null
  },
  tooltipPosition: {
    key: "tooltipPosition",
    required: false,
    type: String,
    default: "is-top"
  },
  maxWidth: {
    key: "maxWidth",
    required: false,
    type: [Number, String],
    default: 350
  },
  flipBehavior: {
    key: "flipBehavior",
    required: false,
    type: [String, Array],
    default: "flip"
  },
  boundary: {
    key: "boundary",
    required: false,
    type: [String, null],
    default: "window"
  },
  icon: {
    key: "icon",
    required: false,
    type: String,
    default: "question-circle"
  },
  pack: {
    key: "pack",
    required: false,
    type: String,
    default: "far"
  },
  hide: {
    key: "hide",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  ColoredStatus
}, __sfc_main.components);
export default __sfc_main;
