<template>
    <b-dropdown
        class="is-clickable"
        append-to-body
        aria-role="menu"
        @active-change="active = $event">
        <template #trigger>
            <slot
                name="trigger"
                :active="active"></slot>
        </template>
        <b-dropdown-item
            :key="index"
            v-for="(item, index) in limit"
            aria-role="listitem"
            class="limitList"
            @click="$emit('input', item)">
            {{ item }}
            <small v-if="description">
                {{ $t(description) }}
            </small>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
  
  export default {
    name: "LimitPageSelect",
    props: {
      value: {
        type: null,
        default: null
      },

      limit: {
        type: Array,
        default: () => [25, 50, 100, 200, 500]
      },

      description: {
        type: String,
        default: "common.table.views.showMore.limit"
      }
    },
    data () {
      return {
        active: false
      };
    }
  };
</script>

<style scoped>

</style>