import { defineStore } from "pinia";
import { computed, reactive, ref, toRefs, watch } from "@vue/composition-api";
import {
  TrafficRequestContextItems,
  TrafficRequestCreateModalInput,
  TrafficRequestHistoryFilters,
  TrafficRequestHistoryItems
} from "@core/store/types/admin/traffic/trafficRequest";
import { TrafficService } from "@core/services/admin/traffic/TrafficService";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { TrafficRequestInputTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestInputTypeEnum";
import { momentInst } from "@core/filters";

export const useTrafficRequestCreateModal = defineStore("admin/efficiency/trafficRequestModal", () => {
  const isActive = ref(false);
  const input = reactive<TrafficRequestCreateModalInput>({
    type: TrafficRequestTypeEnum.RECOMMENDATIONS,
    offerId: null,
    webmasterId: null,
    externalWebmaster: undefined,
    recommendation: {
      audience: null,
      landingLink: null,
      trafficSource: null,
      datepicker: {
        periodDateStart: null,
        periodDateEnd: null
      },
      comment: null
    },
    newTerms: {
      newCapacity: null,
      newPayout: null,
      datepicker: {
        periodDateStart: null,
        periodDateEnd: null
      },
      countHoursToApply: 48,
      comment: null
    },
    growthTerms: {
      newCapacity: null,
      newPayout: null,
      comment: null
    }
  });
  const history = ref<TrafficRequestHistoryItems >({});
  const pagination = reactive({
    page: 1,
    perPage: 10
  });

  const context = ref<TrafficRequestContextItems>({});

  const confirmNow = ref(false);
  const idRequest = ref("");
  const isEdit = ref(false);
  const errorPayout = ref(null);

  const hasShowHistory = computed(() => input.offerId && input.webmasterId);
  const isCurrentDate = computed(() => {
    const format = "YYYY-MM-DDZ";
    const store = useTrafficRequest();
    return momentInst(store.filters.datepicker!.dateEnd).format(format) === momentInst().format(format);
  });
  const hasPayoutLow = computed(() => errorPayout.value === "REWARD_IS_TOO_LOW");

  async function createTrafficRequest () {
    try {
      const { type, offerId, webmasterId, externalWebmaster, ...request } = input as NonNullableRecord<TrafficRequestCreateModalInput & {
        [key: string]: any
      }>;
      const key = TrafficRequestInputTypeEnum[type];
      const {
        datepicker = {},
        countHoursToApply,
        ...filters
      } = request[key] as TrafficRequestCreateModalInput["recommendation"] & TrafficRequestCreateModalInput["newTerms"];
      const otherFilters = { ...filters, ...datepicker, countHoursToApply: confirmNow.value ? 0 : countHoursToApply };

      const item = await TrafficService.createTrafficRequest({ type, offerId, webmasterId, externalWebmaster, [key]: otherFilters });
      const store = useTrafficRequest();

      if (store.type !== input.type || !isCurrentDate.value) return;
      store.updater.LOCAL_ADD({ items: [item] });
    } catch (e: any) {
      errorPayout.value = e.errors[0]?.message;
      throw e;
    }
  }

  async function editTrafficRequest () {
    try {
      const { externalWebmaster } = input;
      const key = TrafficRequestInputTypeEnum[input.type!];
      const {
        datepicker = {},
        countHoursToApply,
        ...filters
      } = input[key] as TrafficRequestCreateModalInput["recommendation"] & TrafficRequestCreateModalInput["newTerms"];
      const otherFilters = { ...filters, ...datepicker, externalWebmaster, countHoursToApply: confirmNow.value ? 0 : countHoursToApply };

      const item = await TrafficService.editTrafficRequest(idRequest.value, { [key]: otherFilters });
      const store = useTrafficRequest();

      if (store.type !== input.type) return;
      store.updater.LOCAL_UPDATE({ items: [item] });
    } catch (e: any) {
      errorPayout.value = e.errors[0]?.message;
      throw e;
    }
  }

  async function getHistoryRequests () {
    pagination.page = 1;

    const limit = pagination.perPage || 10;
    const offset = ((pagination.page || 1) - 1) * limit;
    const { offerId, webmasterId } = input as TrafficRequestHistoryFilters;

    history.value = await TrafficService.getHistoryRequests({ offerId, webmasterId }, limit, offset);
  }
  
  async function updateHistoryRequests () {
    pagination.page += 1;

    const limit = pagination.perPage || 10;
    const offset = ((pagination.page || 1) - 1) * limit;
    const { offerId, webmasterId } = input as TrafficRequestHistoryFilters;

    const data = await TrafficService.getHistoryRequests({ offerId, webmasterId }, limit, offset);

    history.value = { count: data.count, items: history.value.items?.concat(data.items!) };
  }

  async function getContextRequests () {
    context.value = await TrafficService.getContextRequests(input.offerId!, input.webmasterId!);
  }

  function clearDate () {
    input.recommendation.datepicker.periodDateStart = null;
    input.recommendation.datepicker.periodDateEnd = null;
    input.newTerms.datepicker.periodDateStart = null;
    input.newTerms.datepicker.periodDateEnd = null;
  }

  function showAnalyseModal () {
    const store = useTrafficRequestAnalyseModal();
    store.isActive = true;
    store.webmasterId = input.webmasterId!;
    store.offerId= input.offerId!;
  }

  function resetInput () {
    input.recommendation = {
      audience: null,
      landingLink: null,
      trafficSource: null,
      datepicker: {
        periodDateStart: null,
        periodDateEnd: null
      },
      comment: null
    };
    input.newTerms = {
      newCapacity: null,
      newPayout: null,
      datepicker: {
        periodDateStart: null,
        periodDateEnd: null
      },
      countHoursToApply: 48,
      comment: null
    };
    input.growthTerms = {
      newCapacity: null,
      newPayout: null,
      comment: null
    };
  }

  function $reset () {
    isActive.value = false;
    input.type = TrafficRequestTypeEnum.RECOMMENDATIONS;
    input.offerId = null;
    input.webmasterId = null;
    input.externalWebmaster = undefined;
    history.value = {};
    context.value = {};
    confirmNow.value = false;
    isEdit.value = false;
    idRequest.value = "";
    errorPayout.value = null;
    resetInput();
  }

  watch(() => input.type, () => !isEdit.value && resetInput());

  return {
    isActive,
    ...toRefs(input),
    history,
    context,
    hasShowHistory,
    confirmNow,
    idRequest,
    isEdit,
    errorPayout,
    hasPayoutLow,
    createTrafficRequest,
    getHistoryRequests,
    updateHistoryRequests,
    getContextRequests,
    clearDate,
    showAnalyseModal,
    editTrafficRequest,
    $reset,
    CREATE_TRAFFIC_REQUEST: "createTrafficRequest",
    EDIT_TRAFFIC_REQUEST: "editTrafficRequest",
    GET_HISTORY_REQUEST: "getHistoryRequests",
    GET_CONTEXT_REQUEST: "getContextRequests",
    UPDATE_HISTORY_REQUEST: "updateHistoryRequests"
  };
});