import { GET_LANDING_PARAMETERS } from "@core/store/action-constants";
import { UPDATE_LANDING_PARAMETERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { LandingParametersListState } from "@core/store/types/admin/offer/lists/LandingParametersListState";
import { LandingParametersService } from "@core/services/admin/offer/lists/LandingParametersService";

const initialState = (): LandingParametersListState => {
  return {
    data: {}
  };
};

const state: () => LandingParametersListState = initialState;

const mutations: MutationTree<LandingParametersListState> = {
  [UPDATE_LANDING_PARAMETERS] (state, statuses: LandingParametersListState["data"]) {
    state.data = statuses;
  }
};

const actions: ActionTree<LandingParametersListState, RootState> = {
  async [GET_LANDING_PARAMETERS] ({ commit }) {
    const { data: { landingParameters } } = await LandingParametersService.getLandingParameters();
    const items = landingParameters.map((item: string) => {
      return {
        value: item,
        label: `admin.offer.form.common.landingParameters.${ item.toLowerCase() }`
      };
    });
    commit(UPDATE_LANDING_PARAMETERS, {
      items,
      count: items.length
    });
  }
};

export const landingParametersList: Module<LandingParametersListState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
