import { profile } from "@core/store/modules/advertiser/profile";
import { AdvertiserOffersModule } from "@core/store/modules/advertiser/offers";
import { AdvertiserOfferModule } from "@core/store/modules/advertiser/offer/offer";
import { incidence } from "@core/store/modules/advertiser/incidence/incidence";
import { dictionaries } from "@core/store/modules/advertiser/dictionaries";
import { finances } from "@core/store/modules/advertiser/finances";
import { Module } from "@core/store/logic/Module";
import { dashboard } from "@core/store/modules/advertiser/dashboard";

export class CoreAdvertiser<S, R> extends Module<S, R> {
  constructor () {
    super();
  
    this.namespaced = true;
    
    this.modules({
      offers: new AdvertiserOffersModule().toModule(),
      offer: new AdvertiserOfferModule().toModule(),
      profile,
      finances,
      incidence,
      dictionaries,
      dashboard
    });
  }
}