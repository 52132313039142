import { GET_LEAD_STATUSES } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_LEAD_STATUSES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { LeadStatusesListState } from "@core/store/types/admin/statistic/detailing/lists/leadStatusesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): LeadStatusesListState => {
  return {
    data: {}
  };
};

const state: () => LeadStatusesListState = initialState;

const getters: GetterTree<LeadStatusesListState, RootState> = {};

const mutations: MutationTree<LeadStatusesListState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_LEAD_STATUSES_LIST] (state, LeadStatuses: LeadStatusesListState["data"]) {
    state.data = LeadStatuses;
  }
};

const actions: ActionTree<LeadStatusesListState, RootState> = {
  [GET_LEAD_STATUSES] ({ commit }) {
    const items = [
      {
        label: "admin.statistics.statistics.filters.values.isSend.true",
        value: true
      },
      {
        label: "admin.statistics.statistics.filters.values.isSend.false",
        value: false
      }
    ];
    commit(UPDATE_LEAD_STATUSES_LIST, {
      items,
      count: items.length
    });
  }
};

export const leadStatusesList: Module<LeadStatusesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
