// @vue/components
export const Modal = {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    
    isActive: {
      type: Boolean,
      default: false
    },
    
    onCancel: {
      type: Function,
      default: null
    }
    
  },
  
  computed: {
    isModalActive: {
      get () {
        return this.isActive;
      },
      set (value) {
        if (!this.onCancel) {
          this.$emit("update:isActive", value);
        }
      }
    }
  },
  
  methods: {
    closeModal () {
      if (this.$data && this.$options.data) {
        Object.assign(this.$data, this.$options.data.call(this));
      }
      
      this.isModalActive = false;
    },
    
    emitUpdate () {
      this.$emit("update");
    }
  },
  
  watch: {
    isActive (value) {
      value ? this.$emit("opened") : this.$emit("closed");
    }
  }
};
