import Service from "@core/services/common/Service";
import verticalsQuery from "./graphql/verticals.graphql";
import { User } from "@core/store/types/common/User";

export class VerticalsService extends Service {
  public static async getVerticals (): Promise<User["verticals"]> {
    try {
      const { data: { verticals } } = await this.api.get("", {
        params: {
          query: verticalsQuery
        }
      });
      return verticals;
    } catch (e) {
      throw e;
    }
  }
}
