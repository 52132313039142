import { WebAccrualService } from "@core/services/admin/finances/webAccrual/WebAccrualService";
import { GET_WEB_ACCRUAL } from "@core/store/action-constants";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import {
  SET_EMPTY,
  UPDATE_FILTERS,
  SET_WEB_ACCRUAL
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { State, StateFilters } from "@core/store/types/admin/finances/webAccrual";
import { ActionTree, Module, MutationTree } from "vuex";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { offersList } from "@core/store/modules/admin/lists/offersList";

const initialState = (): State => {
  return {
    data: null,
    filters: {
      datepicker: datepicker({ amount: 7, unit: "day" }),
      regions: null,
      lead: null,
      webmaster: null,
      advertiser: null,
      offer: null,
      admin: null,
      withdrawType: null,
      countries: null,
      currency: null
    }
  };
};

const state: () => State = initialState;

const mutations: MutationTree<State> = {
  [SET_WEB_ACCRUAL] (state, data: State["data"]) {
    state.data = data;
  },

  [UPDATE_FILTERS] (state, filters: StateFilters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [SET_EMPTY] (state, target?: keyof State): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<State, RootState> = {
  async [GET_WEB_ACCRUAL] ({ commit, state }) {
    const { datepicker, ...filters } = state.filters;
    const { limit, offset } = pagination();

    const { data: { webAccrual } } = await WebAccrualService.getWebAccrual(limit, offset, {
      ...datepicker,
      ...filters
    });
    commit(SET_WEB_ACCRUAL, webAccrual);
  },

  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }, target?: State) {
    commit("SET_EMPTY", target);
    commit("withdrawTypesList/SET_EMPTY");
  }
};

export const webAccrual: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    withdrawTypesList,
    adminsList,
    offersList
  }
};
