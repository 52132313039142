import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import editWebmaster from "./graphql/editWebmaster.graphql";
import getWebmaster from "./graphql/getWebmaster.graphql";
import changeWebmasterPassword from "./graphql/changeWebmasterPassword.graphql";
import {
  WebmasterResponse,
  EditInput,
  EditResponse,
  WebmastersPasswordEditInput,
  WebmastersPasswordEditResponse
} from "@core/store/types/admin/users/webmasters/Edit";

export class WebmastersEditService extends Service {
  public static getWebmaster (webmasterId: string): AxiosPromise<WebmasterResponse> {
    return this.api.get("", {
      params: {
        query: getWebmaster,
        variables: {
          webmasterId
        }
      }
    });
  }

  public static editWebmaster (webmasterId: string, input: EditInput): AxiosPromise<EditResponse> {
    return this.api.post("", {
      query: editWebmaster,
      variables: {
        webmasterId,
        input
      }
    });
  }

  public static editPassword (webmasterId: string, input: WebmastersPasswordEditInput): AxiosPromise<WebmastersPasswordEditResponse> {
    return this.api.post("", {
      query: changeWebmasterPassword,
      variables: {
        webmasterId,
        input
      }
    });
  }
}
