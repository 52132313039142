import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import {
  Auth,
  Registration,
  ResetPassword,
  SendNewPassword
} from "@core/store/types/common/auth";
import { RefreshToken, TokenResponse } from "@core/logic/user/service";

export class AuthService extends Service {
  public static registration (payload: Registration): Promise<any> {
    try {
      return this.rest.post("/signup", payload);
    } catch (e) {
      throw e;
    }
  }
  
  public static resetPassword (params: ResetPassword): Promise<boolean> {
    try {
      return this.rest.post("/identity/forgot-password", params);
    } catch (e) {
      throw e;
    }
  }
  
  public static sendNewPassword (params: SendNewPassword): AxiosPromise<boolean> {
    try {
      return this.rest.post("/identity/reset-password", params);
    } catch (e) {
      throw e;
    }
  }
  
  public static refreshToken (params: RefreshToken): AxiosPromise<TokenResponse> {
    try {
      return this.rest.get("/auth/token/refresh", {
        baseURL: process.env.VUE_APP_API_BASE_URL,
        params
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static whoAmI (data: { refreshToken: string, fingerPrint: string }): AxiosPromise<Auth> {
    try {
      return this.rest.post("/auth/token/refresh", data, {
        baseURL: process.env.VUE_APP_API_BASE_URL
      });
    } catch (e) {
      throw e;
    }
  }
}
