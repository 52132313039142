import { defineStore } from "pinia";
import { OfferDisclaimerModalState } from "@core/store/types/common/offer/OfferDisclaimerModalState";
import storeInstance from "@/store";
import { offerDisclaimerService } from "@core/services/webmaster/offer/offerDisclaimerService";

export const offerDisclaimerModal = defineStore("offerDisclaimerModal", {
  state: (): OfferDisclaimerModalState => ({
    isActive: false,
    isAcceptOfferDisclaimer: storeInstance.state.auth.isAcceptOfferDisclaimer
  }),

  actions: {
    async ACCEPT_OFFER_DISCLAIMER () {
      try {
        await offerDisclaimerService.acceptOfferDisclaimer();
        this.isAcceptOfferDisclaimer = true;
      } catch (e) {
        throw e;
      }
    }
  }
});