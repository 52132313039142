import { AxiosResponse } from "axios";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import { PaginationParams } from "@core/logic/common/pagination-params";
import Service from "@core/services/common/Service";

export interface AddCallCenter {
  name: string;
  advertiserId: string;
  timeZone: string;
  timeTables: Timetable;
}

interface Advertiser {
  id: string;
  name: string;
  login: string;
}
// export interface EditCallCenter {
//   id: string;
//   payment: AddCallCenter;
// }

export interface CallCenter {
  id: string;
  name: string;
  isDeleted: null;
  createdAt: string;
  advertiser: Advertiser;
}

export interface GetCallCentersParams extends PaginationParams {
  filters: {
    advertiser: string;
  };
}

interface Time {
  timeStart: string;
  timeFinish: string;
}

export interface Timetable {
  monday: Time[];
  tuesday: Time[];
  wednesday: Time[];
  thursday: Time[];
  friday: Time[];
  saturday: Time[];
  sunday: Time[];
}

export interface AddTimetable {
  timetable: Timetable;
  callCenterId: string;
}

export interface CallCenter extends PaginationOutput {
  items: CallCenter[];
}

export interface CallCenterId {
  callCenterId: string;
}

class CallCentersService extends Service{
  public static getCallCenters (params: GetCallCentersParams): Promise<AxiosResponse<CallCenter>> {
    return this.rest.get("/admin/callcenters", { params });
  }

  public static getTimetable (payload: CallCenterId): Promise<AxiosResponse<Timetable>> {
    return this.rest.get(`/callcenters/${ payload }/timetable`);
  }

  public static createCallCenter (payload: any): Promise<AxiosResponse<CallCenter>> {
    return this.rest.post("/admin/callcenters", payload);

  }

  public static deleteCallCenter (payload: CallCenterId): Promise<AxiosResponse<CallCenter>> {
    return this.rest.delete(`/admin/callcenters/${ payload }`);
  }

  public static addTimetable (payload: AddTimetable): Promise<AxiosResponse<Timetable>> {
    return this.rest.post(`/admin/callcenters/${ payload.callCenterId }/timetable`, { ...payload.timetable });
  }

  public static editTimetable (payload: any): Promise<AxiosResponse<Timetable>> {
    return this.rest.put(`/admin/callcenters/${ payload.callCenterId }/edit`, payload.callCenter);
  }

  // public static editCallCenter ({ id, payment }: EditCallCenter): Promise<AxiosResponse<CallCenter>> {
  //   return this.rest.put(`/admin/callcenters/${ id }`, payment);
  // }
}

export default CallCentersService;

