import { Offer } from "@core/logic/admin/offer/offer";
import { OfferModule } from "@core/store/modules/common/offer/OfferModule";
import { RootState } from "@core/store/root-state";
import { GET_OFFER, SET_EMPTY } from "@core/store/action-constants";
import { SET_OFFER } from "@core/store/mutation-constants";

export interface OfferState {
  offer: Nullable<Partial<Offer>>;
}

export class AdminOfferModule extends OfferModule<OfferState, RootState> {
  private readonly initState: () => OfferState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): OfferState => {
      return {
        offer: {
          landingParameters: ["name", "phone"],
          isTest: false,
          isResale: false,
          isApiAllowed: true,
          isExclusive: false
        }
      };
    };

    this.state(this.initState());

    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      }
    });

    this.actions({
      async [GET_OFFER] ({ commit, rootGetters }, { service, offerId, isClone, isShowCommissions }) {
        try {
          if (offerId) {
            const offer = await service.getOffer(
              offerId,
              isClone,
              isShowCommissions
            );
            commit(SET_OFFER, offer);
            return offer;
          }
        } catch (e) {
          throw e;
        }
      },

      SET_EMPTY: ({ commit }): void => {
        commit(SET_EMPTY);
        commit("offerConversionList/SET_EMPTY");
        commit("landings/SET_EMPTY");
        commit("transits/SET_EMPTY");
      }
    });

    return this;
  }
}

