import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function offerGenerator (offerId: string): ASTGeneratedOutput {
  const money = ["value", "currency"];
  let exclude = [] as string[];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    const items = [
      "minimalCommission/value",
      "minimalCommission/currency",
      "advertiserMinimalCommission/value",
      "advertiserMinimalCommission/currency",
      "finance/webmasterReward/value",
      "finance/webmasterReward/currency"
    ];
    exclude = items;
  }

  const params = { exclude };

  const gqlObject = {
    operation: "offer",

    fields: [
      "id",
      "intId",
      "slug",
      "labels",
      "isApiAllowed",
      "vertical",
      "createdAt",
      "isLaunched",
      "type",
      "isLaunched",
      "landingParameters",
      "photoUrl",
      "landingUrl",
      {
        detail: [
          "name",
          "description",
          "rules",
          "isInHouse"
        ]
      },
      {
        finance: [
          { webmasterReward: money },
          { minimalCommission: money },
          { holdTime: ["holdMinutes"] },
          "withdrawType",
          "subGoalType",
          "currency"
        ]
      },
      {
        landing: [
          "currency",
          "cost",
          "preCost",
          "trackingPageId"
        ]
      },
      { categories: ["id"] },
      { logo: ["id", "url"] },
      { previewLogo: ["id", "url"] },
      { countries: ["id"] },
      { trafficTypes: ["id", "name"] },
      { minimalCommission: money },
      { advertiserMinimalCommission: money }
    ],

    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      }
    }
  };

  return Generator.query(gqlObject, params);
}
