import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  ComplaintsModalInput,
  ComplaintsModalInputReason,
  ComplaintsModalState
} from "@core/store/types/admin/complaints/complaintsModal";
import { RootState } from "@core/store/root-state";
import { datepicker, datepickerFormat, uniqDatepicker } from "@core/helpers/datepicker";
import {
  CREATE_TRAFFIC_COMPLAINT,
  GET_ANALYTIC_REQUIRED_APPROVE
} from "@core/store/action-constants";
import { ComplaintsService } from "@core/services/admin/complaints/ComplaintsService";
import { SET_EMPTY, UPDATE_INPUT, UPDATE_MODAL } from "@core/store/mutation-constants";

const initialState = (): ComplaintsModalState => {
  return {
    modal: {
      isActive: false,
      positiveConversionApprove: null,
      negativeConversionApprove: null,
      requiredApprove: null
    },
    input: {
      offerId: null,
      webmasterId: null,
      reason: null,
      positiveDatepicker: {
        positiveStartDate: null,
        positiveEndDate: null
      },
      negativeDatepicker: uniqDatepicker(["negativeStartDate", "negativeEndDate"], { units: "day", amounts: 1 }),
      creatorComment: null
    }
  };
};

const state: () => ComplaintsModalState = initialState;

const getters: GetterTree<ComplaintsModalState, RootState> = {
  filledUsers: ({ input }) => !!input.offerId && !!input.webmasterId
};

const mutations: MutationTree<ComplaintsModalState> = {
  [SET_EMPTY]: (state) => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, payload) {
    state.modal = { ...state.modal, ...payload };
  },

  [UPDATE_INPUT] (state, payload) {
    state.input = { ...state.input, ...payload };
  }
};

const actions: ActionTree<ComplaintsModalState, RootState> = {
  async [CREATE_TRAFFIC_COMPLAINT] ({ state, commit, rootState }) {
    const { dateEnd } = datepickerFormat(rootState.filtersConfirm.filters.datepicker, "date");
    const { positiveDatepicker, negativeDatepicker, ...input } = state.input;
    const { data: { createTrafficComplaint: items } } = await ComplaintsService.createTrafficComplaint({
      ...input,
      ...positiveDatepicker,
      ...negativeDatepicker
    } as ComplaintsModalInputReason);

    if (dateEnd === datepicker(undefined, "date").dateEnd) {
      commit("admin/complaints/LOCAL_ADD", { items }, { root: true });
    }
  },

  [UPDATE_MODAL] ({ commit }, payload) {
    commit(UPDATE_MODAL, payload);
  },

  [UPDATE_INPUT] ({ commit }, payload: ComplaintsModalInput) {
    commit(UPDATE_INPUT, payload);
  },

  async [GET_ANALYTIC_REQUIRED_APPROVE] ({ state, commit }) {
    const { webmasterId, offerId, positiveDatepicker, negativeDatepicker } = state.input as ComplaintsModalInput;
    const { data: { trafficComplaintContext } } = await ComplaintsService.getRequiredApprove(
      negativeDatepicker!.negativeEndDate,
      negativeDatepicker!.negativeStartDate,
      offerId,
      positiveDatepicker!.positiveEndDate,
      positiveDatepicker!.positiveStartDate,
      webmasterId
    );

    commit(UPDATE_MODAL, trafficComplaintContext);
  }
};

export const complaintsModal: Module<ComplaintsModalState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};