import Service from "@core/services/common/Service";
import { ResponseDetail } from "@core/store/types/admin/statistic/detailing/detailingState";
import { AxiosResponse } from "axios";
import _chunk from "lodash/chunk";
import postbackRecovery from "../graphql/postbackRecovery.graphql";

export class DetailingRequestModalService extends Service {
  public static async postbackRecovery (
    leadsIds: string[]
  ): Promise<AxiosResponse<ResponseDetail>[]> {
    const chunkSize = 50;
    const chunkList = _chunk(leadsIds, chunkSize);
    const promiseList: Promise<AxiosResponse<ResponseDetail>>[] = [];
    try {
      for (const chunk of chunkList) {
        promiseList.push(this.api.post("", {
          query: postbackRecovery,
          variables: {
            input: { leadIds: [...chunk] }
          }
        }));
      }
      return await Promise.all(promiseList);
    } catch (e) {
      throw e;
    }
  }
}
