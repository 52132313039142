import moveHoldLeadToCancel from "@core/services/admin/statistic/detailing/graphql/moveHoldLeadToCancel.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class DetailingCancelLeadsModalService extends Service {
  public static cancelLeads (leadIds: Nullable<string[]>): AxiosPromise {
    return this.api.post("", {
      query: moveHoldLeadToCancel,
      variables: {
        leadIds
      }
    });
  }
}
