import { Filters } from "../../../../common/generators/filters";
import { OfferItem } from "@core/store/types/admin/statistic/common/offersFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class OfferFilters extends Filters<OfferItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data } = await StatisticsService.getOffersFilters(offset, limit, filters, initialFilters);
    
    return {
      data: {
        items: data.items.map((item: any) => ({
          id: item.id,
          intId: item.intId,
          name: item.name
        })),
        count: data.count
      },
      initialData: {
        items: [],
        count: 0
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const offerId = Array.isArray(ids) ? ids : [ids];
      return { offerId };
    }
  };
}

export const offersFilters = new OfferFilters();
