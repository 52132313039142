import Service from "@core/services/common/Service";
import { TransactionListResponse, PaginationTransactionsListState } from "@core/store/types/advertiser/finances/index";
import advertiserTransactions from "@core/services/advertiser/finances/graphql/advertiserTransactions.graphql";

export class FinancesService extends Service {
  public static async getTransactions (
    limit: number,
    offset: number,
    filters: PaginationTransactionsListState["filters"]
  ): Promise<TransactionListResponse> {
    try {
      const { data } = await this.api.get("", {
        params: {
          query: advertiserTransactions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}