export class News {
  public newsId = "";
  public createdAt = "";
  public title = "";
  public description = "";
  
  constructor (news: any) {
    this.newsId = news.id || null;
    this.createdAt = news.createdAt || null;
    this.title = news.title || null;
    this.description = news.text || null;
  }
}
