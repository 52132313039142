import type { RenderContext, VNode } from "vue";
import { FunctionalComponent } from "@vue/runtime-dom";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
import { checkProps } from "@/helpers/checkProps";

interface Props {
  labelGroup: string;
  centered: boolean;
  shadow: boolean;
  headerClass: string;
  cellClass: string;
  customClass: string;
  sortNamespace: string;
  iconPath: string;
  colspan: number;
}

const labelGroups = new Set();

const GroupColumns: FunctionalComponent<Props & RenderContext<Props>> = ({ children, props, parent, scopedSlots }: RenderContext<Props>) => {
  children = children.filter(el => el.data);
  labelGroups.add(props.labelGroup);

  const headerGroup = scopedSlots["header-group"];
  const headerGroupOption = scopedSlots["header-group-option"];
  const groupIndex = [...labelGroups].indexOf(props.labelGroup).toString();
  const groupClass = +groupIndex % 2 === 0 ? "is-even" : "is-odd";

  return children.map((vnode: VNode, idx) => {
    const { tooltip } = vnode.data!.attrs!;
    const subheadingClass = vnode.data!.attrs!["subheading-class"];
    const { field, headerClass, sortable, thAttrs, cellClass } = vnode.componentOptions!.propsData as { [key: string]: string | boolean };
    const isFirst = idx === 0;
    const isSortable = checkProps(sortable);
    const headerClasses = isFirst ? "is-header is-pointer-none borderless" : "is-hidden";
    // @ts-ignore
    const isHeaderSlot = vnode.fnOptions?.name === "TableColumn";
    const isGroup = isFirst && !(props.shadow ?? true);

    // @ts-ignore
    const customIcon = (vnode: VNode) => parent?.$refs.table?.$refs.lTable.currentSortColumn.field === vnode.componentOptions.propsData.field
    // @ts-ignore
      ? parent.$refs.table.$refs.lTable.isAsc ? "sort-up" : "sort-down"
      : "sort";

    function sorting (event: Event) {
      // @ts-ignore
      const column = parent.$refs.table.$refs.lTable.newColumns.find((c) => c.field === field);
      // @ts-ignore
      parent.$refs.table.$refs.lTable.sort(column, null, event);
    }

    if (!vnode.data?.scopedSlots?.subheading) {
      if (isSortable) {
        vnode.data!.scopedSlots!.subheading = isHeaderSlot ? isGroup ? (props) => [
          // @ts-ignore
          parent.$refs.table.$refs.lTable.checkable &&
            <b-checkbox
              style={"padding: 1rem; position: absolute; left: -66px; top: 50%; transform: translateY(-50%);"}
              // @ts-ignore
              { ...{ nativeOn: { change: parent.$refs.table.$refs.lTable.checkAll } } }>
            </b-checkbox>,
          // @ts-ignore
          vnode.componentOptions.propsData.sortable
            ? <div
              class={["th-wrap is-clickable nowrap", { "is-centered": checkProps(props.centered) }]}
              onClick={sorting}>
              <span>{ props.column.label }</span>
              <b-icon
                icon={customIcon(vnode)}
                size="is-small"
                class={"icon sort-icon is-relative is-inline ml-2 is-size-7"}
                both>
              </b-icon>
            </div>
            : props.column.label
        ] : vnode.data!.scopedSlots!.header : (props) =>
          <LTooltip
            // @ts-ignore
            type="is-dark"
            label={tooltip}
            active={checkProps(tooltip)}
            { ...{ nativeOn: { click: sorting } } }>
            <div class={["th-wrap is-centered is-pointer nowrap", subheadingClass]}>
              <span>{ props.column.label }</span>
              <b-icon
                icon={customIcon(vnode)}
                size="is-small"
                class={"icon sort-icon is-relative is-inline ml-2 is-size-7"}
                both>
              </b-icon>
            </div>
          </LTooltip>;
      } else {
        vnode.data!.scopedSlots!.subheading = vnode.data!.scopedSlots!.header ?? ((props) => props.column.label);
      }
    }
    vnode.key = idx + props.labelGroup;
    vnode.data!.attrs = { index: idx, sortNamespace: props.sortNamespace, iconPath: props.iconPath ?? "assets/common", shadow: props.shadow ?? true };
    // @ts-ignore
    vnode.data.scopedSlots.header = isFirst ? () => headerGroup
      ? <div
        class={props.customClass}
        onClick={(e: Event) => e.stopPropagation()}>
        { headerGroup(props) }
      </div>
      : headerGroupOption
        ? <div
          class={props.customClass}
          onClick={(e: Event) => e.stopPropagation()}>
          <div
            class="flex is-justify-content-space-between"
            style="width: 100%">
            <span>
              { props.labelGroup?.toUpperCase() }
            </span>
            { headerGroupOption(props) }
          </div>
        </div>
        : props.labelGroup?.toUpperCase() : () => ({});
    // @ts-ignore
    vnode.componentOptions.propsData.thAttrs = isFirst ? () => ({ colspan: children.length + (props.colspan ?? 0), ...thAttrs?.() }) : thAttrs;
    // @ts-ignore
    vnode.componentOptions.propsData.headerClass = [headerClass, headerClasses, props.headerClass, groupClass].filter(Boolean).join(" ");
    // @ts-ignore
    vnode.componentOptions.propsData.cellClass = [isFirst && (props.shadow ?? true) ? "is-first" : "", cellClass, props.cellClass].filter(Boolean).join(" ");
    // @ts-ignore
    vnode.componentOptions.propsData.centered = checkProps(props.centered);

    return vnode;
  }).flat(1);
};

export default GroupColumns;