import { ActionTree, Module, MutationTree } from "vuex";
import { DesignersListState } from "@core/store/types/admin/lists/designersListState";
import { RootState } from "@core/store/root-state";
import { GET_DESIGNERS } from "@core/store/action-constants";
import { SET_DESIGNERS } from "@core/store/mutation-constants";
import { DesignersListService } from "@core/services/admin/lists/DesignersListService";

class DesignersList implements Module<DesignersListState, RootState> {
  public namespaced: boolean;
  public state?: DesignersListState;
  
  public mutations: MutationTree<DesignersListState> = {
    [SET_DESIGNERS] (state, payload) {
      state.data = payload;
    }
  }
  
  public actions: ActionTree<DesignersListState, RootState> = {
    async [GET_DESIGNERS] ({ commit }) {
      const { data: { landingDesigners } } = await DesignersListService.getDesignersList();
      commit(SET_DESIGNERS, landingDesigners);
    }
  }
  
  private readonly initialState: () => DesignersListState;
  
  constructor () {
    this.namespaced = true;
    this.initialState = (): DesignersListState => {
      return {
        data: null
      };
    };
    this.state = this.initialState();
    
    this.mutations.SET_EMPTY = (state): DesignersListState => Object.assign(state, this.initialState());
    
    return this;
  }
}

export const designersList = new DesignersList();