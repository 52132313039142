import { Advertiser, AdvertisersEditState } from "@core/store/types/admin/users/advertisers/Edit";
import { defineStore } from "pinia";
import { EDIT_ADVERTISER } from "@core/store/action-constants";
import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { useAdvertisers } from "@/stores/admin/users/advertisers/advertisersStore";
import { SET_ADVERTISERS_EDIT_FORM, UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS } from "@core/store/mutation-constants";
import { Money } from "@core/store/types/common";
import { AdvertiserForm } from "@core/store/logic/admin/users/AdvertiserForm";

export const useAdvertisersEdit = defineStore("admin/users/advertisers/edit", {
  state: (): AdvertisersEditState => ({
    isActive: false,
    form: {}
  }),

  getters: {
    advertiserId: ({ form }) => form.advertiserId
  },

  actions: {
    async [EDIT_ADVERTISER] () {
      const { advertiserId, ...input } = this.form;
      const item = await AdvertisersEditService.editAdvertiser(advertiserId!, input);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    [UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS] (commission: Money) {
      this.form.minimalCommission = this.form.minimalCommission
        ?.map(item => item.currency === commission.currency ? commission : item);
    },

    [SET_ADVERTISERS_EDIT_FORM] (advertiser: Advertiser) {
      this.form = new AdvertiserForm(advertiser);
    }
  }
});