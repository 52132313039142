import Service from "@core/services/common/Service";

export class UserContextStorage extends Service {
  public static getItem (path: string) {
    return localStorage.getItem(path);
  }
  
  public static setItem (path: string, data: any) {
    localStorage.setItem(path, data);
  }

  public static removeItem (path: string) {
    localStorage.removeItem(path);
  }
}
