import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import editAdvertiserQuery from "./graphql/editAdvertiser.graphql";
import changePasswordAdvertiser from "./graphql/changePasswordAdvertiser.graphql";
import {
  EditInput,
  EditResponse,
  AdvertisersPasswordEditInput,
  AdvertisersPasswordEditResponse, EditInputForDTO
} from "@core/store/types/admin/users/advertisers/Edit";
import { AdvertisersEditInputDTO } from "../../../../../../stores/admin/users/advertisers/dto/AdvertisersEditInputDTO";

export class AdvertisersEditService extends Service {
  public static async editAdvertiser (advertiserId: string, input: EditInput): Promise<EditResponse> {
    try {
      const { data: { editAdvertiser } } = await this.api.post("", {
        query: editAdvertiserQuery,
        variables: {
          advertiserId,
          input: new AdvertisersEditInputDTO(input as EditInputForDTO)
        }
      });
      
      return editAdvertiser;
    } catch (e) {
      throw e;
    }
  }

  public static editPassword (advertiserId: string, input: AdvertisersPasswordEditInput): AxiosPromise<AdvertisersPasswordEditResponse> {
    try {
      return this.api.post("", {
        query: changePasswordAdvertiser,
        variables: {
          advertiserId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
