import { Module } from "@core/store/logic/Module";
import { RootState } from "@core/store/root-state";
import {
  UPDATE_EXPAND_FULLSCREEN,
  UPDATE_MODAL_ACTIVE
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_DEVISE_ORIENTATION
} from "@core/store/mutation-constants";

export interface ExpandFullscreenState {
  isActiveModal: boolean;
  deviseOrientation: string | null;
}

export class ExpandFullscreen extends Module<ExpandFullscreenState, RootState> {
  private readonly initState: () => ExpandFullscreenState;
  
  constructor () {
    super();
    
    this.namespaced = true;
  
    this.initState = (): ExpandFullscreenState => {
      return {
        isActiveModal: false,
        deviseOrientation: null
      };
    };
    
    this.state(this.initState());
    
    this.mutations({
      [SET_EMPTY]: (state): void => {
        Object.assign(state, this.initState());
      },
  
      [UPDATE_MODAL_ACTIVE]: (state): void => {
        state.isActiveModal = !state.isActiveModal;
      },
      
      [UPDATE_DEVISE_ORIENTATION]: (state, payload): void => {
        state.deviseOrientation = typeof payload === "string" ? payload : screen.orientation?.type;
      }
    });
    
    this.actions({
      [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
        if (payload) {
          commit(SET_EMPTY);
        }
        commit(UPDATE_MODAL_ACTIVE, payload);
      },
      
      [UPDATE_EXPAND_FULLSCREEN] ({ state, commit }) {
        if (state.isActiveModal) {
          commit(SET_EMPTY);
        } else {
          commit(UPDATE_MODAL_ACTIVE);
          commit(UPDATE_DEVISE_ORIENTATION);
        }
      }
    });
  
    return this;
  }
}