import { admin } from "@/router/admin";
import { advertiser } from "@/router/advertiser";
import auth from "@/router/common/auth";
import dashboard from "@/router/common/dashboard";
import mail from "@/router/common/mail";
import transaction from "@/router/common/transaction";
import { webmaster } from "@/router/webmaster";
import store from "../store";
import { ERROR, SUCCESS } from "@core/store/mutation-constants";
import Empty from "@/views/Empty.vue";

import URLNotFound from "@/views/URLNotFound.vue";
import Vue, { CreateElement, VNode } from "vue";
import Router, { Route, RouteConfig } from "vue-router";
import { NavigationGuard, Position, PositionResult } from "vue-router/types/router";

type NewRouteConfig = RouteConfig & {
  component: {
    render: (c: CreateElement) => VNode
  },
}

// eslint-disable-next-line default-param-last
function recursivePaths (routes: NewRouteConfig[] = [], name?: string): RouteConfig[] {
  for (let i = 0, l = routes.length; i < l; i++) {
    if (routes[i].component == null) {
      routes[i].component = { render: (c: CreateElement): VNode => c("router-view") };
    }
    
    if (name && routes[i].name) {
      routes[i].name = `${ name }:${ routes[i].name }`;
    }

    if (routes[i].children) {
      if (routes[i].name) {
        recursivePaths(routes[i].children as NewRouteConfig[], routes[i].name);
      } else {
        recursivePaths(routes[i].children as NewRouteConfig[], name);
      }
    }
  }
  
  return routes;
}

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "main",
    redirect: { name: "dashboard" }
  },
  dashboard,
  auth,
  mail,
  transaction,
  webmaster,
  advertiser,
  admin,
  {
    path: "",
    component: Empty,
    children: [
      {
        path: "*",
        name: "page-not-found",
        component: URLNotFound
      }
    ]
  }
];

Vue.use(Router);

const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void):
PositionResult | Promise<PositionResult> => {
  if (to.hash) {
    return {
      selector: to.hash,
      offset: {
        x: 0,
        y: 100
      }
    };
  } else {
    return savedPosition || {
      x: 0,
      y: 0
    };
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior,
  routes: recursivePaths(routes as NewRouteConfig[])
});

const guard: NavigationGuard = (to, from, next) => {
  const isUserLogin = store.getters.isUserLogin;
  const isTokenSet = store.getters.isTokenSet;
  
  const roles = ["webmaster", "advertiser", "admin"];
  const currentRole = store.state.auth.role?.toLowerCase();
  const { name, path } = to;

  if (path === "/telegram/connect") {
    return next({
      name: "dashboard"
    });
  }
  // Тут берём path, потому что для роутера этой страницы нет и to
  // вернёт name === 'page-not-found' в то время как path будет равен адресу,
  // на который мы изначально хотим попасть
  const currentUrlRole = path.slice(1).split("/")[0];
  const currentUrlIncludeRole = roles.includes(currentUrlRole);
  
  if (currentRole && currentRole !== currentUrlRole && currentUrlIncludeRole) {
    // аналогично с коментом выше, но тут нужно превратить новый адрес в шаблон имени роута,
    // т.к. path в роутере не содержит имя родителя, если он вложенный, в отличие от name
    const newRoutePath = path?.replace(currentUrlRole, currentRole);
    const resolvedRoute = router.resolve({ path: newRoutePath });
  
    if (resolvedRoute.resolved.matched.length) {
      return next({
        path: newRoutePath,
        params: to.params
      });
    } else {
      return next({
        name: "dashboard"
      });
    }
  }

  if (name && !name.startsWith("auth")) {
    if (!isUserLogin) {
      next({ name: "auth:signIn" });
    } else if (isTokenSet) {
      const { auth } = store.state;
      
      if (auth.isConfirmed === true && auth.isApproved === false) {
        if (name !== "auth:signUpProfile") {
          next({ name: "auth:signUpProfile" });
        }
      } else if (auth.isConfirmed === false && auth.isApproved === false) {
        if (name !== "mail:send") {
          next({ name: "mail:send" });
        }
      }
    }
  }

  if (!isUserLogin && !(to.name ? to.name.startsWith("auth") : false)) {
    return next({ name: "auth:signIn" });
  } else {
    store.commit(SUCCESS, null);
    store.commit(ERROR, null);
    return next();
  }
};

router.beforeEach((...args) => {
  if (args[0].path === "/telegram/connect") {
    sessionStorage.setItem("telegram_connect", JSON.stringify(args[0].query));
  }
  
  if (store.state.auth.role || args[0].name?.startsWith("auth")) {
    guard(...args);
  } else {
    // Ждём, пока загрузится роль
    // Попадём сюда при первой загрузке сайта, т.к. в этот момент "store.state.auth.role" пустой
    store.watch(
      (state) => state.auth.role,
      () => { guard(...args); }
    );
  }
});

router.onError(error => {
  if (/loading chunk \S* failed/i.test(error.message)) {
    store.commit("SET_IS_REQUEST_BLOCKED", true);
  }
});

export default router;
