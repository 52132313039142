import { AdvertisersService } from "@core/services/admin/users/advertisers/AdvertisersService";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import {
  ADD_ADVERTISER_PERSONAL_MANAGER,
  ADD_APPROVED_ADVERTISER,
  ADVERTISER_BALANCE,
  BLOCK_ADVERTISER,
  DELETE_ADVERTISER_PERSONAL_MANAGER,
  EDIT_ADVERTISER_ADMIN_COMMENT,
  GET_ADVERTISERS,
  GET_ADVERTISERS_WITH_BALANCE,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_ADVERTISER,
  UPDATE_ADVERTISERS_BALANCE
} from "@core/store/action-constants";
import { approvedList } from "@core/store/modules/admin/lists/ApprovedList";
import { blockedList } from "@core/store/modules/admin/lists/BlockedList";
import { emailConfirmedList } from "@core/store/modules/admin/lists/EmailConfirmedList";
import {
  EMPTY_ADVERTISER_EDITABLE_COMMENT,
  SET_ADVERTISER_BALANCE,
  SET_ADVERTISERS,
  SET_EMPTY,
  UPDATE_ADVERTISER_EDITABLE_COMMENT,
  UPDATE_ADVERTISERS_FILTERS,
  UPDATE_ADVERTISERS_SORTS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  AdvertisersApproveFields,
  AdvertisersItem,
  AdvertisersState
} from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { UserCommentInput } from "@core/store/types/admin/users/common";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { detail } from "./modules/detail";
import { edit } from "./modules/edit";
import _findIndex from "lodash/findIndex";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { pagination } from "@core/helpers/pagination";

const initialEditableComment = (): AdvertisersState["editableComment"] => ({
  userId: null,
  comment: null
});

const initialState = (): AdvertisersState => {
  return {
    data: null,
    filters: {
      isBlocked: false,
      id: null,
      isApproved: null,
      email: null,
      isEmailConfirmed: null,
      telegram: null,
      manager: null
    },
    sort: null,
    order: null,
    editableComment: initialEditableComment()
  };
};

const state: () => AdvertisersState = initialState;

const mutations: MutationTree<AdvertisersState> = {
  [SET_ADVERTISERS]: (state, advertisers: AdvertisersState["data"]): void => {
    state.data = advertisers;
  },

  [SET_ADVERTISER_BALANCE]: (state, { balance, advertiserId: id }): void => {
    if (state.data?.items) {
      const { items } = state.data;
      const advertiserIndex = _findIndex(items, { id });
      Vue.set(state.data.items, advertiserIndex, { ...items[advertiserIndex], balance });
    }
  },

  [UPDATE_ADVERTISERS_SORTS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [UPDATE_ADVERTISERS_FILTERS] (state, filter: AdvertisersState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_ADVERTISER_EDITABLE_COMMENT] (state, editableComment: AdvertisersState["editableComment"]): void {
    state.editableComment = { ...state.editableComment, ...editableComment };
  },
  
  [EMPTY_ADVERTISER_EDITABLE_COMMENT] (state): void {
    state.editableComment = initialEditableComment();
  },
  
  [SET_EMPTY] (state, target?: keyof AdvertisersState) {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<AdvertisersState, RootState> = {
  async [GET_ADVERTISERS] ({ state, commit }) {
    const { limit, offset } = pagination();

    try {
      const advertisers = await AdvertisersService.getAdvertisers(
        limit,
        offset,
        state.filters,
        state.sort,
        state.order
      );
  
      commit(SET_ADVERTISERS, advertisers);
    } catch (error) {
      throw error;
    }
  },
  
  async [GET_ADVERTISERS_WITH_BALANCE] ({ dispatch }) {
    await dispatch(GET_ADVERTISERS);
    // dispatch(UPDATE_ADVERTISERS_BALANCE);
  },
  
  [UPDATE_ADVERTISERS_BALANCE] ({
    state,
    dispatch
  }) {
    state.data?.items?.forEach((item: AdvertisersItem) => {
      dispatch(ADVERTISER_BALANCE, item.id);
    });
  },
  
  async [ADD_APPROVED_ADVERTISER] ({ commit }, { advertiserId, vertical }: AdvertisersApproveFields) {
    const approvedAdvertiser = await AdvertisersService.approveAdvertiser(advertiserId, vertical);
    commit("LOCAL_UPDATE", { items: approvedAdvertiser, target: "advertisers" });
  },

  async [ADD_ADVERTISER_PERSONAL_MANAGER] ({ commit }, { userId, adminIds }) {
    const bindAdvertiser = await AdvertisersService.bindAdvertiser(userId, { adminId: adminIds[0] });
    commit("LOCAL_UPDATE", { items: bindAdvertiser, target: "advertisers" });
  },

  async [DELETE_ADVERTISER_PERSONAL_MANAGER] ({ commit }, userId: string) {
    const bindAdvertiser = await AdvertisersService.bindAdvertiser(userId, { adminId: null });
    commit("LOCAL_UPDATE", { items: bindAdvertiser, target: "advertisers" });
  },

  async [BLOCK_ADVERTISER] ({ commit }, advertiserId: string) {
    const blockedAdvertiser = await AdvertisersService.blockAdvertiser(advertiserId);
    commit("LOCAL_UPDATE", { items: blockedAdvertiser, target: "advertisers" });
  },

  async [UNBLOCK_ADVERTISER] ({ commit }, advertiserId: string) {
    const unblockedAdvertiser = await AdvertisersService.unblockAdvertiser(advertiserId);
    commit("LOCAL_UPDATE", { items: unblockedAdvertiser, target: "advertisers" });
  },

  async [ADVERTISER_BALANCE] ({ commit }, advertiserId: string) {
    try {
      const balance = await UsersService.balance(advertiserId);
      commit("SET_ADVERTISER_BALANCE", { balance, advertiserId });
    } catch (error) {
      throw error;
    }
  },

  async [RESEND_EMAIL_BY_ADMIN] (_, userId: string) {
    await UsersService.resendEmailByAdmin(userId);
  },
 
  [UPDATE_ADVERTISERS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_ADVERTISERS_FILTERS, filter);
  },

  async [EDIT_ADVERTISER_ADMIN_COMMENT] ({ state, commit }) {
    if (state.editableComment) {
      const { editableComment: { userId, comment } } = state;
      const updatedComment = await UsersService.updateComment(userId as string, { comment } as UserCommentInput);
      commit("LOCAL_UPDATE", { items: updatedComment, target: "advertisers" });
    }
  },
  
  [UPDATE_ADVERTISER_EDITABLE_COMMENT] ({ commit }, editableComment) {
    commit(UPDATE_ADVERTISER_EDITABLE_COMMENT, editableComment);
  },
  
  [SET_EMPTY] ({ commit }, target?: string) {
    commit(SET_EMPTY, target);
  }
};

export const advertisers: Module<AdvertisersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    edit,
    detail,
    blockedList,
    approvedList,
    emailConfirmedList
  }
};
