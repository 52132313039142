import { OfferEditWebmastersServices } from "@core/services/webmaster/offer/OfferRatesWebmastersService";
import {
  ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  BEFORE_GET_OFFER_WEBMASTERS,
  DELETE_OFFER_WEBMASTERS,
  GET_OFFER_ID,
  GET_OFFER_WEBMASTERS,
  PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE,
  SET_EMPTY,
  UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE
} from "@core/store/action-constants";
import { offerEditWebmastersModal } from "@core/store/modules/webmaster/offer/offerRates/offerRatesWebmastersModal";
import {
  SET_OFFER_WEBMASTERS,
  UPDATE_LOADING,
  UPDATE_OFFER_FILTERS,
  UPDATE_OFFER_WEBMASTER_RATE,
  UPDATE_OFFER_WEBMASTERS,
  UPDATE_OFFER_WEBMASTERS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  IndividualRate,
  OfferWebmaster,
  RatesWebmastersFilters
} from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { OfferRatesWebmastersState } from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import WebmasterEditDataMapper from "@core/store/logic/webmaster/offer/WebmasterEditDataMapper";
import { capitalizeFirstLetter } from "@core/filters";
import { Pagination } from "@core/store/types/common";

const initialState = (): OfferRatesWebmastersState => {
  return {
    webmasters: {},
    filters: {
      webmasterId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => OfferRatesWebmastersState = initialState;

const mutations: MutationTree<OfferRatesWebmastersState> = {
  SET_EMPTY: (state: OfferRatesWebmastersState): OfferRatesWebmastersState => Object.assign(state, initialState()),
  
  [SET_OFFER_WEBMASTERS] (state: OfferRatesWebmastersState, webmasters: PaginationOutput<OfferWebmaster[]>): void {
    state.webmasters = { ...webmasters };
  },
  
  [UPDATE_OFFER_WEBMASTERS] (state: OfferRatesWebmastersState, webmasters: PaginationOutput<OfferWebmaster[]>): void {
    if (state.webmasters) {
      state.webmasters = {
        count: webmasters.count,
        items: state.webmasters?.items?.concat(webmasters.items as OfferWebmaster[])
      };
    }
  },
  
  [UPDATE_OFFER_WEBMASTER_RATE] (state: OfferRatesWebmastersState, { newRate, index }): void {
    if (state.webmasters?.items) {
      Vue.set(state.webmasters.items[index], "individualRate", newRate);
    }
  },
  
  [UPDATE_LOADING] (state: OfferRatesWebmastersState, { index, params, value }): void {
    if (state.webmasters?.items) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const prop = `is${ capitalizeFirstLetter(key) }Loading`;
          Vue.set(state.webmasters.items[index], prop, value);
        }
      }
    }
  },
  
  [UPDATE_OFFER_WEBMASTERS_PAGINATION] (state: OfferRatesWebmastersState, pagination: Pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_OFFER_FILTERS] (state: OfferRatesWebmastersState, payload: RatesWebmastersFilters): void {
    state.filters = { ...state.filters, ...payload };
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<OfferRatesWebmastersState, RootState> = {
  async [GET_OFFER_WEBMASTERS] ({ commit, rootGetters, state }): Promise<void> {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    const isAgent = rootGetters.isAgent;

    try {
      const { pagination } = state;
      if (offerId && isAgent) {
        const limit = pagination.perPage;
        const offset = (pagination.page - 1) * limit;
        const { data: { offer: { webmasters } } } = await OfferEditWebmastersServices.getOfferWebmasters(
          {},
          offerId,
          limit,
          offset
        );
        
        webmasters.items = WebmasterEditDataMapper(webmasters.items);
        
        if (offset === 0) {
          commit(SET_OFFER_WEBMASTERS, webmasters);
        } else {
          commit(UPDATE_OFFER_WEBMASTERS, webmasters);
        }
        return webmasters;
      }
    } catch (e) {
      throw e;
    }
  },
  
  [BEFORE_GET_OFFER_WEBMASTERS] ({ commit, dispatch, state }): void {
    const { page, perPage } = state.pagination;
    
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
      page: 1,
      perPage: perPage * page
    });
    
    dispatch(GET_OFFER_WEBMASTERS);
    
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
      page,
      perPage
    });
  },
  
  async [ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({ state, rootGetters, commit }, { index, params }): Promise<void> {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    commit(UPDATE_LOADING, {
      index,
      params,
      value: true
    });
    
    try {
      if (state.webmasters?.items && offerId) {
        const { webmaster: { id } } = state.webmasters.items[index];
        const prop = Object.keys(params)[0];
        const newRate: IndividualRate =
          Object.assign(
            { rate: null, id },
            { [prop]: isNaN(params[prop]) ? params[prop] : +params[prop] }
          );
        const { data: { addSubWebmasterParameters } } =
          await OfferEditWebmastersServices.addSubWebmasterParameters(offerId, [ newRate ]);
        
        const { individualRate } = addSubWebmasterParameters[0];
        
        commit(UPDATE_OFFER_WEBMASTER_RATE, {
          newRate: individualRate,
          index
        });
      }
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_LOADING, {
        index,
        params,
        value: false
      });
    }
  },
  
  async [UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({ state, rootGetters, commit }, { index, params }): Promise<void> {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    commit(UPDATE_LOADING, { index, params, value: true });
    
    try {
      if (state.webmasters?.items) {
        const { webmaster, individualRate } = state.webmasters.items[index];
        const { rate, id } = individualRate || {};
        const prop = Object.keys(params)[0];
        const propValue = !params[prop] || isNaN(params[prop]) ? params[prop] : +params[prop];
        
        const newRate: IndividualRate =
          Object.assign(
            { rate, id },
            {
              [prop]: prop !== "rate" && propValue === 0 ? null : propValue
            }
          );
        const {
          data: { editSubWebmasterParameter: { individualRate: rates } } } =
          await OfferEditWebmastersServices.editSubWebmasterParameter(offerId, newRate, webmaster);
        
        commit(UPDATE_OFFER_WEBMASTER_RATE, {
          newRate: rates && Object.keys(rates)?.length > 0 ? rates : null,
          index
        });
      }
    } catch (e) {
      throw e;
    } finally {
      commit(UPDATE_LOADING, {
        index,
        params,
        value: false
      });
    }
  },
  
  async [PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE] ({ state, dispatch }, { index, params }): Promise<void> {
    const prop = Object.keys(params)[0];
    
    params[prop] = params[prop] === "" ? null : params[prop];
    
    if (state.webmasters?.items) {
      const { individualRate } = state.webmasters.items[index];

      if (!individualRate) {
        await dispatch(ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE, { index, params });
      } else {
        await dispatch(UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE, { index, params });
      }
    }
  },
  
  async [DELETE_OFFER_WEBMASTERS] ({ commit, rootGetters }, payload): Promise<void> {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    
    try {
      if (offerId) {
        await OfferEditWebmastersServices.excludeSubWebmastersToOffer(offerId, payload);
        commit("LOCAL_DELETE", { items: { webmasterId: payload[0] }, target: "webmasters", param: "webmasterId" });
      }
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_OFFER_WEBMASTERS_PAGINATION] ({ commit }, pagination): void {
    commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, pagination);
  },
  
  [UPDATE_OFFER_FILTERS] ({ commit }, filter): void {
    commit(UPDATE_OFFER_FILTERS, filter);
  },

  [SET_EMPTY] ({ commit, dispatch }): void {
    commit(SET_EMPTY);
    dispatch(`offerEditWebmastersModal/${ SET_EMPTY }`);
  }
};

export const offerRatesWebmasters: Module<OfferRatesWebmastersState, RootState> = {
  namespaced: true,
  modules: {
    add: offerEditWebmastersModal
  },
  state,
  mutations,
  actions
};
