import { GET_SUBACCOUNTS } from "@core/store/action-constants";
import {
  SET_SUBACCOUNTS,
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_MODAL,
  UPDATE_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { SubAccountsState } from "@core/store/types/webmaster/subAccounts";
import { ActionTree, Module, MutationTree } from "vuex";
import { SubAccountsService } from "@core/services/webmaster/subAccounts/SubAccounts";
import { subAccountsModal } from "./subAccountsModal";

const initialState = (): SubAccountsState => {
  return {
    data: null,
    pagination: {
      perPage: 25,
      page: 1
    },
    filters: {}
  };
};

export const state: () => SubAccountsState = initialState;

const mutations: MutationTree<SubAccountsState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [SET_SUBACCOUNTS] (state, payload) {
    state.data = payload;
  },
  
  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<SubAccountsState, RootState> = {
  async [GET_SUBACCOUNTS] ({ state, commit }): Promise<void> {
    const { filters, pagination: { perPage: limit, page } } = state;
    const offset = (page - 1) * limit;
    const { data: { agentWebmasters } } = await SubAccountsService.getSubAccounts(limit, offset, filters);
    
    commit(SET_SUBACCOUNTS, agentWebmasters);
  },
  
  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },
  
  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
    commit(UPDATE_PAGINATION, { page: 1 });
  },
  
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const subAccounts: Module<SubAccountsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    subAccountsModal
  }
};
