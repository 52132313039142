import prolongLeadHold from "@core/services/admin/statistic/detailing/graphql/prolongLeadHold.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class DetailingExtendHoldTimeModalService extends Service {
  public static extendHoldTime (leadIds: Nullable<string[]>, prolongation: number): AxiosPromise {
    return this.api.post("", {
      query: prolongLeadHold,
      variables: {
        leadIds,
        prolongation
      }
    });
  }
}
