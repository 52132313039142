import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";

export default function StatisticGraphGenerator (
  filters: BaseFiltersInput,
  excludedFilters: BaseFiltersInput,
  groups: string[]
): ASTGeneratedOutput {
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push("items/moneyCommission");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "statistics",

    fields: [
      {
        items: [
          "countLeadsCreated",
          "countLeadsAccepted",
          "countLeadsSpammed",
          "countLeadsIntegrated",
          "conversionApprove",
          "moneyAdvertiserWrittenOff",
          "countExternalLeadsCreated",
          "moneyCommission",
          "hours",
          "day",
          "week",
          "month",
          "year"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      filters: {
        type: "StatisticsCriteriaFilters",
        value: filters
      },
      excludedFilters: {
        type: "StatisticsCriteriaFilters",
        value: excludedFilters
      },
      groups: {
        type: "[StatisticsGroupsEnum!]!",
        value: groups
      }
    }
  };

  return Generator.query(gqlObject, params);
}