export default [
  {
    path: "finances",
    name: "finances",
    redirect: { name: "advertiser:finances:refill" },
    component: (): Promise<any> => import(/* webpackChunkName: "advertiser:finances" */ "@/views/Advertiser/FinancesView.vue"),
    children: [
      {
        path: "refill",
        name: "refill",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "advertiser:finances:refill" */ "@/components/Advertiser/Finances/FinancesRefill.vue")
      },
      {
        path: "costs",
        name: "costs",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "advertiser:finances:costs" */ "@/components/Advertiser/Finances/FinancesCost.vue")
      }
    ]
  }
];
