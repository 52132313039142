import { Filters } from "../../common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { IntegrationCRMListService } from "@core/services/admin/lists/IntegrationCRMListService";

export class IntegrationCRMList extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { integrations } } =
                await IntegrationCRMListService.getIntegrationCRMList(offset, limit, filters, initialFilters);

    return {
      data: {
        items: integrations?.items,
        count: integrations?.count
      },
      initialData: {
        items: /*initialIntegrations?.items*/ [],
        count: /*initialIntegrations?.length*/ 0
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
