<template>
    <ul>
        <template v-if="items && items.length">
            <template v-if="compact && items.length > 1">
                <div class="is-flex">
                    <ul>
                        <li>
                            <div>
                                <!--suppress HtmlUnknownTag -->
                                <flag :iso="items[0].alpha2"></flag>
                                {{ items[0].name }}
                                <slot></slot>
                            </div>
                        </li>
                    </ul>
                    <LTooltip
                        :active="active"
                        :position="tooltipPosition"
                        :interactive="interactive"
                        type="is-white">
                        <b-tag class="has-text-weight-bold has-text-primary ml-1 is-clickable">
                            +{{ items.length - 1 }}
                        </b-tag>
                        <template #content>
                            <div
                                :style="{ 'max-height': `${ maxHeight }px` }"
                                class="tooltip-content-list">
                                <ul>
                                    <li
                                        :key="index"
                                        v-for="(country, index) in items"
                                        class="my-1">
                                        <div>
                                            <!--suppress HtmlUnknownTag -->
                                            <flag :iso="country.alpha2"></flag>
                                            {{ country.name }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </LTooltip>
                </div>
            </template>
            <template v-else>
                <li
                    :key="index"
                    v-for="(country, index) in items">
                    <div>
                        <!--suppress HtmlUnknownTag -->
                        <flag :iso="country.alpha2"></flag>
                        {{ country.name }}
                        <slot></slot>
                    </div>
                </li>
            </template>
        </template>
        <li v-else>
            {{ empty ? empty : $options.methods.formatEmptyString() }}
        </li>
    </ul>
</template>

<script>
  import { ListView } from "@core/mixins";
  
  export default {
    name: "Countries",
    mixins: [ListView],
    props: {
      compact: {
        type: Boolean,
        default: false
      },
      tooltipPosition: {
        type: String,
        default: "is-left"
      },
      maxHeight: {
        type: [String, Number],
        default: 200
      },
      active: {
        type: Boolean,
        default: true
      },
      interactive: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="scss" scoped>
    .tooltip-content-list {
        margin: -0.35rem -0.75rem;
        padding: 0.35rem 0.75rem;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;
    }

    ul {
        margin: 0 !important;
        list-style: none !important;

        .v-popover {
          display: flex;
        }

        li {
            padding: 0;

            div {
                display: flex;
                text-align: left;
                align-items: center;
                justify-content: left;

                .flag-icon {
                    flex: none;
                    margin-right: 0.4em;
                }
            }
        }
    }
</style>
