import webmasterFilters from "@core/services/admin/finances/referral/graphql/webmasterFilters.graphql";
import referralBonus from "@core/services/admin/finances/referral/graphql/referralBonus.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import {
  ReferralBonusFiltersService,
  ReferralBonusState, ReferralListFilters
} from "@core/store/types/admin/finances/referralBonus/referralBonus";

export class ReferralService extends Service {
  public static getReferralBonus (
    limit: number,
    offset: number,
    sort: ReferralBonusState["sort"],
    order: ReferralBonusState["order"],
    filters: ReferralBonusFiltersService
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: referralBonus,
          variables: {
            limit,
            offset,
            sort,
            order,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getWebmastersFilters (
    limit: number,
    offset: number,
    filters: ReferralListFilters
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: webmasterFilters,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
