import { serializeDate } from "@core/helpers/serializeDate";
// @ts-ignore
import Vue from "@/main";
import { BackendError } from "@core/services/errors/BackendError";
import { GET_TOKEN, USER_LOGOUT } from "@core/store/action-constants";
import { cancelSource } from "@core/services/common/cancelSource";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { FieldNode, OperationDefinitionNode,
  print as ASTPrint,
  parse as ASTParse, SelectionNode, SelectionSetNode, visit } from "graphql";
import Cookies from "js-cookie";

const JWT_TOKEN_ERRORS = ["JWT_TOKEN_IS_EXPIRED", "JWT_TOKEN_IS_INVALID", "JWT_TOKEN_NOT_PROVIDED"];
const REFRESH_TOKEN_ERRORS = ["REFRESH_TOKEN_IS_EXPIRED", "REFRESH_TOKEN_IS_INVALID"];

export const GraphQLServer = Axios.create({
  baseURL: process.env.VUE_APP_GRAPHQL_URL
});

GraphQLServer.defaults.baseURL = process.env.VUE_APP_GRAPHQL_URL;
GraphQLServer.defaults.headers.common["Accept-Language"] = localStorage.getItem("locale") || "en";

function replacer (key: string, value: unknown): undefined | unknown {
  if (value == null) {
    return undefined;
    // @ts-ignore
    // eslint-disable-next-line
  } else if (Object.prototype.toString.call(this[key]) === "[object Date]") {
    // @ts-ignore
    // eslint-disable-next-line
    return serializeDate(this[key]);
  }
  return value;
}

GraphQLServer.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.cancelToken = cancelSource.getToken();
    let variables;
    let variablesAsString;
    let query;
    let operationsNames: Array<string> | undefined;

    if (config.params) {
      variables = config.params?.variables;
      variablesAsString = JSON.stringify(variables, replacer);
      query = config.params.query;

      config.params.query = ASTPrint(query);
      config.params.variables = variables ? JSON.parse(variablesAsString) : variables;
    } else if (config.data) {
      variables = config.data?.variables;
      variablesAsString = JSON.stringify(variables, replacer);
      query = config.data.query;

      config.data.query = ASTPrint(query);
      config.data.variables = variables ? JSON.parse(variablesAsString) : variables;
    }

    visit(query, {
      OperationDefinition: {
        enter: (node: OperationDefinitionNode): void => {
          const selectionSet: SelectionSetNode = node.selectionSet;
          if (selectionSet) {
            operationsNames = selectionSet.selections
              // eslint-disable-next-line no-extra-parens
              .map((node: SelectionNode) => (node as FieldNode).name.value);
          }
        }
      }
    });

    const isOperationNameRefreshToken = operationsNames && operationsNames?.includes("refreshToken");

    if (
      !isOperationNameRefreshToken &&
      Vue.$store.state.auth.token?.access
    ) {
      config.headers.Authorization = `Bearer ${ Vue.$store.state.auth.token.access }`;
    }

    return config;
  },

  (error: AxiosError) => Promise.reject(error));

GraphQLServer.interceptors.response.use(
  (response: AxiosResponse) => {
    return {
      ...response,
      data: response.data.data || response.data.data === 0 ? response.data.data : response.data
    };
  },

  async (error: AxiosError) => {
    let query;
    const { response } = error;
    const refresh = Cookies.get("refresh_token");

    // Если ACCESS токен невалидный и есть REFRESH, перезапрашиваем ACCESS
    if (JWT_TOKEN_ERRORS.includes(response?.data.type) && refresh) {
      try {
        await Vue.$store.dispatch(GET_TOKEN);
        //FixMe: Возможно есть другой способ вызвать store, но пока не работает
        Vue.$pinia._s.get("auth/permissions").GET_USER_PERMISSIONS();

        const config = error.config;

        if (config.params) {
          query = config.params.query;

          config.params.query = ASTParse(query);
        } else if (config.data) {
          query = JSON.parse(config.data);
          config.data = {};

          config.data.query = ASTParse(query.query);
        }
        
        return new Promise((resolve, reject) =>
          GraphQLServer
            .request(config)
            .then((response: AxiosResponse) => resolve(response))
            .catch((error: AxiosError) => reject(error))
        );
      } catch (error: any) {
        throw new BackendError(error);
      }
      // Если REFRESH токен невалидный хоть в одной из ошибок, разлогиниваем пользователя
    } else if (
      response?.data.errors.some(
        ({ message }: { message: string }) => REFRESH_TOKEN_ERRORS.includes(message)
      )
    ) {
      await Vue.$store.dispatch(USER_LOGOUT);
    }

    if (error.message !== "cancelled") {
      throw new BackendError(error);
    } else {
      throw "cancelled";
    }
  }
);
