import Service from "@core/services/common/Service";
import { DetailingState, LeadsFilters, LeadsResponse } from "@core/store/types/webmaster/statistic/modules/detailng";
import { AxiosPromise } from "axios";
import leadStatuses from "./graphql/leadStatuses.graphql";
import leadsGenerator from "@core/services/webmaster/statistic/detailing/graphql/generatorDetailing.generator";

export class DetailingService extends Service {
  public static getLeads (
    filters: LeadsFilters,
    limit: number,
    offset: number,
    sort: DetailingState["sort"],
    order: DetailingState["order"], isAgent: boolean
  ): AxiosPromise<LeadsResponse> {
    return this.api.get("", {
      params: leadsGenerator(
        filters,
        limit,
        offset,
        sort,
        order,
        isAgent
      )
    });
  }
  
  public static getStatuses (): AxiosPromise {
    return this.api.get("", {
      params: {
        query: leadStatuses
      }
    });
  }
}
