import { BaseFilter, BaseFilterList } from "./BaseFilter";

export class OfferFilter extends BaseFilter {
  public name = "";
  public intId: number;
  
  constructor (offer: any) {
    super();
    
    this.id = offer.id;
    this.intId = offer.intId;
    this.name = offer.name;
  }
}

export interface OffersFilterList extends BaseFilterList {
  items: OfferFilter[];
}