import { defineStore } from "pinia";
import type { Centrifuge, Subscription } from "centrifuge";
import { computed, ref, watch, WatchStopHandle } from "@vue/composition-api";
import storeInstance from "@/store";
import notification from "@/assets/audio/Traffic/notification.mp3";
import { NotificationSocketDTO } from "@core/store/types/common/sockets";

export const useTrafficNotifications = (centrifuge: Centrifuge) => defineStore("trafficNotificationsSockets", () => {
  let unwatch: WatchStopHandle;

  const notificationAudio = new Audio(notification);
  const userId = computed(() => storeInstance.state.admin.profile.user?.intId);

  const channel = ref<Subscription | null>(null);
  const data = ref<NotificationSocketDTO[]>([]);

  function subscribe () {
    if (channel.value) {
      centrifuge.removeSubscription(channel.value as Subscription);
      channel.value = null;
    }

    const sub = centrifuge.newSubscription(`traffic-request:new-messages#${ userId.value }`);

    sub.on("subscribed", () => {
      channel.value = sub;
      unwatch = watch(() => userId.value, subscribe);
    });
    sub.on("unsubscribed", () => {
      $reset();
      unwatch?.();
      centrifuge.removeSubscription(sub);
    });
    sub.on("publication", async ({ data: publication }) => {
      data.value.unshift(publication);
      await notificationAudio.play();
    });

    sub.subscribe();
  }

  function unsubscribe () {
    if (!channel.value) return;
    channel.value.unsubscribe();
  }

  function $reset () {
    channel.value = null;
    data.value = [];
  }

  return {
    data,
    subscribe,
    unsubscribe,
    $reset
  };
})();