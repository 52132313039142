import Service from "@core/services/common/Service";
import {
  EfficiencyAdvertItem,
  EfficiencyDatepicker,
  EfficiencyItem
} from "@core/store/types/admin/efficiency/efficiency";
import efficiencyQuery from "@core/services/admin/efficiency/graphql/efficiency.graphql";
import advertiserEfficiencyQuery from "@core/services/admin/efficiency/graphql/advertiserEfficiencyDashboard.graphql";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";

export class EfficiencyService extends Service {
  public static async getEfficiency (
    currency: Nullable<CurrencyEnum>,
    filters: Partial<NullableRecord<EfficiencyDatepicker>>,
    sort: Nullable<string>,
    order: Nullable<string>
  ): Promise<PaginationOutput<EfficiencyItem[]>> {
    try {
      const { data: { efficiencyDashboard } } = await this.api.get("", {
        params: {
          query: efficiencyQuery,
          variables: {
            currency,
            filters,
            sort,
            order
          }
        }
      });
      return { items: efficiencyDashboard, count: efficiencyDashboard.length };
    } catch (e) {
      throw e;
    }
  }

  public static async getEfficiencyAdvert (
    currency: Nullable<CurrencyEnum>,
    filters: Partial<NullableRecord<EfficiencyDatepicker>>,
    sort: Nullable<string>,
    order: Nullable<string>
  ): Promise<PaginationOutput<EfficiencyAdvertItem[]>> {
    try {
      const { data: { advertiserEfficiencyDashboard } } = await this.api.get("", {
        params: {
          query: advertiserEfficiencyQuery,
          variables: {
            currency,
            filters,
            sort,
            order
          }
        }
      });
      return { items: advertiserEfficiencyDashboard, count: advertiserEfficiencyDashboard.length };
    } catch (e) {
      throw e;
    }
  }
}