import Service from "@core/services/common/Service";
import { AdvertisersRatesFilters, AdvertisersRatesState } from "@core/store/types/admin/users/advertisers/Detail";
import { ratesMapper } from "@core/services/admin/users/advertisers/ratesMapper";
import advertiserRatesSummaryGenerator
  from "@core/services/admin/users/advertisers/graphql/advertiserRatesSummary.generator";

export class AdvertisersDetailService extends Service {
  public static async getRates (advertiserId: string, filters: AdvertisersRatesFilters): Promise<AdvertisersRatesState["data"]> {
    try {
      const { data: { advertiserRatesSummary: { ratesSummary } } } = await this.api.get("", {
        params: advertiserRatesSummaryGenerator(advertiserId, filters)
      });
      return ratesMapper(ratesSummary);
    } catch (e) {
      throw e;
    }
  }
}
