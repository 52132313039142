

























import VNode from "@/components/Common/VNode.vue";
import { withDefaults, defineProps } from "@vue/runtime-dom";
import RequiredField from "@/components/Common/RequiredField.vue";
interface Props {
  label: string;
  expandedLeft: boolean;
  message: string | null;
  type: string | null;
  required: boolean;
  hideHelp: boolean;
  maxWidth: number;
}
const __sfc_main = {};
__sfc_main.props = {
  label: {
    key: "label",
    required: false,
    type: String,
    default: ""
  },
  expandedLeft: {
    key: "expandedLeft",
    required: false,
    type: Boolean,
    default: false
  },
  message: {
    key: "message",
    required: false,
    type: [String, null],
    default: null
  },
  type: {
    key: "type",
    required: false,
    type: [String, null],
    default: null
  },
  required: {
    key: "required",
    required: false,
    type: Boolean,
    default: false
  },
  hideHelp: {
    key: "hideHelp",
    required: false,
    type: Boolean,
    default: false
  },
  maxWidth: {
    key: "maxWidth",
    required: false,
    type: Number,
    default: 90
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  function calcExpanded(index: number) {
    return props.expandedLeft ? index !== 1 : index !== 0;
  }
  return {
    RequiredField,
    calcExpanded
  };
};
__sfc_main.components = Object.assign({
  VNode
}, __sfc_main.components);
export default __sfc_main;
