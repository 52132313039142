import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import deletePageQuery from "./graphql/deletePage.graphql";

export class OfferLandingsService extends Service {
  public static async removePage (pageId: string): Promise<AxiosPromise> {
    try {
      const { data: { deletePage } } = await this.api.post("", {
        query: deletePageQuery,
        variables: { pageId }
      });
      
      return deletePage;
    } catch (e) {
      throw e;
    }
  }
}
