import Vue from "../../../main";

export const pagination = (initialPerPage = 25, initialPage= 1): {limit: number, offset: number} => {
  const { page, perPage } = Vue.$route.query;
  if (!page) {
    Vue.$router.replace({
      // @ts-ignore
      query: { ...Vue.$route.query, page: initialPage }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch(_ => {});
  }
  if (!perPage) {
    Vue.$router.replace({
      // @ts-ignore
      query: { ...Vue.$route.query, perPage: initialPerPage }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch(_ => {});
  }
  const limit = Number(perPage ?? initialPerPage);
  const offset = (Number(page ?? initialPage) - 1) * limit;
  
  return {
    limit,
    offset
  };
};
