import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { BackendError } from "@core/services/errors/BackendError";

export class telegramService extends Service {
  public static async connect (params: any): Promise<AxiosPromise> {
    try {
      return await this.rest.post("/telegram/connect", { ...params });
    } catch (e: any) {
      throw new BackendError(e);
    }
  }
}