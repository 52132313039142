import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { ReferralService } from "@core/services/admin/finances/referral/referralService";
import { Admin } from "@core/store/types/admin/users/webmasters/WebmastersState";
import { Filters } from "@core/store/modules/common/generators/filters";

class ReferrerList extends Filters<Admin, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput) => {
    const { data: { webmasters } } = await ReferralService.getWebmastersFilters(limit, offset, { isReferrer: true, ...filters });
    return {
      data: {
        items: webmasters.items,
        count: webmasters?.count
      }
    };
  }
  
  getInitialFilters = (ids?: string): { referrer: string } | undefined => {
    if (ids) {
      const referrer = ids;
      return { referrer };
    }
  };
}

export const referrerList = new ReferrerList();
