import { INVITE_WEBMASTER } from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { SubAccountsModalState } from "@core/store/types/webmaster/subAccounts";
import { ActionTree, Module, MutationTree } from "vuex";
import { SubAccountsService } from "@core/services/webmaster/subAccounts/SubAccounts";

const initialState = (): SubAccountsModalState => {
  return {
    modal: {
      isActive: false,
      email: ""
    }
  };
};

export const state: () => SubAccountsModalState = initialState;

const mutations: MutationTree<SubAccountsModalState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },
  
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<SubAccountsModalState, RootState> = {
  async [INVITE_WEBMASTER] ({ state }) {
    const { modal: { email } } = state;
    await SubAccountsService.inviteWebmaster(email);
  },
  
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const subAccountsModal: Module<SubAccountsModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
