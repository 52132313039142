import { GET_STATUSES } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_STATUSES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { StatusesListState } from "@core/store/types/admin/lists/StatusesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): StatusesListState => {
  return {
    data: {}
  };
};

const state: () => StatusesListState = initialState;

const getters: GetterTree<StatusesListState, RootState> = {};

const mutations: MutationTree<StatusesListState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_STATUSES_LIST] (state, statuses: StatusesListState["data"]) {
    state.data = statuses;
  }
};

const actions: ActionTree<StatusesListState, RootState> = {
  [GET_STATUSES] ({ commit }) {
    const items = [
      {
        value: "PROCESSED",
        label: "admin.statistics.statistics.filters.values.status.processed"
      },
      {
        value: "CONFIRMED",
        label: "admin.statistics.statistics.filters.values.status.confirmed"
      },
      {
        value: "CANCELED",
        label: "admin.statistics.statistics.filters.values.status.cancelled"
      },
      {
        value: "TRASH",
        label: "admin.statistics.statistics.filters.values.status.trash"
      }
    ];
    commit(UPDATE_STATUSES_LIST, {
      items,
      count: items.length
    });
  }
};

export const detailingStatusesList: Module<StatusesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
