import { defineStore } from "pinia";
import { REQUEST_TO_CHANGE_MANAGER } from "@core/store/action-constants";
import { InputRequestManager, RequestModalManagerStore } from "@core/store/types/webmaster/profile";
import { ProfileService } from "@core/services/webmaster/ProfileService";

export const useToChangeManagerModal = defineStore("webmaster/changeManagerModal", {
  state: (): RequestModalManagerStore => ({
    isActive: false,
    input: {
      managerId: null,
      comment: null
    }
  }),

  actions: {
    async [REQUEST_TO_CHANGE_MANAGER] () {
      await ProfileService.requestToChangeManager(this.input as InputRequestManager);
    }
  }
});