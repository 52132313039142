import Service from "@core/services/common/Service";
import { UserCommentInput, UserCommentInputResponse } from "@core/store/types/admin/users/common";
import { AxiosPromise } from "axios";
import balanceQuery from "./graphql/balance.graphql";
import myBalance from "@core//services/webmaster/graphql/myBalance.graphql";
import resendEmailByAdmin from "./graphql/resendEmailByAdmin.graphql";
import updateCommentQuery from "./graphql/updateComment.graphql";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import { Balance } from "@core/store/types/admin/users/advertisers/AdvertisersState";

export class UsersService extends Service {
  /**
   * TODO: Добавить описание
   * @param {string} userId
   * @return {Promise<AxiosPromise>}
   */
  public static async resendEmailByAdmin (
    userId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: resendEmailByAdmin,
        variables: {
          userId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} userId
   * @param {UserCommentInput} input
   * @return {Promise<AxiosPromise<UserCommentInputResponse>>}
   */
  public static async updateComment (
    userId: string,
    input: UserCommentInput
  ): Promise<AxiosPromise<UserCommentInputResponse>> {
    try {
      const { data: { updateComment } } = await this.api.post("", {
        query: updateCommentQuery,
        variables: {
          userId,
          input
        }
      });
      
      return updateComment;
    } catch (e) {
      throw e;
    }
  }

  public static async balance (
    userId: string
  ) {
    try {
      const { data: { balanceList } } = await this.api.post("", {
        query: balanceQuery,
        variables: {
          userId
        }
      });

      return sortByPreferredCurrency(balanceList.map(({ balance }: Balance) => balance))
        ?.map((balance, idx) => ({ balance, updatedAt: balanceList[idx].updatedAt }));
    } catch (e) {
      throw e;
    }
  }
  
  public static async myBalance (): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: myBalance
      });
    } catch (e) {
      throw e;
    }
  }
}
