import { Advertiser, EditInput } from "@core/store/types/admin/users/advertisers/Edit";
import { Money } from "@core/store/types/common";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

export class AdvertiserForm implements EditInput {
  public sizeLeads: string;
  public login: string;
  public geo: string;
  public isFineDebt: boolean;
  public fineDebtPercent: number;
  public fineDebtDays: number;
  public timeZone: string;
  public telegram: string;
  public whatsApp: number;
  public viber: number;
  public skype: string;
  public email: string;
  public minimalCommission: Money[];
  public avatarId: string;
  public verticals: string[];
  public systemVertical: VerticalsEnum;
  public advertiserId: string;

  constructor (user: Advertiser) {
    this.advertiserId = user.id;
    this.sizeLeads = user.sizeLeads;
    this.login = user.login;
    this.geo = user.geo;
    this.isFineDebt = user.isFineDebt;
    this.fineDebtPercent = user.fineDebtPercent;
    this.fineDebtDays = user.fineDebtDays;
    this.telegram = user.contact.telegram;
    this.whatsApp = user.contact.whatsApp;
    this.viber = user.contact.viber;
    this.skype = user.contact.skype;
    this.email = user.contact?.email;
    this.timeZone = user.timeZone?.alias;
    this.avatarId = user.avatar?.id;
    this.minimalCommission = user.minimalCommission;
    this.verticals = user.verticals?.map(({ id }) => id);
    this.systemVertical = user.systemVertical;
  }
}
