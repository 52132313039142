import Service from "@core/services/common/Service";
import {
  OffersFilters,
  OffersResponseDataInterface,
  OffersState
} from "@core/store/types/webmaster/offers";
import offersQuery from "./graphql/offers.graphql";
import topOffers from "./graphql/dashboardTopOffers.graphql";
import { AxiosPromise } from "axios";
import { WebmasterOfferInterface } from "@core/store/types/webmaster/offer";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";

export class OffersService extends Service {
  public static async getOffers (
    filters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"]
  ): Promise<OffersResponseDataInterface> {
    try {
      const { data: { offers } } = await this.api.get("", {
        params: {
          query: offersQuery,
          variables: {
            filters,
            limit,
            offset,
            sort,
            order
          }
        }
      });
      
      return { ...offers, items: offers.items.map((item: WebmasterOfferInterface) => new WebmasterOffer(item)) };
    } catch (e) {
      throw e;
    }
  }

  public static async getTopOffers (
    filters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: topOffers,
          variables: {
            filters,
            limit,
            offset,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
