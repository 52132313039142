<template>
    <div class="full">
        <NavigationBar
            v-if="$route.name && !$route.name.startsWith('auth')">
            <template #burger>
                <BurgerButton
                    :is-active="isActiveAsideMenuMobile"
                    @update="isActiveAsideMenuMobile = $event"></BurgerButton>
            </template>
        </NavigationBar>
        <AsideMenu
            v-if="$route.name && !$route.name.startsWith('auth')"
            :is-reduce.sync="isReduce"
            :is-active-aside-menu-mobile.sync="isActiveAsideMenuMobile">
            <template #burger="{ actionReduce, isActive }">
                <BurgerButton
                    :is-active="isActive"
                    @update="actionReduce"></BurgerButton>
            </template>
        </AsideMenu>
        <router-view></router-view>
        <LScrollBar></LScrollBar>
    </div>
</template>

<script>
  import AsideMenu from "@/components/Common/AsideMenu";
  import NavigationBar from "@/components/Common/NavigationBar/NavigationBar";
  import LScrollBar from "@/components/Common/Scroll/LScrollBar";
  import BurgerButton from "@/components/Common/Controls/BurgerButton.vue";

  export default {
    name: "Full",
    components: {
      BurgerButton,
      LScrollBar,
      AsideMenu,
      NavigationBar
    },
    data () {
      return {
        isActiveAsideMenuMobile: false,
        isReduce: false
      };
    },

    provide () {
      return {
        isReduceMenu: () => this.isReduce
      };
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        @media screen and (max-width: 1023px) {
            .navbar {
                z-index: 40 !important;
            }
        }
    }

    .full {
        display: flex;
        margin-right: 8px;
        margin-bottom: 8px;
    }
</style>
