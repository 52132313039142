import { PostbacksService } from "@core/services/webmaster/postbacks/PostbacksService";
import { GET_GLOBAL_POSTBACKS, REMOVE_GLOBAL_POSTBACK, SAVE_GLOBAL_POSTBACKS } from "@core/store/action-constants";
import { InputPbCreate, InputPbEdit, ModifedPostback } from "@core/store/logic/webmaster/postbacks/postback";
import {
  ADD_GLOBAL_POSTBACK,
  SET_EMPTY,
  SET_GLOBAL_POSTBACKS,
  UPDATE_GLOBAL_POSTBACK_STATUSES,
  UPDATE_GLOBAL_POSTBACK_URL,
  UPDATE_GLOBAL_POSTBACK_DUPLICATES,
  UPDATE_OFFER_POSTBACK_VERTICAL,
  UPDATE_POSTBACK_OPTIONS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CreateInput, EditInput } from "@core/store/types/webmaster/postbacks/postback";
import { State } from "@core/store/types/webmaster/profile/modules/globalPostback";
import { ActionTree, Module, MutationTree } from "vuex";
import Vue from "vue";

const initialState = (): State => {
  return {
    postbacks: []
  };
};

const state: () => State = initialState;

const mutations: MutationTree<State> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [ADD_GLOBAL_POSTBACK]: (state, vertical) => {
    // @ts-ignore
    state.postbacks = [new ModifedPostback({ vertical }), ...state.postbacks];
  },

  [SET_GLOBAL_POSTBACKS] (state, postbacks) {
    state.postbacks = postbacks;
  },

  [UPDATE_OFFER_POSTBACK_VERTICAL]: (state, { index, vertical }) => {
    state.postbacks[index].vertical = vertical;
  },

  [UPDATE_GLOBAL_POSTBACK_URL] (state, { value, index }): void {
    Vue.set(state.postbacks[index], "url", value);
  },

  [UPDATE_POSTBACK_OPTIONS] (state, { value, index }): void {
    Vue.set(state.postbacks, index, { ...state.postbacks[index], ...value });
  },

  [UPDATE_GLOBAL_POSTBACK_STATUSES] (state, { statusIndex, postbackIndex }): void {
    state.postbacks[postbackIndex].statuses[statusIndex].isActive = !state.postbacks[postbackIndex].statuses[statusIndex].isActive;
  },
  
  [UPDATE_GLOBAL_POSTBACK_DUPLICATES] (state, index): void {
    state.postbacks[index].isSendDuplicates = !state.postbacks[index].isSendDuplicates;
  }
};

const actions: ActionTree<State, RootState> = {
  async [GET_GLOBAL_POSTBACKS] ({ commit }) {
    const { data: { postbackUrlList } } = await PostbacksService.getPostbacks();
    const postbacks = postbackUrlList.map((pb) => new ModifedPostback(pb));

    commit(SET_GLOBAL_POSTBACKS, postbacks);
  },

  async [SAVE_GLOBAL_POSTBACKS] ({ state, dispatch }) {
    const { create, edit } = state.postbacks.reduce<{ create: CreateInput; edit: EditInput }>(
      (acc, pb) => {
        if (pb.id) {
          acc.edit.push(new InputPbEdit(pb));
        } else {
          acc.create.push(new InputPbCreate(pb));
        }
        return acc;
      },
      { create: [], edit: [] }
    );

    if (edit.length) {
      await PostbacksService.editPostbacks(edit);
    }

    if (create.length) {
      await PostbacksService.createPostbacks(create);
    }

    dispatch(GET_GLOBAL_POSTBACKS);
  },

  async [REMOVE_GLOBAL_POSTBACK] ({ dispatch, commit, state }, index) {
    const pb = state.postbacks[index];

    if (pb.id) {
      await PostbacksService.removePostback(pb.id as string);
      dispatch(GET_GLOBAL_POSTBACKS);
    } else {
      const filteredPostbacks = state.postbacks.filter((_, i) => i !== index);
      commit(SET_GLOBAL_POSTBACKS, filteredPostbacks);
    }
  },

  [UPDATE_OFFER_POSTBACK_VERTICAL] ({ commit }, payload) {
    commit(UPDATE_OFFER_POSTBACK_VERTICAL, payload);
  },

  [ADD_GLOBAL_POSTBACK] ({ commit, rootGetters: { user: { preferredVertical: vertical } } }) {
    commit(ADD_GLOBAL_POSTBACK, vertical);
  },

  [UPDATE_GLOBAL_POSTBACK_URL] ({ commit }, payload) {
    commit(UPDATE_GLOBAL_POSTBACK_URL, payload);
  },

  [UPDATE_GLOBAL_POSTBACK_STATUSES] ({ commit }, payload) {
    commit(UPDATE_GLOBAL_POSTBACK_STATUSES, payload);
  },
  
  [UPDATE_GLOBAL_POSTBACK_DUPLICATES] ({ commit }, payload) {
    commit(UPDATE_GLOBAL_POSTBACK_DUPLICATES, payload);
  },

  [UPDATE_POSTBACK_OPTIONS] ({ commit }, payload) {
    commit(UPDATE_POSTBACK_OPTIONS, payload);
  }
};

export const globalPostback: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
