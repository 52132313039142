<template>
    <span v-if="value">
        {{ $t(`common.entity.withdrawType.${ withdrawType }`) }}
    </span>
    <span v-else>
        {{ formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";

  export default {
    name: "WithdrawTypeOutput",

    props: {
      value: {
        type: Object,
        default: null
      }
    },

    computed: {
      withdrawType () {
        const { withdrawType, subGoalType } = this.value;
        return subGoalType ? [ withdrawType, subGoalType ].join("-") : withdrawType;
      }
    },

    methods: {
      formatEmptyString
    }
  };
</script>

<style lang="scss" scoped></style>
