import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  EDIT_USER,
  FETCH_USER,
  GET_ADVERTISER_PERSONAL_MANAGER, RESEND_EMAIL
} from "@core/store/action-constants";
import { ProfileService } from "@core/services/advertiser/ProfileService";
import {
  UPDATE_ADVERTISER_FORM,
  SET_PERSONAL_MANAGER,
  SAVE_USER,
  UPDATE_MODAL,
  SET_EMPTY, UPDATE_USER, SET_WHO_AM_I
} from "@core/store/mutation-constants";
import { AdvertiserProfileState } from "@core/store/types/advertiser/profile";
import { password } from "./modules/password";
import { setPreferredVertical } from "@core/store/modules/common/helpers/setPreferredVertical";

const initialState = (): AdvertiserProfileState => {
  return {
    user: null,
    form: {},
    modal: {
      isActive: false
    },
    personalManager: {}
  };
};

const state: () => AdvertiserProfileState = initialState;

const mutations: MutationTree<AdvertiserProfileState> = {
  [SAVE_USER] (state, user) {
    state.user = user;
  },

  [UPDATE_USER] (state, user) {
    state.user = { ...state.user, ...user };
  },

  [UPDATE_ADVERTISER_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal , ...modal };
  },
  
  [SET_PERSONAL_MANAGER] (state, manager) {
    state.personalManager = manager;
  },

  [SET_EMPTY] (state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, personalManager, ...emptyFields } = initialState();
    Object.assign(state, emptyFields);
  }
};

const actions: ActionTree<AdvertiserProfileState, RootState> = {
  async [FETCH_USER] ({ commit }) {
    const { data: { profile } } = await ProfileService.getProfile();

    commit(SAVE_USER, profile);
    commit(SET_WHO_AM_I, { preferredVertical: profile.systemVertical }, { root: true });
    setPreferredVertical(profile.systemVertical);

    return profile;
  },
  
  async [EDIT_USER] ({ commit, state: { form } }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, email, verticals, systemVertical, ...input } = form;

    const { data: { editAdvertiserProfile } } = await ProfileService
      .editProfile({ ...input, verticals: verticals?.length ? verticals : [] });
    commit(SAVE_USER, editAdvertiserProfile);
  },

  [UPDATE_USER] ({ commit }, payload) {
    commit(UPDATE_USER, payload);
  },
  
  async [GET_ADVERTISER_PERSONAL_MANAGER] ({ commit }) {
    const data = await ProfileService.getPersonalManager();
    
    commit(SET_PERSONAL_MANAGER, data);
  },

  async [RESEND_EMAIL] () {
    await ProfileService.resendEmail();
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const profile: Module<AdvertiserProfileState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    password
  }
};
