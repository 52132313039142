import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import trafficTypes from "./graphql/trafficTypes.graphql";

export class TrafficTypesService extends Service {
  public static async getTrafficTypes (): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: trafficTypes
      });
    } catch (e) {
      throw e;
    }
  }
}
