import { AdminStatisticByGroups } from "@core/logic/admin/statistic/AdminStatisticByGroups";
import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DashboardStatisticState } from "@core/store/types/common/statistic/common/statistic";
import {
  UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS,
  UPDATE_DASHBOARD_STATISTIC_FILTERS,
  SET_DASHBOARD_STATISTICS
} from "@core/store/mutation-constants";
import { SET_EMPTY, GET_DASHBOARD_STATISTICS } from "@core/store/action-constants";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import convertExcludedFiltersToIncludedFilters from "@core/helpers/convertExcludedFiltersToIncludedFilters";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): DashboardStatisticState => {
  return {
    statistics: null,
    dashboardFilters: {
      filters: {
        datepicker: datepicker({ amount: 1, unit: "month" }),
        withdrawType: null,
        groups: "day",
        offerId: null,
        webmasterId: null,
        countryId: null,
        advertiserId: null,
        managerId: null,
        isInHouse: null,
        currency: null,
        withBlockedWebmaster: false
      },
      excludedFilters: {
        excludedWebmasterId: null,
        excludedAdvertiserId: null,
        excludedManagerId: null,
        excludedCountryId: null,
        excludedWithdrawType: null,
        excludedOfferId: null
      }
    }
  };
};

const state: () => DashboardStatisticState = initialState;

const mutations: MutationTree<DashboardStatisticState> = {
  [SET_DASHBOARD_STATISTICS] (state, statistics: AdminStatisticByGroups): void {
    state.statistics = statistics;
  },
  
  [UPDATE_DASHBOARD_STATISTIC_FILTERS]: (state, filters: BaseFiltersInput): void => {
    state.dashboardFilters.filters = { ...state.dashboardFilters.filters, ...filters };
  },
  
  [UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS]: (state, excludedFilters: BaseFiltersInput): void => {
    state.dashboardFilters.excludedFilters = { ...state.dashboardFilters.excludedFilters, ...excludedFilters };
  },
  
  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], filters: { ...initialState()[target].filters, ...filters } };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DashboardStatisticState, RootState> = {
  async [GET_DASHBOARD_STATISTICS] ({
    commit,
    state: { dashboardFilters: { filters, excludedFilters } },
    rootState: { verticals: { currentVertical: vertical } }
  }): Promise<void> {
    const changedExcludedFilters = convertExcludedFiltersToIncludedFilters(excludedFilters) as BaseFiltersInput;
    const { groups, datepicker, ...otherFilters } = filters;
    const dashboardFilters = { ...datepicker, ...otherFilters, vertical };
    const [withdrawType, subGoalType ] = filters.withdrawType?.split("-") || [null, null];
    const [excludedWithdrawType, excludedSubGoalType ] = changedExcludedFilters?.withdrawType?.split("-") || [null, null];
    
    const { data: { statistics } } = await StatisticsService.getAdminStatisticGraph(
      { ...dashboardFilters, withdrawType, subGoalType },
      { ...changedExcludedFilters, withdrawType: excludedWithdrawType, subGoalType: excludedSubGoalType },
      [groups.toUpperCase()]
    );
    
    // @ts-ignore
    statistics.items = statistics.items.length ? statistics.items.reverse() : null;
    commit(SET_DASHBOARD_STATISTICS, statistics);
  },
  
  [UPDATE_DASHBOARD_STATISTIC_FILTERS] ({ commit }, filters: BaseFiltersInput): void {
    commit(UPDATE_DASHBOARD_STATISTIC_FILTERS, filters);
  },
  
  [UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS] ({ commit }, excludedFilter: BaseFiltersInput): void {
    commit(UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS, excludedFilter);
  },
  
  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
  }
};

export const dashboardStatistic: Module<DashboardStatisticState, RootState> = {
  namespaced: true,
  state,
  modules: {},
  actions,
  mutations
};
