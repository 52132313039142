import { StatisticItems, StatisticResponse } from "@core/store/types/common/statistic/common/statistic";

export default function ({ items, ...obj }: StatisticResponse) {
  const { countLeadsCancelled, countLeadsSpammed, ...otherTotal } = obj.aggregates.total;
  const { countLeadsCancelled: countLeadsCancelledAvg, countLeadsSpammed: countLeadsSpammedAvg, ...otherAverage } = obj.aggregates.average;

  const aggregates = {
    total: { countLeadsSpammed: countLeadsSpammed + countLeadsCancelled, ...otherTotal },
    average: { countLeadsSpammed: countLeadsSpammedAvg + countLeadsCancelledAvg, ...otherAverage }
  };

  return {
    ...obj,
    items: items.map(itemsMapper),
    aggregates
  };
}

const itemsMapper = ({ countLeadsCancelled, countLeadsSpammed, ...item }: StatisticItems) => {
  return {
    ...item,
    countLeadsSpammed: countLeadsSpammed + countLeadsCancelled
  };
};