import webAccuralGenerator from "@core/services/admin/finances/webAccrual/graphql/webAccural.generator";
import { Response, StateRequestFilters } from "@core/store/types/admin/finances/webAccrual";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class WebAccrualService extends Service {
  public static getWebAccrual (limit: number, offset: number, filters: StateRequestFilters): AxiosPromise<Response> {
    return this.api.get("", {
      params: webAccuralGenerator(limit, offset, filters)
    });
  }
}
