import { SET_INCIDENCE, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { GET_INCIDENCE, SET_EMPTY } from "@core/store/action-constants";
import { IncidenceState } from "@core/store/types/advertiser/incidence/incidence";
import { formatPercent, defaultFormatter } from "@core/filters";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { IncidenceService } from "@core/services/advertiser/incidence/IncidenceService";
import { incidenceDetail } from "@core/store/modules/advertiser/incidence/incidenceDetail";
import { fixedFormatPercent } from "@core/flowMethods";

const initialState = (): IncidenceState => {
  return {
    data: null,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "week" }),
      webmasterId: null,
      offerId: null
    }
  };
};

const state: () => IncidenceState = initialState;

const mutations: MutationTree<IncidenceState> = {
  [SET_INCIDENCE] (state, data: IncidenceState["data"]): void {
    state.data = data;
  },

  [UPDATE_FILTERS]: (state, filters: IncidenceState["filters"]): void => {
    state.filters = { ...state.filters, ...filters };
  },
  
  [SET_EMPTY] (state, target?: string) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<IncidenceState, RootState> = {
  async [GET_INCIDENCE] ({ state, commit }): Promise<void> {
    const { filters: { datepicker, webmasterId, ...fields } } = state;
    const filters = {
      ...datepickerFormat(datepicker, "date", true),
      ...fields,
      webmasterId: webmasterId ? [webmasterId] : null
    };

    // @ts-ignore
    const data = await IncidenceService.getIncidenceAnalyse(filters);

    const total = data.count;
    data.items = data.items?.map(({ diff, reason }) => ({
      value: fixedFormatPercent(diff / total), diff, reason
    }));

    commit(SET_INCIDENCE, data);
  },

  [UPDATE_FILTERS]: ({ commit }, filters): void => {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`incidenceDetail/${ SET_EMPTY }`);
  }
};

export const incidence: Module<IncidenceState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    incidenceDetail
  }
};