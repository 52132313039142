export default [
  {
    path: "users",
    name: "users",
    redirect: { name: "admin:users:webmasters" },
    component: (): Promise<any> => import(/* webpackChunkName: "users" */ "@/views/Admin/UsersView.vue"),
    children: [
      {
        path: "webmasters",
        name: "webmasters",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmasters" */ "@/components/Admin/Users/UsersWebmasters.vue")
      },
      {
        path: "advertisers",
        name: "advertisers",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "advertisers" */ "@/components/Admin/Users/UsersAdvertisers.vue")
      },
      {
        path: "administrators",
        name: "administrators",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "administrators" */ "@/components/Admin/Users/UsersAdministrators.vue")
      }
    ]
  }
];
