


























































































import InfiniteLoading from "vue-infinite-loading";
import { defineEmits, defineProps, withDefaults } from "@vue/runtime-dom";
interface Props {
  viewed: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  viewed: {
    key: "viewed",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  function infiniteHandler() {
    emit("update:viewed", true);
  }
  return {
    infiniteHandler
  };
};
__sfc_main.components = Object.assign({
  InfiniteLoading
}, __sfc_main.components);
export default __sfc_main;
