import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY, UPDATE_MODAL } from "@core/store/mutation-constants";
import { RolesModalState } from "@core/store/types/admin/roles/rolesModal";
import {
  ADD_ROLE,
  UPDATE_ROLE,
  GET_ROLE_BY_ID,
  UPDATE_ROLES_FORM,
  UPDATE_ROLES_FORM_MODAL,
  UPDATE_ROLES_PERMISSIONS
} from "@core/store/action-constants";
import { RolesService } from "@core/services/admin/roles/RolesService";
import { Form } from "@core/store/types/admin/payments/PaymentsModalState";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { RolesFilters } from "@core/store/types/admin/roles/roles";

const initialState = (): RolesModalState => {
  return {
    form: {
      id: null,
      name: null,
      permissions: []
    },
    filters: {
      id: null,
      name: null
    },
    option: {
      isActive: false
    }
  };
};

const state: () => RolesModalState = initialState;

const mutations: MutationTree<RolesModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, modal): void {
    state.option = { ...state.option , ...modal };
  },
  
  [UPDATE_ROLES_FORM] (state, form: Form): void {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_ROLES_PERMISSIONS] (state, permissions): void {
    state.form.permissions = [ ...state.form.permissions, ...permissions ];
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<RolesModalState, RootState> = {
  async [GET_ROLE_BY_ID] ({ commit }, payload: string): Promise<void> {
    const limit = 500;
    const offset = 0;
    const filters: RolesFilters = { id: [payload] };

    const getRole = await RolesService.getRolesById(limit, offset, filters);
    commit(UPDATE_ROLES_FORM, getRole);
  },
  
  async [ADD_ROLE] ({ state, commit }, permissions): Promise<void> {
    const { name } = state.form;

    const addRole = await RolesService.addRole(name, permissions);
    commit("admin/roles/LOCAL_ADD", { items: addRole, target: "roles", infiniteScroll: true }, { root: true });
  },
  
  async [UPDATE_ROLE] ({ state, commit }, permissions): Promise<void> {
    const { id: roleId, name } = state.form;
    const input = { name, permissions };

    const updateRole = await RolesService.updateRole(roleId, input);
    commit("admin/roles/LOCAL_UPDATE", { items: updateRole, target: "roles", infiniteScroll: true }, { root: true });
  },

  [UPDATE_MODAL] ({ commit }, payload: boolean): void {
    commit(UPDATE_MODAL, payload);
  },
  
  [UPDATE_ROLES_FORM_MODAL] ({ commit }, form): void {
    commit(UPDATE_ROLES_FORM, form);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const rolesModal: Module<RolesModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};