import Service from "@core/services/common/Service";
import offerAuditGql from "./graphql/offerAudits.graphql";
import { AdminOffer } from "@core/store/logic/admin/audit/AuditOffer";
import {
  AuditFilters,
  AuditResponse,
  AuditResponseData
} from "@core/store/types/admin/audit";
import _pickBy from "lodash/pickBy";

export class AuditService extends Service {
  public static async getOfferAudits (
    limit: number,
    offset: number,
    filters: AuditFilters
  ): Promise<AuditResponseData> {
    try {
      const { data: { offerAudits } } = await this.api.get("", {
        params: {
          query: offerAuditGql,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      const offersAudit = offerAudits.items.map((item: AuditResponse) => {
        return {
          ...new AdminOffer(item),
          newData: _pickBy(new AdminOffer(item).newData, undefined),
          oldData: _pickBy(new AdminOffer(item).oldData, undefined)
        };
      });

      return {
        count: offerAudits.count,
        items: offersAudit
      };
    } catch (e) {
      throw e;
    }
  }
}