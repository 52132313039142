<script>

  export default {
    name: "BaseTable",

    props: {
      data: {
        type: Array,
        default: null
      },

      backendSorting: {
        type: Boolean,
        default: true
      },

      backendPagination: {
        type: Boolean,
        default: false
      },

      perPage: {
        type: Number,
        default: 25
      },

      page: {
        type: Number,
        default: 1
      },
  
      virtualPerPage: {
        type: Number,
        default: 15
      },
  
      virtualScroll: {
        type: Boolean,
        default: false
      },

      queryPagination: {
        type: Boolean,
        default: false
      },

      loading: {
        type: Boolean,
        default: false
      },

      hidePagination: {
        type: Boolean,
        default: false
      },

      withOptions: {
        type: Boolean,
        default: false
      },

      count: {
        type: Number,
        default: null
      },

      hoverable: {
        type: Boolean,
        default: false
      },

      checkable: {
        type: Boolean,
        default: false
      },

      isRowCheckable: {
        type: Function,
        default: () => true
      },

      checkedRows: {
        type: Array,
        default: () => []
      },

      hideLimit: {
        type: Boolean,
        default: false
      },

      limit: {
        type: [Array, String],
        default: "medium"
      },

      pageInput: {
        type: Boolean,
        default: true
      },

      // stickyPagination: {
      //   type: Boolean,
      //   default: false
      // },

      debouncePageInput: {
        type: [Number, String],
        default: 400
      },

      detailed: {
        type: Boolean,
        default: false
      },

      showDetailIcon: {
        type: Boolean,
        default: false
      },

      customDetailRow: {
        type: Boolean,
        default: false
      },

      detailKey: {
        type: String,
        default: null
      },

      defaultSort: {
        type: [String, Array],
        default: null
      },

      hideSticky: {
        type: Boolean,
        default: false
      },

      hideMobile: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      pageChange (event) {
        this.$emit("pageChange", event);
      },

      sort (value, order) {
        if (!this.backendSorting) {
          this.$refs.lTable.doSortSingleColumn({ field: value, customSort: order });
        } else {
          // backend order принимает в uppercase
          this.$emit("sort", value, order.toUpperCase());
          this.localDefaultSort = [value, order.toUpperCase()];
        }
      }
    }
  };

</script>