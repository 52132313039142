<template>
    <div class="is-flex is-justify-content-flex-end">
        <div class="sticky-wrapper is-flex-wrap-wrap">
            <slot name="before"></slot>
            <LimitPageSelect
                v-if="!hideLimit"
                :value="+_perPage"
                class="mr-2"
                :limit="limitPagination"
                @input="updateRoute('perPage', $event)">
                <template #trigger="{ active }">
                    <TableItemsCount
                        :items-length="itemsLength"
                        :count="count"
                        :current-page="+_page"
                        :per-page="+_perPage">
                    </TableItemsCount>
                    <b-icon
                        :icon="`chevron-${active ? 'up' : 'down'}`"
                        size="is-small"></b-icon>
                </template>
            </LimitPageSelect>
            <b-pagination
                v-if="!hidePagination"
                :current="+_page"
                simple
                :per-page="+_perPage"
                :total="count"
                :page-input="pageInput"
                :debounce-page-input="+debouncePageInput"
                @change="updateRoute('page', $event)">
            </b-pagination>
        </div>
    </div>
</template>

<script>
  import TableItemsCount from "@/components/Common/Table/TableItemsCount";
  import LimitPageSelect from "@/components/Common/Select/LimitPageSelect.vue";

  export default {
    name: "LPagination",
    components: {
      LimitPageSelect,
      TableItemsCount
    },

    props: {
      page: {
        type: [Number, String],
        default: 1
      },

      perPage: {
        type: [Number, String],
        default: 25
      },

      count: {
        type: Number,
        default: 0
      },

      hideLimit: {
        type: Boolean,
        default: false
      },

      hidePagination: {
        type: Boolean,
        default: false
      },

      limit: {
        type: [Array, String],
        default: "medium"
      },

      pageInput: {
        type: Boolean,
        default: false
      },

      queryPagination: {
        type: Boolean,
        default: false
      },

      debouncePageInput: {
        type: [Number, String],
        default: 400
      },
      
      itemsLength: {
        type: Number,
        default: null
      }
    },

    computed: {
      _page () {
        return this.queryPagination ? this.$route.query.page : this.page;
      },

      _perPage () {
        return this.queryPagination ? this.$route.query.perPage : this.perPage;
      },

      limitPagination () {
        const sizeLimit = {
          large: [50, 100, 250, 500, 1000],
          medium: [25, 50, 100, 200, 500],
          small: [10, 20, 30, 40, 50]
        };
        if (Array.isArray(this.limit)) {
          return this.limit;
        } else {
          return sizeLimit[this.limit];
        }
      }
    },

    methods: {
      update (key, value) {
        this.$emit(`update:${ key }`, value);
        this.$emit("update", { key, value });
      },

      updateRoute (key, value) {
        if (this.queryPagination) {
          this.$router.push({
            query: { ...this.$route.query, [key]: value }
          }).catch(_ => {});
        } else {
          this.update(key, value);
        }
      }
    },

    watch: {
      _page (value) {
        if (this.queryPagination) {
          this.update("page", value);
        }
      },

      _perPage (value) {
        if (this.queryPagination) {
          this.update("perPage", value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    $color-pagination-button: #b5b5b5;
    $color-border: #dbdbdb;

    .sticky-wrapper {
        position: sticky;
        right: 2rem;
        display: flex;
        align-items: center;
        row-gap: 1rem;

        ::v-deep {
            .info {
               display: none;
            }
            .pagination-link {
                color: $color-pagination-button;
                background-color: white;
                max-width: 39px;
                border-color: $color-border;

                &.is-current {
                    border-color: $primary;
                }
            }
        }
    }
</style>