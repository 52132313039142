import Service from "@core/services/common/Service";
import { AdministratorsState } from "@core/store/types/admin/users/administrators/AdministratorsState";
import { AxiosPromise } from "axios";
import admins from "./graphql/admins.generator";
import blockAdminQuery from "./graphql/blockAdmin.graphql";
import unblockAdminQuery from "./graphql/unBlockAdmin.graphql";

export class AdministratorsService extends Service {
  public static async getAdministrators (
    filters: AdministratorsState["filters"],
    limit: number,
    offset: number,
    sort: AdministratorsState["sort"],
    order: AdministratorsState["order"],
    hasPermissionAdvertisers: boolean
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: admins(
          filters,
          limit,
          offset,
          sort,
          order,
          hasPermissionAdvertisers
        )
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async blockAdministrator (adminId: string): Promise<AxiosPromise> {
    try {
      const { data: { blockAdmin } } = await this.api.post("", {
        query: blockAdminQuery,
        variables: {
          adminId
        }
      });
      
      return blockAdmin;
    } catch (e) {
      throw e;
    }
  }

  public static async unBlockAdministrator (adminId: string): Promise<AxiosPromise> {
    try {
      const { data: { unBlockAdmin } } = await this.api.post("", {
        query: unblockAdminQuery,
        variables: {
          adminId
        }
      });
      
      return unBlockAdmin;
    } catch (e) {
      throw e;
    }
  }
}
