import { GET_RECOVERY_STRATEGY } from "@core/store/action-constants";
import { UPDATE_RECOVERY_STRATEGY_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { RecoveryStrategyListState } from "@core/store/types/admin/crm/lists/RecoveryStrategyListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): RecoveryStrategyListState => {
  return {
    data: {}
  };
};

const state: () => RecoveryStrategyListState = initialState;

const getters: GetterTree<RecoveryStrategyListState, RootState> = {};

const mutations: MutationTree<RecoveryStrategyListState> = {
  [UPDATE_RECOVERY_STRATEGY_LIST] (state, recoveryStrategy: RecoveryStrategyListState["data"]) {
    state.data = recoveryStrategy;
  }
};

const actions: ActionTree<RecoveryStrategyListState, RootState> = {
  [GET_RECOVERY_STRATEGY] ({ commit }) {
    const items = [
      {
        label: "leadGroup",
        value: "LEADGROUP"
      }
    ];
    commit(UPDATE_RECOVERY_STRATEGY_LIST, {
      items,
      count: items.length
    });
  }
};

export const recoveryStrategyList: Module<RecoveryStrategyListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
