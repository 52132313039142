<template>
    <section class="progressBar-wrapper">
        <div class="progressBar mr-4">
            <div class="progressBar-icon-left">
                <img
                    alt="logo-header"
                    src="@/assets/Competition/Raffle/logo-header.svg">
            </div>
            <div
                class="progressBar-line"
                :style="{ width: `${ percentGoal }%` }">
                <span
                    v-if="percentGoal"
                    class="progressBar-amountPercent">
                    {{ percentGoal }}%
                </span>
            </div>
        </div>
        <b-dropdown
            :mobile-modal="false"
            :triggers="['hover']"
            aria-role="list"
            class="competitionInfo"
            position="is-bottom-left">
            <template #trigger>
                <div class="flex is-align-items-center">
                    <img
                        alt="Crystal"
                        class="mr-1 mb-2"
                        src="@/assets/Competition/Raffle/Crystal-Header.svg">
                    <span class="count">
                        {{ currentCompetition && currentCompetition.balance }}
                    </span>
                </div>
            </template>

            <div class="competitionInfo-wrapper has-text-white">
                <div class="competitionInfo-body">
                    <div class="flex is-justify-content-space-between">
                        <div>
                            <div class="flex is-align-items-center mb-3">
                                <img
                                    alt="coin"
                                    class="mr-2"
                                    src="@/assets/Competition/Raffle/Crystal-Header.svg">
                                <p class="is-size-3 has-text-weight-semibold">
                                    {{ currentCompetition.balance }}
                                </p>
                            </div>
                            <p class="default-text-style">
                                {{ $t("common.rating.card.ticket") }}
                            </p>
                        </div>
                        <div>
                            <div class="flex is-align-items-center mb-3">
                                <img
                                    alt="coin"
                                    class="mr-3"
                                    src="@/assets/Competition/curved-line.svg">
                                <p class="is-size-3 has-text-weight-semibold percent-goal">
                                    {{ `${ percentGoal }%` }}
                                </p>
                            </div>
                            <p class="default-text-style">
                                {{ $t("webmaster.dashboard.competition.dropdown.conditions") }}
                            </p>
                        </div>
                    </div>

                    <span class="competitionInfo-body_line"></span>

                    <p class="competitionInfo-body_description">
                        <i18n path="webmaster.dashboard.competition.dropdown.conditionsInfo">
                            <template #money>
                                <span class="has-text-weight-bold has-text-white money">$1000</span>
                            </template>
                            <template #crystal>
                                <span class="has-text-weight-bold has-text-white money">{{ $t("webmaster.dashboard.competition.dropdown.conditionsInfoCrystal") }}</span>
                            </template>
                        </i18n>
                    </p>

                    <a
                        href="https://breakingbad.expert"
                        target="_blank"
                        rel="noopener">
                        <div class="flex is-align-items-center mb-1">
                            <img
                                alt="cup"
                                class="mr-2"
                                src="@/assets/Competition/cup-regular.svg">
                            <p class="competitionInfo-body_about-competition">
                                {{ $t("webmaster.dashboard.competition.dropdown.aboutCompetition") }}
                            </p>
                        </div>
                    </a>
                    <p class="default-text-style">
                        {{ $t("webmaster.dashboard.competition.dropdown.termsPrizes") }}
                    </p>
                </div>
            </div>
        </b-dropdown>
    </section>
</template>

<script>
  import { mapState } from "vuex";
  import { GET_CURRENT_COMPETITION } from "@core/store/action-constants";
  import { encodeFilters, formatPercent, toFixed } from "@core/filters";

  export default {
    name: "CompetitionDropdown",

    computed: {
      ...mapState("competition", {
        currentCompetition: ({ currentCompetition }) => currentCompetition
      }),

      percentGoal () {
        if (this.currentCompetition) {
          return formatPercent(toFixed(
            this.currentCompetition.currentProgress / this.currentCompetition.floatGoal,
            1));
        } return 0;
      },

      isLoading () {
        return this.$wait(`competition/${ GET_CURRENT_COMPETITION }`);
      }
    },

    methods: { encodeFilters }
  };
</script>

<style lang="scss" scoped>
@import "~@/scss/competition-banner";

::v-deep {
    .dropdown .dropdown-menu {
        right: -70px !important;
    }
}

.progressBar {
    position: relative;
    width: 120px;
    height: 24px;

    padding: 4px;
    background-color: black;
    border-radius: 12px;

    &:hover .progressBar-amountPercent {
        display: block;
    }

    &-icon-left {
        position: absolute;
        z-index: 1;
        top: -26%;
        left: -18%;
        border: 1px solid black;
        border-radius: 999px;
        height: 38px;
        width: 38px;
    }

    &-line {
        position: relative;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(180deg, #e0fba6 25.5%, #9fd728 75%, #557c03 100%);

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: url("~@/assets/Competition/Raffle/particles-header.svg");
        }

        .progressBar-amountPercent {
            display: none;

            position: absolute;
            top: -113%;
            right: -20%;
            padding: 2px 5px;
            z-index: 11;

            font-weight: 600;
            font-size: 10px;
            line-height: 1.4;
            color: #FFFFFF;

            background-color: black;
            border-radius: 8px;
        }
    }
}

.default-text-style {
    color: $light-gray_;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre;
}

.count {
    font-weight: 600;
    font-size: 24px;
    color: $dark-brown;
}

.progressBar-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 0.5rem;
}

.competitionInfo {
    $self: &;
    $border-radius: 10px;
    $inner-indent: 10;

    ::v-deep {
        .dropdown-content {
            padding: 0;
            border-radius: $border-radius;
        }

        .dropdown-menu {
            position: absolute;
            left: -50px;

            padding-top: #{ $inner-indent }px;
            border-radius: $border-radius;

            &::before {
                content: "";

                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;

                width: 100%;
                height: calc(100% - #{ $inner-indent }px);

                border-radius: $border-radius;
                box-shadow: 0 4px 4px 0 #25062C40;
            }
        }
    }

    &-wrapper {
        position: relative;

        overflow: hidden;
        border-radius: $border-radius;
        background-color: black;

        #{ $self }-body {
            position: relative;
            width: 350px;

            padding: 32px;
            border-radius: $border-radius;

            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;

                width: 100%;
                height: 40px;
                background-image: url("~@/assets/Competition/BG-dropdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
            }

            &_line {
                display: block;

                width: 100%;
                height: 1px;
                margin: 24px 0;
                background-color: $dark-brown;
            }

            &_description {
                margin-bottom: 24px;

                color: $light-gray;
                font-size: 14px;
                line-height: 1.43;
            }

            &_about-competition {
                font-size: 14px;
                line-height: 1.43;
                color: $color-green;
            }
        }
    }
}

.dropdown-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 2rem;
}
</style>
