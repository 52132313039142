import { DetailingRequestModalService } from "@core/services/admin/statistic/detailing/modal/detailingRequestModalService";

import {
  GET_PAID_UP_COUNT,
  GET_RE_REQUESTED_LEADS,
  GET_SENT_TO_CRM_LEADS,
  REREQUEST_LEAD_STATUSES,
  SELECTED_LEADS
} from "@core/store/action-constants";
import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { DetailingRequestModalState } from "@core/store/types/admin/statistic/detailing/modal/detailingRequestModalState";
import { ResponseDetailItem } from "@core/store/types/admin/statistic/detailing/detailingState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): DetailingRequestModalState => {
  return {
    isDetailingRequestModalActive: false
  };
};

const state: () => DetailingRequestModalState = initialState;

const getters: GetterTree<DetailingRequestModalState, RootState> = {
  [SELECTED_LEADS]: (_, __, rootState) => rootState.snackbarActions.selected,

  [GET_SENT_TO_CRM_LEADS]: (_, getters) => getters[SELECTED_LEADS]?.filter(({ data }: ResponseDetailItem) => !!data.remoteId),

  [GET_RE_REQUESTED_LEADS]: (_, getters) => function (): ResponseDetailItem[] {
    const sentToCrm = getters[GET_SENT_TO_CRM_LEADS];
    return sentToCrm.filter(({ data }: ResponseDetailItem) => {
      const { status } = data;

      switch (data.reward.withdrawType) {
        case "REQUEST":
          return !status.includes("ACCEPT") || !status.includes("PAID") || !status.includes("CLOSED");
        case "ACCEPT":
          return !status.includes("PAID") || !status.includes("CLOSED");
        default:
          return false;
      }
    });
  },

  [GET_PAID_UP_COUNT]: (_, getters) => function (): number {
    const sentToCrmCount = getters[GET_SENT_TO_CRM_LEADS];
    const reRequestedCount = getters[GET_RE_REQUESTED_LEADS]();

    return sentToCrmCount.length - reRequestedCount.length;
  }
};

const mutations: MutationTree<DetailingRequestModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isDetailingRequestModalActive = payload;
  }
};

const actions: ActionTree<DetailingRequestModalState, RootState> = {

  async [REREQUEST_LEAD_STATUSES] ({ getters }) {
    const leadsIds = getters[GET_SENT_TO_CRM_LEADS].map(({ id }: ResponseDetailItem) => id);
    try {
      await DetailingRequestModalService.postbackRecovery(
        leadsIds
      );
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit(UPDATE_MODAL_ACTIVE, payload);
    } else {
      commit("SET_EMPTY");
    }
  }
};

export const detailingRequestModal: Module<DetailingRequestModalState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
