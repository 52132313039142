import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { StatisticService } from "@core/logic/advertiser/statistic/statistic";
import { GET_STATISTIC, UPDATE_OPTIONS } from "@core/store/action-constants";
import { SET_EMPTY, SET_STATISTIC, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { datepicker } from "@core/helpers/datepicker";
import { DashboardOptionsActiveChart } from "@core/store/types/advertiser/dashboard/dashboardEnums";
import { DashboardState } from "@core/store/types/advertiser/dashboard/dashboard";

const initialState = (): DashboardState => {
  return {
    chart: null,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "month" }),
      offerId: null
    },
    options: {
      activeChart: DashboardOptionsActiveChart.LEADS
    }
  };
};

const state: () => DashboardState = initialState;

const mutations: MutationTree<DashboardState> = {
  [SET_STATISTIC] (state, chart: DashboardState["chart"]): void {
    state.chart = chart;
  },

  [UPDATE_FILTERS] (state, filters: DashboardState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_OPTIONS] (state, options: DashboardState["options"]): void {
    state.options = { ...state.options, ...options };
  },

  [SET_EMPTY] (state, target?: keyof DashboardState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DashboardState, RootState> = {
  async [GET_STATISTIC] ({ state, commit }): Promise<void> {
    const { datepicker, ...otherFilters } = state.filters;
    const data = await StatisticService.getAdvertiserDashboardStatistic({ ...datepicker, ...otherFilters }, ["DAY"]);

    commit(SET_STATISTIC, data);
  },

  [UPDATE_FILTERS] ({ commit }, filter: DashboardState["filters"]): void {
    commit(UPDATE_FILTERS, filter);
  },

  [UPDATE_OPTIONS] ({ commit }, options: DashboardState["options"]): void {
    commit(UPDATE_OPTIONS, options);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
