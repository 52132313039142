import { mapState } from "vuex";

/**
 * Набор экшенов и мутаций для поулчения фильтров по пользователям
 * @typedef {Object} GetUsersOptions
 * @property {string} updatePaginationMutation - Мутация для обновления пагинации
 * @property {string} updateFiltersMutation - Мутация для обновления фильтров
 * @property {string} getDataAction - Экшен для получения фильтров
 * @property {string} getInitialAction - Экшен для получения инициализирующих фильтров
 */

// @vue/components
export const UsersFilters = {
  computed: {
    ...mapState({
      currencyList: ({ auth }) => auth?.availableCurrencies
    })
  },
  
  methods: {
    getUsersByActionName (func, label, offset, loginFilterType = "loginOrIntId", filters = {}) {
      label = label.length > 0 ? label : null;
      return func({
        limit: 25,
        offset,
        filters: {
          ...filters,
          [loginFilterType]: label
        }
      });
    }
  }
};
