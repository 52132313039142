<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
  export default {
    name: "Empty"
  };
</script>

<style scoped>

</style>
