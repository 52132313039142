import offerByIntIdGenerator from "@core/services/admin/common/offer/graphql/offerByIntId.generator";
import { OfferDataByIntIdDataValue } from "@core/store/types/admin/common/OfferOutput";
import Service from "@core/services/common/Service";

export class OfferByIntIdService extends Service {
  public static async getOfferByIntId (intId: string): Promise<OfferDataByIntIdDataValue> {
    try {
      const { data: { offerByIntId: data } } = await this.api.get("", { params: offerByIntIdGenerator(intId) });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}
