var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CompactList',{attrs:{"items":_vm._contacts},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
var ref_item = ref.item;
var type = ref_item.type;
var value = ref_item.value;
return [_c('ContactOutput',{attrs:{"has-icon":"","type":type,"value":value}})]}},{key:"tooltipItem",fn:function(ref){
var ref_item = ref.item;
var type = ref_item.type;
var value = ref_item.value;
return [_c('ContactOutput',{attrs:{"has-icon":"","type":type,"value":value}})]}},{key:"singleItem",fn:function(ref){
var ref_item = ref.item;
var type = ref_item.type;
var value = ref_item.value;
return [_c('ContactOutput',{attrs:{"has-icon":"","type":type,"value":value}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }