import inTransactionsQuery from "./graphql/inTransactions.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise, AxiosResponse } from "axios";
import cancelInTransactionQuery from "./graphql/cancelInTransactionQuery.graphql";
import balance from "./graphql/balance.graphql";
import AddInTransactionInputQuery from "./graphql/AddInTransactionInputQuery.graphql";

export class FinancesService extends Service {
  public static async getIncomes (limit: number, offset: number, filters: any): Promise<AxiosResponse> {
    try {
      const { data: { inTransactions } } = await this.api.get("", {
        params: {
          query: inTransactionsQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return inTransactions;
    } catch (e) {
      throw e;
    }
  }
  
  public static async cancelInTransaction (id: string): Promise<AxiosPromise> {
    try {
      const { data: { cancelInTransaction } } = await this.api.post("", {
        query: cancelInTransactionQuery,
        variables: {
          id
        }
      });
      return cancelInTransaction;
    } catch (e) {
      throw e;
    }
  }
  
  public static async createIncome (input: string): Promise<AxiosPromise> {
    try {
      const { data: { addInTransaction } } = await this.api.post("", {
        query: AddInTransactionInputQuery,
        variables: {
          input
        }
      });
      return addInTransaction;
    } catch (e) {
      throw e;
    }
  }
  
  public static async balance (
    userId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: balance,
        variables: {
          userId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}