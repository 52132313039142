import VueInstance from "@/main";
import momentjs from "moment";
import Vue from "vue";
import i18n from "@core/plugins/i18n";
import { CurrencySymbol } from "@core/helpers/currencySymbolMap";

export function momentInst (value?: Date | string, locale = i18n.locale): momentjs.Moment {
  const { offset } = VueInstance.$store.getters.timezone;
  
  return momentjs
    .utc(value)
    .utcOffset(offset)
    .locale(locale);
}

export function moment (
  value?: Date | string,
  format = "DD MMMM YYYY HH:mm",
  withOffset = false,
  dateModifier = (momentInstance: momentjs.Moment): momentjs.Moment => momentInstance,
  locale = i18n.locale
): string {
  const { offset } = VueInstance.$store.getters.timezone;
  const formatToken = withOffset ? format + offset : format;
  const momentInstance = momentInst(value, locale);
  
  return dateModifier(momentInstance).format(formatToken);
}

export function momentFrom (value?: Date | string, locale = i18n.locale): string {
  return momentjs(value).locale(locale).fromNow();
}

export function formatEmptyString (value: any, emptyString = "-"): string {
  value = value || value === 0 ? String(value).trim() : value;
  return value && value !== "Invalid date" && value !== "NaN" && !(typeof value === "number" && isNaN(value)) ?
    value : emptyString;
}

export function toFixed (value: any, digits = 0): string | undefined {
  if (value != null) {
    value = parseFloat(Number(value)?.toFixed(digits));
    return value === parseInt(value) ? value : value.toFixed(digits);
  }
}

export function formatPercent (value: any): number {
  return parseFloat(value) * 100;
}

export function formatCurrency (value: any, currency = "USD"): string | undefined {
  // @ts-ignore
  const symbolFromCurrency = CurrencySymbol[currency?.toUpperCase()];
  const formatValue = toFixed(value, 2);

  if (value == null) {
    return formatEmptyString(value);
  }

  if (symbolFromCurrency) {
    return `${ formatValue } ${ symbolFromCurrency }`;
  }

  return formatValue;
}

Vue.filter("moment", moment);
Vue.filter("momentFrom", momentFrom);
Vue.filter("formatEmptyString", formatEmptyString);
Vue.filter("formatPercent", formatPercent);
Vue.filter("toFixed", toFixed);
Vue.filter("formatCurrency", toFixed);


