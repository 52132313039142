import { GET_DOMESTIC_WEBMASTERS } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_DOMESTIC_WEBMASTERS_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { DomesticWebmastersListState } from "@core/store/types/admin/lists/DomesticWebmastersListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";

const initialState = (): DomesticWebmastersListState => {
  return {
    data: {}
  };
};

const state: () => DomesticWebmastersListState = initialState;

const getters: GetterTree<DomesticWebmastersListState, RootState> = {};

const mutations: MutationTree<DomesticWebmastersListState> = {
  [UPDATE_DOMESTIC_WEBMASTERS_LIST] (state, domestic: DomesticWebmastersListState["data"]) {
    state.data = domestic;
  },
  
  [SET_EMPTY]: state => {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<DomesticWebmastersListState, RootState> = {
  [GET_DOMESTIC_WEBMASTERS] ({ commit }) {
    const items = [
      {
        value: true,
        label: i18n.t("admin.users.webmasters.filters.values.isDomestic")
      },
      {
        value: false,
        label: i18n.t("admin.users.webmasters.filters.values.isNotDomestic")
      }
    ];
    commit(UPDATE_DOMESTIC_WEBMASTERS_LIST, {
      items,
      count: items.length
    });
  }
};

export const domesticWebmastersList: Module<DomesticWebmastersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
