<template>
    <div class="progress-bar">
        <div
            v-show="!link && status !== 'FAILED'"
            class="circle">
        </div>
        <LTooltip
            type="is-info"
            position="is-top">
            <template #content>
                {{ $t(`common.files.tooltips.${ status === 'FAILED' ? 'error' : 'download' }`) }}
            </template>
            <b-button
                type="is-info"
                :tag="link ? 'a' : 'button'"
                :href="link ? link : ''"
                target="_blank"
                rounded
                :style="{ 'background-color': status === 'FAILED' ? '#ff0000c2' : '#1a5cffcc' }"
                @click="downloadFile">
                <span
                    v-if="!link && status !== 'FAILED'"
                    class="outputValue">
                    {{ readyPercent }}%
                </span>
                <b-icon
                    v-else
                    icon="file-download"
                    pack="uil"
                    size="is-large">
                </b-icon>
            </b-button>
        </LTooltip>
    </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { SET_EMPTY, SET_UPLOAD_FILE_ID } from "@core/store/action-constants";
  
  export default {
    name: "UploadsList",
    
    data () {
      return {
        value: 0,
        interval: null,
        outputValue: 0
      };
    },
    
    computed: {
      ...mapState("uploads", {
        file: state => state.file ?? {}
      }),

      link () {
        return this.file.link;
      },

      status () {
        return this.file.status;
      },
  
      readyPercent () {
        const percent = this.file.readyPercent || 0;
        return percent > 100 ? 100 : percent;
      }
    },
    
    methods: {
      ...mapActions("uploads",[
        SET_UPLOAD_FILE_ID,
        SET_EMPTY
      ]),

      downloadFile () {
        if(this.link) {
          this.SET_UPLOAD_FILE_ID(null);
        } else {
          this.SET_EMPTY();
        }
      }
    },
    
    watch: {
      value () {
        this.interval = setInterval(() => {
          if (this.outputValue < this.value) {
            this.outputValue += 1;
          } else {
            clearInterval(this.interval);
          }
        }, 40);
      }
    }
  };

</script>

<style lang="scss" scoped>
    $radius: 70;

    .progress-bar {
      z-index: 100;
      position: fixed;
      right: 30px;
      bottom: 25px;
      padding: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .circle{
        width: $radius + 18 + px;
        height: $radius + 18 + px;
        border-bottom: 4px dashed #487dff;
        border-radius:50%;
        position:absolute;
        overflow:hidden;
        z-index: 10;
        animation: circle 2s linear infinite;
      }
      .button {
        width: $radius + px;
        height: $radius + px;

        .outputValue {
          font-size: 18px;
        }
        img {
            font-size: $radius - 40 + px;
        }
      }
    }
    .media {
        min-width: 160px;
    }

    @keyframes circle{
      0%{
        transform:rotate(0deg)
    
      }
      100%{
        transform:rotate(360deg)
      }
    }
</style>
