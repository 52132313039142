import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  CapacityRequestState,
  PayoutRequestState,
  UpdateSubsectionRequestDTO
} from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import {
  SET_EMPTY,
  SET_PAYOUT_REQUEST,
  SET_MINIMAL_PAYOUT
} from "@core/store/mutation-constants";
import {
  ACTUALIZE_PAYOUT_REQUEST,
  APPROVE_PAYOUT_REQUEST,
  CREATE_PAYOUT_REQUEST,
  DELETE_PAYOUT_REQUEST,
  EDIT_PAYOUT_REQUEST,
  REJECT_PAYOUT_REQUEST,
  UPDATE_PAYOUT_REQUEST,
  UPDATE_SUBSECTION_REQUEST,
  GET_MINIMAL_PAYOUT
} from "@core/store/action-constants";
import { PayoutRequestService } from "@core/services/admin/offers/PayoutRequestService";

class PayoutRequest implements Module<PayoutRequestState, RootState> {
  public namespaced: boolean;
  public state: PayoutRequestState;
  
  public getters: GetterTree<CapacityRequestState, RootState> = {
    getData: (state) => state.data
  }
  
  public mutations: MutationTree<PayoutRequestState> = {
    [SET_PAYOUT_REQUEST] (state: PayoutRequestState, payload): void {
      state.data = payload;
    },
    
    [UPDATE_PAYOUT_REQUEST] (state: PayoutRequestState, payload): void {
      state.data = { ...state.data, ...payload };
    },
    
    [SET_MINIMAL_PAYOUT] (state: PayoutRequestState, payload): void {
      state.minimalPayout = payload;
    }
  };
  
  public actions: ActionTree<PayoutRequestState, RootState> = {
    [UPDATE_PAYOUT_REQUEST] ({ commit }, payload): void {
      commit(UPDATE_PAYOUT_REQUEST, payload);
    },
    
    async [GET_MINIMAL_PAYOUT] ({ commit }, filters): Promise<void> {
      const minimalPayout = await PayoutRequestService.getMinimalPayout({
        ...filters
      });
      commit(SET_MINIMAL_PAYOUT, minimalPayout);
    },
  
    async [CREATE_PAYOUT_REQUEST] ({ state: { data }, commit, dispatch, rootGetters }, { moduleNamespace, ...payload }): Promise<void> {
      const { newPayoutValue, creatorComment, goal, reason, webmasterRate } = data;
      const { data: { createPayoutRequest } } = await PayoutRequestService.createPayoutRequest({
        ...payload,
        newPayoutValue,
        creatorComment,
        goal,
        reason,
        webmasterRate
      },
      rootGetters["admin/capacityAndBumps/logRequest/isShowCommission"]
      );
      
      commit(UPDATE_PAYOUT_REQUEST, createPayoutRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, { moduleNamespace, ...payload });
    },
    
    async [EDIT_PAYOUT_REQUEST] ({ state: { data }, commit, dispatch, rootGetters }, params): Promise<void> {
      const { newPayoutValue, creatorComment, webmasterRate, goal, reason, id } = data;
      const { data: { editPayoutRequest } } = await PayoutRequestService.editPayoutRequest(
        {
          creatorComment,
          newPayoutValue,
          webmasterRate,
          reason,
          goal
        },
        id as string,
        rootGetters["admin/capacityAndBumps/logRequest/isShowCommission"]
      );
      
      commit(UPDATE_PAYOUT_REQUEST, editPayoutRequest);
  
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [DELETE_PAYOUT_REQUEST] ({ state: { data: { id } }, commit, dispatch }, params): Promise<void> {
      await PayoutRequestService.deletePayoutRequest(id as string);
      
      commit(SET_PAYOUT_REQUEST, { data: null });
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
  
    async [APPROVE_PAYOUT_REQUEST] ({ state: { data }, commit, dispatch, rootGetters }, params): Promise<void> {
      const { executorComment, id } = data;
      const { data: { approvePayoutRequest } } = await PayoutRequestService.approvePayoutRequest(
        {
          executorComment
        },
        id as string,
        rootGetters["admin/capacityAndBumps/logRequest/isShowCommission"]
      );
      
      commit(UPDATE_PAYOUT_REQUEST, approvePayoutRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [REJECT_PAYOUT_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { executorComment, id } = data;
      const { data: { rejectPayoutRequest } } = await PayoutRequestService.rejectPayoutRequest(
        {
          executorComment
        },
        id as string
      );
  
      commit(UPDATE_PAYOUT_REQUEST, rejectPayoutRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [ACTUALIZE_PAYOUT_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { executorComment, id } = data;
      const { data: { actualizePayoutRequest } } = await PayoutRequestService.actualizePayoutRequest(
        {
          executorComment
        },
        id as string
      );
  
      commit(UPDATE_PAYOUT_REQUEST, actualizePayoutRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
  
    [UPDATE_SUBSECTION_REQUEST] ({ dispatch, getters }, { moduleNamespace, ...payload }): void {
      dispatch(`${ moduleNamespace }/${ UPDATE_SUBSECTION_REQUEST }`, {
        data: getters.getData,
        offerId: payload.offerId,
        webmasterId: payload.webmasterId,
        externalWebmasterId: payload.externalWebmasterId,
        moduleName: "payoutRequest"
      } as UpdateSubsectionRequestDTO, { root: true });
    }
  };
  
  private readonly initState: () => PayoutRequestState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): PayoutRequestState => {
      return {
        minimalPayout: {},
        data: {
          newPayoutValue: null,
          oldPayoutValue: null,
          creatorComment: null,
          executorComment: null,
          status: null,
          createdAt: null,
          updatedAt: null,
          goal: null,
          reason: null,
          externalWebmaster: null,
          webmasterRate: null,
          id: null
        }
      };
    };
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
  
    this.state = this.initState();
  
    return this;
  }
}

export const payoutRequest = new PayoutRequest();