export default [
  {
    path: "flows",
    name: "flows",
    redirect: { name: "webmaster:flows:flows" },
    children: [
      {
        path: "",
        component: (): Promise<any> => import(/* webpackChunkName: "webmaster:flows" */ "@/views/Webmaster/FlowsView.vue"),
        children: [
          {
            path: "",
            name: "flows",
            component: (): Promise<any> =>
              import(/* webpackChunkName: "webmaster:flows:flows" */ "@/components/Webmaster/Flows/Flows.vue")
          }
        ]
      },
      {
        path: "create",
        name: "create",
        props: true,
        component: (): Promise<any> => import(/* webpackChunkName: "webmaster:flow:create" */ "@/components/Webmaster/Flow/FlowForm.vue")
      },
      {
        path: "edit",
        name: "edit",
        props: true,
        component: (): Promise<any> => import(/* webpackChunkName: "webmaster:flow:edit" */ "@/components/Webmaster/Flow/FlowForm.vue")
      }
    ]
  }
];
