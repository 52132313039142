import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function offerByIntIdGenerator (intId: string): ASTGeneratedOutput {
  const { isShowCommissions } = useOffers();

  const params = {
    exclude: !isShowCommissions ? [
      "finance/webmasterReward/value",
      "finance/webmasterReward/currency"
    ] : []
  };

  const gqlObject = {
    operation: "offerByIntId",

    fields: [
      "type",
      "id",
      "slug",
      "isLaunched",
      "vertical",
      {
        finance: [
          "withdrawType",
          "subGoalType",
          {
            webmasterReward: [
              "value",
              "currency"
            ]
          }
        ]
      },
      { logo: ["url"] },
      { previewLogo: ["url"] },
      { detail: ["name"] },
      { countries: ["name", "alpha2", "alpha3", "id"] },
      { categories: ["name"] }
    ],

    variables: {
      intId: {
        type: "Int!",
        value: intId
      }
    }
  };

  return Generator.query(gqlObject, params);
}