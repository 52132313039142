import Service from "@core/services/common/Service";
// import { LanguagesListResponse } from "@/store/types/admin/lists/LanguagesListState";
import axios, { AxiosPromise } from "axios";

export class CountriesListService extends Service {
  public static async getCountriesList (/*
    limit: number,
    offset: number
  */): Promise<AxiosPromise> {
    try {
      return await axios.get("/dictionaries/countries"/*, {
        params: {
          limit: 25,
          offset: 0
        }
      }*/);
    } catch (e) {
      throw e;
    }
  }
}
