// export const isAllow = (A, B) => isEqual(intersection(A, B), minBy([A, B], "length"));
const arrify = a => Array.isArray(a) ? a : [a];
export const isAllow = (A, B) => A.some(el => B.includes(el));
export const isAllowStrict = (A, B) => A.every(el => B.includes(el));

export const hasPermissions = (permissions, userPermissions) => {
  if (
    permissions && permissions.includes("ANY") ||
    permissions && permissions.includes("SUPER.PERMISSION") &&
    userPermissions && userPermissions.includes("SUPER.PERMISSION")
  ) {
    return true;
  }
  
  return !(
    Array.isArray(permissions) && permissions.length === 0 ||
    ["", 0, null, NaN, false].includes(permissions) ||
    permissions && userPermissions &&
    !isAllow(arrify(permissions), userPermissions)
  );
};

export const hasPermissionsStrict = (permissions, userPermissions) => {
  if (
    permissions && permissions.includes("ANY") ||
    permissions && permissions.includes("SUPER.PERMISSION") &&
    userPermissions && userPermissions.includes("SUPER.PERMISSION")
  ) {
    return true;
  }
  
  return !(
    Array.isArray(permissions) && permissions.length === 0 ||
    ["", 0, null, NaN, false].includes(permissions) ||
    permissions && userPermissions &&
    !isAllowStrict(arrify(permissions), userPermissions)
  );
};
