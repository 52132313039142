import { defineStore } from "pinia";
import { BadgesService } from "@core/services/common/badges/BadgesService";
import { BadgesCount, BadgesListFilters } from "@core/store/types/common/badges";
import badgesList from "@core/services/common/badges/datasets/badgesList.json";
import { reactive, ref, toRefs, watch } from "@vue/composition-api";
import { usePermissions } from "@/stores/common/auth/permissionsStore";

export function useBadges (operations = Object.keys(badgesList)) {
  return defineStore("badges", () => {
    const permissionsStore = usePermissions();
    const stackOperations: BadgesCount & { [key: string]: number } = reactive(operations.reduce((acc, operation) => ({ ...acc, [operation]: 0 }), {}));
    const operationKeys = ref<string[]>([]);

    async function GET_BADGES () {
      try {
        const data = await BadgesService.getBadges(operationKeys.value);

        operationKeys.value.forEach(operation => {
          stackOperations[operation] = data[operation].count;
        });
      } catch (e) {
        throw e;
      }
    }

    function updateOperations () {
      const operations: string[] = [];
      Object.keys(badgesList).forEach((value) => {
        const { permissions } = badgesList[value as keyof BadgesListFilters];

        if (typeof permissions[0] === "string") {
          if (permissionsStore.hasPermissions(permissions as string[])) {
            operations.push(value);
          }
        } else {
          permissions.forEach((p, idx) => {
            if (!permissions[idx - 1]) return;
            if (permissionsStore.hasPermissions(permissions[idx - 1]) &&
                permissionsStore.hasPermissions(p)) {
              operations.push(value);
            }
          });
        }
        operationKeys.value = operations;
      });
    }

    function $reset () {
      Object.keys(badgesList).forEach(operation => {
        stackOperations[operation] = 0;
      });
      operationKeys.value = [];
    }

    if (permissionsStore.userPermissions !== null) {
      watch(() => JSON.stringify(permissionsStore.userPermissions), () => {
        updateOperations();
      }, { immediate: true });
    }

    return {
      ...toRefs(stackOperations),
      GET_BADGES,
      $reset
    };
  })();
}