<template>
    <form
        class="content"
        @submit.prevent="sendNewPassword">
        <!--suppress HtmlUnknownTarget-->
        <img
            :alt="$saas.name"
            class="form-logo"
            :class="{'pb-0 pl-4': !$saas.main}"
            :style="{'height': !$saas.main && '100px'}"
            :src="require(`@/assets/${ $saas.logo }`)">
  
        <h3 class="title is-4 has-text-centered">
            {{ $t(`common.auth.resetPassword.title`) }}
        </h3>
      
        <div class="columns is-multiline mb-0">
            <EditUserPasswordField
                v-model="newPassword"
                :message="$t('common.validation.password')"
                class="column is-12"
                :placeholder="$t(`common.auth.signIn.component.passwordResetWithToken.body.labels.newPassword`)">
            </EditUserPasswordField>

            <EditUserPasswordField
                v-model="newPasswordConfirmation"
                class="column is-12"
                :message="newPasswordError"
                :type="typeIsDanger"
                :placeholder="$t(`common.auth.signIn.component.passwordResetWithToken.body.labels.newPasswordConfirmation`)">
            </EditUserPasswordField>
            <div class="column is-12">
                <b-button
                    :disabled="!newPasswordIsValid"
                    class="is-success "
                    expanded
                    native-type="submit"
                    rounded>
                    {{ $t(`common.auth.signIn.component.passwordResetWithToken.body.buttons.send`) }}
                </b-button>
            </div>
        </div>
      
        <b-button
            tag="router-link"
            type="is-text"
            :to="{ name: 'auth:signIn' }"
            class="form-subbutton mt-6">
            {{ $t(`common.auth.signIn.signIn`) }}
        </b-button>
    </form>
</template>

<script>
  import { SEND_NEW_PASSWORD } from "@core/store/action-constants";
  import { mapActions } from "vuex";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";

  export default {
    name: "NewPassword",
    components: {
      EditUserPasswordField
    },
    data () {
      return {
        token: null,
        newPassword: null,
        newPasswordConfirmation: null
      };
    },
    computed: {
      newPasswordIsValid () {
        return this.newPassword == null || !!(this.newPassword && this.newPassword === this.newPasswordConfirmation);
      },

      newPasswordError () {
        return this.newPasswordIsValid ?
          undefined : this.$t("common.auth.signIn.component.passwordResetWithToken.body.errors.newPasswordError");
      },

      typeIsDanger () {
        return this.newPasswordIsValid ? undefined : "is-danger";
      }
    },
    methods: {
      ...mapActions([
        SEND_NEW_PASSWORD
      ]),

      async sendNewPassword () {
        const data = await this.SEND_NEW_PASSWORD({
          password: this.newPassword === this.newPasswordConfirmation ? this.newPassword : null,
          token: this.$route.query.token
        });

        if (data) await this.$router.push({
          name: "auth:signIn"
        }).catch(_ => {});
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
