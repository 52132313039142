<template>
    <div class="balance">
        <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            position="is-bottom-right"
            @active-change="isActiveBalanceModal = $event">
            <template #trigger>
                <div class="is-flex is-align-items-center">
                    <div class="is-flex is-flex-direction-column">
                        <small class="balance-prefix">
                            {{ $t("common.navBar.balance") }}:
                        </small>
                        <b-skeleton
                            v-if="isLoading || isLoadingBalance || isLoadingPreferredCurrency"
                            height="24px"
                            animated>
                        </b-skeleton>
                        <span
                            v-else
                            class="has-text-weight-bold">
                            {{ fixedCurrency(preferredBalance) }}
                        </span>
                    </div>
                    <b-icon
                        :icon="isActiveBalanceModal ? 'chevron-up' : 'chevron-down'"
                        class="ml-1">
                    </b-icon>
                </div>
            </template>

            <div class="balance-wrapper">
                <div class="balance-content">
                    <span>
                        {{ $t(`common.navBar.currencies`) }}
                    </span>

                    <span class="balance-update pl-3">
                        {{ $t("common.navBar.updated", { message: updatedMessage }) }}
                    </span>

                    <b-button
                        class="balance-refresh"
                        @click="refreshBalance">
                        <InlineSvg
                            :class="{ 'is-loading': isLoadingBalance || isLoadingPreferredCurrency }"
                            :src="refresh.icon"
                            :alt="refresh.label"
                            :aria-label="refresh.label">
                        </InlineSvg>
                    </b-button>
                </div>

                <div class="balance-content is-flex-direction-column is-align-items-flex-start">
                    <div
                        :key="balance.currency"
                        v-for="(balance, idx) of balanceList"
                        class="balance-row">
                        <span class="balance-row-currency">
                            {{ fixedCurrency(balance) }}
                        </span>
                        <b-button
                            v-if="balance.currency !== preferredCurrency"
                            outlined
                            class="balance-row-label"
                            :loading="isLoadingPreferredCurrency && balanceIndex === idx"
                            type="is-info"
                            @click="changePreferredCurrency(balance.currency, idx)">
                            {{ $t(`common.navBar.makeMainCurrency`) }}
                        </b-button>
                        <span
                            v-else
                            class="balance-row-label px-4 is-clickable-default">
                            {{ $t(`common.navBar.mainCurrency`) }}
                        </span>
                    </div>
                </div>

                <div class="balance-content flex is-flex-direction-column">
                    <button
                        v-if="role === 'advertiser' || role === 'webmaster' && !isDomestic"
                        class="balance-refill"
                        @click="isActiveModal = true">
                        <template v-if="role === 'advertiser'">
                            {{ $t("advertiser.navBar.balanceRefill") }}
                        </template>
                        <template v-else-if="role === 'webmaster'">
                            {{ $t("webmaster.navBar.orderPayouts") }}
                        </template>
                    </button>

                    <b-button
                        v-if="role === 'webmaster' && !isDomestic"
                        class="balance-refill has-text-white mt-3"
                        style="font-size: 14px"
                        type="is-success"
                        size="is-small"
                        @click="openAutoPaymentsListModal">
                        {{ $t("webmaster.finances.autoPaymentsListModal.header.title") }}
                    </b-button>
                </div>

                <div
                    v-if="role === 'webmaster' && $saas.features.referrals"
                    class="balance-referrals">
                    <p class="flex is-justify-content-space-between">
                        <span class="balance-referrals_title">
                            {{ $t("webmaster.navBar.partners.referrals.title") }}
                        </span>
                        <span
                            v-if="hasReferralBonus"
                            class="balance-referrals_update balance-update">
                            {{ $t("webmaster.navBar.partners.referrals.update") }}
                        </span>
                    </p>
                    <template v-if="hasReferralBonus">
                        <div
                            :key="balance.balance.currency"
                            v-for="balance of referralBonus"
                            class="balance-row">
                            <span class="balance-row-currency">
                                {{ fixedCurrency(balance.balance) }}
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <p class="balance-referrals_empty mt-2">
                            <i18n path="webmaster.navBar.partners.referrals.description">
                                <template #start>
                                    <b>5%</b>
                                </template>
                                <template #end>
                                    <b>1%</b>
                                </template>
                            </i18n>
                        </p>
                    </template>
                    <CustomInput
                        :value="referralLink"
                        :label="$t('webmaster.navBar.partners.referrals.input')"
                        class="mt-4"
                        copy
                        expanded
                        readonly>
                    </CustomInput>
                </div>
            </div>
        </b-dropdown>

        <RefillModal
            v-if="role === 'advertiser'"
            :is-active.sync="isActiveModal">
        </RefillModal>
        <FinanceOrder
            v-if="role === 'webmaster' && !isDomestic"
            :is-active.sync="isActiveModal">
        </FinanceOrder>
    </div>
</template>
<script>
  import { useFinanceAutoPaymentsListModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsListModalStore";
  import { momentFrom } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import {
    GET_ADVERTISER_BALANCE,
    GET_WEBMASTER_BALANCE,
    UPDATE_PREFERRED_CURRENCY
  } from "@core/store/action-constants";
  import RefillModal from "@/components/Advertiser/NavigationBar/RefillModal";
  import FinanceOrder from "@/components/Webmaster/Finance/FinanceOrder";
  import CustomInput from "@/components/Common/Select/CustomInput.vue";
  import InlineSvg from "vue-inline-svg";
  import refresh from "@/assets/NavigationBar/refresh.svg";
  import { fixedCurrency } from "@core/flowMethods";
  import { storeToRefs } from "pinia";

  export default {
    name: "Balance",
    components: {
      FinanceOrder,
      RefillModal,
      CustomInput,
      InlineSvg
    },
    props: {
      role: {
        type: String,
        required: true
      }
    },

    created () {
      this.instanceBalanceList = this.$watch("currencyList", () => {
        this.refreshBalance();
      }, { immediate: true });

      this.balanceInterval = setInterval(() => {
        this.refreshBalance();
      }, 5 * 60 * 1000);
    },

    data () {
      return {
        updatedMessage: null,
        reload: new Date(),
        messageInterval: null,
        balanceInterval: null,
        isLoading: true,
        isLoadingBalance: false,
        isActiveModal: false,
        isActiveBalanceModal: false,
        balanceIndex: null,
        instanceBalanceList: null
      };
    },

    computed: {
      ...mapState({
        balanceList: ({ balance }) => balance.balance,
        referralBonus: ({ balance }) => balance.referralBonus,
        userIntId: ({ webmaster }) => webmaster.profile.user.intId,
        preferredCurrency: ({ auth }) => auth.preferredCurrency,
        currencyList: ({ auth }) => auth?.availableCurrencies,
        isDomestic: ({ webmaster }) => webmaster.profile.user.detail?.isDomestic
      }),

      referralLink () {
        return `${ process.env.VUE_APP_REFERRALS_LINK }/${ this.userIntId }`;
      },

      preferredBalance () {
        return this.balanceList.find(({ currency }) => currency === this.preferredCurrency);
      },

      refresh () {
        return {
          icon: refresh,
          label: this.$t("webmaster.navBar.refresh")
        };
      },

      isLoadingPreferredCurrency () {
        return this.$wait(UPDATE_PREFERRED_CURRENCY);
      },

      hasReferralBonus () {
        return this.referralBonus?.some(el => Number(el.balance.value) > 0);
      }
    },

    methods: {
      ...mapActions({
        GET_WEBMASTER_BALANCE,
        GET_ADVERTISER_BALANCE,
        UPDATE_PREFERRED_CURRENCY
      }),

      openAutoPaymentsListModal () {
        const store = useFinanceAutoPaymentsListModal();
        const { isActive } = storeToRefs(store);
        isActive.value = true;
      },

      changePreferredCurrency (newPreferredCurrency, idx) {
        if (
          newPreferredCurrency !== this.preferredCurrency &&
          !this.isLoadingPreferredCurrency
        ) {
          this.balanceIndex = idx;
          this.UPDATE_PREFERRED_CURRENCY(newPreferredCurrency);
        }
      },

      async refreshBalance () {
        let response;
        this.isLoadingBalance = true;

        if (this.role === "webmaster") {
          response = await this.GET_WEBMASTER_BALANCE();
        } else if (this.role === "advertiser") {
          response = await this.GET_ADVERTISER_BALANCE();
        }

        if (response) {
          this.updateMessage();
          this.isLoading = false;
        }

        this.isLoadingBalance = false;
      },

      updateMessage () {
        clearInterval(this.messageInterval);

        this.reload = new Date();
        this.updatedMessage = momentFrom(this.reload);

        this.messageInterval = setInterval(() => {
          this.updatedMessage = momentFrom(this.reload);
        }, 1000);
      },

      fixedCurrency
    },

    beforeDestroy () {
      clearInterval(this.messageInterval);
      clearInterval(this.balanceInterval);
      this.instanceBalanceList();
    }
  };
</script>

<style lang="scss">
  .has-navbar-fixed-top {
    padding-top: $navbar-height + 1rem !important;
  }
</style>

<style lang="scss" scoped>
    @import "../../../scss/colors";

    $border-color-light: transparentize($light, 0.7);

    ::v-deep {
        .button.is-info.is-outlined {
            &:focus {
                background-color: $info-light;
                border-color: transparent;
            }
        }

        .dropdown.is-mobile-modal > .dropdown-menu {
            z-index: 40 !important;
        }
    }

    .balance {
        $self: &;

        display: flex;
        position: relative;
        align-items: center;

        &-wrapper {
            width: 335px;
            display: flex;
            flex-direction: column;
            padding: 1.25rem;

            #{ $self }-prefix {
                margin-right: 10px;
            }

            #{ $self }-content {
                display: flex;
                align-items: center;
            }

            #{ $self }-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0.75rem 0;
                border-bottom: 1px solid $border-color-light;

                &:last-of-type {
                    border-bottom: none;
                }

                &-currency {
                    font-size: 0.85rem;
                    font-weight: $weight-bold;
                }

                &-label {
                    font-size: $size-7;

                    &.is-info {
                        color: $info;
                    }
                }
            }

            #{ $self }-number {
                cursor: help;
                display: inline-flex !important;
                align-items: center;
                margin: 0;

                &:after {
                    content: "\f021";
                    font-size: 60%;
                    opacity: 0;
                    visibility: hidden;
                    display: inline-block;
                    vertical-align: middle;
                    transform: translateX(15px);
                    transition: transform 300ms, opacity 300ms, visibility 300ms;
                    font-family: "Font Awesome 5 Free";
                }

                &:hover {
                    color: $info;

                    &:after {
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(5px);
                    }
                }
            }

            #{ $self }-update {
                display: block;
                font-size: 12px;
                color: #b9b9b9;
                white-space: nowrap;
                flex-grow: 1;
            }

            #{ $self }-referrals {
                margin-top: 1.3rem;

                &_title {
                    font-weight: 600;
                    font-size: 0.94rem;
                    line-height: 18px;
                }

                &_update {
                    flex-grow: 0;
                }

                &_empty {
                    padding-bottom: .6rem;

                    font-size: .87rem;
                    border-bottom: 1px solid $border-color-light;
                }
            }

            #{ $self }-refresh {
                background-color: $info;
                color: $white;
                padding: 0;
                width: $size-4;
                height: $size-4;
                font-size: 12px;
                border-radius: 0.25rem;
                transform: translateX(5px) rotateZ(360deg * 0);

                &:focus {
                    border: none;
                }

                svg {

                    &.is-loading {
                        animation: 2s rotate infinite linear;

                        @keyframes rotate {
                            0% {
                                color: darken($info, 15);
                                transform: rotateZ(360deg * 0);
                            }

                            25% {
                                color: darken($info, 15);
                                transform: rotateZ(360deg * 0.25);
                            }

                            50% {
                                color: $info;
                                transform: rotateZ(360deg * 0.5);
                            }

                            75% {
                                color: $info;
                                transform: rotateZ(360deg * 0.75);
                            }

                            100% {
                                color: darken($info, 15);
                                transform: rotateZ(360deg * 1);
                            }
                        }
                    }
                }

                ::v-deep {
                    span {
                        display: flex;
                    }
                }
            }
        }

        &-refill {
            height: 44px;
            width: 100%;

            font-family: inherit;
            color: #ffffff;
            border: none;
            outline: none;

            background: $info;
            border-radius: 5px;
            cursor: pointer;
        }

        &-update {
            font-size: 12px;
            color: $light;
        }

        ::v-deep {
            .dropdown {
                &-content {
                    padding: 0;
                }
            }

            .b-skeleton {
                min-width: 70px;
                width: 100%;
            }
        }
    }
</style>
