import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { DesignersListResponse } from "@core/store/types/admin/lists/designersListState";
import landingDesigners from "@core/services/admin/lists/graphql/landingDesigners.graphql";

export class DesignersListService extends Service {
  public static getDesignersList (): AxiosPromise<DesignersListResponse> {
    return this.api.get("", {
      params: {
        query: landingDesigners
      }
    });
  }
}