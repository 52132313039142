import { OfferLandingsService } from "@core/services/admin/offer/OfferLandingsService";
import {
  GET_OFFER_LANDINGS_READY,
  UPDATE_MANUAL_LANDINGS,
  ADD_OFFER_CONVERSION,
  SET_EMPTY, GET_LANDINGS
} from "@core/store/action-constants";
import {
  SET_OFFER_LANDINGS_READY,
  SET_OFFER_LANDINGS_ID,
  UPDATE_OFFER_LANDINGS,
  SET_OFFER_LANDINGS,
  UPDATE_PAGINATION,
  REMOVE_PAGE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { OfferLanding } from "@core/store/logic/common/offer/OfferLanding";
import { Landing, OfferLandingsFilters, OfferLandingsState } from "@core/store/types/admin/offer/OfferLandings";
import { OfferConversionList } from "@core/store/types/admin/offer/lists/offerConversionList";
import { OfferService } from "@core/services/common/offer/OfferService";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import _unionBy from "lodash/unionBy";

export class OfferLandingsList extends OfferLanding<OfferLandingsState, RootState> {
  private readonly initState: () => OfferLandingsState;

  constructor (pageType: OfferLandingsFilters["pageType"], isReady?: OfferLandingsFilters["isReady"]) {
    super();

    this.namespaced = true;

    this.initState = (): OfferLandingsState => {
      return {
        offerId: null,
        landings: {
          items: [],
          count: 0
        },
        landingsReady: {
          items: [],
          count: 0
        },
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {
          pageType,
          isReady
        }
      };
    };

    this.state(this.initState());

    this.getters({
      offerConversion: (state, getters, rootState) => rootState.admin.offer.offerConversionList.offerConversion
    });

    this.mutations({
      [SET_EMPTY]: (state: OfferLandingsState): void => {
        Object.assign(state, this.initState());
      },

      [SET_OFFER_LANDINGS_ID] (state: OfferLandingsState, id: string): void {
        state.offerId = id;
      },

      [SET_OFFER_LANDINGS_READY] (state: OfferLandingsState, landingsReady: boolean): void {
        state.landingsReady = landingsReady;
      },

      [UPDATE_OFFER_LANDINGS] (state, payload) {
        state.landings = {
          count: payload.count,
          items: _unionBy(payload.items, state.landings.items, "id")
        };
      },

      ...stateUpdater.mutations
    });

    this.actions({
      async GET_LANDINGS ({ state, commit, dispatch, rootGetters }, offerId) {
        const { page, perPage } = state.pagination;
        const limit = perPage;
        const offset = (page - 1) * perPage;
        const { pageType, ...fields } = state.filters;
        const filters = {
          ...fields,
          offerId,
          pageType: pageType?.toUpperCase()
        };

        if (offerId) {
          const { data: { landingPages } } = await OfferService.getLandingPages({
            limit,
            offset,
            filters
          },
          rootGetters.role);

          if (page === 1) {
            commit(SET_OFFER_LANDINGS, landingPages);
          } else {
            commit(UPDATE_OFFER_LANDINGS, landingPages);
            dispatch(UPDATE_OFFER_LANDINGS);
          }
        }
      },

      async [GET_OFFER_LANDINGS_READY] ({ commit, rootGetters }, offerId: string): Promise<void> {
        if (offerId) {
          const filters = {
            pageType: "LANDING" as OfferLandingsFilters["pageType"],
            isReady: true as OfferLandingsFilters["isReady"],
            offerId
          };
          const { data: { landingPages } } =
              await OfferService.getLandingPages({ filters }, rootGetters.role);

          commit(SET_OFFER_LANDINGS_READY, landingPages);
        }
      },

      [ADD_OFFER_CONVERSION] ({ state }, { conversion, landings }) {
        const pageType = state.filters.pageType?.toUpperCase();

        return landings.items.map((el: Landing) => {
          const { cr, isNew } = conversion.find((item: OfferConversionList) =>
            item.pageType === pageType && item.pageId === el.id) ?? {};

          return {
            ...el,
            cr,
            isNew
          };
        });
      },

      async [UPDATE_MANUAL_LANDINGS] ({ state, dispatch }, offerId) {
        const { page, perPage } = { ...state.pagination } as OfferLandingsState;

        try {
          dispatch(UPDATE_PAGINATION, { page: 1, perPage: perPage * page });
          if (offerId) {
            await dispatch(GET_LANDINGS, offerId);
          }
        } finally {
          dispatch(UPDATE_PAGINATION, { page, perPage });
        }
      },

      async [REMOVE_PAGE] ({ commit }, id) {
        const data = await OfferLandingsService.removePage(id);
        commit("LOCAL_DELETE", { items: data, target: "landings" });
      },

      async [UPDATE_OFFER_LANDINGS] ({ state, commit, dispatch, getters }) {
        const landings = state.landings;
        const items = await dispatch(ADD_OFFER_CONVERSION, { conversion: getters.offerConversion, landings });

        commit(SET_OFFER_LANDINGS, { count: landings.count, items });
      }
    });
    
    return this;
  }
}
