import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function dashboardStatisticGenerator (
  filters: BaseFiltersInput,
  groups: string[],
  isAgent: boolean
): ASTGeneratedOutput {
  const params = {
    include: isAgent ? ["items/moneyAgentCommission"] : []
  };

  const gqlObject = {
    operation: "statistics",

    fields: [
      {
        items: [
          "countLeadsSpamByAdvertiser",
          "conversionCleanApprove",
          "moneyWebmasterAccrued",
          "countLeadsAccepted",
          "countLeadsWhite",
          "currency",
          "day"
        ]
      }
    ],

    variables: {
      filters: {
        type: "StatisticsCriteriaFilters",
        value: filters
      },
      groups: {
        type: "[StatisticsGroupsEnum!]",
        value: groups
      }
    }
  };

  return Generator.query(gqlObject, params);
}