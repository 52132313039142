import store from "../../../../../../store";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import { StatisticResponse } from "@core/store/types/common/statistic/common/statistic";

export function currencyMapper (array?: Array<{ currency: string }>) {
  const defaultCurrency = sortByPreferredCurrency(store.state.auth.availableCurrencies)?.map(currency => ({ currency, value: 0 }));

  return defaultCurrency?.map(item => {
    const findElement = array?.find(el => el.currency === item.currency);
    if (findElement) {
      return findElement;
    } return item;
  }) as StatisticResponse["items"];
}