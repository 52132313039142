<template>
    <b-field
        :message="message"
        :label="label"
        :type="type">
        <LInput
            :value="value"
            type="password"
            :placeholder="placeholder"
            :required="required"
            :pattern="pattern"
            :minlength="minlength"
            @input="updateValue">
        </LInput>
    </b-field>
</template>

<script>
  import { PASSWORD_VALIDATOR } from "@core/validators";

  export default {
    name: "EditUserPasswordField",
    props: {
      value: {
        type: String,
        default: ""
      },
      placeholder: {
        type: String,
        default: " "
      },
      minlength: {
        type: Number,
        default: 6
      },

      pattern: {
        type: String,
        default: PASSWORD_VALIDATOR
      },

      required: {
        type: Boolean,
        default: true
      },
      
      message: {
        type: String,
        default: null
      },

      type: {
        type: String,
        default: ""
      },

      label: {
        type: String,
        default: null
      }
    },
    methods: {
      updateValue (value) {
        this.$emit("input", value);
      }
    }
  };
</script>

<style scoped></style>