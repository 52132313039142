import { GET_CATEGORIES } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_CATEGORIES } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { CategoryState } from "@core/store/types/common/CategoriesFilters";
import { CategoriesListService } from "../../../../services/common/lists/CategoriesListService";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const initialState = (): CategoryState => {
  return {
    data: {}
  };
};

const state: () => CategoryState = initialState;

const getters: GetterTree<CategoryState, RootState> = {};

const mutations: MutationTree<CategoryState> = {
  [UPDATE_CATEGORIES] (state, data: CategoryState["data"]) {
    state.data = data;
  },

  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<CategoryState, RootState> = {
  async [GET_CATEGORIES] ({ commit }, filters: { vertical: VerticalsEnum[]; }) {
    const data = await CategoriesListService.getCategoriesList(filters);
    commit(UPDATE_CATEGORIES, { items: data, count: data.length });
  },

  [SET_EMPTY]: ({ commit }) => {
    commit(SET_EMPTY);
  }
};

export const categoriesFilters: Module<CategoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
