
import store from "@/store";
import router from "@/router";
import storeInstance from "@/store";
import { onMounted } from "@vue/composition-api";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  onMounted(async () => {
    if (store.getters.isUserLogin) {
      try {
        await storeInstance.dispatch("OAUTH2_LOGIN", router.currentRoute.query);
      } catch (e) {
        await router.push({
          name: "dashboard"
        });
      }
    } else {
      await router.push({
        name: "auth:signIn",
        query: router.currentRoute.query
      });
    }
  });
  return {};
};
export default __sfc_main;
