import Service from "@core/services/common/Service";
import { WebmasterDataByIdResponse } from "@core/store/types/common/webmasterDataById";
import webmasterById from "@core/services/common/WebmasterId.generator";
import editWebmasterById from "@core/services/common/graphql/editWebmasterById.graphql";
import { EditInput, EditResponse } from "@core/store/types/admin/users/webmasters/Edit";

export class WebmasterByIdService extends Service {
  public static async getWebmasterById (webmasterId: string, isFullCardProfile: boolean): Promise<WebmasterDataByIdResponse> {
    try {
      return await this.api.get("", {
        params: webmasterById(
          webmasterId,
          isFullCardProfile
        )
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async editWebmasterById (webmasterId: string, input: EditInput): Promise<EditResponse> {
    try {
      const { data: { editWebmaster } } = await this.api.post("", {
        query: editWebmasterById,
        variables: {
          webmasterId,
          input
        }
      });
      
      return editWebmaster;
    } catch (e) {
      throw e;
    }
  }
}
