import Service from "@core/services/common/Service";
import advertisersDuty from "@core/services/admin/finances/advertisersDuty/graphql/advertisersDuty.graphql";
import advertisersDutyAnalyse from "@core/services/admin/finances/advertisersDuty/graphql/advertisersDutyAnalyse.graphql";
import { AdvertisersDutyAnalyseGroup } from "@core/store/types/admin/finances/advertisersDuty/enums/advertisersDutyEnums";
import {
  AdvertisersDutyAnalyseData,
  AdvertisersDutyAnalyseFilters,
  AdvertisersDutyAnalyseFiltersResponse
} from "@core/store/types/admin/finances/advertisersDuty/advertisersDutyAnalyse";
import {
  AdvertisersDutyFiltersResponse,
  AdvertisersDutyState
} from "@core/store/types/admin/finances/advertisersDuty/advertisersDuty";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";

export class AdvertisersDutyService extends Service {
  public static async getAdvertisersDuty (
    filters: AdvertisersDutyFiltersResponse,
    limit: number,
    offset: number,
    sort: AdvertisersDutyState["sort"],
    order: AdvertisersDutyState["order"],
    groups: string[] | undefined,
    currency: CurrencyEnum
  ): Promise<AdvertisersDutyState["advertisersDuty"]> {
    try {
      const { data: { advertisersDuty: data } } = await this.api.get("", {
        params: {
          query: advertisersDuty,
          variables: {
            filters,
            limit,
            offset,
            sort,
            order,
            groups,
            currency
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getAdvertisersDutyAnalyse (
    filters: AdvertisersDutyAnalyseFiltersResponse,
    advertiserId: AdvertisersDutyAnalyseFilters["advertiserId"],
    group: AdvertisersDutyAnalyseGroup,
    currency: CurrencyEnum
  ): Promise<AdvertisersDutyAnalyseData[]> {
    try {
      const { data: { advertisersDutyAnalyse: data } } = await this.api.get("", {
        params: {
          query: advertisersDutyAnalyse,
          variables: {
            filters,
            advertiserId,
            group,
            currency
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}
