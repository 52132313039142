import Service from "@core/services/common/Service";
import subAccountsGql from "@core/services/webmaster/subAccounts/graphql/subAccounts.graphql";
import inviteWebmasterGql from "@core/services/webmaster/subAccounts/graphql/inviteWebmaster.graphql";
import { SubAccountsFilters } from "@core/store/types/webmaster/subAccounts";
import { AxiosPromise } from "axios";

export class SubAccountsService extends Service {
  public static async getSubAccounts (
    limit: number,
    offset: number,
    filters: SubAccountsFilters
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: subAccountsGql,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async inviteWebmaster (
    email: string
  ): Promise<void> {
    try {
      await this.api.get("", {
        params: {
          query: inviteWebmasterGql,
          variables: {
            email
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
