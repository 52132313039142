import { RootState } from "@core/store/root-state";
import { offerEditExternalIndividualRatesModal } from "./offerEditExternalIndividualRatesModal";
import { AdvertisersIndividualRates } from "@core/store/modules/admin/offer/modules/edit/modules/AdvertisersIndividualRates";
import { PartialOfferAdvertiserIndividualRateState } from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";

export class AdvertisersExternalIndividualRates extends AdvertisersIndividualRates<PartialOfferAdvertiserIndividualRateState, RootState> {
  private readonly initState: () => PartialOfferAdvertiserIndividualRateState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): PartialOfferAdvertiserIndividualRateState => {
      return {
        individualRates: {},
        filters: {
          advertiserId: null,
          webmasterId: null,
          hasExternalWebmaster: true
        },
        pagination: {
          page: 1,
          perPage: 25
        }
      };
    };
    this.state(this.initState());
    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      }
    });

    this.modules({
      offerEditExternalIndividualRatesModal
    });

    return this;
  }
}