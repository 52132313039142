import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_SUBSECTION_CAPACITY,
  UPDATE_CAPACITY,
  UPDATE_SUBSECTIONS
} from "@core/store/action-constants";
import {
  SET_SUBSECTION_CAPACITY,
  SET_EMPTY,
  SUBSECTION_CAPACITY_DATA,
  SET_EMPTY_DATA,
  UPDATE_PAGINATION,
  UPDATE_SORTINGS,
  SUBSECTION_CAPACITY_PAGINATION,
  SUBSECTION_CAPACITY_SORT,
  SUBSECTION_CAPACITY_ORDER,
  SET_FILLED_CAPACITY,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import { OffersCapacityData, OffersCapacityState } from "@core/store/types/admin/offers/capacity";
import Vue from "vue";
import _isEmpty from "lodash/isEmpty";
import moment from "moment/moment";

class OffersCapacity implements Module<OffersCapacityState, RootState> {
  public namespaced: boolean;
  public state: OffersCapacityState;
  private readonly initialPagination = {
    page: 1,
    perPage: 20
  }

  public getters: GetterTree<OffersCapacityState, RootState> = {
    [SUBSECTION_CAPACITY_DATA]: state => state.data,
    
    [SUBSECTION_CAPACITY_PAGINATION]: state => state.pagination,
    
    [SUBSECTION_CAPACITY_SORT]: state => state.sort,
    
    [SUBSECTION_CAPACITY_ORDER]: state => state.order
  }

  public mutations: MutationTree<OffersCapacityState> = {
    [SET_SUBSECTION_CAPACITY]: (state, payload): void => {
      let { id, ...data } = payload;
      
      if (!_isEmpty(state.data[id])) {
        data = {
          ...data,
          count: data.count,
          items: state.data[id]?.items?.concat(data.items)
        };
    
        state.data[id] = {};
      }
      
      Vue.set(state.data, id, data);
    },
  
    [UPDATE_CAPACITY]: (state, payload): void => {
      const elem = state.data[payload.user.id]?.items
        ?.find(({ id }) => id === payload.offer.id) as OffersCapacityData;
      
      Vue.set(elem, "softCapacity", payload.softCapacity);
      Vue.set(elem, "updatedAt", moment().toDate());
    },
  
    [UPDATE_SUBSECTIONS]: (state, payload): void => {
      const elem = state.data[payload.userId]?.items
        ?.find(({ id }) => id === payload.id) as OffersCapacityData;
  
      Vue.set(elem, "subsection", payload?.subsection);
    },
  
    [UPDATE_PAGINATION] (state, payload) {
      const { id, ...pagination } = payload;
      Vue.set(state.pagination, id, { ...state.pagination[id], ...pagination });
    },
  
    [UPDATE_SORTINGS] (state, { id, sort }) {
      state.sort[id] = sort.sortField;
      state.order[id] = sort.direction.toUpperCase();
    },
    
    [SET_EMPTY_DATA] (state, id) {
      state.data[id] = {};
    },
    
    [SET_FILLED_CAPACITY] (state, payload) {
      const offer = state.data[payload.advertiserId]?.items?.find(({ id }) => id === payload.id);
      const capacity = offer.filledCapacity - payload.oldCapacity + payload.newCapacity;
      Vue.set(offer, "filledCapacity", capacity);
    },
  
    [UPDATE_FILTERS] (state, filters) {
      state.filters = { ...state.filters, ...filters };
    }
  }


  public actions: ActionTree<OffersCapacityState, RootState> = {
    async [GET_SUBSECTION_CAPACITY] ({ state , commit, rootGetters }, { isRender, id }) {
      const limit = state.pagination[id]?.perPage || 20;
      const offset = limit * ((state.pagination[id]?.page || 1) - 1);
      const { isRequest, isCommon, ...filters } = state.filters;
      
      const offerAdvertisersCapacity = await GqlOffersService.getOffersCapacity(
        limit,
        offset,
        id,
        filters,
        state.sort,
        state.order,
        isRequest ?? false,
        isCommon ?? false,
        rootGetters["admin/capacityAndBumps/capacity/advertisersCapacity/isShowRedemption"]
      );
      
      if (!state.pagination[id] || _isEmpty(state.pagination[id])) {
        commit(UPDATE_PAGINATION, { id, page: 1, perPage: 20 });
      }
      
      if (!isRender && state.sort[id] && state.pagination[id]?.page === 1) {
        commit(SET_EMPTY_DATA, id);
      }
      
      commit(SET_SUBSECTION_CAPACITY, { ...offerAdvertisersCapacity, id });
      return offerAdvertisersCapacity;
    },

    async [UPDATE_CAPACITY] ({ commit }, input) {
      const { data: { setCapacity } } = await GqlOffersService.updateCapacity(input);
      commit(UPDATE_CAPACITY, setCapacity);
    },
  
    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
      commit(UPDATE_PAGINATION, { id: payload.id, page: 1 });
    }
  }

  private readonly initState: () => OffersCapacityState;

  constructor () {
    this.namespaced = true;
    this.initState = (): OffersCapacityState => {
      return {
        data: {},
        pagination: {},
        filters: {},
        sort: {},
        order: {}
      };
    };
  
    this.mutations[SET_EMPTY] = (state, id) => {
      state.data[id] = {};
      state.filters = {};
      state.pagination[id] = this.initialPagination;
      if (state.sort) {
        state.sort[id] = null;
        state.order[id] = null;
      }
    };

    this.state = this.initState();

    return this;
  }
}

export const offersCapacity = new OffersCapacity();