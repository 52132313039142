import Service from "@core/services/common/Service";
import { Response, State } from "@core/store/types/admin/offer/webmasters";
import { AxiosPromise } from "axios";
import webmastersByOffer from "./graphql/webmastersByOffer.graphql";
import webmastersByOfferWithCommission from "./graphql/webmastersByOfferWithCommission.graphql";
import webmastersByOfferAdvertiser from "../../../advertiser/offer/webmasters/graphql/webmastersByOffer.graphql";
import { UserFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export class OfferWebmastersService extends Service {
  public static getOfferWebmasters (
    offerId: UserFiltersInput["offerId"],
    webmasterFilters: State["filters"],
    role: string,
    limit = 25,
    offset = 0
  ): AxiosPromise<Response> {
    // @ts-ignore
    const { webmasterId, ...fields } = webmasterFilters;
    const hasAdvertiser = role === "advertiser";
    const filters = {
      id: webmasterId.length > 0 ? webmasterId : null,
      ...fields
    };
    const { isShowCommissions } = useOffers();

    return this.api.get("", {
      params: {
        query: hasAdvertiser 
          ? webmastersByOfferAdvertiser
          : isShowCommissions ? webmastersByOfferWithCommission : webmastersByOffer,
        variables: {
          limit,
          offset,
          offerId,
          filters
        }
      }
    });
  }
}
