import { FinancesService } from "@core/services/admin/finances/financesIncomes/FinancesIncomesService";
import { CREATE_INCOME, GET_USER_BALANCE } from "@core/store/action-constants";
import {
  SET_ADVERTISER_ID,
  SET_USER_BALANCE,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_OPTIONS,
  UPDATE_SELECTED_WEBMASTERS_BALANCE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialCreatePaymentCostsState } from "@core/store/types/admin/finances/financesCosts/CreatePaymentCostsState";
import { ActionTree, Module, MutationTree } from "vuex";
import { AdvertisersFilters } from "@core/store/modules/common/lists/advertisersList";

const initialState = (): PartialCreatePaymentCostsState => {
  return {
    options: {
      userId: null,
      amount: 0,
      paymentSystemId: null,
      comment: null,
      currency: null
    },
    advertiserId: null,
    isModalActive: false,
    balance: null
  };
};

const state: () => PartialCreatePaymentCostsState = initialState;

const mutations: MutationTree<PartialCreatePaymentCostsState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_OPTIONS] (state, option: any) {
    state.options = { ...state.options, ...option };
  },
  
  [SET_USER_BALANCE] (state, balance) {
    state.balance = balance;
  },
  
  [SET_ADVERTISER_ID] (state, advertiserId) {
    state.advertiserId = advertiserId;
  },
  
  [UPDATE_SELECTED_WEBMASTERS_BALANCE] (state, payload: number) {
    state.selectedWebmasterBalance = payload;
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isModalActive = payload;
  }
};

const actions: ActionTree<PartialCreatePaymentCostsState, RootState> = {
  async [GET_USER_BALANCE] ({ commit }, userId) {
    const { data: { balance } } = await FinancesService.balance(userId);
    commit(SET_USER_BALANCE, balance);
    return balance;
  },
  
  async [CREATE_INCOME] ({ state, commit }) {
    try {
      const addInTransaction = await FinancesService.createIncome(state.options);
      commit("admin/finances/incomes/LOCAL_ADD", { items: addInTransaction, target: "incomes" }, { root: true });
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit(UPDATE_MODAL_ACTIVE, payload);
    } else {
      commit("SET_EMPTY");
      commit("advertisersFilters/SET_EMPTY");
    }
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, option) {
    commit(UPDATE_MODAL_OPTIONS, option);
  }
};

export const createIncomeModal: Module<PartialCreatePaymentCostsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    advertisersFilters: new AdvertisersFilters()
  }
};
