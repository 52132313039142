import {
  GET_HISTORY, UPDATE_MODAL_OPTIONS, GENERATE_NEW_ID
} from "@core/store/action-constants";
import {
  UPDATE_MODAL_ACTIVE,
  UPDATE_HISTORY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { WebmastersIdHistoryModalState } from "@core/store/types/admin/offer/webmasters/WebmastersIdHistoryModalState";
import { WebmastersIdHistoryService } from "@core/services/admin/offer/edit/WebmastersIdHistoryService";

const initialState = (): WebmastersIdHistoryModalState => {
  return {
    history: null,
    isModalActive: false,
    options: {
      offerId: null,
      webmaster: null
    }
  };
};

const state: () => WebmastersIdHistoryModalState = initialState;

const mutations: MutationTree<WebmastersIdHistoryModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_HISTORY] (state, history: WebmastersIdHistoryModalState["history"]) {
    state.history = history;
  },
  
  [UPDATE_MODAL_OPTIONS] (state, options: WebmastersIdHistoryModalState["options"]): void {
    state.options = { ...state.options, ...options };
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isModalActive = payload;
  }
};

const actions: ActionTree<WebmastersIdHistoryModalState, RootState> = {
  async [GET_HISTORY] ({ state, commit }) {
    const { offerId, webmaster: { id } } = state.options;
    if (offerId && id) {
      try {
        const history = await WebmastersIdHistoryService.getHistory(offerId, id);
        commit(UPDATE_HISTORY, history);
      } catch (e) {
        throw e;
      }
    }
  },
  
  async [GENERATE_NEW_ID] ({ state }) {
    const { offerId, webmaster: { id } } = state.options;
    if (offerId && id) {
      try {
        await WebmastersIdHistoryService.generateNewId(offerId, id);
      } catch (e) {
        throw e;
      }
    }
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit("SET_EMPTY");
    commit(UPDATE_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, options: WebmastersIdHistoryModalState["options"]): void {
    commit(UPDATE_MODAL_OPTIONS, options);
  }
};

export const webmastersIdHistoryModal: Module<WebmastersIdHistoryModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {}
};
