import { PiniaPluginContext } from "pinia";
import { set } from "@vue/composition-api";
import _cloneDeep from "lodash/cloneDeep";

export function excludedFiltersPlugin ({ store }: PiniaPluginContext) {
  if (store.$state.hasOwnProperty("excludedFilters")) {
    const excludedFilters = store.$state.excludedFilters;
    const filters = Object.keys(excludedFilters).reduce((acc, key) => {
      if (!key.includes("excluded")) {
        const newKey = key.replace(key, `excluded${ key.replace(/^./, c => c.toUpperCase()) }`);

        set(acc, newKey, excludedFilters[key]);
      }
      return acc;
    }, {});
    set(store.$state, "excludedFilters", _cloneDeep(filters));

    const originalReset = store.$reset.bind(store);

    return {
      $reset () {
        originalReset();
        store.excludedFilters = filters;
      }
    };
  }
}