import { AdvertisersListService } from "@core/services/admin/lists/AdvertisersListService";
import {
  GET_ADVERTISER_BY_ID,
  GET_ADVERTISERS_LIST,
  GET_INITIAL_ADVERTISERS,
  UPDATE_ADVERTISERS_LIST
} from "@core/store/action-constants";
import {
  EMPTY_ADVERTISERS_LIST,
  UPDATE_ADVERTISERS_FILTERS,
  UPDATE_ADVERTISERS_LIST_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  AdvertisersListItem,
  AdvertisersListResponse,
  PartialAdvertisersListState
} from "@core/store/types/admin/lists/AdvertisersListState.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialAdvertisersListState => {
  return {
    data: {},
    filters: {
      id: null,
      loginOrIntId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialAdvertisersListState = initialState;

const getters: GetterTree<PartialAdvertisersListState, RootState> = {
  [GET_ADVERTISER_BY_ID]: state => function (ids: string[]): any {
    return ids?.map(id => state.data?.items?.find((item: { id: string }) => item.id === id));
  }
};

const mutations: MutationTree<PartialAdvertisersListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_ADVERTISERS_LIST] (state, advertisers: AdvertisersListResponse["advertisers"]) {
    if (state.pagination.page !== 1 && state?.data?.items && advertisers) {
      state.data.items = Array.from(new Set([...state.data.items, ...advertisers.items as []]));
    } else {
      state.data = advertisers;
    }
  },

  [UPDATE_ADVERTISERS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_ADVERTISERS_FILTERS] (state, filters: PartialAdvertisersListState["filters"]) {

    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_ADVERTISERS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialAdvertisersListState, RootState> = {
  async [GET_ADVERTISERS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { data: { advertisers } } =
                await AdvertisersListService.getAdvertisersList(
                  {
                    loginOrIntId: state.filters?.loginOrIntId
                  },
                  limit,
                  offset
                );

    commit(UPDATE_ADVERTISERS_LIST, advertisers);
    return advertisers;
  },

  async [GET_INITIAL_ADVERTISERS] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const { data: { advertisers, initialAdvertisers } } = await AdvertisersListService.getAdvertisersList(
      {},
      state.pagination.perPage,
      0,
      { id: ids });
    advertisers.items = [...initialAdvertisers.items, ...advertisers.items as []];

    commit(UPDATE_ADVERTISERS_LIST, advertisers);
    return initialAdvertisers?.items?.length
      ? initialAdvertisers.items.map((user: AdvertisersListItem) => user.id)
      : null;
  }
};

export const advertisersList: Module<PartialAdvertisersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
