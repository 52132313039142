import { CompetitionRouletteModalState } from "@core/store/types/admin/competition";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY, UPDATE_MODAL } from "@core/store/mutation-constants";
import { UPDATE_FORM } from "@core/store/action-constants";

const initialState = (): CompetitionRouletteModalState => {
  return {
    form: {
      question: null
    },
    option: {
      isActive: false
    }
  };
};

const state: () => CompetitionRouletteModalState = initialState;

const mutations: MutationTree<CompetitionRouletteModalState> = {
  [UPDATE_MODAL] (state, payload) {
    state.option = { ...state.option , ...payload };
  },

  [UPDATE_FORM] (state, payload) {
    state.form = { ...state.form , ...payload };
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<CompetitionRouletteModalState, RootState> = {
  [UPDATE_MODAL] ({ commit }, payload) {
    commit(UPDATE_MODAL, payload);
  },

  [UPDATE_FORM] ({ commit }, payload) {
    commit(UPDATE_FORM, payload);
  }
};

export const competitionRouletteModal: Module<CompetitionRouletteModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations  
};