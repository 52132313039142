import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import {
  OfferAdvertisersListResponse,
  PartialOfferAdvertisersListItemState
} from "@core/store/types/admin/lists/OfferAdvertisersListState";
import { AxiosPromise } from "axios";
import advertisersByOffer from "./graphql/advertisersByOffer.graphql";

export class OfferAdvertisersListService extends Service {
  public static getAdvertisersListByOffer (offerId: string, advertisersLimit: number, advertisersOffset: number, advertisersFilters: PartialOfferAdvertisersListItemState["filters"] = {}): AxiosPromise<OfferAdvertisersListResponse> {
    return GraphQLServer.get("", {
      params: {
        query: advertisersByOffer,
        variables: {
          offerId,
          advertisersLimit,
          advertisersOffset,
          advertisersFilters
        }
      }
    });
  }
}
