const getHash = s => {
  let h = 0xdeadbeef;
  for (let i = 0, l = s.length; i < l; i++) {
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  }
  return (h ^ h >>> 16) >>> 0;
};

export const ColorGenerator = {
  methods: {
    colorGenerator (item, alpha = 1) {
      const hue = getHash(item.name) % 360;
      return `hsla(${ hue }, 60%, 60%, ${ alpha })`;
    }
  }
};