import { Integrations, NullableIntegrationsFilters } from "@core/logic/admin/integrations/integrations";
import createIntegrationQuery from "@core/services/admin/integrations/graphql/createIntegration.graphql";
import { CreateIntegrationMapper, integrationsMapper } from "@core/services/admin/integrations/IntegrationsMapper";
import Service from "@core/services/common/Service";
import { CreateIntegrationInput, Integration } from "@core/store/types/admin/integrations";
import { AxiosPromise } from "axios";
import _cloneDeep from "lodash/cloneDeep";
import integrationsQuery from "../../admin/integrations/graphql/integrations.graphql";
import launchIntegrationQuery from "../../admin/integrations/graphql/launchIntegration.graphql";
import removeIntegrationQuery from "../../admin/integrations/graphql/removeIntegration.graphql";
import stopIntegrationQuery from "../../admin/integrations/graphql/stopIntegration.graphql";
import validateIntegrationQuery from "../../admin/integrations/graphql/validateIntegration.graphql";
import editIntegrationQuery from "../../admin/integrations/graphql/editIntegration.graphql";

function prepareRequestData (data: NullableRecord<CreateIntegrationInput>): Record<string, unknown> {
  const newData: NullableRecord<CreateIntegrationInput> = _cloneDeep(data);
  const newOptions = {};
  
  // FixMe: newOptions нужно описать тип
  newData.options?.forEach(option => {
    // @ts-ignore
    newOptions[option.name] = option.value;
  });
  
  // @ts-ignore
  newData.options = newOptions;
  
  return newData;
}

export class IntegrationService extends Service {
  /**
   * Метод создания интеграции
   * @param {NullableRecord<CreateIntegrationInput>} payload
   * @return {Promise<AxiosResponse<Partial<Integration>>>}
   */
  public static async createIntegration (
    payload: NullableRecord<CreateIntegrationInput>
  ): Promise<Integration> {
    const preparedData = prepareRequestData(payload);
    try {
      const { data: { createIntegration } } = await this.api.post("", {
        query: createIntegrationQuery,
        variables: {
          input: {
            ...preparedData
          }
        }
      });
      return CreateIntegrationMapper(createIntegration);
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод получения списка интеграций
   * @param {number} limit
   * @param {number} offset
   * @param {NullableIntegrationsFilters} filters
   * @return {Promise<AxiosPromise>}
   */
  public static async getIntegrations (
    limit: number,
    offset: number,
    filters: NullableIntegrationsFilters
  ): Promise<Partial<Integrations>> {
    try {
      const { data: { integrations } } = await this.api.get("", {
        params: {
          query: integrationsQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
  
      return integrationsMapper(integrations);
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод запуска интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async launchIntegration (
    integrationId: string
  ): Promise<Integration[]> {
    try {
      const { data: { launchIntegration } } = await this.api.post("", {
        query: launchIntegrationQuery,
        variables: {
          integrationId
        }
      });
      return launchIntegration;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод остановки интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async stopIntegration (
    integrationId: string
  ): Promise<Integration[]> {
    try {
      const { data: { stopIntegration } } = await this.api.post("", {
        query: stopIntegrationQuery,
        variables: {
          integrationId
        }
      });
      return stopIntegration;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод валидации интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async validateIntegration (
    integrationId: string
  ): Promise<AxiosPromise> {
    try {
      const { data: { validateIntegration } } = await this.api.post("", {
        query: validateIntegrationQuery,
        variables: {
          integrationId
        }
      });
      return validateIntegration;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод удаления интеграции
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async removeIntegration (
    integrationId: string
  ): Promise<Integration[]> {
    try {
      const { data: { removeIntegration } } = await this.api.post("", {
        query: removeIntegrationQuery,
        variables: {
          integrationId
        }
      });
      return removeIntegration;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * Метод редактирования интеграции
   * @param {CreateIntegrationInput} input
   * @param {string} integrationId
   * @return {Promise<AxiosPromise>}
   */
  public static async editIntegration (
    input: CreateIntegrationInput,
    integrationId: string
  ): Promise<Integration[]> {
    try {
      const preparedData = prepareRequestData(input);
      const { data: { editIntegration } } = await this.api.post("", {
        query: editIntegrationQuery,
        variables: {
          input: {
            ...preparedData
          },
          integrationId
        }
      });
      return editIntegration;
    } catch (e) {
      throw e;
    }
  }
}
