import {
  GET_OFFER_REQUEST,
  UPDATE_EFFECTIVE,
  UPDATE_MODAL_FORM, UPDATE_OFFER_REQUEST_EFFECTIVE, UPDATE_OFFER_REQUEST_INEFFECTIVE
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { LogRequestEffectiveModalState } from "@core/store/types/admin/offers/bampsAndCaps";
import { resultEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";

class LogRequestEffectiveModal implements Module<LogRequestEffectiveModalState, RootState> {
  public namespaced: boolean;
  public state: LogRequestEffectiveModalState;
  
  public mutations: MutationTree<LogRequestEffectiveModalState> = {
    [UPDATE_MODAL_FORM] (state, form: LogRequestEffectiveModalState["form"]) {
      state.form = { ...state.form, ...form };
    },
    
    [UPDATE_MODAL] (state, modal) {
      state.option = { ...state.option , ...modal };
    }
  }
  
  public actions: ActionTree<LogRequestEffectiveModalState, RootState> = {
    [UPDATE_MODAL] ({ commit }, payload: boolean) {
      commit(UPDATE_MODAL, payload);
    },
    
    [UPDATE_MODAL_FORM] ({ commit }, form: LogRequestEffectiveModalState["form"]) {
      commit(UPDATE_MODAL_FORM, form);
    },
    
    async [UPDATE_EFFECTIVE] ({ state: { option: { result, id }, form: { comment } }, commit, dispatch }) {
      const namespace = "admin/capacityAndBumps/logRequest";
      
      switch (result) {
        case resultEnum.EFFECTIVE: {
          await dispatch(`${ namespace }/${ UPDATE_OFFER_REQUEST_EFFECTIVE }`, { id, comment }, { root: true });
          break;
        }
        case resultEnum.INEFFECTIVE: {
          await dispatch(`${ namespace }/${ UPDATE_OFFER_REQUEST_INEFFECTIVE }`, { id, comment }, { root: true });
          break;
        }
      }
      
      commit(SET_EMPTY);
  
      await dispatch(`${ namespace }/${ GET_OFFER_REQUEST }`, null, { root: true });
    }
  }
  
  private readonly initState: () => LogRequestEffectiveModalState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): LogRequestEffectiveModalState => {
      return {
        option: {
          isActive: false,
          result: null,
          id: null
        },
        form: {
          comment: null
        }
      };
    };
    
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    
    this.state = this.initState();
    
    return this;
  }
}

export const logRequestEffectiveModal = new LogRequestEffectiveModal();
