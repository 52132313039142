import { GqlPostback, StatusesNames } from "@core/store/types/webmaster/postbacks/postback";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const statusesArr: Array<StatusesNames> = [
  "processingStatus",
  "acceptStatus",
  "spamStatus",
  "paidStatus",
  "cancelStatus"
];

class ModifedStatus {
  name: StatusesNames;
  isActive = false;
  value?: string;

  constructor (name: StatusesNames, value?: string, isActive = false) {
    this.isActive = isActive;
    this.name = name;
    this.value = value;
  }
}

export class ModifedPostback {
  statuses: Array<ModifedStatus> = []
  id?: string;
  url?: string;
  offerId?: string;
  vertical?: VerticalsEnum;
  isSendDuplicates?: boolean;
  isSendByLeads: boolean;
  isSendByDomonetizationLeads: boolean;

  constructor (postback?: GqlPostback) {
    this.statuses = statusesArr.map(statusName => {
      const isActive = !!postback?.statuses?.[statusName];
      const value = postback?.statuses?.[statusName];

      return new ModifedStatus(statusName, value, isActive);
    });

    this.id = postback?.id;
    this.offerId = postback?.offer?.id;
    this.url = postback?.url;
    this.vertical = postback?.vertical;
    this.isSendDuplicates = !!postback?.isSendDuplicates;
    this.isSendByLeads = postback?.isSendByLeads ?? true;
    this.isSendByDomonetizationLeads = postback?.isSendByDomonetizationLeads ?? false;
  }
}

class InputPb {
  url = "";
  processingStatus?: string;
  acceptStatus?: string;
  spamStatus?: string;
  cancelStatus?: Nullable<string>;
  paidStatus?: string;
  isSendDuplicates?: boolean;
  vertical?: VerticalsEnum;
  isSendByLeads: boolean;
  isSendByDomonetizationLeads: boolean;

  constructor (postback: ModifedPostback) {
    this.url = postback.url as string;
    this.vertical = postback.vertical;
    this.isSendDuplicates = postback.isSendDuplicates as boolean;
    this.isSendByLeads = postback.isSendByLeads;
    this.isSendByDomonetizationLeads = postback.isSendByDomonetizationLeads;
    postback.statuses
      .filter(status => status.isActive)
      .forEach(status => {
        if (postback.vertical === VerticalsEnum.GAMBLING && status.name === "cancelStatus") {
          this.cancelStatus = null;
        } else {
          this[status.name] = status.value;
        }
      });
  }
}

export class InputPbCreate extends InputPb {
  offerId?: string;

  constructor (postback: ModifedPostback, offerId?: string) {
    super(postback);
    this.offerId = offerId;
  }
}

export class InputPbEdit extends InputPb {
  id = "";

  constructor (postback: ModifedPostback) {
    super(postback);
    this.id = postback.id as string;
  }
}
