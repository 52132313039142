import { SET_EMPTY, CANCEL_LEADS }from "@core/store/action-constants";
import { UPDATE_MODAL } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  DetailingDepositHistoryModalState
} from "@core/store/types/common/statistic/detailing/detailingDepositHistoryModal";
import {
  DetailingCancelLeadsModalService
} from "@core/services/admin/statistic/detailing/modal/DetailingCancelLeadsModalService";
import { DetailingCanceledLeadModal } from "@core/store/types/admin/statistic/detailing/modal/detailingCanceledLeadModal";

const initialState = (): DetailingCanceledLeadModal => {
  return {
    modal: {
      isActive: false,
      leadIds: null
    }
  };
};

const state: () => DetailingCanceledLeadModal = initialState;

const mutations: MutationTree<DetailingCanceledLeadModal> = {
  SET_EMPTY: (state: DetailingCanceledLeadModal): DetailingCanceledLeadModal => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, options: DetailingDepositHistoryModalState["modal"]): void {
    state.modal = { ...state.modal, ...options };
  }
};

const actions: ActionTree<DetailingCanceledLeadModal, RootState> = {
  async [CANCEL_LEADS] ({ state: { modal: { leadIds } } }): Promise<void> {
    await DetailingCancelLeadsModalService.cancelLeads(leadIds);
  },

  [UPDATE_MODAL] ({ commit }, options: DetailingDepositHistoryModalState["modal"]): void {
    commit(UPDATE_MODAL, options);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const detailingCanceledLeadModal: Module<DetailingCanceledLeadModal, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
