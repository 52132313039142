import { GET_FILTER_DATA, GET_FILTER_INITIAL_DATA } from "@core/store/action-constants";
import { UPDATE_PAGINATION, UPDATE_FILTERS, UPDATE_SORTINGS } from "@core/store/mutation-constants";

// @vue/components
export const StatisticFilters = {
  data () {
    return {
      namespace: ""
    };
  },
  
  computed: {
    namespaceOrEmpty () {
      return this.namespace ? `${ this.namespace }/` : "";
    }
  },
  
  methods: {
    setInitialValue (filterParam, res) {
      if (this.filters) {
        this.filters[filterParam] = res;
      }
    },
    
    getFilterData (moduleName) {
      return this.$store.dispatch(`${ this.namespaceOrEmpty }${ moduleName }/${ GET_FILTER_DATA }`);
    },
    
    getFilterInitialData (moduleName, payload) {
      return this.$store.dispatch(`${ this.namespaceOrEmpty }${ moduleName }/${ GET_FILTER_INITIAL_DATA }`, payload);
    },
    
    updateFilterPagination (moduleName, payload) {
      this.$store.commit(`${ this.namespaceOrEmpty }${ moduleName }/${ UPDATE_PAGINATION }`, payload);
    },
    
    updateFilterFilters (moduleName, filters) {
      this.$store.commit(`${ this.namespaceOrEmpty }${ moduleName }/${ UPDATE_FILTERS }`, filters);
    },

    updateFilterSort (moduleName, payload) {
      this.$store.commit(`${ this.namespaceOrEmpty }${ moduleName }/${ UPDATE_SORTINGS }`, payload);
    },

    async getFilters (moduleName, data, routeParam, filterParam, label, isLoading) {
      if (isLoading) {
        this.updateFilterPagination(moduleName);
      }
      
      if (data?.items.length > 0) {
        await this.getFilterData(moduleName);
      } else {
        const normalizedRouteParam = routeParam?.length === 0 ? null : routeParam;
        const res = await this.getFilterInitialData(moduleName, normalizedRouteParam);
        if (res?.length > 0) {
          this.setInitialValue(filterParam, res);
        }
      }
    },
    
    getUserFilters (moduleName, data, routeParam, filterParam, label, isLoading, filterOptions = {}, sortOptions = {}) {
      this.updateFilterFilters(moduleName, { loginOrIntId: label?.length > 0 ? label : null, label: label, ...filterOptions });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    },
    
    getExternalWebmasterFilters (moduleName, data, routeParam, filterParam, label, isLoading, filterOptions = {}, excludedFilterOptions = {}, sortOptions= {}) {
      this.updateFilterFilters(moduleName, { externalWebmasterId: label?.length > 0 ? label : null, label: label, ...filterOptions, ...Object.keys(excludedFilterOptions).length > 0 ? { excludedFilterOptions } : {} });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    },
    
    getOfferFilters (moduleName, data, routeParam, filterParam, label, isLoading, filterOptions = {}, sortOptions = {}) {
      this.updateFilterFilters(moduleName, { nameOrIntId: label?.length > 0 ? label : null, label: label, ...filterOptions });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    },
    
    getFiltersByName (moduleName, data, routeParam, filterParam, label, isLoading, filterOptions = {}, sortOptions = {}) {
      this.updateFilterFilters(moduleName, { name: label?.length > 0 ? label : null, label: label, ...filterOptions });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    },
    
    getUtmFilters (moduleName, data, routeParam, filterParam, labelName, label, isLoading, filterOptions = {}, sortOptions = {}) {
      this.updateFilterFilters(moduleName, { [labelName]: label?.length > 0 ? label : null, label: label, ...filterOptions });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    },
  
    getFiltersCommon (moduleName, data, routeParam, filterParam, labelName, label, isLoading, filterOptions = {}, sortOptions = {}) {
      this.updateFilterFilters(moduleName, { [labelName]: label === "" ? null : label, label: label, ...filterOptions });
      Object.keys(sortOptions).length && this.updateFilterSort(moduleName, sortOptions);
      return this.getFilters(moduleName, data, routeParam, filterParam, label, isLoading);
    }
  }
};
