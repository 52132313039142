import User from "@core/logic/user/User";
import Service from "@core/services/common/Service";
import agent from "@core/services/webmaster/graphql/agent.graphql";
import personalManagers from "@core/services/webmaster/graphql/personalManagers.graphql";
import { Agent as AgentConstructor, Manager as ManagerConstructor } from "@core/logic/user/Manager";

export interface WebmasterAdditional {
  skype: string | null;
  telegram: string | null;
  vk: string | null;
}

interface WebmasterTrafficTypes {
  context: boolean;
  brand: boolean;
  popup: boolean;
  clickunder: boolean;
  doorway: boolean;
  email: boolean;
  mobile: boolean;
  motive: boolean;
  broker: boolean;
  teaser: boolean;
  banner: boolean;
  cashback: boolean;
  socialGroups: boolean;
  socialAd: boolean;
  socialApp: boolean;
  coupons: boolean;
  promocodes: boolean;
  autoblock: boolean;
  redirect: boolean;
  app: boolean;
  facebook: boolean;
  youtube: boolean;
  instagram: boolean;
}

// interface Category {
//   id: string;
//   name: string;
// }

export default class Webmaster extends User {
  public hasManager = true;
  public additional: WebmasterAdditional = {
    skype: "",
    telegram: "",
    vk: ""
  };
  public trafficTypes: WebmasterTrafficTypes = {
    context: false,
    brand: false,
    popup: false,
    clickunder: false,
    doorway: false,
    email: false,
    mobile: false,
    motive: false,
    broker: false,
    teaser: false,
    banner: false,
    cashback: false,
    socialGroups: false,
    socialAd: false,
    socialApp: false,
    coupons: false,
    promocodes: false,
    autoblock: false,
    redirect: false,
    app: false,
    facebook: false,
    youtube: false,
    instagram: false
  };
  public categoriesPreferences: string[] = [];
  
  public constructor (response?: Webmaster) {
    super(response);
    
    if (response) {
      this.hasManager = response.hasManager;
      this.additional = response.additional;
      this.trafficTypes = response.trafficTypes;
      this.categoriesPreferences = response.categoriesPreferences.map((el: any): string => el.id);
    }
  }
}

export class WebmasterService extends Service{
  public static async getWebmasterPersonalManager (): Promise<ManagerConstructor[]> {
    try {
      const { data: { webmasterPersonalManagers } } = await this.api.get("", {
        params: {
          query: personalManagers
        }
      });
      return webmasterPersonalManagers.map((element: ManagerConstructor) => new ManagerConstructor(element));
    } catch (e) {
      throw e;
    }
  }
  
  public static async getWebmasterPersonalAgent (): Promise<AgentConstructor> {
    const { data: { personalAgent } } = await this.api.get("", {
      params: {
        query: agent
      }
    });
    return new AgentConstructor(personalAgent);
  }
}
