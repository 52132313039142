import { GET_OFFER_BY_INT_ID, UPDATE_OFFER_BY_INT_ID } from "@core/store/action-constants";
import { SET_EMPTY } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { OfferByIntIdService } from "@core/services/admin/common/offer/OfferByIntIdService";
import { OfferMiniCard } from "@core/store/logic/admin/OfferMiniCard";
import { OfferDataByIntId } from "@core/store/types/admin/common/OfferOutput";

const initialState = (): OfferDataByIntId => {
  return {
    data: null
  };
};

const state: () => OfferDataByIntId = initialState;

const getters: GetterTree<OfferDataByIntId, RootState> = {};

const mutations: MutationTree<OfferDataByIntId> = {
  [UPDATE_OFFER_BY_INT_ID] (state: OfferDataByIntId, data: OfferDataByIntId["data"]): void {
    state.data = { ...state.data, ...data };
  },
  
  [SET_EMPTY]: (state: OfferDataByIntId) => Object.assign(state, initialState())
};

const actions: ActionTree<OfferDataByIntId, RootState> = {
  async [GET_OFFER_BY_INT_ID] ({ commit }, intId: string): Promise<void> {
    const offerByIntId = await OfferByIntIdService.getOfferByIntId(intId);
    
    commit(UPDATE_OFFER_BY_INT_ID, { [intId]: new OfferMiniCard(offerByIntId) });
  },
  
  [SET_EMPTY]: ({ commit }): void => {
    commit(SET_EMPTY);
  }
};

export const offerDataByIntId: Module<OfferDataByIntId, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {}
};
