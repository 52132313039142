import Service from "@core/services/common/Service";
import changeAvatar from "./graphql/changeAvatar.graphql";
import { AdvertiserProfileEditInput } from "@core/store/types/advertiser/profile";

export class ProfileService extends Service {
  public static editAvatarProfile (input: AdvertiserProfileEditInput): Promise<any> {
    return this.api.post("", {
      query: changeAvatar,
      variables: {
        input
      }
    });
  }
}
