export default [
  {
    path: "competition",
    name: "competition",
    redirect: { name: "admin:competition:competition" },
    component: (): Promise<any> => import(/* webpackChunkName: "admin:competition" */ "@/views/Admin/CompetitionView.vue"),
    children: [
      {
        path: "competition",
        name: "competition",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:competition:competition" */ "@/components/Admin/Competition/Competition/Competition.vue")
      },
      {
        path: "rating",
        name: "rating",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:competition:rating" */ "@/components/Admin/Competition/CompetitionRating/CompetitionRating.vue")
      },
      {
        path: "roulette",
        name: "roulette",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:competition:roulette" */ "@/views/Admin/CompetitionRouletteView.vue")
      }
    ]
  }
];
