import { DetailingService } from "@core/services/webmaster/statistic/detailing/DetailingService";
import { GET_STATUSES } from "@core/store/action-constants";
import { UPDATE_STATUSES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { StatusesListState } from "@core/store/types/admin/lists/StatusesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): StatusesListState => {
  return {
    data: {}
  };
};

const state: () => StatusesListState = initialState;

const getters: GetterTree<StatusesListState, RootState> = {};

const mutations: MutationTree<StatusesListState> = {
  [UPDATE_STATUSES_LIST] (state, statuses: StatusesListState["data"]) {
    state.data = statuses;
  }
};

const actions: ActionTree<StatusesListState, RootState> = {
  async [GET_STATUSES] ({ commit }) {
    const { data: { leadStatuses } } = await DetailingService.getStatuses();
    const items = leadStatuses.map((item: string) => {
      return {
        value: item,
        label: `webmaster.statistics.details.filters.values.${ item.toLowerCase() }`
      };
    });
    commit(UPDATE_STATUSES_LIST, {
      items,
      count: items.length
    });
  }
};

export const detailingStatusesList: Module<StatusesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
