import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { PartialRequisitesListState, RequisitesListResponse } from "@core/store/types/admin/lists/RequisitesListState";
import { AxiosPromise } from "axios";
import requisites from "../../admin/lists/graphql/requisites.graphql";

export class RequisitesListService extends Service {
  public static getRequisitesList (
    filters: PartialRequisitesListState["filters"], limit: number, offset: number,
    initialRequisites: PartialRequisitesListState["filters"] = {}): AxiosPromise<RequisitesListResponse> {

    const initialRequisitesLimit = initialRequisites?.id?.length || 0;
    const isInitialRequisites = !!initialRequisites?.id;
    limit = Math.floor(initialRequisitesLimit / limit + 1) * limit - initialRequisitesLimit;
    return GraphQLServer.get("", {
      params: {
        query: requisites,
        variables: {
          limit,
          offset,
          filters,
          initialRequisites,
          initialRequisitesLimit,
          isInitialRequisites
        }
      }
    });
  }
}
