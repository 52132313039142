<template>
    <LTooltip
        ref="tooltip"
        position="is-bottom-end"
        class="is-clickable nowrap is-relative pr-5"
        type="is-white"
        trigger="click"
        interactive
        @onShow="created"
        @onShown="mounted"
        @onHidden="destroy">
        <slot></slot>
        <b-icon
            :icon="customIcon"
            :size="iconSize"
            both
            class="sort-icon is-absolute is-inline ml-2 is-clickable is-size-7"
            style="bottom: 50%; transform: translateY(50%); left: auto; width: auto;"
            :class="[
                icon === 'arrow-up' ? { 'is-desc': !isAsc, 'is-invisible': columnSort !== defaultSort[0] } : null,
                { 'has-text-light': customIcon === 'sort' },
                iconClass
            ]">
        </b-icon>

        <template #content>
            <div class="content px-4 py-2">
                <h6 class="title is-6 mb-3">
                    {{ $t(`admin.offers.capacity.table.dropdown.titleSort`) }}
                </h6>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.sort`)">
                    <b-radio
                        :key="field.label"
                        v-for="field of fields"
                        v-model="sort"
                        :native-value="field.value"
                        :type="typeRadio">
                        {{ field.label }}
                    </b-radio>
                </b-field>

                <b-field :label="$t(`admin.offers.capacity.table.dropdown.order`)">
                    <b-radio
                        v-model="order"
                        native-value="asc"
                        type="is-info">
                        {{ $t(`admin.offers.capacity.table.dropdown.asc`) }}
                    </b-radio>

                    <b-radio
                        v-model="order"
                        native-value="desc"
                        type="is-info">
                        {{ $t(`admin.offers.capacity.table.dropdown.desc`) }}
                    </b-radio>
                </b-field>
            </div>
        </template>
    </LTooltip>
</template>

<script>
  export default {
    name: "CustomHeaderSort",

    props: {
      fields: {
        type: Array,
        required: true
      },

      typeRadio: {
        type: String,
        default: "is-info"
      },

      defaultSort: {
        type: Array,
        default: () => []
      },

      columnSort: {
        type: String,
        default: null
      },

      icon: {
        type: String,
        default: "sort"
      },

      iconSize: {
        type: String,
        default: "is-small"
      },

      iconClass: {
        type: String,
        default: ""
      }
    },

    data () {
      return {
        sort: null,
        order: null,
        instanceSortAndOrderWatch: null
      };
    },

    computed: {
      sortAndOrder () {
        const { sort, order } = this;
        return sort && order ? `${ sort } ${ order }` : null;
      },

      isAsc () {
        return (this.order ?? this.defaultSort[1])?.toLowerCase() !== "desc";
      },

      customIcon () {
        if (this.icon === "sort") {
          return this.defaultSort[0] === this.columnSort
            ? this.isAsc ? "sort-up" : "sort-down"
            : "sort";
        }
        return this.icon;
      }
    },

    methods: {
      updateSorting () {
        this.$emit("update:sorting", this.sort, this.order);
      },

      created () {
        const isSortMatch = this.fields.some(({ value }) => value === this.defaultSort[0]);

        if (isSortMatch) {
          this.sort = this.defaultSort[0];
          this.order = this.defaultSort[1];
        }
      },

      mounted () {
        /** Смотрит за сортировкой после присвоения значений */
        this.instanceSortAndOrderWatch = this.$watch("sortAndOrder", () => {
          this.updateSorting();
          this.$refs.tooltip.$refs.tippy.tip.hide();
        });
      },

      destroy () {
        this.instanceSortAndOrderWatch();
        this.sort = null;
        this.order = null;
      }
    },

    beforeDestroy () {
      /** Уничтожаем вотчер перед уничтожением компонента */
      if (this.instanceSortAndOrderWatch) {
        this.instanceSortAndOrderWatch();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .content {
        width: 290px;

        .field {
            ::v-deep {
                .label {
                    margin-left: 0;
                }

                .field-body {
                    .field {
                        row-gap: 0.5rem;
                        flex-direction: column;
                    }
                }

                .b-radio {
                    font-size: 0.85rem;

                    .check.is-info {
                        border-color: transparentize($light, .5);
                    }
                }
            }
        }
    }
</style>