import {
  LeadData,
  PostbackData,
  PostbackItemResponse,
  PostbackStatus
} from "@core/store/types/webmaster/statistic/modules/postbacks";

export class StatisticPostback {
  public lead: LeadData = {
    clickId: "",
    leadId: "",
    offer: { id: "", intId: "", detail: { name: "" } }
  };
  
  public postback: PostbackData = {
    createdAt: "",
    id: "",
    status: "SUCCEEDED",
    sentAt: "",
    url: ""
  };
  
  constructor (postback: PostbackItemResponse) {
    const status = postback.status?.replace(/[\s]+/gm, "_")
      .replace(/[\W\d]*/gm, "").toUpperCase() as PostbackStatus;
    
    this.lead.clickId = postback.lead.fields.clickId;
    this.lead.leadId = postback.lead.id;
    this.lead.offer = postback.lead.offer;

    this.postback.createdAt = postback.createdAt;
    this.postback.id = postback.id;
    this.postback.status = status;
    this.postback.sentAt = postback.sentAt;
    this.postback.url = postback.url;
  }
}

