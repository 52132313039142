import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import createBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/createBookingCapacityRequest.graphql";
import editBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/editBookingCapacityRequest.graphql";
import deleteBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/deleteBookingCapacityRequest.graphql";
import approveBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/approveBookingCapacityRequest.graphql";
import rejectBookingCapacityRequest from "@core/services/admin/offers/graphql/booking/rejectBookingCapacityRequest.graphql";
import {
  ApproveCapacityRequestInput,
  PayoutRequestInput,
  CreateBookingCapacityRequest,
  EditBookingCapacityRequest,
  DeleteBookingCapacityRequest,
  ApproveBookingCapacityRequest,
  RejectBookingCapacityRequest
} from "@core/store/types/admin/offers/capacity";

export class BookingRequestService extends Service {
  public static createBookingCapacityRequest (
    input: PayoutRequestInput
  ): AxiosPromise<CreateBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: createBookingCapacityRequest,
        variables: {
          input
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static editBookingCapacityRequest (
    input: PayoutRequestInput,
    id: string
  ): AxiosPromise<EditBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: editBookingCapacityRequest,
        variables: {
          id,
          input
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deleteBookingCapacityRequest (
    id: string
  ): AxiosPromise<DeleteBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: deleteBookingCapacityRequest,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static approveBookingCapacityRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): AxiosPromise<ApproveBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: approveBookingCapacityRequest,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static rejectBookingCapacityRequest (id: string): AxiosPromise<RejectBookingCapacityRequest> {
    try {
      return this.api.post("", {
        query: rejectBookingCapacityRequest,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
}
