import { Filters } from "../../generators/filters";
import { FlowItem } from "@core/store/types/admin/statistic/common/flowsFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class FlowsFilters extends Filters<FlowItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { statisticFilters } } = await StatisticsService.getFlowsFilters(offset, limit, filters, initialFilters);
    return statisticFilters;
  };

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const flowId = Array.isArray(ids) ? ids : [ids];
      return { flowId };
    }
  };
}

export const flowsFilters = new FlowsFilters();
