import { Filters } from "../generators/filters";
// import { OfferItem } from "../../../../../types/admin/statistic/common/offersFilters";
import { CountriesListService } from "../../../../services/admin/lists/CountriesListService";
import { BaseFiltersInput, FilterInput } from "../../../types/common/statistic/generic/statisticFilters";

class CountriesFilters extends Filters<any, BaseFiltersInput> {
  getData = async () => {
    const { data } = await CountriesListService.getCountriesList();
    
    return {
      data: {
        items: data,
        count: data.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const countryId = Array.isArray(ids) ? ids : [ids];
      return { countryId };
    }
  };
}

export const countriesFilters = new CountriesFilters();
