import { defineStore } from "pinia";
import { ref } from "@vue/composition-api";
import { IntegrationServices } from "@core/logic/admin/integrations/integrations";

interface Country {
  name: string;
  code: number;
}

export const useCountries = defineStore("auth/countries", () => {
  const data = ref<Country[] | never[]>([]);
  const currentCountry = ref<Country | null>(null);
   
  async function getCountries () {
    const countries = await IntegrationServices.getCountries();
    data.value = countries.map(({ name, phoneCode }) => ({ name, code: phoneCode[0] }));
  }
  
  async function getCurrentCountry () {
    const { name, phoneCode } = await IntegrationServices.getCurrentCountry();
    currentCountry.value = { name, code: phoneCode[0] };
  }

  function $reset () {
    data.value = [];
    currentCountry.value = null;
  }

  return {
    data,
    currentCountry,
    getCountries,
    getCurrentCountry,
    $reset
  }; 
});