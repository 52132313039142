import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AdminProfilePasswordState } from "@core/store/types/admin/profile/modules/password";
import { SET_EMPTY, UPDATE_MODAL } from "@core/store/mutation-constants";

const initialState = (): AdminProfilePasswordState => {
  return {
    modal: {
      isPasswordModalActive: false
    }
  };
};

const state: () => AdminProfilePasswordState = initialState;

const mutations: MutationTree<AdminProfilePasswordState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<AdminProfilePasswordState, RootState> = {
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  }
};

export const password: Module<AdminProfilePasswordState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
