import Vue, { type RenderContext } from "vue";
import { FunctionalComponent } from "@vue/runtime-dom";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
import CustomHeaderSort from "@/components/Common/Table/CustomHeaderSort.vue";
import InlineSvg from "vue-inline-svg";
import { computed } from "@vue/composition-api";
import { checkProps } from "@/helpers/checkProps";

type Attrs = {
  column: {
    $attrs: any;
    label: string;
    field: string;
  }
}

interface Props {
  label: string;
  width: string;
  icon: string;
  iconSize: "small" | "medium" | "large";
  sortable: boolean;
  field: string;
  headerClass: string;
  cellClass: string;
  centered: boolean;
  tooltip: string;
  fields: string[];
  thAttrs: () => any;
  sortNamespace: string;
  iconPath: string;
  subheadingClass: string;
  sticky: boolean;
  numeric: boolean;
  visible: boolean;
  searchable: boolean;
}

interface Context extends RenderContext<Props> {
  parent: Vue & {
    defaultSort: string[];
    updateSortings: (sort: string, order: "DESC" | "ASC" | null) => void;
  };
}

const TableColumn: FunctionalComponent<Props & Context> = ({ props, data, scopedSlots: slots, parent }: Context) => {
  const customFields = computed(() => (columnAttrs: any) => {
    if (typeof props.fields[0] === "string") {
      return props.fields.map(field => ({ label: parent.$t(`${ props.sortNamespace || columnAttrs.sortNamespace }.${ field }`), value: field }));
    }
    return props.fields;
  });

  const dynamicIconPath = computed(() => (columnAttrs: any) => require(`@/${ props.iconPath || columnAttrs.iconPath }/${ props.icon }.svg`));

  const size = computed(() => {
    switch (props.iconSize) {
      case "small":
        return "width: 22px; height: 22px; max-width: 22px; min-width: 22px;";
      case "medium":
        return "width: 38px; height: 38px; max-width: 38px; min-width: 38px;";
      default:
        return "width: 22px; height: 22px; max-width: 22px; min-width: 22px;";
    }
  });

  const customIcon = computed(() =>
  // @ts-ignore
    parent?.$refs.table?.$refs.lTable.currentSortColumn.field === props.field
    // @ts-ignore
      ? parent.$refs.table.$refs.lTable.isAsc ? "sort-up" : "sort-down"
      : "sort");

  const scopedSlots = {
    header: (attrs: Attrs) =>
      slots.header
        ? slots.header({ ...attrs })
        : <div
          class={[ "nowrap is-relative", props.subheadingClass, { "is-first": !attrs.column.$attrs.index && attrs.column.$attrs.shadow }, { "pr-5": checkProps(props.sortable) } ]}
          onClick={columnSort}>
          { slots["header-content"]
            ? slots["header-content"]({ ...attrs })
            : <LTooltip
              // @ts-ignore
              type="is-dark"
              active={checkProps(props.tooltip)}
              class={{ "is-clickable": checkProps(props.sortable) }}
              label={props.tooltip}>
              { checkProps(props.sortable) && props.fields?.length &&
            <CustomHeaderSort
              // @ts-ignore
              icon="sort"
              icon-size="is-small"
              icon-class="is-absolute"
              fields={ customFields.value(attrs.column.$attrs) }
              column-sort={attrs.column.field}
              default-sort={parent.defaultSort}
              { ...{ on: { "update:sorting": parent.updateSortings } } }>
              { props.icon
                ? <div
                  style={size.value}
                  class={[props.icon, "icon-wrapper is-flex is-justify-content-center is-align-items-center"]}>
                  <InlineSvg
                    // @ts-ignore
                    class="is-clickable"
                    style="vertical-align: middle;"
                    src={ dynamicIconPath.value(attrs.column.$attrs) }
                    alt={props.label}
                    aria-label={props.label}>
                  </InlineSvg>
                </div>
                : attrs.column.label }
            </CustomHeaderSort> }
              { props.icon
                ? <div
                  style={size.value}
                  class={[props.icon, "icon-wrapper is-flex is-justify-content-center is-align-items-center"]}>
                  <InlineSvg
                    // @ts-ignore
                    class="is-clickable"
                    style="vertical-align: middle;"
                    src={ dynamicIconPath.value(attrs.column.$attrs) }
                    alt={props.label}
                    aria-label={props.label}>
                  </InlineSvg>
                </div>
                : !props.fields?.length && attrs.column.label }
            </LTooltip> }
          { checkProps(props.sortable) && !props.fields?.length &&
            <b-icon
              icon={customIcon.value}
              size="is-small"
              style={"right: auto !important;"}
              class={[
                { "has-text-light": customIcon.value === "sort" },
                "sort-icon is-absolute is-inline ml-2 is-clickable is-size-7"
              ]}
              both>
            </b-icon> }
        </div>,
    default: (attrs: any) => slots.default?.({ ...attrs })
  };

  function columnSort (event: Event) {
    // @ts-ignore
    const column = parent.$refs.table!.$refs?.lTable.newColumns.find((c) => c.field === props.field);
    // @ts-ignore
    parent.$refs.table.$refs.lTable.sort(column, null, event);
  }

  return (
    <b-table-column
      label={props.label}
      width={props.width}
      sortable={!props.fields?.length && checkProps(props.sortable)}
      centered={checkProps(props.centered)}
      header-class={`borderless ${ props.headerClass || "" }`}
      cell-class={props.cellClass}
      th-attrs={props.thAttrs}
      field={props.field}
      custom-key={props.label || props.tooltip}
      scopedSlots={scopedSlots}
      sticky={checkProps(props.sticky)}
      numeric={checkProps(props.numeric)}
      visible={props.visible ?? true}
      searchable={checkProps(props.searchable)}
      {...data.attrs}>
    </b-table-column>
  )
  ;
};

export default TableColumn;