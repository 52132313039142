var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBox',{attrs:{"is-active":_vm.isActive,"is-loading":_vm.isLoading},on:{"update:isActive":function($event){_vm.isActive=$event},"update:is-active":function($event){_vm.isActive=$event},"close":function($event){_vm.isActive = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex is-align-items-center"},[_c('h5',{staticClass:"title is-5 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("webmaster.finances.autoPaymentsListModal.header.title"))+" ")]),_c('LTooltip',{attrs:{"label":_vm.canBeAddAutoTransaction
                    ? _vm.$t('webmaster.finances.autoPaymentModal.info.noCurrenciesAvailable')
                    : _vm.$t('webmaster.finances.filters.values.autoPayments'),"position":"is-right","type":"is-success"}},[_c('b-button',{staticClass:"ml-3",staticStyle:{"border-radius":"10px","width":"33px"},attrs:{"tag":"button","type":"is-success is-light","disabled":_vm.canBeAddAutoTransaction},on:{"click":_vm.openAutoPayoutModal}},[_c('b-icon',{attrs:{"pack":"fas","icon":"plus","size":"is-medium"}})],1)],1)],1)]},proxy:true}])},[(_vm.data && _vm.data.items.length)?[_c('b-table',{attrs:{"data":_vm.data.items}},[_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('h2',[_vm._v(" "+_vm._s(_vm.$t("webmaster.finances.autoPaymentsListModal.body.labels.type"))+": ")]),_c('div',{staticClass:"out-transaction-type"},[_vm._v(" "+_vm._s(((_vm.$t(("webmaster.finances.autoPaymentsListModal.body.values.type." + (row.outTransactionType)))) + ","))+" "),(_vm.hasTypeTime(row))?[_vm._v(" "+_vm._s(_vm.transactionsType(row))+" ")]:[_vm._v(" "+_vm._s(_vm.formatCurrency(row.sum, row.requisite.paymentSystem.currency))+" ")]],2)]}}],null,false,1653051873)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('h2',[_vm._v(" "+_vm._s(_vm.$t("webmaster.finances.autoPaymentsListModal.body.labels.paymentSystem"))+": ")]),_vm._v(" "+_vm._s(_vm.formatEmptyString(row.requisite.paymentSystem.name))+" "),(row.requisite.paymentSystem.isDisabled)?[_c('LTooltip',{attrs:{"label":_vm.$t('webmaster.finances.autoPaymentModal.messages.paymentSystem'),"position":"is-right","type":"is-dark"}},[_c('b-icon',{attrs:{"pack":"fas","type":"is-danger","icon":"circle-exclamation","size":"is-medium"}})],1)]:_vm._e()]}}],null,false,2639634715)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('h2',[_vm._v(" "+_vm._s(_vm.$t("webmaster.finances.autoPaymentsListModal.body.labels.requisite"))+": ")]),_vm._v(" "+_vm._s(((_vm.formatEmptyString(row.requisite.purse)) + " (" + (_vm.currencySign(row.requisite.paymentSystem.currency)) + ")"))+" ")]}}],null,false,2911431863)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('h2',[_vm._v(" "+_vm._s(_vm.$t("webmaster.finances.autoPaymentsListModal.body.labels.dateCreated"))+": ")]),_vm._v(" "+_vm._s(_vm.formatEmptyString(_vm.moment(row.createdAt, "DD.MM.YYYY")))+" ")]}}],null,false,3191251165)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('div',{staticClass:"flex is-align-items-flex-end"},[_c('LTooltip',{staticClass:"mr-3",attrs:{"active":!row.requisite.paymentSystem.isDisabled,"label":_vm.$t("common.buttons.edit"),"position":"is-left"}},[_c('b-button',{attrs:{"loading":_vm.loadingRowId === row.id,"icon-left":"pen","disabled":row.requisite.paymentSystem.isDisabled,"rounded":"","size":"is-small","type":"is-warning is-light"},on:{"click":function($event){return _vm.editAutoPayment(row)}}})],1),_c('LTooltip',{attrs:{"label":_vm.$t("common.buttons.delete"),"type":"is-danger","position":"is-left"}},[_c('b-button',{attrs:{"loading":_vm.loadingRowId === row.id,"icon-left":"trash-alt","icon-pack":"far","rounded":"","size":"is-small","type":"is-danger is-light"},on:{"click":function($event){return _vm.deleteAutoPayment(row.id)}}})],1)],1)]}}],null,false,1827972472)})],1)]:[_c('section',{staticClass:"section pb-0"},[_c('div',{staticClass:"content has-text-grey has-text-centered is-size-5"},[_c('p',[_vm._v(_vm._s(_vm.$t("common.table.noData")))])])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }