import { defineStore } from "pinia";
import { FinancesAutoPayments } from "@core/store/types/webmaster/finances/modal/financeAutoPayments";
import store from "@/store";
import { FinanceDayEnum } from "@core/store/types/webmaster/finances/modal/enum/financeDayEnum";
import { FinanceTypeEnum } from "@core/store/types/webmaster/finances/modal/enum/financeTypeEnum";
import momentjs from "moment/moment";
import moment from "moment";
import { FinancesService } from "@core/services/webmaster/finances/FinancesService";
import { FinanceOutTransactionType } from "@core/store/types/webmaster/finances/modal/enum/financeOutTransactionType";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { useFinanceAutoPaymentsListModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsListModalStore";

export const useFinanceAutoPaymentsModal = defineStore("webmaster/finance/autoPayments", {
  state: (): FinancesAutoPayments => ({
    isActive: false,
    options: {
      daysOfWeek: [FinanceDayEnum.TUESDAY, FinanceDayEnum.FRIDAY],
      type: FinanceTypeEnum.DAILY,
      outTransactionType: null,
      datepickerDates: null,
      requisiteId: null,
      isEdit: false,
      time: 840,
      sum: null
    }
  }),

  getters: {
    requisitesList: () => store.state.webmaster.finances.requisites.requisites,
    availableCurrencies: () => store.state.auth.availableCurrencies
  },

  actions: {
    async ACTION_AUTO_OUT_TRANSACTION () {
      let { time, type, daysOfWeek, datepickerDates, outTransactionType, sum, requisiteId, isEdit, id } = this.options;
      const isDaily = type === FinanceTypeEnum.DAILY;
      const isWeekly = type === FinanceTypeEnum.WEEKLY;
      const daysOfMonth = !(isWeekly || isDaily) ? datepickerDates?.map(date => +momentjs(date).format("D")) : null;
      const hasTime = outTransactionType === FinanceOutTransactionType.TIME;

      // @ts-ignore
      time = hasTime ? moment().endOf("day").add(time, "minutes").toDate() : null;
      // @ts-ignore
      daysOfWeek = isWeekly ? daysOfWeek : null;
      type = hasTime ? FinanceTypeEnum.DAILY : null;
      sum = hasTime ? null : sum;

      const input = { time, daysOfMonth, isDaily, daysOfWeek, outTransactionType, sum, ...!isEdit && { requisiteId } };

      if (isEdit) {
        const data = await FinancesService.editAutoOutTransaction(id, input);
        useStateUpdater(useFinanceAutoPaymentsListModal).LOCAL_UPDATE({ items: [data] });
      } else {
        const data = await FinancesService.createAutoOutTransaction(input);
        useStateUpdater(useFinanceAutoPaymentsListModal).LOCAL_ADD({ items: [data] });
      }
    }
  }
});
