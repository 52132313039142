import { AdvertisersDetailService } from "@core/services/admin/users/advertisers/AdvertisersDetailService";
import { GET_ADVERTISER_RATES } from "@core/store/action-constants";
import { SET_ADVERTISER_RATES, SET_EMPTY, SET_EMPTY_FILTERS, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdvertisersRatesState } from "@core/store/types/admin/users/advertisers/Detail";
import { ActionTree, Module, MutationTree } from "vuex";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";

const initialState = (): AdvertisersRatesState => {
  return {
    data: null,
    filters: {}
  };
};

const state: () => AdvertisersRatesState = initialState;

const mutations: MutationTree<AdvertisersRatesState> = {
  [SET_ADVERTISER_RATES] (state, data) {
    state.data = data;
  },
	
  [UPDATE_FILTERS] (state, filters) {
    if (Array.isArray(filters.offer) && !filters.offer.length) {
      filters.offer = null;
    }
    if (Array.isArray(filters.webmaster) && !filters.webmaster.length) {
      filters.webmaster = null;
    }

    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [SET_EMPTY_FILTERS] (state) {
    state.filters = initialState().filters;
  }
};

const actions: ActionTree<AdvertisersRatesState, RootState> = {
  async [GET_ADVERTISER_RATES] ({ commit, state, rootGetters }) {
    const advertiserId = rootGetters["admin/users/advertisers/detail/advertiserId"];
    const { filters } = state;

    const ratesSummary = await AdvertisersDetailService.getRates(advertiserId, filters);

    commit(SET_ADVERTISER_RATES, ratesSummary);
  },

  [UPDATE_FILTERS] ({ commit, dispatch }, filters) {
    commit(UPDATE_FILTERS, filters);
    dispatch(GET_ADVERTISER_RATES);
  },

  [SET_EMPTY_FILTERS] ({ commit, dispatch }) {
    commit(SET_EMPTY_FILTERS);
    dispatch(GET_ADVERTISER_RATES);
  }
};

export const rates: Module<AdvertisersRatesState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmastersFilters: new WebmastersFilters()
  }
};
