import { defineStore } from "pinia";
import { computed, ref, unref } from "@vue/composition-api";
import { datepicker } from "@core/helpers/datepicker";
import { TrafficService } from "@core/services/admin/traffic/TrafficService";
import { pagination } from "@core/helpers/pagination";
import {
  TrafficRequestUnificationData,
  TrafficRequestFilters,
  TrafficRequestUnificationItem,
  TrafficRequestSort
} from "@core/store/types/admin/traffic/trafficRequest";
import { TrafficRequestTypeEnum } from "@core/store/types/admin/traffic/enums/trafficRequestTypeEnum";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { useTrafficRequestAnalyseModal } from "@/stores/admin/traffic/modal/trafficRequestAnalyseModalStore";
import { useTrafficRequestChatModal } from "@/stores/admin/traffic/modal/trafficRequestChatModalStore";
import { useTrafficRequestCreateModal } from "@/stores/admin/traffic/modal/trafficRequestCreateModalStore";
import { patchInputData } from "@/stores/admin/traffic/datasets/patchInputData";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";

export const useTrafficRequest = defineStore("admin/efficiency/trafficRequest", () => {
  const initialFilters = <Partial<TrafficRequestFilters>>{
    datepicker: datepicker(),
    intId: null,
    webmasterId: null,
    offerId: null,
    creatorId: null,
    executorId: null,
    manager: null,
    recommendationStatus: null,
    type: [TrafficRequestTypeEnum.RECOMMENDATIONS],
    termsStatus: null,
    notificationState: null
  };

  const data = ref<TrafficRequestUnificationData | null>(null);
  const filters = ref(initialFilters);
  const excludedFilters = ref({});
  const sort = ref<TrafficRequestSort | null>(null);
  const order = ref<OrderEnum | null>(null);

  const type = computed(() => filters.value.type![0]);

  const updater = useStateUpdater(useTrafficRequest);
  
  async function getTrafficRequest () {
    const { limit, offset } = pagination();
    const { datepicker, termsStatus, recommendationStatus, ...otherFilters } = unref(filters);
    const status = typeof recommendationStatus === "object" ? recommendationStatus : { recommendationStatus };
    data.value = await TrafficService.getTrafficRequest({ ...datepicker, ...termsStatus, ...status, ...otherFilters }, unref(excludedFilters), limit, offset, sort.value, order.value);
  }

  async function deleteTrafficRequest (id: string) {
    const items = await TrafficService.deleteTrafficRequest(id);
    updater.LOCAL_UPDATE({ items });
  }

  function showAnalyseRequest (webmasterId: string, offerId: string) {
    const store = useTrafficRequestAnalyseModal();
    store.isActive = true;
    store.webmasterId = webmasterId;
    store.offerId= offerId;
  }

  function showEditTraffic (rowData: TrafficRequestUnificationItem) {
    const store = useTrafficRequestCreateModal();
    store.isActive = true;
    store.isEdit = true;
    store.idRequest = rowData.id;
    store.$patch(patchInputData(rowData));
  }

  function showTrafficChat (rowData: TrafficRequestUnificationItem) {
    const storeChat = useTrafficRequestChatModal();
    storeChat.isActive = true;
    storeChat.modal = rowData;
  }

  async function acceptRecommendationTrafficRequest (id: string) {
    const items = await TrafficService.acceptRecommendationTrafficRequest(id);
    updater.LOCAL_UPDATE({ items });
  }

  async function rejectRecommendationTrafficRequest (id: string) {
    const items = await TrafficService.rejectRecommendationTrafficRequest(id);
    updater.LOCAL_UPDATE({ items });
  }

  async function applyNewTermsTrafficRequest (id: string) {
    const items = await TrafficService.applyNewTermsTrafficRequest(id);
    updater.LOCAL_UPDATE({ items });
  }

  function $reset () {
    data.value = null;
    filters.value = initialFilters;
    sort.value = null;
    order.value = null;
  }

  return {
    data,
    filters,
    sort,
    order,
    type,
    updater,
    getTrafficRequest,
    deleteTrafficRequest,
    showAnalyseRequest,
    acceptRecommendationTrafficRequest,
    rejectRecommendationTrafficRequest,
    applyNewTermsTrafficRequest,
    showTrafficChat,
    showEditTraffic,
    $reset,
    GET_TRAFFIC_REQUEST: "getTrafficRequest",
    DELETE_TRAFFIC_REQUEST: "deleteTrafficRequest"
  };
});