<template>
    <div class="card">
        <LLoading
            :active="loading"
            :is-full-page="false"
            :is-label-exist="false">
        </LLoading>
        <slot name="card">
            <header class="card-header">
                <slot name="card-header">
                    <h2
                        v-if="title || $slots['card-header-title']"
                        :class="['card-header-title', headerClass]">
                        <slot name="card-header-title">
                            {{ title }}
                        </slot>
                    </h2>
                    <a
                        v-if="icon || $slots['card-header-icon']"
                        aria-label="more options"
                        class="card-header-icon"
                        href="#"
                        @click.prevent="iconClick">
                        <slot name="card-header-icon">
                            <b-icon
                                :icon="icon"
                                :pack="iconPack"
                                :size="iconSize"></b-icon>
                        </slot>
                    </a>
                </slot>
            </header>
            <section
                v-if="$slots['card-image']"
                class="card-image">
                <slot name="card-image"></slot>
            </section>
            <section class="card-content">
                <slot
                    v-if="$slots['card-content']"
                    name="card-content"></slot>
                <slot v-else></slot>
            </section>
            <footer
                v-if="$slots['card-footer']"
                class="card-footer">
                <slot
                    v-if="false"
                    name="card-footer"></slot>
                <div
                    :key="i"
                    v-for="(item, i) in $slots['card-footer']"
                    class="card-footer-item">
                    <VNode :vnodes="item"></VNode>
                </div>
            </footer>
        </slot>
    </div>
</template>

<script>
  // В минимальном виде этот компонент можно вызвать так:
  // <Card title="Заголовок" icon="Название иконки">Контент</Card>
  //
  // [PROPS]
  // title? - Заголовок
  // icon? - иконка (см. Документацию по b-icon)
  // iconPack? - пак иконок (см. Документацию по b-icon)
  // iconSize? - размер иконки (см. Документацию по b-icon)
  //
  // [SLOTS]
  // default - тоже что и card-content. Позволяет устанавливать контент компонента, используется если card-content
  //           не заполнен.
  // card - родительский слот, перезаписывает все содержимое компонента.
  // card-header - перезаписывает все содержимое хедера.
  // card-header-title - перезаписывает title. Можно использовать вместо title.
  // card-header-icon - перезаписывает icon. Можно использовать если пропсов для настройки иконки недостаточно.
  // card-image - позволяет добавлять изображение в карточку.
  // card-content - то же что и default. Если заполнен default игнорируется.
  // card-footer - Внимание! Все блоки переданные в card-footer будут обернуты в div.card-footer-item.
  import VNode from "@/components/Common/VNode";
  import LLoading from "@/components/Common/LLoading";

  export default {
    name: "Card",
    components: { LLoading, VNode },
    props: {
      title: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: null
      },
      iconPack: {
        type: String,
        default: null
      },
      iconSize: {
        type: String,
        default: null
      },
      headerClass: {
        type: String,
        default: ""
      }
    },
    methods: {
      iconClick () {
        this.$emit("header-icon-click");
      }
    }
  };
</script>

<style lang="scss" scoped>
    .card-footer-item {
        padding: 0;

        &::v-deep > * {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 0.75rem;
            justify-content: center;
        }
    }
</style>
