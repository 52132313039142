<template>
    <Select
        v-model="selectedCountries"
        v-bind="$attrs"
        :hide-null="hideNull"
        :get-data-vuex="getFilteredCountries"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :loading="isLoading"
        class="white"
        prop="id"
        :multiple="multiple">
        <template #text="{ option }">
            <CountryOutput :country="option"></CountryOutput>
        </template>
        <template #selected-option="{ option }">
            <CountryOutput :country="option"></CountryOutput>
        </template>
    </Select>
</template>

<script>
  import CountryOutput from "@/components/Common/Output/CountryOutput.vue";
  import BaseSelect from "@/components/Common/Base/BaseSelect.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { GET_COUNTRIES_LIST } from "@core/store/action-constants";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "CountriesSelect",
    extends: BaseSelect,
    components: {
      CountryOutput,
      Select
    },

    data () {
      return {
        namespace: "countries"
      };
    },

    computed: {
      ...mapState("countriesList", {
        countries: ({ countries }) => countries
      }),

      selectedCountries: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      },

      isLoading () {
        return this.$wait(`countriesList/${ GET_COUNTRIES_LIST }`);
      }
    },

    methods: {
      ...mapActions("countriesList", [
        GET_COUNTRIES_LIST
      ]),

      async getFilteredCountries (label) {
        //TODO: Убрать условие, когда бЭк сделает контекст для стран + запрос на graphql
        if (this.countries.items.length === 0) {
          await this.GET_COUNTRIES_LIST();
        }
        if (label) {
          return filteredDataBySearch(this.countries.items, label);
        } else {
          return this.countries;
        }
      }
    }
  };
</script>

<style scoped>
</style>
