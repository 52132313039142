import { News } from "@core/logic/news/news";
import { RootState } from "@core/store/root-state";
import Axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface NewsState {
  news: News[];
  newsById: [];
}

const state: NewsState = {
  news: [],
  newsById: []
};

const getters: GetterTree<NewsState, RootState> = {
  NEWS: state => state.news,
  GET_NEWS_BY_ID: state => state.newsById
};

const mutations: MutationTree<NewsState> = {
  SET_NEWS: (state, news): void => {
    state.news = news;
  },
  
  SET_NEWSBYID: (state, newsById): void => {
    state.newsById = newsById;
  },
  
  RESETNEWS: (state): void => {
    state.news = [];
  }
};

const actions: ActionTree<NewsState, RootState> = {
  async GET_NEWS ({ commit }, payload) {
    try {
      const params = {};
      if (payload.dateStart) {
        // @ts-ignore
        params["filters[dateStart]"] = payload.dateStart;
      }
      if (payload.dateEnd) {
        // @ts-ignore
        params["filters[dateEnd]"] = payload.dateEnd;
      }
      if (payload.offerId) {
        // @ts-ignore
        params["filters[offerId]"] = payload.offerId;
      }
      const { data } = await Axios.get("/webmaster/news", { params });
      const news = data.items.map((news: Record<string, unknown>) => new News(news));
      commit("SET_NEWS", news);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async GET_NEWS_BY_ID ({ commit }, newsId): Promise<void> {
    try {
      const response = await Axios.get(`/webmaster/news/${ newsId }`);
      const news = new News(response.data);
      commit("SET_NEWSBYID", news);
    } catch (error) {
      throw error;
    }
  }
};

export const news: Module<NewsState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
