export const hasEmptyObject = (obj) => {
  if (obj) {
    for (const i in obj) {
      if (obj[i]) {
        return false;
      }
    }
  }
  return true;
};
