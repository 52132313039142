import Service from "@core/services/common/Service";
import { UploadFileState } from "@core/store/types/admin/uploads/UploadFileState";
import { UploadState } from "@core/store/types/admin/uploads/UploadsState";
import { AxiosPromise } from "axios";
import fileExport from "./graphql/fileExport.graphql";
import filesReadyPercent from "./graphql/filesReadyPercent.graphql";
import queryExport from "./graphql/queryExport.graphql";
import queryExports from "./graphql/queryExports.graphql";

export class ExportService extends Service {
  /**
   * TODO: Добавить описание
   * @param {number} limit
   * @param {number} offset
   * @param {UploadState["filters"]} filters
   * @return {Promise<AxiosPromise>}
   */
  public static async queryExports (
    limit: number,
    offset: number,
    filters: UploadState["filters"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: queryExports,
          variables: {
            limit,
            offset,
            filters: {
              ...filters
            }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {UploadFileState["options"]} options
   * @return {Promise<AxiosPromise<string>>}
   */
  public static async queryExport (
    options: UploadFileState["options"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: queryExport,
        variables: {
          input: {
            ...options
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {number} limit
   * @param {number} offset
   * @param {UploadState["filters"]} filters
   * @return {Promise<AxiosPromise>}
   */
  public static async getFilesReadyPercent (
    filters: UploadState["filters"],
    limit = 1,
    offset = 0
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: filesReadyPercent,
          variables: {
            limit,
            offset,
            filters: {
              ...filters
            }
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} queryExportId
   * @return {Promise<AxiosPromise>}
   */
  public static async fileExport (
    queryExportId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: fileExport,
          variables: {
            queryExportId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
