import { OfferEditAdvertisersService } from "@core/services/admin/offer/edit/OfferEditAdvertisersService";
import {
  ADD_OFFER_ADVERTISERS,
  GET_AVAILABLE_ADVERTISERS_BY_ID, GET_OFFER_ID,
  GET_SELECTED_ADVERTISERS,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_SELECTED_ADVERTISERS
} from "@core/store/action-constants";
import { offerEditAdvertisersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditAdvertisersAvailableList";
import { UPDATE_MODAL_ACTIVE, UPDATE_SELECTED_ADVERTISERS_IDS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { RequestAdvertiser, SelectedAdvertiser } from "@core/store/types/admin/offer/OfferAdvertiser.d";
import { OfferAdvertisersModalState } from "@core/store/types/admin/offer/OfferAdvertisersModal.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): OfferAdvertisersModalState => {
  return {
    selectAdvertisersIds: [],
    selectedAdvertisers: {},
    isAdvertisersModalActive: false
  };
};

const state: () => OfferAdvertisersModalState = initialState;

const getters: GetterTree<OfferAdvertisersModalState, RootState> = {
  [GET_SELECTED_ADVERTISERS]: state => Object.values(state.selectedAdvertisers)
};

const mutations: MutationTree<OfferAdvertisersModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isAdvertisersModalActive = payload;
  },

  [UPDATE_SELECTED_ADVERTISERS] (state, advertisers: OfferAdvertisersModalState["selectedAdvertisers"]) {
    state.selectedAdvertisers = advertisers;
  },

  [UPDATE_SELECTED_ADVERTISERS_IDS] (state, ids: string[]) {
    state.selectAdvertisersIds = ids;
  }
};

const actions: ActionTree<OfferAdvertisersModalState, RootState> = {
  [UPDATE_SELECTED_ADVERTISERS] ({ getters, state, commit }, ids) {
    commit(UPDATE_SELECTED_ADVERTISERS_IDS, ids);
    const newAdvertisers: { [key: string]: unknown } = {};
    for (const id of state.selectAdvertisersIds) {
      if (state.selectedAdvertisers[id]) {
        newAdvertisers[id] = state.selectedAdvertisers[id];
      } else {
        newAdvertisers[id] =
                    JSON.parse(JSON.stringify(
                      getters[`offerEditAdvertisersAvailableList/${ GET_AVAILABLE_ADVERTISERS_BY_ID }`]([id])[0]));
      }
    }
    commit(UPDATE_SELECTED_ADVERTISERS, newAdvertisers);
  },

  async [ADD_OFFER_ADVERTISERS] ({ rootGetters, state, dispatch }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    try {
      if (offerId) {
        const advertisers: RequestAdvertiser[] = Object.values(state.selectedAdvertisers).map((item: SelectedAdvertiser) => {
          return {
            writeOff: item.writeOff || 0,
            id: item.id
          };
        });
        const { data: { offerIncludeMultipleAdvertisers } } =
                    await OfferEditAdvertisersService.offerIncludeMultipleAdvertisers(offerId, advertisers);

        dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });
        dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
          { offerId }, { root: true });

        return offerIncludeMultipleAdvertisers;
      }
    } catch (e) {
      throw e;
    }
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
      commit("offerEditAdvertisersAvailableList/SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const offerEditAdvertisersModal: Module<OfferAdvertisersModalState, RootState> = {
  namespaced: true,
  modules: {
    offerEditAdvertisersAvailableList
  },
  getters,
  state,
  mutations,
  actions
};
