<template>
    <CompactList :items="items || []">
        <template
            #listItem="{item}">
            <NameTranslationOutput
                :name="item.name.replace(/\s/g, '_')"
                namespace="common.entity.offer.categoriesTypes">
            </NameTranslationOutput>
        </template>
        <template
            #tooltipItem="{item}">
            <NameTranslationOutput
                :name="item.name.replace(/\s/g, '_')"
                namespace="common.entity.offer.categoriesTypes">
            </NameTranslationOutput>
        </template>
        <template
            #singleItem="{item}">
            <NameTranslationOutput
                :name="item.name.replace(/\s/g, '_')"
                namespace="common.entity.offer.categoriesTypes">
            </NameTranslationOutput>
        </template>
    </CompactList>
</template>

<script>
  import NameTranslationOutput from "@/components/Common/Output/NameTranslationOutput.vue";
  import CompactList from "@/components/Common/Lists/CompactList.vue";

  export default {
    name: "CategoriesCompactList",
    components: {
      CompactList,
      NameTranslationOutput
    },
    props: {
      items: {
        type: Array,
        default: null
      }
    }
  };
</script>

<style scoped>

</style>