import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import advertisers from "@core/services/common/lists/graphql/advertisers.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class AdvertisersService extends Service {
  public static getAdvertisersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialAdvertisers?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialAdvertisersLimit = initialAdvertisers?.id?.length || 0;
      
      return this.api.get("", {
        params: {
          query: advertisers,
          variables: {
            limit,
            offset,
            filters,
            initialAdvertisers,
            initialAdvertisersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
