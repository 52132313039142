import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { refill } from "./modules/refill";
import { costs } from "./modules/costs/costs";

export const finances: Module<any, RootState> = {
  namespaced: true,
  modules: {
    costs,
    refill
  }
};
