import Service from "@core/services/common/Service";
import advertiserById from "@core/services/admin/common/graphql/advertiserById.graphql";
import { AdvertiserDataByIdDataResponse } from "@core/store/types/admin/common/AdvertiserOutput";

export class AdvertiserByIdService extends Service {
  public static async getAdvertiserById (advertiserId: string): Promise<AdvertiserDataByIdDataResponse> {
    try {
      const { data: { advertiserById: data } } = await this.api.get("", {
        params: {
          query: advertiserById,
          variables: {
            advertiserId
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}
