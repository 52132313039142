var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"mt-4",scopedSlots:_vm._u([{key:"card-header-title",fn:function(){return [_c('div',{staticClass:"is-align-items-center is-justify-content-space-between is-fullwidth is-flex-wrap-wrap"},[_c('span',{staticClass:"pr-2 py-2"},[_vm._v(" "+_vm._s(_vm.$t('common.profile.security.activeSessions.title'))+" ")]),_c('b-button',{attrs:{"loading":_vm.isLoading.forceLogout,"type":"is-info"},on:{"click":_vm.forceLogout}},[_vm._v(" "+_vm._s(_vm.$t("common.profile.security.activeSessions.button.completeOtherSessions"))+" ")])],1)]},proxy:true}])},[_c('TablePlaceholder',{staticClass:"mt-2",attrs:{"data":_vm.dataTable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"count":count,"hoverable":"","hide-pagination":""}},[_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(("IP:" + (_vm.formatEmptyString(row.ip))))),_c('br'),(row.isCurrentLogin)?_c('span',{staticClass:"has-text-success"},[_vm._v(" "+_vm._s(_vm.$t('common.profile.security.activeSessions.table.activeSessions'))+" ")]):_vm._e()]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.browser))+" ")]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('Countries',{attrs:{"items":_vm.countryToArray(row.country),"compact":""}})]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.momentWithEmpty(row.lastActivityDate, "DD.MM.YYYY HH:mm"))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"width":"5%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{attrs:{"rounded":"","size":"is-small","type":"is-danger is-light","icon-left":"arrow-right-from-bracket","loading":_vm.isLoading.logoutProfileSession && _vm.logoutSessionId === row.loginId,"icon-pack":"fas"},on:{"click":function($event){return _vm.logoutProfileSession(row)}}})]}}],null,true)})],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"hero is-medium has-text-centered"},[_c('div',{staticClass:"hero-body py-6"},[_c('h2',{staticClass:"is-size-4 is-bold has-text-weight-medium has-text-grey"},[_vm._t("placeholder",function(){return [_vm._v(" "+_vm._s(_vm.$t("common.table.noData"))+" ")]})],2)])])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }