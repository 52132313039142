import {
  SET_EMPTY,
  UPDATE_ACTUAL_FILTERS
} from "@core/store/action-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { FiltersConfirmState } from "@core/store/types/common/FiltersConfitm";
import { UPDATE_EXCLUDED_FILTERS, UPDATE_FILTERS } from "@core/store/mutation-constants";

const initialState = (): FiltersConfirmState => {
  return {
    filters: {},
    excludedFilters: {},
    isActualFilters: true
  };
};

const state: () => FiltersConfirmState = initialState;

const getters: GetterTree<FiltersConfirmState, RootState> = {};

const mutations: MutationTree<FiltersConfirmState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_EXCLUDED_FILTERS] (state, excludedFilters) {
    state.excludedFilters = { ...state.excludedFilters, ...excludedFilters };
  },
  
  [UPDATE_ACTUAL_FILTERS] (state, payload) {
    state.isActualFilters = payload;
  }
};

const actions: ActionTree<FiltersConfirmState, RootState> = {
  [UPDATE_FILTERS] ({ commit }, filters) {
    commit(UPDATE_FILTERS, filters);
  },
  
  [UPDATE_EXCLUDED_FILTERS] ({ commit }, excludedFilters) {
    commit(UPDATE_EXCLUDED_FILTERS, excludedFilters);
  },
  
  [UPDATE_ACTUAL_FILTERS] ({ commit }, payload: boolean) {
    commit(UPDATE_ACTUAL_FILTERS, payload);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const filtersConfirm: Module<FiltersConfirmState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
