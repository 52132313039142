import Fuse from "fuse.js";

export const filteredDataBySearch = (items, label, keys = ["name"], threshold = 0.4) => {
  const fuse = new Fuse(items, {
    keys,
    threshold
  });
  const filteredItems = fuse.search(label).map(({ item }) => ({ ...item }));
  
  return {
    count: filteredItems.length,
    items: filteredItems
  };
};