import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { GuaranteeRequestState } from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import {
  APPROVE_GUARANTEE_REQUEST,
  CREATE_GUARANTEE_REQUEST,
  DELETE_GUARANTEE_REQUEST, FORCE_FINISH_GUARANTEE_REQUEST,
  GET_GUARANTEE_STATISTIC,
  REJECT_GUARANTEE_REQUEST,
  UPDATE_GUARANTEE_REQUEST
} from "@core/store/action-constants";
import {
  SET_GUARANTEE_STATISTIC,
  SET_GUARANTEE_REQUEST,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { GuaranteeRequestService } from "@core/services/admin/offers/GuaranteeRequestService";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

class BookingRequest implements Module<GuaranteeRequestState, RootState> {
  public namespaced: boolean;
  public state: GuaranteeRequestState;

  public getters: GetterTree<GuaranteeRequestState, RootState> = {
    getData: (state) => state.data
  }

  public mutations: MutationTree<GuaranteeRequestState> = {
    [SET_GUARANTEE_REQUEST] (state: GuaranteeRequestState, payload): void {
      state.data = payload;
    },

    [UPDATE_GUARANTEE_REQUEST] (state: GuaranteeRequestState, payload): void {
      state.data = { ...state.data, ...payload };
    },

    [SET_GUARANTEE_STATISTIC] (state: GuaranteeRequestState, payload): void {
      state.guaranteeStatistic = payload;
    }
  }

  public actions: ActionTree<GuaranteeRequestState, RootState> = {
    [UPDATE_GUARANTEE_REQUEST] ({ commit }, payload): void {
      commit(UPDATE_GUARANTEE_REQUEST, payload);
    },

    async [CREATE_GUARANTEE_REQUEST] ({ state, commit }, { moduleNamespace, ...payload }): Promise<void> {
      const { data: { createGuaranteeRequest } } = await GuaranteeRequestService.createGuaranteeRequest(payload);

      commit(UPDATE_GUARANTEE_REQUEST, createGuaranteeRequest);
      // dispatch(UPDATE_SUBSECTION_REQUEST, { moduleNamespace, ...payload });
    },

    async [DELETE_GUARANTEE_REQUEST] ({ state: { data }, commit }): Promise<void> {
      await GuaranteeRequestService.deleteGuaranteeRequest(data.id as string);

      commit(SET_GUARANTEE_REQUEST, { data: null });
      // dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },

    async [APPROVE_GUARANTEE_REQUEST] ({ state: { data }, commit }): Promise<void> {
      const { guaranteePercent, dayCount, executorComment, id } = data;
      const { data: { approveGuaranteeRequest } } = await GuaranteeRequestService.approveGuaranteeRequest(
        { guaranteePercent, dayCount, executorComment },
        id as string
      );

      commit(UPDATE_GUARANTEE_REQUEST, approveGuaranteeRequest);
      // dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },

    async [REJECT_GUARANTEE_REQUEST] ({ state: { data }, commit }, { id, comment }): Promise<void> {
      const { data: { rejectGuaranteeRequest } } = await GuaranteeRequestService.rejectGuaranteeRequest(id ?? data.id as string, comment ?? data.executorComment);

      commit(UPDATE_GUARANTEE_REQUEST, rejectGuaranteeRequest);
      // dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },

    async [FORCE_FINISH_GUARANTEE_REQUEST] ({ state: { data }, commit }, { id, comment }): Promise<void> {
      const { data: { forceFinishGuaranteeRequest } } = await GuaranteeRequestService.forceFinishGuaranteeRequest(id ?? data.id as string, comment ?? data.executorComment);

      commit(UPDATE_GUARANTEE_REQUEST, forceFinishGuaranteeRequest);
      // dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },

    async [GET_GUARANTEE_STATISTIC] ({ commit, rootState }) {
      const { offerId, webmasterId, datepicker } = rootState.admin.capacityAndBumps.logRequest.logRequestModal.form;
      const params = { offerId, webmasterId, vertical: VerticalsEnum.NUTRA, ...datepicker };
      const { data: { statistics } } = await GuaranteeRequestService.getGuaranteeStatistic(params);

      commit(SET_GUARANTEE_STATISTIC, {
        // @ts-ignore
        conversionApprove: statistics.items[0].conversionApprove
      });
    }

    // [UPDATE_SUBSECTION_REQUEST] ({ dispatch, getters }, { moduleNamespace, ...payload }): void {
    //   dispatch(`${ moduleNamespace }/${ UPDATE_SUBSECTION_REQUEST }`, {
    //     data: getters.getData,
    //     offerId: payload.offerId,
    //     webmasterId: payload.webmasterId,
    //     moduleName: "bookingCapacityRequest"
    //   } as UpdateSubsectionRequestDTO, { root: true });
    // }
  }

  private readonly initState: () => GuaranteeRequestState;

  constructor () {
    this.namespaced = true;
    this.initState = (): GuaranteeRequestState => {
      return {
        data: {
          startDateForApprove: null,
          endDateForApprove: null,
          guaranteePercent: null,
          executorComment: null,
          webmasterId: null,
          dayCount: null,
          offerId: null
        },
        guaranteeStatistic: null
      };
    };

    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());

    this.state = this.initState();

    return this;
  }
}

export const guaranteeRequest = new BookingRequest();