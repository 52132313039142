import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import webmasters from "@core/services/common/lists/graphql/webmasters.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class WebmastersFilterService extends Service {
  public static getWebmastersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialWebmasters?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialWebmastersLimit = initialWebmasters?.id?.length || 0;
      
      return this.api.get("", {
        params: {
          query: webmasters,
          variables: {
            limit,
            offset,
            filters,
            initialWebmasters,
            initialWebmastersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}