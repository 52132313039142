import Service from "@core/services/common/Service";
import { DomonetizationResponse } from "@core/store/types/admin/statistic/domonetization/domonetization";
import domonetizationStatisticsGenerator
  from "@core/services/common/statistic/domonetization/graphql/domonetizationStatistics.generator";
import { DomonetizationState } from "@core/store/types/webmaster/statistic/modules/domonetization";
import { DomonetizationGroupsEnum } from "@core/store/types/webmaster/statistic/enums/DomonetizationGroupsEnum";

export class DomonetizationService extends Service {
  public static async getDomonetization (
    limit: number,
    offset: number,
    sort: DomonetizationState["sort"],
    order: DomonetizationState["order"],
    filters: DomonetizationState["filters"],
    groups: DomonetizationGroupsEnum[],
    role: string,
    showRedemption?: boolean
  ): Promise<DomonetizationResponse> {
    try {
      const { data: { domonetizationStatistics } } = await this.api.get("", {
        params: domonetizationStatisticsGenerator(
          limit,
          offset,
          sort,
          order,
          filters,
          groups,
          role,
          showRedemption
        )
      });
      
      return domonetizationStatistics;
    } catch (e) {
      throw e;
    }
  }
}
