import { Filters } from "../../common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { AdvertisersService } from "@core/services/common/lists/AdvertisersService";

export class AdvertisersFilters extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { advertisers, initialAdvertisers } } =
      await AdvertisersService.getAdvertisersFilters(offset, limit, filters, initialFilters);
    
    return {
      data: {
        items: advertisers?.items,
        count: advertisers?.count
      },
      initialData: {
        items: initialAdvertisers?.items,
        count: initialAdvertisers?.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
