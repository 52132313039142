import { EditInput, EditInputForDTO } from "@core/store/types/admin/users/advertisers/Edit";
import { Money } from "@core/store/types/common";

export class AdvertisersEditInputDTO implements EditInput {
  public email: string;
  public minimalCommission: Money[];
  public isFineDebt: boolean;
  public fineDebtDays: number | null;
  public fineDebtPercent: number | null;
  public geo: string;
  public sizeLeads: string;
  public verticals: string[];
  public timeZone: string;
  public telegram: string;
  public whatsApp: number;
  public viber: number;
  public skype: string;

  constructor (input: EditInputForDTO) {
    this.email = input.email ?? input.contact.email;
    this.minimalCommission = input.minimalCommission;
    this.isFineDebt = input.isFineDebt;
    this.fineDebtDays = input.isFineDebt ? input.fineDebtDays : null;
    this.fineDebtPercent = input.isFineDebt ? input.fineDebtPercent : null;
    this.geo = input.geo;
    this.sizeLeads = input.sizeLeads;
    this.verticals = typeof input.verticals[0] === "string" ? input.verticals : input.verticals.map(({ id }) => id);
    this.timeZone = input?.timeZone ?? input.timeZone?.alias;
    this.telegram = input.telegram ?? input.contact?.telegram;
    this.whatsApp = input.whatsApp ?? input.contact?.whatsApp;
    this.viber = input.viber ?? input.contact?.viber;
    this.skype = input.skype ?? input.contact?.skype;
  }
}