import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_SUBSECTION_CAPACITY, UPDATE_APPROVE,
  UPDATE_CAPACITY,
  UPDATE_SUBSECTION_REQUEST,
  UPDATE_SUBSECTIONS
} from "@core/store/action-constants";
import {
  SET_SUBSECTION_CAPACITY,
  SET_EMPTY,
  SUBSECTION_CAPACITY_DATA,
  SUBSECTION_CAPACITY_PAGINATION,
  UPDATE_PAGINATION,
  SET_EMPTY_DATA,
  UPDATE_SORTINGS,
  SUBSECTION_CAPACITY_SORT,
  SUBSECTION_CAPACITY_ORDER,
  UPDATE_FILTERS,
  SET_SUBSECTION_REQUEST,
  SET_PAYOUT,
  SET_INDIVIDUAL_RATE
} from "@core/store/mutation-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import { capacityAnalyse } from "@core/store/modules/admin/capacityAndBumps/modules/log/capacityAnalyse";
import {
  ExternalWebmastersCapacityData,
  ExternalWebmastersCapacityResponse,
  ExternalWebmastersCapacityState,
  WebmasterCapacityPagination,
  WebmastersCapacityData,
  WebmastersCapacityItem,
  CapacityOrder,
  CapacitySort,
  WebmastersCapacityResponse, UpdateApproveDTO
} from "@core/store/types/admin/offers/capacity";
import Vue from "vue";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";
import { CapacitySubsectionEnum } from "@core/store/types/common/enums/capacitySubsectionEnum";

class ExternalWebmastersCapacity implements Module<ExternalWebmastersCapacityState, RootState> {
  public namespaced: boolean;
  public state: ExternalWebmastersCapacityState;
  public modules: ModuleTree<RootState>;
  private readonly initialPagination = {
    page: 1,
    perPage: 20
  }

  public getters: GetterTree<ExternalWebmastersCapacityState, RootState> = {
    [SUBSECTION_CAPACITY_DATA]: (state): ExternalWebmastersCapacityData => state.data,
  
    [SUBSECTION_CAPACITY_PAGINATION]: (state: ExternalWebmastersCapacityState): WebmasterCapacityPagination => state.pagination,
  
    [SUBSECTION_CAPACITY_SORT]: (state: ExternalWebmastersCapacityState): CapacitySort => state.sort,
  
    [SUBSECTION_CAPACITY_ORDER]: (state: ExternalWebmastersCapacityState): CapacityOrder => state.order
  }

  public mutations: MutationTree<ExternalWebmastersCapacityState> = {
    [SET_SUBSECTION_CAPACITY]: (state: ExternalWebmastersCapacityState, { id, ...data }): void => {
      if (!_isEmpty(state.data[id])) {
        data = {
          ...data,
          count: data.count,
          items: state.data[id]?.items?.concat(data.items)
        };
  
        state.data[id] = {};
      }
      Vue.set(state.data, id, data);
    },

    [UPDATE_CAPACITY]: (state: ExternalWebmastersCapacityState, payload): void => {
      const elem = state.data[`${ payload.webmasterId }-${ payload.offer.id }`]?.items
        ?.find(({ id }) => id === payload.user.id) as ExternalWebmastersCapacityData;

      Vue.set(elem, "softCapacity", payload.softCapacity);
      Vue.set(elem, "capacityUpdatedAt", moment().toDate());
    },

    [UPDATE_APPROVE]: (state, payload: UpdateApproveDTO): void => {
      const elem = state.data[`${ payload.webmasterId }-${ payload.offerId }`]?.items
        ?.find(({ id, externalWebmasterId }) =>
          (externalWebmasterId || id) === (payload.externalWebmasterId || payload.subWebmasterId)) as ExternalWebmastersCapacityData;

      Vue.set(elem, "requiredApprove", payload.updateOfferWebmasterRequiredApprove.requiredApprove);
      Vue.set(elem, "updatedAt", payload.updateOfferWebmasterRequiredApprove.updatedAt);
    },

    [UPDATE_SUBSECTIONS]: (state: ExternalWebmastersCapacityState, payload): void => {
      const { webmasterId, offerId } = payload;
      const elem = state.data[`${ webmasterId }-${ offerId }`]?.items
        ?.find(({ id }) => id === payload.id) as ExternalWebmastersCapacityData;
      
      Vue.set(elem, "subsection", payload?.subsection);
    },

    [UPDATE_PAGINATION] (state: ExternalWebmastersCapacityState, payload): void {
      const { id, ...pagination } = payload;
      Vue.set(state.pagination, id, { ...state.pagination[id], ...pagination });
    },
  
    [UPDATE_SORTINGS] (state: ExternalWebmastersCapacityState, { id, sort }): void {
      state.sort[id] = sort.sortField.replace("CapacityIndicator", "Leads");
      state.order[id] = sort.direction.toUpperCase();
    },
  
    [SET_EMPTY_DATA] (state: ExternalWebmastersCapacityState, id: string): void {
      state.data[id] = {};
    },
  
    [UPDATE_FILTERS] (state: ExternalWebmastersCapacityState, filters): void {
      state.filters = { ...state.filters, ...filters };
    },
  
    [SET_SUBSECTION_REQUEST] (state: ExternalWebmastersCapacityState, payload): void {
      const { data, webmasterId, offerId } = payload;
      const elem = state.data[`${ webmasterId }-${ offerId }`]?.items
        ?.find(({ externalWebmasterId }) => externalWebmasterId === payload.externalWebmasterId) as WebmastersCapacityData;
    
      Vue.set(elem, "payoutRequest", data);
    },
  
    [SET_PAYOUT] (state: ExternalWebmastersCapacityState, payload): void {
      const { data, webmasterId, offerId } = payload;
      const elem = state.data[`${ webmasterId }-${ offerId }`]?.items
        ?.find(({ externalWebmasterId }) => externalWebmasterId === payload.externalWebmasterId) as WebmastersCapacityItem;
    
      const newPayoutValue = {
        currency: elem.webmasterPayout.currency,
        value: payload.newPayoutValue
      };
      Vue.set(elem, "webmasterPayout", newPayoutValue);
      Vue.set(elem, "payoutUpdatedAt", data.payoutUpdatedAt);
    },
  
    [SET_INDIVIDUAL_RATE] (state: ExternalWebmastersCapacityState, payload): void {
      const { data, webmasterRate, webmasterId, offerId } = payload;
      const elem = state.data[`${ webmasterId }-${ offerId }`]?.items
        ?.find(({ externalWebmasterId }) => externalWebmasterId === payload.externalWebmasterId) as WebmastersCapacityItem;
    
      const newRate = {
        currency: elem.webmasterIndividualRate.currency,
        value: webmasterRate
      };
      Vue.set(elem, "webmasterIndividualRate", newRate);
      Vue.set(elem, "rateUpdatedAt", data.rateUpdatedAt);
    }
  }


  public actions: ActionTree<ExternalWebmastersCapacityState, RootState> = {
    async [GET_SUBSECTION_CAPACITY] ({ state, commit, rootGetters }, { id, offerId, subsection, isRender }): Promise<ExternalWebmastersCapacityResponse | WebmastersCapacityResponse> {
      const uniqId = `${ id }-${ offerId }`;
      
      const limit = state.pagination[uniqId]?.perPage || 20;
      const offset = limit * ((state.pagination[uniqId]?.page || 1) - 1);
      const serviceName = CapacitySubsectionEnum[subsection as keyof typeof CapacitySubsectionEnum];

      const data = await GqlOffersService[serviceName](
        limit,
        offset,
        id,
        offerId,
        state.sort,
        state.order,
        state.filters,
        rootGetters["admin/capacityAndBumps/capacity/webmastersCapacity/isIndividualRate"],
        rootGetters["admin/capacityAndBumps/capacity/advertisersCapacity/isShowRedemption"],
        rootGetters["admin/capacityAndBumps/logRequest/isShowCommission"]
      );
      
      
      if (!state.pagination[uniqId] || _isEmpty(state.pagination[uniqId])) {
        commit(UPDATE_PAGINATION, { id: uniqId, page: 1, perPage: 20 });
      }
  
      if (!isRender && state.sort[uniqId] && state.pagination[uniqId]?.page === 1) {
        commit(SET_EMPTY_DATA, uniqId);
      }
      commit(SET_SUBSECTION_CAPACITY, { ...data, id: uniqId });
      return data;
    },

    async [UPDATE_CAPACITY] ({ commit }, { webmasterId, ...input }): Promise<void> {
      const { data: { setCapacity } } = await GqlOffersService.updateCapacity(input);
      commit(UPDATE_CAPACITY, { webmasterId, ...setCapacity });
    },

    async [UPDATE_APPROVE] ({ commit }, { subWebmasterId, ...input }): Promise<void> {
      const { data: { updateOfferWebmasterRequiredApprove } } = await GqlOffersService.updateApprove({ ...input, webmasterId: subWebmasterId ?? input.webmasterId });
      commit(UPDATE_APPROVE, { updateOfferWebmasterRequiredApprove, offerId: input.offerId, webmasterId: input.webmasterId, externalWebmasterId: input.externalWebmasterId, subWebmasterId });
    },
  
    [UPDATE_SORTINGS] ({ commit }, payload): void {
      commit(UPDATE_SORTINGS, payload);
      commit(UPDATE_PAGINATION, { id: payload.id, page: 1 });
    },
    
    [UPDATE_SUBSECTION_REQUEST] ({ commit, rootGetters }, payload): void {
      commit(SET_SUBSECTION_REQUEST, payload);
      
      if (payload.data.status !== "APPROVED") return;
      commit(SET_PAYOUT, { newPayoutValue: +payload.data.newPayoutValue, ...payload });
  
      if (rootGetters["admin/capacityAndBumps/capacity/webmastersCapacity/isIndividualRate"]) {
        commit(SET_INDIVIDUAL_RATE, { webmasterRate: +payload.data.webmasterRate, ...payload });
      }
    }
  }

  private readonly initState: () => ExternalWebmastersCapacityState;

  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): ExternalWebmastersCapacityState => {
      return {
        data: {},
        pagination: {},
        filters: {},
        sort: {},
        order: {}
      };
    };

    this.mutations[SET_EMPTY] = (state: ExternalWebmastersCapacityState, id: string) => {
      state.data[id] = {};
      state.pagination[id] = this.initialPagination;
      state.filters = {};
      if (state.sort) {
        state.sort[id] = null;
        state.order[id] = null;
      }
    };

    this.state = this.initState();
    this.modules = modules;

    return this;
  }
}

export const externalWebmastersCapacity = new ExternalWebmastersCapacity({
  capacityAnalyse
});