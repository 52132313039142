import { defineStore } from "pinia";
import { computed, ref, Ref, unref } from "@vue/composition-api";
import { newMessageTransform } from "@/stores/common/auth/sokets/datasets/newMessageTransform";
import type { Centrifuge, Subscription } from "centrifuge";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { MessageDTO } from "@core/store/types/common/sockets";
import { TrafficRequestChatActionEnum } from "@core/store/types/admin/traffic/enums/trafficRequestChatActionEnum";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";

export const useTrafficChat = (centrifuge: Centrifuge) => defineStore("trafficChatSockets", () => {
  const permissionsStore = usePermissions();
  const showTrafficData = computed(() => permissionsStore.hasPermissions(["TRAFFIC_REQUESTS.LIST.ALL", "TRAFFIC_REQUESTS.LIST_HANDLE.OWN"]));

  const channel = ref<Subscription | null>(null);
  const data = ref<MessageDTO[]>([]);
    
  function subscribe (intId: Ref<number> | number) {
    if (!showTrafficData.value) return;

    if (channel.value) {
      centrifuge.removeSubscription(channel.value as Subscription);
      channel.value = null;
    }

    const chatChannel = centrifuge.newSubscription(`traffic-request:chat$${ unref(intId) }`);

    chatChannel.on("subscribed", () => {
      channel.value = chatChannel;
    });
    chatChannel.on("unsubscribed", () => {
      $reset();
      centrifuge.removeSubscription(chatChannel);
    });
    chatChannel.on("publication", ({ data: publication }) => {
      const { params, ...item } = newMessageTransform(publication);
      data.value.push({ ...item, params });
      if (item.action === TrafficRequestChatActionEnum.EVENT) {
        const store = useTrafficRequest();
        store.updater.LOCAL_UPDATE({ items: [{ intId: unref(intId), ...params }], param: "intId" });
      }
    });

    chatChannel.subscribe();
  }

  function unsubscribe () {
    if (!channel.value) return;
    channel.value.unsubscribe();
  }

  function $reset () {
    channel.value = null;
    data.value = [];
  }

  return {
    data,
    subscribe,
    unsubscribe,
    $reset
  };
})();