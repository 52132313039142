<template functional>
    <span
        v-if="props.country"
        class="country-block block-select">
        <span class="country-flag">
            <span class="flag-size">
                <!--suppress HtmlUnknownTag -->
                <flag :iso="props.country.alpha2"></flag>
            </span>
        </span>
        <br>
        <span class="country-item">
            {{ props.country.name }}
        </span>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>

  export default {
    name: "CountryOutput",
    props: {
      country: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style lang="scss" scoped>
  .country-block {
    display: inline-flex;

    .country-flag {
      margin-right: 0.4em;
    }
  }
</style>
