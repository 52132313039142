import { RootState } from "@core/store/root-state";
import { ActionTree, Module } from "vuex";
import { offersFilters } from "../common/offersFilters";
import { bunchesFilters } from "../../../../common/statistic/common/bunchesFilters";
import { flowsFilters } from "../../../../common/statistic/common/flowsFilters";
import { withdrawTypesList } from "../../../lists/withdrawTypesList";
import { externalWebmasterFilters } from "../common/externalWebmasterFilters";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { SET_EMPTY, SET_EMPTY_CONTEXT, SYNC_STATISTIC_FILTERS } from "@core/store/action-constants";
import { prepareBaseFilters, prepareUserFilters, filterFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { agentFilters } from "@core/store/modules/admin/statistic/modules/common/agentFilters";
import { utmCampaignFilters } from "@core/store/modules/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/utmTermFilters";
import { partnerNetworkList } from "@core/store/modules/admin/lists/PartnerNetworkList";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import { userTypeList } from "@core/store/modules/admin/lists/userType";
import { domesticWebmastersList } from "@core/store/modules/admin/lists/domesticWebmastersList";

const actions: ActionTree<RootState, RootState> = {
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`bunchesFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`externalWebmastersList/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["externalWebmasterId"]));
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmTermFilters/${ UPDATE_FILTERS }`, baseFilters);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("offersFilters/SET_EMPTY");
    commit("bunchesFilters/SET_EMPTY");
    commit("flowsFilters/SET_EMPTY");
    commit("withdrawTypesList/SET_EMPTY");
    commit("domesticWebmastersList/SET_EMPTY");
    commit("externalWebmastersList/SET_EMPTY");
    commit("agentFilters/SET_EMPTY");
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  },
  
  [SET_EMPTY_CONTEXT] ({ commit }) {
    commit("bunchesFilters/SET_EMPTY");
    commit("flowsFilters/SET_EMPTY");
    commit("externalWebmastersList/SET_EMPTY");
    commit("agentFilters/SET_EMPTY");
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  }
};

export const statistics: Module<RootState, RootState> = {
  namespaced: true,
  modules: {
    currencyList,
    offersFilters,
    agentFilters,
    bunchesFilters,
    flowsFilters,
    userTypeList,
    withdrawTypesList,
    domesticWebmastersList,
    partnerNetworkList,
    externalWebmastersList: externalWebmasterFilters,
    utmCampaignFilters,
    utmContentFilters,
    utmMediumFilters,
    utmSourceFilters,
    utmTermFilters
  },
  actions
};
