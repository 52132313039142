import { UPDATE_ADVERTISER_BY_ID, GET_ADVERTISER_BY_ID } from "@core/store/action-constants";
import { SET_EMPTY } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AdvertiserDataById } from "@core/store/types/admin/common/AdvertiserOutput";
import { AdvertiserByIdService } from "@core/services/admin/common/AdvertiserByIdService";

const initialState = (): AdvertiserDataById => {
  return {
    data: null
  };
};

const state: () => AdvertiserDataById = initialState;

const getters: GetterTree<AdvertiserDataById, RootState> = {};

const mutations: MutationTree<AdvertiserDataById> = {
  [UPDATE_ADVERTISER_BY_ID] (state: AdvertiserDataById, data: AdvertiserDataById["data"]): void {
    state.data = { ...state.data, ...data };
  },
  
  [SET_EMPTY]: (state: AdvertiserDataById): AdvertiserDataById => Object.assign(state, initialState())
};

const actions: ActionTree<AdvertiserDataById, RootState> = {
  async [GET_ADVERTISER_BY_ID] ({ commit }, id: string): Promise<void> {
    const advertiserById = await AdvertiserByIdService.getAdvertiserById(id);
    commit(UPDATE_ADVERTISER_BY_ID, { [id]: advertiserById });
  },
  
  [SET_EMPTY]: ({ commit }): void => {
    commit(SET_EMPTY);
  }
};

export const advertiserDataById: Module<AdvertiserDataById, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
