import Service from "@core/services/common/Service";
import badges from "@core/services/common/badges/qraphql/badges.generator";
import { BadgesCount } from "@core/store/types/common/badges";
import { PaginationOutput } from "@core/store/types/PaginationOutput";

export class BadgesService extends Service {
  public static async getBadges (
    badgesOperations: string[]
  ): Promise<Record<string, Required<PaginationOutput<BadgesCount[]>>>> {
    try {
      const { data } = await this.api.get("", {
        params: badges(
          badgesOperations
        )
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}