import { offerWebmasterAnalyseModal } from "@core/store/modules/advertiser/offer/webmaster/offerWebmasterAnalyseModal";
import { WebmasterTableOfferModule } from "@core/store/modules/common/offer/webmasterTable";
import { State as StateWebmasters } from "@core/store/types/admin/offer/webmasters";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY } from "@core/store/mutation-constants";

class AdvertiserOfferWebmasterTableModule extends WebmasterTableOfferModule<StateWebmasters, RootState> {
  readonly initState: () => StateWebmasters;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): StateWebmasters => {
      return {
        data: null,
        filters: {
          webmasterId: []
        },
        pagination: {
          page: 1,
          perPage: 10
        }
      };
    };

    this.state(this.initState());

    this.mutations({
      [SET_EMPTY]: (state: StateWebmasters): void => {
        Object.assign(state, this.initState());
      }
    });

    this.modules({
      offerWebmasterAnalyseModal
    });

    return this;
  }
}

export default new AdvertiserOfferWebmasterTableModule().toModule();
