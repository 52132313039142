//TODO: отрефакторить капчу
<template>
    <form
        class="content"
        @submit.prevent="captchaExecute">
        <!--suppress HtmlUnknownTarget-->
        <img
            :alt="$saas.name"
            class="form-logo"
            :class="{'pb-0 pl-4': !$saas.main}"
            :style="{'height': !$saas.main && '100px'}"
            :src="require(`@/assets/${ $saas.logo }`)">
  
        <h3 class="title is-4 has-text-centered">
            {{ $t(`common.auth.resetPassword.title`) }}
        </h3>
  
        <!--        <b-notification-->
        <!--            :closable="false"-->
        <!--            type="is-info">-->
        <!--            &lt;!&ndash;        <i18n path="common.auth.signIn.notification.telegramError.content">&ndash;&gt;-->
        <!--            &lt;!&ndash;          <template #username>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <span&ndash;&gt;-->
        <!--            &lt;!&ndash;                      v-t="'common.auth.signIn.notification.telegramError.particles.username'"&ndash;&gt;-->
        <!--            &lt;!&ndash;                      class="has-text-weight-semibold"></span>&ndash;&gt;-->
        <!--            &lt;!&ndash;          </template>&ndash;&gt;-->
        <!--            &lt;!&ndash;        </i18n>&ndash;&gt;-->
        <!--            Вам отправлено письмо с восстановлением пароля на почту limonad@mail.ru-->
        <!--        </b-notification>-->
      
        <div class="columns is-multiline mb-0">
            <div class="column is-12">
                <b-field>
                    <LInput
                        v-model.trim="email"
                        :pattern="emailValidator"
                        :placeholder="$t(`common.auth.signIn.component.passwordReset.body.labels.email`)"
                        required></LInput>
                </b-field>
            </div>
  
            <div class="column is-12">
                <b-button
                    class="is-success"
                    expanded
                    native-type="submit"
                    rounded>
                    {{ $t(`common.auth.signIn.component.passwordReset.body.buttons.reset`) }}
                </b-button>
                <b-button
                    tag="router-link"
                    type="is-text"
                    :to="{ name: 'auth:signIn' }"
                    class="form-subbutton mt-6">
                    {{ $t(`common.auth.signIn.signIn`) }}
                </b-button>
            </div>
        </div>
  
        <Captcha
            ref="captcha"
            @onVerify="resetPassword($event)">
        </Captcha>
    </form>
</template>

<script>
  import { RESET_PASSWORD } from "@core/store/action-constants";
  import { EMAIL_VALIDATOR } from "@core/validators";
  import { mapActions } from "vuex";
  import Captcha from "@/components/Common/Auth/Captcha";

  export default {
    name: "ResetPassword",
    components: { 
      Captcha 
    },
    data () {
      return {
        email: null
      };
    },
    
    computed: {
      isModal () {
        return this.$parent.$vnode.componentOptions.tag === "b-modal";
      },
      
      emailValidator () {
        return EMAIL_VALIDATOR;
      }
    },
    
    methods: {
      ...mapActions([
        RESET_PASSWORD
      ]),

      async resetPassword (captcha) {
        try {
          await this.RESET_PASSWORD({
            email: this.email,
            captcha: captcha
          });
          if (this.$route.name !== "auth:signIn") {
            await this.$router.push({
              name: "auth:signIn",
              params: {
                email: this.email
              }
            }).catch(_ => {});
          }
          this.$emit("closeResetModal");
        } catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.auth.signIn.notification.passwordResetError"),
            type: "is-danger"
          });
        }
      },

      captchaExecute () {
        this.$refs.captcha.$refs.hcaptcha.execute();
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
