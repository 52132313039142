import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { PayoutRequestInput } from "@core/store/types/admin/offers/capacity";

export default function editPayoutRequest (
  input: PayoutRequestInput,
  id: string,
  isShowCommission: boolean

): ASTGeneratedOutput {

  const include = [];
  if (isShowCommission) {
    include.push(
      "webmasterRate"
    );
  }
  const params = {
    include
  };

  const gqlObject = {
    operation: "editPayoutRequest",

    fields: [
      "newPayoutValue",
      "creatorComment",
      "externalWebmaster",
      "goal",
      "updatedAt",
      "id"
    ],

    variables: {
      input: {
        type: "EditPayoutRequestInput!",
        value: input
      },
      id: {
        type: "Uid!",
        value: id
      }
    }
  };

  return Generator.mutation(gqlObject, params);
}