import { Filters } from "../../generators/filters";
import { BunchItem } from "@core/store/types/admin/statistic/common/bunchesFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class BanchesFilters extends Filters<BunchItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { statisticFilters } } = await StatisticsService.getBunchesFilters(offset, limit, filters, initialFilters);
    return statisticFilters;
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const bunchId = Array.isArray(ids) ? ids : [ids];
      return { bunchId };
    }
  };
}

export const bunchesFilters = new BanchesFilters();
