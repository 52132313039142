import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import acceptOfferDisclaimer from "./graphql/acceptOfferDisclaimer.graphql";

export class offerDisclaimerService extends Service {
  public static async acceptOfferDisclaimer (
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: acceptOfferDisclaimer
      });
    } catch (e) {
      throw e;
    }
  }
}