<template functional>
    <span>
        {{ $options.methods.outputFormat(props) }}
    </span>
</template>
<script>
  export default {
    name: "TableItemsCount",
    props: {
      count: {
        type: Number,
        default: null
      },
      currentPage: {
        type: Number,
        default: null
      },
      perPage: {
        type: Number,
        default: null
      },
      itemsLength: {
        type: Number,
        default: null
      }
    },

    methods: {

      startRange (currentPage, perPage) {
        return currentPage * perPage - (perPage - 1);
      },

      endRange (currentPage, perPage, count, itemsLength) {
        return currentPage * perPage > count ? count : itemsLength ? (currentPage - 1) * perPage + itemsLength : currentPage * perPage;
      },

      outputFormat (props) {
        const { currentPage, perPage, count, itemsLength } = props;
        const startRange = this.startRange(currentPage, perPage);
        const endRange = this.endRange(currentPage, perPage, count, itemsLength);

        return `${ startRange } - ${ endRange } / ${ count }`;
      }
    }
  };
</script>
<style lang="scss" scoped>
  span {
      margin-right: 2px;
  }
</style>
