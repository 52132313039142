import { ActionTree, Module, MutationTree } from "vuex";
import { SET_OFFERS_DICTIONARY, UPDATE_OFFERS_DICTIONARY } from "../../mutation-constants";
import { RootState } from "../../root-state";
import { DictionariesAdvertiserOffer, DictionariesService } from "@core/logic/advertiser/dictionaries/dictionaries";
import { PaginationOutput } from "@core/logic/common/pagination-output";
import { AdvertiserOfferInterface } from "@core/store/types/advertiser/offer";

interface Offers extends PaginationOutput {
  items: DictionariesAdvertiserOffer[];
}

export interface DictionariesState {
  offers: Offers | null;
}

const state: DictionariesState = {
  offers: null
};

const mutations: MutationTree<DictionariesState> = {
  [SET_OFFERS_DICTIONARY] (state, offers: Offers): void {
    state.offers = offers;
  },
  
  [UPDATE_OFFERS_DICTIONARY] (state, payload: Offers): void {
    const items = state.offers ? state.offers.items : [];
    
    state.offers = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  }
};

const actions: ActionTree<DictionariesState, RootState> = {
  async [UPDATE_OFFERS_DICTIONARY] ({ commit }, payload): Promise<Offers> {
    const { data } = await DictionariesService.getOffers(payload);
    data.items = data.items.map((item: AdvertiserOfferInterface) => new DictionariesAdvertiserOffer(item));
    
    if (payload.offset === 0) {
      commit(SET_OFFERS_DICTIONARY, data);
    } else {
      commit(UPDATE_OFFERS_DICTIONARY, data);
    }
    
    return data;
  }
};

export const dictionaries: Module<DictionariesState, RootState> = {
  state,
  mutations,
  actions
};
