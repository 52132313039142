import axios, { CancelTokenSource } from "axios";

class CancelToken {
  private source: CancelTokenSource
  
  constructor (initialValue: CancelTokenSource) {
    this.source = initialValue;
  }
  getToken () {
    return this.source.token;
  }
  setSource (value: CancelTokenSource) {
    this.source = value;
  }
  cancel (message: string) {
    this.source.cancel(message);
    this.setSource(axios.CancelToken.source());
  }
}
export const cancelSource = new CancelToken(axios.CancelToken.source());