import { Filters } from "../../common/generators/filters";
import {
  FilterInput,
  BaseFiltersInput,
  UserFiltersInput,
  UserItem
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { CRMService } from "@core/services/admin/lists/CRMService";

export class CRMList extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { crms, initialCRM } } =
                await CRMService.getCRMList(offset, limit, filters, initialFilters);

    return {
      data: {
        items: crms?.items,
        count: crms?.count
      },
      initialData: {
        items: initialCRM?.items,
        count: initialCRM?.length
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
