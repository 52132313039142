import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AnalyticAnalyseChartDetailState } from "@core/store/types/webmaster/analytic/AnalyticAnalyseChartDetail";
import {
  SET_EMPTY,
  SET_INCIDENCE_DETAILS,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import { Pagination } from "@core/store/types/common";
import { GET_INCIDENCE_DETAILS } from "@core/store/action-constants";
import { AnalyticService } from "@core/services/webmaster/analytic/AnalyticService";
import { AnalyticAnalyseChartData } from "@core/store/types/webmaster/analytic/analyticAnalyseChart";
import selectedFields from "./datasets/reasonsSelectedFields.json";
import Vue from "../../../../../../../main";
import { datepickerFormat } from "@core/helpers/datepicker";
import { StatisticState } from "@core/store/types/common/statistic/common/statistic";

const initialState = (): AnalyticAnalyseChartDetailState => {
  return {
    data: null,
    pagination: {
      page: 1,
      perPage: 25
    },
    filters: {
      incidences: null,
      webmasterId: null,
      offerId: null,
      advertiserId: null,
      isInHouse: null
    },
    selectedFields: selectedFields,
    sort: null,
    order: null
  };
};

const state: () => AnalyticAnalyseChartDetailState = initialState;

const getters: GetterTree<AnalyticAnalyseChartDetailState, RootState> = {
  reasons: (state, getters, rootState) => rootState.webmaster.analytic.analyticAnalyseChart.data?.items
    ?.slice()
    .sort((a: AnalyticAnalyseChartData["data"], b: AnalyticAnalyseChartData["data"]) => b.value - a.value)
    .map(({ reason }: AnalyticAnalyseChartData["data"]) => reason),
  datepicker: (state, getters, rootState) => datepickerFormat(rootState.webmaster.analytic.analyticAnalyseChart.filters.datepicker, "date"),
  withBlockedWebmaster: (state, getters, rootState) => rootState.webmaster.analytic.analyticAnalyseChart.filters.withBlockedWebmaster
};

const mutations: MutationTree<AnalyticAnalyseChartDetailState> = {
  [UPDATE_PAGINATION] (state, payload: Pagination) {
    state.pagination = { ...state.pagination, ...payload };
  },

  [UPDATE_FILTERS] (state, payload) {
    state.filters = { ...state.filters, ...payload };
  },

  [SET_INCIDENCE_DETAILS] (state, payload) {
    state.data = { ...state.data, ...payload };
  },

  [UPDATE_SORTINGS] (state, { sort, order }) {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [SET_EMPTY] (state, target?: keyof StatisticState) {
    if (target) {
      state[target] = initialState()[target] as never;
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<AnalyticAnalyseChartDetailState, RootState> = {
  [UPDATE_PAGINATION] ({ commit }, payload: Pagination) {
    commit(UPDATE_PAGINATION, payload);
  },

  [UPDATE_FILTERS] ({ commit }, payload) {
    commit(UPDATE_FILTERS, payload);
    commit(UPDATE_PAGINATION, { page: 1 });
  },

  [UPDATE_SORTINGS] ({ commit }, payload) {
    commit(UPDATE_SORTINGS, payload);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  },

  async [GET_INCIDENCE_DETAILS] ({ commit, state, getters }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { incidences, ...filters } = state.filters;
    const { withBlockedWebmaster, datepicker: { dateStart, dateEnd } } = getters;
    if (incidences?.length) {
      const { data: { incidenceDetails } } = await AnalyticService.getIncidenceDetails(
        Number(Vue.$route.query?.threshold || 20),
        incidences,
        {
          ...filters,
          dateStart,
          dateEnd,
          withBlockedWebmaster
        },
        state.sort,
        state.order,
        limit,
        offset
      );

      commit(SET_INCIDENCE_DETAILS, incidenceDetails);
    }
  }
};

export const analyticAnalyseChartDetail: Module<AnalyticAnalyseChartDetailState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};