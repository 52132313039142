import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=3ed23bcf&scoped=true"
import script from "./Select.vue?vue&type=script&lang=js"
export * from "./Select.vue?vue&type=script&lang=js"
import style0 from "./Select.vue?vue&type=style&index=0&id=3ed23bcf&prod&lang=scss"
import style1 from "./Select.vue?vue&type=style&index=1&id=3ed23bcf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed23bcf",
  null
  
)

export default component.exports