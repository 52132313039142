<template>
    <Card
        v-if="selected.length"
        class="snackbar">
        <div class="is-flex is-align-items-center">
            <span class=" nowrap mr-4">
                {{ $t("common.entity.snackbarActions.selected") }}: {{ selected.length }}
            </span>
            <div class="snackbar-content">
                <slot></slot>
            </div>

            <b-button
                class="ml-4"
                type="is-white"
                icon-left="times"
                rounded
                size="is-small"
                @click="clearSelected">
            </b-button>
        </div>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card.vue";
  import { mapActions, mapState } from "vuex";
  import { SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "SnackbarActions",
    components: {
      Card
    },

    computed: {
      ...mapState("snackbarActions", {
        selected: ({ selected }) => selected
      })
    },

    methods: {
      ...mapActions("snackbarActions", {
        SET_EMPTY
      }),

      clearSelected () {
        this.SET_EMPTY();
        this.$emit("clear");
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    .card {
        margin-bottom: 0 !important;
    }

    .snackbar {
        z-index: 40;
        position: fixed;
        margin: 0;
        bottom: .5rem;
        left: 50%;
        transform: translate(-50%, -10%);
        box-shadow: 0 0 10px rgba(172, 172, 172, 0.7);
        &-content {
            display: flex;
            @media screen and (max-width: 1440px) {
                flex-wrap: wrap;
            }
        }
    }
</style>