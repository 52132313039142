import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import {
  AnalyticAudienceFiltersInterface,
  Periods
} from "@core/store/types/common/statistic/analyticAudience/analyticAudience";

export default function audienceSummary (
  offerId: AnalyticAudienceFiltersInterface["offerId"],
  webmasterId: AnalyticAudienceFiltersInterface["webmasterId"],
  periods: Periods[],
  filters: any,
  includePayout: boolean
): ASTGeneratedOutput {
  const exclude = [];

  if (!includePayout) {
    exclude.push("items/data/...on Audience/conversionPayout");
  }

  const params = { exclude };

  const gqlObject = {
    operation: "audienceSummary",

    fields: [
      {
        items: [
          "alias",
          {
            data: [
              {
                "...on Audience": [
                  "sex",
                  "age",
                  "leads",
                  "conversionLeads",
                  "diffConversionPayout",
                  "conversionPayout",
                  {
                    color: [
                      "r",
                      "g",
                      "b"
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      },
      webmasterId: {
        type: "Uid",
        value: webmasterId
      },
      periods: {
        type: "[AudiencePeriodInput!]!",
        value: periods
      },
      filters: {
        type: "AudienceGraphCriteriaFilters",
        value: filters
      }
    }

  };

  return Generator.query(gqlObject, params);
}