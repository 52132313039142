//TODO: отрефакторить капчу
<template>
    <div class="form-wrapper">
        <Role>
            <template #webmaster>
                <SignUpDetailWebmaster></SignUpDetailWebmaster>
            </template>

            <template #advertiser>
                <SignUpDetailAdvertiser></SignUpDetailAdvertiser>
            </template>
        </Role>
    </div>
</template>

<script>
  import Role from "@/components/Common/Role";
  import SignUpDetailWebmaster from "@/components/Common/Auth/SignUpDetailWebmaster";
  import SignUpDetailAdvertiser from "@/components/Common/Auth/SignUpDetailAdvertiser";

  export default {
    name: "SignUpDetail",
    components: {
      SignUpDetailAdvertiser,
      SignUpDetailWebmaster,
      Role
    },

    created () {
      if (this.$router.history._startLocation === "/confirmation/succeed") {
        this.$ym?.reachGoal("MAIL_APPROVE");
      }
    },

    data () {
      return {
        login: null,
        password: null,
        email: null,
        userType: null
      };
    },

    methods: {
      // TODO: добавить метод получения токена капчи и валидацию
      onCaptchaExpired () {
        this.$refs.hcaptcha.reset();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .form-wrapper {
        padding: 5vh 20px 20px;

        & > div {
            width: 100%;
            margin: auto;
            max-width: 800px;
        }

        &::v-deep {
            .button {
                margin: auto;
                max-width: 240px;
            }
        }
    }
</style>
