import { SET_MODAL_ACTIVE } from "@core/store/mutation-constants";
import {
  UPDATE_MODAL_ACTIVE,
  SET_EMPTY
} from "@core/store/action-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { CallCenterOfferModal } from "@core/store/types/common/offer/offer";
import { RootState } from "@core/store/root-state";

const initialState = (): CallCenterOfferModal => {
  return {
    isModalActive: false
  };
};

const state: () => CallCenterOfferModal = initialState;

const getters: GetterTree<CallCenterOfferModal, RootState> = {};

const mutations: MutationTree<CallCenterOfferModal> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_MODAL_ACTIVE] (state, isModalActive: boolean) {
    state.isModalActive = isModalActive;
  }
};

const actions: ActionTree<CallCenterOfferModal, RootState> = {
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const callCentersOfferModal: Module<CallCenterOfferModal, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
