import { PaginationOutput } from "@core/logic/common/pagination-output";
import { FlowsService } from "@core/logic/flows/flows";
import { GET_FLOWS } from "@core/store/action-constants";
import { SET_FLOWS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface Flow {
  [key: string]: any;
}

export interface Flows extends PaginationOutput {
  items: Flow[];
}

export interface FlowsState {
  flows: Flows | null;
}

const state: FlowsState = {
  flows: null
};

const getters: GetterTree<FlowsState, RootState> = {};

const mutations: MutationTree<FlowsState> = {
  [SET_FLOWS]: (state: FlowsState, payload: Flows): void => {
    state.flows = payload;
  }
};

const actions: ActionTree<FlowsState, RootState> = {
  async [GET_FLOWS] ({ commit }, payload = {}) {
    const { data } = await FlowsService.getFlows(payload);
  
    commit(SET_FLOWS, data);
  }
};

export const flowsOld: Module<FlowsState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
