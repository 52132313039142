import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  CapacityRequestInput,
  CapacityRequestState,
  UpdateSubsectionRequestDTO
} from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import { CapacityRequestService } from "@core/services/admin/offers/CapacityRequestService";
import {
  APPROVE_CAPACITY_REQUEST,
  CREATE_CAPACITY_REQUEST,
  DELETE_CAPACITY_REQUEST,
  EDIT_CAPACITY_REQUEST,
  REJECT_CAPACITY_REQUEST,
  UPDATE_CAPACITY_REQUEST,
  UPDATE_SUBSECTION_REQUEST
} from "@core/store/action-constants";
import {
  SET_CAPACITY_REQUEST,
  SET_EMPTY
} from "@core/store/mutation-constants";

class CapacityRequest implements Module<CapacityRequestState, RootState> {
  public namespaced: boolean;
  public state: CapacityRequestState;
  
  public getters: GetterTree<CapacityRequestState, RootState> = {
    getData: (state) => state.data
  }
  
  public mutations: MutationTree<CapacityRequestState> = {
    [SET_CAPACITY_REQUEST] (state: CapacityRequestState, payload): void {
      state.data = payload;
    },
    
    [UPDATE_CAPACITY_REQUEST] (state: CapacityRequestState, payload): void {
      state.data = { ...state.data, ...payload };
    }
  }
  
  public actions: ActionTree<CapacityRequestState, RootState> = {
    [UPDATE_CAPACITY_REQUEST] ({ commit }, payload): void {
      commit(UPDATE_CAPACITY_REQUEST, payload);
    },
    
    async [CREATE_CAPACITY_REQUEST] ({ state: { data }, commit, dispatch }, { moduleNamespace, ...payload }): Promise<void> {
      const { newCapacityValue, creatorComment } = data;
      const { data: { createCapacityRequest } } = await CapacityRequestService.createCapacityRequest({
        ...payload,
        newCapacityValue,
        creatorComment
      });
      
      commit(UPDATE_CAPACITY_REQUEST, createCapacityRequest);
  
      dispatch(UPDATE_SUBSECTION_REQUEST, { moduleNamespace, ...payload });
    },
    
    async [EDIT_CAPACITY_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { newCapacityValue, creatorComment, id } = data;
      const { data: { editCapacityRequest } } = await CapacityRequestService.editCapacityRequest(
        {
          newCapacityValue,
          creatorComment
        } as CapacityRequestInput,
        id as string
      );
      
      commit(UPDATE_CAPACITY_REQUEST, editCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [DELETE_CAPACITY_REQUEST] ({ state: { data: { id } }, commit, dispatch }, params): Promise<void> {
      await CapacityRequestService.deleteCapacityRequest(id as string);
  
      commit(SET_CAPACITY_REQUEST, { data: null });
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [APPROVE_CAPACITY_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { newCapacityValue, executorComment, id } = data;
      const { data: { approveCapacityRequest } } = await CapacityRequestService.approveCapacityRequest(
        {
          newCapacityValue,
          executorComment
        },
        id as string
      );
      
      commit(UPDATE_CAPACITY_REQUEST, approveCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    async [REJECT_CAPACITY_REQUEST] ({ state: { data }, commit, dispatch }, params): Promise<void> {
      const { executorComment, id } = data;
      const { data: { rejectCapacityRequest } } = await CapacityRequestService.rejectCapacityRequest(
        {
          executorComment
        },
        id as string
      );
      
      commit(UPDATE_CAPACITY_REQUEST, rejectCapacityRequest);
      dispatch(UPDATE_SUBSECTION_REQUEST, params);
    },
    
    [UPDATE_SUBSECTION_REQUEST] ({ dispatch, getters }, { moduleNamespace, ...payload }): void {
      dispatch(`${ moduleNamespace }/${ UPDATE_SUBSECTION_REQUEST }`, {
        data: getters.getData,
        offerId: payload.offerId,
        webmasterId: payload.webmasterId,
        moduleName: "capacityRequest"
      } as UpdateSubsectionRequestDTO, { root: true });
    }
  }
  
  private readonly initState: () => CapacityRequestState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): CapacityRequestState => {
      return {
        data: {
          newCapacityValue: null,
          oldCapacityValue: null,
          creatorComment: null,
          executorComment: null,
          status: null,
          createdAt: null,
          updatedAt: null
        }
      };
    };
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    
    this.state = this.initState();
    
    return this;
  }
}

export const capacityRequest = new CapacityRequest();