import { OfferEditWebmastersListServices } from "@core/services/admin/offer/lists/OfferEditWebmastersListServices";
import {
  GET_INITIAL_WEBMASTERS,
  GET_OFFER_ID,
  GET_WEBMASTERS_BY_ID,
  GET_WEBMASTERS_LIST,
  UPDATE_WEBMASTERS_LIST
} from "@core/store/action-constants";
import {
  EMPTY_WEBMASTERS_LIST,
  UPDATE_WEBMASTERS_FILTERS,
  UPDATE_WEBMASTERS_LIST_PAGINATION
} from "@core/store/mutation-constants";

import { RootState } from "@core/store/root-state";
import {
  PartialWebmastersListState,
  WebmastersListResponse
} from "@core/store/types/admin/offer/lists/OfferEditWebmastersList.d";
import { OfferWebmaster } from "@core/store/types/admin/offer/OfferWebmasters.d";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialWebmastersListState => {
  return {
    data: null,
    filters: {
      id: null,
      loginOrIntId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialWebmastersListState = initialState;

const getters: GetterTree<PartialWebmastersListState, RootState> = {
  [GET_WEBMASTERS_BY_ID]: state => function (ids: string[]): any {
    return ids?.map(id => state.data?.items?.find((item: { id: string }) => item.id === id));
  }
};

const mutations: MutationTree<PartialWebmastersListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_WEBMASTERS_LIST] (state, webmasters: WebmastersListResponse["webmasters"]) {
    if (state.pagination.page !== 1 && state?.data?.items && webmasters) {
      state.data.items = Array.from(new Set([...state.data.items, ...webmasters.items as []]));
    } else {
      state.data = webmasters;
    }
  },

  [UPDATE_WEBMASTERS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_WEBMASTERS_FILTERS] (state, filters: PartialWebmastersListState["filters"]) {

    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_WEBMASTERS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialWebmastersListState, RootState> = {
  async [GET_WEBMASTERS_LIST] ({ commit, state, rootGetters }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const { data: { offer: { webmasters } } } =
            await OfferEditWebmastersListServices.getOfferWebmasters(
              {
                loginOrIntId: state.filters?.loginOrIntId
              },
              limit,
              offset,
              offerId,
              []
            );
    webmasters.items = webmasters.items.map((user: OfferWebmaster) => {
      return {
        ...user.webmaster,
        moneyReward: user.individualRate?.moneyReward
      };
    });
    commit(UPDATE_WEBMASTERS_LIST, webmasters);
    return webmasters;
  },
  
  async [GET_INITIAL_WEBMASTERS] ({ commit, state, rootGetters }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const limit = state.pagination.perPage;
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const { data: { offer, initialWebmasters } } =
      await OfferEditWebmastersListServices.getOfferWebmasters(
        {
          loginOrIntId: state.filters?.loginOrIntId
        },
        limit,
        0,
        offerId,
        ids
      );
    offer.webmasters.items = [...initialWebmasters?.webmasters?.items || [], ...offer.webmasters?.items as []].map((user: OfferWebmaster) => {
      return {
        ...user.webmaster,
        moneyReward: user.individualRate?.moneyReward
      };
    });
    commit(UPDATE_WEBMASTERS_LIST, offer.webmasters);
    return offer.webmasters;
  }
};

export const offerEditWebmastersList: Module<PartialWebmastersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
