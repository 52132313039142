import { OfferWebmasterAnalyseModal } from "@core/store/types/advertiser/offer/webmasters/offerWebmasterAnalyseModal";
import { GET_ANALYSE_DATA } from "@core/store/action-constants";
import {
  UPDATE_ANALYSE_DATA,
  UPDATE_MODAL,
  SET_EMPTY, UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { datepicker } from "@core/helpers/datepicker";
import {
  OfferWebmasterAnalyseModalService
} from "@core/services/advertiser/offer/webmasters/offerWebmasterAnalyseModalService";

const initialState = (): OfferWebmasterAnalyseModal => {
  return {
    data: null,
    modal: {
      isActive: false,
      webmasterId: null,
      offer: null
    },
    filters: {
      datepicker: datepicker({ amount: 1, unit: "month" }, "date"),
      withExternalWebmasters: false,
      externalWebmasterId: null
    }
  };
};

export const state: () => OfferWebmasterAnalyseModal = initialState;

const mutations: MutationTree<OfferWebmasterAnalyseModal> = {
  [UPDATE_ANALYSE_DATA] (state, data): void {
    state.data = data;
  },

  [UPDATE_MODAL] (state, modal): void {
    state.modal = { ...state.modal , ...modal };
  },

  [UPDATE_FILTERS] (state, filters): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state): void {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<OfferWebmasterAnalyseModal, RootState> = {
  async [GET_ANALYSE_DATA] ({ state, commit }): Promise<void> {
    const {
      // @ts-ignore
      modal: { offer: { id }, webmasterId },
      filters: { datepicker, externalWebmasterId, withExternalWebmasters, ...otherFilters }
    } = state;

    const data = await OfferWebmasterAnalyseModalService.getAnalyseGraph(
      id,
      webmasterId,
      {
        ...datepicker,
        ...otherFilters,
        withExternalWebmasters,
        externalWebmasterId: withExternalWebmasters ? externalWebmasterId : null
      }
    );

    commit(UPDATE_ANALYSE_DATA, data);
  },

  [UPDATE_MODAL] ({ commit }, modal): void {
    commit(UPDATE_MODAL, modal);
  },

  [UPDATE_FILTERS] ({ commit, dispatch }, payload): void {
    commit(UPDATE_FILTERS, payload);
    dispatch(GET_ANALYSE_DATA);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const offerWebmasterAnalyseModal: Module<OfferWebmasterAnalyseModal, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
