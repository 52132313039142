import _debounce from "lodash/debounce";

export const StickyTableMixin = {
  inject: ["isReduceMenu"],
  
  created () {
    this.mutationObserver = new MutationObserver(this.debounceResizeTableWidth);
    this.resizeObserver = new ResizeObserver(this.debounceDocumentResize);
  },
  
  data () {
    return {
      mutationObserver: null,
      resizeObserver: null,
      tableElementNode: null,
      isStickyPagination: false
    };
  },
  
  computed: {
    isReduce () {
      return this.isReduceMenu();
    },
  
    parentNode () {
      return this.$parent.$el;
    },
    
    debounceDocumentResize () {
      return _debounce(this.updateStickyPagination, 400, { leading: true, trailing: true });
    },
  
    debounceResizeTableWidth () {
      return _debounce(this.observeResizeTableWidth, 400);
    }
  },
  
  methods: {
    initializeObservers () {
      this.$nextTick(() => {
        this.mutationObserver.observe(this.$el, { childList: true, subtree: true });
        window.addEventListener("resize", this.debounceDocumentResize);
      });
    },
  
    /**
     * @name observeResizeTableWidth Наблюдает за изменением размеров таблицы и пересчитывает ширину
     * @method
     * */
    observeResizeTableWidth () {
      this.tableElementNode = this.parentNode?.querySelector(".table");
      if (!this.tableElementNode) return;
      this.mutationObserver.disconnect();
      this.resizeObserver.observe(this.tableElementNode);
    },
  
    updateStickyPagination () {
      const { clientWidth } = this.parentNode.querySelector(".table") ?? {};
      this.isStickyPagination = !this.hideSticky && this.parentNode.clientWidth < clientWidth;
    }
  },
  
  watch: {
    isReduce () {
      this.debounceDocumentResize.cancel();
    },
  
    tableElementNode (value) {
      if (value) {
        this.tableElementNode = this.parentNode.querySelector(".table");
        this.resizeObserver.observe(this.tableElementNode);
      }
    }
  },
  
  destroyed () {
    window.removeEventListener("resize", this.debounceDocumentResize);
    this.resizeObserver?.disconnect();
  }
};