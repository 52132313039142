import { SET_CALLCENTERS, SET_OFFER, UPDATE_OFFER_OPTIONS } from "@core/store/mutation-constants";
import { GET_CALLCENTERS, GET_OFFER, GET_OFFER_ID, GET_OFFER_INT_ID } from "@core/store/action-constants";
import { Module } from "@core/store/logic/Module";
import { OfferService } from "@core/services/common/offer/OfferService";
import CallCentersService, { CallCenter, GetCallCentersParams } from "@core/logic/common/callCenters/callCenters";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export interface OfferState {
  offer: Nullable<{
    id?: string,
    name?: string
  }>
  callCenters?: Nullable<CallCenter>;
}
export class OfferModule<S extends OfferState, R> extends Module<S, R> {
  constructor () {
    super();
    this.actions({
      async [GET_OFFER] ({ commit, rootGetters }, { service, offerId }) {
        try {
          if (offerId) {
            const offer = await service.getOffer(offerId, rootGetters.role);
            commit(SET_OFFER, offer);
            return offer;
          }
        } catch (e) {
          throw e;
        }
      },
  
      async [GET_OFFER_INT_ID] ({ commit, rootGetters }, slug) {
        const intId = +slug.split("-")[0];
        try {
          if (intId) {
            const { isShowCommissions } = useOffers();
            const offer = await OfferService.getOfferByIndId(
              intId,
              rootGetters.role,
              isShowCommissions
            );
            commit(SET_OFFER, offer);
            return offer;
          }
        } catch (e) {
          throw e;
        }
      },
  
      async [GET_CALLCENTERS] ({ commit }, { limit, offset, filters, timeZone }: GetCallCentersParams) {
        try {
          const { data: { callCenters } } = await CallCentersService.getCallCenters(limit, offset, filters, timeZone);

          commit(SET_CALLCENTERS, callCenters);
        } catch (error) {
          throw error;
        }
      },

      [UPDATE_OFFER_OPTIONS] ({ commit }, options) {
        commit(UPDATE_OFFER_OPTIONS, options);
      }
    });

    this.mutations({
      [SET_OFFER] (state, offer) {
        state.offer = { ...offer };
      },

      [UPDATE_OFFER_OPTIONS] (state, offer) {
        if (state?.offer)
          state.offer = { ...state.offer, ...offer };
      },
  
      [SET_CALLCENTERS]: (state, callCenters): void => {
        state.callCenters = callCenters;
      }
    });

    this.getters({
      [GET_OFFER_ID]: (state) => {
        return state.offer?.id;
      }
    });

    return this;
  }
}