import { Filters } from "../../../../common/generators/filters";
import { WebmasterItem } from "@core/store/types/admin/statistic/common/webmastersFilters";
import {
  UserFiltersInput,
  FilterInput,
  BaseFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";

export class WebmastersFilters extends Filters<WebmasterItem, UserFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: BaseFiltersInput) => {
    const { loginOrIntId } = filters;
    const { data: { webmasters, initialWebmasters } } =
      await StatisticsService.getAnalyticWebmastersFilters(offset, limit, { loginOrIntId }, initialFilters);
    
    return {
      data: {
        items: webmasters.items,
        count: webmasters.count
      },
      initialData: {
        items: initialWebmasters?.items,
        count: initialWebmasters?.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}

// export const webmastersFilters = new WebmastersFilters();
