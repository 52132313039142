import { GET_OFFER_TYPES } from "@core/store/action-constants";
import { UPDATE_OFFER_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { OfferTypesListState } from "@core/store/types/admin/lists/OfferTypesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): OfferTypesListState => {
  return {
    data: {}
  };
};

const state: () => OfferTypesListState = initialState;

const getters: GetterTree<OfferTypesListState, RootState> = {};

const mutations: MutationTree<OfferTypesListState> = {
  [UPDATE_OFFER_TYPES_LIST] (state, statuses: OfferTypesListState["data"]) {
    state.data = statuses;
  }
};

const actions: ActionTree<OfferTypesListState, RootState> = {
  [GET_OFFER_TYPES] ({ commit }) {
    const items = [
      {
        value: "PUBLIC",
        label: "admin.offer.form.common.offerType.public"
      },
      {
        value: "PRIVATE",
        label: "admin.offer.form.common.offerType.private"
      },
      {
        value: "HIDDEN",
        label: "admin.offer.form.common.offerType.hidden"
      }
    ];
    commit(UPDATE_OFFER_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const offerTypesList: Module<OfferTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
