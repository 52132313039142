import { GET_FLOW_REDIRECT_MACROS } from "@core/store/action-constants";
import { UPDATE_FLOW_REDIRECT_MACROS_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FlowRedirectMacrosListState } from "@core/store/types/admin/common/FlowRedirectMacrosListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { FlowRedirectMacrosService } from "@core/services/admin/common/flowRedirectMacrosService";

const initialState = (): FlowRedirectMacrosListState => {
  return {
    data: []
  };
};

const state: () => FlowRedirectMacrosListState = initialState;

const getters: GetterTree<FlowRedirectMacrosListState, RootState> = {};

const mutations: MutationTree<FlowRedirectMacrosListState> = {
  [UPDATE_FLOW_REDIRECT_MACROS_LIST] (state, flowRedirect: FlowRedirectMacrosListState["data"]) {
    state.data = flowRedirect;
  }
};

const actions: ActionTree<FlowRedirectMacrosListState, RootState> = {
  async [GET_FLOW_REDIRECT_MACROS] ({ commit }) {
    const { data: { enumValues } } = await FlowRedirectMacrosService.getFlowRedirectMacros("FlowRedirectEnum");
    const data = enumValues.map(item => item.value);
    
    commit(UPDATE_FLOW_REDIRECT_MACROS_LIST, data);
  }
};

export const flowRedirectMacrosList: Module<FlowRedirectMacrosListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
