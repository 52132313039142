import { DELETE_DOMAIN, GET_DOMAINS, UPDATE_DNS_DOMAIN } from "@core/store/action-constants";
import { SET_DOMAINS, SET_EMPTY, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { DomainsState } from "@core/store/types/common/domains/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/webmaster/domains/domainsService";
import { domainModal } from "@core/store/modules/webmaster/domains/domainsModal";
import { Pagination } from "@core/store/types/Pagination";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import _findIndex from "lodash/findIndex";
import { pagination } from "@core/helpers/pagination";

const initialState = (): DomainsState => {
  return {
    domains: null,
    filters: {
      name: null
    }
  };
};

const state: () => DomainsState = initialState;

const mutations: MutationTree<DomainsState> = {
  [SET_DOMAINS] (state, domains: DomainsState["domains"]): void {
    state.domains = domains;
  },
  
  [UPDATE_FILTERS] (state, filters: DomainsState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_DNS_DOMAIN] (state: DomainsState, { id, checkDnsDomain: isDnsValid }): void {
    if (state.domains && state.domains.items) {
      const domains = state.domains.items;
      const idxDomain = _findIndex(domains, { id });
      domains.splice(idxDomain, 1, { ...domains[idxDomain], isDnsValid });
    }
  },

  [SET_EMPTY] (state, target?: keyof DomainsState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<DomainsState, RootState> = {
  async [GET_DOMAINS] ({ state, commit }): Promise<void> {
    const { limit, offset } = pagination(100);
    const filters = state.filters;
    
    const { data: { landingDomains } } = await DomainsService.getDomains(filters, limit, offset);
    
    commit(SET_DOMAINS, landingDomains);
  },
  
  async [UPDATE_DNS_DOMAIN] ({ commit }, id): Promise<void> {
    const checkDnsDomain = await DomainsService.updateDomains(id);
    
    commit(UPDATE_DNS_DOMAIN, { id, checkDnsDomain });
  },
  
  async [DELETE_DOMAIN] ({ commit }, domainId): Promise<void> {
    const deletedDomain = await DomainsService.deleteDomain(domainId);
    commit("LOCAL_DELETE", { items: deletedDomain, target: "domains" });
  },
  
  [UPDATE_FILTERS] ({ commit }, filters): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const domains: Module<DomainsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    domainModal
  }
};
