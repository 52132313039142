import { defineStore, Store, storeToRefs } from "pinia";
import { ref } from "@vue/composition-api";

export function useWait (store: Store, actionName: string) {
  const waitStore = defineStore(`wait/${ store.$id }/${ actionName }`, () => {
    const isLoading = ref(false);

    store.$onAction(({ after, onError, name }) => {
      if (name === actionName) {
        isLoading.value = true;
      }

      after(() => {
        if (name === actionName) {
          isLoading.value = false;
        }
      });

      onError(() => {
        if (name === actionName) {
          isLoading.value = false;
        }
      });
    });
    
    return {
      isLoading
    };
  })();

  const { isLoading } = storeToRefs(waitStore);
  return isLoading;
}