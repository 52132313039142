import {
  SET_COMPETITION_RATING,
  UPDATE_FILTERS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { CompetitionPeriodEnum } from "@core/store/types/admin/competition/enum/competitionPeriodEnum";
import { CompetitionRatingState } from "@core/store/types/admin/competition/competitionRating";
import { Module } from "@core/store/logic/Module";

export class CompetitionRatingModule<S extends CompetitionRatingState, R> extends Module<S, R> {
  private readonly initialState: () => CompetitionRatingState;

  constructor () {
    super();

    this.namespaced = true;

    this.initialState = (): CompetitionRatingState => {
      return {
        data: null,
        filters: {
          period: CompetitionPeriodEnum.CURRENT
        }
      };
    };

    // @ts-ignore
    this.state(this.initialState());

    this.mutations({
      [SET_COMPETITION_RATING] (state, competition: CompetitionRatingState["data"]): void {
        state.data = competition;
      },

      [UPDATE_FILTERS] (state, filters: CompetitionRatingState["filters"]): void {
        state.filters = { ...state.filters, ...filters };
      },

      [SET_EMPTY]: (state) => {
        Object.assign(state, this.initialState());
      }
    });

    this.actions({
      [UPDATE_FILTERS] ({ commit }, filters): void {
        commit(UPDATE_FILTERS, filters);
      },

      [SET_EMPTY] ({ commit }): void {
        commit(SET_EMPTY);
      }
    });

    return this;
  }
}
