import { StatisticFilters } from "@core/mixins/statistic/statisticFilters";
import { mapState } from "vuex";
import _get from "lodash/get";
import { SET_EMPTY } from "@core/store/action-constants";

// @vue/components
export const UsersSelect = {
  mixins: [StatisticFilters],
  
  props: {
    value: {
      type: [Array, String, null],
      default: null
    },
  
    event: {
      type: String,
      default: "input"
    },
    
    placeholder: {
      type: String,
      default: ""
    },
  
    multiple: {
      type: Boolean,
      default: false
    },
  
    lazy: {
      type: Boolean,
      default: false
    },

    intId: {
      type: Boolean,
      default: true
    },
  
    filterOptions: {
      type: Object,
      default: () => {}
    },

    excludedFilterOptions: {
      type: Object,
      default: () => {}
    },

    sortOptions: {
      type: Object,
      default: () => {}
    }
  },
  
  computed: {
    ...mapState({
      list (state) {
        return _get(state, (this.namespaceModule ?? this.defaultModuleName).replace(/\//g, ".")).data;
      }
    }),

    componentInstance () {
      const name = this.lazy ? "LazySelect" : "Select";
      return () => import(`@/components/Common/Select/${ name }`);
    }
  },
  
  methods: {
    mutation (value, params) {
      this.$store.commit(`${ this.namespaceModule ?? this.defaultModuleName }/${ value }`, params);
    }
  },
  
  beforeDestroy () {
    this.mutation(SET_EMPTY);
  }
};
