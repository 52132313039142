import Axios from "axios";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { GET_ADVERTISER_BALANCE, GET_WEBMASTER_BALANCE } from "@core/store/action-constants";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import { SET_REFERRAL_BONUS } from "@core/store/mutation-constants";
import { currencyAvailableToSort } from "@core/flowMethods";

export type BalanceItem = {
  value: number;
  currency: string;
}

export type DetailedItems = Array<{
  balance: BalanceItem;
  serviceType: string;
}>

export type BalanceItemResponse = {
  balance: BalanceItem;
  updatedAt: string;
}

export interface BalanceState {
  balance?: BalanceItem;
  updatedAt?: Date;
  referralBonus?: BalanceItem[];
}

// Делает из массива объектов объект массивов
function arrayToArrayObject (array: Array<BalanceState>) {
  return array?.reduce((acc, obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    keys.forEach((key, index) => {
      // @ts-ignore
      acc[key] ??= [];
      // @ts-ignore
      acc[key].push(values[index]);
    });
    return acc;
  }, {});
}

const state: BalanceState = {
  balance: undefined,
  referralBonus: undefined
};

const getters: GetterTree<BalanceState, RootState> = {};

const mutations: MutationTree<BalanceState> = {
  SET_BALANCE: (state, { balance }) => {
    state.balance = balance;
  },
  
  [SET_REFERRAL_BONUS]: (state, referralBonus) => {
    state.referralBonus = referralBonus;
  }
};

const actions: ActionTree<BalanceState, RootState> = {
  async [GET_WEBMASTER_BALANCE] ({ commit }) {
    try {
      const { data: { myBalance, referralBalance } } = await UsersService.myBalance();
      const balance = arrayToArrayObject(myBalance);

      commit("SET_BALANCE", { ...balance, balance: currencyAvailableToSort(balance.balance) });
      commit(SET_REFERRAL_BONUS, referralBalance);
      return balance;
    } catch (error) {
      throw error;
    }
  },
  
  [GET_ADVERTISER_BALANCE] ({ commit }): Promise<void> {
    return Axios.get("/advertiser/finances/balance").then(response => {
      // @ts-ignore
      const { balance: balanceList, ...balanceItems } = arrayToArrayObject(response.data) as Promise<void>;
      
      const balance = currencyAvailableToSort(
        // @ts-ignore
        balanceList.map(({ currency, ...items }) =>
          ({ ...items, currency: currency.toUpperCase() }))
      );
      // @ts-ignore
      commit("SET_BALANCE", { ...balanceItems, balance });
      return { ...balanceItems, balance };
    });
    
  }
};


export const balance: Module<BalanceState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
