import Full from "@/views/Full.vue";

export default {
  path: "",
  component: Full,
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: (): Promise<any> => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue")
    }
  ]
};
