import { defineStore } from "pinia";
import { computed, ref } from "@vue/composition-api";
import { AuthenticationService } from "@core/services/common/AuthenticationService";
import { Centrifuge, UnauthorizedError } from "centrifuge";
import storeInstance from "@/store";
import Cookies from "js-cookie";
import { useTrafficNotifications } from "@/stores/common/auth/sokets/notifications/trafficNotificationsSocketsStore";
import { useTrafficChat } from "@/stores/common/auth/sokets/chats/trafficChatSocketsStore";
import { useSystemOnline } from "@/stores/common/auth/sokets/system/systemOnlineSocketsStore";

export const useWebsockets = defineStore("websockets", () => {
  const token = ref(Cookies.get("socket_token") || null);

  const accessToken = computed(() => storeInstance.state.auth.token.access);
  const hasAdmin = computed(() => storeInstance.getters.role === "admin");

  const centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGE_ENDPOINT as string, {
    getToken: getSocketToken
  });

  const trafficNotificationsStore = useTrafficNotifications(centrifuge);
  const trafficChatStore = useTrafficChat(centrifuge);
  const systemOnlineStore = useSystemOnline(centrifuge);

  async function getSocketToken () {
    const { data: { socketConnect }, status } = await AuthenticationService.getSocketToken();
    token.value = socketConnect.token;
    Cookies.set("socket_token", socketConnect.token);

    if (status === 403) {
      throw new UnauthorizedError(status);
    }

    return socketConnect.token;
  }

  function connectSockets () {
    centrifuge.disconnect();
    centrifuge.setToken("");
    centrifuge.connect();
    systemOnlineStore.subscribe();

    centrifuge.on("connected", ctx => {
      // eslint-disable-next-line no-console
      console.info(`connected: ${ ctx.transport }`);
    });

    centrifuge.on("disconnected", ctx => {
      // eslint-disable-next-line no-console
      console.info(`disconnected websocket: ${ ctx.reason }`);
      systemOnlineStore.unsubscribe();
    });
  }

  function disconnectSockets () {
    centrifuge.disconnect();
    Cookies.remove("socket_token");
    $reset();
    systemOnlineStore.unsubscribe();
    trafficNotificationsStore.unsubscribe();
    trafficChatStore.unsubscribe();
  }

  function $reset () {
    token.value = "";
  }

  return {
    token,
    accessToken,
    hasAdmin,
    getSocketToken,
    connectSockets,
    disconnectSockets,
    systemOnline: systemOnlineStore,
    trafficNotifications: trafficNotificationsStore,
    trafficChat: trafficChatStore,
    $reset
  };
});