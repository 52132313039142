import { UPDATE_FAIL_RULE_OPTIONS, CREATE_RULES, SET_EMPTY, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { CrmFailRuleState } from "@core/store/types/admin/crm/crmFailRule";
import { CRMService } from "@core/services/admin/crm/CRMService";
import { DELETE_ROLE, UPDATE_MODAL_DATA } from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";

const initialState = (): CrmFailRuleState => {
  return {
    data: null,
    options: {
      failRuleType: null,
      failRuleComparison: null,
      text: null,
      spamReason: null,
      xpath: null
    },
    isModalActive: false
  };
};

const state: () => CrmFailRuleState = initialState;

const mutations: MutationTree<CrmFailRuleState> = {
  [SET_EMPTY] (state): void {
    Object.assign(state, initialState());
  },

  SET_EMPTY_OPTIONS (state): void {
    const { options } = initialState();
    Object.assign(state.options, options);
  },

  [UPDATE_FAIL_RULE_OPTIONS] (state, payload: CrmFailRuleState["options"]): void {
    state.options = { ...state.options, ...payload };
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean): void {
    state.isModalActive = payload;
  },

  [UPDATE_MODAL_DATA] (state, data: CrmFailRuleState["data"]): void {
    state.data = data;
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<CrmFailRuleState, RootState> = {
  async [CREATE_RULES] ({ state, commit, rootState }): Promise<void> {
    const { admin: { crm: { crmCreateModal: { options: { alias: crmAlias } } } } } = rootState;
    const { options } = state;
    const items = await CRMService.createFailRule({ crmAlias, ...options });

    commit("LOCAL_ADD", { items });
  },

  async [DELETE_ROLE] ({ commit }, id: string): Promise<void> {
    const items = await CRMService.deleteFailRule(id);
    commit("LOCAL_DELETE", { items });
  },

  [UPDATE_FAIL_RULE_OPTIONS] ({ commit }, payload: CrmFailRuleState["options"]): void {
    commit(UPDATE_FAIL_RULE_OPTIONS, payload);
  },

  [UPDATE_MODAL_DATA] ({ commit }, payload: CrmFailRuleState["data"]): void {
    commit(UPDATE_MODAL_DATA, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  },

  SET_EMPTY_OPTIONS ({ commit }): void {
    commit("SET_EMPTY_OPTIONS");
  }
};

export const crmFailRule: Module<CrmFailRuleState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
