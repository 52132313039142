import allowedOfferParametersGenerator from "@core/services/admin/capacityAndBumps/logRequest/graphql/allowedOfferParameters.generator";
import Service from "@core/services/common/Service";
import { AllowedOfferParameters } from "@core/store/types/admin/offers/bampsAndCaps";

export class AllowedOfferParametersService extends Service {
  public static async getAllowedOfferParameters (webmasterId: string, offerId: string, externalWebmasterId?: string): Promise<AllowedOfferParameters> {
    try {
      const { data: { allowedOfferParameters: data } } = await this.api.get("", {
        params: allowedOfferParametersGenerator(
          offerId,
          webmasterId,
          externalWebmasterId
        )
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
