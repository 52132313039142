import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import admins from "@core/services/admin/lists/graphql/admins.graphql";

export class WebmasterService extends Service {
  public static getWebmasterFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialAdmins?: UserFiltersInput,
    sort?: string,
    order: "DESC" | "ASC"
  ): Promise<AxiosPromise> {
    try {
      const initialAdminsLimit = initialAdmins?.id?.length || 0;
      
      return this.api.get("", {
        params: {
          query: admins,
          variables: {
            limit,
            offset,
            filters,
            initialAdmins,
            initialAdminsLimit,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
