import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import landings from "@core/services/common/lists/graphql/landings.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class LandingsService extends Service {
  public static getLandingFilters (
    offset = 0,
    limit = 25,
    initialLandings: UserFiltersInput = {},
    filters?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialLandingsLimit = initialLandings?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: landings,
          variables: {
            limit,
            offset,
            filters,
            initialLandings,
            initialLandingsLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
