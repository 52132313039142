import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

export interface PostbackURL {
  url: string | null;
}

export interface Postback extends PostbackURL {
  id: string | null;
  offerId: string | null;
  processingStatus: string | null;
  acceptStatus: string | null;
  spamStatus: string | null;
  cancelStatus: string | null;
}

export class PostbackService extends Service {
  public static enableOfferPostback (id: string): AxiosPromise<Postback> {
    return this.rest.post(`/webmaster/offers/${ id }/postback/enable`);
  }
  
  public static disableOfferPostback (id: string): AxiosPromise<Postback> {
    return this.rest.post(`/webmaster/offers/${ id }/postback/disable`);
  }
  
  public static getPostback (params: { offerId?: string }): AxiosPromise<Postback> {
    return this.rest.get("/webmaster/postbacks", { params });
  }
  
  public static updatePostback (params: Postback): AxiosPromise<Postback> {
    return this.rest.put("/webmaster/postbacks", params);
  }
  
  public static deletePostback (id: string): AxiosPromise<Postback> {
    return this.rest.delete(`/webmaster/postbacks/${ id }`);
  }
}
