import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { WebmasterStatisticFiltersResponse } from "@core/store/types/webmaster/statistic";

export default function statisticsGenerator (
  filters: WebmasterStatisticFiltersResponse,
  excludedFilters: BaseFiltersInput,
  groups: string[],
  isAgent: boolean
): ASTGeneratedOutput {
  const { vertical } = filters;
  const agent = isAgent ? ["webmaster", "moneyAgentCommission"] : [];
  const dataSet = {
    NUTRA: [
      "items/countLeadsWhite",
      "items/conversionCleanCr",
      "items/conversionTransitEpc",
      "items/conversionLandingEpc",
      "items/countLeadsProcessing",
      "items/moneyWebmasterAwaiting",
      "items/conversionCleanApprove",
      "items/conversionApprove",
      "items/countLeadsFilteredSpam",
      "items/countLeadsSpamByAdvertiser",
      "items/moneyDomonetizationWebmasterAccrued",
      "items/countDomonetizationClicks",
      "items/countDomonetizationLeadsCreated",
      "items/countDomonetizationLeadsAccepted",
      "items/countLandingClicks",
      "items/countTransitClicks",
      "items/conversionTransitToLanding"
    ],
    GAMBLING: [
      "items/countFirstDeposit",
      "items/countSecondDeposit",
      "items/countLeadsSpammed",
      "items/countFlowClicks",
      "items/moneyTotalDeposit",
      "items/moneyFirstDeposit",
      "items/moneySecondDeposit",
      "items/conversionCRSecondDepositToFirstDeposit",
      "items/conversionCRSecondDepositToUniques",
      "items/conversionCRFirstDepositToUniques",
      "items/conversionCRFirstDepositToLeads"
    ]
  };

  const params = {
    // @ts-ignore
    include: dataSet[vertical]
  };
  
  const gqlObject = {
    operation: "statistics",
    
    fields: [
      {
        items: [
          ...agent,
          "countLeadsCreated",
          "countLeadsAccepted",
          "countLeadsHold",
          "moneyWebmasterAccrued",
          "countLeadsCancelled",
          "countFlowClicks",
          "countLandingUniques",
          "conversionCR",
          "conversionFlowEpc",
          "offer",
          "bunch",
          "flow",
          "country",
          "externalWebmaster",
          "hours",
          "day",
          "week",
          "month",
          "year",
          "globalSource",
          "bonusCompetition",
          "region",
          "category",
          "currency",
          "utmCampaign",
          "utmContent",
          "utmMedium",
          "utmSource",
          "utmTerm",
          {
            withdraw: [
              "withdrawType"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
    
    variables: {
      filters: {
        type: "StatisticsCriteriaFilters",
        value: filters
      },
      excludedFilters: {
        type: "StatisticsCriteriaFilters",
        value: excludedFilters
      },
      groups: {
        type: "[StatisticsGroupsEnum!]",
        value: groups
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}
