<template functional>
    <span>
        {{ $options.methods.translatedName(props) }}
    </span>
</template>

<script>
  import { i18n } from "@core/plugins";
  export default {
    name: "NameTranslationOutput",
    props: {
      name: {
        type: String,
        default: null
      },
      namespace: {
        type: String,
        default: null
      }
    },
    methods: {
      translatedName ({ name, namespace }) {
        const translatedName = `${ namespace }.${ name }`;
        return i18n.te(translatedName) ? i18n.t(translatedName) : name;
      }
    }
  };
</script>

<style scoped>

</style>