




























































import TelegramInput from "@/components/Common/Select/TelegramInput.vue";
import NumberCodeInput from "@/components/Common/Fields/NumberCodeInput.vue";
import { defineEmits, defineProps, withDefaults } from "@vue/runtime-dom";
import { computed, onUnmounted } from "@vue/composition-api";
import { useCountries } from "@/stores/common/auth/countriesStore";
import { storeToRefs } from "pinia";
interface Props {
  telegram: string | null;
  whatsApp: number | null;
  viber: number | null;
  skype: string | null;
  disabled: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  telegram: {
    key: "telegram",
    required: false,
    type: [String, null],
    default: null
  },
  whatsApp: {
    key: "whatsApp",
    required: false,
    type: [Number, null],
    default: null
  },
  viber: {
    key: "viber",
    required: false,
    type: [Number, null],
    default: null
  },
  skype: {
    key: "skype",
    required: false,
    type: [String, null],
    default: null
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const store = useCountries();
  const {
    currentCountry,
    data: countriesList
  } = storeToRefs(store);
  store.getCountries();
  store.getCurrentCountry();
  const _telegram = computed({
    get: () => props.telegram,
    set: value => emit("update:telegram", value)
  });
  const _whatsApp = computed({
    get: () => props.whatsApp,
    set: value => emit("update:whatsApp", value)
  });
  const _viber = computed({
    get: () => props.viber,
    set: value => emit("update:viber", value)
  });
  const _skype = computed({
    get: () => props.skype,
    set: value => emit("update:skype", value)
  });
  onUnmounted(() => {
    store.$reset();
  });
  return {
    currentCountry,
    countriesList,
    _telegram,
    _whatsApp,
    _viber,
    _skype
  };
};
__sfc_main.components = Object.assign({
  TelegramInput,
  NumberCodeInput
}, __sfc_main.components);
export default __sfc_main;
