import { render, staticRenderFns } from "./FilterWrapper.vue?vue&type=template&id=5a1f6b52&scoped=true&functional=true"
import script from "./FilterWrapper.vue?vue&type=script&lang=js"
export * from "./FilterWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5a1f6b52",
  null
  
)

export default component.exports