import { OffersState as OriginalOffersState, WebmasterIndividualRates } from "@core/store/types/admin/offers";
import { SelectedFields } from "@core/store/types/admin/statistic/detailing/detailingState";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { RootState } from "@core/store/root-state";
import { webmasters } from "./modules/webmasters";
import { Module } from "vuex";

export interface OffersState extends OriginalOffersState {
  webmasterIndividualRates: WebmasterIndividualRates | null;
  selectedFields: SelectedFields[];
}

export const offers: Module<OffersState, RootState> = {
  namespaced: true,
  modules: {
    webmasters,
    webmastersList,
    withdrawTypesList,
    webmastersFilters: new WebmastersFilters()
  }
};
