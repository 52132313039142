import { ActionTree, Module, MutationTree } from "vuex";
import { CapacityRequestState } from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY } from "@core/store/mutation-constants";
import {
  CREATE_CANCEL_CAPACITY_REQUEST,
  UPDATE_CANCEL_CAPACITY_REQUEST,
  UPDATE_CAPACITY_REQUEST
} from "@core/store/action-constants";
import { CancelCapacityService } from "@core/services/admin/offers/CancelCapacityService";

class CancelCapacityRequest implements Module<CapacityRequestState, RootState> {
  public namespaced: boolean;
  public state: CapacityRequestState;
    
  public mutations: MutationTree<CapacityRequestState> = {};

  public actions: ActionTree<CapacityRequestState, RootState> = {
    [UPDATE_CANCEL_CAPACITY_REQUEST] ({ commit }, payload) {
      commit(UPDATE_CANCEL_CAPACITY_REQUEST, payload);
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [CREATE_CANCEL_CAPACITY_REQUEST] ({ commit }, { offerId, webmasterId, creatorComment }) {
      const { data: { cancelCapacity } } = await CancelCapacityService.createCancelCapacity(
        offerId,
        webmasterId,
        creatorComment
      );

      commit(UPDATE_CAPACITY_REQUEST, cancelCapacity);
    }
  };

  private readonly initState: () => CapacityRequestState;

  constructor () {
    this.namespaced = true;
    this.initState = (): CapacityRequestState => {
      return {
        data: {
          newCapacityValue: null,
          oldCapacityValue: null,
          creatorComment: null,
          executorComment: null,
          creator: null,
          executor: null,
          status: null,
          createdAt: null,
          updatedAt: null
        }
      };
    };

    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());

    this.state = this.initState();

    return this;
  }
}

export const cancelCapacityRequest = new CancelCapacityRequest();