import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { AdminsListResponse, PartialAdminsListState } from "@core/store/types/admin/lists/AdminsListState";
import { AxiosPromise } from "axios";
import admins from "../../admin/lists/graphql/admins.graphql";

export class AdminsListService extends Service {
  public static getAdminsList (
    filters: PartialAdminsListState["filters"], limit: number, offset: number,
    initialAdmins: PartialAdminsListState["filters"] = {}): AxiosPromise<AdminsListResponse> {

    const initialAdminsLimit = initialAdmins?.id?.length || 0;
    const isInitialAdmins = !!initialAdmins?.id;
    limit = Math.floor(initialAdminsLimit / limit + 1) * limit - initialAdminsLimit;
    return GraphQLServer.get("", {
      params: {
        query: admins,
        variables: {
          limit,
          offset,
          filters,
          initialAdmins,
          initialAdminsLimit,
          isInitialAdmins
        }
      }
    });
  }
}
