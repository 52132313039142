<template>
    <div class="banner is-relative">
        <div
            class="has-text-centered is-flex is-align-items-center"
            style="height: 100%; margin-top: 40px">
            <div v-if="currentCompetitionInfo">
                <slot name="logo"></slot>
                <p class="banner-description">
                    {{ $t(`common.auth.signIn.competition.description`) }}
                </p>
                <p class="banner-title">
                    <i18n path="common.auth.signIn.competition.title">
                        <template #strong>
                            <span
                                v-t="'common.auth.signIn.competition.subTitle'"
                                class="competition-text-style">
                            </span>
                        </template>
                        <template #linebreak>
                            <br>
                        </template>
                    </i18n>
                </p>
                <p class="banner-date">
                    {{ $t("common.auth.signIn.competition.date.from") }}
                    {{ moment(currentCompetitionInfo.startDate).locale(locale).format("D MMMM") }}
                    {{ $t("common.auth.signIn.competition.date.to") }}
                    {{ moment(currentCompetitionInfo.finishDate).locale(locale).format("D MMMM YYYY") }}
                </p>
  
                <b-button
                    class="mt-3"
                    type="is-white"
                    tag="a"
                    :href="competitionUrl"
                    target="_blank">
                    {{ $t(`admin.dashboard.competition.banner.button`) }}
                </b-button>
            </div>
        </div>
        <b-image
            style="width: 140px"
            :src="BottomImage"
            alt="Duck"
            :lazy="false">
        </b-image>
    </div>
</template>

<script>
  import moment from "moment";
  
  export default {
    name: "AuthBanner",
    
    computed: {
      currentCompetitionInfo () {
        return this.$store.state.dictionaries.currentCompetition;
      },
  
      locale () {
        return this.$root.$i18n.locale;
      },
      
      competitionUrl () {
        // TODO изменить когда бек будет присылать разную ссылку для разных языков
        return this.currentCompetitionInfo.url + `/?lang=${ this.locale }`;
      },

      BottomImage () {
        return this.locale === "ru" ? require("@/assets/Dashboard/competition/AroundWorld.png") : require("@/assets/Dashboard/competition/logo-2-en.png");
      }
    },
    methods: {
      moment
    }
  };
</script>

<style lang="scss" scoped>
    .banner {
        background-color: #16052f;
        min-width: 50%;
        width: 50%;
        background-image: url("~@/assets/Dashboard/competition/Mountain.png");
        border-top-left-radius: $radius-large;
        border-bottom-left-radius: $radius-large;
        background-position: left;
        overflow: hidden;
        background-size: cover;

        &-date {
            color: $primary-invert;
            font-size: $size-6;
            padding: $size-7 0;
        }

        &-title {
            font-size: $size-3;
            font-weight: $weight-bold;
            padding: 0 $size-6;
            color: white;
        }

        &-description {
            color: $primary-invert;
            font-size: 13px;
            padding: $size-7 $size-5;
            text-transform: uppercase;
        }

        .competition-text-style {
            color: $competition-color;
        }

        .button {
            padding: 19px 16px;
            border-radius: 10px;
        }
  
        ::v-deep {
            .button {
                font-size: $size-7;
            }
        }

        @media screen and (max-width: 825px) {
            display: none !important;
        }
    }
</style>
