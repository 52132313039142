import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { CreateCancelCapacityResponse } from "@core/store/types/admin/offers/capacity";
import createCancelCapacityQuery from "@core/services/admin/offers/graphql/createCancelCapacity.graphql";

export class CancelCapacityService extends Service {
  public static createCancelCapacity (
    offerId: string,
    webmasterId: string,
    comment?: string | null
  ): AxiosPromise<CreateCancelCapacityResponse> {
    try {
      return this.api.post("", {
        query: createCancelCapacityQuery,
        variables: {
          offerId,
          webmasterId,
          comment
        }
      });
    } catch (e) {
      throw e;
    }
  }
}