import { GET_AVAILABLE_CALLCENTERS_UTC, GET_CALLCENTERS_UTC } from "@core/store/action-constants";
import { getCallCentersUTC } from "@core/store/modules/common/callCenters";
import { mapActions, mapGetters } from "vuex";

// @vue/components
export const CallCenters = {
  computed: {
    ...mapGetters("admin", {
      callCentersUTC: GET_CALLCENTERS_UTC,
      availableCallCentersUTC: GET_AVAILABLE_CALLCENTERS_UTC
    }),
    
    currentYear () {
      return new Date().getFullYear();
    },
    
    currentMonth () {
      return ("0" + (new Date().getMonth() + 1)).slice(-2);
    },
    
    daysOfWeek () {
      return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    }
  },
  
  methods: {
    ...mapActions("admin", [
      GET_CALLCENTERS_UTC,
      GET_AVAILABLE_CALLCENTERS_UTC
    ]),
    
    getCallCentersUTC (callCenters) {
      return getCallCentersUTC(callCenters);
    },
    
    isMidnight (time) {
      if (time) {
        time = new Date(time);
        return time.getHours() === 0 && time.getMinutes() === 0;
      }
      return false;
    },
    
    getTime (time, options) {
      if (time) {
        time = new Date(time);
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          hourCycle: "h23",
          ...options
        }).format(time);
      }
    },
    
    dayEvents (events) {
      if (events == null) {
        return {
          label: this.$t("common.entity.callCenters.labels.dayOff"),
          class: "has-text-danger"
        };
      } else if (events && Array.isArray(events) && events.length === 0) {
        return {
          label: this.$t("common.entity.callCenters.labels.notBreaks"),
          class: "has-text-success"
        };
      } else if (this.isMidnight(events[0].timeStart) && this.isMidnight(events[0].timeFinish)) {
        return {
          label: this.$t("common.entity.callCenters.labels.allDay"),
          class: "has-text-success",
          tooltipText: this.$t("common.entity.callCenters.labels.allDayTooltip")
        };
      } else {
        return events.map(time => {
          if (this.isMidnight(time.timeFinish)) {
            return `${ this.getTime(time.timeStart) } – 24:00`;
          }
          
          return `${ this.getTime(time.timeStart) } – ${ this.getTime(time.timeFinish) }`;
        }).join("\n");
      }
    }
  }
};
