import { AdvertisersRate, AdvertisersRatesState } from "@core/store/types/admin/users/advertisers/Detail";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export function ratesMapper (rates: AdvertisersRate[]): AdvertisersRatesState["data"] {
  const { isShowCommissions } = useOffers();
  return {
    items: rates.map(rate => {
      const { individualWriteOff, totalWriteOff, reward } = rate;
      const { value, currency } = individualWriteOff ?? totalWriteOff;
      return {
        ...rate,
        ...isShowCommissions && { commission: { value: value - reward.value, currency } }
      };
    }),
    count: rates.length
  };
}