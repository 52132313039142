<template>
    <div class="block-box">
        <div class="flex is-justify-content-space-between">
            <div
                v-if="title"
                class="is-flex">
                <slot name="title">
                    <h5 :class="titleClass">
                        {{ title }}
                    </h5>
                </slot>
                <LTooltip
                    v-if="note"
                    :type="noteType"
                    interactive
                    :max-width="250"
                    position="is-right">
                    <!--suppress HtmlUnknownTarget -->
                    <b-icon
                        type="is-info"
                        icon="question-circle"
                        pack="far"
                        size="is-small">
                    </b-icon>
                    <template #content>
                        <p class="multilined-tooltip">
                            {{ note }}
                        </p>
                    </template>
                </LTooltip>
            </div>
            <slot name="title-content-right"></slot>
        </div>
        <div class="block-card">
            <LLoading
                v-if="loading"
                :is-full-page="false"
                active></LLoading>
            <slot v-else></slot>
        </div>
    </div>
</template>

<script>
  import LLoading from "@/components/Common/LLoading";

  export default {
    components: { LLoading },
    props: {
      title: {
        type: String,
        // eslint-disable-next-line no-irregular-whitespace
        default: " " //  	&#8195;	широкий пробел (em-шириной в букву m)
      },
      note: {
        type: String,
        default: null
      },
      noteType: {
        type: String,
        default: "is-white"
      },
      loading: {
        type: Boolean,
        default: false
      },
      titleClass: {
        type: String,
        default: "has-text-weight-medium title is-5 mb-3 ml-5"
      }
    }
  };
</script>

<style lang="scss" scoped>
  .multilined-tooltip {
    white-space: pre-line !important
  }

  .block-box {
    min-height: 83px;
    position: relative;
  }

  ::v-deep {
    .b-tooltip {
      &.is-multiline:after {
        text-align: left;
        white-space: pre-wrap;
      }

      .icon {
        margin-left: 0.25em;
      }
    }
  }
</style>
