import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { AdvertisersCapacityFilters, OffersCapacityState } from "@core/store/types/admin/offers/capacity";

export default function offerAdvertisersCapacity (
  limit: number,
  offset: number,
  advertiserId: string,
  filters: AdvertisersCapacityFilters,
  sort: OffersCapacityState["sort"],
  order: OffersCapacityState["order"],
  isRequest: boolean,
  isCommon: boolean,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const include = [];
  
  if (isShowRedemption) {
    include.push("items/statisticCapacity/monthRedemption");
  }
  
  if (isCommon) {
    include.push(
      "items/statisticCapacityByOffer/yesterdayCapacityIndicator",
      "items/statisticCapacityByOffer/weekCapacityIndicator",
      "items/statisticCapacityByOffer/monthCapacityIndicator",
      "items/statisticCapacityByOffer/yesterdayCapacityEfficiency",
      "items/statisticCapacityByOffer/weekCapacityEfficiency",
      "items/statisticCapacityByOffer/monthCapacityEfficiency"
    );
  } else {
    include.push(
      "items/statisticCapacity/yesterdayCapacityIndicator",
      "items/statisticCapacity/weekCapacityIndicator",
      "items/statisticCapacity/monthCapacityIndicator",
      "items/statisticCapacity/yesterdayCapacityEfficiency",
      "items/statisticCapacity/weekCapacityEfficiency",
      "items/statisticCapacity/monthCapacityEfficiency"
    );
  }
  
  const params = {
    include
  };
  
  const gqlObject = {
    operation: "offerAdvertisersCapacity",
  
    fields: [
      {
        items: [
          {
            offer: [
              "id",
              "slug",
              "intId",
              {
                detail: [
                  "name"
                ]
              },
              "isLaunched"
            ]
          },
          "filledCapacity",
          "softCapacity",
          "bookingCapacity",
          "updatedAt",
          {
            statisticCapacity: [
              "yesterdayLeads",
              "weekLeads",
              "monthLeads"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
  
    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      advertiserId: {
        type: "Uid!",
        value: advertiserId
      },
      filters: {
        type: "OfferAdvertiserCapacityListCriteriaFilters",
        value: filters
      },
      sort: {
        type: "OfferAdvertiserCapacitySort",
        value: sort[advertiserId] ?? "yesterdayLeads"
      },
      order: {
        type: "SortOrder",
        value: order[advertiserId] ?? "ASC"
      },
      isRequest: {
        type: "Boolean!",
        value: isRequest
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}