import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";
import useSaas from "../../../../../../../saas/saas.config";
import { useVerticals } from "../../../../../../../stores/common/auth/verticalsStore";

export default function statisticsNew (
  limit: number,
  offset: number,
  filters: BaseFiltersInput,
  excludedFilters: BaseFiltersInput,
  sort: string | null,
  order: string | null,
  groups: string[],
  hasPayout: boolean,
  payoutAnalyze: boolean
): ASTGeneratedOutput {
  const user = ["id", "intId", "login"];
  const input = ["id", "name"];

  const { isShowCommissions } = useOffers();
  const { isNutra } = useVerticals();
  const include = [];

  if (hasPayout) {
    include.push("items/conversionPayout");
  }

  if (useSaas().main && isNutra) {
    include.push("items/targetSex", "items/targetAge");
  }

  const params = {
    include,
    exclude: !isShowCommissions ? [
      "items/moneyAdvertiserWrittenOff",
      "items/moneyWebmasterAccrued",
      "items/moneyCommission",
      "items/moneyLeadCommission",
      "items/moneyDomonetizationWebmasterAccrued",
      "items/moneyDomonetizationCommission",
      "items/averageMoneyCommission"
    ] : []
  };

  const gqlObject = {
    operation: "statisticsNew",

    fields: [
      {
        items: [
          "countLeadsCreated",
          "countLeadsAccepted",
          "countLeadsProcessing",
          "countLeadsCancelled",
          "countLeadsSpammed",
          "countLeadsIntegrated",
          "countLeadsSpamByAdvertiser",
          "countLeadsFilteredSpam",
          "countDomonetizationLeadsCreated",
          "countDomonetizationLeadsAccepted",
          "countLeadsHold",
          "countFirstDeposit",
          "countSecondDeposit",
          { colorPayout: ["r", "g", "b"] },
          "conversionApprove",
          "conversionCleanApprove",
          "conversionCRFirstDepositToUniques",
          "conversionCRSecondDepositToUniques",
          "conversionCRFirstDepositToLeads",
          "conversionCRSecondDepositToFirstDeposit",
          "moneyAdvertiserProcessing",
          "moneyAdvertiserWrittenOff",
          "moneyAdvertiserCancelled",
          "moneyWebmasterAwaiting",
          "actualWebmasterIntId",
          "moneyWebmasterAccrued",
          "moneyCommission",
          "moneyLeadCommission",
          "bonusCompetition",
          "moneyDomonetizationWebmasterAccrued",
          "moneyDomonetizationCommission",
          "moneyTotalDeposit",
          "moneyFirstDeposit",
          "moneySecondDeposit",
          "countDomonetizationClicks",
          "conversionDomonetization",
          "averageMoneyCommission",
          "countLandingClicks",
          "countFlowClicks",
          "countTransitClicks",
          "countLandingUniques",
          "conversionCR",
          "conversionLandingEpc",
          "conversionTransitEpc",
          "conversionCleanCr",
          "conversionFlowEpc",
          "conversionTransitToLanding",
          "bonusCompetition",
          {
            offer: [
              "id",
              "intId",
              {
                detail: [
                  "name"
                ]
              },
              "isLaunched"
            ]
          },
          {
            bunch: [
              "transit",
              "landing"
            ]
          },
          {
            flow: input
          },
          {
            webmaster: user
          },
          {
            advertiser: user
          },
          {
            country: [
              "alpha2",
              "alpha3",
              ...input
            ]
          },
          "externalWebmaster",
          "webmasterLabel",
          "hours",
          "day",
          "week",
          "month",
          "year",
          {
            agent: user
          },
          {
            category: input
          },
          {
            withdraw: [
              "withdrawType",
              "subGoalType"
            ]
          },
          {
            region: input
          },
          {
            manager: user
          },
          "currency",
          "globalSource",
          "utmCampaign",
          "utmContent",
          "utmMedium",
          "utmSource",
          "utmTerm"
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      filters: {
        type: "StatisticsCriteriaFilters",
        value: filters
      },
      excludedFilters: {
        type: "StatisticsCriteriaFilters",
        value: excludedFilters
      },
      sort: {
        type: "StatisticsSortEnum",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      },
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      groups: {
        type: "[StatisticsGroupsEnum!]!",
        value: groups
      },
      payoutAnalyze: {
        type: "Boolean!",
        value: payoutAnalyze
      }
    }
  };

  return Generator.query(gqlObject, params);
}