import { FlowService } from "@core/services/webmaster/FlowService";

import {
  CREATE_BASE_LINK,
  CREATE_LINK,
  GENERATE_URL,
  GET_FLOW,
  GET_FLOW_PARAMETERS,
  UPDATE_FIELDS
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_FIELDS,
  UPDATE_URL,
  SET_OPTIONS,
  SET_MODAL_ACTIVE,
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { EditLinkModalState } from "@core/store/types/webmaster/flows/modules/FlowsEditLinkModal";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { globalSourceFilters } from "@core/store/modules/common/globalSource";
import { Flow } from "@core/store/types/webmaster/flows";

const fieldsMap: Record<string, string> = {
  "utmSource": "utm_source",
  "utmTerm": "utm_term",
  "utmMedium": "utm_medium",
  "utmContent": "utm_content",
  "utmCampaign": "utm_campaign",
  "clickId": "clickid",
  "externalWebmasterId": "externalWebmasterId",
  "globalSource": "globalSource"
};

const initialState = (): EditLinkModalState => {
  return {
    isModalActive: false,
    options: null,
    url: null,
    fields: {
      utmSource: null,
      utmTerm: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      clickId: null,
      externalWebmasterId: null,
      globalSource: null
    }
  };
};

const state: () => EditLinkModalState = initialState;

const getters: GetterTree<EditLinkModalState, RootState> = {
  [CREATE_LINK] (state, getters) {
    return (options = state.options as EditLinkModalState["options"]): URL => {
      const createBaseLink = getters.CREATE_BASE_LINK;
      const url = createBaseLink(options);
      
      for (const [key, value] of Object.entries(state.fields)) {
        const name = fieldsMap[key] ?? key;
        
        if (value && typeof value === "string") {
          url.searchParams.append(name, value);
        }
      }
      
      return url;
    };
  },

  [CREATE_BASE_LINK] () {
    return ({ id, options, isHttps, link }: Flow): URL => {
      if (link) {
        return new URL(link);
      } else {
        const { webmasterDomain: domain } = options ?? {};
        const protocol = isHttps ? "https://" : "http://";
        const urlParam = domain ? `${ protocol + domain }` : process.env.VUE_APP_FLOWS_URL;

        const url = new URL("/", urlParam);

        url.searchParams.append("flow", window.btoa(id));

        return url;
      }
    };
  }
};

const mutations: MutationTree<EditLinkModalState> = {
  [SET_MODAL_ACTIVE] (state, value): void {
    state.isModalActive = value;
  },
  
  [SET_OPTIONS] (state, options): void {
    state.options = options;
  },
  
  [SET_FIELDS] (state, fields): void {
    state.fields = { ...state.fields, ...fields };
  },
  
  [UPDATE_URL] (state, url): void {
    state.url = url;
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<EditLinkModalState, RootState> = {
  async [GET_FLOW] ({ state }) {
    if (state.options?.id) {
      const { data: { landingFlow } } = await FlowService.getFlow(state.options.id);

      return landingFlow;
    }
  },

  async [GET_FLOW_PARAMETERS] ({ dispatch, commit }) {
    const { options } = await dispatch(GET_FLOW);
    const payload: Record<string, string | null> = {};
  
    for (const [key, value] of Object.entries(options) as [string, string][]) {
      if (key.endsWith("FieldName") && value) {
        payload[value] = null;
      }
    }

    commit(SET_FIELDS, payload);
    dispatch(GENERATE_URL);
  },
  
  [GENERATE_URL] ({ commit, getters }): void {
    const url = getters.CREATE_LINK();

    commit(UPDATE_URL, url);
  },

  [SET_OPTIONS] ({ commit, dispatch }, options: EditLinkModalState["options"]): void {
    commit(SET_OPTIONS, options);
    dispatch(GENERATE_URL);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_FIELDS] ({ commit, dispatch }, fields: EditLinkModalState["fields"]): void {
    commit(SET_FIELDS, fields);
    dispatch(GENERATE_URL);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
    commit(`globalSourceFilters/${ SET_EMPTY }`);
  }
};

export const editLinkModal: Module<EditLinkModalState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    globalSourceFilters
  }
};
