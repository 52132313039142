import Service from "@core/services/common/Service";
import { BindAdminInput, AdvertisersState } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AxiosPromise } from "axios";
import advertisersQuery from "./graphql/advertisers.graphql";
import approveAdvertiserQuery from "./graphql/approveAdvertiser.graphql";
import bindAdvertiserQuery from "./graphql/bindAdvertiser.graphql";
import blockAdvertiserQuery from "./graphql/blockAdvertiser.graphql";
import unblockAdvertiserQuery from "./graphql/unblockAdvertiser.graphql";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

export class AdvertisersService extends Service {
  public static async getAdvertisers (limit: number,
    offset: number,
    filters: AdvertisersState["filters"],
    sort: AdvertisersState["sort"],
    order: AdvertisersState["order"]
  ) {
    try {
      const { data: { advertisers } } = await this.api.get("", {
        params: {
          query: advertisersQuery,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
      return advertisers;
    } catch (e) {
      throw e;
    }
  }

  public static async approveAdvertiser (
    advertiserId: string,
    vertical: VerticalsEnum
  ): Promise<AxiosPromise> {
    try {
      const { data: { approveAdvertiser } } = await this.api.post("", {
        query: approveAdvertiserQuery,
        variables: {
          advertiserId,
          vertical
        }
      });
      
      return approveAdvertiser;
    } catch (e) {
      throw e;
    }
  }

  public static async bindAdvertiser (advertiserId: string, input: BindAdminInput): Promise<AxiosPromise> {
    try {
      const { data: { bindAdvertiser } } = await this.api.post("", {
        query: bindAdvertiserQuery,
        variables: {
          advertiserId,
          input
        }
      });
      
      return bindAdvertiser;
    } catch (e) {
      throw e;
    }
  }

  public static async blockAdvertiser (advertiserId: string): Promise<AxiosPromise> {
    try {
      const { data: { blockAdvertiser } } = await this.api.post("", {
        query: blockAdvertiserQuery,
        variables: {
          advertiserId
        }
      });
      
      return blockAdvertiser;
    } catch (e) {
      throw e;
    }
  }

  public static async unblockAdvertiser (advertiserId: string): Promise<AxiosPromise> {
    try {
      const { data: { unblockAdvertiser } } = await this.api.post("", {
        query: unblockAdvertiserQuery,
        variables: {
          advertiserId
        }
      });
      
      return unblockAdvertiser;
    } catch (e) {
      throw e;
    }
  }
}
