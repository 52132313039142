<template functional>
    <div :class="props.wrapperClass">
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: "FilterWrapper",
    props: {
      wrapperClass: {
        type: String,
        default: "column is-6-real-mobile is-4-tablet is-3-desktop is-one-fifth-fullhd is-2-real-fullhd"
      }
    }
  };
</script>

<style scoped>
</style>