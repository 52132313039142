import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { GET_REASONS } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_REASONS_LIST } from "@core/store/mutation-constants";
import Vue from "../../../../../../main";

interface ReasonsListState {
  data: Array<{ label: string, value: number }>
}

const initialState = (): ReasonsListState => {
  return {
    data: []
  };
};

const state: () => ReasonsListState = initialState;

const mutations: MutationTree<ReasonsListState> = {
  [UPDATE_REASONS_LIST] (state, payload) {
    state.data = payload;
  },

  [SET_EMPTY]: (state) => Object.assign(state, initialState())
};

const actions: ActionTree<ReasonsListState, RootState> = {
  [GET_REASONS] ({ commit }) {
    const items: ReasonsListState["data"] = [];
    Object.entries(Vue.$t("common.entity.cancelReason")).forEach(([key, label]) => {
      const value = Number(key);
      if (!isNaN(value)) {
        items.push({ label, value });
      }
    });

    commit(UPDATE_REASONS_LIST, items);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const reasonsList: Module<ReasonsListState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};