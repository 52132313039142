import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { CorrectionHistoryState } from "@core/store/types/admin/finances/financesCorrection/FinancesCorrectionHistoryState";
import createAutoOutTransaction from "@core/services/webmaster/finances/graphql/createAutoOutTransaction.graphql";
import deleteAutoOutTransaction from "@core/services/webmaster/finances/graphql/deleteAutoOutTransaction.graphql";
import editAutoOutTransaction from "@core/services/webmaster/finances/graphql/editAutoOutTransaction.graphql";
import {
  FinancesAutoPaymentsList,
  FinancesAutoPaymentsListData
} from "@core/store/types/webmaster/finances/modal/financeAutoPaymentsList";
import webmasterTransactions from "@core/services/webmaster/finances/graphql/webmasterTransactions.graphql";
import autoOutTransactions from "@core/services/webmaster/finances/graphql/autoOutTransactions.graphql";
import orderOutTransaction from "@core/services/webmaster/finances/graphql/orderOutTransaction.graphql";
import cancelTransaction from "@core/services/webmaster/finances/graphql/cancelTransaction.graphql";
import { OrderOutTransaction } from "@core/store/types/webmaster/finances";

export class FinancesService extends Service {
  public static getFinances (
    limit: number,
    offset: number,
    filters: CorrectionHistoryState["filters"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: webmasterTransactions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static addFinances (input: OrderOutTransaction): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: orderOutTransaction,
          variables: {
            input
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async rejectCost (transactionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: cancelTransaction,
        variables: {
          transactionId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async createAutoOutTransaction (input: any): Promise<AxiosPromise> {
    try {
      const { data: { createAutoOutTransaction: data } } = await this.api.post("", {
        query: createAutoOutTransaction,
        variables: {
          input
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getAutoOutTransactions (): Promise<FinancesAutoPaymentsListData[]> {
    try {
      const { data: { autoOutTransactions: data } } = await this.api.post("", {
        query: autoOutTransactions
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async deleteAutoOutTransactions (autoOutTransactionId: string): Promise<FinancesAutoPaymentsList> {
    try {
      const { data: { deleteAutoOutTransaction: data } } = await this.api.post("", {
        query: deleteAutoOutTransaction,
        variables: { autoOutTransactionId }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async editAutoOutTransaction (autoOutTransactionId: string | undefined, input: any): Promise<FinancesAutoPaymentsList> {
    try {
      const { data: { editAutoOutTransaction: data } } = await this.api.post("", {
        query: editAutoOutTransaction,
        variables: { autoOutTransactionId, input }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
