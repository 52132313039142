import { MacrosListService } from "@core/services/admin/crm/lists/MacrosListService";
import { GET_MACROS } from "@core/store/action-constants";
import { UPDATE_MACROS_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { MacrosListState } from "@core/store/types/admin/crm/lists/MacrosListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): MacrosListState => {
  return {
    data: {}
  };
};

const state: () => MacrosListState = initialState;

const getters: GetterTree<MacrosListState, RootState> = {};

const mutations: MutationTree<MacrosListState> = {
  [UPDATE_MACROS_LIST] (state, macros: MacrosListState["data"]) {
    state.data = macros;
  }
};

const actions: ActionTree<MacrosListState, RootState> = {
  async [GET_MACROS] ({ commit }) {
    const { data: { crmListMacro } } =
            await MacrosListService.crmListMacro();
    commit(UPDATE_MACROS_LIST, crmListMacro);
  }
};

export const macrosList: Module<MacrosListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
