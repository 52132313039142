<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :is-loading="isProfileLoading"
            @submit.prevent.stop="updateUser">
            <template #header>
                <div class="container">
                    <div class="columns is-vcentered">
                        <h5 class="title column has-text-left is-5 mb-0">
                            {{ $t(`advertiser.profile.labels.title`) }}
                        </h5>
                        <div class="column has-text-right">
                            <label v-if="isConfirmed">
                                <LTooltip
                                    :label="$t(`common.mailConfirmation.resendEmail.resend`)"
                                    class="mr-2"
                                    position="is-left"
                                    type="is-warning">
                                    <span
                                        class="button is-outlined is-warning"
                                        @click="resendEmail">
                                        <b-icon
                                            icon="envelope"
                                            pack="fas"
                                            size="is-small"></b-icon>
                                    </span>
                                </LTooltip>
                            </label>
                            <label>
                                <LTooltip
                                    :label="profileEditLabel"
                                    position="is-left"
                                    type="is-success">
                                    <input
                                        v-model="isEdit"
                                        class="is-hidden"
                                        type="checkbox">
                                    <span
                                        :class="isEdit ? 'is-light' : 'is-outlined'"
                                        class="button is-success">
                                        <b-icon
                                            :type="isEdit ? 'is-success' : ''"
                                            icon="pen"
                                            pack="fas"
                                            size="is-small"></b-icon>
                                    </span>
                                </LTooltip>
                            </label>
                        </div>
                    </div>
                </div>
            </template>

            <AdvertiserProfile
                :is-edit="!isEdit"
                :user.sync="localForm">
            </AdvertiserProfile>

            <ActiveSessions
                v-if="!asAdmin"
                @userLogout="isActive = false">
            </ActiveSessions>

            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                v-if="isEdit"
                                :loading="isProfileLoading"
                                native-type="submit"
                                class="is-fullwidth is-success is-light">
                                {{ $t(`advertiser.profile.values.save`) }}
                            </b-button>
                            <b-button
                                v-else
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="openPasswordModal">
                                {{ $t(`advertiser.profile.values.changePassword`) }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-light"
                                expanded
                                @click="isActive = false">
                                {{ $t(`advertiser.profile.values.cancel`) }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>

        <AdvertiserProfilePasswordModal></AdvertiserProfilePasswordModal>
    </div>
</template>

<script>
  import AdvertiserProfile from "@/components/Common/Advertiser/AdvertiserProfile";
  import AdvertiserProfilePasswordModal from "@/components/Common/Advertiser/AdvertiserProfilePasswordModal";
  import { EDIT_USER, FETCH_USER, GET_TOKEN, RESEND_EMAIL, SET_EMPTY } from "@core/store/action-constants";
  import ActiveSessions from "@/components/Common/Profile/ActiveSessions";
  import { UPDATE_ADVERTISER_FORM, UPDATE_MODAL } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { UserForm } from "@core/store/logic/advertiser/UserForm";

  export default {
    name: "AdvertiserProfileModal",

    components: {
      ModalBox,
      ActiveSessions,
      AdvertiserProfile,
      AdvertiserProfilePasswordModal
    },

    data () {
      return {
        state: "profile",
        localForm: null,
        isEdit: false
      };
    },
    computed: {
      ...mapFields("advertiser/profile", {
        fields: [
          "isActive"
        ],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("advertiser/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapState("advertiser/profile", [
        "user", "form"
      ]),

      ...mapGetters(["asAdmin"]),

      isProfileLoading () {
        return this.$wait(`advertiser/profile/${ FETCH_USER }`) || this.$wait(`advertiser/profile/${ EDIT_USER }`);
      },

      profileEditLabel () {
        return this.isEdit
          ? this.$t("advertiser.profile.values.cancelEditProfile")
          : this.$t("advertiser.profile.values.editProfile");
      },

      isConfirmed () {
        return !this.$store.state.auth.isConfirmed;
      }
    },
    methods: {
      ...mapActions({
        GET_TOKEN
      }),

      ...mapActions("advertiser/profile", [
        FETCH_USER,
        EDIT_USER,
        RESEND_EMAIL
      ]),

      ...mapMutations("advertiser/profile", [
        UPDATE_ADVERTISER_FORM,
        SET_EMPTY
      ]),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      convertUserToForm () {
        this.UPDATE_ADVERTISER_FORM(new UserForm(this.user));
        this.localForm = _cloneDeep(this.form);
      },

      async updateUser () {
        try {
          await this.EDIT_USER();
          this.GET_TOKEN();

          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });

          this.SET_EMPTY();
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileErrorConfirmation"),
            type: "is-danger"
          });
        }
      },

      async resendEmail () {
        try {
          await this.RESEND_EMAIL();

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      }
    },

    watch: {
      async isActive (value) {
        if (value) {
          this.isEdit = false;
          this.state = "profile";

          await this.FETCH_USER();
        }
        this.convertUserToForm();
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADVERTISER_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.isActive = !value;
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card, .modal-card-body {
      overflow: visible;
    }

    .modal-card-foot {
      border-radius: 0 0 10px 10px;
    }

    .modal-card-head {
      border-radius: 10px 10px 0 0;
    }

    .modal-card {
      max-width: 700px;
      width: 100vw;
    }
  }
</style>
