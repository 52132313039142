import { Integration, IntegrationResponse, Integrations } from "@core/logic/admin/integrations/integrations";
import { PaginationOutput } from "@core/store/types/PaginationOutput";

export function integrationsMapper (integrations: PaginationOutput<IntegrationResponse[]>): Partial<Integrations> {
  // @ts-ignore
  integrations.items = integrations.items?.map((item: IntegrationResponse) => processData(item));
  return integrations;
}

export function CreateIntegrationMapper (integration: IntegrationResponse): Integration {
  return processData(integration);
}

function processData (item: IntegrationResponse): Integration {
  const processItem = {
    ...item,
    offer: {
      id: item.offer.id,
      intId: item.offer.intId,
      slug: item.offer.slug,
      name: item.offer.detail.name
    }
  };
  
  return new Integration(processItem);
}