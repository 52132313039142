import { FinancesService } from "@core/services/webmaster/finances/FinancesService";
import { FinancesAutoPaymentsList } from "@core/store/types/webmaster/finances/modal/financeAutoPaymentsList";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { defineStore } from "pinia";
import vuexStore from "@/store";

export const useFinanceAutoPaymentsListModal = defineStore("webmaster/finance/autoPaymentsList", {
  state: (): FinancesAutoPaymentsList => ({
    isActive: false,
    data: null
  }),

  getters: {
    balance: () => vuexStore.state.balance.balance
  },

  actions: {
    async GET_AUTO_OUT_TRANSACTIONS (): Promise<void> {
      const items = await FinancesService.getAutoOutTransactions();
      this.data = { items, count: items.length };
    },

    async DELETE_AUTO_OUT_TRANSACTIONS (id: string): Promise<void> {
      const item = await FinancesService.deleteAutoOutTransactions(id);
      useStateUpdater(useFinanceAutoPaymentsListModal).LOCAL_DELETE({ items: [item] });
    }
  }
});
