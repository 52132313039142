<template>
    <div>
        <BlockBox
            :title-class="titleClass"
            :loading="isLoading"
            :title="title">
            <div class="row-grid row-grid--checkbox-cover">
                <div
                    :key="i"
                    v-for="(type, i) in trafficTypes">
                    <b-checkbox
                        :value="isUserTrafficType(type)"
                        @input="changeTrafficType(type)">
                        {{ $t(`dictionaries.trafficTypes.${ type.name }`) }}
                    </b-checkbox>
                </div>
            </div>
        </BlockBox>
    
        <b-button
            v-if="separate && !isLoading"
            expanded
            type="is-success is-light"
            @click="updateTrafficTypes">
            {{ $t(`common.buttons.save`) }}
        </b-button>
    </div>
</template>

<script>
  import { GET_TRAFFIC_TYPES } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading.vue";
  import BlockBox from "@/components/Common/BlockBox.vue";
  
  export default {
    name: "EditTrafficTypes",
    components: { BlockBox },

    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: Array,
        default: () => []
      },
      separate: {
        type: Boolean,
        default: false
      },
      titleClass: {
        type: String,
        default: undefined
      }
    },

    created () {
      this.GET_TRAFFIC_TYPES();
    },

    computed: {
      ...mapState("trafficTypes", {
        trafficTypes: ({ trafficTypes }) => trafficTypes
      }),

      isLoading () {
        return this.$wait(`trafficTypes/${ GET_TRAFFIC_TYPES }`);
      }
    },

    methods: {
      ...mapActions("trafficTypes", {
        GET_TRAFFIC_TYPES
      }),

      changeTrafficType (type) {
        const newTrafficTypes = this.isUserTrafficType(type) ?
          this.value.filter(id => id !== type.id) :
          [ ...this.value || [], type.id ];
        this.$emit("input", newTrafficTypes);
      },

      isUserTrafficType (type) {
        return this.value ? this.value.includes(type.id) : false;
      },

      updateTrafficTypes () {
        this.$emit("update");
      }
    }
  };
</script>

<style scoped>

</style>
