<template>
    <!--    <div :class="{ 'checkbox-wrapper': accent }">-->
    <b-switch
        v-if="switchable"
        v-model="model"
        v-bind="$attrs"
        :class="{ 'is-flex-direction-row-reverse': reversed }">
        <slot name="label">
            <span v-if="label">{{ label }}</span>
        </slot>
    </b-switch>
    <b-checkbox
        v-else
        v-model="model"
        v-bind="$attrs"
        :indeterminate="indeterminate"
        :native-value="nativeValue">
        <slot name="label">
            <span v-if="label">{{ label }}</span>
        </slot>
    </b-checkbox>
<!--        <b-tag-->
<!--            v-if="accent"-->
<!--            class="checkbox-tag"-->
<!--            size="is-small"-->
<!--            type="is-success">-->
<!--            NEW-->
<!--        </b-tag>-->
<!--    </div>-->
</template>

<script>
  import { OfferLabelsToColorsEnum } from "@core/store/types/admin/offers/enums/offerLabelsToColorsEnum";
  import { OfferLabelsEnum } from "@core/store/types/admin/offers/enums/offerLabelsEnum";

  export default {
    props: {
      value: {
        type: null,
        default: null
      },
      accent: {
        type: Boolean,
        default: false
      },
      nativeValue: {
        type: null,
        default: null
      },
      indeterminate: {
        type: Boolean,
        default: false
      },
      switchable: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ""
      },
      reversed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      OfferLabelsEnum () {
        return OfferLabelsEnum;
      },
      OfferLabelsToColorsEnum () {
        return OfferLabelsToColorsEnum;
      },
      model: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  span {
    font-size: 0.875em;
  }

  .checkbox-tag {
      position: absolute;
      top: -50%;
      right: 0;
      transform: translate(15%, 80%);
      padding: 0 8px;
      font-size: 10px;
  }

  .checkbox-wrapper {
      padding: 8px 10px;
      background: $primary-light;
      border-radius: 6px;
      position: relative;
  }

  .switch.is-flex-direction-row-reverse {
      margin-right: 0;
      column-gap: .5em;
  }
</style>
