import Service from "@core/services/common/Service";
import { BindAdminInput } from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { AxiosPromise } from "axios";
import approveWebmasterQuery from "./graphql/approveWebmaster.graphql";
import bindWebmasterQuery from "./graphql/bindWebmaster.graphql";
import blockWebmasterQuery from "./graphql/blockWebmaster.graphql";
import disableAccessToApiForWebmasterQuery from "./graphql/disableAccessToApiForWebmaster.graphql";
import enableAccessToApiForWebmasterQuery from "./graphql/enableAccessToApiForWebmaster.graphql";
import unblockWebmasterQuery from "./graphql/unblockWebmaster.graphql";
import webmasters from "./graphql/webmasters.graphql";
import checkWebmaster from "./graphql/checkWebmaster.graphql";
import disableReferralQuery from "./graphql/disableReferral.graphql";
import enableReferralQuery from "./graphql/enableReferral.graphql";
import { WebmastersState } from "@core/store/types/admin/users/webmasters/WebmastersState";

export class WebmastersService extends Service {
  public static getWebmasters (
    limit: number,
    offset: number,
    filters: WebmastersState["filters"],
    sort?: WebmastersState["sort"],
    order?: WebmastersState["order"]
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: webmasters,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async approveWebmaster (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { approveWebmaster } } = await this.api.post("", {
        query: approveWebmasterQuery,
        variables: {
          webmasterId
        }
      });
      return approveWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async enableAccessToApiForWebmaster (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { enableAccessToApiForWebmaster } } = await this.api.post("", {
        query: enableAccessToApiForWebmasterQuery,
        variables: {
          webmasterId
        }
      });
      return enableAccessToApiForWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async disableAccessToApiForWebmaster (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { disableAccessToApiForWebmaster } } = await this.api.post("", {
        query: disableAccessToApiForWebmasterQuery,
        variables: {
          webmasterId
        }
      });
      return disableAccessToApiForWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async blockWebmaster (webmasterId: string, transferBalance = false): Promise<AxiosPromise> {
    try {
      const { data: { blockWebmaster: blockWebmaster } } = await this.api.post("", {
        query: blockWebmasterQuery,
        variables: {
          webmasterId,
          transferBalance
        }
      });
      return blockWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async unblockWebmaster (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { unblockWebmaster } } = await this.api.post("", {
        query: unblockWebmasterQuery,
        variables: {
          webmasterId
        }
      });
      return unblockWebmaster;
    } catch (e) {
      throw e;
    }
  }
  
  public static async bindWebmaster (webmasterId: string, input: BindAdminInput): Promise<AxiosPromise> {
    try {
      const { data: { bindWebmaster } } = await this.api.post("", {
        query: bindWebmasterQuery,
        variables: {
          webmasterId,
          input
        }
      });
      return bindWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async checkWebmaster (webmasterTelegram: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: checkWebmaster,
          variables: {
            identifier: webmasterTelegram
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async enableReferral (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { enableReferral } } = await this.api.get("", {
        params: {
          query: enableReferralQuery,
          variables: {
            webmasterId
          }
        }
      });
      return enableReferral;
    } catch (e) {
      throw e;
    }
  }
  
  public static async disableReferral (webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { disableReferral } } = await this.api.get("", {
        params: {
          query: disableReferralQuery,
          variables: {
            webmasterId
          }
        }
      });
      return disableReferral;
    } catch (e) {
      throw e;
    }
  }
}
