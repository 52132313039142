// @vue/components
export const CopyToClipboard = {
  methods: {
    copyToClipboard () {
      navigator.clipboard.writeText(`${ this.$attrs.value }`);
      this.$buefy.toast.open({
        message: this.$t("common.components.customInput.messages.success"),
        type: "is-success"
      });
    }
  }
};
