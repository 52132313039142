<template functional>
    <div class="wrapper">
        <div>
            <h1 v-t="'common.errors.404'"></h1>
        </div>
    </div>
</template>

<script>
  export default {
    name: "URLNotFound"
  };
</script>

<style lang="scss" scoped>
    .wrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: fixed;
    }
</style>
