import { AuditService } from "@core/services/admin/audit/AuditService";
import { GET_AUDIT } from "@core/store/action-constants";
import {
  SET_AUDIT,
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AuditState } from "@core/store/types/admin/audit";
import { ActionTree, Module, MutationTree } from "vuex";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";

const initialState = (): AuditState => {
  return {
    data: null,
    filters: {
      datepicker: datepicker(),
      nameOrIntId: null,
      executor: null
    },
    modal: {
      isActive: false,
      offer: null
    }
  };
};

export const state: () => AuditState = initialState;

const mutations: MutationTree<AuditState> = {
  [SET_AUDIT] (state: AuditState, audit: AuditState["data"]): void {
    state.data = audit;
  },
  
  [UPDATE_FILTERS] (state: AuditState, filters: AuditState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_MODAL] (state: AuditState, modal: AuditState["modal"]): void {
    state.modal = { ...state.modal, ...modal };
  },
  
  [SET_EMPTY] (state: AuditState, target?: keyof AuditState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<AuditState, RootState> = {
  async [GET_AUDIT] ({ state, commit }): Promise<void> {
    const { filters: { datepicker, ...otherFilters } } = state;
    const { limit, offset } = pagination();

    const data = await AuditService.getOfferAudits(limit, offset, { ...datepicker, ...otherFilters });
    commit(SET_AUDIT, data);
  },
  
  [UPDATE_FILTERS] ({ commit }, filter): void {
    commit(UPDATE_FILTERS, filter);
  },
  
  [UPDATE_MODAL] ({ commit }, modal): void {
    commit(UPDATE_MODAL, modal);
  },
  
  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`offersList/${ SET_EMPTY }`);
  }
};

export const audit: Module<AuditState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    offersList
  }
};
