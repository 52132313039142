<template>
    <div
        class="aside-menu-button is-clickable"
        :class="{ 'is-active': isActive }"
        @click="toggleActiveAsideMenu">
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script>
  export default {
    name: "BurgerButton",
  
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      toggleActiveAsideMenu () {
        this.$emit("update", !this.isActive);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .aside-menu-button {
        position: relative;
        flex-shrink: 0;

        width: $navbar-height - 1rem;
        height: $navbar-height;

        span {
            display: block;
            position: absolute;
            left: calc(50% - 8px);

            height: 2.5px;
            width: 16px;

            background-color: #000000;
            transform-origin: center;
            transition-duration: 86ms;
            transition-property: background-color, opacity, transform;
            transition-timing-function: ease-out;

            &:nth-child(1) {
                top: calc(50% - 7px);
            }

            &:nth-child(2) {
                top: calc(50% - 2px);
            }

            &:nth-child(3) {
                top: calc(50% + 3px);
            }
        }

        @include touch {
            &.is-active span:nth-child(1) {
                transform: translateY(5px) rotate(45deg);
            }

            &.is-active span:nth-child(2) {
                opacity: 0;
            }

            &.is-active span:nth-child(3) {
                transform: translateY(-5px) rotate(-45deg);
            }
        }
    }
</style>