import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { CapacityAnalyseState } from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import { GET_ANALYSE_CAPACITY } from "@core/store/action-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import {
  SET_ANALYSE_CAPACITY,
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_MODAL
} from "@core/store/mutation-constants";
import Vue from "vue";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { CapacityAnalyseSubsectionEnum } from "@core/store/types/common/enums/capacitySubsectionEnum";

class CapacityAnalyse implements Module<CapacityAnalyseState, RootState> {
  public namespaced: boolean;
  public state: CapacityAnalyseState;
  public modules: ModuleTree<RootState>;
  
  public getters: GetterTree<CapacityAnalyseState, RootState> = {
    watchedParams: state => JSON.stringify(state.filters)
  }
  
  public mutations: MutationTree<CapacityAnalyseState> = {
    [SET_ANALYSE_CAPACITY]: (state, payload) => {
      Vue.set(state.data, "items", payload);
    },
    
    [UPDATE_MODAL] (state, modal) {
      state.modal = { ...state.modal , ...modal };
    },
    
    [UPDATE_FILTERS] (state, payload) {
      state.filters = { ...state.filters, ...payload };
    }
  }

  public actions: ActionTree<CapacityAnalyseState, RootState> = {
    async [GET_ANALYSE_CAPACITY] ({ state, commit, rootGetters }) {
      const { offerId, webmasterId, subsection } = state.modal;
      const { datepicker, ...filters } = state.filters;
      const serviceName = CapacityAnalyseSubsectionEnum[subsection as keyof typeof CapacityAnalyseSubsectionEnum];

      if (!filters.withExternalWebmasters) {
        filters.externalWebmasterId = null;
      }

      const data = await GqlOffersService[serviceName](
        offerId as string,
        webmasterId as string,
        {
          ...filters,
          ...datepickerFormat(datepicker, "date")
        },
        rootGetters["admin/capacityAndBumps/capacity/advertisersCapacity/isShowRedemption"]
      );

      commit(SET_ANALYSE_CAPACITY, data);
      return data;
    },
  
    [UPDATE_MODAL] ({ commit }, modal) {
      commit(UPDATE_MODAL, modal);
    },
    
    [UPDATE_FILTERS] ({ commit, dispatch }, payload) {
      commit(UPDATE_FILTERS, payload);

      dispatch(GET_ANALYSE_CAPACITY);
    },

    [SET_EMPTY] ({ commit }) {
      commit(SET_EMPTY);
    }
  }
  
  private readonly initState: () => CapacityAnalyseState;
  
  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): CapacityAnalyseState => {
      return {
        data: {},
        filters: {
          datepicker: datepicker({ amount: 1, unit: "month" }, "dateTime"),
          withExternalWebmasters: false,
          externalWebmasterId: null
        },
        modal: {
          isActive: false,
          offerId: null,
          webmasterId: null,
          subsection: null
        }
      };
    };
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    
    this.state = this.initState();
    this.modules = modules;
    
    return this;
  }
}

export const capacityAnalyse = new CapacityAnalyse({});