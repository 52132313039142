import { defineStore } from "pinia";
import { Agent as AgentConstructor, Manager as ManagerConstructor } from "@core/logic/user/Manager";
import { WebmasterService } from "@core/logic/user/Webmaster";
import { ProfileService } from "@core/services/advertiser/ProfileService";
import store from "@/store";
import { computed, Ref, ref } from "@vue/composition-api";
import { SubTypeEnum } from "@core/store/types/advertiser/profile/enums/SubTypeEnum";

function setArray (el: Ref<unknown>) {
  if (el.value !== null) {
    return Array.isArray(el.value) ? el.value : [el.value];
  } return [];
}

export const usePersonalManagers = defineStore("profile/personalManagers", () => {
  const data = ref<ManagerConstructor[] | AgentConstructor | ManagerConstructor>({});

  const subType = computed(() => store.state.auth.subType);
  const role = computed(() => store.state.auth.role);

  const isSub = computed(() => subType.value === SubTypeEnum.SUB_WEBMASTER);
  const isWeb = computed(() => subType.value === SubTypeEnum.WEBMASTER);
  const isAgent = computed(() => subType.value === SubTypeEnum.AGENT);

  const dataList = computed(() => setArray(data));

  async function GET_WEBMASTER_PERSONAL_MANAGER () {
    data.value = await WebmasterService.getWebmasterPersonalManager();
  }

  async function GET_WEBMASTER_PERSONAL_AGENT () {
    data.value = await WebmasterService.getWebmasterPersonalAgent();
  }

  async function GET_ADVERTISER_PERSONAL_MANAGER () {
    data.value = await ProfileService.getPersonalManager();
  }

  async function getPersonalManager () {
    if (role.value === "webmaster" && isWeb.value || role.value === "webmaster" && isAgent.value) {
      await GET_WEBMASTER_PERSONAL_MANAGER();
    } else if (role.value === "webmaster" && isSub.value) {
      await GET_WEBMASTER_PERSONAL_AGENT();
    } else if (role.value === "advertiser") {
      await GET_ADVERTISER_PERSONAL_MANAGER();
    }
  }

  function $reset () {
    data.value = {};
  }

  return {
    data: dataList,
    subType,
    role,
    isSub,
    isWeb,
    isAgent,
    getPersonalManager,
    $reset
  };
});