import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { AdvertisersCapacityFilters, AdvertisersCapacityState } from "@core/store/types/admin/offers/capacity";

export default function advertisersCapacity (
  limit: number,
  offset: number,
  filters: AdvertisersCapacityFilters,
  sort: AdvertisersCapacityState["sort"],
  order: AdvertisersCapacityState["order"],
  isRequest: boolean,
  isCommon: boolean,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const include = [];
  
  if (isShowRedemption) {
    include.push("items/statisticCapacity/monthRedemption");
  }
  
  if (isCommon) {
    include.push(
      "items/statisticCapacityByAdvertiser/yesterdayCapacityIndicator",
      "items/statisticCapacityByAdvertiser/weekCapacityIndicator",
      "items/statisticCapacityByAdvertiser/monthCapacityIndicator",
      "items/statisticCapacityByAdvertiser/yesterdayCapacityEfficiency",
      "items/statisticCapacityByAdvertiser/weekCapacityEfficiency",
      "items/statisticCapacityByAdvertiser/monthCapacityEfficiency"
    );
  } else {
    include.push(
      "items/statisticCapacity/yesterdayCapacityIndicator",
      "items/statisticCapacity/weekCapacityIndicator",
      "items/statisticCapacity/monthCapacityIndicator",
      "items/statisticCapacity/yesterdayCapacityEfficiency",
      "items/statisticCapacity/weekCapacityEfficiency",
      "items/statisticCapacity/monthCapacityEfficiency"
    );
  }
  
  const params = {
    include
  };
  
  const gqlObject = {
    operation: "advertisersCapacity",
  
    fields: [
      {
        items: [
          {
            advertiser: [
              "id",
              "intId",
              "login"
            ]
          },
          "filledCapacity",
          "softCapacity",
          "bookingCapacity",
          "updatedAt",
          "offerAdvertiserCapacity",
          {
            statisticCapacity: [
              "yesterdayLeads",
              "weekLeads",
              "monthLeads"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
  
    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      filters: {
        type: "AdvertiserCapacityListCriteriaFilters",
        value: filters
      },
      sort: {
        type: "AdvertiserCapacitySort",
        value: sort
      },
      order: {
        type: "SortOrder",
        value: order
      },
      isRequest: {
        type: "Boolean!",
        value: isRequest
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}