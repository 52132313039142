import { Refer, ReferralsOption, ReferralsState } from "@core/store/types/admin/users/webmasters/Referrals";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import { SET_MODAL_ACTIVE } from "@core/store/mutation-constants";
import {
  UPDATE_WEBMASTER_REFERRALS,
  UPDATE_MODAL_OPTIONS,
  UPDATE_MODAL_ACTIVE,
  DiSABLE_REFERRAL,
  ENABLE_REFERRAL,
  SET_REFERRALS,
  SET_REFERRER,
  SET_EMPTY
} from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";

const initialState = (): ReferralsState => {
  return {
    options: {
      webmasterId: null
    },
    referrals: null,
    referrer: null,
    isModalActive: false
  };
};

const state: () => ReferralsState = initialState;

const mutations: MutationTree<ReferralsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_MODAL_ACTIVE] (state, isModalActive: boolean) {
    state.isModalActive = isModalActive;
  },
  
  [UPDATE_MODAL_OPTIONS] (state, options: ReferralsOption) {
    state.options = { ...state.options, ...options };
  },
  
  [SET_REFERRALS] (state, items: Refer[]) {
    state.referrals = { items };
  },
  
  [SET_REFERRER] (state, referrer: ReferralsState["referrer"]) {
    state.referrer = referrer;
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<ReferralsState, RootState> = {
  async [ENABLE_REFERRAL] ({ commit, dispatch, state: { referrals, options: { webmasterId } } }, id: string) {
    const enabledReferral = await WebmastersService.enableReferral(id);
    commit("LOCAL_UPDATE", { items: enabledReferral, target: "referrals" });
    dispatch(UPDATE_WEBMASTER_REFERRALS, { id: webmasterId, referrals: referrals?.items });
  },

  async [DiSABLE_REFERRAL] ({ commit, dispatch, state: { referrals, options: { webmasterId } } }, id: string) {
    const disabledReferral = await WebmastersService.disableReferral(id);
    commit("LOCAL_UPDATE", { items: disabledReferral, target: "referrals" });
    dispatch(UPDATE_WEBMASTER_REFERRALS, { id: webmasterId, referrals: referrals?.items });
  },
  
  [UPDATE_WEBMASTER_REFERRALS] ({ commit }, { id, referrals }) {
    const webmasterReferral = { id, referrals };
    commit("admin/users/webmasters/LOCAL_UPDATE", { items: webmasterReferral, target: "webmasters" }, { root: true });
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, options: ReferralsOption) {
    commit(UPDATE_MODAL_OPTIONS, options);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const referralsModal: Module<RootState, ReferralsState> = {
  namespaced: true,
  state,
  actions,
  mutations
};