import Service from "../../../services/common/Service";
import categories from "@core/services/common/lists/graphql/categories.graphql";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

interface ResponseCategories { name: string; vertical: VerticalsEnum; id: string; }

export class CategoriesListService extends Service {
  public static async getCategoriesList (filters: { vertical: VerticalsEnum[]; }): Promise<ResponseCategories[]> {
    try {
      const { data: { categories: data } } = await this.api.get("", {
        params: {
          query: categories,
          variables: {
            filters,
            limit: 100,
            offset: 0
          }
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
