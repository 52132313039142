import Service from "@core/services/common/Service";
import {
  DashboardCapacityByCountryResponse,
  DashboardCapacityRequestResponse, DashboardLeadLaunchActivityResponse,
  DashboardPayoutRequestResponse, DashboardStatisticChartResponse,
  DashboardStatisticWidgetSummaryClientsWidget,
  DashboardStatisticWidgetFilters
} from "@core/store/types/admin/dashboard/dashboardStatisticWidget";
import dashboardSummaryClientsWidget from "@core/services/admin/dashboard/graphql/widget/dashboardSummaryClientsWidget.graphql";
import efficiencyKpiBonusWidget from "@core/services/admin/dashboard/graphql/widget/efficiencyKpiBonusWidget.graphql";
import leadLaunchActivityWidget from "@core/services/admin/dashboard/graphql/widget/leadLaunchActivityWidget.graphql";
import capacityByCountryWidget from "@core/services/admin/dashboard/graphql/widget/capacityByCountryWidget.graphql";
import capacityRequestWidget from "@core/services/admin/dashboard/graphql/widget/capacityRequestWidget.graphql";
import statisticsChartWidget from "@core/services/admin/dashboard/graphql/widget/statisticsChartWidget.graphql";
import payoutRequestWidget from "@core/services/admin/dashboard/graphql/widget/payoutRequestWidget.graphql";
import efficiencyRateWidget from "@core/services/admin/dashboard/graphql/widget/efficiencyRateWidget.graphql";
import summaryStatisticsByDayWidgetQuery from "@core/services/admin/dashboard/graphql/widget/summaryStatisticsByDayWidget.graphql";
import incidenceWidget from "@core/services/admin/dashboard/graphql/widget/incidenceWidget.graphql";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import { IndicatorEnum } from "@core/store/types/admin/dashboard/enums/indicatorEnum";
import { AxiosPromise } from "axios";

export class DashboardStatisticWidgetService extends Service {
  public static async getEfficiencyRateWidget (): Promise<AxiosPromise> {
    try {
      const { data: { efficiencyRateWidget: data } } = await this.api.post("", {
        query: efficiencyRateWidget
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getLeadStatisticWidget (filters: DashboardStatisticWidgetFilters): Promise<AxiosPromise> {
    try {
      const { data: { summaryStatisticsByDayWidget } } = await this.api.post("", {
        query: summaryStatisticsByDayWidgetQuery,
        variables: {
          filters
        }
      });

      return summaryStatisticsByDayWidget;
    } catch (e) {
      throw e;
    }
  }

  public static async getIncidenceWidget (threshold: number): Promise<AxiosPromise> {
    try {
      const { data: { incidenceWidget: data } } = await this.api.post("", {
        query: incidenceWidget,
        variables: {
          threshold
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getEfficiencyKpiBonusWidget ({ dateStart, dateEnd }: { dateStart: string; dateEnd: string }): Promise<AxiosPromise> {
    try {
      const { data: { efficiencyKpiBonusWidget: data } } = await this.api.post("", {
        query: efficiencyKpiBonusWidget,
        variables: {
          dateStart,
          dateEnd
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getDashboardSummaryClientsWidget (
    { dateStart, dateEnd }: { dateStart: string, dateEnd: string },
    withBlockedManager: boolean
  ): Promise<DashboardStatisticWidgetSummaryClientsWidget> {
    try {
      const { data: { dashboardSummaryClientsWidget: data, activeOffers, totalOffers } } = await this.api.post("", {
        query: dashboardSummaryClientsWidget,
        variables: {
          dateStart,
          dateEnd,
          withBlockedManager
        }
      });

      return { ...data, activeOffers: activeOffers.count, totalOffers: totalOffers.count };
    } catch (e) {
      throw e;
    }
  }

  public static async getCapacityRequestWidget (
    { dateStart, dateEnd }: { dateStart: string, dateEnd: string }
  ): Promise<DashboardCapacityRequestResponse> {
    try {
      const { data: { capacityRequestWidget: data } } = await this.api.post("", {
        query: capacityRequestWidget,
        variables: {
          dateStart,
          dateEnd
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getPayoutRequestWidget (
    { dateStart, dateEnd }: { dateStart: string, dateEnd: string }
  ): Promise<DashboardPayoutRequestResponse> {
    try {
      const { data: { payoutRequestWidget: data } } = await this.api.post("", {
        query: payoutRequestWidget,
        variables: {
          dateStart,
          dateEnd
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getStatisticsChartWidget (
    indicator: IndicatorEnum,
    currency: CurrencyEnum,
    filters: any
  ): Promise<DashboardStatisticChartResponse> {
    try {
      const { data: { statisticsChartWidget: data } } = await this.api.post("", {
        query: statisticsChartWidget,
        variables: {
          indicator,
          currency,
          filters
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getLeadLaunchActivityWidget (
    limit: number,
    offset: number,
    filters: any
  ): Promise<DashboardLeadLaunchActivityResponse> {
    try {
      const { data: { leadLaunchActivityWidget: data } } = await this.api.post("", {
        query: leadLaunchActivityWidget,
        variables: {
          limit,
          offset,
          filters
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getCapacityByCountryWidget (
    { dateStart, dateEnd }: { dateStart: string | Date; dateEnd: string | Date }
  ): Promise<DashboardCapacityByCountryResponse> {
    try {
      const { data: { capacityByCountryWidget: data } } = await this.api.post("", {
        query: capacityByCountryWidget,
        variables: {
          dateStart,
          dateEnd
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}
