import store from "../../../store";
import { hasPermissionsStrict } from "@core/mixins/permissions";
import { RootState } from "@core/store/root-state";

/*
* Функция примешивает права к каждому значению в массиве, которое укажем.
* */

export function mixPermissions (data: RootState, optionsRules: Array<{ name: string, permissions: string[] }>, prop?: string) {
  const RootPermissions = store?.getters?.permissions;
  
  return data?.map((item: any) => {
    // name - имя строки массива (item), permissions - массив правил
    const hasItem: string = typeof item === "string" ? item : item[prop || "value"];
    const { name, permissions } = optionsRules.find(option => option.name === hasItem) || {};
    
    if (
      hasItem === name &&
      !hasPermissionsStrict(permissions, RootPermissions)
    ) {
      return null;
    }
    return item;
  }).filter(Boolean);
}