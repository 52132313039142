import { DetailingCopyLeadsModalService } from "@core/services/admin/statistic/detailing/modal/DetailingCopyLeadsModalService";
import { DetailingCopyLeadsModalState } from "@core/store/types/admin/statistic/detailing/modal/detailingCopyLeadsModal";
import { ResponseDetailItem } from "@core/store/types/admin/statistic/detailing/detailingState";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";
import {
  UPDATE_MODAL_DATA,
  UPDATE_MODAL_FORM,
  COPY_LEADS,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { hasPermissions } from "@core/mixins/permissions";

const initialState = (): DetailingCopyLeadsModalState => {
  return {
    form: {
      offerId: null
    },
    data: null,
    isModalActive: false
  };
};

const state: () => DetailingCopyLeadsModalState = initialState;

const getters: GetterTree<DetailingCopyLeadsModalState, RootState> = {
  selectedLeads: (state, getters, rootState, rootGetters) =>
    rootState.admin.statistics.detailing.selectedLeads.filter((el: ResponseDetailItem) => {
      const hasPermissionSelected = hasPermissions(["LEAD.OFFER_TRANSFER.ALL"], rootGetters.permissions) ||
        hasPermissions(["LEAD.OFFER_TRANSFER.OWN"], rootGetters.permissions) && el.data.webmaster?.isBindedToCurrentAdmin;
      return !el.isCopied && hasPermissionSelected;
    })
};

const mutations: MutationTree<DetailingCopyLeadsModalState> = {
  SET_EMPTY: (state: DetailingCopyLeadsModalState): DetailingCopyLeadsModalState => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_FORM] (state, form: DetailingCopyLeadsModalState["form"]): void {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_MODAL_DATA] (state, data: DetailingCopyLeadsModalState["data"]): void {
    state.data = data;
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean): void {
    state.isModalActive = payload;
  }
};

const actions: ActionTree<DetailingCopyLeadsModalState, RootState> = {
  async [COPY_LEADS] ({ state: { form: { offerId } }, commit, rootState }): Promise<void> {
    const leadIds = rootState.snackbarActions.selected.map((el: ResponseDetailItem) => el.id);
    const DATA = await DetailingCopyLeadsModalService.copyLeads(offerId, leadIds);
  
    commit(UPDATE_MODAL_DATA, DATA);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_MODAL_FORM] ({ commit }, form: DetailingCopyLeadsModalState["form"]): void {
    if (!form.offerId) {
      commit(UPDATE_MODAL_DATA, null);
    }
    commit(UPDATE_MODAL_FORM, form);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
    commit(`offersList/${ SET_EMPTY }`);
  }
};

export const detailingCopyLeadsModal: Module<DetailingCopyLeadsModalState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    offersList: new OfferFilters()
  }
};
