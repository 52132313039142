<script>
  import { createPopper } from "@popperjs/core";
  import Fuse from "fuse.js";
  
  export default {
    name: "BaseSelect",
    
    props: {
      field: {
        type: String,
        default: "name"
      },
      
      prop: {
        type: [String, Array],
        default: "id"
      },
      
      value: {
        type: null,
        default: null
      },
      
      options: {
        type: Object,
        default: null
      },
      
      multiple: {
        type: Boolean,
        default: false
      },
      
      clearable: {
        type: Boolean,
        default: true
      },
      
      filterable: {
        type: Boolean,
        default: true
      },
      
      filterBy: {
        type: Function,
        default: null
      },
      
      searchable: {
        type: Boolean,
        default: true
      },
      
      required: {
        type: Boolean,
        default: false
      },
      
      autocomplete: {
        type: String,
        default: "off"
      },
      
      getData: {
        type: Function,
        default: null
      },
      
      getDataVuex: {
        type: Function,
        default: null
      },
      
      disabled: {
        type: Boolean,
        default: false
      },
      
      seed: {
        type: null,
        default: null
      },
      
      placeholder: {
        type: String,
        default: ""
      },
      
      minRequiredLength: {
        type: Number,
        default: 1
      },
      
      appendToBody: {
        type: Boolean,
        default: true
      },
      
      taggable: {
        type: Boolean,
        default: false
      },
      
      noDrop: {
        type: Boolean,
        default: false
      },
      
      createOption: {
        type: Function,
        default: () => {
        }
      },
      
      loading: {
        type: Boolean,
        default: false
      },

      selectable: {
        type: Function,
        default: () => true
      },

      selectOnSpace: {
        type: Boolean,
        default: false
      },

      selectOnBlur: {
        type: Boolean,
        default: false
      },

      indicator: {
        type: Boolean,
        default: false
      },

      marquee: {
        type: Boolean,
        default: false
      },

      hideNull: {
        type: Boolean,
        default: false
      },
        
      widthMenu: {
        type: String,
        default: null,
        validator: value => value.includes("px")
      }
    },
    
    computed: {
      _filterBy () {
        return this.filterBy == null ? this._filterByDefault : this.filterBy;
      }
    },
    
    methods: {
      calculatePosition (dropdownList, component, { width }) {
        dropdownList.style.width = this.widthMenu || width;
        dropdownList.style.border = "none";
        dropdownList.style.minWidth = "unset";
        dropdownList.style.borderRadius = "5px";
        
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: "bottom",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 4]
              }
            }
          ]
        });
        
        return () => popper.destroy();
      },
      
      _filterByDefault (option, label, search) {
        const fuse = new Fuse(option, {
          keys: typeof this.prop === "string" ? [this.prop] : this.prop,
          shouldSort: true
        });
        
        return search.length
          ? fuse
            .search(search)
            .map(({ item }) => item)
          : null;
      }
    }
  };
</script>
