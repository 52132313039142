function structuredClone (obj) {
  return new Promise(resolve => {
    // eslint-disable-next-line no-undef
    const {
      port1,
      port2
    } = new MessageChannel();
    port2.onmessage = e => resolve(e.data);
    port1.postMessage(obj);
  });
}

window.structuredClone = structuredClone;
