import { Auth, Login, Registration, TelegramLogin, WhoAmI } from "@core/store/types/common/auth";
import { AuthService } from "@core/logic/auth/service";
import { AuthenticationService } from "@core/services/common/AuthenticationService";
import store from "../../../../store";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { FETCH_USER, GET_WHO_AM_I, UPDATE_TOKEN } from "@core/store/action-constants";
import { SAVE_USER } from "@core/store/mutation-constants";
import Vue from "../../../../main";
import Cookies from "js-cookie";
import { usePermissions } from "../../../../stores/common/auth/permissionsStore";

export default class AuthenticationProvider {
  public refreshToken = "";
  public login = "";
  public self: boolean;
  public userType: string;
  public isConfirmed: boolean;
  public isBlocked: boolean;
  public subType: string;
  public preferredCurrency: string;
  public preferredVertical: VerticalsEnum;
  public availableCurrencies: string[];
  public isApproved: boolean;
  public isPartnerNetwork: boolean;
  public isShowDomonetizationStatistics: boolean;
  public refreshTokenTtl: number;
  public token: string;
  public expiresIn: number;
  public intId: string;
  public nextPasswordChangeDate: Date;
  public showPasswordChangeNotification: boolean;
  public userPermissions: Nullable<[]>;
  
  constructor (auth: Auth) {
    this.refreshToken = auth.refreshToken;
    this.login = auth.login;
    this.self = auth.self;
    this.userType = auth.userType;
    this.isConfirmed = auth.isConfirmed;
    this.isBlocked = auth.isBlocked;
    this.subType = auth.subType?.toUpperCase();
    this.preferredCurrency = auth.preferredCurrency;
    this.preferredVertical = auth.preferredVertical;
    this.availableCurrencies = auth.availableCurrencies;
    this.isApproved = auth.isApproved;
    this.token = auth.token;
    this.isPartnerNetwork = auth.isPartnerNetwork;
    this.isShowDomonetizationStatistics = auth.isShowDomonetizationStatistics;
    this.expiresIn = auth.expiresIn;
    this.refreshTokenTtl = auth.refreshTokenTtl;
    this.nextPasswordChangeDate = auth.nextPasswordChangeDate;
    this.showPasswordChangeNotification = auth.showPasswordChangeNotification;
    this.intId = auth.intId;
    this.userPermissions = auth.userPermissions;

    if (auth.loginId) {
      Cookies.set("login_id", auth.loginId as string, { expires: this.refreshTokenTtl });
    }

    store.dispatch(UPDATE_TOKEN, {
      access: this.token,
      expiresIn: this.expiresIn
    }).then();

    // TODO: Описать $ym в типе vue
    // @ts-ignore
    Vue.$ym?.setUserId(this.login, this.intId);

    store.commit(SAVE_USER, {
      role: this.userType?.toLowerCase(),
      self: this.self,
      isConfirmed: this.isConfirmed,
      isApproved: this.isApproved,
      isBlocked: this.isBlocked,
      subType: this.subType,
      preferredCurrency: this.preferredCurrency,
      preferredVertical: this.preferredVertical,
      availableCurrencies: this.availableCurrencies,
      nextPasswordChangeDate: this.nextPasswordChangeDate,
      showPasswordChangeNotification: this.showPasswordChangeNotification,
      isPartnerNetwork: this.isPartnerNetwork,
      isShowDomonetizationStatistics: this.isShowDomonetizationStatistics,
      intId: this.intId,
      token: {
        refresh: this.refreshToken,
        access: this.token,
        expiresIn: this.expiresIn,
        refreshTokenTtl: this.refreshTokenTtl
      }
    });

    if (auth?.token) {
      store.dispatch(GET_WHO_AM_I);
      usePermissions().GET_USER_PERMISSIONS().then();
    }

    // noinspection PointlessBooleanExpressionJS
    if (this.userType) {
      store.dispatch(`${ FETCH_USER }`).then();
    }
  }
  
  /**
   * Логин через логин/пароль
   * @param {Login} login
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByPassword (login: Login): Promise<AuthenticationProvider> {
    const auth = await AuthenticationService.login(login);
    const authConfirm = await AuthenticationService.authConfirm(auth.loginId);

    authConfirm.self = true;
    this.setYandexClientId();
    return new AuthenticationProvider(authConfirm.isBlocked ? { isBlocked: true } : authConfirm);
  }
  
  /**
   * Логин по телеграм аккаунту
   * @param {TelegramLogin} payload
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByTelegram (payload: TelegramLogin): Promise<AuthenticationProvider> {
    const data = await AuthenticationService.loginByTelegram(payload);
    data.self = true;
    this.setYandexClientId();
    return new AuthenticationProvider(data);
  }
  
  /**
   * Логин по рефреш токену, при обновлении страницы
   * @param {string} refreshToken
   * @param {string} fingerPrint
   * @param {string} headerOptions
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginByToken (refreshToken: string, fingerPrint: string, headerOptions: string): Promise<AuthenticationProvider> {
    let data = await AuthenticationService.refreshToken({ refreshToken, fingerPrint }, headerOptions);
    const { token: access, expiresIn } = data;
    
    await store.dispatch(UPDATE_TOKEN, { access, expiresIn });
    
    this.setYandexClientId();

    const userType = JSON.parse(atob(access.split(".")[1])).userType;

    const whoAmI = await this.whoAmI(userType);

    data = { ...data, ...whoAmI, refreshToken };
    
    return new AuthenticationProvider(data);
  }

  /**
   * Логин за администратора
   * @return {void}
   */
  static setYandexClientId (): void {
    if (process.env.NODE_ENV === "production") {
      // @ts-ignore
      const clientId = Vue.$ym?.getClientID();
      if (clientId) {
        AuthenticationService.setYandexClientId(clientId);
      }
    }
  }

  /**
   * Информация о пользователе
   * @return {Promise<WhoAmI>}
   */
  static async whoAmI (role: Nullable<string>): Promise<WhoAmI> {
    const whoAmI = await AuthenticationService.whoAmI(role);
    whoAmI.userType = whoAmI.userType?.toLowerCase();
    return whoAmI;
  }
  
  /**
   * Логин за администратора
   * @param {string} adminId
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginAsAdministrator (adminId: string): Promise<AuthenticationProvider> {
    const { data: { loginAsAdministrator } } = await AuthenticationService.loginAsAdministrator(adminId);
    loginAsAdministrator.self = false;
    return new AuthenticationProvider(loginAsAdministrator);
  }
  
  /**
   * Логин за вебмастера
   * @param {string} webmasterId
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginAsWebmaster (webmasterId: string): Promise<AuthenticationProvider> {
    const { data: { loginAsWebmaster } } = await AuthenticationService.loginAsWebmaster(webmasterId);
    loginAsWebmaster.self = false;
    return new AuthenticationProvider(loginAsWebmaster);
  }
  
  /**
   * Логин за рекламодателя
   * @param {string} advertiserId
   * @return {Promise<AuthenticationProvider>}
   */
  static async loginAsAdvertiser (advertiserId: string): Promise<AuthenticationProvider> {
    const { data: { loginAsAdvertiser } } = await AuthenticationService.loginAsAdvertiser(advertiserId);
    loginAsAdvertiser.self = false;
    return new AuthenticationProvider(loginAsAdvertiser);
  }
  
  /**
   * Логин за админа из вебмастера/рекламодателя
   * @return {Promise<AuthenticationProvider>}
   */
  static async returnToAdminProfile (): Promise<AuthenticationProvider> {
    const data = await AuthenticationService.returnToAdminProfile();
    data.self = true;
    return new AuthenticationProvider(data);
  }

  /**
   * Выход из текущей сессии
   * @return {Promise<AuthenticationProvider>}
   */
  static async logoutUser (): Promise<AuthenticationProvider> {
    const data = await AuthenticationService.authenticationLogout();
    data.self = true;
    return new AuthenticationProvider(data);
  }
  
  static async loginByRegistration (payload: Registration): Promise<AuthenticationProvider> {
    const { data } = await AuthService.registration(payload);
    const authConfirm = await AuthenticationService.authConfirm(data.loginId);
    authConfirm.self = true;
    return new AuthenticationProvider(authConfirm);
  }
  
  static async getUserPermissions (): Promise<AuthenticationProvider> {
    const { data } = await AuthenticationService.getUserPermissions();
    return data;
  }
}
