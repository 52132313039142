import { AdvertisersDutyService } from "@core/services/admin/finances/advertisersDuty/advertisersDutyService";
import { AdvertisersDutyDetailState } from "@core/store/types/admin/finances/advertisersDuty/advertisersDuty";
import { GET_ADVERTISERS_DUTY_DETAIL, SET_ADVERTISERS_DUTY_DETAIL } from "@core/store/action-constants";
import {
  CREATE_INITIAL_STRUCTURE,
  UPDATE_PAGINATION,
  UPDATE_SORTINGS,
  UPDATE_FILTERS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { datepicker } from "@core/helpers/datepicker";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import {
  efficiencyDataMapper
} from "@core/store/modules/admin/capacityAndBumps/modules/efficiency/dataset/efficiencyDataMapper";
import Vue from "vue";
import {
  CapacityRequestEfficiencyGroups,
  CapacityRequestEfficiencyState
} from "@core/store/types/admin/capacityAndBumps/capacityRequestEfficiency";

const initialState = (): AdvertisersDutyDetailState => {
  return {
    advertisersDuty: {
      data: null,
      filters: {
        datepicker: datepicker({ amount: 1, unit: "weeks" }),
        currency: CurrencyEnum.USD,
        userId: null,
        groups: ["MONTH"]
      },
      pagination: {
        page: 1,
        perPage: 20
      },
      sort: null,
      order: null
    }
  };
};

const state: () => AdvertisersDutyDetailState = initialState;

const mutations: MutationTree<AdvertisersDutyDetailState> = {
  [CREATE_INITIAL_STRUCTURE] (state, uniqueKey) {
    const model = {
      data: null,
      filters: {
        datepicker: datepicker({ amount: 1, unit: "weeks" }),
        currency: CurrencyEnum.USD,
        userId: null,
        groups: ["MONTH"]
      },
      pagination: {
        page: 1,
        perPage: 20
      },
      sort: null,
      order: null
    };
    Vue.set(state, uniqueKey, model);
  },

  [SET_ADVERTISERS_DUTY_DETAIL] (state, { uniqueKey, data }) {
    if (state[uniqueKey].data && state[uniqueKey].pagination.page !== 1) {
      data = {
        count: data.count,
        items: state[uniqueKey].data?.items?.concat(data.items)
      };
    }

    Vue.set(state[uniqueKey], "data", data);
  },

  [UPDATE_FILTERS] (state, { data, uniqueKey }) {
    Object.assign(state[uniqueKey].filters, data);
  },

  [UPDATE_PAGINATION] (state, { uniqueKey, ...payload }) {
    Object.assign(state[uniqueKey].pagination, payload);
  },

  [UPDATE_SORTINGS] (state, { uniqueKey, sort, order }) {
    Vue.set(state[uniqueKey], "sort", sort);
    Vue.set(state[uniqueKey], "order", order.toUpperCase());
  },

  [SET_EMPTY] (state, uniqueKey: string) {
    if (uniqueKey) {
      delete state[uniqueKey];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<AdvertisersDutyDetailState, RootState> = {
  async [GET_ADVERTISERS_DUTY_DETAIL] ({ commit, state }, uniqueKey) {
    const limit = state[uniqueKey]?.pagination.perPage || 20;
    const offset = ((state[uniqueKey]?.pagination.page || 1) - 1) * limit;
    const { currency, datepicker, groups, ...filters } = state[uniqueKey]?.filters;

    const data = await AdvertisersDutyService.getAdvertisersDuty(
      { ...datepicker, ...filters },
      limit,
      offset,
      state[uniqueKey]?.sort,
      state[uniqueKey]?.order,
      groups,
      currency
    );

    commit(SET_ADVERTISERS_DUTY_DETAIL, {
      uniqueKey,
      data: efficiencyDataMapper(data as NonNullable<CapacityRequestEfficiencyState["data"]>, groups?.[0] as CapacityRequestEfficiencyGroups)
    });
  },

  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
  },

  [UPDATE_PAGINATION] ({ commit }, payload) {
    commit(UPDATE_PAGINATION, payload);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    commit(`advertisersDutyDetail/${ SET_EMPTY }`);
  }
};

export const advertisersDutyDetail: Module<AdvertisersDutyDetailState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
