<script>
  export default {
    name: "BaseLoading",
    props: {
      active: {
        type: Boolean,
        default: false
      },

      animation: {
        type: String,
        default: "fade"
      },

      isFullPage: {
        type: Boolean,
        default: true
      },

      isLabelExist: {
        type: Boolean,
        default: true
      },

      isCustomLoader: {
        type: Boolean,
        default: true
      },

      canCancel: {
        type: Boolean,
        default: false
      },

      onCancel: {
        type: Function,
        default: function _default () {}
      }

    },

    methods: {
      close () {
        this.$emit("close");
      }
    }
  };
</script>
