import ErrorPayment from "@/components/Common/TransactionStatus/ErrorPayment.vue";
import SuccessPayment from "@/components/Common/TransactionStatus/SuccessPayment.vue";
import Empty from "@/views/Empty.vue";

export default {
  path: "/transaction",
  name: "transaction",
  redirect: { name: "main" },
  component: Empty,
  children: [
    {
      path: "succeed",
      name: "succeed",
      component: SuccessPayment
    },
    {
      path: "failed",
      name: "failed",
      component: ErrorPayment
    }
  ]
};
