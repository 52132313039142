import audienceSummaryGenerator from "@core/services/common/statistic/AnalyticTargerAudience/audienceSummary.generator";
import audienceSummary from "@core/services/admin/statistic/analyticAudience/graphql/audienceSummary.graphql";
import {
  AnalyticAudienceData,
  AnalyticAudienceFiltersInterface,
  Periods
} from "@core/store/types/common/statistic/analyticAudience/analyticAudience";
import Service from "@core/services/common/Service";

export class AnalyticTargetAudience extends Service {
  public static async getAnalyticTargetAudience (
    offerId: AnalyticAudienceFiltersInterface["offerId"],
    webmasterId: AnalyticAudienceFiltersInterface["webmasterId"],
    periods: Periods[],
    filters: any,
    includePayout: boolean
  ): Promise<AnalyticAudienceData> {
    try {
      const [{ data: { audienceSummary: table } }, { data: { audienceGraphByAge: dataByAge, audienceGraphBySex: dataBySex } }] =
          await Promise.all([
            this.api.get("", { params: audienceSummaryGenerator(offerId, webmasterId, periods, filters, includePayout) }),
            this.api.get("", { params: { query: audienceSummary, variables: { offerId, webmasterId, periods, filters, includePayout } } })
          ]);

      return { table, dataByAge, dataBySex };
    } catch (e) {
      throw e;
    }
  }
}
