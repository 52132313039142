<template>
    <section class="hero confirm-mail is-fullheight">
        <div class="hero-body">
            <div class="container">
                <!--suppress HtmlUnknownTarget -->
                <img
                    v-if="$saas.main"
                    alt="logo"
                    :src="require(`@/assets/${ $saas.logo }`)">
                <!--suppress HtmlUnknownTarget -->
                <img
                    alt="mail-image"
                    src="@/assets/MailConfirmation/expired.png">
                <h1 class="has-text-weight-bold has-text-centered">
                    {{ $t(`common.mailConfirmation.expired.title`) }}
                </h1>
                <p class="has-text-grey has-text-centered">
                    {{ $t(`common.mailConfirmation.expired.message`) }}
                </p>
                <a
                    :href="link"
                    class="button is-success is-rounded is-fullwidth">
                    {{ $t(`common.mailConfirmation.expired.link`) }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
  export default {
    name: "MailConfirmationExpired",
    computed: {
      link () {
        return process.env.VUE_APP_LANDING_PAGE_URL;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .confirm {
      &-mail {
          h1 {
              margin-bottom: 0;
          }
          p {
              margin-bottom: 20px;
          }
      }
  }
  </style>
