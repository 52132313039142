import OfferRequestMinimalPayout from "@core/services/admin/offers/graphql/OfferRequestMinimalPayout.graphql";
import Service from "@core/services/common/Service";
import { BampsAndCapsState } from "@core/store/types/admin/offers/bampsAndCaps";
import { Money } from "@core/store/types/common";
import { AxiosPromise } from "axios";
import createPayoutRequestQuery from "@core/services/admin/offers/graphql/createPayoutRequest.generator";
import editPayoutRequest from "@core/services/admin/offers/graphql/editPayoutRequest.generator";
import deletePayoutRequestQuery from "@core/services/admin/offers/graphql/deletePayoutRequest.graphql";
import approvePayoutRequest from "@core/services/admin/offers/graphql/approvePayoutRequest.generator";
import rejectPayoutRequestQuery from "@core/services/admin/offers/graphql/rejectPayoutRequest.graphql";
import actualizePayoutRequestQuery from "@core/services/admin/offers/graphql/actualizePayoutRequest.graphql";
import {
  ApproveCapacityRequestInput,
  PayoutRequestInput,
  CreatePayoutRequestResponse,
  EditPayoutRequestResponse,
  DeletePayoutRequestResponse,
  ApprovePayoutRequestResponse,
  RejectPayoutRequestResponse, ActualizePayoutRequestResponse
} from "@core/store/types/admin/offers/capacity";

export class PayoutRequestService extends Service {
  public static async getMinimalPayout (
    filters: BampsAndCapsState["filters"]
  ): Promise<Money> {
    try {
      const { data: { offerParameterRequests: { items } } } = await this.api.get("", {
        params: {
          query: OfferRequestMinimalPayout,
          variables: {
            filters
          }
        }
      });
      return items[0]?.minimalPayout;
    } catch (e) {
      throw e;
    }
  }
  
  public static createPayoutRequest (
    input: PayoutRequestInput,
    isShowCommission: boolean
  ): AxiosPromise<CreatePayoutRequestResponse> {
    try {
      const data = createPayoutRequestQuery(
        input,
        isShowCommission
      );
      return this.api.post("", data);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static editPayoutRequest (
    input: PayoutRequestInput,
    id: string,
    isShowCommission: boolean
  ): AxiosPromise<EditPayoutRequestResponse> {
    try {
      const data = editPayoutRequest(
        input,
        id,
        isShowCommission
      );
      return this.api.post("", data);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deletePayoutRequest (
    id: string
  ): AxiosPromise<DeletePayoutRequestResponse> {
    try {
      return this.api.post("", {
        query: deletePayoutRequestQuery,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static approvePayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string,
    isShowCommission: boolean
  ): AxiosPromise<ApprovePayoutRequestResponse> {
    try {
      const data = approvePayoutRequest(
        input,
        id,
        isShowCommission
      );
      return this.api.post("", data);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static rejectPayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): AxiosPromise<RejectPayoutRequestResponse> {
    try {
      return this.api.post("", {
        query: rejectPayoutRequestQuery,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static actualizePayoutRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): AxiosPromise<ActualizePayoutRequestResponse> {
    try {
      return this.api.post("", {
        query: actualizePayoutRequestQuery,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
}
