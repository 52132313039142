// Расширяет компонент с перегрузкой методов

import Vue, { ComponentOptions } from "vue/types";
import _merge from "lodash/merge";

export default function (
  component: ComponentOptions<Vue> | typeof Vue,
  obj: ComponentOptions<Vue> | typeof Vue
): ComponentOptions<Vue> {
  return _merge(component, obj) as ComponentOptions<Vue>;
}

