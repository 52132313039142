import { SET_MODAL_ACTIVE } from "@core/store/mutation-constants";
import {
  GET_WEBMASTER_PROFILE_BY_ID,
  UPDATE_LOCAL_DATA_PARAMS,
  UPDATE_WEBMASTER_BY_ID,
  UPDATE_MODAL_ACTIVE,
  EDIT_WEBMASTER,
  UPDATE_OPTIONS,
  UPDATE_FORM,
  SET_EMPTY,
  SET_FORM
} from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { WebmasterByIdService } from "@core/services/common/WebmasterByIdService";
import { WebmasterForm } from "@core/store/logic/admin/WebmasterForm";
import {
  LocalDataParams,
  WebmasterProfileViewModal,
  WebmasterProfileViewModalForm,
  WebmasterProfileViewModalOptions
} from "@core/store/types/common/webmasterProfileViewModal";

const initialState = (): WebmasterProfileViewModal => {
  return {
    form: {
      email: null,
      login: null,
      skype: null,
      telegram: null,
      whatsApp: null,
      viber: null,
      timeZone: null,
      isTeam: false,
      teamName: null,
      verticals: [],
      trust: null,
      categoriesPreferences: [],
      preferredVertical: null,
      isShowDomonetizationStatistics: false,
      residenceCity: null,
      residenceCountry: null,
      trafficTypes: [],
      isPartnerNetwork: false,
      isDomestic: false,
      comment: null,
      label: null,
      isAgent: false,
      agentType: null,
      birthday: null
    },
    options: {
      canBeEdit: false
    },
    data: null,
    isModalActive: false,
    localDataParams: null
  };
};

const state: () => WebmasterProfileViewModal = initialState;

const mutations: MutationTree<WebmasterProfileViewModal> = {
  [UPDATE_WEBMASTER_BY_ID] (state, data: WebmasterProfileViewModal["data"]) {
    // @ts-ignore
    state.data = { ...state.data, ...data };
  },
  
  [UPDATE_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },
  
  [SET_MODAL_ACTIVE] (state, isModalActive: boolean) {
    state.isModalActive = isModalActive;
  },
  
  [UPDATE_LOCAL_DATA_PARAMS] (state, localDataParams: LocalDataParams) {
    state.localDataParams = localDataParams;
  },
  
  [UPDATE_OPTIONS] (state, option) {
    state.options = { ...state.options, ...option };
  },
  
  [SET_EMPTY] (state) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { localDataParams, ...emptyFields } = initialState();
    Object.assign(state, emptyFields);
  }
};

const actions: ActionTree<WebmasterProfileViewModal, RootState> = {
  async [GET_WEBMASTER_PROFILE_BY_ID] ({ state: { data }, commit }, id = data?.id) {
    const { data: { webmasterById } } = await WebmasterByIdService.getWebmasterById(id, true);
    commit(UPDATE_WEBMASTER_BY_ID, webmasterById);
  },
  
  async [EDIT_WEBMASTER] ({ state: { form, data }, commit }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, isAgent, agentType: agent, ...input } = form;
    const webmasterId = data?.id;
    const agentType = isAgent ? agent : null;
    // @ts-ignore
    const editedWebmaster = await WebmasterByIdService.editWebmasterById(webmasterId as string, { ...input, agentType, isAgent });
    commit(UPDATE_WEBMASTER_BY_ID, editedWebmaster);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_FORM] ({ commit }, form: WebmasterProfileViewModalForm) {
    commit(UPDATE_FORM, form);
  },
  
  [UPDATE_OPTIONS] ({ commit }, options: WebmasterProfileViewModalOptions) {
    commit(UPDATE_OPTIONS, options);
  },
  
  [SET_FORM] ({ state: { data }, commit }) {
    commit(UPDATE_FORM, new WebmasterForm(data));
  },
  
  [UPDATE_LOCAL_DATA_PARAMS] ({ commit }, localDataParams: LocalDataParams) {
    commit(UPDATE_LOCAL_DATA_PARAMS, localDataParams);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const webmasterProfileViewModal: Module<RootState, WebmasterProfileViewModal> = {
  namespaced: true,
  state,
  actions,
  mutations
};
