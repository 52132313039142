import Service from "@core/services/common/Service";
import { BampsAndCapsState } from "@core/store/types/admin/offers/bampsAndCaps";
import { AxiosResponse } from "axios";
import capacityStatisticsQuery from "./graphql/capacityStatistics.graphql";

export class CapacityStatisticsService extends Service {
  public static async capacityStatistics (
    limit: number,
    offset: number,
    sort: BampsAndCapsState["sort"],
    order: BampsAndCapsState["order"],
    filters: BampsAndCapsState["filters"],
    groups: any
  ): Promise<AxiosResponse> {
    try {
      const { data: { capacityStatistics } } = await this.api.post("", {
        query: capacityStatisticsQuery,
        variables: {
          limit,
          offset,
          sort,
          order,
          filters,
          groups
        }
      });
      
      return capacityStatistics;
    } catch (e) {
      throw e;
    }
  }
}