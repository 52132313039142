import Service from "@core/services/common/Service";
import discounts from "@core/services/webmaster/discounts/discounts.json";
import { DiscountsState } from "@core/store/types/webmaster/discounts";

export class DiscountsService extends Service {
  public static getDiscounts (): Promise<DiscountsState> {
    try {
      //TODO изменить на запрос когда он появится на бекенде
      
      // return await this.api.get("", {
      //   params: {
      //     query: discounts
      //   }
      // });
      
      // @ts-ignore
      return discounts;
    } catch (e) {
      throw e;
    }
  }
}