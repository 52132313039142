import resendEmail from "@core/services/common/graphql/resendEmail.graphql";
import Service from "@core/services/common/Service";
import {
  EditInput,
  EditResponse, InputRequestManager,
  WebmasterResponse
} from "@core/store/types/webmaster/profile";
import { WebmasterProfileRequisitesResponse } from "@core/store/types/webmaster/profile/modules/requisites";
import {
  WebmasterProfileRequisitesAddInput,
  WebmasterProfileRequisitesAddResponse,
  WebmasterProfileRequisitesRemoveResponse
} from "@core/store/types/webmaster/profile/modules/requisites/modules/add";
import { WebmasterProfileTokensResponse } from "@core/store/types/webmaster/profile/modules/tokens";
import { AxiosPromise, AxiosResponse } from "axios";
import addRequisiteByWebmaster from "./graphql/addRequisiteByWebmaster.graphql";
import deleteRequisiteByWebmaster from "./graphql/deleteRequisiteByWebmaster.graphql";
import isUserExistInZaleyCash from "./graphql/isUserExistInZaleyCash.graphql";
import isValidTronAddress from "./graphql/isValidTronAddress.graphql";
import editWebmasterProfile from "./graphql/editWebmasterProfile.graphql";
import profile from "./graphql/profile.graphql";
import requisites from "./graphql/requisites.graphql";
import updateWebmasterNotifications from "./graphql/updateWebmasterNotifications.graphql";
import requestToChangeManagerQuery from "./graphql/requestToChangeManager.graphql";
import { UserFormInterface } from "@core/store/logic/webmaster/UserForm";

export class ProfileService extends Service {
  public static getProfile (): AxiosPromise<WebmasterResponse> {
    try {
      return this.api.get("", {
        params: {
          query: profile
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static editProfile (input: EditInput | UserFormInterface): AxiosPromise<EditResponse> {
    try {
      return this.api.post("", {
        query: editWebmasterProfile,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getRequisites (): AxiosPromise<WebmasterProfileRequisitesResponse> {
    try {
      return this.api.get("", {
        params: {
          query: requisites
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static addRequisite (input: WebmasterProfileRequisitesAddInput): AxiosPromise<WebmasterProfileRequisitesAddResponse> {
    try {
      return this.api.post("", {
        query: addRequisiteByWebmaster,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static removeRequisite (requisiteId: string): AxiosPromise<WebmasterProfileRequisitesRemoveResponse> {
    try {
      return this.api.post("", {
        query: deleteRequisiteByWebmaster,
        variables: {
          requisiteId
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getTokens (): Promise<AxiosResponse<WebmasterProfileTokensResponse>> {
    try {
      // TODO: Поменять на gql api, когда у бека там появится токены
      const response = await this.rest.get("/webmaster/tokens");

      response.data = {
        tokens: {
          items: response.data,
          count: response.data.length
        }
      };

      return response;
    } catch (e) {
      throw e;
    }
  }

  public static resendEmail (): AxiosPromise {
    try {
      return this.api.post("", {
        query: resendEmail
      });
    } catch (e) {
      throw e;
    }
  }

  public static userExistInZaleyCash (email: string): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: isUserExistInZaleyCash,
          variables: {
            email
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static validTronAddress (address: string): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: isValidTronAddress,
          variables: {
            address
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static updateNotifications (input: any): AxiosPromise {
    try {
      return this.api.post("", {
        query: updateWebmasterNotifications,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async requestToChangeManager (input: InputRequestManager) {
    try {
      const { data: { requestToChangeManager } } = await this.api.post("", {
        query: requestToChangeManagerQuery,
        variables: {
          input
        }
      });

      return requestToChangeManager;
    } catch (e) {
      throw e;
    }
  }
}
