import { SET_COMPETITION_RATING } from "@core/store/mutation-constants";
import { GET_COMPETITION_RATING } from "@core/store/action-constants";
import { CompetitionService } from "@core/services/admin/competition/CompetitionService";
import { CompetitionRatingState } from "@core/store/types/admin/competition/competitionRating";
import { CompetitionRatingModule } from "@core/store/modules/common/competition/competitionRating";
import { RootState } from "@core/store/root-state";

export class CompetitionRating extends CompetitionRatingModule<CompetitionRatingState, RootState> {
  constructor () {
    super();

    this.actions({
      async [GET_COMPETITION_RATING] ({ state, commit }): Promise<void> {
        const { period } = state.filters;

        const data = await CompetitionService.getCompetitionsRating(period);

        commit(SET_COMPETITION_RATING, data);
      }
    });

    return this;
  }
}

export const competitionRating = new CompetitionRating().toModule();
