import { UPDATE_CURRENT_VERTICAL } from "@core/store/action-constants";
import store from "../../../../../../store";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useVerticals } from "../../../../../../stores/common/auth/verticalsStore";

export function setPreferredVertical (preferredVertical: VerticalsEnum): void {
  const urlParams = new URLSearchParams(window.location.search);
  const { vertical } = Object.fromEntries(urlParams.entries());
  const verticalsStore = useVerticals();

  if (vertical || preferredVertical) {
    const currentVertical = vertical === "" ? null : vertical || preferredVertical;
    store.commit(
      `verticals/${ UPDATE_CURRENT_VERTICAL }`,
      currentVertical,
      { root: true }
    );

    verticalsStore.preferredVertical = preferredVertical;
  }
}