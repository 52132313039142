import Service from "@core/services/common/Service";
import { AdvertiserOffer } from "@core/logic/common/offer/offer";
import { WithdrawType } from "@core/logic/common/common";

export class DictionariesService extends Service{
  public static async getOffers (params: any): Promise<any> {
    // return Axios.get("/advertiser/dictionaries/offers', { params });
    const response = await this.rest.get("/advertiser/offers", { params });
    response.data.items.forEach((item: any) => {
      return {
        id: item.id,
        name: item.name
      };
    });

    return response;
  }
}

export class DictionariesAdvertiserOffer extends AdvertiserOffer {
  name: string | undefined;
  withdrawType: WithdrawType | undefined;
  
  constructor (offer: any) {
    super(offer);
    
    this.name = offer.name;
    this.withdrawType = offer.withdrawType;
  }
}
