import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { CreatedOffer, Offer, OfferService, Landing } from "@core/logic/admin/offer/offer";
import {
  SET_OFFER,
  SET_OFFER_ADVERTISER_WEBMASTERS,
  SET_OFFER_ADVERTISERZ, SET_OFFER_RATES_REQUIREMENTS,
  SET_OFFER_TRAFFIC_TYPES,
  SET_OFFER_WEBMASTERS,
  UPDATE_OFFER_ADVERTISERZ,
  UPDATE_OFFER_WEBMASTERS,
  SET_LANDINGS,
  SET_TRANSITS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import {
  ADD_OFFER_CALLCENTERS,
  GET_OFFER,
  GET_OFFER_ADVERTISER_WEBMASTERS,
  GET_OFFER_ADVERTISERS,
  GET_OFFER_WEBMASTERS,
  GET_OFFER_WEBMASTERS_AVAILABLE,
  UPDATE_OFFER_RATES_REQUIREMENTS,
  UPDATE_OFFER_TRAFFIC_TYPES,
  GET_LANDINGS,
  GET_TRANSITS
} from "@core/store/action-constants";
import { PaginationOutput } from "@core/logic/common/pagination-output";

interface User {
  contact: any;
  id: string;
  intId: number;
  login: string;
}

interface Advertiser {
  percent: number;
  user: User;
  webmasters: User[];
}

interface Advertisers extends PaginationOutput {
  items: Advertiser[];
  count: number;
}

interface Landings extends PaginationOutput {
  items: Landing[];
  count: number;
}

export interface OfferState {
  offer: Offer | null;
  webmasters: any | null;
  advertisers: Advertisers | null;
  trafficTypes: any | null;
  offerLandings: Landings | null;
  offerTransits: Landings | null;
}

const initialState = (): OfferState => {
  return {
    offer: null,
    webmasters: null,
    advertisers: null,
    trafficTypes: null,
    offerLandings: null,
    offerTransits: null
  };
};

const state: () => OfferState = initialState;

const mutations: MutationTree<OfferState> = {
  [SET_OFFER_TRAFFIC_TYPES] (state, trafficTypes) {
    state.trafficTypes = trafficTypes;
  },
  [SET_OFFER] (state, offer: Offer) {
    state.offer = offer;
  },
  [SET_OFFER_WEBMASTERS] (state, webmasters) {
    state.webmasters = webmasters;
  },
  [UPDATE_OFFER_WEBMASTERS] (state, webmasters) {
    if (state.webmasters) {
      state.webmasters = {
        count: webmasters.count,
        items: state.webmasters.items.concat(webmasters.items)
      };
    }
  },
  [SET_OFFER_ADVERTISERZ] (state, advertisers) {
    state.advertisers = advertisers;
  },
  [UPDATE_OFFER_ADVERTISERZ] (state, advertisers) {
    if (state.advertisers) {
      state.advertisers.items = state.advertisers.items.concat(advertisers.items);
    }
  },
  [SET_OFFER_ADVERTISER_WEBMASTERS] (state, { advertiserId, webmasters }) {
    if (state.advertisers) {
      state.advertisers.items = state.advertisers.items.map(item => {
        if (item.user.id === advertiserId) {
          const webmastersItems = webmasters.items.length ? webmasters.items : null;
          return Object.assign(item, { webmasters: webmastersItems });
        }
        return item;
      });
    }
  },

  [SET_LANDINGS] (state, landings) {
    if (state.offerLandings && state.offerLandings.items) {
      state.offerLandings.items = [ ...state.offerLandings.items, ...landings.items ];
    } else { state.offerLandings = landings; }
  },

  [SET_TRANSITS] (state, landings) {
    if (state.offerTransits && state.offerTransits.items) {
      state.offerTransits.items = [ ...state.offerTransits.items, ...landings.items ];
    } else { state.offerTransits = landings; }
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<OfferState, RootState> = {
  async [GET_OFFER] ({ commit }, offerId) {
    const { data } = await OfferService.getOffer(offerId);
    const offer = new CreatedOffer(data);
    commit(SET_OFFER, offer);

    return offer;
  },

  async [UPDATE_OFFER_RATES_REQUIREMENTS] ({ commit }, offerId) {
    const { data } = await OfferService.getOffer(offerId);
    const offer = new CreatedOffer(data);
    commit(SET_OFFER_RATES_REQUIREMENTS, offer);
    
    return offer;
  },

  async [UPDATE_OFFER_TRAFFIC_TYPES] (_, { offerId, trafficTypes }) {
    await OfferService.updateOfferTrafficTypes(offerId, { ...trafficTypes });
  },

  async [GET_OFFER_WEBMASTERS] ({ commit }, { offerId, params }) {
    const { data } = await OfferService.getOfferWebmasters(offerId, params);
    if (params.offset === 0) {
      commit(SET_OFFER_WEBMASTERS, data);
    } else {
      commit(UPDATE_OFFER_WEBMASTERS, data);
    }
    return data;
  },

  async [GET_OFFER_ADVERTISERS] ({ commit }, { offerId, params }) {
    const { data } = await OfferService.getOfferAdvertisers(offerId, params);
    if (params.offset === 0) {
      commit(SET_OFFER_ADVERTISERZ, data);
    } else {
      commit(UPDATE_OFFER_ADVERTISERZ, data);
    }
    return data;
  },

  async [GET_OFFER_ADVERTISER_WEBMASTERS] ({ commit }, { offerId, params }) {
    const { data } = await OfferService.getOfferAdvertiserWebmasters(offerId, params);
    commit(SET_OFFER_ADVERTISER_WEBMASTERS, {
      advertiserId: params.filters.advertiserId,
      webmasters: data
    });
    return data;
  },

  async [GET_OFFER_WEBMASTERS_AVAILABLE] (_, { offerId, params }) {
    const { data } = await OfferService.getOfferWebmastersAvailable(offerId, params);
    return data;
  },

  async [ADD_OFFER_CALLCENTERS] (_, { offerId, callCenterIds }) {
    try {
      await OfferService.addOfferCallCenters(offerId, callCenterIds);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [GET_LANDINGS] ({ commit }, { id, params }) {
    const limit = params?.perPage || 25;
    const offset = (params?.page - 1) * limit || 0;
    try {
      const filters = { pageType: "landing" };
      const { data } = await OfferService.getLandings(id, { limit, offset, filters });
      commit(SET_LANDINGS, data);
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [GET_TRANSITS] ({ commit }, { id, params }) {
    const limit = params?.perPage || 25;
    const offset = (params?.page - 1) * limit || 0;
    try {
      const filters = { pageType: "transit" };
      const { data } = await OfferService.getLandings(id, { limit, offset, filters });
      commit(SET_TRANSITS, data);
    } catch (e: any) {
      throw new Error(e);
    }
  }
};

export const offerOld: Module<OfferState, RootState> = {
  // modules: {
  //   edit
  // },
  state,
  actions,
  mutations
};
