import Service from "@core/services/common/Service";
import {
  BampsAndCapsIneffectiveResponse,
  BampsAndCapsResponse,
  BampsAndCapsState
} from "@core/store/types/admin/offers/bampsAndCaps.d";
import { AxiosPromise } from "axios";
import markOfferParameterRequestAsEffective from "@core/services/admin/offers/graphql/markOfferParameterRequestAsEffective.graphql";
import markOfferParameterRequestAsIneffective from "@core/services/admin/offers/graphql/markOfferParameterRequestAsIneffective.graphql";
import renewalGuaranteeRequest from "@core/services/admin/offers/graphql/guarantee/renewalGuaranteeRequest.graphql";
import { BampsAndCapsEffectiveResponse } from "@core/store/types/admin/offers/bampsAndCaps";
import offerParameterRequests from "@core/services/admin/offers/graphql/offerRequests.generator";
import { RenewalGuaranteeRequestEnum } from "@core/store/types/admin/capacityAndBumps/emuns/renewalGuaranteeRequestEnum";

export class OfferRequestService extends Service {
  public static getOfferRequest (
    limit: number,
    offset: number,
    sort: BampsAndCapsState["sort"],
    order: BampsAndCapsState["order"],
    filters: BampsAndCapsState["filters"],
    isShowCommission: boolean,
    isShowOfferAdvertisers: boolean
  ): AxiosPromise<BampsAndCapsResponse> {
    try {
      return this.api.get("", {
        params: offerParameterRequests(
          limit,
          offset,
          sort,
          order,
          filters,
          isShowCommission,
          isShowOfferAdvertisers
        )
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static updateOfferRequestEffective (
    id: string,
    comment: string
  ): AxiosPromise<BampsAndCapsEffectiveResponse> {
    try {
      return this.api.post("", {
        query: markOfferParameterRequestAsEffective,
        variables: {
          id,
          comment
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static updateOfferRequestIneffective (
    id: string,
    comment: string
  ): AxiosPromise<BampsAndCapsIneffectiveResponse> {
    try {
      return this.api.post("", {
        query: markOfferParameterRequestAsIneffective,
        variables: {
          id,
          comment
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static renewalGuaranteeRequest (id: string, input: { status: RenewalGuaranteeRequestEnum }): AxiosPromise<BampsAndCapsIneffectiveResponse> {
    try {
      return this.api.post("", {
        query: renewalGuaranteeRequest,
        variables: {
          id,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
}