import capacityGraphByWebmaster from "@core/services/advertiser/offer/webmasters/graphql/capacityGraphByWebmaster.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { OfferWebmasterAnalyseModalOptions } from "@core/store/types/advertiser/offer/webmasters/offerWebmasterAnalyseModal";

export class OfferWebmasterAnalyseModalService extends Service {
  public static async getAnalyseGraph (
    offerId: string,
    webmasterId: OfferWebmasterAnalyseModalOptions["webmasterId"],
    filters: any
  ): Promise<AxiosPromise> {
    try {
      const { data: { capacityGraphByWebmaster: data } } = await this.api.get("", {
        params: {
          query: capacityGraphByWebmaster,
          variables: {
            offerId,
            webmasterId,
            filters
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}