import { AdvertisersDutyAnalyseGroup } from "@core/store/types/admin/finances/advertisersDuty/enums/advertisersDutyEnums";
import { AdvertisersDutyAnalyseState } from "@core/store/types/admin/finances/advertisersDuty/advertisersDutyAnalyse";
import { AdvertisersDutyService } from "@core/services/admin/finances/advertisersDuty/advertisersDutyService";
import { AdvertisersFilters } from "@core/store/modules/common/lists/advertisersList";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import {
  SET_MODAL_ACTIVE,
  UPDATE_FILTERS,
  SET_ANALYTIC
} from "@core/store/mutation-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_ADVERTISERS_DUTY_ANALYSE,
  UPDATE_MODAL_ACTIVE,
  SET_EMPTY
} from "@core/store/action-constants";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): AdvertisersDutyAnalyseState => {
  return {
    advertisersDutyAnalyse: null,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "weeks" }, "date"),
      groups: AdvertisersDutyAnalyseGroup.DAY,
      currency: CurrencyEnum.USD,
      advertiserId: null
    },
    isModalActive: false
  };
};

const state: () => AdvertisersDutyAnalyseState = initialState;

const mutations: MutationTree<AdvertisersDutyAnalyseState> = {
  [SET_ANALYTIC] (state, advertisersDutyAnalyse: AdvertisersDutyAnalyseState["advertisersDutyAnalyse"]): void {
    state.advertisersDutyAnalyse = advertisersDutyAnalyse;
  },

  [UPDATE_FILTERS] (state, filters: AdvertisersDutyAnalyseState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_MODAL_ACTIVE] (state, isModalActive: boolean): void {
    state.isModalActive = isModalActive;
  },

  [SET_EMPTY]: (state): AdvertisersDutyAnalyseState => Object.assign(state, initialState())
};

const actions: ActionTree<AdvertisersDutyAnalyseState, RootState> = {
  async [GET_ADVERTISERS_DUTY_ANALYSE] ({ commit, state }): Promise<void> {
    const { datepicker, advertiserId, groups, currency, ...filters } = state.filters;

    const data = await AdvertisersDutyService.getAdvertisersDutyAnalyse(
      { ...datepicker, ...filters },
      advertiserId,
      groups,
      currency
    );

    commit(SET_ANALYTIC, data);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(SET_MODAL_ACTIVE, payload);
  },

  [UPDATE_FILTERS] ({ commit }, payload: AdvertisersDutyAnalyseState["filters"]): void {
    commit(UPDATE_FILTERS, payload);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const advertisersDutyAnalyseModal: Module<RootState, AdvertisersDutyAnalyseState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    advertisersFilters: new AdvertisersFilters()
  }
};