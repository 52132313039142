const isFilterSelected = (value) => {
  if (Array.isArray(value)) {
    return value.length;
  }
  return value != null && value !== "";
};

// @vue/components
export const FiltersCount = {
  computed: {
    optionFilters () {
      // eslint-disable-next-line no-console
      console.warn("Should be override");
      return {};
    },
    
    optionFiltersCount () {
      return Object
        .values(this.optionFilters)
        // FixMe: переписать на reduce
        .filter(isFilterSelected)
        .length;
    }
  }
};
