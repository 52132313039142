function IsJsonString (str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getMacrosString (string: string): string[] {
  if (string) {
    return Array.from(string.matchAll(/{(\w+)}/g)).map(arr => arr[1]);
  }
  return [];
}

export function getMacrosJson (string: string): string[] {
  if (string && IsJsonString(string)) {
    const regExp = /{(.+?)}/g;
    const newCustomMacros: string[] = [];
    
    JSON.parse(string, function (key, value) {
      const keyMacros = Array.from(key?.matchAll(regExp)).map(arr => arr[1]);
      let keyValue;
      if (typeof value === "string") {
        keyValue = Array.from(value?.matchAll(regExp)).filter(el => !el[0].includes("fields:")).map(arr => arr[1]);
      }
      
      if (keyMacros) {
        newCustomMacros.push(...keyMacros);
      }
      if (keyValue) {
        newCustomMacros.push(...keyValue);
      }
    });
    return newCustomMacros;
  }
  return [];
}

export function getCustomMacros (macrosList: string[] | null, systemMacros: string[] = []): string[] {
  return macrosList?.map(el => el.replace(/[{}]/g, ""))
    .filter(el => !systemMacros.includes(el)) || [];
}
