import { Filters } from "@core/store/modules/common/generators/filters";
import { RegionsItem } from "@core/store/types/admin/statistic/common/regionsItem";
import { RegionsListService } from "@core/services/admin/lists/RegionsListService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class RegionsFilters extends Filters<RegionsItem, BaseFiltersInput> {
  getData = async () => {
    const { data } = await RegionsListService.getRegionsList();
    
    return {
      data: {
        items: data,
        count: data.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const regionId = Array.isArray(ids) ? ids : [ids];
      return { regionId };
    }
  };
}

export const regionsFilters = new RegionsFilters();
