import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import enumValues from "@core/services/common/graphql/enumValues.graphql";

export class EnumValues extends Service {
  public static getEnumValues (enumListName: string): AxiosPromise {
    return this.api.post("", {
      query: enumValues,
      variables: { enumListName }
    });
  }
}