































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { useToChangeManagerModal } from "@/stores/webmaster/profile/profileToChangeManagerModalStore";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
import { REQUEST_TO_CHANGE_MANAGER } from "@core/store/action-constants";
import { onUnmounted } from "@vue/composition-api";
import { actionWithToast } from "@/helpers/actionWithToast";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useToChangeManagerModal();
  const {
    isActive,
    input
  } = storeToRefs(store);
  const isLoading = useWait(store, REQUEST_TO_CHANGE_MANAGER);
  async function onSendRequestToChange() {
    await actionWithToast(store.REQUEST_TO_CHANGE_MANAGER(), "common.entity.manager.modal.messages");
    isActive.value = false;
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    isActive,
    input,
    isLoading,
    onSendRequestToChange
  };
};
__sfc_main.components = Object.assign({
  ModalBox
}, __sfc_main.components);
export default __sfc_main;
