import { GET_METHODS } from "@core/store/action-constants";
import { UPDATE_METHODS_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { MethodsListState } from "@core/store/types/admin/crm/lists/MethodsListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): MethodsListState => {
  return {
    data: {}
  };
};

const state: () => MethodsListState = initialState;

const getters: GetterTree<MethodsListState, RootState> = {};

const mutations: MutationTree<MethodsListState> = {
  [UPDATE_METHODS_LIST] (state, methods: MethodsListState["data"]) {
    state.data = methods;
  }
};

const actions: ActionTree<MethodsListState, RootState> = {
  [GET_METHODS] ({ commit }) {
    const items = [
      {
        label: "get",
        value: "GET"
      },
      {
        label: "post",
        value: "POST"
      },
      {
        label: "put",
        value: "PUT"
      },
      {
        label: "patch",
        value: "PATCH"
      }
    ];
    commit(UPDATE_METHODS_LIST, {
      items,
      count: items.length
    });
  }
};

export const methodsList: Module<MethodsListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
