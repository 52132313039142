import { ExportService } from "@core/services/common/exports/ExportService";
import { UPDATE_MODAL_ACTIVE, UPDATE_MODAL_OPTIONS, UPLOAD_FILE } from "@core/store/action-constants";
import { formatFileList } from "@core/store/modules/common/uploads/lists/formatFileLists";
import { uploadsFieldsList } from "@core/store/modules/common/uploads/lists/uploadsFieldsList";
import { SET_UPLOADS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { UploadFileListItem, UploadFileState } from "@core/store/types/admin/uploads/UploadFileState";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import _flatten from "lodash/flatten";

const initialState = (): UploadFileState => {
  return {
    options: {
      query: null,
      fields: [],
      format: "XLSX",
      filters: [],
      arguments: null
    },
    isUploadFileModalActive: false
  };
};

const state: () => UploadFileState = initialState;

const getters: GetterTree<UploadFileState, RootState> = {
  filters: (state): UploadFileListItem["filters"] => {
    const filters = state.options?.filters;
    const newFilters: UploadFileListItem["filters"] = [];
    
    if (filters) {
      for (const [name, value] of Object.entries(filters)) {
        if (value || value === false) {
          if (name === "datepicker") {
            // @ts-ignore
            newFilters.push({ name: "dateStart", value: [value.dateStart] });
            // @ts-ignore
            newFilters.push({ name: "dateEnd", value: [value.dateEnd] });
          } else if (name === "registeredDatepicker") {
            // @ts-ignore
            if (value.registeredDateStart) newFilters.push({ name: "registeredDateStart", value: [value.registeredDateStart] });
            // @ts-ignore
            if (value.registeredDateEnd) newFilters.push({ name: "registeredDateEnd", value: [value.registeredDateEnd] });
          } else {
            newFilters.push({ name, value: Array.isArray(value) ? value : [value.toString()] });
          }
        }
      }
    }
    return newFilters;
  },
  
  arguments: (state): UploadFileListItem["arguments"] => {
    const args = state.options?.arguments;
    const newArguments: UploadFileListItem["filters"] = [];
    
    if (args) {
      for (const [name, value] of Object.entries(args)) {
        if (value) {
          newArguments.push({
            name,
            // @ts-ignore
            value: value
          });
        }
      }
    }
    return newArguments;
  }
};

const mutations: MutationTree<UploadFileState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_OPTIONS] (state, option: UploadFileListItem) {
    state.options = { ...state.options, ...option };
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isUploadFileModalActive = payload;
  }
};

const actions: ActionTree<UploadFileState, RootState> = {
  async [UPLOAD_FILE] ({
    state,
    getters,
    commit,
    rootState
  }) {
    const newFilters = getters.filters as unknown as UploadFileListItem["filters"];
    const newArguments = getters.arguments as unknown as UploadFileListItem["arguments"];
    const language = rootState.locale.locale.toUpperCase();
    const { fields } = state.options;

    try {
      const { data: { queryExport } } = await ExportService.queryExport(
        {
          ...state.options,
          fields: _flatten(fields),
          filters: newFilters,
          language,
          arguments: newArguments
        }
      );
      const { id, ...options } = queryExport;
      commit(`uploads/${ SET_UPLOADS }`, options, { root: true });
      
      return id;
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: any) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit(UPDATE_MODAL_ACTIVE, payload);
    } else {
      commit("SET_EMPTY");
    }
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
    commit("uploadsFieldsList/SET_EMPTY");
  }
};

export const uploadFile = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    uploadsFieldsList,
    formatFileList
  }
};
