import _debounce from "lodash/debounce";

// @vue/components
export const FilteredComponent = {
  created () {
    this.update();
  },
  
  data () {
    return {
      filters: {},
      sort: {}
    };
  },
  
  computed: {
    sortings () {
      return {
        sort: Object.keys(this.sort)[0],
        order: Object.values(this.sort)[0]?.toUpperCase()
      };
    },
    
    debouncedUpdate () {
      return _debounce(this.debouncedRequest, 400, { trailing: true });
    }
  },
  
  methods: {
    update () {
      this.debouncedUpdate();
    },
    
    debouncedRequest () {
      // eslint-disable-next-line no-console
      console.warn("Should be override");
    },
    
    updateSortings ({
      value,
      order
    }) {
      this.sort = {
        ...this.sort,
        [value]: order
      };
    },
    
    clearFilters () {
      this.filters = this.$options.data.call(this).filters;
    }
  },
  
  watch: {
    filters: {
      deep: true,
      handler () {
        if (this.currentPage) {
          this.currentPage = 1;
        }
        this.update();
      }
    },
  
    excludedFilters: {
      deep: true,
      handler () {
        if (this.currentPage) {
          this.currentPage = 1;
        }
        this.update();
      }
    },
    
    sort: {
      deep: true,
      handler () {
        // Подразумевается тот метод update, который вызывает debouncedUpdate
        this.update();
      }
    }
  }
};
