import Service from "@core/services/common/Service";
import registrations from "./graphql/registrations.graphql";
import { DomonetizationResponse } from "@core/store/types/admin/statistic/domonetization/domonetization";
import { RegistrationsFilters } from "@core/store/types/admin/statistic/registrations/registrationsState";

export class RegistrationsService extends Service {
  public static async getRegistrations (
    limit: number,
    offset: number,
    sort: Nullable<Array<string> | string>,
    order: "DESC" | "ASC" | null,
    filters: RegistrationsFilters,
    group: RegistrationsFilters["group"]
  ): Promise<DomonetizationResponse> {
    try {
      const { data: { webmasterRegistrations } } = await this.api.get("", {
        params: {
          query: registrations,
          variables: {
            limit,
            offset,
            sort,
            order,
            filters,
            group
          }
        }
      });

      return webmasterRegistrations;
    } catch (e) {
      throw e;
    }
  }
}
