import { GET_CURRENCY } from "@core/store/action-constants";
import { UPDATE_CURRENCY_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CurrencyListState } from "@core/store/types/common/lists/CurrencyList";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { CurrenciesService } from "@core/services/common/currenciesService";

const initialState = (): CurrencyListState => {
  return {
    data: []
  };
};

const state: () => CurrencyListState = initialState;

const getters: GetterTree<CurrencyListState, RootState> = {};

const mutations: MutationTree<CurrencyListState> = {
  [UPDATE_CURRENCY_LIST] (state, currency: CurrencyListState["data"]) {
    state.data = currency;
  }
};

const actions: ActionTree<CurrencyListState, RootState> = {
  async [GET_CURRENCY] ({ commit }) {
    const { data: { currencies } } = await CurrenciesService.getCurrencies();
    commit(UPDATE_CURRENCY_LIST, currencies);
    return currencies;
  }
};

export const currencyList: Module<CurrencyListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
