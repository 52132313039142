import Service from "@core/services/common/Service";
import {
  AdminListRolesParams,
  PermissionsArr,
  Role,
  RoleInput,
  RolesFilters
} from "@core/store/types/admin/roles/roles";
import addRoleQuery from "@core/services/admin/roles/graphql/addRoleQuery.graphql";
import updateRoleQuery from "@core/services/admin/roles/graphql/updateRoleQuery.graphql";
import deleteRoleQuery from "@core/services/admin/roles/graphql/deleteRoleQuery.graphql";
import addOrEditAdminsRolesQuery from "@core/services/admin/roles/graphql/addOrEditAdminsRolesQuery.graphql";
import getRolesQuery from "@core/services/admin/roles/graphql/getRolesQuery.graphql";
import getPermissionsQuery from "@core/services/admin/roles/graphql/getPermissionsQuery.graphql";
import getRolesByIdQuery from "@core/services/admin/roles/graphql/getRolesByIdQuery.graphql";

export class RolesService extends Service {
  public static async getPermissions (): Promise<PermissionsArr> {
    try {
      const { data: { permissions } } = await this.api.get("", {
        params: {
          query: getPermissionsQuery
        }
      });

      return permissions;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getRoles (
    limit: number,
    offset: number,
    filters: RolesFilters
  ): Promise<Role> {
    try {
      const { data: { roles } } = await this.api.get("", {
        params: {
          query: getRolesQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return roles;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getRolesById (
    limit: number,
    offset: number,
    filters: RolesFilters
  ): Promise<Role> {
    try {

      const { data: { roles: { items } } } = await this.api.get("", {
        params: {
          query: getRolesByIdQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });

      return items[0];
    } catch (e) {
      throw e;
    }
  }
  
  public static async addRole (
    name: string | null,
    permissions: string[]
  ): Promise<Role> {
    try {
      const { data: { addRole } } = await this.api.post("", {
        query: addRoleQuery,
        variables: {
          input:{
            name,
            permissions
          }
        }
      });
    
      return addRole;
    } catch (e) {
      throw e;
    }
  }
  
  public static async addOrEditMultipleAdminsRoles (admins: AdminListRolesParams): Promise<Role[]> {
    try {
      const { data: { addOrEditAdminsRoles } } = await this.api.post("", {
        query: addOrEditAdminsRolesQuery,
        variables: {
          input: {
            admins
          }
        }
      });
      return addOrEditAdminsRoles;
    } catch (e) {
      throw e;
    }
  }
  
  public static async updateRole (
    roleId: string | null,
    input: RoleInput 
  ): Promise<Role> {
    try {
      const { data: { updateRole } } = await this.api.post("", {
        query: updateRoleQuery,
        variables: {
          roleId,
          input
        }
      });
      
      return updateRole;
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteRole (
    roleId: string
  ): Promise<Role>{
    try {
      const { data: { deleteRole } } = await this.api.post("", {
        query: deleteRoleQuery,
        variables: {
          roleId
        }
      });
      
      return deleteRole;
    } catch (e) {
      throw e;
    }
  }
}