import { BaseFilter, BaseFilterList } from "@core/logic/common/dictionaries/BaseFilter";

export class UserFilter extends BaseFilter {
  public login = "";
  
  constructor (user: any) {
    super();
    
    this.id = user.id;
    this.login = user.login;
  }
}

export interface UsersFilterList extends BaseFilterList {
  items: UserFilter[];
}