import {
  EXTEND_HOLD_TIME,
  SET_EMPTY
} from "@core/store/action-constants";
import { UPDATE_MODAL } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  DetailingDepositHistoryModalState
} from "@core/store/types/common/statistic/detailing/detailingDepositHistoryModal";
import {
  DetailingExtendHoldTimeModalService
} from "@core/services/admin/statistic/detailing/modal/DetailingExtendHoldTimeModalService";
import {
  DetailingExtendHoldTimeModal
} from "@core/store/types/admin/statistic/detailing/modal/detailingExtendHoldTimeModal";

const initialState = (): DetailingExtendHoldTimeModal => {
  return {
    modal: {
      isActive: false,
      leadIds: null,
      prolongation: 0
    }
  };
};

const state: () => DetailingExtendHoldTimeModal = initialState;

const mutations: MutationTree<DetailingExtendHoldTimeModal> = {
  SET_EMPTY: (state: DetailingExtendHoldTimeModal): DetailingExtendHoldTimeModal => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, options: DetailingDepositHistoryModalState["modal"]): void {
    state.modal = { ...state.modal, ...options };
  }
};

const actions: ActionTree<DetailingExtendHoldTimeModal, RootState> = {
  async [EXTEND_HOLD_TIME] ({ state: { modal: { prolongation, leadIds } } }): Promise<void> {
    await DetailingExtendHoldTimeModalService.extendHoldTime(leadIds, prolongation);
  },

  [UPDATE_MODAL] ({ commit }, options: DetailingDepositHistoryModalState["modal"]): void {
    commit(UPDATE_MODAL, options);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const detailingExtendHoldTimeModal: Module<DetailingExtendHoldTimeModal, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
