import MailConfirmationConfirmed from "@/components/Common/MailConfirmation/MailConfirmationConfirmed.vue";
import MailConfirmationExpired from "@/components/Common/MailConfirmation/MailConfirmationExpired.vue";
import MailConfirmationFailed from "@/components/Common/MailConfirmation/MailConfirmationFailed.vue";
import MailConfirmationSend from "@/components/Common/MailConfirmation/MailConfirmationSend.vue";
import Empty from "@/views/Empty.vue";

export default {
  path: "/confirmation",
  name: "mail",
  redirect: { name: "main" },
  component: Empty,
  children: [
    {
      path: "send",
      name: "send",
      component: MailConfirmationSend
    },
    {
      path: "succeed",
      name: "succeed",
      component: MailConfirmationConfirmed
    },
    {
      path: "failed",
      name: "failed",
      component: MailConfirmationFailed
    },
    {
      path: "update",
      name: "update",
      component: MailConfirmationExpired
    }
  ]
};
