import {
  AdvertisersCapacityItem,
  AdvertisersCapacityItems
} from "@core/store/types/admin/offers/capacity";

export const capacityMapper = ({ items, ...data }: AdvertisersCapacityItems) => ({
  ...data,
  items: items.map((item: AdvertisersCapacityItem) => processData(item))
});

const processData = ({ statisticCapacity, statisticCapacityByAdvertiser, statisticCapacityByOffer, requiredApprove, ...item }: AdvertisersCapacityItem): AdvertisersCapacityItem => ({
  ...item,
  ...statisticCapacity,
  ...statisticCapacityByAdvertiser,
  ...statisticCapacityByOffer,
  ...requiredApprove
});
