import { formatPercent, hasInfinity, toFixed } from "@core/filters";
import momentjs from "moment/moment";
import { moment } from "@core/filters";

export function negativeTagColor (value) {
    return value < 0 ? "success" : value === 0 || value === "0.00" ? "silver" : "danger";
}

export function positiveTagColor (value) {
    return value < 0 ? "danger" : value === 0 || value === "0.00" ? "silver" : "success";
}

export function dataPercent (item, total) {
    return toFixed(formatPercent(hasInfinity(item / total)), 2);
}

function formatDate (date) {
    return moment(date, "DD.MM.YYYY");
}

export function labelPeriod (value) {
    const { dateStart, dateEnd } = value;
    return `${ formatDate(dateStart) } - ${ formatDate(dateEnd) }`;
}

function percentShares (value, total) {
    const { countLeadsIntegrated: CLI = {} } = total ?? {};
    return dataPercent(value.second, CLI.second) - dataPercent(value.first, CLI.first);
}

function difference (value, total) {
    return { value: calculateDiff(value), percentShares: percentShares(value, total) };
}

export const calculateDiff = (v) => v.second - v.first;

export function totalTable () {
    const formatDate = (unixTimestamp) => momentjs.unix(unixTimestamp).utc().format("HH:mm:ss");
    const { total, firstPeriod, secondPeriod, comparisonPeriods } = this;

    const {
        countLeadsIntegrated = {}, countLeadsNew = {}, countRecalls = {}, countLeadsAccepted = {}, countLeadsCancelled = {},
        countLeadsValidCancelReason = {}, countLeadsInvalidCancelReason = {}, countLeadsDouble = {}, countLeadsSpamByAdvertiser = {},
        countNegativeCallBackLeads = {}, countLeadsLzHold = {}, countValidCalls = {}, averageCallTime = {},
        averageCallAttemptsByTotalAttempts = {}, averageCallsByTotalAttempts = {}, averageCallAttempts = {},
        countLeadsWasInRecall = {}
    } = total ?? {};

    const items = [
        {
            ...comparisonPeriods && { period: labelPeriod(firstPeriod) },
            countLeadsIntegrated: countLeadsIntegrated.first,
            countLeadsNew: countLeadsNew.first,
            countRecalls: countRecalls.first,
            countLeadsWasInRecall: countLeadsWasInRecall.first,
            countLeadsAccepted: countLeadsAccepted.first,
            countLeadsCancelled: countLeadsCancelled.first,
            countLeadsValidCancelReason: countLeadsValidCancelReason.first,
            countLeadsInvalidCancelReason: countLeadsInvalidCancelReason.first,
            countLeadsDouble: countLeadsDouble.first,
            countLeadsSpamByAdvertiser: countLeadsSpamByAdvertiser.first,
            countNegativeCallBackLeads: countNegativeCallBackLeads.first,
            countLeadsLzHold: countLeadsLzHold.first,
            countValidCalls: countValidCalls.first,
            averageCallTime: formatDate(averageCallTime.first),
            averageCallAttemptsByTotalAttempts: toFixed(averageCallAttemptsByTotalAttempts.first),
            averageCallsByTotalAttempts: toFixed(averageCallsByTotalAttempts.first),
            averageCallAttempts: toFixed(averageCallAttempts.first)
        },
        comparisonPeriods && {
            period: labelPeriod(secondPeriod),
            countLeadsIntegrated: countLeadsIntegrated.second,
            countLeadsNew: countLeadsNew.second,
            countRecalls: countRecalls.second,
            countLeadsWasInRecall: countLeadsWasInRecall.second,
            countLeadsAccepted: countLeadsAccepted.second,
            countLeadsCancelled: countLeadsCancelled.second,
            countLeadsValidCancelReason: countLeadsValidCancelReason.second,
            countLeadsInvalidCancelReason: countLeadsInvalidCancelReason.second,
            countLeadsDouble: countLeadsDouble.second,
            countLeadsSpamByAdvertiser: countLeadsSpamByAdvertiser.second,
            countNegativeCallBackLeads: countNegativeCallBackLeads.second,
            countLeadsLzHold: countLeadsLzHold.second,
            countValidCalls: countValidCalls.second,
            averageCallTime: formatDate(averageCallTime.second),
            averageCallAttemptsByTotalAttempts: toFixed(averageCallAttemptsByTotalAttempts.second),
            averageCallsByTotalAttempts: toFixed(averageCallsByTotalAttempts.second),
            averageCallAttempts: toFixed(averageCallAttempts.second)
        },
        comparisonPeriods && {
            name: "diff",
            period: this.$t("common.statistic.trafficAnalysis.total.difference"),
            countLeadsIntegrated: difference(countLeadsIntegrated, total),
            countLeadsNew: difference(countLeadsNew, total),
            countRecalls: difference(countRecalls, total),
            countLeadsWasInRecall: difference(countLeadsWasInRecall, total),
            countLeadsAccepted: difference(countLeadsAccepted, total),
            countLeadsCancelled: difference(countLeadsCancelled, total),
            countLeadsValidCancelReason: difference(countLeadsValidCancelReason, total),
            countLeadsInvalidCancelReason: difference(countLeadsInvalidCancelReason, total),
            countLeadsDouble: difference(countLeadsDouble, total),
            countLeadsSpamByAdvertiser: difference(countLeadsSpamByAdvertiser, total),
            countNegativeCallBackLeads: difference(countNegativeCallBackLeads, total),
            countLeadsLzHold: difference(countLeadsLzHold, total),
            countValidCalls: difference(countValidCalls, total),
            averageCallTime: calculateDiff(averageCallTime),
            averageCallAttemptsByTotalAttempts: toFixed(calculateDiff(averageCallAttemptsByTotalAttempts), 2),
            averageCallsByTotalAttempts: toFixed(calculateDiff(averageCallsByTotalAttempts), 2),
            averageCallAttempts: toFixed(calculateDiff(averageCallAttempts), 2)
        }
    ].filter(Boolean);

    return { items, count: items.length };
}