export default [
  {
    path: "statistics",
    name: "statistics",
    redirect: { name: "webmaster:statistics:statistics" },
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:statistics" */ "@/views/Webmaster/StatisticView.vue"),
    children: [
      {
        path: "statistics",
        name: "statistics",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Statistic/Statistic/Statistic.vue")
      },
      {
        path: "detailing",
        name: "detailing",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:detailing" */ "@/components/Webmaster/Statistic/Detailing.vue")
      },
      {
        path: "analytic",
        name: "analytic",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:analytic" */ "@/components/Webmaster/Statistic/Analytic.vue")
      },
      {
        path: "postbacks",
        name: "postbacks",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:postbacks" */ "@/components/Webmaster/Statistic/Postback.vue")
      },
      {
        path: "analyticsTargetAudience",
        name: "analyticsTargetAudience",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:analyticsTargetAudience" */ "@/components/Common/Statistic/AnalyticTargetAudience/AnalyticTargetAudience.vue")
      },
      {
        path: "trafficAnalysis",
        name: "trafficAnalysis",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:trafficAnalysis" */ "@/components/Common/Statistic/TrafficAnalysis.vue")
      },
      {
        path: "domonetization",
        name: "domonetization",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:domonetization" */ "@/components/Webmaster/Statistic/Domonetization.vue")
      }
    ]
  }
];
