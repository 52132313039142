import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { CRMListResponse, PartialCRMListState } from "@core/store/types/admin/lists/CRMListState";
import { AxiosPromise } from "axios";
import crms from "../../admin/lists/graphql/crms.graphql";

export class CRMListService extends Service {
  public static getCRMList (
    filters: PartialCRMListState["filters"], limit: number, offset: number,
    initialCRM: PartialCRMListState["filters"] = {}): AxiosPromise<CRMListResponse> {

    const initialCRMLimit = initialCRM?.alias?.length || 0;
    const isInitialCRM = !!initialCRM?.alias;
    limit = Math.floor(initialCRMLimit / limit + 1) * limit - initialCRMLimit;
    return GraphQLServer.get("", {
      params: {
        query: crms,
        variables: {
          limit,
          offset,
          filters,
          initialCRM,
          initialCRMLimit,
          isInitialCRM
        }
      }
    });
  }
}
