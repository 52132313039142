import { GET_REQUEST_TYPES } from "@core/store/action-constants";
import { UPDATE_REQUEST_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { RequestTypesListState } from "@core/store/types/admin/crm/lists/RequestTypesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): RequestTypesListState => {
  return {
    data: {}
  };
};

const state: () => RequestTypesListState = initialState;

const getters: GetterTree<RequestTypesListState, RootState> = {};

const mutations: MutationTree<RequestTypesListState> = {
  [UPDATE_REQUEST_TYPES_LIST] (state, requestTypes: RequestTypesListState["data"]) {
    state.data = requestTypes;
  }
};

const actions: ActionTree<RequestTypesListState, RootState> = {
  [GET_REQUEST_TYPES] ({ commit }) {
    const items = [
      {
        label: "admin.crm.modal.labels.integrationDetail.form",
        value: "FORM_PARAMS"
      },
      {
        label: "admin.crm.modal.labels.integrationDetail.json",
        value: "JSON"
      },
      {
        label: "admin.crm.modal.labels.integrationDetail.emptyRequestTypes",
        value: "EMPTY"
      }
    ];
    commit(UPDATE_REQUEST_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const requestTypesList: Module<RequestTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
