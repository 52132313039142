import {
  FORCE_LOGOUT, FORCE_LOGOUT_BY_ADMIN,
  SET_EMPTY
} from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { UserLogoutState } from "@core/store/types/common/userLogout";
import { UserLogoutService } from "@core/services/common/UserLogout";
import { hasPermissions } from "@core/mixins/permissions";

class UserLogout implements Module<UserLogoutState, RootState> {
  public namespaced: boolean;
  public state: UserLogoutState;
  
  public getters: GetterTree<UserLogoutState, RootState> = {
    isForceLogout: (state, getters, rootState, rootGetters) =>
      (isBindedToCurrentAdmin: boolean) => {
        const rules = ["USERS.FORCE_LOGOUT.ALL"];
        if (isBindedToCurrentAdmin) {
          rules.push("USERS.FORCE_LOGOUT.OWN");
        }
        return hasPermissions(rules, rootGetters.permissions);
      }
  }
  
  public mutations: MutationTree<UserLogoutState> = {
  
  };
  
  public actions: ActionTree<UserLogoutState, RootState> = {
    async [FORCE_LOGOUT] () {
      await UserLogoutService.forceLogOut();
    },
    
    async [FORCE_LOGOUT_BY_ADMIN] (_, userId) {
      await UserLogoutService.forceLogOutByAdmin(userId);
    }
  };
  
  private readonly initState: () => UserLogoutState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): UserLogoutState => ({});
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    this.state = this.initState();
  
    return this;
  }
}

export const userLogout = new UserLogout();