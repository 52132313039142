import { ActionTree, GetterTree, Module as VuexModule, ModuleTree, MutationTree } from "vuex";

interface StoreModule<S, R> extends VuexModule<S, R> {
  strict?: boolean
}

export abstract class Module<S, R> {
  public namespaced?: boolean;
  public strict?: boolean;
  private _state?: S | (() => S);
  private _getters?: GetterTree<S, R> = {};
  private _mutations?: MutationTree<S> = {};
  private _modules?: ModuleTree<R> = {};
  private _actions?: ActionTree<S, R> = {};

  protected state (state: S | (() => S)) {
    if (typeof state === "function") {
      // @ts-ignore
      state = state();
    }
    this._state = Object.assign({}, this?._state, state);
  }

  protected getters (getters: GetterTree<S, R>) {
    this._getters = Object.assign({}, this?._getters, getters);
  }

  protected mutations (mutations: MutationTree<S>) {
    this._mutations = Object.assign({}, this?._mutations, mutations);
  }

  protected modules (modules: ModuleTree<R>) {
    this._modules = Object.assign({}, this?._modules, modules);
  }

  protected actions (actions: ActionTree<S, R>) {
    this._actions = Object.assign({}, this?._actions, actions);
  }

  toModule (): StoreModule<S, R> {
    return {
      strict: this.strict,
      namespaced: this.namespaced,
      state: this._state,
      getters: this._getters,
      mutations: this._mutations,
      modules: this._modules,
      actions: this._actions
    };
  }
}
