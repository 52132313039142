import Service from "@core/services/common/Service";
import requestOptionsMapper from "@core/services/admin/crm/PrepareRequestOptionsMapper";
import { CRMOptions } from "@core/store/types/admin/crm/CreateCRMState";
import { CRMListItem } from "@core/store/types/admin/crm/CRMState";
import { AxiosPromise } from "axios";
import createCrmQuery from "./graphql/createCrm.graphql";
import crm from "./graphql/crm.graphql";
import crmOptions from "./graphql/crmOptions.graphql";
import crms from "./graphql/crms.graphql";
import deleteCrmQuery from "./graphql/deleteCrm.graphql";
import editCrmQuery from "./graphql/editCrm.graphql";
import createFailRule from "./graphql/createFailRule.graphql";
import removeFailRule from "./graphql/removeFailRule.graphql";
import { CrmFailRuleOptions } from "@core/store/types/admin/crm/crmFailRule";

export class CRMService extends Service {
  /**
   * TODO: Добавить описание
   * @param {number} limit
   * @param {number} offset
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMs (
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crms,
          variables: {
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMByAlias (
    alias: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crm,
          variables: {
            alias
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async getCRMOptions (
    alias: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crmOptions,
          variables: {
            alias
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {CRMOptions} options
   * @param {boolean} isWithoutRequest
   * @return {Promise<AxiosPromise<CRMListItem>>}
   */
  public static async createCRM (
    options: CRMOptions,
    isWithoutRequest: boolean
  ): Promise<AxiosPromise<CRMListItem>> {
    const preparedOptions = requestOptionsMapper(options, isWithoutRequest);
    
    try {
      const { data: { createCrm } } = await this.api.post("", {
        query: createCrmQuery,
        variables: {
          input: {
            ...preparedOptions
          }
        }
      });
      
      return createCrm;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @param {CRMOptions} options
   * @param {boolean} isWithoutRequest
   * @return {Promise<AxiosPromise<CRMListItem>>}
   */
  public static async editCRM (
    alias: string,
    options: CRMOptions,
    isWithoutRequest: boolean
  ): Promise<AxiosPromise<CRMListItem>> {
    const preparedOptions = requestOptionsMapper(options, isWithoutRequest);
    
    try {
      const { data: { editCrm } } = await this.api.post("", {
        query: editCrmQuery,
        variables: {
          alias,
          input: {
            ...preparedOptions
          }
        }
      });
      return editCrm;
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * TODO: Добавить описание
   * @param {string} alias
   * @return {Promise<AxiosPromise>}
   */
  public static async deleteCRM (alias: string): Promise<AxiosPromise> {
    try {
      const { data: { deleteCrm } } = await this.api.post("", {
        query: deleteCrmQuery,
        variables: {
          alias
        }
      });
      return deleteCrm;
    } catch (e) {
      throw e;
    }
  }

  public static async createFailRule (input: CrmFailRuleOptions): Promise<AxiosPromise> {
    try {
      const { data: { createFailRule: data } } = await this.api.post("", {
        query: createFailRule,
        variables: {
          input
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async deleteFailRule (id: string): Promise<AxiosPromise> {
    try {
      const { data: { removeFailRule: data } } = await this.api.post("", {
        query: removeFailRule,
        variables: {
          id
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
