import {
  SET_ANALYTIC_REASON,
  UPDATE_SORTINGS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { GET_ANALYTIC_REASONS, SET_EMPTY } from "@core/store/action-constants";
import { AnalyticReasonFilters, AnalyticReasonState } from "@core/store/types/admin/analytic/analyticReasons";
import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { pagination } from "@core/helpers/pagination";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";

const initialState = (): AnalyticReasonState => {
  return {
    data: null,
    filters: {
      datepicker: datepicker({ amount: 2, unit: "day", amountEnd: 1, unitEnd: "day" }),
      threshold: 20,
      managerId: null
    },
    sort: null,
    order: null
  };
};

const state: () => AnalyticReasonState = initialState;

const mutations: MutationTree<AnalyticReasonState> = {
  [SET_EMPTY] (state, target?: string) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  [SET_ANALYTIC_REASON] (state: AnalyticReasonState, data: AnalyticReasonState["data"]): void {
    state.data = data;
  },
  
  [UPDATE_SORTINGS] (state: AnalyticReasonState, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [UPDATE_FILTERS]: (state, filters: AnalyticReasonFilters): void => {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<AnalyticReasonState, RootState> = {
  async [GET_ANALYTIC_REASONS] ({ state, commit }): Promise<void> {
    const {
      sort,
      order
    } = state;
    const { datepicker, threshold, ...otherFilters } = state.filters;
    const { limit, offset } = pagination();
    const filters = {
      ...datepickerFormat(datepicker, "date", true),
      ...otherFilters
    };
    const incidenceSummary = await AnalyticService.getAnalyticReason(limit, offset, filters, threshold, sort, order);
    
    commit(SET_ANALYTIC_REASON, incidenceSummary);
  },
  
  [UPDATE_FILTERS]: ({ commit }, filters: AnalyticReasonFilters): void => {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const analyticReasons: Module<RootState, AnalyticReasonState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {}
};
