import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function whoAmI (role: Nullable<string>): ASTGeneratedOutput {
  let include;

  if (role === "admin") {
    include = ["nextPasswordChangeDate", "showPasswordChangeNotification"];
  }

  if (role === "webmaster") {
    include = ["isShowDomonetizationStatistics", "isAcceptOfferDisclaimer"];
  }

  const params = { include };

  const gqlObject = {
    operation: "whoAmI",

    fields: [
      "login",
      "userType",
      "isConfirmed",
      "isApproved",
      "isBlocked",
      "self",
      "intId",
      "isPartnerNetwork",
      "subType",
      "preferredCurrency",
      "preferredVertical",
      "availableCurrencies"
    ],

    variables: {}
  };

  return Generator.query(gqlObject, params);
}