import Service from "@core/services/common/Service";
import { AxiosPromise, AxiosResponse } from "axios";
import { BaseFiltersInput, OfferFiltersInput, UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
// import { OffersResponse } from "@core/store/types/admin/statistic/common/offersFilters";
// import { ExternalWebmastersResponse } from "@core/store/types/admin/statistic/common/externalWebmasterFilters";
import { BunchesResponse } from "@core/store/types/admin/statistic/common/bunchesFilters";
import { FlowsResponse } from "@core/store/types/admin/statistic/common/flowsFilters";
import { WebmastersResponse } from "@core/store/types/admin/statistic/common/webmastersFilters";
import { AdvertisersResponse } from "@core/store/types/admin/statistic/common/advertisersFilters";
// import { OffersResponse } from "@core/store/types/admin/statistic/common/offersFilters";
import statisticBunches from "./statistic/graphql/statisticBunches.graphql";
import statisticFlows from "./statistic/graphql/statisticFlows.graphql";
// import statisticWebmasters from "./statistic/graphql/statisticWebmasters.graphql";
import statisticExternalWebmasters from "./statistic/graphql/statisticExternalWebmasters.graphql";
// import statisticWebmasters from "./statistic/graphql/statisticWebmasters.graphql";
import webmasters from "@core/services/common/lists/graphql/webmasters.graphql";
import advertisers from "@core/services/common/lists/graphql/advertisers.graphql";
import offers from "@core/services/common/lists/graphql/offers.graphql";
// import statisticOffers from "./statistic/graphql/statisticOffers.graphql";
// import statisticAdvertisers from "./statistic/graphql/statisticAdvertisers.graphql";

export class StatisticsService extends Service {
  public static getOffersFilters (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<AxiosPromise> {
    try {
      // TODO: Вернуть gql, когда появится фильтр по nameOrIntId для офферов
      // const initialLimit = initialFilters?.offerId?.length || 0;
      // const hasInitial = initialLimit > 0;

      // return this.api.get("", {
      //   params: {
      //     query: statisticOffers,
      //     variables: {
      //       limit,
      //       offset,
      //       filters,
      //       initialFilters,
      //       initialLimit,
      //       hasInitial
      //     }
      //   }
      // });

      const initialLimit = initialFilters?.offerId?.length || 0;
      const hasInitial = initialLimit > 0;

      const params = {
        limit: hasInitial ? initialLimit : limit,
        offset,
        filters: {
          nameOrIntId: filters?.nameOrIntId,
          id: hasInitial ? initialFilters?.offerId : null
        }
      };

      return this.rest.get("/admin/offers", { params });
    } catch (e) {
      throw e;
    }
  }

  public static getBunchesFilters (
    offset = 0,
    limit = 25,
    filters?: BaseFiltersInput,
    initialFilters?: BaseFiltersInput
  ): BunchesResponse {
    try {
      const initialLimit = initialFilters?.bunchId?.length || 0;
      const hasInitial = initialLimit > 0;

      return this.api.get("", {
        params: {
          query: statisticBunches,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getFlowsFilters (
    offset = 0,
    limit = 25,
    filters?: BaseFiltersInput,
    initialFilters?: BaseFiltersInput
  ): FlowsResponse {
    try {
      const initialLimit = initialFilters?.flowId?.length || 0;
      const hasInitial = initialLimit > 0;

      return this.api.get("", {
        params: {
          query: statisticFlows,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getExternalWebmasterFilters (
    offset = 0,
    limit = 25,
    filters?: BaseFiltersInput,
    initialFilters?: BaseFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialLimit = initialFilters?.externalWebmasterId?.length || 0;
      const hasInitial = initialLimit > 0;

      return await this.api.get("", {
        params: {
          query: statisticExternalWebmasters,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getWebmastersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialFilters?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      // const initialLimit = initialFilters?.bunchId?.length || 0;
      // const hasInitial = initialLimit > 0;

      // return this.api.get("", {
      //   params: {
      //     query: statisticWebmasters,
      //     variables: {
      //       limit,
      //       offset,
      //       filters,
      //       initialFilters,
      //       initialLimit,
      //       hasInitial
      //     }
      //   }
      // });

      const initialLimit = initialFilters?.webmasterId?.length || 0;
      const hasInitial = initialLimit > 0;

      const params = {
        limit: hasInitial ? initialLimit : limit,
        offset,
        filters: {
          loginOrIntId: filters?.loginOrIntId,
          id: hasInitial ? initialFilters?.webmasterId : null
        }
      };

      return this.rest.get("/admin/webmasters", { params });
    } catch (e) {
      throw e;
    }
  }

  public static getAnalyticWebmastersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialWebmasters?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialWebmastersLimit = initialWebmasters?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: webmasters,
          variables: {
            limit,
            offset,
            filters,
            initialWebmasters,
            initialWebmastersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getAnalyticOffersFilters (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialOffers?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialOffersLimit = initialOffers?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: offers,
          variables: {
            limit,
            offset,
            filters,
            initialOffers,
            initialOffersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getAnalyticAdvertisersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialAdvertisers?: UserFiltersInput
  ): Promise<AxiosResponse> {
    try {
      const initialAdvertisersLimit = initialAdvertisers?.id?.length || 0;
      
      return this.api.get("", {
        params: {
          query: advertisers,
          variables: {
            limit,
            offset,
            filters,
            initialAdvertisers,
            initialAdvertisersLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getAdvertisersFilters (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialFilters?: UserFiltersInput
  ): Promise<AxiosResponse<AdvertisersResponse>> {
    try {
      // const initialLimit = initialFilters?.bunchId?.length || 0;
      // const hasInitial = initialLimit > 0;

      // return this.api.get("", {
      //   params: {
      //     query: statisticAdvertisers,
      //     variables: {
      //       limit,
      //       offset,
      //       filters,
      //       initialFilters,
      //       initialLimit,
      //       hasInitial
      //     }
      //   }
      // });

      const initialLimit = initialFilters?.advertiserId?.length || 0;
      const hasInitial = initialLimit > 0;

      const params = {
        limit: hasInitial ? initialLimit : limit,
        offset,
        filters: {
          loginOrIntId: filters?.loginOrIntId,
          id: hasInitial ? initialFilters?.advertiserId : null
        }
      };

      return this.rest.get("/admin/advertisers", { params });
    } catch (e) {
      throw e;
    }
  }
}
