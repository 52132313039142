<template>
    <div class="menu">
        <AsideMenuList
            ref="menuList"
            :menu="menu"
            :is-reduce="isReduce"
            @resetReduce="$emit('resetReduce')"
            @closeAsideMenu="$emit('closeAsideMenu')">
        </AsideMenuList>
    </div>
</template>

<script>
  import dashboard from "@/assets/AsideMenu/dashboard.svg";
  import finance from "@/assets/AsideMenu/finance.svg";
  import offers from "@/assets/AsideMenu/offers.svg";
  import incidence from "@/assets/AsideMenu/incidence.svg";
  import statistics from "@/assets/AsideMenu/statistics.svg";
  import AsideMenuList from "@/components/Common/AsideMenuList";
  import { mapState } from "vuex";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  
  export default {
    name: "AsideMenu",
    components: { AsideMenuList },

    props: {
      isReduce: {
        type: Boolean,
        default: null
      }
    },

    computed: {
      ...mapState("advertiser/profile", {
        hasNutra: ({ user }) => user.systemVertical === VerticalsEnum.NUTRA,
        systemVertical: ({ user }) => user.systemVertical
      }),

      menu () {
        return [
          {
            to: { name: "dashboard" },
            label: this.$t("advertiser.menu.dashboard"),
            icon: dashboard
          },
          {
            to: { name: "advertiser:statistics" },
            label: this.$t("advertiser.menu.statistics"),
            icon: statistics
          },
          {
            to: { name: "advertiser:offers" },
            label: this.$t("advertiser.menu.offers"),
            icon: offers
          },
          {
            label: this.$t("advertiser.menu.finances.finances"),
            icon: finance,
            children: [
              {
                to: { name: "advertiser:finances:refill" },
                label: this.$t("advertiser.menu.finances.refill")
              },
              {
                to: { name: "advertiser:finances:costs" },
                label: this.$t("advertiser.menu.finances.costs")
              }
            ]
          },
          this.hasNutra && {
            to: { name: "advertiser:incidence" },
            label: this.$t("advertiser.menu.incidence"),
            icon: incidence
          }

          // {
          //   to: { name: 'advertiser:support' },
          //   label: 'Тех. поддержка',
          //   icon: 'icons/navbar/support.svg'
          // }
        ].filter(Boolean);
      }
    }
  };
</script>

<style scoped></style>
