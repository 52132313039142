import Service from "@core/services/common/Service";
import {
  LandingOperations,
  LandingOperationsGroup,
  LandingsHistoryListState,
  LandingsHistoryFiltersResponse
} from "@core/store/types/admin/statistic/landingsHistory/landingsHistory";
import landingOperations from "@core/services/admin/statistic/landingsHistory/graphql/landingOperations.graphql";
import {
  LandingHistoryDateDirection,
  LandingHistoryGroups
} from "@core/store/types/admin/statistic/landingsHistory/landingHistoryEnum";
import { UnknownObject } from "@core/store/types/common";

export class LandingsHistoryService extends Service {
  static dataMapper (data: any) {
    const { extras: { group } } = data;
    const dataSet = {
      pageCreatedDay: ["day"],
      pageCreatedWeek: ["week"],
      pageCreatedMonth: ["month"],
      default: group
    } as LandingOperationsGroup;

    data.extras.group = dataSet[group[0]] ?? dataSet.default;
    // @ts-ignore
    data.items = data.items.map(({ landingType, page, ...elements }) => ({ ...elements, pageType: landingType, page: page?.name }));

    return data;
  }

  public static async getLandingsHistory (
    filters: LandingsHistoryFiltersResponse,
    group: LandingHistoryGroups,
    dateDirection: LandingHistoryDateDirection,
    excludedFilters: UnknownObject,
    sort: LandingsHistoryListState["sort"],
    order: LandingsHistoryListState["order"],
    limit: number,
    offset: number
  ): Promise<LandingOperations> {
    try {
      let { data: { landingOperations: data } } = await this.api.get("", {
        params: {
          query: landingOperations,
          variables: {
            filters,
            group,
            dateDirection,
            excludedFilters,
            sort,
            order,
            limit,
            offset
          }
        }
      });

      data = this.dataMapper(data);
      return data;
    } catch (e) {
      throw e;
    }
  }
}