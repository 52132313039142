import { BaseFiltersInput, UserFiltersInput, FilterInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";

// * Удалить ЭТОТ ФАЙЛ и использовать общий из @core/store/modules/common/helpers/prepareFilters

export const prepareBaseFilters = (filters: any): BaseFiltersInput => {
  const {
    advertiserId,
    webmasterId,
    bunchId,
    flowId,
    offerId,
    managerId,
    countryId,
    externalWebmasterId,
    withdrawType,
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
    vertical,
    datepicker
  } = filters;

  return {
    advertiserId,
    webmasterId,
    bunchId,
    flowId,
    offerId,
    vertical,
    managerId,
    countryId,
    externalWebmasterId,
    withdrawType,
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
    ...datepicker
  };
};

export const prepareUserFilters = (filters: any, baseFilters: any): UserFiltersInput => {
  baseFilters = baseFilters ?? prepareBaseFilters(filters);
  const { webmasterId, advertiserId } = filters;

  return {
    ...baseFilters,
    webmasterId,
    advertiserId
  };  
};

export const filterFilters = (filters: FilterInput, excludeKeys: Array<keyof FilterInput> = []) => {
  // Убираем лишние фильтры (например для списка вебов не нужен фильтр по webmasterId)
  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => {
      const isExcluded = excludeKeys.includes(key as keyof FilterInput);
      return [key, isExcluded ? null : value];
    })
  );
};
