import VueIntercom from "vue-intercom";
import Vue from "vue";
import { mapState } from "vuex";
import smartlookClient from "smartlook-client";

Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_ID });

export default {
  mounted () {
    if (this.isIntercom) {
      this.$intercom.boot(this.intercomParameters);
      if (this.isUserLogin && this.isSmartlook) {
        smartlookClient.init(process.env.VUE_APP_SMARTLOOK_ID);
      }
    }
  },

  computed: {
    ...mapState({
      role: state => state.auth.role
    }),

    user () {
      return this.$store.getters.profile.user;
    },

    intercomParameters () {
      const common = {
        user_id: this.user?.id,
        intId: this.user?.intId,
        "name": this.user?.name || this.user?.login,
        "login": this.user?.login,
        email: this.user?.contact?.email,
        "telegram": this.user?.contact?.telegram,
        "role": this.role,
        "timeZone": this.user?.timeZone.offset,
        alignment: "left",
        vertical_padding: 40
      };
      const users = {
        admin: {
          ...common,
          "lastLoginAt": this.user?.activity?.lastLoginAt,
          "registeredAt": this.user?.activity?.registeredAt
        },
        webmaster: {
          ...common,
          "registeredAt": this.user?.activity?.registeredAt,
          "subType": this.user?.subType,
          "isPartnerNetwork": this.user?.isPartnerNetwork
        },
        advertiser: {
          ...common,
          "geo": this.user?.geo,
          "offersAmount": this.user?.offersAmount
        }
      };

      return this.role ? users[this.role] : common;
    },

    smartlookParameters () {
      return {
        name: this.user?.name || this.user?.login,
        intId: this.user?.intId,
        telegram: this.user?.contact?.telegram,
        email: this.user?.contact?.email
      };
    },

    isUserLogin () {
      return this.$store.getters.isUserLogin;
    },
    
    isIntercom () {
      return process.env.VUE_APP_INTERCOM_ON === "true";
    },
  
    isSmartlook () {
      return process.env.VUE_APP_SMARTLOOK_ON === "true";
    }
  },
  watch: {
    isUserLogin (value) {
      if (!value && this.isSmartlook) {
        smartlookClient.disable();
      }
    },
    user (value) {
      if (value && this.isIntercom) {
        smartlookClient.identify(this.user?.id, this.smartlookParameters);
        this.$intercom.update(this.intercomParameters);
      }
    }
  }
};
