import Service from "@core/services/common/Service";
import competitionTop from "@core/services/webmaster/competition/graphql/competitionTop.graphql";
import currentCompetition from "@core/services/webmaster/competition/graphql/currentCompetition.graphql";
import currentCompetitionAdmin from "@core/services/admin/competition/grapgql/currentCompetitionAdmin.graphql";
import competitionParticipants from "@core/services/webmaster/competition/graphql/competitionParticipants.graphql";
import { CompetitionPeriodEnum } from "@core/store/types/admin/competition/enum/competitionPeriodEnum";
import webmasterTop from "@core/services/webmaster/competition/graphql/webmasterTop.graphql";
import { AxiosPromise } from "axios";

export class CompetitionService extends Service {
  public static async getCompetitionTop (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitionTop,
          variables: { competitionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getCompetitionParticipants (
    competitionId: string,
    limit = 25,
    offset = 0): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitionParticipants,
          variables: {
            competitionId,
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getCurrentCompetition (role: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: role === "admin" ? currentCompetitionAdmin : currentCompetition
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getCompetitionsRating (period: CompetitionPeriodEnum): Promise<AxiosPromise> {
    try {
      const { data: { webmasterTop: data } } = await this.api.get("", {
        params: {
          query: webmasterTop,
          variables: {
            period
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}