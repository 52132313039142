import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { ProfileService } from "@core/services/webmaster/ProfileService";
import {
  WebmasterProfileRequisitesState
} from "@core/store/types/webmaster/profile/modules/requisites";
import {
  SET_EMPTY,
  SET_REQUISITES,
  UPDATE_REQUISITES_DELETE
} from "@core/store/mutation-constants";
import {
  GET_REQUISITES,
  REMOVE_REQUISITE
} from "@core/store/action-constants";
import { add } from "./modules/add";

const initialState = (): WebmasterProfileRequisitesState => {
  return {
    requisites: {},
    delete: {
      requisiteId: null
    }
  };
};

const state: () => WebmasterProfileRequisitesState = initialState;

const mutations: MutationTree<WebmasterProfileRequisitesState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_REQUISITES] (state, requisites) {
    state.requisites = requisites;
  },
  
  [UPDATE_REQUISITES_DELETE] (state, value) {
    state.delete = { ...state.delete, ...value };
  }
};

const actions: ActionTree<WebmasterProfileRequisitesState, RootState> = {
  async [GET_REQUISITES] ({ commit }) {
    const { data: { requisites } } = await ProfileService.getRequisites();
    commit(SET_REQUISITES, requisites);
  },

  async [REMOVE_REQUISITE] ({ state: { delete: { requisiteId } } }) {
    await ProfileService.removeRequisite(requisiteId as string);
  },

  [UPDATE_REQUISITES_DELETE] ({ commit }, payload) {
    commit(UPDATE_REQUISITES_DELETE, payload);
  }
};

export const requisites: Module<WebmasterProfileRequisitesState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    add
  }
};
