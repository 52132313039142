<template>
    <LTooltip
        ref="tooltipUser"
        :position="position"
        :active="activeTooltip"
        :delay="[100, 1000]"
        trigger="click"
        type="is-dark">
        <template #content>
            <template v-if="$slots.tooltip">
                <slot name="tooltip"></slot>
                <br>
            </template>
            {{ $t('common.components.customInput.messages.success') }}
        </template>
        <span
            :class="{ canCopy: activeTooltip }"
            @click="hideTooltipUser">
            <slot></slot>
        </span>
    </LTooltip>
</template>

<script>
  export default {
    name: "CanCopy",

    props: {
      position: {
        type: String,
        default: "is-top"
      },

      preventCopy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      activeTooltip () {
        return Boolean(this.$attrs.value) && !this.preventCopy;
      }
    },

    methods: {
      hideTooltipUser () {
        if (this.activeTooltip) {
          navigator.clipboard.writeText(`${ this.$attrs.value }`);
          setTimeout(() => {
            this.$refs.tooltipUser.$refs.tippy.tip.hide();
          }, 700);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "src/scss/theme-default";

    .canCopy {
        cursor: copy;
    }
</style>
