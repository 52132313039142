import {
  DELETE_DOMAIN,
  GET_DOMAINS
} from "@core/store/action-constants";
import { SET_DOMAINS, SET_EMPTY, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { NullableDomainsState } from "@core/store/types/common/domains/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/admin/domains/domainsService";
import { domainModal } from "@core/store/modules/admin/domains/domainModal";
import { pagination } from "@core/helpers/pagination";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";

const initialState = (): NullableDomainsState => {
  return {
    domains: null,
    filters: {
      name: null,
      sslAuthMethod: null,
      webmasterId: null,
      certificateStatus: null
    }
  };
};

const state: () => NullableDomainsState = initialState;

const mutations: MutationTree<NullableDomainsState> = {

  [SET_DOMAINS] (state, domains: NullableDomainsState["domains"]): void {
    state.domains = domains;
  },

  [UPDATE_FILTERS] (state: NullableDomainsState, filters: NullableDomainsState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state, target?: keyof NullableDomainsState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<NullableDomainsState, RootState> = {
  async [GET_DOMAINS] ({ state: { filters }, commit }): Promise<void> {
    const { limit, offset } = pagination();

    const domains = await DomainsService.getDomains(filters, limit, offset);
    
    commit(SET_DOMAINS, domains);
  },
  
  async [DELETE_DOMAIN] ({ commit }, domainId: string): Promise<void> {
    const deleteDomain = await DomainsService.deleteDomain(domainId);
    commit("LOCAL_DELETE", { items: deleteDomain, target: "domains" });
  },

  [UPDATE_FILTERS] ({ state, commit }, filters: NullableDomainsState["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const domains: Module<NullableDomainsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    domainModal
  }
};
