import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import {
  ADD_OFFER_EXTERNAL_WEBMASTERS,
  GET_OFFER_ID,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_REWARD, UPDATE_RATES, WEBMASTER_REWARD
} from "@core/store/action-constants";
import {
  UPDATE_LOADING,
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  OfferExternalWebmastersModalState,
  Rate
} from "@core/store/types/admin/offer/webmasters/OfferExternalWebmastersModalState";

const initialState = (): OfferExternalWebmastersModalState => {
  return {
    rates: [{}],
    isExternalWebmastersModalActive: false
  };
};

const state: () => OfferExternalWebmastersModalState = initialState;

const mutations: MutationTree<OfferExternalWebmastersModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isExternalWebmastersModalActive = payload;
  },

  [UPDATE_RATES] (state, rates: OfferExternalWebmastersModalState["rates"]) {
    state.rates = rates;
  },

  [UPDATE_LOADING] (state, payload) {
    state.rates = state.rates.map((rate: Rate) => {
      if (rate.externalWebmasterId === payload.externalWebmasterId) {
        return {
          ...rate,
          isLoading: payload.isLoading
        };
      }
      return rate;
    });
  },

  [UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD] (state, payload) {
    state.rates = state.rates.map((rate: Rate) => {
      if (rate.externalWebmasterId === payload.externalWebmasterId) {
        return {
          ...rate,
          maximalAllowedWebmasterReward: payload.maximalAllowedWebmasterReward
        };
      }
      return rate;
    });
  }
};

const actions: ActionTree<OfferExternalWebmastersModalState, RootState> = {
  async [ADD_OFFER_EXTERNAL_WEBMASTERS] ({ state, rootGetters, dispatch, rootState }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const webmasterId = rootState.admin.offer.edit.webmastersExternalRates.filters.webmasterId;
    try {
      if (offerId) {
        const parameters = state.rates.map(rate => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { maximalAllowedWebmasterReward, isLoading, ...parameters } = rate;
          return {
            ...parameters,
            webmasterId
          };
        });
        const {
          data: {
            addOfferWebmasterParameters
          }
        } = await OfferEditWebmastersServices.addOfferWebmasterParameters(offerId, parameters);
        dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
          {}, { root: true });

        return addOfferWebmasterParameters;
      }
    } catch (e) {
      throw e;
    }
  },

  async [WEBMASTER_REWARD] ({ commit, rootGetters }, { webmasterId, externalWebmasterId }) {
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    if (externalWebmasterId) {
      try {
        const { data: { maximalAllowedWebmasterReward } } = await OfferEditWebmastersServices.getMaximalAllowedWebmastersReward(
          offerId,
          webmasterId,
          externalWebmasterId
        );
        commit(UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD, { maximalAllowedWebmasterReward, externalWebmasterId });
      } catch (error) {
        throw error;
      }
    }
  },

  [UPDATE_RATES] ({ commit }, payload) {
    commit(UPDATE_RATES, payload);
  },

  [UPDATE_LOADING] ({ commit }, payload) {
    commit(UPDATE_LOADING, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  }
};

export const offerEditExternalWebmastersModal: Module<OfferExternalWebmastersModalState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
