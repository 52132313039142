import { PaymentsService } from "@core/services/admin/payments/PaymentsService";
import {
  ADD_PAYMENT_SYSTEM,
  GET_PAYMENT_BY_ID,
  UPDATE_EDITABLE_PAYMENTS_ID,
  UPDATE_PAYMENT_FORM,
  UPDATE_PAYMENT_FORM_MODAL,
  UPDATE_PAYMENT_SYSTEM
} from "@core/store/action-constants";
import { paymentIntegrationsList } from "@core/store/modules/admin/lists/paymentIntegrationsList";
import { UPDATE_MODAL_ACTIVE, SET_CURRENCY } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Form, PaymentModalState } from "@core/store/types/admin/payments/PaymentsModalState";
import { ActionTree, Module, MutationTree } from "vuex";
import store from "../../../../../../store";

const initialState = (): PaymentModalState => {
  return {
    form: {
      name: null,
      integrationAlias: null,
      visibility: "NONE",
      currency: "USD",
      minimalPayout: 20
    },
    editablePaymentId: null,
    isPaymentsModalActive: false
  };
};

const state: () => PaymentModalState = initialState;

const mutations: MutationTree<PaymentModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isPaymentsModalActive = payload;
  },
  
  [SET_CURRENCY] (state): void {
    state.form.currency = store.state.auth.preferredCurrency;
  },

  [UPDATE_EDITABLE_PAYMENTS_ID] (state, payload: string) {
    state.editablePaymentId = payload;
  },
  
  [UPDATE_PAYMENT_FORM] (state, form: Form) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<PaymentModalState, RootState> = {
  async [ADD_PAYMENT_SYSTEM] ({ state, commit }) {
    const addPaymentSystem = await PaymentsService.addPaymentSystem(state.form);
    commit("admin/payments/LOCAL_ADD", { items: addPaymentSystem, target: "payments" }, { root: true });
  },

  async [UPDATE_PAYMENT_SYSTEM] ({ state, commit }) {
    if (state.editablePaymentId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { currency, ...otherFields } = state.form;
      const updatePaymentSystem = await PaymentsService.updatePaymentSystem(state.editablePaymentId, otherFields);
      commit("admin/payments/LOCAL_UPDATE", { items: updatePaymentSystem, target: "payments" }, { root: true });
    }
  },

  [UPDATE_PAYMENT_FORM] ({ rootGetters, commit }, id) {
    const { name, visibility, integrator, minimalPayout } =
      JSON.parse(JSON.stringify(rootGetters[`admin/payments/${ GET_PAYMENT_BY_ID }`](id)));
    
    commit(UPDATE_EDITABLE_PAYMENTS_ID, id);
    commit(UPDATE_PAYMENT_FORM, { name, visibility, integrationAlias: integrator.alias, minimalPayout });
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [UPDATE_PAYMENT_FORM_MODAL] ({ commit }, form) {
    commit(UPDATE_PAYMENT_FORM, form);
  }
};

export const paymentsModal: Module<PaymentModalState, RootState> = {
  namespaced: true,
  modules: {
    paymentIntegrationsList
  },
  state,
  mutations,
  actions
};
