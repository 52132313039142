import { OfferWebmaster } from "@core/store/types/admin/offer/webmasters";

export default function WebmasterEditDataMapper (items: OfferWebmaster[]) {
  return items.map((item: OfferWebmaster) => {
    if (item.individualRate) {
      const isLoading: { [key: string]: unknown } = {};
      for (let key in item.individualRate) {
        if (item.individualRate.hasOwnProperty(key) && key !== "id") {
          if (key === "moneyReward") {
            key = "rate";
          }
          const prop = key.charAt(0).toUpperCase() + key.slice(1);
          isLoading[`is${ prop }Loading`] = false;
        }
      }
      return {
        webmasterId: item.webmaster.id,
        ...item,
        ...isLoading
      };
    } else {
      return { ...item, webmasterId: item.webmaster.id };
    }
  });
}
