import { UnknownObject } from "@core/store/types/common";
import _keyBy from "lodash/keyBy";
import { MutationTree } from "vuex";
import { Params, ProcessedData } from "@core/store/types/common/StateUpdater";
import Vue from "../../../../../main";

class StateUpdater {
  public mutations: MutationTree<UnknownObject> = {};
  
  constructor () {
    
    function processData ({ items, target = "data", param = "id" }: Params): ProcessedData {
      items = Array.isArray(items) ? items : [items];
      return {
        target: target,
        param: param,
        items,
        groupItems: _keyBy(items, param)
      };
    }
    
    this.mutations = {
      LOCAL_ADD (state, payload: Params) {
        const { items, target } = processData(payload);
        const updateData = () => {
          state[target] = {
            ...state[target],
            items: [...items, ...state[target].items],
            count: state[target].count + items.length,
            aggregates: state[target].aggregates
          };
          if (state.pagination?.perPage) {
            state.pagination.perPage += 1;
          }
        };

        if (state[target]) {
          if (payload?.infiniteScroll) {
            updateData();
          } else {
            if (state.pagination?.page > 1) {
              state.pagination.page = 1;
            } else if (+Vue.$route.query.page > 1) {
              Vue.$router.push({
                // @ts-ignore
                query: { ...Vue.$route.query, page: 1 }
                // eslint-disable-next-line @typescript-eslint/no-empty-function
              }).catch(_ => {});
            } else {
              updateData();
            }
          }
        }
      },
  
      LOCAL_UPDATE (state, payload: Params) {
        const { groupItems, target, param } = processData(payload);
  
        if (state[target]) {
          state[target].items = state[target].items.map((item: UnknownObject) =>
            groupItems[item[param]] ? { ...item, ...groupItems[item[param]] } : item);
        }
      },
  
      LOCAL_DELETE (state, payload: Params) {
        const { groupItems, target, param } = processData(payload);

        if (state[target]) {
          const items = state[target].items.filter((item: UnknownObject) => !groupItems[item[param]]);

          state[target] = {
            items,
            count: state[target].count - Object.keys(groupItems).length
          };
          if (state.pagination?.perPage) {
            state.pagination.perPage -= 1;
          }
        }
      }
    };
  }
}

export const stateUpdater = new StateUpdater();