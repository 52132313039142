import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import {
  GET_STATISTIC,
  GET_USER_TOTAL,
  GET_ACTIVE_COUNTS,
  GET_STATISTIC_TOP_CARD_TABLE_DATA
} from "@core/store/action-constants";
import {
  SET_STATISTIC,
  SET_USER_TOTAL,
  UPDATE_STATISTIC,
  SET_ACTIVE_COUNTS,
  UPDATE_STATISTIC_SORTING,
  UPDATE_TOP_CARD_AMOUNT_TYPE,
  UPDATE_TOP_CARD_FILTERS,
  UPDATE_PAGINATION,
  SET_CURRENCY
} from "@core/store/mutation-constants";
import { DashboardTopCardState } from "@core/store/types/admin/lists/DashboardListState";
import {
  ActionTree,
  GetterTree,
  Module, ModuleTree,
  MutationTree
} from "vuex";
import { RootState } from "@core/store/root-state";
import { capitalizeFirstLetter } from "@core/filters";
import { datepicker } from "@core/helpers/datepicker";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { fixedFormatPercent } from "@core/flowMethods";

const getPercentDifference = (newValue: Nullable<number>, oldValue: Nullable<number>): number | string | undefined => {
  if (oldValue === newValue) {
    return 0;
  } else if (oldValue === 0 || newValue === 0) {
    return 100;
  } else if (oldValue && newValue) {
    return fixedFormatPercent(Math.abs(oldValue - newValue) / oldValue);
  }
};

export class DashboardTopCard implements Module<DashboardTopCardState, RootState> {
  public namespaced: boolean;
  public state: DashboardTopCardState | undefined;
  public modules: ModuleTree<RootState>;

  public getters: GetterTree<DashboardTopCardState, RootState> = {
    [GET_STATISTIC_TOP_CARD_TABLE_DATA]: (state) => {
      return state.cardStatistic?.items.map((item) => {
        const {
          group: {
            id,
            intId,
            login,
            name,
            capacityIndicator: capacity,
            leadChanges: { after: leadsAfter, before: leadsBefore },
            moneyCommissionChanges: { after: commissionAfter, before: commissionBefore }
          },
          items
        } = item;
        
        return {
          group: { id, intId, [name ? "name" : "login"]: login ?? name },
          graph: items,
          amount: {
            leads: leadsAfter,
            commission: commissionAfter,
            capacity
          },
          change: {
            leads: {
              absolute: leadsAfter - leadsBefore,
              relative: getPercentDifference(leadsAfter, leadsBefore)
            },
            commission: {
              absolute: commissionAfter - commissionBefore,
              relative: getPercentDifference(commissionAfter, commissionBefore)
            }
          }
        };
      }) ?? [];
    }
  };
  
  public mutations: MutationTree<DashboardTopCardState> = {
    [SET_STATISTIC] (state, cardStatistic: DashboardTopCardState["cardStatistic"]): void {
      state.cardStatistic = cardStatistic;
    },
  
    [UPDATE_STATISTIC] (state, payload: DashboardTopCardState["cardStatistic"]) {
      state.cardStatistic = {
        count: payload?.count,
        // @ts-ignore
        items: [...state.cardStatistic?.items, ...payload?.items]
      };
    },
    
    [SET_ACTIVE_COUNTS] (state, payload): void {
      state.activeToday = payload.activeToday;
      state.activeYesterday = payload.activeYesterday;
    },
    
    [UPDATE_TOP_CARD_AMOUNT_TYPE] (state, payload): void {
      state.amountType = payload.amountType;
    },
    
    [UPDATE_TOP_CARD_FILTERS] (state, filters: DashboardTopCardState["filters"]): void {
      state.filters = { ...state.filters, ...filters };
    },
    
    [SET_USER_TOTAL] (state, userTotal: DashboardTopCardState["userTotal"]): void {
      state.userTotal = userTotal;
    },
    
    [UPDATE_STATISTIC_SORTING] (state, { sort, order }): void {
      state.sort = sort;
      state.order = order.toUpperCase();
    },
    
    [UPDATE_PAGINATION] (state, pagination: DashboardTopCardState["pagination"]): void {
      state.pagination = { ...state.pagination, ...pagination };
    }
  };
  
  public actions: ActionTree<DashboardTopCardState, RootState> = {
    async [GET_STATISTIC] ({ commit, state, rootState: { verticals: { currentVertical: vertical } } }): Promise<void> {
      const {
        pagination: { perPage: limit, page },
        filters: { periodType, managerId, ...filters },
        groupType,
        sort,
        order
      } = state;
      const offset = (page - 1) * limit;

      const { data } = await StatisticsService.getDashboardSummaryUser(
        limit,
        offset,
        // @ts-ignore
        { managerId, ...filters, vertical },
        periodType,
        groupType,
        sort,
        order
      );
  
      const userKey = {
        advertiser: "dashboardSummaryAdvertiser",
        webmaster: "dashboardSummaryWebmaster",
        offer: "dashboardSummaryOffer"
      }[groupType];
  
      commit(offset === 0 ? SET_STATISTIC : UPDATE_STATISTIC, data[userKey]);
    },
    
    async [GET_ACTIVE_COUNTS] ({ commit, state, rootState, rootState: { verticals: { currentVertical: vertical } } }): Promise<void> {
      const { dashboard: { dashboardStatisticWidget: { dashboardSummaryClientsWidget } } } = rootState.admin;
      const { excludeFilters, groupType } = state;
      const groups = groupType.toUpperCase();
      const filters = {
        today: { ...datepicker(), vertical },
        yesterday: { ...datepicker({ amount: 1, unit: "day", amountEnd: 1, unitEnd: "day" }), vertical }
      };
      let activeCounts;

      if (dashboardSummaryClientsWidget) {
        const active = (type: string) => dashboardSummaryClientsWidget[`${ type }Active${ capitalizeFirstLetter(groupType) }s`];
        activeCounts = { activeToday: active("thirtyDays"), activeYesterday: active("previousThirtyDate") };
      } else {
        // @ts-ignore
        const active = (type: string) => StatisticsService.getAdminStatisticCompare(filters[type], excludeFilters, [groups]);

        const [today, yesterday] = await Promise.all([active("today"), active("yesterday")]);
        activeCounts = {
          activeToday: today.data.statistics.items?.length,
          activeYesterday: yesterday.data.statistics.items?.length
        };
      }

      commit(SET_ACTIVE_COUNTS, activeCounts);
    },
    
    async [GET_USER_TOTAL] ({ state: { groupType }, commit, rootState }): Promise<void> {
      const { dashboard: { dashboardStatisticWidget: { dashboardSummaryClientsWidget } } } = rootState.admin;
      let total;

      if (dashboardSummaryClientsWidget) {
        total = dashboardSummaryClientsWidget[`total${ capitalizeFirstLetter(groupType) }s`];
      } else {
        const { data } = await StatisticsService.getDashboardUserTotal(groupType);
        total = data[`${ groupType }s`].count;
      }

      commit(SET_USER_TOTAL, total);
    },
  
    [SET_CURRENCY] ({ commit, rootState }): void {
      const currency = rootState.admin.profile.user.preferredCurrency;
      commit(UPDATE_TOP_CARD_FILTERS, { currency });
    },
  
    [UPDATE_STATISTIC_SORTING] ({ commit }, payload): void {
      commit(UPDATE_STATISTIC_SORTING, payload);
    },
    
    [UPDATE_TOP_CARD_AMOUNT_TYPE] ({ commit }, amountType: DashboardTopCardState["amountType"]): void {
      commit(UPDATE_TOP_CARD_AMOUNT_TYPE, amountType);
    },
    
    [UPDATE_TOP_CARD_FILTERS] ({ commit }, filters: DashboardTopCardState["filters"]): void {
      commit(UPDATE_TOP_CARD_FILTERS, filters);
    },
    
    [UPDATE_PAGINATION] ({ commit }, pagination: DashboardTopCardState["pagination"]): void {
      commit(UPDATE_PAGINATION, pagination);
    }
  };
  
  private readonly initState: () => DashboardTopCardState;
  
  constructor (type: DashboardTopCardState["groupType"], modules: ModuleTree<RootState>) {

    this.namespaced = true;
    this.initState = (): DashboardTopCardState => {
      return {
        groupType: type,
        cardStatistic: null,
        activeToday: null,
        activeYesterday: null,
        amountType: "leads",
        userTotal: null,
        pagination: {
          page: 1,
          perPage: 15
        },
        filters: {
          managerId: null,
          currency: null,
          periodType: "TODAY",
          vertical: VerticalsEnum.NUTRA
        },
        sort: "countLeadsIntegrated",
        order: "DESC"
      };
    };
    this.state = this.initState();
    
    this.mutations.SET_EMPTY = (state): DashboardTopCardState => Object.assign(state, this.initState());

    this.modules = modules;
    
    return this;
  }
}
