import { GET_FORMAT_FILE } from "@core/store/action-constants";
import { UPDATE_FORMAT_FILE_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FormatFileListState } from "@core/store/types/admin/uploads/lists/FormatFileListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): FormatFileListState => {
  return {
    data: {}
  };
};

const state: () => FormatFileListState = initialState;

const getters: GetterTree<FormatFileListState, RootState> = {};

const mutations: MutationTree<FormatFileListState> = {
  [UPDATE_FORMAT_FILE_LIST] (state, formatFile: FormatFileListState["data"]) {
    state.data = formatFile;
  }
};

const actions: ActionTree<FormatFileListState, RootState> = {
  [GET_FORMAT_FILE] ({ commit }) {
    const items = [
      {
        label: "XLSX",
        value: "XLSX"
      },
      {
        label: "CSV",
        value: "CSV"
      }
    ];
    commit(UPDATE_FORMAT_FILE_LIST, {
      items,
      count: items.length
    });
  }
};

export const formatFileList: Module<FormatFileListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
