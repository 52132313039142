import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import badgesList from "@core/services/common/badges/datasets/badgesList.json";
import { BadgesList, BadgesListFilters } from "@core/store/types/common/badges";
import { replaceOfferParameterRequest } from "@core/services/common/badges/datasets/replaceOfferParameterRequest";
import { BadgesEnum } from "@core/store/types/common/enums/badgesEnum";

export default function badges (
  badgesOperations: string[]
): ASTGeneratedOutput {
  const params = {
    include: []
  };
  
  const gqlFields = {
    fields: [
      // TODO: Удалить items, когда станут необязательны для отправки
      {
        items: ["id"]
      },
      "count"
    ]
  };
  
  /*
  *
  * В badgesList описываются все динамические поля
  * key - имя операции
  * name - уникальное имя фильтра
  * type - уникальный тип фильтра
  * value - дополнительные поля, то что передаём из фильтров
  *
  * */
  
  const gqlArray = badgesOperations.map((operation) => {
    let { type, value } = badgesList[operation as keyof BadgesListFilters] as BadgesList;
    
    if (operation === BadgesEnum.OFFER_PARAMETER_REQUESTS) {
      value = replaceOfferParameterRequest(value);
    }
    
    return {
      operation,
      ...gqlFields,
      variables: {
        [type]: {
          name: "filters",
          type,
          value
        }
      }
    };
  });
  
  return Generator.query(gqlArray, params);
}

