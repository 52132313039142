import { EnumValues } from "@core/services/common/EnumValues";
import { GlobalSourceState } from "@core/store/types/common/statistic/common/globalSource";
import { GET_GLOBAL_SOURCE } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_GLOBAL_SOURCE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): GlobalSourceState => {
  return {
    data: {}
  };
};

const state: () => GlobalSourceState = initialState;

const getters: GetterTree<GlobalSourceState, RootState> = {};

const mutations: MutationTree<GlobalSourceState> = {
  [UPDATE_GLOBAL_SOURCE] (state, statuses: GlobalSourceState["data"]) {
    state.data = statuses;
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<GlobalSourceState, RootState> = {
  async [GET_GLOBAL_SOURCE] ({ commit }) {
    const { data: { enumValues } } = await EnumValues.getEnumValues("GlobalSourceEnum");

    commit(UPDATE_GLOBAL_SOURCE, {
      items: enumValues,
      count: enumValues.length
    });
  },
  
  [SET_EMPTY]: ({ commit }) => {
    commit(SET_EMPTY);
  }
};

export const globalSourceFilters: Module<GlobalSourceState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
