import { AnalyticFiltersRequest } from "@core/store/types/admin/analytic/analytic";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";

export default function analyticGenerator (
  limit: number,
  offset: number,
  filters: AnalyticFiltersRequest,
  mainGroup: string,
  additionalGroup: string
): ASTGeneratedOutput {
  const { isShowCommissions } = useOffers();

  const params = {
    exclude: !isShowCommissions ? [
      "items/items/webmasterRateCurrent",
      "items/items/webmasterRateOld"
    ] : []
  };

  const gqlObject = {
    operation: "analytic",

    fields: [
      {
        items: [
          "group",
          {
            items: [
              "day",
              "week",
              "month",
              "year",
              "conversionApprove",
              "conversionCleanApprove",
              "conversionRedemption",
              "countLeadsAccepted",
              "countLeadsCreated",
              "countLeadsSpammed",
              "countLeadsIntegrated",
              "countLeadsCancelled",
              "countLeadsValidAccepted",
              "countLeadsProcessing",
              "countLeadsFilteredSpam",
              "countLeadsSpamByAdvertiser",
              "countLeadsRedemption",
              "capacityIndicator",
              "isWebmasterRateChanged",
              "webmasterRateCurrent",
              "webmasterRateOld",
              "guaranteeRate"
            ]
          },
          {
            total: [
              "conversionApprove",
              "conversionCleanApprove",
              "conversionRedemption",
              "countLeadsAccepted",
              "countLeadsCreated",
              "countLeadsSpammed",
              "countLeadsIntegrated",
              "countLeadsCancelled",
              "countLeadsValidAccepted",
              "countLeadsProcessing",
              "countLeadsFilteredSpam",
              "countLeadsSpamByAdvertiser",
              "countLeadsRedemption",
              "capacityIndicator",
              "guaranteeRate"
            ]
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],

    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      filters: {
        type: "AnalyticCriteriaFilters",
        value: filters
      },
      mainGroup: {
        type: "AnalyticMainGroupsEnum!",
        value: mainGroup
      },
      additionalGroup: {
        type: "AnalyticAdditionalGroupsEnum!",
        value: additionalGroup
      }
    }
  };

  return Generator.query(gqlObject, params);
}