import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  DashboardStatisticChartWidget
} from "@core/store/types/admin/dashboard/dashboardStatisticWidget";
import {
  SET_DASHBOARD_STATISTICS_CHART_WIDGET,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_DASHBOARD_STATISTICS_CHART_WIDGET,
  SET_EMPTY
} from "@core/store/action-constants";
import { DashboardStatisticWidgetService } from "@core/services/admin/dashboard/dashboardStatisticWidgetService";
import { IndicatorEnum } from "@core/store/types/admin/dashboard/enums/indicatorEnum";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): DashboardStatisticChartWidget => {
  return {
    statisticsChartWidget: null,
    filters: {
      datepicker: datepicker({ unit: "week", amount: 2 }, "dateTime", {}),
      indicator: IndicatorEnum.LEADS_VALID_ACCEPTED,
      currency: CurrencyEnum.USD
    }
  };
};

const state: () => DashboardStatisticChartWidget = initialState;

const mutations: MutationTree<DashboardStatisticChartWidget> = {
  [SET_DASHBOARD_STATISTICS_CHART_WIDGET] (state, statisticsChartWidget: DashboardStatisticChartWidget["statisticsChartWidget"]): void {
    state.statisticsChartWidget = statisticsChartWidget;
  },

  [UPDATE_FILTERS] (state, filters: DashboardStatisticChartWidget["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state, target?: DashboardStatisticChartWidget): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DashboardStatisticChartWidget, RootState> = {
  async [GET_DASHBOARD_STATISTICS_CHART_WIDGET] ({ state, commit }): Promise<void> {
    const { filters: { datepicker, indicator, currency } } = state;
    const filters = { ...datepicker };

    const data = await DashboardStatisticWidgetService.getStatisticsChartWidget(indicator, currency, filters);

    commit(SET_DASHBOARD_STATISTICS_CHART_WIDGET, data);
  },

  [UPDATE_FILTERS] ({ commit }, filters: DashboardStatisticChartWidget["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const dashboardStatisticChartWidget: Module<DashboardStatisticChartWidget, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
