import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { StatusesListState } from "@core/store/types/admin/lists/StatusesListState";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY, GET_STATUSES } from "@core/store/action-constants";
import { UPDATE_STATUSES_LIST } from "@core/store/mutation-constants";

const initialState = (): StatusesListState => {
  return {
    data: {}
  };
};

const state: () => StatusesListState = initialState;

const getters: GetterTree<StatusesListState, RootState> = {};

const mutations: MutationTree<StatusesListState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_STATUSES_LIST] (state, statuses: StatusesListState["data"]) {
    state.data = statuses;
  }
};

const actions: ActionTree<StatusesListState, RootState> = {
  [GET_STATUSES] ({ commit }) {
    const items = [
      {
        value: "CREATED",
        label: "admin.finances.costs.table.values.status.created"
      },
      {
        value: "AUTO_PROCESSING",
        label: "admin.finances.costs.table.values.status.auto_processing"
      },
      {
        value: "HAND_PROCESSING",
        label: "admin.finances.costs.table.values.status.hand_processing"
      },
      {
        value: "PROCESSED",
        label: "admin.finances.costs.table.values.status.processed"
      },
      {
        value: "REJECTED",
        label: "admin.finances.costs.table.values.status.rejected"
      }
    ];
    commit(UPDATE_STATUSES_LIST, {
      items,
      count: items.length
    });
  }
};

export const statusesList: Module<StatusesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
