import { formatCurrency, formatEmptyString, moment, toFixed } from "@core/filters";
import { fixedCurrency, momentWithEmpty, currencyAvailableToSort } from "@core/flowMethods";

// @vue/components
export const Table = {
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    
    isLoading: {
      type: Boolean,
      default: false
    },
    
    pageSizes: {
      type: Array,
      default: () => [25, 50, 100]
    },
    
    perPage: {
      type: Number,
      default: 0
    },
  
    showHeader: {
      type: Boolean,
      default: true
    },
    
    currentPage: {
      type: Number,
      default: 1
    },
    
    total: {
        type: Number,
        default: null
    },
    
    getSummaries: {
        type: Function,
        default: () => {}
    },
    
    showSummaries: {
        type: Boolean,
        default: false
    },
  
    pageInput: {
      type: Boolean,
      default: true
    },
  
    namespaceStorage: {
      type: String,
      default: null
    }
  },
  
  computed: {
    isPaginated () {
      return this.data && !isNaN(this.data.count) ? this.data.count > this.perPage : false;
    }
  },
  
  methods: {
    moment,
    
    toFixed,
    
    formatEmptyString,
    
    formatCurrency,
    
    fixedCurrency,
  
    momentWithEmpty,
    
    currencyAvailableToSort,
    
    updateSortings (value, order) {
      if (value && order) {
        this.$emit("update:sort", {
          value,
          order
        });
      }
    },
    
    updateCurrentPage (value) {
        this.$emit("update:currentPage", value);
    },
    
    emitUpdate () {
      this.$emit("update");
    },
    
    updatePerPage (value) {
        this.$emit("update:perPage", value);
    }
  }
};
