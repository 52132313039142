import { render, staticRenderFns } from "./URLNotFound.vue?vue&type=template&id=56b64271&scoped=true&functional=true"
import script from "./URLNotFound.vue?vue&type=script&lang=js"
export * from "./URLNotFound.vue?vue&type=script&lang=js"
import style0 from "./URLNotFound.vue?vue&type=style&index=0&id=56b64271&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "56b64271",
  null
  
)

export default component.exports