import Service from "@core/services/common/Service";
import {
  TrafficRequestAcceptRecommendation,
  TrafficRequestAnalyseData,
  TrafficRequestChatInput,
  TrafficRequestChatItem,
  TrafficRequestContextItems,
  TrafficRequestCreateModalInput,
  TrafficRequestFilters,
  TrafficRequestHistoryItems, TrafficRequestSort,
  TrafficRequestUnificationData,
  TrafficRequestUnificationDeleted,
  TrafficRequestUnificationItem
} from "@core/store/types/admin/traffic/trafficRequest";
import trafficRequestsQuery from "@core/services/admin/traffic/graphql/trafficRequests.graphql";
import trafficRequestsChatQuery from "@core/services/admin/traffic/graphql/trafficRequestsChat.graphql";
import {
  trafficRequestReplaceItem,
  trafficRequestSetStatus,
  trafficRequestsMapper
} from "@core/services/admin/traffic/trafficRequestsMapper";
import deleteTrafficRequestMutation from "@core/services/admin/traffic/graphql/deleteTrafficRequest.graphql";
import acceptRecommendationTrafficRequestMutation from "@core/services/admin/traffic/graphql/acceptRecommendationTrafficRequest.graphql";
import rejectRecommendationTrafficRequestMutation from "@core/services/admin/traffic/graphql/rejectRecommendationTrafficRequest.graphql";
import applyNewTermsTrafficRequestMutation from "@core/services/admin/traffic/graphql/applyNewTermsTrafficRequest.graphql";
import createTrafficRequestMutation from "@core/services/admin/traffic/graphql/createTrafficRequest.graphql";
import editTrafficRequestMutation from "@core/services/admin/traffic/graphql/editTrafficRequest.graphql";
import trafficRequestsHistoryQuery from "@core/services/admin/traffic/graphql/trafficRequestsHistory.graphql";
import trafficRequestsContextQuery from "@core/services/admin/traffic/graphql/trafficRequestsContext.graphql";
import trafficRequestsAnalyseChart from "@core/services/admin/traffic/graphql/trafficRequestCapacityAnalyse.generator";
import addMessageToTrafficRequestChatMutation from "@core/services/admin/traffic/graphql/addMessageToTrafficRequestChat.graphql";
import { CapacityAnalyseFiltersResponse } from "@core/store/types/admin/offers/capacity";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";

export class TrafficService extends Service {
  public static async getTrafficRequest (
    filters: Partial<Omit<TrafficRequestFilters, "datepicker">>,
    excludedFilters: Partial<Omit<TrafficRequestFilters, "datepicker">>,
    limit: number,
    offset: number,
    sort: Nullable<TrafficRequestSort>,
    order: Nullable<OrderEnum>
  ): Promise<TrafficRequestUnificationData> {
    try {
      const { data: { trafficRequests } } = await this.api.get("", {
        params: {
          query: trafficRequestsQuery,
          variables: {
            filters,
            excludedFilters,
            limit,
            offset,
            sort,
            order
          }
        }
      });
      return trafficRequestsMapper(trafficRequests);
    } catch (e) {
      throw e;
    }
  }

  public static async getTrafficRequestChat (filters: Partial<Omit<TrafficRequestFilters, "datepicker">>): Promise<TrafficRequestChatItem[]> {
    try {
      const { data: { trafficRequests: { items: [item] } } } = await this.api.get("", {
        params: {
          query: trafficRequestsChatQuery,
          variables: {
            filters,
            limit: 1,
            offset: 0
          }
        }
      });
      return item.chat;
    } catch (e) {
      throw e;
    }
  }

  public static async deleteTrafficRequest (id: string): Promise<[TrafficRequestUnificationDeleted]> {
    try {
      const { data: { deleteTrafficRequest } } = await this.api.post("", {
        query: deleteTrafficRequestMutation,
        variables: {
          id
        }
      });
      return [trafficRequestSetStatus(deleteTrafficRequest)];
    } catch (e) {
      throw e;
    }
  }

  public static async acceptRecommendationTrafficRequest (id: string): Promise<[TrafficRequestAcceptRecommendation]> {
    try {
      const { data: { acceptRecommendationTrafficRequest } } = await this.api.post("", {
        query: acceptRecommendationTrafficRequestMutation,
        variables: {
          id
        }
      });
      return [acceptRecommendationTrafficRequest];
    } catch (e) {
      throw e;
    }
  }

  public static async rejectRecommendationTrafficRequest (id: string): Promise<[TrafficRequestAcceptRecommendation]> {
    try {
      const { data: { rejectRecommendationTrafficRequest } } = await this.api.post("", {
        query: rejectRecommendationTrafficRequestMutation,
        variables: {
          id
        }
      });
      return [rejectRecommendationTrafficRequest];
    } catch (e) {
      throw e;
    }
  }

  public static async applyNewTermsTrafficRequest (id: string): Promise<[TrafficRequestAcceptRecommendation]> {
    try {
      const { data: { applyNewTermsTrafficRequest } } = await this.api.post("", {
        query: applyNewTermsTrafficRequestMutation,
        variables: {
          id
        }
      });
      return [trafficRequestSetStatus(applyNewTermsTrafficRequest)];
    } catch (e) {
      throw e;
    }
  }

  public static async createTrafficRequest (input: Omit<TrafficRequestCreateModalInput, "recommendation" | "newTerms" | "growthTerms">): Promise<TrafficRequestUnificationItem> {
    try {
      const { data: { createTrafficRequest } } = await this.api.post("", {
        query: createTrafficRequestMutation,
        variables: {
          input
        }
      });
      return trafficRequestReplaceItem(createTrafficRequest);
    } catch (e) {
      throw e;
    }
  }

  public static async editTrafficRequest (
    id: string,
    input: Partial<Pick<TrafficRequestCreateModalInput, "recommendation" | "newTerms" | "growthTerms">>
  ): Promise<TrafficRequestUnificationItem> {
    try {
      const { data: { editTrafficRequest } } = await this.api.post("", {
        query: editTrafficRequestMutation,
        variables: {
          id,
          input
        }
      });
      return trafficRequestReplaceItem(editTrafficRequest);
    } catch (e) {
      throw e;
    }
  }

  public static async getHistoryRequests (
    filters: { offerId: string; webmasterId: string; },
    limit: number,
    offset: number
  ): Promise<TrafficRequestHistoryItems> {
    try {
      const { data: { trafficRequests } } = await this.api.get("", {
        params: {
          query: trafficRequestsHistoryQuery,
          variables: {
            filters,
            limit,
            offset
          }
        }
      });
      return trafficRequests.items.length ? trafficRequests : {};
    } catch (e) {
      throw e;
    }
  }

  public static async getContextRequests (
    offerId: string,
    webmasterId: string
  ): Promise<TrafficRequestContextItems> {
    try {
      const { data: { trafficRequestCreateContext } } = await this.api.get("", {
        params: {
          query: trafficRequestsContextQuery,
          variables: {
            offerId,
            webmasterId
          }
        }
      });
      return trafficRequestCreateContext;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAnalyseChart (
    offerId: string,
    webmasterId: string,
    filters: CapacityAnalyseFiltersResponse,
    isShowRedemption: boolean
  ): Promise<TrafficRequestAnalyseData> {
    try {
      const { data: { capacityGraphByWebmaster } } = await this.api.get("", {
        params: trafficRequestsAnalyseChart(
          offerId,
          webmasterId,
          filters,
          isShowRedemption
        )
      });
      return capacityGraphByWebmaster;
    } catch (e) {
      throw e;
    }
  }

  public static async addNewMessageToChat (
    requestId: string,
    input: TrafficRequestChatInput
  ): Promise<TrafficRequestChatItem> {
    try {
      const { data: { addMessageToTrafficRequestChat } } = await this.api.post("", {
        query: addMessageToTrafficRequestChatMutation,
        variables: {
          requestId,
          input
        }
      });
      return addMessageToTrafficRequestChat;
    } catch (e) {
      throw e;
    }
  }
}