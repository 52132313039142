import finances from "@/router/advertiser/finance";
import offers from "@/router/advertiser/offers";
import statistic from "@/router/advertiser/statistic";
import incidence from "@/router/advertiser/incidence";
import { RouteConfig } from "vue-router/types/router";

import Full from "@/views/Full.vue";

export const advertiser: RouteConfig = {
  path: "/advertiser",
  name: "advertiser",
  component: Full,
  children: [
    ...offers,
    ...finances,
    ...incidence,
    ...statistic
  ]
};
