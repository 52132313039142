import Buefy from "buefy";
import Vue from "vue";

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultFirstDayOfWeek: 1,
  defaultProgrammaticPromise: true,
  customIconPacks: {
    "uil": {
      iconPrefix: "uil-",
      sizes: {
        "default": "is-size-6",
        "is-small": "is-size-5",
        "is-medium": "is-size-4",
        "is-large": "is-size-3"
      },
      internalIcons: {
        "eye-off": "eye-slash"
      }
    }
  }
});
