import { FinancesCostsService } from "@core/services/advertiser/finances/costs/FinancesCostsService";
import { GET_WRITE_OFFS_BY_LEADS_GROUPED, SET_EMPTY } from "@core/store/action-constants";
import { SET_COSTS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WriteOffsByLeadsGroupedState } from "@core/store/types/advertiser/finances/writeOffsByLeadsGroupedState";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): WriteOffsByLeadsGroupedState => ({
  data: null
});

const state = initialState;

const mutations: MutationTree<WriteOffsByLeadsGroupedState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_COSTS]: (state, costs): void => {
    state.data = costs;
  }
};

const actions: ActionTree<WriteOffsByLeadsGroupedState, RootState> = {
  async [GET_WRITE_OFFS_BY_LEADS_GROUPED] ({ commit }, { pagination, group, filters: { currency, ...filters } }) {
    const { offset, limit } = pagination;

    const { data: { writeOffsByLeadsGrouped } } = await FinancesCostsService.getCostsByLeadsGrouped(
      limit,
      offset,
      filters,
      group.toUpperCase(),
      currency
    );
    commit(SET_COSTS, writeOffsByLeadsGrouped);
  }
};

export const writeOffsByLeadsGrouped: Module<WriteOffsByLeadsGroupedState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
