import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { ProfileService } from "@core/services/webmaster/ProfileService";
import {
  WebmasterProfileTokensState
} from "@core/store/types/webmaster/profile/modules/tokens";
import {
  SET_EMPTY,
  SET_API_TOKENS
} from "@core/store/mutation-constants";
import {
  GET_API_TOKENS
} from "@core/store/action-constants";

const initialState = (): WebmasterProfileTokensState => {
  return {
    tokens: {
      items: [],
      count: 0
    }
  };
};

const state: () => WebmasterProfileTokensState = initialState;

const mutations: MutationTree<WebmasterProfileTokensState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [SET_API_TOKENS] (state, tokens) {
    state.tokens = tokens;
  }
};

const actions: ActionTree<WebmasterProfileTokensState, RootState> = {
  async [GET_API_TOKENS] ({ commit }) {
    const { data: { tokens } } = await ProfileService.getTokens();
    commit(SET_API_TOKENS, tokens);
  }
};

export const tokens: Module<WebmasterProfileTokensState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
