import { AbstractOffer } from "@core/store/logic/common/offer/AbstractOffer";
import { OfferResponseInterface } from "@core/store/types/common/offer/offer";
import { Money } from "@core/store/types/common";

export class EditableOffer extends AbstractOffer {
  public minimalCommission?: Money["value"];
  public currency?: Money["currency"];
  public webmasterReward?: Money["value"];
  public totalSoftCapacity?: number;
  public isApiAllowed?: boolean;
  public isExclusive?: boolean;
  public isResale?: boolean;
  public isTest?: boolean;
  public advertiserMinimalCommission?: Money;
  public slug?: string;

  constructor (payload?: OfferResponseInterface) {
    super(payload);
    if (payload) {
      this.landingParameters = payload.landingParameters ?? ["name", "phone"];
      this.categories = payload.categories?.map(type => type.id) as OfferResponseInterface["categories"];
      this.trafficTypes = payload.trafficTypes?.map(type => type.id) as OfferResponseInterface["trafficTypes"];
      this.countries = payload.countries?.map(type => type.id) as OfferResponseInterface["countries"];
      this.minimalCommission = payload.minimalCommission?.value;
      this.currency = payload.finance?.currency;
      this.slug = payload.slug;
      this.isApiAllowed = payload.isApiAllowed;
      this.isTest = payload.labels?.includes("TEST") || false;
      this.isExclusive = payload.labels?.includes("EXCLUSIVE") || false;
      this.isResale = payload.labels?.includes("RESALE") || false;
      this.webmasterReward = payload.finance?.webmasterReward?.value;
      this.totalSoftCapacity = payload.totalSoftCapacity;
      this.totalTimeHold = payload.finance?.holdTime?.holdMinutes ?? 15;
      this.advertiserMinimalCommission = payload.advertiserMinimalCommission;
    }
  }
}

