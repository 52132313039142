import {
  AnalyticDetailModalChart,
  AnalyticDetailModalFilters,
  AnalyticDetailModalState
} from "@core/store/types/admin/analytic/AnalyticDetailModal";
import {
  SET_ANALYTIC_GROUP_FILTERS,
  SET_ANALYTIC_GROUP_FIELDS,
  SET_MODAL_ACTIVE,
  UPDATE_FILTERS,
  SET_ANALYTIC
} from "@core/store/mutation-constants";
import { FiltersSubAnalytic, GroupFields } from "@core/store/types/admin/analytic/analytic";
import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  UPDATE_ANALYTIC_GROUP_FILTERS,
  UPDATE_ANALYTIC_GROUP_FIELDS,
  UPDATE_MODAL_ACTIVE,
  GET_ANALYTIC,
  SET_EMPTY
} from "@core/store/action-constants";
import moment from "moment";
import { momentInst } from "@core/filters";

const initialState = (): AnalyticDetailModalState => {
  return {
    data: null,
    groupFields: null,
    groupFilters: null,
    filters: {
      datepicker: {
        dateStart: moment().startOf("day").subtract(1, "weeks").toDate(),
        dateEnd: moment().endOf("day").toDate()
      }
    },
    isModalActive: false
  };
};

const state: () => AnalyticDetailModalState = initialState;

const mutations: MutationTree<AnalyticDetailModalState> = {
  [SET_EMPTY] (state: AnalyticDetailModalState): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { filters, ...emptyFields } = initialState();
    Object.assign(state, emptyFields);
  },
  
  [SET_MODAL_ACTIVE] (state: AnalyticDetailModalState, isModalActive: boolean): void {
    state.isModalActive = isModalActive;
  },
  
  [SET_ANALYTIC] (state: AnalyticDetailModalState, data: AnalyticDetailModalChart): void {
    state.data = data;
  },
  
  [SET_ANALYTIC_GROUP_FILTERS] (state: AnalyticDetailModalState, filters: FiltersSubAnalytic): void {
    state.groupFilters = filters;
  },
  
  [SET_ANALYTIC_GROUP_FIELDS] (state: AnalyticDetailModalState, groupFields: GroupFields): void {
    state.groupFields = groupFields;
  },
  
  [UPDATE_FILTERS] (state, filters: AnalyticDetailModalFilters): void {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<AnalyticDetailModalState, RootState> = {
  async [GET_ANALYTIC] ({ commit, state, rootState }): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { datepicker: { dateStart, dateEnd }, groups, currency, threshold, registeredDatepicker, ...analyticFilters } = rootState.admin.analytic.filters;

    const filters = {
      ...analyticFilters,
      ...state.groupFilters,
      ...registeredDatepicker,
      dateStart:momentInst(dateStart).format("YYYY-MM-DDZ"),
      dateEnd: momentInst(dateEnd).format("YYYY-MM-DDZ"),
      currency: currency ?? rootState.auth.preferredCurrency
    };
    const { data: { analytic } } = await AnalyticService.getDetailAnalytic(filters, groups[0], groups[1]);

    const items = analytic.items?.[0]?.items;
    const data = { items, count: analytic.count };
    
    commit(SET_ANALYTIC, data);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_ANALYTIC_GROUP_FILTERS] ({ commit }, payload: FiltersSubAnalytic): void {
    commit(SET_ANALYTIC_GROUP_FILTERS, payload);
  },
  
  [UPDATE_ANALYTIC_GROUP_FIELDS] ({ commit }, payload: GroupFields): void {
    commit(SET_ANALYTIC_GROUP_FIELDS, payload);
  },
  
  [UPDATE_FILTERS] ({ commit }, payload: AnalyticDetailModalFilters): void {
    commit(UPDATE_FILTERS, payload);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const analyticDetailModal: Module<RootState, AnalyticDetailModalState> = {
  namespaced: true,
  state,
  actions,
  mutations
};