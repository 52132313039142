import Service from "@core/services/common/Service";
import availableDomonetizations from "@core/services/webmaster/domonetization/graphql/availableDomonetizations.graphql";
import { DomonetizationsState } from "@core/store/modules/webmaster/flows/modules/availableDomonetizations";

export class DomonetizationService extends Service {
  public static async getAvailableDomonetization (offerId: string): Promise<DomonetizationsState> {
    try {
      return await this.api.get("", {
        params: {
          query: availableDomonetizations,
          variables: {
            offerId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}