import { OfferAdvertisersListService } from "@core/services/admin/lists/OfferAdvertisersListService";
import { GET_OFFER_ADVERTISERS_LIST } from "@core/store/action-constants";
import {
  UPDATE_OFFER_ADVERTISERS,
  UPDATE_OFFER_ADVERTISERS_FILTERS,
  UPDATE_OFFER_ADVERTISERS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialOfferAdvertisersListItemState } from "@core/store/types/admin/lists/OfferAdvertisersListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialOfferAdvertisersListItemState => {
  return {
    data: {},
    filters: {
      id: null,
      loginOrIntId: null
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => PartialOfferAdvertisersListItemState = initialState;

const getters: GetterTree<PartialOfferAdvertisersListItemState, RootState> = {
};

const mutations: MutationTree<PartialOfferAdvertisersListItemState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_OFFER_ADVERTISERS] (state, advertisers: PartialOfferAdvertisersListItemState["advertisers"]) {
    if (state.pagination.page !== 1 && state?.data?.items && advertisers) {
      state.data.items = Array.from(new Set([...state.data.items, ...advertisers.items as []]));
    } else {
      state.data = advertisers;
    }
  },

  [UPDATE_OFFER_ADVERTISERS_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_OFFER_ADVERTISERS_FILTERS] (state, filters: PartialOfferAdvertisersListItemState["filters"]) {

    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<PartialOfferAdvertisersListItemState, RootState> = {
  async [GET_OFFER_ADVERTISERS_LIST] ({ commit, state }, offerId: string) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    const { data: { offers: { items: [offer] } } } =
            await OfferAdvertisersListService.getAdvertisersListByOffer(
              offerId,
              limit,
              offset,
              state.filters
            );
        
    if (offer) {
      const { advertisers } = offer;
      const advertisersModifed = {
        ...advertisers,
        items: advertisers.items.map(({ advertiser }) => advertiser)
      };
      
      commit(UPDATE_OFFER_ADVERTISERS, advertisersModifed);
      return advertisersModifed;
    }
  }
};

export const offerAdvertisersList: Module<PartialOfferAdvertisersListItemState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
