export default [
  {
    path: "offers",
    name: "offers",
    redirect: { name: "webmaster:offers:offers" },
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:offers" */ "@/views/Webmaster/OffersView.vue"),
    children: [
      {
        path: "",
        name: "offers",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:offers:offers" */ "@/components/Webmaster/Offers/Offers.vue")
      }
    ]
  },
  {
    path: "offers/:slug",
    name: "offer",
    props: true,
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:offer" */ "@/views/Webmaster/OfferView.vue")
  }
];
