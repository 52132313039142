import Service from "@core/services/common/Service";
import competitions from "@core/services/admin/competition/grapgql/competitions.graphql";
import webmasterTop from "@core/services/admin/competition/grapgql/webmasterTop.graphql";
import editCompetition from "@core/services/admin/competition/grapgql/editCompetition.graphql";
import planCompetition from "@core/services/admin/competition/grapgql/planCompetition.graphql";
import draftCompetition from "@core/services/admin/competition/grapgql/draftCompetition.graphql";
import createCompetition from "@core/services/admin/competition/grapgql/createCompetition.graphql";
import deleteCompetition from "@core/services/admin/competition/grapgql/deleteCompetition.graphql";
import competitionParticipants from "@core/services/admin/competition/grapgql/competitionParticipants.graphql";
import currentCompetitionAdmin from "@core/services/admin/competition/grapgql/currentCompetitionAdmin.graphql";
import { CompetitionPeriodEnum } from "@core/store/types/admin/competition/enum/competitionPeriodEnum";
import {
  CompetitionFiltersResponse,
  CompetitionModalState
} from "@core/store/types/admin/competition";
import { AxiosPromise } from "axios";

export class CompetitionService extends Service {
  public static async getCompetitions (
    limit: number,
    offset: number,
    filters: CompetitionFiltersResponse
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitions,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getCurrentCompetition (): Promise<Nullable<string>> {
    try {
      const { data: { currentCompetition } } = await this.api.get("", {
        params: {
          query: currentCompetitionAdmin
        }
      });

      return currentCompetition?.competition?.id;
    } catch (e) {
      throw e;
    }
  }

  public static async getCompetitionsRating (period: CompetitionPeriodEnum): Promise<AxiosPromise> {
    try {
      const { data: { webmasterTop: data } } = await this.api.get("", {
        params: {
          query: webmasterTop,
          variables: {
            period
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async createCompetition (input: CompetitionModalState["form"]): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: createCompetition,
        variables: { input }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async editCompetition (competitionId: string | null, input: CompetitionModalState["form"]): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: editCompetition,
        variables: {
          competitionId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async draftCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: draftCompetition,
        variables: { competitionId }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async planCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: planCompetition,
        variables: { competitionId }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteCompetitions (competitionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: deleteCompetition,
        variables: { competitionId }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async detailCompetitions (
    limit: number,
    offset: number,
    competitionId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: competitionParticipants,
          variables: {
            limit,
            offset,
            competitionId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}