import _Vue from "vue";

((m, e, t, r, i, k, a): void => {
  // @ts-ignore
  m[i] = m[i] || ((...params): void => {
    // @ts-ignore
    (m[i].a = m[i].a || []).push(params);
  });
  
  // @ts-ignore
  m[i].l = 1 * new Date();
  
  // @ts-ignore
  k = e.createElement(t);
  // @ts-ignore
  a = e.getElementsByTagName(t)[0];
  // @ts-ignore
  k.async = 1;
  // @ts-ignore
  k.src = r;
  // @ts-ignore
  a.parentNode.insertBefore(k, a);
})(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

interface MetrikaOptions {
  id: string;
  disable: boolean;
  debug: boolean;
  options: {
    webvisor: boolean;
    clickmap: boolean;
    trackLinks: boolean;
    accurateTrackBounce: boolean;
    trackHash: boolean;
  };
}

export function useMetrika (options?: MetrikaOptions) {
  const { id, disable, debug, options: initOptions } = <MetrikaOptions>{
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    debug: process.env.NODE_ENV !== "production",
    disable: process.env.VUE_APP_YANDEX_METRIKA_ON !== "true",
    options: {
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true
    },
    ...options
  };

  if (!id || disable) {
    return;
  }

  // @ts-ignore
  ym(id, "init", initOptions);

  return {
    reachGoal: (eventName: string, params?: any): void => {
      const eventType = "reachGoal";

      // @ts-ignore
      window.ym(id, eventType, eventName, params);

      if (debug) {
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] ${ eventType }: ${ eventName }`);
      }
    },

    getClientID: (): string | undefined => {
      let clientId;
      // @ts-ignore
      window.ym(id, "getClientID", (clientID) => {
        clientId = clientID;
      });
      return clientId;
    },

    setUserId: (login: string, eventId: string): void => {
      const eventType = "userParams";
      // @ts-ignore
      window.ym(id, eventType, {
        login: login,
        UserID: eventId
      });

      if (debug) {
        // eslint-disable-next-line no-console
        console.log(`[DEBUG] ${ eventType }: ${ login } / ${ eventId }`);
      }
    }
  };
}

export default function install (Vue: typeof _Vue, options: MetrikaOptions): void {
  Vue.prototype.$ym = useMetrika(options);
}