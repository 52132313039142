import { OfferEditAdvertisersAvailableService } from "@core/services/admin/common/offer/OfferEditAdvertisersAvailableService";
import {
  GET_AVAILABLE_ADVERTISERS_BY_ID,
  GET_OFFER_ADVERTISERS_AVAILABLE,
  GET_OFFER_ID
} from "@core/store/action-constants";
import {
  SET_OFFER_ADVERTISERS_AVAILABLE,
  UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION,
  UPDATE_OFFER_AVAILABLE_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  OfferAdvertiserAvailable,
  PartialOfferAdvertisersAvailableState
} from "@core/store/types/admin/offer/lists/OfferEditAdvertisersAvailableList.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialOfferAdvertisersAvailableState => {
  return {
    data: {},
    filters: {
      loginOrIntId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialOfferAdvertisersAvailableState = initialState;

const getters: GetterTree<PartialOfferAdvertisersAvailableState, RootState> = {
  [GET_AVAILABLE_ADVERTISERS_BY_ID]: state => function (ids: string[]): OfferAdvertiserAvailable[] {
    // @ts-ignore
    return ids.map(id => state.data?.items?.find((item: OfferWebmasterAvailable) => item.id === id));
  }
};

const mutations: MutationTree<PartialOfferAdvertisersAvailableState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_OFFER_ADVERTISERS_AVAILABLE] (state, advertisers: PaginationOutput<OfferAdvertiserAvailable[]>) {
    if (state.pagination.page !== 1 && state?.data?.items && advertisers) {
      state.data.items = Array.from(new Set([...state.data.items, ...advertisers.items as []]));
    } else {
      state.data = advertisers;
    }
  },

  [UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_OFFER_AVAILABLE_FILTERS] (state, filters: PartialOfferAdvertisersAvailableState["filters"]) {
    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<PartialOfferAdvertisersAvailableState, RootState> = {
  async [GET_OFFER_ADVERTISERS_AVAILABLE] ({ commit, state, rootGetters }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
    const { data: { offer: { availableAdvertisers } } } =
            await OfferEditAdvertisersAvailableService.getOfferAdvertisers(
              limit,
              offset,
              state.filters,
              offerId
            );
    availableAdvertisers.items = availableAdvertisers.items.map((item: OfferAdvertiserAvailable) => {
      return {
        advertiserMinimalWriteOff: item.advertiserMinimalWriteOff,
        ...item.advertiser
      };
    });
    commit(SET_OFFER_ADVERTISERS_AVAILABLE, availableAdvertisers);
    return availableAdvertisers;
  }
};

export const offerEditAdvertisersAvailableList: Module<PartialOfferAdvertisersAvailableState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
