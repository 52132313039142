import { TrafficRequestChatItem, TrafficRequestChatParams } from "@core/store/types/admin/traffic/trafficRequest";
import { MessageDTO } from "@core/store/types/common/sockets";
import { TrafficRequestChatActionEnum } from "@core/store/types/admin/traffic/enums/trafficRequestChatActionEnum";
import { EventSocketEnum } from "@core/store/types/common/enums/eventSocketEnum";
import { TrafficRequestStatusEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStatusEnum";

export function newMessageTransform (item: TrafficRequestChatItem): MessageDTO {
  return {
    action: item.action.toUpperCase() as TrafficRequestChatActionEnum,
    comment: item.message,
    creator: item.author,
    id: item.id,
    createdAt: item.createdAt,
    params: item.action.toUpperCase() === TrafficRequestChatActionEnum.EVENT ? paramsTransform(item.params!, item.message as EventSocketEnum) : {}
  };
}

function paramsTransform (params: Partial<TrafficRequestChatParams<EventSocketEnum>>, message: EventSocketEnum) {
  switch (message) {
    case EventSocketEnum.RECOMMENDATION_CREATED_EVENT: {
      const {
        audience,
        landing,
        trafficSource,
        comment,
        period: [periodStartDate, periodEndDate]
      } = params as TrafficRequestChatParams<EventSocketEnum.RECOMMENDATION_CREATED_EVENT>;

      return { audience, landing, trafficSource, comment, periodStartDate, periodEndDate };
    }
    case EventSocketEnum.RECOMMENDATION_EDITED_EVENT: {
      const {
        new: {
          audience,
          landing,
          trafficSource,
          comment,
          period: [periodStartDate, periodEndDate]
        }
      } = params as TrafficRequestChatParams<EventSocketEnum.RECOMMENDATION_EDITED_EVENT>;

      return { audience, landing, trafficSource, comment, periodStartDate, periodEndDate };
    }
    case EventSocketEnum.RECOMMENDATION_CLOSED: {
      const { status } = params as TrafficRequestChatParams<EventSocketEnum.RECOMMENDATION_CLOSED>;
      return { status };
    }
    case EventSocketEnum.NEW_TERMS_CREATED_EVENT: {
      const {
        capacity: newCapacity,
        payout: newPayout,
        countHoursToApply,
        comment,
        period: [periodStartDate, periodEndDate]
      } = params as TrafficRequestChatParams<EventSocketEnum.NEW_TERMS_CREATED_EVENT>;

      return { newCapacity, newPayout, countHoursToApply, comment, periodStartDate, periodEndDate };
    }
    case EventSocketEnum.NEW_TERMS_EDITED_EVENT: {
      const {
        new: {
          capacity: newCapacity,
          payout: newPayout,
          countHoursToApply,
          comment,
          period: [periodStartDate, periodEndDate]
        }
      } = params as TrafficRequestChatParams<EventSocketEnum.NEW_TERMS_EDITED_EVENT>;

      return { newCapacity, newPayout, countHoursToApply, comment, periodStartDate, periodEndDate };
    }
    case EventSocketEnum.NEW_TERMS_APPLIED:
      return {
        status: TrafficRequestStatusEnum.APPLIED
      };
    case EventSocketEnum.TRAFFIC_REQUEST_DELETED_EVENT:
      return {
        status: TrafficRequestStatusEnum.DELETED
      };
    default: return {};
  }
}