import { createIncomeModal } from "@core/store/modules/admin/finances/modules/financeIncomes/financesIncomesCreateIncomeModal";
import { FinancesService } from "@core/services/admin/finances/financesIncomes/FinancesIncomesService";
import {
  CANCEL_IN_TRANSACTION,
  GET_INCOMES,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  UPDATE_FILTERS,
  SET_INCOMES
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FinanceIncomesState } from "@core/store/types/admin/finances/financesIncomes/financesIncomes";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { ActionTree, Module, MutationTree } from "vuex";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): FinanceIncomesState => {
  return {
    incomes: null,
    filters: {
      datepicker: datepicker({ amount: 7, unit: "day" }),
      intId: null,
      userId: null,
      status: null,
      currency: null,
      paymentSystemId: null,
      creatorId: null
    }
  };
};

const state: () => FinanceIncomesState = initialState;

const mutations: MutationTree<FinanceIncomesState> = {
  [SET_INCOMES] (state, payload: FinanceIncomesState["incomes"]) {
    state.incomes = payload;
  },
  
  [UPDATE_FILTERS] (state, filter) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [SET_EMPTY] (state, target?: keyof FinanceIncomesState) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<FinanceIncomesState, RootState> = {
  async [GET_INCOMES] ({ state, commit }) {
    const { datepicker: { dateEnd, dateStart }, ...filters } = state.filters;
    const { limit, offset } = pagination();

    try {
      const inTransactions = await FinancesService.getIncomes(
        limit,
        offset,
        {
          dateEnd,
          dateStart,
          ...filters
        }
      );
      commit(SET_INCOMES, inTransactions);
  
      return inTransactions;
    } catch (e) {
      throw e;
    }
  },
  
  async [CANCEL_IN_TRANSACTION] ({ commit }, id) {
    try {
      const cancelInTransaction = await FinancesService.cancelInTransaction(id);
      commit("LOCAL_UPDATE", { items: cancelInTransaction, target: "incomes" });
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_FILTERS] ({ commit }, option) {
    commit(UPDATE_FILTERS, option);
  },
  
  [SET_EMPTY] ({ commit }, target?: FinanceIncomesState) {
    commit(SET_EMPTY, target);
  }
};

export const incomes: Module<FinanceIncomesState, RootState> = {
  modules: {
    createIncomeModal
  },
  namespaced: true,
  state,
  actions,
  mutations
};
