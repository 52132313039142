import { formatEmptyString, moment, formatCurrency } from "@core/filters";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";

// @vue/components
export const StatisticTableView = {
  mixins: [VuetableFieldMixin],
  
  methods: {
    moment,
    
    formatEmptyString,
    
    formatCurrency,
    
    getMethodByPath (obj, path) {
      path = path.split(".");
      
      for (const level of path) {
        obj = obj && obj[level];
      }
      
      return obj;
    },
    
    formatter (value, subValue) {
      if (this.rowField.formatter) {
        return this.rowField.formatter(value, subValue);
      } else {
        return value;
      }
    },

    secondaryFormatter (value, subValue) {
      if (this.rowField.formatter) {
        return this.rowField.secondaryFormatter(value, subValue);
      } else {
        return value;
      }
    },
    
    onColumnHeaderClicked (field, event) {
      this.vuetable.orderBy(field, event);
    },
  
    onColumnHeaderClickedBackend (field, event) {
      this.onColumnHeaderClicked(field, event);
      this.rowField?.updateSorting?.();
    },
    
    onCustomSorting (field, { sortField, direction }) {
      if (!field.sortOrder) {
        throw new Error("added sort order");
      }
  
      const customField = Object.assign(field, { sortField });
      
      if (field.sortOrder.length !== 0) {
        this.vuetable.removeSortColumn(0);
      }
      
      this.vuetable.singleColumnSort(customField);
      this.vuetable.setSortColumnDirection(0, direction);
      this.rowField.updateSorting();
    }
  }
};
