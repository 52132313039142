import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { DashboardTopCardState } from "@core/store/types/admin/lists/DashboardListState";

export default function dashboardUserTotal (groupType: DashboardTopCardState["groupType"]): ASTGeneratedOutput {
  const hasAdvertiser = groupType === "advertiser";

  return Generator.query({
    operation: groupType + "s",
  
    fields: ["count"],

    ...hasAdvertiser && {
      variables: {
        filters: {
          type: "AdvertiserListCriteriaFilters",
          value: { isApproved: true }
        }
      }
    }
  });
}
