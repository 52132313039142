import { Module } from "vuex";
import { PaginationTransactionsListState } from "@core/store/types/advertiser/finances";
import { RootState } from "@core/store/root-state";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";


export const refillModal: Module<PaginationTransactionsListState, RootState> = {
  namespaced: true,
  modules: {
    paymentSystemsList
  }
};