import Service from "@core/services/common/Service";
import {
  CostsListItem,
  CostsListState,
  PartialFinancesCostsState
} from "@core/store/types/admin/finances/financesCosts/financesCosts";
import { AxiosPromise } from "axios";
import autoPayTransaction from "./graphql/autoPayTransaction.graphql";
import getCosts from "./graphql/outTransactions.graphql";
import transitCreatedToHandProcessingQuery from "./graphql/transitCreatedToHandProcessing.graphql";
import transitHandProcessingToProcessedQuery from "./graphql/transitHandProcessingToProcessed.graphql";
import transitHandProcessingToReject from "./graphql/transitHandProcessingToReject.graphql";
import markExpress from "./graphql/markExpress.graphql";
import unmarkExpress from "./graphql/unmarkExpress.graphql";
import { outTransactionMapper, outTransactionsMapper } from "./FinancesCostsMapper";

export class FinancesCostsService extends Service {
  public static async getCosts (
    limit: number,
    offset: number,
    filters: PartialFinancesCostsState["filters"],
    sort?: Nullable<string>,
    order?: Nullable<string>
  ): Promise<CostsListState["costs"]> {
    try {
      const { data: { outTransactions } } = await this.api.get("", {
        params: {
          query: getCosts,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
      return outTransactionsMapper(outTransactions);
    } catch (e) {
      throw e;
    }
  }

  public static async autoCost (transactionId: string): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: autoPayTransaction,
        variables: {
          input: { ids: transactionId }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async processCost (ids: string): Promise<CostsListItem[]> {
    try {
      const { data: { transitCreatedToHandProcessing } } = await this.api.post("", {
        query: transitCreatedToHandProcessingQuery,
        variables: {
          input: { ids }
        }
      });
      return outTransactionMapper(transitCreatedToHandProcessing);
    } catch (e) {
      throw e;
    }
  }

  public static async acceptCost (ids: string): Promise<CostsListItem[]> {
    try {
      const { data: { transitHandProcessingToProcessed } } = await this.api.post("", {
        query: transitHandProcessingToProcessedQuery,
        variables: {
          input: { ids }
        }
      });
      return outTransactionMapper(transitHandProcessingToProcessed);
    } catch (e) {
      throw e;
    }
  }

  public static async rejectCost (ids: string): Promise<CostsListItem[]> {
    try {
      const { data: { transitToReject } } = await this.api.post("", {
        query: transitHandProcessingToReject,
        variables: {
          input: { ids }
        }
      });
      return outTransactionMapper(transitToReject);
    } catch (e) {
      throw e;
    }
  }

  public static async expressCost (id: string): Promise<CostsListItem[]> {
    try {
      const { data: { markIsExpress: data } } = await this.api.post("", {
        query: markExpress,
        variables: {
          id
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async unmarkExpressCost (id: string): Promise<CostsListItem[]> {
    try {
      const { data: { unmarkIsExpress: data } } = await this.api.post("", {
        query: unmarkExpress,
        variables: {
          id
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}
