<template>
    <LTooltip
        ref="userOutput"
        :position="position"
        :delay="[150, 200]"
        v-bind="$attrs"
        type="is-white"
        boundary="window"
        interactive
        class="mr-1"
        @onShow="onShow">
        <span
            class="is-clickable"
            @click="imageClick">
            <slot name="image"></slot>
        </span>

        <template #content>
            <template v-if="currentItem">
                <slot name="content"></slot>
            </template>

            <InlineLoader
                v-else-if="isLoading"
                active
                :is-full-page="false">
            </InlineLoader>

            <template v-else>
                <span>{{ $t("common.table.noData") }}</span>
            </template>
        </template>
    </LTooltip>
</template>

<script>
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";

  export default {
    name: "MiniCardTooltip",
    components: {
      InlineLoader
    },
    props: {
      currentItem: {
        type: Object,
        default: null
      },
      isLoading: {
        type: Boolean,
        required: true
      },
      position: {
        type: String,
        default: "is-bottom"
      }
    },

    methods: {
      imageClick () {
        this.$emit("imageClick");
      },

      onShow () {
        this.$emit("onShow");
      }
    }
  };
</script>

<style lang="scss">
    .tippy-arrow {
        &::after {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>

</style>
