import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function webmasterById (
  webmasterId: string,
  isFullCardProfile: boolean
): ASTGeneratedOutput {
  const params = {
    include: isFullCardProfile ? [
      "admins/name",
      "comment",
      "contact/skype",
      "detail/isDomestic",
      "detail/trust/alias",
      "detail/label",
      "detail/categoriesPreferences/name",
      "detail/categoriesPreferences/id",
      "detail/trafficTypes/name",
      "detail/trafficTypes/id",
      "detail/residenceCountry/name",
      "detail/residenceCountry/alpha2",
      "detail/residenceCountry/alpha3",
      "detail/residenceCountry/id",
      "detail/residenceCity/name",
      "detail/residenceCity/id",
      "contact/email",
      "detail/birthday",
      "subType",
      "preferredVertical",
      "isShowDomonetizationStatistics",
      "agentType",
      "activity/isConfirmed",
      "activity/isBlocked",
      "referrer/login",
      "detail/verticals/name",
      "detail/verticals/id",
      "detail/isPartnerNetwork",
      "timeZone/alias",
      "timeZone/continent",
      "timeZone/offset",
      "preferredCurrency",
      "referrals/avatar/url",
      "referrals/login",
      "referrals/intId",
      "requisites/purse",
      "requisites/paymentSystem/currency",
      "requisites/paymentSystem/name",
      "webmasters/avatar/url",
      "webmasters/login",
      "webmasters/intId"
    ] : []
  };
  
  const gqlObject = {
    operation: "webmasterById",
    
    fields: [
      {
        admins: [
          "id",
          "login",
          "intId"
        ],
        detail: [
          "isTeam",
          "teamName"
        ],
        activity: [
          "registeredAt"
        ],
        avatar: [
          "url"
        ],
        contact: [
          "telegram",
          "whatsApp",
          "viber"
        ],
        country: [
          "name",
          "alpha2",
          "alpha3",
          "id"
        ]
      },
      "isApproved",
      "login",
      "intId",
      "id",
      "preferredVertical"
    ],
    
    variables: {
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}
