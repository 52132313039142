import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  AdvertisersProfilePasswordState
} from "@core/store/types/advertiser/profile/modules/password";
import {
  SET_EMPTY,
  UPDATE_MODAL
} from "@core/store/mutation-constants";

const initialState = (): AdvertisersProfilePasswordState => {
  return {
    modal: {
      isPasswordModalActive: false
    }
  };
};

const state: () => AdvertisersProfilePasswordState = initialState;

const mutations: MutationTree<AdvertisersProfilePasswordState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<AdvertisersProfilePasswordState, RootState> = {
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  }
};

export const password: Module<AdvertisersProfilePasswordState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
