export default [
  {
    path: "efficiency",
    name: "efficiency",
    redirect: { name: "admin:efficiency:affiliate" },
    component: (): Promise<any> => import(/* webpackChunkName: "efficiency" */ "@/views/Admin/EfficiencyView.vue"),
    children: [
      {
        path: "affiliate",
        name: "affiliate",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:efficiency:affiliate" */ "@/components/Admin/Efficiency/Efficiency.vue")
      },
      {
        path: "advert",
        name: "advert",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "admin:efficiency:advert" */ "@/components/Admin/Efficiency/EfficiencyAdvert.vue")
      }
    ]
  }
];
