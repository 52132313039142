import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import currencies from "./graphql/currencies.graphql";

export class CurrenciesService extends Service {
  public static async getCurrencies (): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: currencies
      });
    } catch (e) {
      throw e;
    }
  }
}