import { CapacityOrder, CapacitySort, SubWebmastersFilters } from "@core/store/types/admin/offers/capacity";
import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function subWebmasterCapacity (
  limit: number,
  offset: number,
  agentId: string,
  offerId: string,
  filters: SubWebmastersFilters,
  sort: CapacitySort,
  order: CapacityOrder,
  isIndividualRate: boolean,
  isShowRedemption: boolean
): ASTGeneratedOutput {
  const include = [];

  if (isShowRedemption) {
    include.push("items/statisticCapacity/monthRedemption");
  }

  const params = {
    include
  };
  const uniqId = `${ agentId }-${ offerId }`;
  const moneyType = ["value", "currency"];
  const gqlObject = {
    operation: "subWebmastersCapacity",

    fields: [
      {
        items: [
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "isBindedToCurrentAdmin"
            ]
          },
          {
            requiredApprove: [
              "requiredApprove",
              "updatedAt"
            ]
          },
          "softCapacity",
          "capacityUpdatedAt",
          {
            statisticCapacity: [
              "yesterdayLeads",
              "yesterdayCapacityIndicator",
              "weekLeads",
              "weekCapacityIndicator",
              "monthLeads",
              "monthCapacityIndicator",
              "yesterdayCapacityEfficiency",
              "weekCapacityEfficiency",
              "monthCapacityEfficiency"
            ]
          }
        ]
      },
      "count"
    ],

    variables: {
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      offerId: {
        type: "Uid!",
        value: offerId
      },
      agentId: {
        type: "Uid!",
        value: agentId
      },
      filters: {
        type: "SubWebmasterCapacityListCriteriaFilters",
        value: filters
      },
      sort: {
        type: "OfferWebmasterCapacitySort",
        value: sort[uniqId] ?? "yesterdayLeads"
      },
      order: {
        type: "SortOrder",
        value: order[uniqId] ?? "ASC"
      }
    }
  };

  return Generator.query(gqlObject, params);
}