import { StatisticItems } from "@core/store/types/common/statistic/common/statistic";
import Vue from "vue";

export const sortFieldGroupsStatistic = (items: StatisticItems[] | undefined, ...fields: ["country", "region", "category"]): void => {
  fields.forEach((field: "country" | "region" | "category") => {
    items?.map((item: StatisticItems) => {
      const sortField = item[field];
      
      if (sortField?.length && Array.isArray(sortField)) {
        Vue.set(item, `${ field }Sort`, sortField[0]);
      }
    });
  });
};