import { GET_MY_REFERRALS, SET_MY_REFERRALS, SET_EMPTY } from "@core/store/action-constants";
import { ReferralService } from "@core/services/webmaster/referrals/referralsService";
import { MyReferrals, MyReferralsState } from "@core/store/types/webmaster/referrals";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";

const initialState = (): MyReferralsState => {
  return {
    myReferrals: null
  };
};

const state: () => MyReferralsState = initialState;

const mutations: MutationTree<MyReferralsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_MY_REFERRALS] (state, myReferrals: MyReferrals[]) {
    state.myReferrals = myReferrals;
  }
};

const actions: ActionTree<MyReferralsState, RootState> = {
  async [GET_MY_REFERRALS] ({ commit }) {
    const { data: { myReferrals } } = await ReferralService.getMyReferrals();
    
    commit(SET_MY_REFERRALS, myReferrals);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const asideReferrals: Module<MyReferralsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
