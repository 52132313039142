



















































































import InlineSvg from "vue-inline-svg";
import notificationIcon from "@/assets/NavigationBar/notification.svg";
import { useWebsockets } from "@/stores/common/auth/socketsStore";
import { computed, onBeforeMount, onUnmounted } from "@vue/composition-api";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
import { setQuery } from "@/components/Common/NavigationBar/Notifications/setQuery";
import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useWebsockets();
  const data = computed(() => store.trafficNotifications.data);
  const countNotifications = computed(() => data.value.length);
  onBeforeMount(() => {
    store.trafficNotifications.subscribe();
  });
  onUnmounted(() => {
    store.trafficNotifications.unsubscribe();
  });
  return {
    notificationIcon,
    setQuery,
    data,
    countNotifications
  };
};
__sfc_main.components = Object.assign({
  InlineSvg,
  WebmasterOutput,
  CustomOfferOutput,
  AdminOutput
}, __sfc_main.components);
export default __sfc_main;
