import advertiserRates from "@core/services/admin/offer/edit/graphql/advertiserRates.graphql";
import getOfferWebmastersReward from "@core/services/admin/offer/edit/graphql/getOfferWebmastersReward.graphql";
import Service from "@core/services/common/Service";
import {
  OfferAdvertiserIndividualRateState, PartialOfferAdvertiserIndividualRateFilters,
  UpdateIndividualRate
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";
import { AxiosPromise } from "axios";
import createAdvertiserIndividualRate from "./graphql/createAdvertiserIndividualRate.graphql";
import editAdvertiserIndividualRate from "./graphql/editAdvertiserIndividualRate.graphql";
import removeAdvertiserIndividualRate from "./graphql/removeAdvertiserIndividualRate.graphql";
import minimalAllowedAdvertiserRate from "./graphql/minimalAllowedAdvertiserRate.graphql";
import { RequestRates } from "@core/store/types/admin/offer/webmasters/OfferExternalWebmastersModalState";

export class OfferEditAdvertisersIndividualRateService extends Service {
  public static getOfferAdvertisersIndividualRates (limit: number, offset: number,
    offerId: string, filters: OfferAdvertiserIndividualRateState["filters"]): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: advertiserRates,
          variables: {
            limit,
            offset,
            filters,
            offerId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static minimalAllowedAdvertiserRate (
    offerId: string,
    advertiserId: string,
    webmasterId: string): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: minimalAllowedAdvertiserRate,
          variables: {
            offerId,
            advertiserId,
            webmasterId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getOfferWebmastersReward (limit: number, offset: number,
    offerId: string, filters: PartialOfferAdvertiserIndividualRateFilters): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: getOfferWebmastersReward,
          variables: {
            limit,
            offset,
            offerId,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static createAdvertiserIndividualRate (offerId: string, rates: RequestRates[]): AxiosPromise {
    try {
      return this.api.post("", {
        query: createAdvertiserIndividualRate,
        variables: { offerId, input: { rates } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static editAdvertiserIndividualRate (rateId: string, params: UpdateIndividualRate): AxiosPromise {
    try {
      return this.api.post("", {
        query: editAdvertiserIndividualRate,
        variables: { rateId, input: { ...params } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static removeAdvertiserIndividualRate (rateId: string): AxiosPromise {
    try {
      return this.api.post("", {
        query: removeAdvertiserIndividualRate,
        variables: { rateId }
      });
    } catch (e) {
      throw e;
    }
  }
}
