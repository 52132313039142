import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";

export default function offerByIntId (intId: number, user: string, isShowCommissions: boolean): ASTGeneratedOutput {
  const dataSet = {
    advertiser: [
      "landingParameters",
      "advertiserWriteOff/value",
      "advertiserWriteOff/currency"
    ],
    webmaster: [
      "finance/webmasterReward/currency",
      "finance/webmasterReward/value",
      "isAvailLimonadDomonetization",
      "webmasterJoinedState",
      "landing/trackingUrl",
      "landingParameters",
      "isPostbackEnable",
      "competitionPoint",
      "isAvailLimonadDomonetization"
    ],
    admin: [
      "finance/minimalCommission/currency",
      "finance/minimalCommission/value",
      "advertiserMinimalWriteOff/value",
      "advertiserMinimalWriteOff/currency"/*,
      "advertisers/items/writeOff/value",
      "advertisers/items/writeOff/currency",
      "advertisers/count"*/
    ].concat(isShowCommissions ? [
      "finance/webmasterReward/currency",
      "finance/webmasterReward/value",
      "minimalCommission/value",
      "minimalCommission/currency",
      "advertiserMinimalCommission/value",
      "advertiserMinimalCommission/currency"
    ] : [])
  };
  // @ts-ignore
  const params = { include: dataSet[user] };
  
  const gqlObject = {
    operation: "offerByIntId",
    
    fields: [
      "id",
      "intId",
      "slug",
      "createdAt",
      "type",
      "photoUrl",
      "landingUrl",
      "isApiAllowed",
      "vertical",
      "labels",
      {
        landing: [
          "cost",
          "currency",
          "preCost"
        ]
      },
      {
        logo: [
          "id",
          "url"
        ]
      },
      {
        detail: [
          "name",
          "description",
          "isInHouse",
          "rules"
        ]
      },
      {
        countries: [
          "id",
          "name",
          "alpha2",
          "alpha3"
        ]
      },
      {
        trafficTypes: [
          "id",
          "name"
        ]
      },
      {
        categories: [
          "id",
          "name"
        ]
      },
      {
        finance: [
          "withdrawType",
          "currency",
          "subGoalType",
          {
            holdTime: [
              "holdMinutes"
            ]
          }
        ]
      }
    ],
    
    variables: {
      intId: {
        type: "Int!",
        value: intId
      }
    }
    
  };
  
  return Generator.query(gqlObject, params);
}

