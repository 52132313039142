import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import forceLogOut from "@core/services/common/graphql/forceLogOut.graphql";
import forceLogOutByAdmin from "@core/services/common/graphql/forceLogOutByAdmin.graphql";

export class UserLogoutService extends Service {
  public static forceLogOut (): Promise<AxiosPromise> {
    try {
      return this.api.post("", {
        query: forceLogOut
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static forceLogOutByAdmin (
    userId: string
  ): Promise<AxiosPromise> {
    try {
      return this.api.post("", {
        query: forceLogOutByAdmin,
        variables: {
          userId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}