import { SET_EMPTY, UPDATE_MODAL } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { State } from "@core/store/types/admin/offers/webmasters";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): State => {
  return {
    modal: {
      isActive: false,
      offer: null
    }
  };
};

const state: () => State = initialState;

const mutations: MutationTree<State> = {
  [UPDATE_MODAL] (state, modal) {
    state.modal = { ...state.modal, ...modal };
  },

  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<State, RootState> = {
  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  }
};

export const webmasters: Module<State, RootState> = {
  namespaced: true,
  actions,
  state,
  mutations
};
