<template>
    <ModalBox
        :is-active.sync="isActive"
        :is-loading="isPaymentSystemsLoading"
        @close="SET_EMPTY"
        @submit="addRequisites">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`webmaster.profile.modal.requisiteAddTitle`) }}
            </h5>
        </template>
        <div class="columns">
            <label class="column">
                <Select
                    v-model="paymentSystemId"
                    :placeholder="$t('webmaster.profile.modal.defaultPaymentSystem')"
                    :get-data-vuex="getPaymentSystems"
                    :min-required-length="1"
                    field="name">
                </Select>
            </label>
            <label class="column">
                <b-field
                    :message="fieldMessage"
                    :type="{ 'is-danger': fieldMessage }">
                    <LInput
                        v-model="purse"
                        type="text"
                        :loading="isLoading.userExist || isLoading.tronAddress"
                        :required="isRequisite"
                        :validation-message="$t(`webmaster.profile.modal.validationRequisiteMessage`)"
                        :placeholder="$t(`webmaster.profile.modal.enterRequisite`)">
                    </LInput>
                </b-field>
            </label>
        </div>
        <template #footer>
            <b-button
                class="is-fullwidth is-info is-light"
                :disabled="isDisableButton"
                :loading="isLoading.addRequisite"
                native-type="submit">
                {{ $t(`webmaster.profile.modal.add`) }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import {
    ADD_REQUISITE,
    GET_REQUISITES,
    VALID_TRON_ADDRESS,
    GET_PAYMENT_SYSTEMS,
    USER_EXIST_IN_ZALEYCASH
  } from "@core/store/action-constants";
  import {
    SET_EMPTY,
    UPDATE_MODAL,
    UPDATE_MODAL_OPTIONS,
    UPDATE_PAYMENT_SYSTEMS_FILTERS,
    UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION,
    UPDATE_WEBMASTER_REQUISITES_ADD_FORM
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import Select from "@/components/Common/Select/Select.vue";
  import _debounce from "lodash/debounce";

  export default {
    name: "WebmasterProfileRequisitesModal",

    components: {
      ModalBox,
      Select
    },

    mounted () {
      if (this.queryStatusCode) {
        this.showModalConfirmedRequisites();
      }
    },

    data () {
      const { code } = this.$route.query;

      return {
        isPaymentSystemsLoading: false,
        queryStatusCode: code || null
      };
    },

    computed: {
      ...mapFields("webmaster/profile/requisites/add", {
        fields: ["isActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("webmaster/profile/requisites/add", {
        fields: ["isUserExistInZaleyCash", "isValidTronAddress"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      ...mapFields("webmaster/profile/requisites/add", {
        fields: ["paymentSystemId", "purse"],
        base: "form",
        action: UPDATE_WEBMASTER_REQUISITES_ADD_FORM
      }),

      ...mapState("webmaster/profile/requisites/add/paymentSystemsList", {
        paymentSystems: ({ data }) => data
      }),

      isLoading () {
        return {
          userExist: this.$wait(`webmaster/profile/requisites/add/${ USER_EXIST_IN_ZALEYCASH }`),
          tronAddress: this.$wait(`webmaster/profile/requisites/add/${ VALID_TRON_ADDRESS }`),
          addRequisite: this.$wait(`webmaster/profile/requisites/add/${ ADD_REQUISITE }`)
        };
      },

      debounceUpdateZaleyCash () {
        return _debounce(this.userExistInZaleyCash , 600, { trailing: true });
      },

      debounceUpdateValidTronAddress () {
        return _debounce(this.userValidTronAddress , 600, { trailing: true });
      },

      isRequisite () {
        return this.paymentSystems.items?.find(({ id }) => id === this.paymentSystemId)?.isRequisite ?? false;
      },

      fieldMessage () {
        const isZaleyCash = this.isShowMessage("zaley_cash", this.isUserExistInZaleyCash);
        const isTron = this.isShowMessage("tron", this.isValidTronAddress);

        return isZaleyCash || isTron
          ? this.$t(`webmaster.profile.modal.${ isZaleyCash ? "zaleycash" : "tron" }Message`)
          : "";
      },

      isDisableButton () {
        const { purse, isUserExistInZaleyCash, isValidTronAddress } = this;

        const isZaleyCash = this.hasIntegrator("zaley_cash");
        const isTron = this.hasIntegrator("tron");

        return isZaleyCash && (!purse || purse && !isUserExistInZaleyCash) ||
          isTron && (!purse || purse && !isValidTronAddress);
      }
    },

    methods: {
      ...mapActions("webmaster/profile/requisites", {
        [ADD_REQUISITE]: `add/${ ADD_REQUISITE }`,
        [GET_REQUISITES]: GET_REQUISITES
      }),

      ...mapActions("webmaster/profile/requisites/add", [
        USER_EXIST_IN_ZALEYCASH,
        VALID_TRON_ADDRESS,
        SET_EMPTY
      ]),

      ...mapMutations("webmaster/profile/requisites/add/paymentSystemsList", [
        UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION,
        UPDATE_PAYMENT_SYSTEMS_FILTERS
      ]),

      ...mapActions("webmaster/profile/requisites/add/paymentSystemsList", [
        GET_PAYMENT_SYSTEMS
      ]),

      async getPaymentSystems (label = "") {
        this.UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION();
        this.UPDATE_PAYMENT_SYSTEMS_FILTERS({ name: label.length > 0 ? label : null, withAttachedToUser: null });
        await this.GET_PAYMENT_SYSTEMS();
        return this.paymentSystems;
      },

      isShowMessage (integrator, isValid) {
        return this.hasIntegrator(integrator) && this.purse && isValid !== null && !isValid;
      },

      hasIntegrator (value) {
        return !!this.paymentSystems.items?.find(el => {
          return el.id === this.paymentSystemId && el.integrator.alias === value;
        });
      },

      userExistInZaleyCash () {
        if (this.purse && this.hasIntegrator("zaley_cash")) {
          this.USER_EXIST_IN_ZALEYCASH(this.purse);
        }
      },

      userValidTronAddress () {
        if (this.purse && this.hasIntegrator("tron")) {
          this.VALID_TRON_ADDRESS(this.purse);
        }
      },

      showModalConfirmedRequisites () {
        let notificationModal;
        const codes = {
          success: "success",
          invalid_link: "invalidLink",
          link_expired: "linkExpired",
          reject: "reject"
        };

        if (this.queryStatusCode === codes.success) {
          notificationModal = this.$buefy.toast.open({
            duration: 5000,
            message: this.$t("webmaster.profile.modal.requisitesAdd"),
            type: "is-success"
          });
        } else {
          notificationModal = this.$buefy.toast.open({
            duration: 5000,
            message: this.$t("webmaster.profile.modal.requisiteRejected") +
              "</br>" +
              this.$t(`webmaster.profile.modal.statusCodes.${ codes[this.queryStatusCode] }`),
            type: "is-danger"
          });
        }

        notificationModal?.$on("close", () => {
          this.$router.replace({
            query: null
          });
        });
      },

      async addRequisites () {
        if (this.paymentSystemId) {
          try {
            await this.ADD_REQUISITE();
            this.SET_EMPTY();

            this.$buefy.dialog.alert({
              title: " ",
              message: this.$t("webmaster.profile.modal.sendMessageOnMail"),
              position: "is-top",
              type: "is-warning",
              hasIcon: true,
              icon: "envelope",
              onConfirm: this.GET_REQUISITES
            });
          } catch (e) {
            if (e.errors[0].message === "NOT_CONNECTED_TO_ZALEY_CASH") {
              this.$buefy.toast.open({
                message: this.$t("webmaster.profile.modal.NOT_CONNECTED_TO_ZALEY_CASH"),
                type: "is-danger"
              });
            } else {
              this.$buefy.toast.open({
                message: this.$t("webmaster.profile.modal.requisitesAlreadyAdded"),
                type: "is-danger"
              });
            }
          }
        } else {
          this.$buefy.toast.open({
            message: this.$t("webmaster.profile.modal.requisites"),
            type: "is-danger"
          });
        }
      }
    },

    watch: {
      purse (value) {
        this.debounceUpdateZaleyCash();
        this.debounceUpdateValidTronAddress();

        if (!value) {
          this.isUserExistInZaleyCash = this.isValidTronAddress = null;
        }
      }
    }
  };
</script>

<style>

</style>
