import { WebmasterLabelsState } from "@core/store/types/common/webmasterLabels";
import { SET_WEBMASTER_LABELS } from "@core/store/mutation-constants";
import { GET_WEBMASTER_LABELS } from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import i18n from "@core/plugins/i18n";

const state: WebmasterLabelsState = {
  webmasterLabels: []
};

const mutations: MutationTree<WebmasterLabelsState> = {
  [SET_WEBMASTER_LABELS] (state, payload) {
    state.webmasterLabels = payload;
  }
};

const actions: ActionTree<WebmasterLabelsState, RootState> = {
  [GET_WEBMASTER_LABELS] ({ commit }) {
    const namespace = "dictionaries.webmasterLabel";
    const items = [
      { label: i18n.t(`${ namespace }.CIS`), value: "CIS" },
      // { label: i18n.t(`${ namespace }.NONE`), value: "NONE" },
      { label: i18n.t(`${ namespace }.EXTERNAL`), value: "EXTERNAL" },
      { label: i18n.t(`${ namespace }.MULTI`), value: "MULTI" }
    ];
    const data = { items, count: items.length };
    
    commit(SET_WEBMASTER_LABELS, data);
  }
};

export const webmasterLabels: Module<WebmasterLabelsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
