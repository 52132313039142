import { RootState } from "@core/store/root-state";
import { offerEditIndividualRatesModal } from "./offerEditIndividualRatesModal";
import { AdvertisersIndividualRates } from "@core/store/modules/admin/offer/modules/edit/modules/AdvertisersIndividualRates";
import {
  OfferAdvertiserIndividualRate,
  PartialOfferAdvertiserIndividualRateState
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate";
import { GET_WEBMASTER_BY_ID } from "@core/store/action-constants";
import { WATCHED_PARAMS } from "@core/store/mutation-constants";

export class AdvertisersInternalIndividualRates extends AdvertisersIndividualRates<PartialOfferAdvertiserIndividualRateState, RootState> {
  private readonly initState: () => PartialOfferAdvertiserIndividualRateState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): PartialOfferAdvertiserIndividualRateState => {
      return {
        individualRates: {},
        filters: {
          advertiserId: null,
          hasExternalWebmaster: false,
          loginOrIntId: null,
          manager: null
        },
        pagination: {
          page: 1,
          perPage: 25
        }
      };
    };
    this.getters({
      [GET_WEBMASTER_BY_ID]: state => function (webmasterId: string): OfferAdvertiserIndividualRate | undefined {
        return state.individualRates?.items?.find((user: OfferAdvertiserIndividualRate) => user.webmaster.id === webmasterId);
      },
      [WATCHED_PARAMS]: ({ filters }) => JSON.stringify(filters)
    });
    this.state(this.initState());
    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      }
    });

    this.modules({
      offerEditIndividualRatesModal
    });

    return this;
  }
}