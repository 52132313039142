import { UPDATE_THEME } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";

interface ThemeState {
  currentTheme: string
}

const initialState = (): ThemeState => {
  return {
    currentTheme: "light"
  };
};

const state: () => ThemeState = initialState;

const mutations: MutationTree<ThemeState> = {
  [UPDATE_THEME] (state, theme: string) {
    state.currentTheme = theme;
  }
};

const actions: ActionTree<ThemeState, RootState> = {
  [UPDATE_THEME] ({ commit }, theme) {
    commit(UPDATE_THEME, theme);
  }
};

export const theme: Module<ThemeState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
