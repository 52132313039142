import {
  PartialTrafficAnalysisState, Reason,
  TrafficAnalysisReason
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysis";
import { TrafficAnalysisService } from "@core/services/admin/statistic/trafficAnalysis/TrafficAnalysis";
import {
  SET_EMPTY,
  GET_TRAFFIC_ANALYSIS,
  SYNC_STATISTIC_FILTERS,
  SET_EMPTY_CONTEXT
} from "@core/store/action-constants";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { SET_TRAFFIC_ANALYSIS, UPDATE_FILTERS, UPDATE_TRAFFIC_ANALYSIS } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";
import { RootState } from "@core/store/root-state";
import { filterFilters, prepareUserFilters } from "@core/store/modules/admin/statistic/helpers/prepareFilters";
import { flowsFilters } from "@core/store/modules/common/statistic/common/flowsFilters";
import { dataPercent } from "../../../../../../../components/Common/Statistic/TrafficAnalysis/trafficDataHelper";
import { externalWebmasterFilters } from "@core/store/modules/admin/statistic/modules/common/externalWebmasterFilters";
import { datepicker } from "@core/helpers/datepicker";
import { transformObject } from "@core/services/admin/statistic/trafficAnalysis/transformObject";
import {
  trafficAnalysisChangeApproveHistoryModal
} from "@core/store/modules/common/statistic/trafficAnalysis/trafficAnalysisChangeApproveHistoryModal";

function formatReason (arr: TrafficAnalysisReason[]): Reason {
  if (arr?.length > 0) {
    const firstTotal = arr.reduce((a: number, b: TrafficAnalysisReason) => a + b.first, 0);
    const secondTotal = arr.reduce((a: number, b: TrafficAnalysisReason) => a + b.second, 0);

    const items = arr.map(({ reason, first, second }: TrafficAnalysisReason) => {
      const reasonTranslate = `common.entity.cancelReason.${ reason }`;
      const secondPercent = dataPercent(second, secondTotal);
      const firstPercent = dataPercent(first, firstTotal);
      const diffCount = second - first;
      const diff = Number(secondPercent) - Number(firstPercent);

      return {
        name: `${
          i18n.te(reasonTranslate)
            ? i18n.t(reasonTranslate)
            : `${ i18n.t("common.entity.cancelReason.unknownReason") } (${ reason })`
        }`,
        diffCount,
        firstPercent,
        first,
        secondPercent,
        second,
        diff
      };
    });

    // @ts-ignore
    const diffTotal = items.reduce((a: number, b: TrafficAnalysisReason) => a + b.diffCount, 0);

    return {
      items: items.sort((a, b) => b.first - a.first),
      aggregates: {
        firstTotal,
        secondTotal,
        diffTotal
      }
    };
  } else {
    return {
      items: [],
      aggregates: {
        firstTotal: 0,
        secondTotal: 0,
        diffTotal: 0
      }
    };
  }
}

const initialState = (): PartialTrafficAnalysisState => {
  return {
    trafficAnalysis: null,
    filters: {
      countryId: null,
      offerId: null,
      flowId: null,
      webmasterId: null,
      categoryId: null,
      externalWebmasterId: null,
      comparisonPeriods: false,
      currency: null,
      firstPeriod: datepicker({ amount: 1, unit: "months" }),
      secondPeriod: datepicker({ amount: 1, unit: "months" })
    }
  };
};

const state: () => PartialTrafficAnalysisState = initialState;

const getters: GetterTree<PartialTrafficAnalysisState, RootState> = {};

const mutations: MutationTree<PartialTrafficAnalysisState> = {
  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  },
  
  [SET_TRAFFIC_ANALYSIS] (state, items: PartialTrafficAnalysisState["trafficAnalysis"]): void {
    state.trafficAnalysis = items;
  },

  [UPDATE_TRAFFIC_ANALYSIS] (state, trafficAnalysis): void {
    state.trafficAnalysis = { ...state.trafficAnalysis, ...trafficAnalysis };
  },
  
  [UPDATE_FILTERS] (state, filter: PartialTrafficAnalysisState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  }
};

const actions: ActionTree<PartialTrafficAnalysisState, RootState> = {
  async [GET_TRAFFIC_ANALYSIS] ({ commit, state }): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { firstPeriod, secondPeriod, comparisonPeriods, ...filters } = state.filters;
    const _secondPeriod = comparisonPeriods ? secondPeriod : null;

    const data = await TrafficAnalysisService.getTraffic(firstPeriod, _secondPeriod, filters);

    let total: unknown = data.total;
    const spam = formatReason(data.spam);
    const cancel = formatReason(data.cancel);

    if (!_secondPeriod) {
      total = transformObject(data.total);
    }

    commit(SET_TRAFFIC_ANALYSIS, { spam, cancel, total });
  },
  
  [UPDATE_FILTERS] ({ commit }, filter: PartialTrafficAnalysisState["filters"]): void {
    commit(UPDATE_FILTERS, filter);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters: PartialTrafficAnalysisState["filters"]): void {
    const baseFilters = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`externalWebmasterFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["externalWebmasterId"]));
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters, { root: true });
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters, { root: true });
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters, { root: true });
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters, { root: true });
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    commit(`flowsFilters/${ SET_EMPTY }`);
    commit(`externalWebmasterFilters/${ SET_EMPTY }`);
    commit(`utmCampaignFilters/${ SET_EMPTY }`, null, { root: true });
    commit(`utmContentFilters/${ SET_EMPTY }`, null, { root: true });
    commit(`utmSourceFilters/${ SET_EMPTY }`, null, { root: true });
    commit(`utmMediumFilters/${ SET_EMPTY }`, null, { root: true });
  },
  
  [SET_EMPTY_CONTEXT] ({ commit }): void {
    commit(`flowsFilters/${ SET_EMPTY }`);
    commit(`externalWebmasterFilters/${ SET_EMPTY }`);
  }
};

export const trafficAnalysis: Module<PartialTrafficAnalysisState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    trafficAnalysisChangeApproveHistoryModal,
    externalWebmasterFilters,
    flowsFilters
  }
};
