




















import { withDefaults, defineProps, defineEmits } from "@vue/runtime-dom";
import { i18n } from "@core/plugins";
import { computed } from "@vue/composition-api";
interface Props {
  value: string | null;
  required: boolean;
  disabled: boolean;
  placeholder: string;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: [String, null],
    default: null
  },
  required: {
    key: "required",
    required: false,
    type: Boolean,
    default: false
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: String,
    default: () => i18n.t("webmaster.profile.labels.placeholder") as string
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const _telegram = computed({
    get: () => props.value?.replace(/@/g, ""),
    set: value => emit("input", value ? "@" + value.replace(/@/g, "") : null)
  });
  return {
    emit,
    _telegram
  };
};
export default __sfc_main;
