import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import crmListMacro from "./graphql/crmListMacro.graphql";

export class MacrosListService extends Service {
  /**
   * TODO: Добавить описание
   * @return {Promise<AxiosPromise>}
   */
  public static async crmListMacro (): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: crmListMacro
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
