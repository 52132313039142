import { callCenters } from "@core/store/modules/admin/callCenters";
import { capacityAndBumps } from "@core/store/modules/admin/capacityAndBumps";
import { crm } from "@core/store/modules/admin/crm/crm";
import { dashboard } from "@core/store/modules/admin/dashboard";
import { dictionaries } from "@core/store/modules/admin/dictionaries";
import { domains } from "@core/store/modules/admin/domains";
import { efficiency } from "@core/store/modules/admin/efficiency/efficiency";
import { finances } from "@core/store/modules/admin/finances/finances";
import { countries } from "@core/store/modules/common/countries/countries";
import { regions } from "@core/store/modules/admin/common/regions";
import { integration } from "@core/store/modules/admin/integrations/integration";
import { integrations } from "@core/store/modules/admin/integrations/integrations";
import { offerOld } from "@core/store/modules/admin/offer/offerOld";
import { offers } from "@core/store/modules/admin/offers";
import { payments } from "@core/store/modules/admin/payments/payments";
import { profile } from "@core/store/modules/admin/profile";
import { roles } from "@core/store/modules/admin/roles/roles";
import { statistic } from "@core/store/modules/admin/statistic";
import { analytic } from "@core/store/modules/admin/analytic";
import { statistics } from "@core/store/modules/admin/statistic/statistics";
import { users } from "@core/store/modules/admin/users";
import { AdminOffer } from "@core/store/modules/admin/offer";
import { audit } from "@core/store/modules/admin/audit";
import { competition } from "@core/store/modules/admin/competition";
import { webmasterSubType } from "@core/store/modules/admin/lists/webmasterSubType";
import { webmasterLabels } from "@core/store/modules/admin/lists/webmasterLabels";
import { offerDataByIntId } from "@core/store/modules/admin/common/offerOutput";
import { adminDataById } from "@core/store/modules/admin/common/adminOutput";
import { advertiserDataById } from "@core/store/modules/admin/common/advertiserOutput";
import { Module } from "@core/store/logic/Module";
import { competitionRating } from "@core/store/modules/admin/competition/competitionRating";
import { complaints } from "@core/store/modules/admin/complaints";
import { password } from "@core/store/modules/admin/password";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { competitionRouletteModal } from "@core/store/modules/admin/competition/competitionRouletteModal";

export class CoreAdmin<S, R extends Module<any, R>> extends Module<S, R> {
  constructor () {
    super();
  
    this.namespaced = true;
    
    this.modules({
      // * Statistic
      efficiency,
      statistics,
      statistic,
      analytic,
      dashboard,
      // * Offers
      offer: AdminOffer,
      offers,
      offerOld,
      // * Users
      users,
      adminDataById,
      advertiserDataById,
      offerDataByIntId,
      webmasterSubType,
      webmasterLabels,
      // * Integrations
      integrations,
      integration,
      // * Finances
      finances,
      payments,
      // * Competition
      competition,
      competitionRouletteModal,
      competitionRating,
      // * Lists
      countries,
      regions,
      // * ChangePassword
      password,
      webmasterPassword: password,
      // * Other
      profile,
      dictionaries,
      callCenters,
      roles,
      domains,
      crm,
      audit,
      capacityAndBumps,
      complaints,
      // TODO: Удалить как только перепишем фильтры ан pinia
      advertisersList,
      webmastersList
    });
  }
}
