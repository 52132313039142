import { AnalyticService } from "@core/services/webmaster/analytic/AnalyticService";
import { analyticAnalyseChart } from "@core/store/modules/webmaster/analytic/modules/analyticAnalyseChart";
import { subAnalytic } from "@core/store/modules/webmaster/analytic/subanalytic";
import { RootState } from "@core/store/root-state";
import {
  AnalyticItem,
  AnalyticItems,
  AdminAnalyticState
} from "@core/store/types/webmaster/analytic/analytic";
import { useStringToArray } from "@core/store/modules/webmaster/analytic/useStringToArray";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  FILTERED_CHART_LIST,
  SET_ANALYTIC,
  SET_ANALYTIC_FILTERS,
  SET_CHART_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_ANALYTIC,
  SET_EMPTY,
  UPDATE_OPTIONS,
  UPDATE_CHART_FILTERS,
  UPDATE_ANALYTIC_FILTERS
} from "@core/store/action-constants";
import { analyticDetailModal } from "@core/store/modules/webmaster/analytic/modules/analyticDetailModal";
import { analyticReasons } from "@core/store/modules/webmaster/analytic/modules/analyticReasons";
import { analyticModal } from "@core/store/modules/webmaster/analytic/modules/analyticModal";
import { defaultFormatter } from "@core/filters";
import { pagination } from "@core/helpers/pagination";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { fixedFormatPercent } from "@core/flowMethods";

const initialState = (): AdminAnalyticState => {
  return {
    analytic: null,
    filters: {
      groups: ["OFFER", "DAY"],
      currency: null,
      threshold: 20,
      datepicker: datepicker({ amount: 1, unit: "weeks" }),
      categoryId: null,
      offerId: null,
      regionId: null,
      utmTerm: null,
      utmSource: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      globalSource: null,
      countryId: null
    },
    chartFilters: {},
    options: {
      analysisMode: false
    }
  };
};

const state: () => AdminAnalyticState = initialState;

const mutations: MutationTree<AdminAnalyticState> = {
  [SET_ANALYTIC] (state, analytic) {
    state.analytic = analytic;
  },
  
  [SET_ANALYTIC_FILTERS] (state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
  
  [SET_CHART_FILTERS] (state, chartFilters) {
    state.chartFilters = { ...state.chartFilters, ...chartFilters };
  },

  [UPDATE_OPTIONS] (state, options) {
    state.options = { ...state.options, ...options };
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const getters: GetterTree<AdminAnalyticState, RootState> = {
  [FILTERED_CHART_LIST]: (state) => {
    const group = state.filters.groups[0];

    return state.analytic?.items.map((item: AnalyticItem) => {
      const { name, login, intId, ...fields } = useStringToArray(item.group) ?? {};
      const user = {
        ...fields,
        name: group === "WEBMASTER" ? `${ intId }` : name || login
      };
      
      const items = item.items.map((item: AnalyticItems) => ({
        leadsIntegrated: defaultFormatter(item.countLeadsIntegrated),
        leads: defaultFormatter(item.countLeadsCreated),
        validLeads: defaultFormatter(item.countLeadsCreated - item.countLeadsSpammed),
        approvePercent: defaultFormatter(item.conversionApprove * 100),
        spamPercent: fixedFormatPercent(item.countLeadsSpammed / item.countLeadsCreated),
        redemptionPercent: fixedFormatPercent(item.conversionRedemption),
        day: item.day,
        week: item.week,
        month: item.month,
        year: item.year,
        user
      }));
      
      return { user, items };
    });
  }
};

const actions: ActionTree<AdminAnalyticState, RootState> = {
  async [GET_ANALYTIC] ({ commit, state, rootState }) {
    const { limit, offset } = pagination();
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { groups, currency, datepicker, threshold, ...otherFilters } = state.filters;
    const { auth: { preferredCurrency }, verticals: { currentVertical: vertical } } = rootState;
    
    const filters = {
      ...otherFilters,
      ...datepickerFormat(datepicker, "date", true),
      currency: currency ?? preferredCurrency,
      vertical
    };
    
    const { data: { analytic } } = await AnalyticService.getAnalytic(
      limit,
      offset,
      filters,
      groups[0],
      groups[1]
    );
  
    analytic.items = analytic.items.map((item, i) => ({ ...item, uniqueKey: new Date().getTime() + i }));
    
    commit(SET_ANALYTIC, analytic);
    commit(`subAnalytic/${ SET_EMPTY }`);
  },
  
  [UPDATE_ANALYTIC_FILTERS] ({ commit }, filters) {
    commit(SET_ANALYTIC_FILTERS, filters);
  },

  [UPDATE_CHART_FILTERS] ({ commit }, filters) {
    commit(SET_CHART_FILTERS, filters);
  },
  
  [UPDATE_OPTIONS] ({ commit }, options) {
    commit(UPDATE_OPTIONS, options);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    commit("analyticAnalyseChart/SET_EMPTY");
  }
};

export const analytic: Module<AdminAnalyticState, RootState> = {
  namespaced: true,
  modules: {
    subAnalytic,
    analyticModal,
    analyticReasons,
    analyticDetailModal,
    analyticAnalyseChart
  },
  state,
  mutations,
  getters,
  actions
};
