import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  SET_EMPTY,
  SET_OFFERS,
  SET_TOP_OFFERS,

  UPDATE_SORTING,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { GET_OFFERS, GET_TOP_OFFERS } from "@core/store/action-constants";
import { OffersService } from "@core/services/webmaster/offers/OffersService";
import { OffersFilters, OffersState } from "@core/store/types/webmaster/offers";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import offersFieldsList from "@core/store/modules/webmaster/offers/modules/datasets/offersFieldsList.json";
import { pagination } from "@core/helpers/pagination";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";

const initialState = (): OffersState => {
  return {
    selectedFields: offersFieldsList,
    data: null,
    dashboardTopOffers: null,
    filters: {
      type: null,
      withdrawType: null,
      countries: null,
      categories: null,
      isMine: null,
      nameOrIntId: null,
      isNewCreated: null,
      regions: null,
      currency: null,
      inCompetition: null,
      state: null,
      isInHouse: null,
      tiers: null
    },
    sort: null,
    order: null
  };
};

export const state: () => OffersState = initialState;

const mutations: MutationTree<OffersState> = {
  [SET_OFFERS] (state, offers: OffersState["data"]): void {
    state.data = offers;
  },

  [SET_TOP_OFFERS] (state, offers) {
    state.dashboardTopOffers = offers;
  },

  [UPDATE_FILTERS] (state, filters: OffersFilters): void {
    state.filters = { ...state.filters, ...filters };
  },
  
  [UPDATE_SORTING] (state, sort): void {
    state.sort = sort.sort;
    state.order = sort.order.toUpperCase();
  },

  [SET_EMPTY] (state, target?: keyof OffersState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<OffersState, RootState> = {
  async [GET_OFFERS] ({ commit, state: { filters, sort, order }, rootState }): Promise<void> {
    const { verticals: { currentVertical: vertical } } = rootState;
    const { limit, offset } = pagination();
    const [withdrawType, subGoalType ] = filters.withdrawType?.split("-") || [null, null];

    const offers = await OffersService.getOffers(
      { ...filters, withdrawType, subGoalType, vertical },
      limit,
      offset,
      sort,
      order
    );

    commit(SET_OFFERS, offers);
  },

  async [GET_TOP_OFFERS] ({ commit, rootState }) {
    const { verticals: { currentVertical: vertical } } = rootState;
    const { data } = await OffersService.getTopOffers({ vertical }, 8, 0, "countLeadInOffer", "DESC");

    commit(SET_TOP_OFFERS, data.offers);
  },

  [UPDATE_FILTERS] ({ commit }, filters: OffersFilters): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const offers: Module<OffersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    currencyList,
    withdrawTypesList
  }
};
