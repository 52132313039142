import { ADD_DOMAIN, UPDATE_OPTIONS } from "@core/store/action-constants";
import { SET_EMPTY, SET_MODAL_ACTIVE, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { DomainsService } from "@core/store/logic/webmaster/domains/domainsService";
import { DomainsModalState, Options } from "@core/store/types/webmaster/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): DomainsModalState => {
  return {
    options: {
      name: null
    },
    isModalActive: false
  };
};

const state: () => DomainsModalState = initialState;

const mutations: MutationTree<DomainsModalState> = {
  [SET_EMPTY] (state: DomainsModalState): void {
    Object.assign(state, initialState());
  },
  
  [SET_MODAL_ACTIVE] (state: DomainsModalState, isModalActive: boolean): void {
    state.isModalActive = isModalActive;
  },
  
  [UPDATE_OPTIONS] (state: DomainsModalState, option: Options): void {
    state.options = { ...state.options, ...option };
  }
};

const actions: ActionTree<DomainsModalState, RootState> = {
  async [ADD_DOMAIN] ({ state: { options: input }, commit }): Promise<void> {
    const addedDomains = await DomainsService.addDomain(input);
    commit("webmaster/domains/LOCAL_ADD", { items: addedDomains, target: "domains" }, { root: true });
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [UPDATE_OPTIONS] ({ commit }, options: Options): void {
    commit(UPDATE_OPTIONS, options);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const domainModal: Module<DomainsModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
