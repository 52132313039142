import { FinancesService } from "@core/services/webmaster/finances/FinancesService";
import { GET_FINANCES, REJECT_COST } from "@core/store/action-constants";
import { SET_EMPTY, SET_FINANCES, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { FinancesFilters, FinancesState, OrderOutTransaction } from "@core/store/types/webmaster/finances";
import { ActionTree, Module, MutationTree } from "vuex";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import { Pagination } from "@core/store/types/common";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import orderOutTransaction from "@core/services/webmaster/finances/graphql/orderOutTransaction.graphql";
import { requisites } from "@core/store/modules/webmaster/profile/modules/requisites";

const initialState = (): FinancesState => {
  return {
    data: null,
    filters: {
      datepicker: {
        dateStart: null,
        dateEnd: datepicker().dateEnd
      },
      paymentSystemId: null,
      status: null
    }
  };
};

const state: () => FinancesState = initialState;

const mutations: MutationTree<FinancesState> = {
  [SET_FINANCES]: (state: FinancesState, data: FinancesState["data"]): void => {
    state.data = data;
  },
  
  [UPDATE_FILTERS]: (state: FinancesState, filters: FinancesFilters): void => {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state, target?: keyof FinancesState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<FinancesState, RootState> = {
  async [GET_FINANCES] ({ state, commit }): Promise<void> {
    const { limit, offset } = pagination();
    const { datepicker, ...filters } = state.filters;

    const { data: { webmasterTransactions } } = await FinancesService.getFinances(
      limit,
      offset,
      {
        ...datepicker,
        ...filters
      }
    );
      
    commit(SET_FINANCES, webmasterTransactions);
  },
  
  async ORDER_PAYMENT ({ commit }, payload: OrderOutTransaction): Promise<void> {
    const { data: { orderOutTransaction } } = await FinancesService.addFinances(payload);
    commit("LOCAL_ADD", { items: orderOutTransaction });
  },
  
  async [REJECT_COST] ({ commit }, transactionId: string): Promise<void> {
    const { data: { cancelTransaction } } = await FinancesService.rejectCost(transactionId);
    const newCancelTransaction = { ...cancelTransaction, status: cancelTransaction.status.toUpperCase() };
    commit("LOCAL_UPDATE", { items: newCancelTransaction });
  },
  
  [UPDATE_FILTERS] ({ commit }, filters: FinancesFilters): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const finances: Module<FinancesState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    paymentSystemsList,
    requisites
  }
};
