import { GET_RESPONSE_TYPES } from "@core/store/action-constants";
import { UPDATE_RESPONSE_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ResponseTypesListState } from "@core/store/types/admin/crm/lists/ResponseTypesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): ResponseTypesListState => {
  return {
    data: {}
  };
};

const state: () => ResponseTypesListState = initialState;

const getters: GetterTree<ResponseTypesListState, RootState> = {};

const mutations: MutationTree<ResponseTypesListState> = {
  [UPDATE_RESPONSE_TYPES_LIST] (state, responseTypes: ResponseTypesListState["data"]) {
    state.data = responseTypes;
  }
};

const actions: ActionTree<ResponseTypesListState, RootState> = {
  [GET_RESPONSE_TYPES] ({ commit }) {
    const items = [
      {
        label: "admin.crm.modal.labels.integrationDetail.xml",
        value: "XML"
      },
      {
        label: "admin.crm.modal.labels.integrationDetail.json",
        value: "JSON"
      },
      {
        label: "admin.crm.modal.labels.integrationDetail.emptyResponseTypes",
        value: null
      }
    ];
    commit(UPDATE_RESPONSE_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const responseTypesList: Module<ResponseTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
