import { State as StateWebmasters } from "@core/store/types/admin/offer/webmasters";
import { OfferWebmastersService } from "@core/services/common/offer/webmasters";
import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_FILTERS,
  UPDATE_PAGINATION,
  UPDATE_OFFER_WEBMASTERS
} from "@core/store/mutation-constants";
import { Module } from "@core/store/logic/Module";

export class WebmasterTableOfferModule<S extends StateWebmasters, R> extends Module<S, R> {
  constructor () {
    super();

    this.mutations({
      [UPDATE_OFFER_WEBMASTERS] (state, data) {
        state.data = { ...state.data, ...data };
      },

      [UPDATE_FILTERS] (state, filters) {
        state.filters = { ...state.filters, ...filters };
      },

      [UPDATE_PAGINATION] (state, pagination) {
        state.pagination = { ...state.pagination, ...pagination };
      }
    });

    this.actions({
      async [GET_OFFER_WEBMASTERS] ({ commit, state, rootGetters }, offerId) {
        // @ts-ignore
        const { pagination: { page, perPage: limit }, filters } = state;
        const offset = (page - 1) * limit;

        const { data: { offer: { webmasters } } } = await OfferWebmastersService.getOfferWebmasters(offerId, filters, rootGetters.role, limit, offset);
        commit(UPDATE_OFFER_WEBMASTERS, webmasters);
      },

      [UPDATE_FILTERS] ({ commit }, filter) {
        commit(UPDATE_FILTERS, filter);
        commit(UPDATE_PAGINATION, { page: 1 });
      },

      [UPDATE_PAGINATION] ({ commit }, pagination) {
        commit(UPDATE_PAGINATION, pagination);
      },

      [SET_EMPTY] ({ commit }): void {
        commit(SET_EMPTY);
      }
    });

    return this;
  }
}
