import { mutation, query } from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";
import { DocumentNode } from "graphql";

import gql from "graphql-tag";
import _forEach from "lodash/forEach";
import _groupBy from "lodash/groupBy";
import _intersection from "lodash/intersection";
import _union from "lodash/union";
import _without from "lodash/without";

type QueryParam = string | Record<string, any>;

interface QueryParams {
  include?: string[];
  exclude?: string[];
}

interface GeneratedOutput {
  variables: any;
  query: string;
}

export interface ASTGeneratedOutput {
  variables: any;
  query: DocumentNode;
}

function _unfold (
  data: string[][]
): QueryParam[] {
  return Object
    .entries<string[][]>(
    // Группируем данные по первому значению
    // [
    //   ['a', 'a']
    //   ['a', 'b']
    // ]
    // =>
    // {
    //   a: [
    //     ['a', 'a']
    //     ['a', 'b']
    //   ]
    // }
    // https://lodash.com/docs#groupBy
    _groupBy(data, (o: string[]) => o[0])
  )
    .reduce(
      (array: QueryParam[], [key, value]: [string, string[][]]) => {
        value = value
          .reduce(
            (array: string[][], el: string[]) => {
              if (Array.isArray(el)) {
                // Убираем первое значение из массива,
                // так как оно идентично группировке
                el = el.slice(1);
                
                if (el.length) {
                  array.push(el);
                }
              }
        
              return array;
            }, []
          );
  
        if (value.length) {
          array.push({
            [key]: _unfold(value)
          });
        } else {
          array.push(key);
        }

        return array;
      }, []
    );
}

function _fold (
  data: QueryParam[],
  prefix: string | null
): string[] {
  return data
    .reduce(
      (array: string[], el: QueryParam) => {
        if (typeof el === "string") {
          array.push([prefix, el].filter(Boolean).join("/"));
        } else if (Object.prototype.toString.call(el) === "[object Object]") {
          _forEach(
            Object.entries(el),
            ([key, value]: [string, QueryParam[]]) => {
              array.push(..._fold(value, [prefix, key].filter(Boolean).join("/")));
            }
          );
        }

        return array;
      }, []
    );
}

function unfold (
  data: string[]
): QueryParam[] {
  return _unfold(
    data
      .filter(Boolean)
      .map((o: string) => o.split("/"))
  );
}

function fold (
  data: QueryParam[]
): string[] {
  return _fold(
    data
      .filter(Boolean),
    null
  );
}

function include (
  target: QueryParam[],
  source: string[]
): QueryParam[] {
  return unfold(
    _union(
      fold(target),
      source
    )
  );
}

function exclude (
  target: QueryParam[],
  source: string[]
): QueryParam[] {
  return unfold(
    _without(
      fold(target),
      ...source
    )
  );
}

function modifyFields (
  option: IQueryBuilderOptions,
  params: QueryParams
): IQueryBuilderOptions {
  const { include: includeParams, exclude: excludeParams } = params;

  if (includeParams) {
    option.fields = include(option?.fields ?? {} as QueryParam[], includeParams);
  }
  
  if (excludeParams) {
    option.fields = exclude(option?.fields ?? {} as QueryParam[], excludeParams);
  }
  return option;
}

export class Generator {
  public static query (
    options: IQueryBuilderOptions | IQueryBuilderOptions[],
    params?: QueryParams
  ): ASTGeneratedOutput {
    return new Generator("query", options, params) as ASTGeneratedOutput;
  }
  
  public static mutation (
    options: IQueryBuilderOptions | IQueryBuilderOptions[],
    params?: QueryParams
  ): ASTGeneratedOutput {
    return new Generator("mutation", options, params) as ASTGeneratedOutput;
  }
  
  private constructor (
    type: "query" | "mutation",
    options: IQueryBuilderOptions | IQueryBuilderOptions[],
    params?: QueryParams
  ) {
    if (params) {
      const { include, exclude } = params;
      
      if (
        include?.length &&
        exclude?.length &&
        _intersection(include, exclude)?.length
      ) {
        throw new Error("\"include\" and \"exclude\" params can't have the same values");
      }
  
      if (Array.isArray(options)) {
        options = options.map(
          option => modifyFields(option, params)
        );
      } else {
        options = modifyFields(options, params);
      }
    }
    
    let generator: GeneratedOutput;
    
    switch (type) {
      case "query":
        generator = query(options);
        break;
      case "mutation":
        generator = mutation(options);
        break;
    }
  
    return {
      ...generator,
      query: gql(generator.query)
    } as ASTGeneratedOutput;
  }
}
