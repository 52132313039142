import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import advertiserPersonalManagerQuery from "./graphql/advertiserPersonalManager.graphql";
import profile from "./graphql/profile.graphql";
import resendEmail from "@core/services/common/graphql/resendEmail.graphql";
import editAdvertiserProfile from "./graphql/editAdvertiserProfile.graphql";
import {
  AdvertiserProfileResponse,
  AdvertiserProfileEditResponse,
  AdvertiserProfileEditInput
} from "@core/store/types/advertiser/profile";
import { Manager as ManagerConstructor } from "@core/logic/user/Manager";
 

export class ProfileService extends Service {
  public static getProfile (): AxiosPromise<AdvertiserProfileResponse> {
    return this.api.get("", {
      params: {
        query: profile
      }
    });
  }

  public static editProfile (input: AdvertiserProfileEditInput): AxiosPromise<AdvertiserProfileEditResponse> {
    return this.api.post("", {
      query: editAdvertiserProfile,
      variables: {
        input
      }
    });
  }
    
  public static async getPersonalManager (): Promise<ManagerConstructor> {
    try {
      const { data: { advertiserPersonalManager } } = await this.api.get("", {
        params: {
          query: advertiserPersonalManagerQuery
        }
      });
      return advertiserPersonalManager;
    } catch (e) {
      throw e;
    }
  }

  public static resendEmail (): AxiosPromise {
    return this.api.post("", {
      query: resendEmail
    });
  }
}
