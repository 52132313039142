import {
  ACCEPT_COST,
  AUTO_COST, GET_COSTS,
  PROCESS_COST,
  PROXY_COST,
  REJECT_COST,
  UPDATE_COSTS_TYPE
} from "@core/store/action-constants";
import { SET_COSTS, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ConfirmPayoutModalState } from "@core/store/types/admin/finances/financesCosts/ConfirmPayoutModalState";
import { CostsListItem } from "@core/store/types/admin/finances/financesCosts/financesCosts";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): ConfirmPayoutModalState => {
  return {
    costs: null,
    costsType: null,
    isConfirmPayoutModalActive: false
  };
};

const state: () => ConfirmPayoutModalState = initialState;

const mutations: MutationTree<ConfirmPayoutModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [SET_COSTS] (state, costs: CostsListItem[]) {
    state.costs = costs;
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isConfirmPayoutModalActive = payload;
  },

  [UPDATE_COSTS_TYPE] (state, payload: string) {
    state.costsType = payload;
  }
};

const actions: ActionTree<ConfirmPayoutModalState, RootState> = {
  async [PROXY_COST] ({
    state,
    dispatch
  }) {
    const transactionIds = state.costs?.map(cost => cost?.id);
    if (transactionIds) {
      switch (state.costsType) {
        case "auto":
          await dispatch(`admin/finances/costs/${ AUTO_COST }`, transactionIds, { root: true });
          dispatch(`admin/finances/costs/${ GET_COSTS }`, {}, { root: true });
          break;
        case "created":
          await dispatch(`admin/finances/costs/${ PROCESS_COST }`, transactionIds, { root: true });
          break;
        case "accept":
          await dispatch(`admin/finances/costs/${ ACCEPT_COST }`, transactionIds, { root: true });
          break;
        case "reject":
          await dispatch(`admin/finances/costs/${ REJECT_COST }`, transactionIds, { root: true });
          break;
      }

    }
  },

  [SET_COSTS] ({ commit }, payload: CostsListItem[]) {
    commit(SET_COSTS, payload);
  },

  [UPDATE_COSTS_TYPE] ({ commit }, payload: string) {
    commit(UPDATE_COSTS_TYPE, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const confirmPayoutModal: Module<ConfirmPayoutModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
