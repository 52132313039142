import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { globalSource } from "@core/filters";
import Vue from "vue";
import {
  SET_EMPTY_SUB_ANALYTIC,
  SET_SUB_ANALYTIC,
  UPDATE_SUB_ANALYTIC,
  SET_WEBMASTERS, UPDATE_FILTERS,
  CREATE_INITIAL_STRUCTURE
} from "@core/store/mutation-constants";
import {
  GET_SUB_ANALYTIC_BY_INDEX,
  GET_SUB_ANALYTIC_ANALYSE,
  GET_SUB_ANALYTIC,
  GET_WEBMASTERS,
  SET_EMPTY, UPDATE_FIELDS
} from "@core/store/action-constants";
import {
  NullablePaginationAdminSubAnalyticState,
  AnalyticItem,
  SubAnalytic
} from "@core/store/types/admin/analytic/analytic";
import { WebmastersListResponse } from "@core/store/types/admin/offer/lists/OfferEditWebmastersList";
import { datepickerFormat } from "@core/helpers/datepicker";
import { fixedFormatPercent } from "@core/flowMethods";
import _uniqueId from "lodash/uniqueId";

const initialState = (): NullablePaginationAdminSubAnalyticState => {
  return {
    webmasters: null,
    subAnalytic: {}
  };
};

const state: () => NullablePaginationAdminSubAnalyticState = initialState;

const mutations: MutationTree<NullablePaginationAdminSubAnalyticState> = {
  [CREATE_INITIAL_STRUCTURE] (state, uniqueKey) {
    Vue.set(state.subAnalytic, uniqueKey, { data: null, filters: {}, fields: {} });
  },

  [SET_EMPTY_SUB_ANALYTIC] (state, payload: { uniqueKey: number; }): void {
    const { uniqueKey } = payload;

    if (state.subAnalytic[uniqueKey]) {
      Vue.set(state.subAnalytic, uniqueKey, undefined);
    }
  },

  [SET_SUB_ANALYTIC] (state, { uniqueKey, data }: { uniqueKey: number; data: SubAnalytic; }): void {
    if (state.subAnalytic[uniqueKey]?.data) {
      data = {
        count: data.count,
        items: state.subAnalytic[uniqueKey].data.items.concat(data.items)
      };
    }

    Vue.set(state.subAnalytic[uniqueKey], "data", data);
  },
  
  [UPDATE_SUB_ANALYTIC] (state, { uniqueKey, data }): void {
    state.subAnalytic[uniqueKey].data = data;
  },
  
  [SET_WEBMASTERS]: (state, webmasters: WebmastersListResponse["webmasters"]): void => {
    state.webmasters = webmasters;
  },

  [UPDATE_FILTERS] (state, { data, uniqueKey, currentGroup }) {
    Object.assign(state.subAnalytic[uniqueKey].filters, { ...data, currentGroup });
  },

  [UPDATE_FIELDS] (state, { data, uniqueKey }) {
    Object.assign(state.subAnalytic[uniqueKey].fields, data);
  },

  [SET_EMPTY] (state, uniqueKey) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { webmasters, ...emptyFields } = initialState();
    if (uniqueKey) {
      delete state.subAnalytic[uniqueKey];
    } else {
      Object.assign(state, emptyFields);
    }
  }
};

const actions: ActionTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  async [GET_SUB_ANALYTIC] ({ dispatch, commit, state, rootState }, uniqueKey: string): Promise<void> {
    const subAnalytic = state.subAnalytic[uniqueKey];
    const { currentGroup, ...groupFiltersData } = subAnalytic.filters ?? {};

    const { admin: { analytic: { filters: analyticFilters, options: { analysisMode } } }, verticals: { currentVertical: vertical } } = rootState;
    const { groups: analyticGroups, threshold, datepicker, registeredDatepicker, ...otherAnalyticFilters } = analyticFilters;
    const secondAnalyticGroup = analyticGroups[1];

    const { global_source, actual_webmaster_id: actualWebmasterIntId, ...fields } = groupFiltersData;
    const groupFiltersAnalytic = { ...fields, globalSource: globalSource(global_source), actualWebmasterIntId };

    let data;
    const limit = 10;
    const offset = subAnalytic.data?.items.length ?? 0;
    const filters = {
      ...datepickerFormat(datepicker, "date", true),
      ...registeredDatepicker,
      ...otherAnalyticFilters,
      vertical
    };
    
    if (analysisMode) {
      const { offerId, webmasterId } = groupFiltersData;
      const contextId = offerId ?? webmasterId;
      const params = { limit, offset, group: currentGroup, webmasterId, contextId, threshold, filters };
      
      data = await dispatch(GET_SUB_ANALYTIC_ANALYSE, params);
    } else {
      const { data: { analytic } } = await AnalyticService.getAnalytic(
        limit,
        offset,
        {
          ...filters,
          ...groupFiltersAnalytic
        },
        currentGroup,
        secondAnalyticGroup
      );
      data = analytic;
    }

    data.items = data.items.map((element: AnalyticItem) => ({ uniqueKey: _uniqueId(`${ element.group?.id ?? element.group }_`), ...element }));

    commit(SET_SUB_ANALYTIC, { uniqueKey, data });
  },
  
  async [GET_SUB_ANALYTIC_ANALYSE] ({ state }, { limit, offset, group, webmasterId, contextId, threshold, filters }) {
    const { data: { analyticAnalyse } } =
      await AnalyticService.getAnalyticAnalyse(limit, offset, group, contextId, threshold, filters);
    
    const { webmasters } = state;
    
    const data = analyticAnalyse.items?.map(({ group, total, items }) => {
      const userId = webmasterId ?? group.id;
      const hasOurWebmaster = webmasters?.items?.some(item => item.id === userId);
      
      const innerItems = items.map(item => {
        const {
          statistics: { countLeadsIntegrated: statisticLeadsIntegrated, ...otherStatisticFields },
          difference: { countLeadsIntegrated: differenceLeadsIntegrated },
          ...fields
        } = item;
        
        const yesterday = differenceLeadsIntegrated * -1 + statisticLeadsIntegrated;
        const difference = differenceLeadsIntegrated !== 0 && differenceLeadsIntegrated === statisticLeadsIntegrated ?
          1 : statisticLeadsIntegrated / yesterday - 1;
        const differencePercent = fixedFormatPercent(difference);
  
        return {
          differencePercent,
          hasOurWebmaster,
          difference,
          ...fields,
          ...otherStatisticFields,
          countLeadsIntegrated: statisticLeadsIntegrated
        };
      });
      
      return { group, total, items: innerItems };
    });
    
    return { items: data, count: analyticAnalyse.count };
  },
  
  async [GET_WEBMASTERS] ({ commit, rootState }) {
    const { admin: { profile: { user: { id: manager } } } } = rootState;
    
    const { data: { webmasters } } = await AnalyticService.getWebmastersIds({ manager });
    commit(SET_WEBMASTERS, webmasters);
  },

  [UPDATE_FILTERS] ({ commit }, data) {
    commit(UPDATE_FILTERS, data);
  },

  [UPDATE_FIELDS] ({ commit }, data) {
    commit(UPDATE_FIELDS, data);
  }
};

const getters: GetterTree<NullablePaginationAdminSubAnalyticState, RootState> = {
  [GET_SUB_ANALYTIC_BY_INDEX]: state => (uniqueKey: string | number) => (state.subAnalytic || {})[uniqueKey]
};

export const subAnalytic: Module<NullablePaginationAdminSubAnalyticState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
