


























import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
import CompactList from "@/components/Common/Lists/CompactList.vue";
import ContactOutput from "@/components/Common/Output/ContactOutput.vue";
interface Props {
  contacts: any;
}
const __sfc_main = {};
__sfc_main.props = {
  contacts: {
    key: "contacts",
    required: false,
    type: null,
    default: []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const _contacts = computed(() => {
    const preparedContacts = [];
    Object.keys(props.contacts).forEach(key => {
      if (props.contacts[key] && key !== "email") {
        preparedContacts.push({
          type: key,
          value: props.contacts[key]
        });
      }
    });
    return preparedContacts;
  });
  return {
    _contacts
  };
};
__sfc_main.components = Object.assign({
  CompactList,
  ContactOutput
}, __sfc_main.components);
export default __sfc_main;
