import {
  TrafficRequestCreateModalInput,
  TrafficRequestUnificationItem
} from "@core/store/types/admin/traffic/trafficRequest";
import momentjs from "moment/moment";

export function patchInputData (rowData: TrafficRequestUnificationItem) {
  return (state: TrafficRequestCreateModalInput) => {
    state.type = rowData.type;
    state.offerId = rowData.offer.id;
    state.webmasterId = rowData.webmaster.id;
    state.externalWebmaster = rowData.externalWebmaster;
    state.recommendation = {
      audience: rowData.audience!,
      landingLink: rowData.landing!,
      trafficSource: rowData.trafficSource!,
      datepicker: {
        periodDateStart: rowData.periodStartDate!,
        periodDateEnd: rowData.periodEndDate!
      },
      comment: rowData.comment
    };
    state.newTerms = {
      newCapacity: rowData.newCapacity,
      newPayout: rowData.newPayout,
      datepicker: {
        periodDateStart: rowData.periodStartDate!,
        periodDateEnd: rowData.periodEndDate!
      },
      countHoursToApply: momentjs(rowData.expireDate).diff(momentjs(rowData.createdAt), "hours"),
      comment: rowData.comment
    };
    state.growthTerms = {
      newCapacity: rowData.newCapacity,
      newPayout: rowData.newPayout,
      comment: rowData.comment
    };
  };
}