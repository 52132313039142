
import {
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { GET_SELECTED_OFFERS } from "@core/store/action-constants";
import { StatisticsRatingService } from "@core/services/admin/statistic/statistic/StatisticsRatingService";
import {
  StatisticsOffersRatingModalState
} from "@core/store/types/admin/statistic/statistics/StatisticsOffersRatingModalState";

const initialState = (): StatisticsOffersRatingModalState => {
  return {
    offers: null,
    isModalActive: false
  };
};

const state: () => StatisticsOffersRatingModalState = initialState;

const mutations: MutationTree<StatisticsOffersRatingModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isModalActive = payload;
  },

  [GET_SELECTED_OFFERS] (state, offers) {
    state.offers = offers;
  }
};

const actions: ActionTree<StatisticsOffersRatingModalState, RootState> = {
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  async [GET_SELECTED_OFFERS] ({ commit, rootState }) {
    const { selected } = rootState.snackbarActions;
    const vertical = rootState.verticals.currentVertical;
    const ids = selected.map(({ offer: { id } }: { offer: {id: string } }) => id);
    const offers = await StatisticsRatingService.getSelectedOffers(
      { id: ids, vertical },
      ids.length,
      0
    );
    commit(GET_SELECTED_OFFERS, offers);
  }
};

export const statisticsOffersRatingModal: Module<StatisticsOffersRatingModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
