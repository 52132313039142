<template>
    <div class="social-network-links mr-3 ml-3">
        <LTooltip
            v-if="$saas.telegram_bot_link"
            :label="$t(`common.navBar.socials.telegramBot`)"
            position="is-bottom"
            type="is-dark">
            <a
                :href="$saas.telegram_bot_link"
                target="_blank"
                class="mr-2">
                <img
                    src="@/assets/NavigationBar/socials/telegram-bot.svg"
                    alt="">
            </a>
        </LTooltip>
        <b-dropdown
            v-if="socials.length"
            :triggers="['hover']"
            aria-role="list">
            <template #trigger>
                <b-button>
                    <img
                        src="@/assets/NavigationBar/socials/socials.svg"
                        alt="">
                </b-button>
            </template>
            <b-dropdown-item
                :key="index"
                v-for="(item, index) in socials"
                aria-role="listitem"
                :index="index"
                :value="item">
                <a
                    :href="item.link"
                    target="_blank"
                    class="link-wrapper">
                    <img
                        :src="item.icon"
                        alt="">
                    {{ $t(item.title) }}
                </a>
            </b-dropdown-item>
        </b-dropdown>
        <ThemeToggle
            class="ml-2 mt-2"></ThemeToggle>
    </div>
</template>

<script>
  import ThemeToggle from "@/components/Common/NavigationBar/ThemeToggle.vue";
  import { mapState } from "vuex";

  export default {
    name: "SocialNetworkLinks",
    components: { ThemeToggle },
    computed: {
      ...mapState({
        role: ({ auth }) => auth.role
      }),

      socials (){
        const socials = {
          facebook: {
            icon: require("@/assets/NavigationBar/socials/facebook.svg"),
            title: "common.navBar.socials.facebook"
          },
          telegram: {
            icon: require("@/assets/NavigationBar/socials/telegram.svg"),
            title: "common.navBar.socials.telegram"
          },
          vk: {
            icon: require("@/assets/NavigationBar/socials/vk.svg"),
            title: "common.navBar.socials.vk"
          },
          instagram: {
            icon: require("@/assets/NavigationBar/socials/instagram.svg"),
            title: "common.navBar.socials.instagram"
          },
          linkedin: {
            icon: require("@/assets/NavigationBar/socials/linkedin.svg"),
            title: "common.navBar.socials.linkedin"
          }
        };
        return Object.keys(this.$saas.contacts).map(key => {
          if (this.$saas.contacts[key] && socials[key]) {
            return {
              ...socials[key],
              link: this.$saas.contacts[key]
            };
          }
        }).filter(Boolean);
      }
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .dropdown-content{
            width: 210px;
        }
    }

    .social-network-links, a{
        display: flex;
        position: relative;
        align-items: center;
    }

    .button{
        border: none;
        padding: 0;

        img{
            width: 26px;
            height: 26px;
        }
    }

    .link-wrapper{
        display:flex;
        align-items: center;
        justify-content: center;
        gap:10px;
        color: #212121;
    }
</style>