import { RootState } from "@core/store/root-state";
import { CoreWebmaster } from "@core/store/modules/webmaster";
import { SET_STATISTIC_DATEPICKER } from "@core/store/mutation-constants";
import moment from "moment";
export interface Datepicker {
  dateStart: Date;
  dateEnd: Date;
}

export interface WebmasterState {
  statisticDatepicker: Datepicker;
}
export class Webmaster extends CoreWebmaster<WebmasterState, RootState> {
  constructor () {
    super();
    
    this.mutations({
      [SET_STATISTIC_DATEPICKER]: (state, payload: Datepicker): void => {
        state.statisticDatepicker = payload;
      }
    });
    
    this.state({
      statisticDatepicker: {
        dateStart: moment().startOf("day").toDate(),
        dateEnd: moment().toDate()
      }
    });
    
    this.modules({});
  }
}
