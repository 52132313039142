























import { formatEmptyString } from "@core/filters";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
interface Props {
  value: any;
  domain: string | null;
  type: "telegram" | "skype" | "whatsApp" | "viber" | "email";
  hasIcon: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: true,
    type: null
  },
  domain: {
    key: "domain",
    required: false,
    type: [String, null],
    default: null
  },
  type: {
    key: "type",
    required: true,
    type: String
  },
  hasIcon: {
    key: "hasIcon",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const _value = props.type === "telegram" ? props.value?.replace(/^@/, "") : props.value;
  const dictionary = {
    telegram: "tg://resolve?domain={value}",
    whatsApp: "https://wa.me/{value}",
    skype: "skype:{value}?call",
    viber: "viber://chat?number=+{value}",
    email: "mailto:{value}"
  };
  const href = computed(() => props.domain ? props.domain.replace("{value}", _value) : dictionary[props.type].replace("{value}", _value));
  if (props.domain && props.domain.includes("{value}")) {
    throw new Error("The domain property does not include {value}");
  }
  return {
    formatEmptyString,
    props,
    href
  };
};
export default __sfc_main;
