import { OfferEditAdvertisersIndividualRateService } from "@core/services/admin/offer/edit/OfferEditAdvertisersIndividualRateService";
import {
  BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES,
  DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE,
  GET_OFFER_ADVERTISER_INDIVIDUAL_RATES,
  GET_OFFER_WEBMASTERS_REWARD,
  SET_EMPTY, GET_OFFER_ID,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF,
  UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE,
  UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES
} from "@core/store/action-constants";
import {
  SET_OFFER_ADVERTISER_INDIVIDUAL_RATES,
  UPDATE_LOADING,
  UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS,
  UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION,
  UPDATE_OFFER_WEBMASTERS_REWARD
} from "@core/store/mutation-constants";
import {
  OfferAdvertiserIndividualRate, PartialOfferAdvertiserIndividualRateState,
  UpdateIndividualRate
} from "@core/store/types/admin/offer/OfferAdvertiserIndividualRate.d";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import Vue from "vue";
import { Module } from "@core/store/logic/Module";
import { capitalizeFirstLetter } from "@core/filters";

export class AdvertisersIndividualRates<S extends PartialOfferAdvertiserIndividualRateState, R> extends Module<S, R> {
  constructor () {
    super();

    this.mutations({
      [SET_OFFER_ADVERTISER_INDIVIDUAL_RATES] (state, individualRates: PaginationOutput<OfferAdvertiserIndividualRate[]>) {
        state.individualRates = individualRates;
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES] (state, individualRates: PaginationOutput<OfferAdvertiserIndividualRate[]>) {
        if (state.individualRates) {
          state.individualRates = {
            count: individualRates.count,
            items: state.individualRates?.items?.concat(individualRates.items as OfferAdvertiserIndividualRate[])
          };
        }
      },

      [UPDATE_LOADING] (state, { index, params, value }) {
        if (state.individualRates?.items) {
          for (const key in params) {
            if (params.hasOwnProperty(key)) {
              const prop = `is${ capitalizeFirstLetter(key) }Loading`;
              Vue.set(state.individualRates.items[index], prop, value);
            }
          }

        }
      },

      [UPDATE_OFFER_WEBMASTERS_REWARD] (state, payload) {
        if (state.individualRates?.items) {
          const webmastersIds = state.individualRates.items.map(item => item.id);

          state.individualRates.items = state.individualRates.items.map((item, index) => {

            item.advertiserMinimalWriteOff = payload[webmastersIds[index]] || null;

            return item;
          });
        }
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE] (state, {
        payload,
        index
      }) {
        if (state.individualRates?.items) {
          Vue.set(state.individualRates.items, index, { ...state.individualRates.items[index], ...payload });
        }
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS] (state, filters) {
        state.filters = { ...state.filters, ...filters };
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION] (state, pagination) {
        state.pagination = { ...state.pagination, ...pagination };
      }
    });

    this.actions({
      SET_EMPTY ({ commit }) {
        commit(SET_EMPTY);
      },

      async [GET_OFFER_ADVERTISER_INDIVIDUAL_RATES] ({ state, rootGetters, commit }) {
        try {
          const { pagination, filters } = state;
          const limit = pagination.perPage;
          const offset = (pagination.page - 1) * limit;
          const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

          if (offerId) {
            const { data: { advertiserRates } } =
              await OfferEditAdvertisersIndividualRateService.getOfferAdvertisersIndividualRates(
                limit,
                offset,
                offerId,
                filters
              );
            advertiserRates.items = advertiserRates.items.map((item: OfferAdvertiserIndividualRate) => {
              return {
                ...item,
                writeOff: item.writeOff.value,
                isWriteOffLoading: false
              };
            });

            if (offset === 0) {
              commit(SET_OFFER_ADVERTISER_INDIVIDUAL_RATES, advertiserRates);
            } else {
              commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES, advertiserRates);
            }

            return advertiserRates;
          }
        } catch (e) {
          throw e;
        }

      },

      [BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES] ({ commit, dispatch, state }) {
        const { page, perPage } = state.pagination;
        commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION, {
          page: 1,
          perPage: perPage * page
        });
        dispatch(GET_OFFER_ADVERTISER_INDIVIDUAL_RATES);
        commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION, {
          page,
          perPage
        });
      },

      async [GET_OFFER_WEBMASTERS_REWARD] ({ state, rootGetters, commit }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          if (state.filters?.advertiserId && offerId) {
            const limit = state.pagination.perPage * state.pagination.page;

            const { data: { advertiserRates: { items } } } =
              await OfferEditAdvertisersIndividualRateService.getOfferWebmastersReward(
                limit,
                0,
                offerId,
                state.filters
              );
            const webmastersReward: { [key: string]: unknown } = {};
            items.forEach((item: OfferAdvertiserIndividualRate) => {
              webmastersReward[item.id] = item.advertiserMinimalWriteOff;
            });

            commit(UPDATE_OFFER_WEBMASTERS_REWARD, webmastersReward);
          }
        } catch (e) {
          throw e;
        }
      },

      async [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE] ({ state, rootGetters, commit, dispatch }, { params, index }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        commit(UPDATE_LOADING, { index, params, value: true });
        try {
          if (state.individualRates?.items && offerId) {
            const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
            const { id } = state.individualRates.items[index];
            const newRate: UpdateIndividualRate = {
              rate: params.writeOff || state.individualRates.items[index].writeOff
            };
            const { data: { editAdvertiserIndividualRate } } =
              await OfferEditAdvertisersIndividualRateService.editAdvertiserIndividualRate(id, newRate);

            dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              { offerId }, { root: true });
            dispatch(`admin/offer/edit/offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`,
              { offerId }, { root: true });
            dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              { offerId }, { root: true });
            commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE, {
              payload: {
                id: editAdvertiserIndividualRate.id,
                params
              },
              index
            });
            commit(UPDATE_LOADING, {
              index,
              params,
              value: false
            });
            return editAdvertiserIndividualRate;
          }
        } catch (e) {
          commit(UPDATE_LOADING, { index, params, value: false });
          throw e;
        }
      },

      async [DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE] ({ rootGetters, dispatch }, rateId) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          if (offerId && rateId) {
            const { data: { removeAdvertiserIndividualRate } } =
              await OfferEditAdvertisersIndividualRateService.removeAdvertiserIndividualRate(rateId);

            dispatch(`admin/offer/edit/webmastersInternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              { offerId }, { root: true });
            dispatch(`admin/offer/edit/offerEditAdvertisers/${ UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF }`,
              { offerId }, { root: true });
            dispatch(`admin/offer/edit/webmastersExternalRates/${ UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD }`,
              { offerId }, { root: true });
            return removeAdvertiserIndividualRate;
          }
        } catch (e) {
          throw e;
        }
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION] ({ commit }, pagination) {
        commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION, pagination);
      },

      [UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS] ({ commit }, filter) {
        commit(UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS, filter);
      }
    });

    return this;
  }
}
