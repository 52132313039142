// @vue/components

import { mapActions, mapGetters, mapState } from "vuex";
import {
  UPDATE_ANALYTIC_GROUP_FILTERS,
  UPDATE_ANALYTIC_GROUP_FIELDS,
  UPDATE_MODAL_ACTIVE
} from "@core/store/action-constants";
import { globalSource } from "@core/filters";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { isArray } from "lodash";

export const AnalyticTableViewGroupsDetails = {
  computed: {
    ...mapGetters(["role"]),

    isAdmin () {
      return this.role === "admin";
    },

    userType () {
      return this.isAdmin ? "admin" : "webmaster";
    },

    filters () {
      return this.$store[this.userType].analytic.filters;
    },

    calcCountryHeight () {
      const height = (this.rowIndex + 1) * 40 + 80;
      return Math.min(height, 200);
    },
    
    type () {
      return this.rowField.label.split(".")[0];
    },
    
    name () {
      return this.rowField.label.split(".")[1];
    },
  
    userId () {
      return this.formattedGetMethodByPath(`${ this.type }.id`);
    },
    
    intId () {
      return this.formattedGetMethodByPath("groups.intId");
    },
    
    user () {
      const login = this.formattedGetMethodByPath("groups.login");
      return login ? login : this.formattedGetMethodByPath("groups.id");
    },
    
    offer () {
      const name = this.formattedGetMethodByPath("groups.name");
      return name ? name : this.formattedGetMethodByPath("groups.id");
    },
  
    offerSlug () {
      return this.formattedGetMethodByPath("groups.slug");
    },
  
    category () {
      return this.hasArray(this.groups);
    },
    
    groups () {
      return this.getMethodByPath(this.rowData, "groups");
    },
  
    analysisMode () {
      return this.$store.state[this.userType].analytic.options.analysisMode;
    },
  
    groupIntIdOrId () {
      const groups = this.rowData.groups;
      if (typeof groups === "string") return groups;
      return groups && (groups.id ?? groups.intId ?? groups[0]?.id ?? groups);
    },
    
    globalSource () {
      const name = this.groups;
      const key = `common.entity.globalSource.${ name || "empty" }`;
      return this.$te(key) ? this.$t(key) : "-";
    }
  },
  
  methods: {
    async action (key, params) {
      await this.$store.dispatch(`${ this.userType }/analytic/analyticDetailModal/${ key }`, params);
    },
    
    openDetailModal () {
      const { datepicker } = this.filters;
      const { rowData: { groupFilters, groupFields }, groups, name } = this;
      let filtersParams;
      let fieldsParams;
      
      if (groupFilters && groupFields) {
        const { global_sourceId, ...filters } = groupFilters;
        filtersParams = { globalSource: globalSource(global_sourceId), ...filters };
        fieldsParams = groupFields;
      } else {
        filtersParams = { [`${ name }Id`]: groups.id };
        fieldsParams = { [name]: groups };
      }
      
      this.action("UPDATE_FILTERS", { datepicker });
      this.action("UPDATE_ANALYTIC_GROUP_FILTERS", filtersParams);
      this.action("UPDATE_ANALYTIC_GROUP_FIELDS", fieldsParams);
      this.action("UPDATE_MODAL_ACTIVE", true);
    },
  
    activateSubsection (value) {
      const { rowData, vuetable } = this;
      rowData.subsection = value === rowData.subsection ? null : value;
      
      if (rowData.subsection === null) {
        vuetable.hideDetailRow(rowData.id);
      } else {
        vuetable.showDetailRow(rowData.id);
      }
    },
    
    formattedGetMethodByPath (label) {
      return this.formatter(this.getMethodByPath(this.rowData, label));
    },
  
    hasArray (items) {
      if (isArray(items)) {
        return items;
      }
      return [];
    }
  }
};
