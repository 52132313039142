import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import editCapacityRequestQuery from "@core/services/admin/offers/graphql/editCapacityRequest.graphql";
import deleteCapacityRequestQuery from "@core/services/admin/offers/graphql/deleteCapacityRequest.graphql";
import approveCapacityRequestQuery from "@core/services/admin/offers/graphql/approveCapacityRequest.graphql";
import rejectCapacityRequestQuery from "@core/services/admin/offers/graphql/rejectCapacityRequest.graphql";
import {
  CreateCapacityRequestResponse,
  CapacityRequestInput,
  EditCapacityRequestResponse,
  DeleteCapacityRequestResponse,
  ApproveCapacityRequestInput, ApproveCapacityRequestResponse, RejectCapacityRequestResponse
} from "@core/store/types/admin/offers/capacity";
import CreateCapacityRequestGenerator from "@core/services/admin/offers/graphql/createCapacityRequest.generator";

export class CapacityRequestService extends Service {
  public static createCapacityRequest (
    input: CapacityRequestInput
  ): AxiosPromise<CreateCapacityRequestResponse> {
    try {
      return this.api.post("", CreateCapacityRequestGenerator(input));
    }
    catch (e) {
      throw e;
    }
  }
  
  public static editCapacityRequest (
    input: CapacityRequestInput,
    id: string
  ): AxiosPromise<EditCapacityRequestResponse> {
    try {
      return this.api.post("", {
        query: editCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static deleteCapacityRequest (
    id: string
  ): AxiosPromise<DeleteCapacityRequestResponse> {
    try {
      return this.api.post("", {
        query: deleteCapacityRequestQuery,
        variables: {
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static approveCapacityRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): AxiosPromise<ApproveCapacityRequestResponse> {
    try {
      return this.api.post("", {
        query: approveCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
  
  public static rejectCapacityRequest (
    input: ApproveCapacityRequestInput,
    id: string
  ): AxiosPromise<RejectCapacityRequestResponse> {
    try {
      return this.api.post("", {
        query: rejectCapacityRequestQuery,
        variables: {
          input,
          id
        }
      });
    }
    catch (e) {
      throw e;
    }
  }
}
