import Service from "@core/services/common/Service";
import editAdminQuery from "./graphql/editAdmin.graphql";
import createAdminQuery from "./graphql/createAdmin.graphql";
import changeAdminPassword from "./graphql/changeAdminPassword.graphql";
import {
  EditInput,
  EditResponse,
  CreateResponse,
  AdministratorsPasswordEditInput,
  AdministratorsPasswordEditResponse
} from "@core/store/types/admin/users/administrators/Edit";

export class AdministratorsEditService extends Service {
  public static async editAdmin (adminId: string, input: EditInput): Promise<EditResponse> {
    try {
      const { data: { editAdmin } } = await this.api.post("", {
        query: editAdminQuery,
        variables: {
          adminId,
          input
        }
      });
      
      return editAdmin;
    } catch (e) {
      throw e;
    }
  }

  public static async createAdmin (input: EditInput): Promise<CreateResponse> {
    try {
      const { data: { createAdmin } } = await this.api.post("", {
        query: createAdminQuery,
        variables: {
          input
        }
      });
    
      return createAdmin;
    } catch (e) {
      throw e;
    }
  }

  public static async editPassword (adminId: string, input: AdministratorsPasswordEditInput): Promise<AdministratorsPasswordEditResponse> {
    try {
      return await this.api.post("", {
        query: changeAdminPassword,
        variables: {
          adminId,
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
