<template>
    <div>
        <slot
            v-if="role === 'webmaster'"
            name="webmaster"></slot>
        <slot
            v-else-if="role === 'advertiser'"
            name="advertiser"></slot>
        <slot
            v-else-if="role === 'admin'"
            name="admin"></slot>
        <div
            v-else
            class="loader-wrapper">
            <LLoading
                :active="true"
                :is-full-page="false"></LLoading>
        </div>
    </div>
</template>

<script>
  import LLoading from "@/components/Common/LLoading";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { UPDATE_MODAL_OPTIONS } from "@core/store/action-constants";

  export default {
    name: "Role",
    components: { LLoading },

    created () {
      this.setAvatarUrl();
    },

    updated () {
      this.setAvatarUrl();
    },

    computed: {
      ...mapState({
        role: state => state.auth.role
      }),

      ...mapGetters([
        "user"
      ])
    },

    methods: {
      ...mapActions("userAvatarModal", {
        UPDATE_MODAL_OPTIONS
      }),

      setAvatarUrl () {
        const avatarUrl = this.user.avatar?.url ?? require("@/assets/Avatar.svg");
        this.UPDATE_MODAL_OPTIONS({ avatarUrl });
      }
    }
  };
</script>

<style lang="scss" scoped>
    .loader-wrapper {
        width: 100%;
        height: 100%;
        margin: auto;
        min-width: 60px;
        min-height: 60px;
        position: relative;
    }
</style>
