import competitionRating from "@/router/webmaster/competitionRating";
import finances from "@/router/webmaster/finance";
import subAccounts from "@/router/webmaster/subAccounts";
import domains from "@/router/webmaster/domains";
import discounts from "@/router/webmaster/discounts";
import referrals from "@/router/webmaster/referrals";
import flows from "@/router/webmaster/flows";
import offers from "@/router/webmaster/offers";
import profile from "@/router/webmaster/profile";
import statistic from "@/router/webmaster/statistic";
import { RouteConfig } from "vue-router/types/router";

import Full from "@/views/Full.vue";

export const webmaster: RouteConfig = {
  path: "/webmaster",
  name: "webmaster",
  component: Full,
  children: [
    ...statistic,
    ...offers,
    ...flows,
    ...finances,
    ...competitionRating,
    ...profile,
    ...subAccounts,
    ...referrals,
    ...domains,
    ...discounts
  ]
};
