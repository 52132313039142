import { OfferLandingsFilters, OfferLandingsState } from "@core/store/types/common/offerLandings";
import { OfferLanding } from "@core/store/logic/common/offer/OfferLanding";
import { RootState } from "@core/store/root-state";
import { ADD_OFFER_CONVERSION, SET_EMPTY } from "@core/store/action-constants";

export class AdvertiserOfferLandings extends OfferLanding<OfferLandingsState, RootState> {
  private readonly initState: () => OfferLandingsState;
  
  constructor (pageType: OfferLandingsFilters["pageType"]) {
    super();
    
    this.namespaced = true;
    
    this.initState = (): OfferLandingsState => {
      return {
        landings: {
          items: [],
          count: 0
        },
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {
          pageType
        }
      };
    };
    
    this.state(this.initState());
    
    this.actions({
      [ADD_OFFER_CONVERSION] ({ commit, rootState }): void {
        const offerConversionList = rootState.advertiser.offer.offerConversionList.offerConversion;
        commit(ADD_OFFER_CONVERSION, offerConversionList);
      }
    });
    
    this.mutations({
      [SET_EMPTY]: (state: OfferLandingsState): void => {
        Object.assign(state, this.initState());
      }
    });
    
    return this;
  }
}
