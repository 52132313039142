

































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "@vue/composition-api";
import OfferDisclaimer from "@/components/Common/Offer/OfferDisclaimer/OfferDisclaimer.vue";
import { offerDisclaimerModal } from "@/stores/common/offer/OfferDisclaimerModal";
import storeInstance from "@/store";
import { useWait } from "@/stores/common/waitStore";
import router from "@/router";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    asAdmin
  } = storeInstance.getters;
  const role = storeInstance.state.auth.role;
  const store = offerDisclaimerModal();
  const {
    isActive,
    isAcceptOfferDisclaimer
  } = storeToRefs(store);
  const viewOnly = computed(() => {
    return role !== "webmaster" || isAcceptOfferDisclaimer.value || asAdmin;
  });
  const viewed = ref(false);
  const isAccepted = ref(false);
  const isLoading = useWait(store, "ACCEPT_OFFER_DISCLAIMER");
  watch(() => isActive.value, value => {
    viewed.value = false;
  });
  async function acceptOfferDisclaimer() {
    try {
      await store.ACCEPT_OFFER_DISCLAIMER();
      isActive.value = false;
    } catch (e) {
      throw e;
    }
  }
  function onCancel() {
    isActive.value = false;
    if (role === "webmaster" && !isAcceptOfferDisclaimer.value && !asAdmin) {
      router.push({
        name: "dashboard"
      });
    }
  }
  return {
    isActive,
    viewOnly,
    viewed,
    isAccepted,
    isLoading,
    acceptOfferDisclaimer,
    onCancel
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  OfferDisclaimer
}, __sfc_main.components);
export default __sfc_main;
