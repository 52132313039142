import copyLeads from "@core/services/admin/statistic/detailing/graphql/copyLeads.graphql";
import Service from "@core/services/common/Service";
import { AxiosResponse } from "axios";
import { DetailingCopyLeadsForm } from "@core/store/types/admin/statistic/detailing/modal/detailingCopyLeadsModal";

export class DetailingCopyLeadsModalService extends Service {
  public static async copyLeads (offerId: DetailingCopyLeadsForm["offerId"], leadIds: string[]): Promise<AxiosResponse> {
    try {
      const { data: { copyLeads: data } } = await this.api.get("", {
        params: {
          query: copyLeads,
          variables: {
            input: {
              offerId,
              leadIds
            }
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}
