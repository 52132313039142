<template>
    <b-field
        class="required-field"
        :custom-class="customClass"
        v-bind="$attrs"
        v-on="$listeners">
        <template #label>
            <slot name="label">
                {{ label }}
            </slot>
        </template>
        <slot></slot>
    </b-field>
</template>

<script>
  export default {
    name: "RequiredField",

    props: {
      label: {
        type: String,
        default: ""
      },

      required: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      customClass () {
        return `required-field__label ${ this.required ? "required-field__label--required" : "" }`;
      }
    }
  };
</script>

<style lang="scss">
    .required-field {
        &__label {
            position: relative;
            display: inline-flex !important;
            align-items: center;
            column-gap: 0.4rem;

            &--required:after {
                content: "";
                position: absolute;
                top: 5px;
                right: -7px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $danger;
            }
        }
    }
</style>