import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface AdBlockState {
  isRequestBlocked: boolean;
  isActive: boolean;
}

const state: AdBlockState = {
  isRequestBlocked: false,
  isActive: false
};


const getters: GetterTree<AdBlockState, RootState> = {};

const mutations: MutationTree<AdBlockState> = {
  SET_IS_ACTIVE: (state, isActive: boolean): void => {
    state.isActive = isActive;
  },
  SET_IS_REQUEST_BLOCKED: (state, isRequestBlocked: boolean): void => {
    state.isRequestBlocked = isRequestBlocked;
  }
};

const actions: ActionTree<AdBlockState, RootState> = {};


export const adblock: Module<AdBlockState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
