import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import landingParameters from "./graphql/landingParametersList.graphql";

export class LandingParametersService extends Service {
  public static getLandingParameters (): AxiosPromise {
    return this.api.get("", {
      params: {
        query: landingParameters
      }
    });
  }
}
