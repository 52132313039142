import Service from "@core/services/common/Service";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticResponse } from "@core/store/types/common/statistic/common/statistic";
import statisticGenerator from "@core/services/webmaster/statistic/statistic/graphql/statistics.generator";
import statisticNewGenerator from "@core/services/webmaster/statistic/statistic/graphql/statisticNew.generator";
import dashboardStatistic from "@core/services/webmaster/statistic/dashboard/dashboardStatistic.graphql";
import { StatisticResponseData } from "@core/store/types/common/statistic/common/statistic";
import statisticVerticalsMapper from "@core/services/admin/statistic/statisticVerticalsMapper";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { WebmasterStatisticFiltersResponse } from "@core/store/types/webmaster/statistic";
import dashboardStatisticGenerator from "@core/services/webmaster/statistic/dashboard/dashboardStatistic.generator";

export class StatisticService extends Service {
  public static async getWebmasterStatistic (
    filters: WebmasterStatisticFiltersResponse,
    excludedFilters: BaseFiltersInput,
    groups: string[],
    isAgent: boolean
  ): Promise<StatisticResponse> {
    try {
      const { data: { statistics } } = await this.api.get("", {
        params: statisticGenerator(
          filters,
          excludedFilters,
          groups,
          isAgent
        )
      });

      return filters.vertical === VerticalsEnum.GAMBLING ? statisticVerticalsMapper(statistics) : statistics;
    } catch (e) {
      throw e;
    }
  }

  public static async getWebmasterStatisticNew (
    filters: WebmasterStatisticFiltersResponse,
    excludedFilters: BaseFiltersInput,
    groups: string[],
    isAgent: boolean,
    limit: number,
    offset: number,
    sort: Nullable<string | string[]>,
    order: Nullable<string>,
    payoutAnalyze: boolean
  ): Promise<StatisticResponse> {
    try {
      const { data: { statisticsNew } } = await this.api.get("", {
        params: statisticNewGenerator(
          filters,
          excludedFilters,
          groups,
          isAgent,
          limit,
          offset,
          sort,
          order,
          payoutAnalyze
        )
      });

      return filters.vertical === VerticalsEnum.GAMBLING ? statisticVerticalsMapper(statisticsNew) : statisticsNew;
    } catch (e) {
      throw e;
    }
  }

  public static async getWebmasterStatisticDashboard (
    filters: BaseFiltersInput,
    groups: string[],
    isAgent: boolean
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: dashboardStatisticGenerator(
          filters,
          groups,
          isAgent
        )
      });
    } catch (e) {
      throw e;
    }
  }
}
