<template>
    <div class="splash-screen">
        <!--suppress HtmlUnknownTarget -->
        <img
            :class="{'pb-6 pl-4': !$saas.main}"
            :alt="$saas.name"
            :src="logo">
        <LLoading
            :is-full-page="false"
            active></LLoading>
    </div>
</template>

<script>
  import LLoading from "@/components/Common/LLoading";
  export default {
    name: "SplashScreen",
    components: { LLoading },
    created () {
      const darkMode = localStorage.getItem("theme");

      if (darkMode === "dark") {
        const htmlEl = document.getElementsByTagName("html")[0];

        htmlEl.classList.add("dark");
      }
    },
    computed: {
      logo () {
        const darkMode = localStorage.getItem("theme");
        const name = darkMode === "dark" ? "logo_dark" : "logo";
        return require(`@/assets/${ this.$saas[name] }`);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .splash-screen {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: white;

        &::v-deep {
            .loading-background {
                background-color: transparent;
            }
        }

        img {
            width: 60%;
            display: block;
            max-width: 280px;
            margin-bottom: 172px;
        }
    }
</style>
