import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { integrationEdit } from "@core/store/modules/admin/integrations/edit";
import { Integrations } from "@core/logic/admin/integrations/integrations";

export interface IntegrationState {
  integrations: Integrations | null;
}

const state: IntegrationState = {
  integrations: null
};

export const integration: Module<IntegrationState, RootState> = {
  namespaced: true,
  state,
  modules: {
    edit: integrationEdit
  }
};
