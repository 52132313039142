import { formatCurrency, formatEmptyString, moment, toFixed } from "@core/filters";
import { fixedCurrency, momentWithEmpty } from "@core/flowMethods";

// @vue/component
export const TableVuex = {
  computed: {
    isPaginated () {
      return this.data && !isNaN(this.data.count) ? this.data.count > this.perPage : false;
    }
  },
  
  methods: {
    formatEmptyString,
    
    formatCurrency,
    
    toFixed,
    
    moment,
  
    fixedCurrency,
  
    momentWithEmpty,
    
    updatePerPage (value) {
        this.perPage = value;
    }
  }
};
