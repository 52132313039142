var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"notification-button"},[_c('InlineSvg',{staticClass:"has-text-info",attrs:{"src":_vm.notificationIcon,"alt":"notification"}}),(_vm.countNotifications)?_c('span',{staticClass:"notification-badge"},[_vm._v(" "+_vm._s(_vm.countNotifications)+" ")]):_vm._e()],1)]},proxy:true}])},_vm._l((_vm.data),function(ref,idx){
var ref_request = ref.request;
var intId = ref_request.intId;
var webmaster = ref_request.webmaster;
var offer = ref_request.offer;
var createdAt = ref_request.createdAt;
var type = ref_request.type;
var id = ref.id;
var event = ref.event;
var author = ref.author;
return _c('b-dropdown-item',{key:id,attrs:{"aria-role":"listitem","custom":""}},[_c('div',{staticClass:"is-flex is-align-items-flex-start"},[_c('div',{staticClass:"notification-button mr-3"},[_c('InlineSvg',{staticClass:"has-text-info",attrs:{"src":_vm.notificationIcon,"alt":"notification"}})],1),_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-flex-start"},[_c('div',{staticClass:"nowrap"},[_c('span',{staticClass:"pr-1"},[_vm._v(" "+_vm._s(_vm.$t(("common.navBar.notifications.events." + event)))+": ")]),_c('router-link',{staticClass:"has-text-info",attrs:{"to":{
                            name: 'admin:traffic',
                            query: _vm.setQuery(intId, createdAt, type)
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(intId)+" ")])],1),_c('div',{staticClass:"is-flex",staticStyle:{"column-gap":"1rem"}},[_c('div',{staticClass:"mt-1 has-text-grey is-flex nowrap"},[_vm._v(" "+_vm._s(_vm.$t("admin.traffic.table.dropdown.labels.webmaster"))+": "),_c('WebmasterOutput',{staticClass:"has-text-black pl-1",attrs:{"webmaster":webmaster}})],1),_c('div',{staticClass:"mt-1 has-text-grey is-flex nowrap"},[_vm._v(" "+_vm._s(_vm.$t("admin.traffic.table.dropdown.labels.offer"))+": "),_c('span',{staticClass:"has-text-black pl-1"},[_c('CustomOfferOutput',{attrs:{"offer":offer}})],1)])]),(author)?_c('div',[_c('div',{staticClass:"mt-1 has-text-grey is-flex nowrap"},[_vm._v(" "+_vm._s(_vm.$t("admin.traffic.table.dropdown.labels.manager"))+": "),_c('AdminOutput',{staticClass:"has-text-black pl-1",attrs:{"admin":author}})],1)]):_vm._e()])]),_c('hr',{staticClass:"dropdown-divider",class:{ 'is-hidden': idx + 1 === _vm.countNotifications }})])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }