export interface UserContacts {
  name: string;
  phone: string;
  email: string;
  telegram: string;
}

export interface UserActivity {
  registeredAt: string | null;
  blocked: boolean;
  confirmed: boolean;
  updatedAt: string | null;
  lastLoginAt: string | null;
}

interface UserTimeZone {
  alias: string;
  continent: string;
  offset: string;
}

export default class User {
  public id = "";
  public login = "";
  public contact: UserContacts = {
    name: "",
    phone: "",
    email: "",
    telegram: ""
  };
  public balance = 0;
  public timeZone: UserTimeZone = {
    alias: "Europe/Moscow",
    continent: "Europe",
    offset: "+03:00"
  };
  public activity: UserActivity = {
    registeredAt: null,
    blocked: false,
    confirmed: true,
    updatedAt: null,
    lastLoginAt: null
  };
  
  public constructor (response?: User) {
    if (response) {
      this.id = response.id;
      this.login = response.login;
      this.contact = response.contact;
      this.balance = response.balance;
      this.activity = response.activity;
      this.timeZone = response.timeZone;
    }
  }
}
