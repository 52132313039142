import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  CHECK_WEBMASTER,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_OPTIONS
} from "@core/store/action-constants";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import { CheckModalOptions, CheckModalState } from "@core/store/types/admin/users/webmasters/Check";

const initialState = (): CheckModalState => {
  return {
    options: {
      webmasters: null
    },
    isCheckModalActive: false
  };
};

const state: () => CheckModalState = initialState;

const mutations: MutationTree<CheckModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_OPTIONS] (state, options: CheckModalOptions) {
    state.options = { ...state.options, ...options };
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isCheckModalActive = payload;
  }
};

const actions: ActionTree<CheckModalState, RootState> = {
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: CheckModalOptions) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit("SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  async [CHECK_WEBMASTER] ({ commit }, webmasterKeyword: string) {
    try {
      const { data: { checkWebmasterExisting: webmasters } } = await WebmastersService.checkWebmaster(webmasterKeyword);
      commit(UPDATE_MODAL_OPTIONS, { webmasters });
    } catch (error) {
      throw error;
    }
  }
};


export const checkModal: Module<RootState, CheckModalState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
