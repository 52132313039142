import Service from "@core/services/common/Service";
import { FiltersResponse, OfferFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import utmCampaign from "@core/services/common/statistic/graphql/utmCampaign.graphql";
import utmContent from "@core/services/common/statistic/graphql/utmContent.graphql";
import utmMedium from "@core/services/common/statistic/graphql/utmMedium.graphql";
import utmSource from "@core/services/common/statistic/graphql/utmSource.graphql";
import utmTerm from "@core/services/common/statistic/graphql/utmTerm.graphql";

export class StatisticsService extends Service {
  public static async getUtmCampaign (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<FiltersResponse<string>> {
    try {
      const initialLimit = initialFilters?.utmCampaign?.length || 0;
      const hasInitial = initialLimit > 0;
  
      return await this.api.get("", {
        params: {
          query: utmCampaign,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getUtmContent (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<FiltersResponse<string>> {
    try {
      const initialLimit = initialFilters?.utmContent?.length || 0;
      const hasInitial = initialLimit > 0;
  
      return await this.api.get("", {
        params: {
          query: utmContent,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getUtmMedium (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<FiltersResponse<string>> {
    try {
      const initialLimit = initialFilters?.utmMedium?.length || 0;
      const hasInitial = initialLimit > 0;
  
      return await this.api.get("", {
        params: {
          query: utmMedium,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getUtmSource (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<FiltersResponse<string>> {
    try {
      const initialLimit = initialFilters?.utmSource?.length || 0;
      const hasInitial = initialLimit > 0;

      return await this.api.get("", {
        params: {
          query: utmSource,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getUtmTerm (
    offset = 0,
    limit = 25,
    filters?: OfferFiltersInput,
    initialFilters?: OfferFiltersInput
  ): Promise<FiltersResponse<string>> {
    try {
      const initialLimit = initialFilters?.utmTerm?.length || 0;
      const hasInitial = initialLimit > 0;
  
      return await this.api.get("", {
        params: {
          query: utmTerm,
          variables: {
            limit,
            offset,
            filters,
            initialFilters,
            initialLimit,
            hasInitial
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
