export default [
  {
    path: "profile",
    name: "profile",
    component: (): Promise<any> => import(/* webpackChunkName: "webmaster:profile" */ "@/views/Webmaster/ProfileView.vue"),
    children: [
      {
        path: "",
        name: "profile",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Profile.vue")
      },
      {
        path: "postback",
        name: "postback",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Postback.vue")
      },
      {
        path: "requisites",
        name: "requisites",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Requisites.vue")
      },
      {
        path: "notifications",
        name: "notifications",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/Notifications.vue")
      },
      {
        path: "settings",
        name: "settings",
        component: (): Promise<any> =>
          import(/* webpackChunkName: "webmaster:statistics:statistics" */ "@/components/Webmaster/Profile/OtherSettings.vue")
      }
    ]
  }
];
