<template>
    <section class="paymentDefaultHeight">
        <div class="columns">
            <div class="column is-5 list">
                <div class="mb-1">
                    <slot name="headerList"></slot>
                </div>

                <LLoading
                    :active="isLoading"
                    :is-full-page="false">
                    <b-radio-button
                        :key="item.id"
                        v-for="item in data.items"
                        v-model="_selected"
                        class="mb-1"
                        :native-value="item"
                        :disabled="item.isDisabled || item.status !== 'APPROVED'"
                        type="is-white">
                        <slot
                            name="buttons"
                            :item="item">
                        </slot>
                    </b-radio-button>
                </LLoading>

                <InfiniteLoading
                    v-if="isInfiniteLoading"
                    @infinite="infiniteHandler">
                    <div slot="spinner"></div>
                </InfiniteLoading>
            </div>
            <div class="column is-7 p-5">
                <form @submit.prevent.stop="$emit('submit', $event)">
                    <div class="wrapper-content">
                        <slot name="content"></slot>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
  import InfiniteLoading from "vue-infinite-loading";
  import LLoading from "@/components/Common/LLoading.vue";

  export default {
    name: "FormWithAsideList",

    components: {
      InfiniteLoading,
      LLoading
    },

    props: {
      data: {
        type: Object,
        default: () => {}
      },

      selected: {
        type: Object,
        default: () => {}
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      perPage: {
        type: Number,
        default: 25
      },

      currentPage: {
        type: Number,
        default: 1
      }
    },

    computed: {
      _selected: {
        get () {
          return this.selected;
        },
        set (value) {
          this.$emit("update:selected", value);
        }
      },

      isInfiniteLoading () {
        return this.perPage * this.currentPage < this.data?.count;
      }
    },

    methods: {
      async infiniteHandler ($state) {
        await this.$emit("infiniteHandler", $state);
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .b-radio.radio.button {
            justify-content: flex-start;
            height: 3.5rem;
            font-size: 0.95rem;
            color: #363636;
            border-color: transparent;
            border-radius: 6px;

            &.is-selected, &:hover {
                border: 1px solid #FFBA40;
            }
        }
    }

    .wrapper-content, form {
        height: 100%;
    }

    .wrapper-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .paymentDefaultHeight {
        height: 272px;
    }

    .list {
        min-height: 17rem;
        max-height: 17rem;
        background: #F5F8FA;
        border-right: 1px solid #ECECEC;
        overflow: auto;
    }
</style>
