import { dashboardStatisticWidget } from "@core/store/modules/admin/dashboard/dashboardStatisticWidget";
import { DashboardTopCard } from "@core/store/modules/admin/dashboard/modules/dashboardTopCard";
import { adminsList } from "@core/store/modules/admin/lists/adminsList";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { ActionTree } from "vuex";
import { AnalyticAnalyseChartState } from "@core/store/types/admin/analytic/analyticAnalyseChart";
import { dashboardStatistic } from "@core/store/modules/admin/dashboard/dashboardStatistic";
import { SET_EMPTY } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { ManagersFilters } from "@core/store/modules/common/lists/managersFilters";

const actions: ActionTree<AnalyticAnalyseChartState, RootState> = {
  [SET_EMPTY] ({ commit }): void {
    commit(`offersTopCard/${ SET_EMPTY }`);
    commit(`withdrawTypesList/${ SET_EMPTY }`);
    commit(`webmastersTopCard/${ SET_EMPTY }`);
    commit(`advertisersTopCard/${ SET_EMPTY }`);
  }
};

export const dashboard = {
  namespaced: true,
  actions,
  modules: {
    adminsList,
    advertisersList,
    withdrawTypesList,
    dashboardStatistic,
    dashboardStatisticWidget,
    advertisersTopCard: new DashboardTopCard("advertiser", { managersFilters: new ManagersFilters() }),
    webmastersTopCard: new DashboardTopCard("webmaster", { managersFilters: new ManagersFilters() }),
    offersTopCard: new DashboardTopCard("offer", { managersFilters: new ManagersFilters() })
  }
};
