import { Filters } from "@core/store/modules/common/generators/filters";
import { LandingDomains, LandingDomainsFilters } from "@core/store/types/common/lists/landingDomainsList";
import { FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { DomainsService } from "@core/store/logic/webmaster/domains/domainsService";

class DomainFilters extends Filters<LandingDomains, LandingDomainsFilters> {
  getData = async (offset: number, limit: number, filters: LandingDomainsFilters) => {
    const { data: { landingDomains: data } } = await DomainsService.getDomains(filters, limit, offset);
   
    return {
      data: {
        items: data.items?.map((item: LandingDomains) => ({
          id: item.id,
          name: item.name,
          isHttps: item.isHttps
        })),
        count: data.count
      },
      initialData: {
        items: [],
        count: 0
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const name = Array.isArray(ids) ? ids : [ids];
      return { name };
    }
  };
}

export const domainFilters = new DomainFilters();
