



































































































































import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import { useFinanceAutoPaymentsListModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsListModalStore";
import { FinanceOutTransactionType } from "@core/store/types/webmaster/finances/modal/enum/financeOutTransactionType";
import { FinancesAutoPaymentsListData } from "@core/store/types/webmaster/finances/modal/financeAutoPaymentsList";
import { useFinanceAutoPaymentsModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsModalStore";
import { FinanceTypeEnum } from "@core/store/types/webmaster/finances/modal/enum/financeTypeEnum";
import { formatEmptyString, moment, formatCurrency, momentInst, currencySign } from "@core/filters";
import { actionWithToast } from "@/helpers/actionWithToast";
import { useWait } from "@/stores/common/waitStore";
import { computed, Ref, ref } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";
import { DialogProgrammatic } from "buefy";
import i18n from "@core/plugins/i18n";
import { storeToRefs } from "pinia";
import momentjs from "moment";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useFinanceAutoPaymentsListModal();
  const {
    isActive,
    data
  } = storeToRefs(store);
  const storeFinanceAutoPayments = useFinanceAutoPaymentsModal();
  const {
    isActive: isActiveCreateAutoPayout,
    options,
    availableCurrencies
  } = storeToRefs(storeFinanceAutoPayments);
  store.GET_AUTO_OUT_TRANSACTIONS();
  const isLoading = useWait(store, "GET_AUTO_OUT_TRANSACTIONS");
  const loadingRowId = ref(null) as Ref<string | null>;
  const canBeAddAutoTransaction = computed(() => {
    const sortedAvailableCurrencies = Object.values(availableCurrencies.value).sort().toString();
    const sortedAvailablePaymentCurrencies = [...new Set(data.value?.items.map(el => el.requisite.paymentSystem.currency))].sort().toString();
    return sortedAvailableCurrencies === sortedAvailablePaymentCurrencies;
  });
  function convertTime(value: number) {
    const DAY = 1440;
    const HOUR = 60;
    const selectedDay = Math.floor(value / DAY);
    const selectedHour = Math.floor(value - selectedDay * DAY / HOUR);
    return `${selectedHour && selectedHour < 10 ? `0${selectedHour}` : selectedHour}:00`;
  }
  function transactionsType(el: FinancesAutoPaymentsListData): TranslateResult {
    const time = convertTime(momentInst(el.time).hours());
    if (el.daysOfMonth?.length) {
      const daysOfMonth = el.daysOfMonth?.toSorted((a: number, b: number) => a - b).toString();
      return `${daysOfMonth} ${i18n.t("webmaster.finances.autoPaymentsListModal.body.values.type.amount")} - ${time}`;
    } else if (el.daysOfWeek && el.daysOfWeek.length) {
      return labelAnnotationTooltip(el);
    }
    return `${i18n.t(`webmaster.finances.autoPaymentModal.tabs.${el.isDaily ? "daily" : "weekly"}`)} - ${time}`;
  }
  function hasTypeTime(el: FinancesAutoPaymentsListData): boolean {
    return el.outTransactionType === "TIME";
  }
  function labelAnnotationTooltip(el: FinancesAutoPaymentsListData): string {
    const time = convertTime(momentInst(el.time).hours());
    const order = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    return `${el.daysOfWeek.map(el => i18n.t(`common.entity.callCenters.daysOfWeek.short.${el.toLowerCase()}`))
    // @ts-ignore
    .sort((a, b) => order.indexOf(a) - order.indexOf(b)).toString()} - ${time}`;
  }
  function openAutoPayoutModal(): void {
    isActiveCreateAutoPayout.value = true;
    isActive.value = false;
  }
  function editAutoPayment(row: FinancesAutoPaymentsListData): void {
    const time = momentInst(row.time).hours() * 60;
    const type = row.isDaily || row.outTransactionType === FinanceOutTransactionType.SUM ? FinanceTypeEnum.DAILY : row.daysOfWeek?.length ? FinanceTypeEnum.WEEKLY : FinanceTypeEnum.DATE;
    openAutoPayoutModal();
    options.value = {
      ...row,
      datepickerDates: row.daysOfMonth?.map(el => momentInst("01.01.2024").add(el - 1, "days").toDate()),
      daysOfWeek: row.daysOfWeek ?? [],
      requisiteId: row.requisite.id,
      isEdit: true,
      type,
      time
    };
  }
  function deleteAutoPayment(id: string): void {
    DialogProgrammatic.confirm({
      title: i18n.t("webmaster.finances.autoPaymentModal.messages.title") as string,
      message: i18n.t("webmaster.finances.autoPaymentModal.messages.confirm") as string,
      confirmText: i18n.t("common.buttons.delete") as string,
      cancelText: i18n.t("common.buttons.cancel") as string,
      type: "is-danger is-light",
      onConfirm: async () => {
        try {
          loadingRowId.value = id;
          await actionWithToast(store.DELETE_AUTO_OUT_TRANSACTIONS(id), "webmaster.finances.autoPaymentModal.messages.deleteAutoPayment");
        } finally {
          loadingRowId.value = null;
        }
      }
    });
  }
  return {
    formatEmptyString,
    moment,
    formatCurrency,
    currencySign,
    isActive,
    data,
    isLoading,
    loadingRowId,
    canBeAddAutoTransaction,
    transactionsType,
    hasTypeTime,
    openAutoPayoutModal,
    editAutoPayment,
    deleteAutoPayment
  };
};
__sfc_main.components = Object.assign({
  ModalBox
}, __sfc_main.components);
export default __sfc_main;
