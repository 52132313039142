import { CreatePaymentCostsService } from "@core/services/admin/finances/financesCosts/CreatePaymentCostsService";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import { CREATE_COST, GET_WEBMASTER_BALANCE } from "@core/store/action-constants";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import {
  SET_WEBMASTER_ID,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_OPTIONS,
  UPDATE_SELECTED_WEBMASTERS_BALANCE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialCreatePaymentCostsState } from "@core/store/types/admin/finances/financesCosts/CreatePaymentCostsState";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): PartialCreatePaymentCostsState => {
  return {
    options: {
      userId: null,
      amount: 0,
      requisiteId: null,
      paymentSystemId: null,
      comment: null
    },
    isCreatePaymentModalActive: false,
    selectedWebmasterBalance: null,
    webmasterId: null
  };
};

const state: () => PartialCreatePaymentCostsState = initialState;

const mutations: MutationTree<PartialCreatePaymentCostsState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_MODAL_OPTIONS] (state, option: any) {
    state.options = { ...state.options, ...option };
  },

  [UPDATE_SELECTED_WEBMASTERS_BALANCE] (state, payload: number) {
    state.selectedWebmasterBalance = payload;
  },

  [SET_WEBMASTER_ID] (state, webmasterId: number) {
    state.webmasterId = webmasterId;
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isCreatePaymentModalActive = payload;
  }
};

const actions: ActionTree<PartialCreatePaymentCostsState, RootState> = {
  async [GET_WEBMASTER_BALANCE] ({ commit, state }) {
    const id = state.options.userId;

    if (id) {
      const balance = await UsersService.balance(id);

      commit(UPDATE_SELECTED_WEBMASTERS_BALANCE, balance);
    }
    else {
      commit(UPDATE_SELECTED_WEBMASTERS_BALANCE, null);
    }
  },

  async [CREATE_COST] ({ state, commit }) {
    try {
      const addOutTransaction = await CreatePaymentCostsService.createCosts(
        state.options
      );
      commit("admin/finances/costs/LOCAL_ADD", { items: addOutTransaction, target: "costs" }, { root: true });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    if (payload) {
      commit(UPDATE_MODAL_ACTIVE, payload);
    } else {
      commit("SET_EMPTY");
      commit("webmastersList/SET_EMPTY");
    }
  },

  [UPDATE_MODAL_OPTIONS] ({ commit }, option) {
    commit(UPDATE_MODAL_OPTIONS, option);
  }
};

export const createPaymentModal: Module<PartialCreatePaymentCostsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmastersList
  }
};
