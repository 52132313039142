export enum LandingHistoryGroups {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  OFFER = "OFFER",
  PAGE_TYPE = "PAGE_TYPE",
  PAGE = "PAGE"
}

export enum LandingHistoryDateDirection {
  BY_EVENT = "BY_EVENT",
  BY_PAGE = "BY_PAGE"
}

export enum LandingHistorySort {
  COUNT_LANDINGS_CREATED = "COUNT_LANDINGS_CREATED",
  COUNT_LANDINGS_UPDATED = "COUNT_LANDINGS_UPDATED",
  COUNT_LANDINGS_DELETED = "COUNT_LANDINGS_DELETED",
  COUNT_FLOWS_CREATED = "COUNT_FLOWS_CREATED",
  COUNT_PAGE_DOWNLOADED = "COUNT_PAGE_DOWNLOADED"
}
