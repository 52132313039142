





































































































































































































import CustomTimepicker from "@/components/Common/Date/CustomTimepicker.vue";
import DateSelect from "@/components/Common/Date/DateSelect.vue";
import ModalBox from "@/components/Common/Modals/ModalBox.vue";
import Select from "@/components/Common/Select/Select.vue";
import { FinanceOutTransactionType } from "@core/store/types/webmaster/finances/modal/enum/financeOutTransactionType";
import { useFinanceAutoPaymentsListModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsListModalStore";
import { useFinanceAutoPaymentsModal } from "@/stores/webmaster/finance/modal/financeAutoPaymentsModalStore";
import { FinanceTypeEnum } from "@core/store/types/webmaster/finances/modal/enum/financeTypeEnum";
import { FinanceDayEnum } from "@core/store/types/webmaster/finances/modal/enum/financeDayEnum";
import { RequisitesListItem } from "@core/store/types/admin/lists/RequisitesListState";
import { Requisite } from "@core/store/types/webmaster/profile/modules/requisites";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import { BalanceItem } from "@core/store/modules/common/balance-widget";
import { actionWithToast } from "@/helpers/actionWithToast";
import { computed, ref, watch } from "@vue/composition-api";
import { useWait } from "@/stores/common/waitStore";
import { currencySign } from "@/core/src/filters";
import i18n from "@core/plugins/i18n";
import { storeToRefs } from "pinia";
import vuexStore from "@/store";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useFinanceAutoPaymentsModal();
  const {
    isActive,
    options,
    requisitesList,
    availableCurrencies
  } = storeToRefs(store);
  const autoPaymentsList = useFinanceAutoPaymentsListModal();
  const {
    isActive: isActiveAutoPaymentsList,
    data,
    balance
  } = storeToRefs(autoPaymentsList);
  const isLoading = useWait(store, "ACTION_AUTO_OUT_TRANSACTION");
  const isReadySending = ref(false);
  const types = computed(() => {
    return [{
      value: FinanceTypeEnum.DAILY,
      label: i18n.t("webmaster.finances.autoPaymentModal.tabs.daily")
    }, {
      value: FinanceTypeEnum.WEEKLY,
      label: i18n.t("webmaster.finances.autoPaymentModal.tabs.weekly")
    }, {
      value: FinanceTypeEnum.DATE,
      label: i18n.t("webmaster.finances.autoPaymentModal.tabs.selectDate")
    }];
  });
  // @ts-ignore
  const daysOfWeek = computed(() => [...i18n.t("common.days.short").slice(1), i18n.t("common.days.short")[0]]);
  const hasOutTransactionSum = computed(() => options.value.outTransactionType === FinanceOutTransactionType.SUM);
  const hasOutTransactionTime = computed(() => options.value.outTransactionType === FinanceOutTransactionType.TIME);
  const validationData = computed(() => ({
    ...(options.value.type === FinanceTypeEnum.DATE && {
      datepickerDates: isReadySending.value && !options.value.datepickerDates && options.value.datepickerDates?.length !== 0
    }),
    options: isReadySending.value && !options.value.time && options.value.time !== 0,
    ...(options.value.type === FinanceTypeEnum.WEEKLY && {
      daysOfWeek: isReadySending.value && options.value.daysOfWeek?.length === 0
    })
  }));
  const isValid = computed(() => options.value.outTransactionType === FinanceOutTransactionType.SUM || Object.values(validationData.value).every(v => !v));
  const balanceInt = computed(() => {
    const findValueCurrency = balance.value?.find((el: BalanceItem) => el.currency === currentCurrency.value)?.value;
    return parseInt(findValueCurrency);
  });
  const requisite = computed(() => requisitesList.value.items?.find((el: RequisitesListItem) => el.id === options.value.requisiteId));
  const currentCurrency = computed(() => requisite.value?.paymentSystem.currency);
  const minimalPayout = computed(() => requisite.value?.paymentSystem?.minimalPayout);
  const watchParams = computed(() => JSON.stringify({
    minimalPayout: minimalPayout.value,
    outTransactionType: options.value.outTransactionType
  }));
  const validationAmount = computed(() => {
    // @ts-ignore
    if (options.value.sum === "") {
      return i18n.t("webmaster.finances.financeOrder.messages.errorAmount");
    } else if (options.value.sum !== null && options.value.sum < minimalPayout.value) {
      return i18n.t("webmaster.finances.financeOrder.messages.errorMinAmount", {
        amount: minimalPayout.value,
        currency: currencySign(currentCurrency.value)
      });
    }
    return "";
  });
  function getAutoPaymentsType() {
    const items = [{
      value: FinanceOutTransactionType.TIME,
      name: i18n.t("webmaster.finances.autoPaymentModal.typePayments.byTime")
    }, {
      value: FinanceOutTransactionType.SUM,
      name: i18n.t("webmaster.finances.autoPaymentModal.typePayments.bySum")
    }];
    return {
      items,
      count: items.length
    };
  }
  async function getRequisitesList() {
    await vuexStore.dispatch("webmaster/finances/requisites/GET_REQUISITES");
    const currency = availableCurrencies.value.filter((item: CurrencyEnum) => data.value?.items.some(el => el.requisite.paymentSystem.currency === item));
    const items = requisitesList.value.items.map((item: Requisite) => {
      return {
        ...item,
        isSelectable: !currency.some((el: CurrencyEnum) => item.paymentSystem.currency === el) && item.status === "APPROVED" && !item.paymentSystem.isDisabled
      };
    });
    return {
      items,
      count: items.length
    };
  }
  function changeDaysOfWeek(key: FinanceDayEnum): void {
    options.value.daysOfWeek = hasDayByArray(key) ? options.value.daysOfWeek.filter(el => el !== key) : [...options.value.daysOfWeek, key];
  }
  function hasDayByArray(value: FinanceDayEnum): boolean {
    return options.value.daysOfWeek?.includes(value);
  }
  function close() {
    isActive.value = false;
    isActiveAutoPaymentsList.value = true;
  }
  function resetData() {
    if (!options.value.isEdit) {
      options.value.datepickerDates = null;
      options.value.time = 840;
      options.value.daysOfWeek = [FinanceDayEnum.TUESDAY, FinanceDayEnum.FRIDAY];
    }
    options.value.sum = null;
    isReadySending.value = false;
  }
  function openRequisitesAddModal() {
    vuexStore.commit("webmaster/profile/requisites/add/UPDATE_MODAL", {
      isActive: true
    });
    isActive.value = false;
  }
  async function onSubmit() {
    isReadySending.value = true;
    if (isValid.value) {
      await actionWithToast(store.ACTION_AUTO_OUT_TRANSACTION(), `webmaster.finances.autoPaymentModal.messages.${options.value.isEdit ? "editAutoPayment" : "addAutoPayment"}`);
      isActive.value = false;
      isActiveAutoPaymentsList.value = true;
    }
  }
  watch((): string => watchParams.value, () => {
    if (!options.value.isEdit) {
      if (hasOutTransactionSum.value) {
        options.value.sum = minimalPayout.value;
      } else {
        options.value.sum = null;
      }
    }
  });
  return {
    FinanceDayEnum,
    currencySign,
    i18n,
    store,
    isActive,
    options,
    isLoading,
    types,
    daysOfWeek,
    hasOutTransactionSum,
    hasOutTransactionTime,
    validationData,
    currentCurrency,
    minimalPayout,
    validationAmount,
    getAutoPaymentsType,
    getRequisitesList,
    changeDaysOfWeek,
    hasDayByArray,
    close,
    resetData,
    openRequisitesAddModal,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  ModalBox,
  Select,
  DateSelect,
  CustomTimepicker
}, __sfc_main.components);
export default __sfc_main;
