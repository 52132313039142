import {
  CapacityRequestEfficiencyGroups,
  CapacityRequestEfficiencyItems,
  CapacityRequestEfficiencyState
} from "@core/store/types/admin/capacityAndBumps/capacityRequestEfficiency";
import _uniqueId from "lodash/uniqueId";

export function efficiencyDataMapper (data: NonNullable<CapacityRequestEfficiencyState["data"]>, group: CapacityRequestEfficiencyGroups) {
  return {
    count: data.count,
    items: data.items?.map(dataMapper.bind(group)),
    aggregates: data.aggregates
  };
}

function dataMapper (this: CapacityRequestEfficiencyGroups, { offer, ...item }: CapacityRequestEfficiencyItems) {
  return {
    ...item,
    offer: offer && {
      ...offer,
      name: offer?.detail.name
    },
    uniqueKey: _uniqueId(`${ this }_`)
  };
}