import { TrafficTypes, WebmasterEditFormDetail } from "@core/store/types/webmaster/profile";
import { UserNotificationSetting } from "@core/logic/common/common";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";

export interface UserFormInterface {
  login?: string;
  timeZone?: string | null;
  additional?: {
    skype: string;
    birthday: Date
  };
  categoriesPreferences?: string[];
  trafficTypes?: string[];
  trafficTypesPreferences?: Array<TrafficTypes>;
  trust?: string;
  contact?: {
    email: string;
    telegram: string;
  }
  userNotificationSetting?: Array<UserNotificationSetting>;
  preferredCurrency?: string | null;
  email?: string;
  telegram?: string;
}

export class UserForm implements UserFormInterface {
  public login?: string;
  public timeZone?: string | null;
  public skype?: string;
  public viber?: string | null;
  public whatsApp?: string | null;
  public birthday?: Date;
  public categoriesPreferences?: string[];
  public trafficTypes?: string[];
  public trafficTypesPreferences?: Array<TrafficTypes>;
  public trust?: string;
  public contact?: {
    email: string;
    telegram: string;
  }
  public userNotificationSetting?: Array<UserNotificationSetting>;
  public preferredCurrency?: Nullable<CurrencyEnum>;
  public preferredVertical?: Nullable<VerticalsEnum>;
  public email?: string;
  public telegram?: string;
  public residenceCountry?: string;
  public residenceCity?: string;
  
  constructor (user?: WebmasterEditFormDetail) {
    if (user) {
      this.timeZone = user.timeZone?.alias;
      this.telegram = user.contact?.telegram;
      this.email = user.contact?.email;
      this.categoriesPreferences = user.detail.categoriesPreferences?.map(({ id }) => id);
      this.trafficTypes = user.detail.trafficTypes?.map(({ id }) => id);
      this.trust = user.detail.trust?.alias;
      this.login = user.login;
      this.skype = user.contact?.skype || "";
      this.viber = user.contact?.viber || null;
      this.whatsApp = user.contact?.whatsApp || null;
      this.birthday = user.detail.birthday ?? new Date();
      this.userNotificationSetting = user.userNotificationSetting;
      this.preferredCurrency = user.preferredCurrency;
      this.preferredVertical = user.preferredVertical;
      this.residenceCountry = user.detail.residenceCountry?.id;
      this.residenceCity = user.detail.residenceCity?.id;
    }
  }
  
  isForm () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, trust, email, userNotificationSetting, ...input } = this;
    return input;
  }
}