import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import landingArchive from "@core/services/webmaster/graphql/landingArchive.graphql";
import webmasterRequestToJoinToOffer from "@core/services/webmaster/graphql/joinPrivateOffer.graphql";
import { DomonetizationTypes } from "@core/store/types/webmaster/offer/domonetizationTypes";

export class OfferService extends Service {
  public static async joinPrivateOffer (
    offerId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: webmasterRequestToJoinToOffer,
        variables: {
          offerId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getFileId (
    landingId: string,
    isDomonetizationDisable: boolean,
    domonetizationType: DomonetizationTypes
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: landingArchive,
        variables: {
          landingId,
          isDomonetizationDisable,
          domonetizationType
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
