import Service from "@core/services/common/Service";
import landingFlows from "@core/services/webmaster/flows/graphql/landingFlows.graphql";
import { FlowsState } from "@core/store/types/webmaster/flows";
import { AxiosPromise } from "axios";

export class FlowsService extends Service {
  public static async getFlows (
    limit: number,
    offset: number,
    filters: FlowsState["filters"]
  ): Promise<AxiosPromise> {
    try {
      const { data: { landingFlows: data } } = await this.api.get("", {
        params: {
          query: landingFlows,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }

}
