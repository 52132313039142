import { OffersListService } from "@core/services/admin/lists/OffersListService";
import { GET_INITIAL_OFFERS, GET_OFFERS_LIST, UPDATE_OFFERS_LIST } from "@core/store/action-constants";
import { EMPTY_OFFERS_LIST, UPDATE_OFFERS_FILTERS, UPDATE_OFFERS_LIST_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { OffersListResponse, PartialOffersListState } from "@core/store/types/admin/lists/OffersListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialOffersListState => {
  return {
    data: {},
    filters: {
      id: null,
      nameOrIntId: null,
      webmaster: null
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => PartialOffersListState = initialState;

const getters: GetterTree<PartialOffersListState, RootState> = {};

const mutations: MutationTree<PartialOffersListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_OFFERS_LIST] (state, offers: OffersListResponse["offers"]) {
    if (state.pagination.page !== 1 && state.data?.items && offers) {
      state.data.items = Array.from(new Set([...state.data.items, ...offers.items as []]));
    } else {
      state.data = offers;
    }
  },

  [UPDATE_OFFERS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_OFFERS_FILTERS] (state, filters: PartialOffersListState["filters"]) {
    if (filters?.nameOrIntId !== state.filters?.nameOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_OFFERS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialOffersListState, RootState> = {
  async [GET_OFFERS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { data: { offers } } =
            await OffersListService.getOffersList({
              ...state.filters,
              nameOrIntId: state.filters?.nameOrIntId,
              webmaster: state.filters?.webmaster
            }, limit, offset);

    commit(UPDATE_OFFERS_LIST, offers);
    return offers;
  },

  async [GET_INITIAL_OFFERS] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const { data: { offers, initialOffers } } = await OffersListService.getOffersList(
      {
        ...state.filters,
        webmaster: state.filters?.webmaster
      },
      state.pagination.perPage,
      0,
      { id: ids });
    offers.items = [...initialOffers.items, ...offers.items as []];

    commit(UPDATE_OFFERS_LIST, offers);
    return initialOffers?.items?.length ? initialOffers.items.map(user => user.id) : null;
  }
};

export const offersList: Module<PartialOffersListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
