import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import { Options } from "@core/store/types/common/domains/domains";
import { LandingDomainsFilters, ResponseLandingDomains } from "@core/store/types/common/lists/landingDomainsList";
import landingDomainsQuery from "@core/services/admin/domain/landingDomains.graphql";
import removeLandingDomainQuery from "@core/services/admin/domain/removeLandingDomain.graphql";
import addLandingDomainQuery from "@core/services/admin/domain/addLandingDomain.graphql";

export class DomainsService extends Service {
  public static async getDomains (
    filters: LandingDomainsFilters,
    limit = 25,
    offset = 0
  ): Promise<ResponseLandingDomains> {
    try {
      const { data: { landingDomains } } = await this.api.get("", {
        params: {
          query: landingDomainsQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return landingDomains;
    } catch (e) {
      throw e;
    }
  }
  
  public static async addDomain (input: Options | null): Promise<AxiosPromise> {
    try {
      const { data: { addLandingDomain } } = await this.api.get("", {
        params: {
          query: addLandingDomainQuery,
          variables: {
            input
          }
        }
      });
      
      return addLandingDomain;
    } catch (e) {
      throw e;
    }
  }
  
  public static async deleteDomain (domainId: string): Promise<AxiosPromise> {
    try {
      const { data: { removeLandingDomain } } = await this.api.get("", {
        params: {
          query: removeLandingDomainQuery,
          variables: {
            domainId
          }
        }
      });
      
      return removeLandingDomain;
    } catch (e) {
      throw e;
    }
  }
}
