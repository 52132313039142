import { CompetitionService } from "@core/services/webmaster/competition/CompetitionService";
import {
  UPDATE_OPTIONS,
  GET_COMPETITION_TOP,
  GET_COMPETITION_PLACE,
  GET_CURRENT_COMPETITION,
  GET_COMPETITION_PARTICIPANTS
} from "@core/store/action-constants";
import {
  SET_CURRENT_COMPETITION,
  SET_COMPETITION_TOP,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CompetitionState } from "@core/store/types/webmaster/competition";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): CompetitionState => {
  return {
    competitionTop: null,
    currentCompetition: null,
    options: {
      isActive: false
    }
  };
};

export const state: () => CompetitionState = initialState;

const getters: GetterTree<CompetitionState, RootState> = {
  [GET_COMPETITION_PLACE]: (state) => {
    return state.currentCompetition?.place;
  },
  isVisibleCompetition: (state, getters, rootState, rootGetters) => {
    return !rootGetters.isPartnerNetwork
        && state.currentCompetition
        && rootState.auth.subType !== "SUB_WEBMASTER"
        && rootState.auth.role === "webmaster";
  },
  isApproveCompetitionType: (state) => {
    return state.currentCompetition?.competition?.__typename === "ApproveCompetition";
  }
};

const mutations: MutationTree<CompetitionState> = {
  [SET_CURRENT_COMPETITION] (state, currentCompetition) {
    state.currentCompetition = currentCompetition;
  },
  
  [SET_COMPETITION_TOP] (state, competitionTop) {
    state.competitionTop = competitionTop;
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [UPDATE_OPTIONS] (state, params) {
    state.options = { ...state.options, ...params };
  }
};

const actions: ActionTree<CompetitionState, RootState> = {
  async [GET_CURRENT_COMPETITION] ({ state, commit, dispatch, rootGetters }) {
    const { data: { currentCompetition: data } } = await CompetitionService.getCurrentCompetition(rootGetters.role);
    
    commit(SET_CURRENT_COMPETITION, data);
    if (state.currentCompetition?.competition?.id) {
      if (rootGetters.role === "admin") {
        dispatch(GET_COMPETITION_PARTICIPANTS);
      } else {
        dispatch(GET_COMPETITION_TOP);
      }
    }
  },
  
  async [GET_COMPETITION_TOP] ({ state, commit }) {
    const competitionId = state.currentCompetition?.competition?.id;
    // @ts-ignore
    const { data: { competitionTop: data } } = await CompetitionService.getCompetitionTop(competitionId);
    
    commit(SET_COMPETITION_TOP, data);
  },

  async [GET_COMPETITION_PARTICIPANTS] ({ state, commit }) {
    const competitionId = state.currentCompetition?.competition?.id;
    // @ts-ignore
    const { data: { competitionParticipants: data } } = await CompetitionService.getCompetitionParticipants(competitionId);

    commit(SET_COMPETITION_TOP, data.items);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  },

  [UPDATE_OPTIONS] ({ commit }, params) {
    commit(UPDATE_OPTIONS, params);
  }
};

export const competition: Module<CompetitionState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
