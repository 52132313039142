import {
  CREATE_LINK,
  GENERATE_OFFER_URL,
  UPDATE_OFFER_FIELDS
} from "@core/store/action-constants";
import {
  SET_EMPTY,
  SET_MODAL_ACTIVE,
  SET_OFFER_FIELDS,
  UPDATE_MODAL_ACTIVE,
  UPDATE_OFFER_URL
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { OfferEditLinkModal } from "@core/store/types/webmaster/offer/OfferEditLinkModal";

const fieldsMap: Record<string, string> = {
  "utmSource": "utm_source",
  "utmTerm": "utm_term",
  "utmMedium": "utm_medium",
  "utmContent": "utm_content",
  "utmCampaign": "utm_campaign",
  "clickId": "clickid",
  "externalWebmasterId": "externalWebmasterId"
};

const initialState = (): OfferEditLinkModal => {
  return {
    isModalActive: false,
    url: null,
    fields: {
      utmSource: null,
      utmTerm: null,
      utmMedium: null,
      utmContent: null,
      utmCampaign: null,
      clickId: null,
      externalWebmasterId: null,
      globalSource: null
    }
  };
};

const state: () => OfferEditLinkModal = initialState;

const getters: GetterTree<OfferEditLinkModal, RootState> = {
  [CREATE_LINK]: (state) => {
    const url = new URL(state.url);
  
    for (const [key, value] of Object.entries(state.fields)) {
      const name = fieldsMap[key] ?? key;
  
      if (url.searchParams.has(name)) {
        url.searchParams.delete(name);
      }
      
      if (value && typeof value === "string") {
        url.searchParams.append(name, value);
      }
    }
  
    return url;
  }
};

const mutations: MutationTree<OfferEditLinkModal> = {
  [SET_MODAL_ACTIVE] (state, value) {
    state.isModalActive = value;
  },
  
  [SET_OFFER_FIELDS] (state, fields) {
    state.fields = { ...state.fields, ...fields };
  },
  
  [UPDATE_OFFER_URL] (state, url) {
    state.url = url;
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<OfferEditLinkModal, RootState> = {
  [UPDATE_MODAL_ACTIVE] ({ commit, dispatch }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
    if (payload) {
      dispatch(GENERATE_OFFER_URL);
    } else {
      commit(SET_EMPTY);
    }
  },
  
  [GENERATE_OFFER_URL] ({
    commit,
    getters
  }) {
    const url = getters.CREATE_LINK;
    
    commit(UPDATE_OFFER_URL, url);
  },
  
  [UPDATE_OFFER_FIELDS] ({
    commit,
    dispatch
  }, payload) {
    commit(SET_OFFER_FIELDS, payload);
    dispatch(GENERATE_OFFER_URL);
  }
};

export const offerEditLinkModal: Module<OfferEditLinkModal, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
