import { defineStore, storeToRefs } from "pinia";
import { ref, reactive, toRefs, computed } from "@vue/composition-api";
import { TrafficService } from "@core/services/admin/traffic/TrafficService";
import { usePermissions } from "@/stores/common/auth/permissionsStore";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { TrafficRequestAnalyseData } from "@core/store/types/admin/traffic/trafficRequest";
import storeInstance from "@/store";
import { Webmaster } from "@core/store/types/common/User";
import { Offer } from "@core/store/types/common";

export const useTrafficRequestAnalyseModal = defineStore("admin/efficiency/analyseModal", () => {
  const isActive = ref(false);

  const data = ref<Nullable<TrafficRequestAnalyseData>>(null);
  const filters = reactive({
    datepicker: datepicker({ amount: 1, unit: "month" }, "dateTime"),
    withExternalWebmasters: false
  });

  const modal = reactive({ offerId: "", webmasterId: "" });

  const webmasterFromList = computed(() => storeInstance.state.webmastersFilters.data.items.find(({ id }: Webmaster) => id === modal.webmasterId));
  const offerFromList = computed(() => storeInstance.state.offersFilters.data.items.find(({ id }: Offer) => id === modal.offerId));

  const webmaster = computed(() => data.value?.[0]?.webmaster.intId || webmasterFromList.value?.intId);
  const offer = computed(() => {
    const offer = data.value?.[0]?.offer || offerFromList.value;
    return offer ? `${ offer.name ?? offer.detail.name } [${ offer.intId }]` : null;
  });

  const permissionsStore = usePermissions();
  const { isShowRedemption } = storeToRefs(permissionsStore);

  async function getTrafficRequestsAnalyse () {
    const { datepicker, ...otherFilters } = filters;
    data.value = await TrafficService.getAnalyseChart(
      modal.offerId,
      modal.webmasterId,
      { ...otherFilters, ...datepickerFormat(datepicker, "date") },
      isShowRedemption.value
    );
  }
  
  function $reset () {
    isActive.value = false;
    data.value = null;
    filters.datepicker = datepicker({ amount: 1, unit: "month" }, "dateTime");
    filters.withExternalWebmasters = false;
  }
  
  return {
    isActive,
    data,
    ...toRefs(filters),
    ...toRefs(modal),
    webmaster,
    offer,
    getTrafficRequestsAnalyse,
    GET_TRAFFIC_REQUESTS_ANALYSE: "getTrafficRequestsAnalyse",
    $reset
  };
});