import { WebmasterDataById } from "@core/store/types/common/webmasterDataById";
import { GET_WEBMASTER_BY_ID, UPDATE_WEBMASTER_BY_ID } from "@core/store/action-constants";
import { SET_EMPTY } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { WebmasterByIdService } from "@core/services/common/WebmasterByIdService";
import { webmasterProfileViewModal } from "@core/store/modules/common/webmasterProfileViewModal";
import { WebmasterMiniCard } from "@core/store/logic/admin/WebmasterMiniCard";

const initialState = (): WebmasterDataById => {
  return {
    data: null
  };
};

const state: () => WebmasterDataById = initialState;

const getters: GetterTree<WebmasterDataById, RootState> = {};

const mutations: MutationTree<WebmasterDataById> = {
  [UPDATE_WEBMASTER_BY_ID] (state: WebmasterDataById, data: WebmasterDataById["data"]): void {
    state.data = { ...state.data, ...data };
  },
  
  [SET_EMPTY]: (state: WebmasterDataById) => Object.assign(state, initialState())
};

const actions: ActionTree<WebmasterDataById, RootState> = {
  async [GET_WEBMASTER_BY_ID] ({ commit }, id: string): Promise<void> {
    const { data: { webmasterById } } = await WebmasterByIdService.getWebmasterById(id, false);
    
    commit(UPDATE_WEBMASTER_BY_ID, { [id]: new WebmasterMiniCard(webmasterById) });
  },
  
  [SET_EMPTY]: ({ commit }): void => {
    commit(SET_EMPTY);
  }
};

export const webmasterDataById: Module<WebmasterDataById, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    webmasterProfileViewModal
  }
};
