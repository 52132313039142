import Service from "@core/services/common/Service";
import incidenceDetails from "@core/services/advertiser/incidence/graphql/incidenceDetails.graphql";
import incidenceAnalyse from "@core/services/admin/analytic/graphql/incidenceAnalyse.graphql";
import { AnalyticAnalyseChartDetailFilters, AnalyticAnalyseChartDetailResponse } from "@core/store/types/admin/analytic/AnalyticAnalyseChartDetail";
import { ReasonEnum } from "@core/store/types/admin/common/enums/ReasonEnum";
import { IncidenceDetailsSortEnum } from "@core/store/types/admin/analytic/enums/IncidenceDetailsSortEnum";
import { IncidenceFiltersRequest, IncidenceResponse } from "@core/store/types/advertiser/incidence/incidence";

export class IncidenceService extends Service {
  public static async getIncidenceAnalyse (filters: IncidenceFiltersRequest): Promise<IncidenceResponse> {
    try {
      const { data: { incidenceAnalyse: data } } = await this.api.get("", {
        params: {
          query: incidenceAnalyse,
          variables: {
            filters
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async getIncidenceDetails (
    threshold: number,
    incidences: ReasonEnum[],
    filters: AnalyticAnalyseChartDetailFilters,
    sort: Nullable<IncidenceDetailsSortEnum>,
    order: Nullable<string>,
    limit: number,
    offset: number
  ): Promise<AnalyticAnalyseChartDetailResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: incidenceDetails,
          variables: {
            threshold,
            incidences,
            filters,
            sort,
            order,
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
