import { GET_REFERRAL_BONUS, SET_EMPTY } from "@core/store/action-constants";
import {
  UPDATE_REFERRAL_BONUS_SORTINGS,
  SET_REFERRAL_BONUS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { ReferralBonusFilters, ReferralBonusState } from "@core/store/types/admin/finances/referralBonus/referralBonus";
import { referralList } from "@core/store/modules/admin/finances/modules/referral/list/referralList";
import { referrerList } from "@core/store/modules/admin/finances/modules/referral/list/referrerList";
import { ReferralService } from "@core/services/admin/finances/referral/referralService";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): ReferralBonusState => {
  return {
    data: null,
    filters: {
      datepicker: datepicker({ amount: 7, unit: "day" }),
      referral: null,
      referrer: null
    },
    sort: null,
    order: null
  };
};

const state: () => ReferralBonusState = initialState;

const mutations: MutationTree<ReferralBonusState> = {
  [SET_REFERRAL_BONUS] (state, items) {
    state.data = items;
  },
  
  [UPDATE_REFERRAL_BONUS_SORTINGS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [UPDATE_FILTERS] (state, filter: ReferralBonusFilters) {
    state.filters = { ...state.filters, ...filter };
  },
  
  [SET_EMPTY] (state, target?: keyof ReferralBonusState) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<ReferralBonusState, RootState> = {
  async [GET_REFERRAL_BONUS] ({ state, commit }) {
    const { sort, order, filters } = state;
    const { datepicker, ...otherFilters } = filters;
    const { limit, offset } = pagination();

    const { data: { referralBonus } } = await ReferralService.getReferralBonus(
      limit,
      offset,
      sort,
      order,
      {
        ...datepicker,
        ...otherFilters
      }
    );
    
    commit(SET_REFERRAL_BONUS, referralBonus);
  },
  
  [UPDATE_FILTERS] ({ commit }, filters: ReferralBonusFilters) {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }, target?: ReferralBonusState) {
    commit(SET_EMPTY, target);
    commit(`referralList/${ SET_EMPTY }`);
    commit(`referrerList/${ SET_EMPTY }`);
  }
};

export const referral: Module<ReferralBonusState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    referralList,
    referrerList
  }
};
