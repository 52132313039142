import { Filters } from "../../common/generators/filters";
import { OfferItem } from "@core/store/types/admin/statistic/common/offersFilters";
import {
  FilterInput,
  UserFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { WebmasterService } from "@core/services/common/lists/WebmasterService";

export class ManagersFilters extends Filters<OfferItem, UserFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: UserFiltersInput, sort: string, order: "DESC" | "ASC") => {
    const { data: { admins, initialAdmins } } =
      await WebmasterService.getWebmasterFilters(offset, limit, filters, initialFilters, sort, order);
    
    return {
      data: {
        items: admins?.items,
        count: admins?.count
      },
      initialData: {
        items: initialAdmins?.items,
        count: initialAdmins?.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
