import { Agent, Manager } from "@core/logic/user/Manager";
import { WebmasterService } from "@core/logic/user/Webmaster";
import { ProfileService } from "@core/services/webmaster/ProfileService";
import {
  EDIT_USER,
  FETCH_USER,
  GET_WEBMASTER_PERSONAL_MANAGER,
  RESEND_EMAIL,
  GET_WEBMASTER_PERSONAL_AGENT,
  UPDATE_WEBMASTER_NOTIFICATIONS
} from "@core/store/action-constants";
import {
  SAVE_USER,
  UPDATE_USER,
  SET_PERSONAL_MANAGER,
  SET_PERSONAL_AGENT,
  UPDATE_WEBMASTER_PROFILE_FORM, SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WebmasterProfileState } from "@core/store/types/webmaster/profile";
import { ActionTree, Module, MutationTree } from "vuex";
import { globalPostback } from "./modules/globalPostback";
import { requisites } from "./modules/requisites";
import { tokens } from "./modules/tokens";
import { Channel, UserNotificationSetting } from "@core/logic/common/common";
import { ManagerItem } from "@core/store/types/webmaster/user/manager";

const initialState = (): WebmasterProfileState => {
  return {
    user: null,
    form: {
      timeZone: {
        alias: null,
        continent: null,
        offset: null
      },
      telegram: null,
      skype: null,
      whatsApp: null,
      viber: null,
      birthday: null,
      categoriesPreferences: [],
      trafficTypes: [],
      preferredCurrency: null,
      preferredVertical: null,
      residenceCountry: null,
      residenceCity: null
    },
    personalManagers: null,
    personalAgent: null
  };
};

const state: () => WebmasterProfileState = initialState;

const mutations: MutationTree<WebmasterProfileState> = {
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  },

  [SET_PERSONAL_MANAGER] (state, manager) {
    state.personalManagers = manager;
  },
  
  [SET_PERSONAL_AGENT] (state, agent) {
    state.personalAgent = agent;
  },
  
  [SAVE_USER] (state, webmaster) {
    state.user = webmaster;
  },

  [UPDATE_USER] (state, webmaster) {
    state.user = { ...state.user, ...webmaster };
  },
  
  [UPDATE_WEBMASTER_PROFILE_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<WebmasterProfileState, RootState> = {
  async [FETCH_USER] ({ commit }) {
    const { data: { profile } } = await ProfileService.getProfile();
    
    commit(SAVE_USER, profile);
    return profile;
  },

  async [EDIT_USER] ({ commit, state: { form } }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, trust, email, userNotificationSetting, isShowPreferredVertical, ...input } = form;
    
    // @ts-ignore
    const { data: { editWebmasterProfile } } = await ProfileService.editProfile({ ...input });
    
    commit(SAVE_USER, editWebmasterProfile);
  },
  
  async [GET_WEBMASTER_PERSONAL_MANAGER] ({ commit }) {
    const manager = await WebmasterService.getWebmasterPersonalManager();
    commit(SET_PERSONAL_MANAGER, manager);
  },
  
  async [GET_WEBMASTER_PERSONAL_AGENT] ({ commit }) {
    const agent = await WebmasterService.getWebmasterPersonalAgent();
    commit(SET_PERSONAL_AGENT, agent);
  },

  async [RESEND_EMAIL] () {
    await ProfileService.resendEmail();
  },
  
  [UPDATE_WEBMASTER_PROFILE_FORM] ({ commit }, form) {
    commit(UPDATE_WEBMASTER_PROFILE_FORM, form);
  },

  [UPDATE_USER] ({ commit }, payload) {
    commit(UPDATE_USER, payload);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  },
  
  async [UPDATE_WEBMASTER_NOTIFICATIONS] ({ commit, state: { user } }, payload) {
    const preparedUserNotificationSetting = payload.map((item: UserNotificationSetting) => {
      const channels = item.channels
        .map((channel: Channel) => channel.channelStatus && channel.channelName)
        .filter(Boolean);
      return {
        ...item,
        channels
      };
    });
    const { data: { userNotificationSetting: { userNotificationSetting } } } =
      await ProfileService.updateNotifications(preparedUserNotificationSetting);
    commit(SAVE_USER, { ...user, userNotificationSetting });
  }
};

export const profile: Module<WebmasterProfileState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    requisites,
    tokens,
    globalPostback
  }
};
