import { actionWithToast } from "@/helpers/actionWithToast";
import { mapActions } from "vuex";
import { REMOVE_FLOW } from "@core/store/action-constants";
export { Offer } from "./offer";
export { Modal } from "./modal";
export { CallCenters } from "./callCenters";
export { Filters } from "./filters";
export { Table } from "./table";
export { TableVuex } from "./tableVuex";
export { FilteredComponent } from "./filteredComponent";
export { ListView } from "./listView";
export { StatisticTableView } from "./statisticTableView";
export { UsersFilters } from "./usersFilter";
export { ColorGenerator } from "./colorGenerator";
export { OffersFilters } from "./offersFilter";
export { DebounceUpdateByWatchedParams } from "./debounceUpdateByWatchedParams";
export { DebounceGetDataByWatchedParams } from "./debounceGetDataByWatchedParams";
export { DropdownCapacity } from "./dropdownCapacity";
export { CRM } from "./CRM";
export { FiltersCount } from "./filtersCount";
export { AnalyticTableViewGroupsDetails } from "./statistic/analyticTableViewGroupsDetails";
// @vue/components
export const Flows = {
  data () {
    return {
      activeRowId: null,
      deleteFlowId: null
    };
  },
  
  computed: {
    isDeleteFlowActive: {
      get () {
        return this.deleteFlowId != null;
      },
      
      set (value) {
        if (!value) {
          this.deleteFlowId = null;
        }
      }
    }
  },
  
  methods: {
    ...mapActions("webmaster/offer", {
      REMOVE_FLOW
    }),
    
    isWaitingButton (id) {
      return id === this.activeRowId;
    },

    deleteFlow (deleteFlowId) {
      this.$buefy.dialog.confirm({
        title: this.$t("webmaster.flows.modals.deleteConfirmationText"),
        confirmText: this.$t("webmaster.flows.modals.deleteConfirmationAction"),
        cancelText: this.$t("common.buttons.cancel"),
        type: "is-danger is-light",
        onConfirm: async () => {
          try {
            this.deleteFlowId = deleteFlowId;
            this.activeRowId = this.deleteFlowId;
            await actionWithToast(
              this.REMOVE_FLOW(deleteFlowId),
              "webmaster.flows.message.deleteFlow"
            );
            this.$emit("update");
          } finally {
            this.activeRowId = null;
            this.deleteFlowId = null;
          }
        }
      });
    }
  }
};
