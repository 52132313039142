import {
  SET_MODAL_ACTIVE,
  UPDATE_FILTERS,
  SET_ANALYTIC
} from "@core/store/mutation-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_ANALYSIS_CHANGE_HISTORY,
  UPDATE_MODAL_ACTIVE,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  TrafficAnalysisChangeApproveHistoryState
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysisChangeApproveHistoryModal";
import { TrafficAnalysisService } from "@core/services/admin/statistic/trafficAnalysis/TrafficAnalysis";

const initialState = (): TrafficAnalysisChangeApproveHistoryState => {
  return {
    data: null,
    filters: {
      offset: 0,
      limit: 10
    },
    isModalActive: false
  };
};

const state: () => TrafficAnalysisChangeApproveHistoryState = initialState;

const mutations: MutationTree<TrafficAnalysisChangeApproveHistoryState> = {
  [SET_EMPTY] (state: TrafficAnalysisChangeApproveHistoryState): void {
    Object.assign(state, initialState());
  },

  [SET_MODAL_ACTIVE] (state: TrafficAnalysisChangeApproveHistoryState, isModalActive: boolean): void {
    state.isModalActive = isModalActive;
  },

  [SET_ANALYTIC] (state: TrafficAnalysisChangeApproveHistoryState, data: TrafficAnalysisChangeApproveHistoryState["data"]): void {
    state.data = data;
  },

  [UPDATE_FILTERS] (state, filters: TrafficAnalysisChangeApproveHistoryState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<TrafficAnalysisChangeApproveHistoryState, RootState> = {
  async [GET_ANALYSIS_CHANGE_HISTORY] ({ commit, state, rootState }): Promise<void> {
    const { offset, limit } = state.filters;
    const { offerId, firstPeriod, secondPeriod, webmasterId: excludedWebmasterId } = rootState.trafficAnalysis.filters;

    const data = await TrafficAnalysisService.getTrafficAnalysisTopWebmasters(
      excludedWebmasterId,
      secondPeriod,
      firstPeriod,
      offerId,
      offset,
      limit
    );
    commit(SET_ANALYTIC, data);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(SET_MODAL_ACTIVE, payload);
  },

  [UPDATE_FILTERS] ({ commit }, payload: TrafficAnalysisChangeApproveHistoryState["filters"]): void {
    commit(UPDATE_FILTERS, payload);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const trafficAnalysisChangeApproveHistoryModal: Module<RootState, TrafficAnalysisChangeApproveHistoryState> = {
  namespaced: true,
  state,
  actions,
  mutations
};