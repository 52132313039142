import { ProfileService } from "@core/services/webmaster/ProfileService";
import { ADD_REQUISITE, USER_EXIST_IN_ZALEYCASH, VALID_TRON_ADDRESS } from "@core/store/action-constants";
import {
  SET_EMPTY,
  UPDATE_MODAL,
  UPDATE_MODAL_OPTIONS,
  UPDATE_WEBMASTER_REQUISITES_ADD_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  WebmasterProfileRequisitesAddInput,
  WebmasterProfileRequisitesAddState
} from "@core/store/types/webmaster/profile/modules/requisites/modules/add";
import { ActionTree, Module, MutationTree } from "vuex";

import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";

const initialState = (): WebmasterProfileRequisitesAddState => {
  return {
    modal: {
      isActive: false
    },
    form: {},
    options: {
      isUserExistInZaleyCash: null,
      isValidTronAddress: null
    }
  };
};

const state: () => WebmasterProfileRequisitesAddState = initialState;

const mutations: MutationTree<WebmasterProfileRequisitesAddState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_WEBMASTER_REQUISITES_ADD_FORM] (state, form: WebmasterProfileRequisitesAddState["form"]) {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_MODAL] (state, modal: WebmasterProfileRequisitesAddState["modal"]) {
    state.modal = { ...state.modal, ...modal };
  },

  [UPDATE_MODAL_OPTIONS] (state, option: WebmasterProfileRequisitesAddState["options"]) {
    state.options = { ...state.options, ...option };
  }
};

const actions: ActionTree<WebmasterProfileRequisitesAddState, RootState> = {
  [ADD_REQUISITE] ({ state: { form } }) {
    return ProfileService.addRequisite(form as WebmasterProfileRequisitesAddInput);
  },

  async [USER_EXIST_IN_ZALEYCASH] ({ commit }, email) {
    const { data: { isUserExistInZaleyCash } } = await ProfileService.userExistInZaleyCash(email);
    commit(UPDATE_MODAL_OPTIONS, { isUserExistInZaleyCash });
  },

  async [VALID_TRON_ADDRESS] ({ commit }, address) {
    const { data: { isValidTronAddress } } = await ProfileService.validTronAddress(address);
    commit(UPDATE_MODAL_OPTIONS, { isValidTronAddress });
  },

  [UPDATE_MODAL] ({ commit }, modal) {
    commit(UPDATE_MODAL, modal);
  },

  [UPDATE_MODAL_OPTIONS] ({ commit }, payload) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },

  [UPDATE_WEBMASTER_REQUISITES_ADD_FORM] ({ commit }, form) {
    commit(UPDATE_WEBMASTER_REQUISITES_ADD_FORM, form);
  },

  [SET_EMPTY] ({ commit }) {
    commit("SET_EMPTY");
    commit("paymentSystemsList/SET_EMPTY");
  }
};

export const add: Module<WebmasterProfileRequisitesAddState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    paymentSystemsList
  }
};
