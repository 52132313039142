import { GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { SHOW_LOADING } from "@core/store/mutation-constants";

export interface LoadingState {
  showLoading: boolean;
}

const state: LoadingState = {
  showLoading: false
};

const getters: GetterTree<LoadingState, RootState> = {
  showLoading: state => state.showLoading
};

const mutations: MutationTree<LoadingState> = {
  [SHOW_LOADING] (state, value) {
    state.showLoading = value;
  }
};


export const loading: Module<LoadingState, RootState> = {
  state,
  getters,
  mutations
};
