import { ExportService } from "@core/services/common/exports/ExportService";
import {
  GET_LINK_FOR_DOWNLOAD,
  GET_UPLOADS,
  SET_UPLOAD_FILE_ID,
  GET_UPLOADS_FILE_READY_PERCENT
} from "@core/store/action-constants";
import { uploadFile } from "@core/store/modules/common/uploads/uploadFile";
import {
  SET_UPLOADS,
  UPDATE_FILE,
  UPDATE_FILES_UPLOADED,
  UPDATE_UPLOADS_FILTERS,
  UPDATE_UPLOADS_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { UploadsState, UploadState } from "@core/store/types/admin/uploads/UploadsState";
import { ActionTree, GetterTree, MutationTree } from "vuex";

function debounceUpdateFiles (): any {
  // @ts-ignore
  // eslint-disable-next-line no-invalid-this
  const func = (): Promise => this.dispatch(`uploads/${ GET_UPLOADS_FILE_READY_PERCENT }`);
  setTimeout(func, 2000);
}

const initialState = (): UploadsState => {
  return {
    file: null,
    filters: {},
    uploadFileId: null,
    isFiledUploaded: false
  };
};

const state: () => UploadsState = initialState;

const getters: GetterTree<UploadsState, RootState> = {};

const mutations: MutationTree<UploadsState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [SET_UPLOAD_FILE_ID] (state, id: string) {
    state.uploadFileId = id;
  },
  
  [SET_UPLOADS] (state, payload) {
    state.file = payload;
  },
  
  [UPDATE_UPLOADS_FILTERS] (state, filter: any) {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_FILE] (state, payload: UploadState["file"]) {
    state.file = { ...state.file, ...payload };
  },
  
  [UPDATE_FILES_UPLOADED] (state, payload: boolean) {
    state.isFiledUploaded = payload;
  }
};

const actions: ActionTree<UploadsState, RootState> = {
  async [GET_UPLOADS] ({ state, commit }) {
    const limit = 1;
    const offset = 0;
    const { ...filters } = state.filters;
    
    try {
      const { data: { queryExports: { items } } } = await ExportService.queryExports(
        limit,
        offset,
        {
          ...filters,
          isDownloaded: false
        }
      );
      
      commit(SET_UPLOADS, items[0]);
      
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [GET_UPLOADS_FILE_READY_PERCENT] ({ state, commit, dispatch }) {
    commit(UPDATE_FILES_UPLOADED, true);
    const uploadFileId = state.uploadFileId;
    try {
      const { data: { queryExports } } = await ExportService.getFilesReadyPercent(
        {
          id: uploadFileId
        }
      );
      
      const file = queryExports.items[0];
      const { id, status, readyPercent } = file;
      
      commit(UPDATE_FILE, { readyPercent, status });

      if (status === "DONE") {
        dispatch(GET_LINK_FOR_DOWNLOAD, id);
        commit(UPDATE_FILES_UPLOADED, false);
      } else if(status !== "FAILED") {
        debounceUpdateFiles.call(this);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [GET_LINK_FOR_DOWNLOAD] ({ commit }, queryExportId) {
    try {
      const { data: { fileExport } } = await ExportService.fileExport(queryExportId);
      
      commit(UPDATE_FILE, { link: fileExport });
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  [UPDATE_UPLOADS_PAGINATION] ({ dispatch, commit }, payload) {
    commit(UPDATE_UPLOADS_PAGINATION, payload);
    dispatch(GET_UPLOADS);
  },
  
  [SET_UPLOAD_FILE_ID] ({ commit }, payload) {
    commit(SET_UPLOAD_FILE_ID, payload);
  },
  
  SET_EMPTY ({ commit }) {
    commit("SET_EMPTY");
  }
};

export const uploads = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    uploadFile
  }
};
