import Service from "@core/services/common/Service";
import {
  PartialPaymentSystemsListState,
  PaymentSystemsListResponse
} from "@core/store/types/common/lists/PaymentSystemsListState";
import { AxiosPromise } from "axios";
import paymentSystems from "../../common/lists/graphql/paymentSystems.graphql";

export class PaymentSystemsListService extends Service {
  public static async getPaymentSystems (
    filters: PartialPaymentSystemsListState["filters"],
    limit: number,
    offset: number,
    role: string,
    initialPaymentSystems: PartialPaymentSystemsListState["filters"] = {}
  ): Promise<AxiosPromise<PaymentSystemsListResponse>> {
    try {
      const initialPaymentSystemsLimit = initialPaymentSystems?.id?.length || 0;
      const isInitialPaymentSystems = !!initialPaymentSystems?.id;
      limit = Math.floor(initialPaymentSystemsLimit / limit + 1) * limit - initialPaymentSystemsLimit;
      return await this.api.get("", {
        params: {
          query: paymentSystems,
          variables: {
            limit,
            offset,
            filters,
            initialPaymentSystems,
            initialPaymentSystemsLimit,
            isInitialPaymentSystems,
            sort: "name",
            order: "ASC"
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
