import { capacity } from "@core/store/modules/admin/capacityAndBumps/modules/capacity";
import { logRequest } from "@core/store/modules/admin/capacityAndBumps/modules/log/logRequest";
import { statistics } from "@core/store/modules/admin/capacityAndBumps/modules/statistics/capacityStatistics";
import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { offerEditWebmastersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersAvailableList";
import { allowedOfferParameters } from "@core/store/modules/admin/capacityAndBumps/modules/log/allowedOfferParameters";
import {
  CapacityAnalyseExternalWebmastersList
} from "@core/store/modules/admin/capacityAndBumps/list/capacityAnalyseExternalWebmastersList";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";
import { ManagersFilters } from "@core/store/modules/common/lists/managersFilters";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";
import { AdvertisersFilters } from "@core/store/modules/common/lists/advertisersList";

export const capacityAndBumps: Module<any, RootState> = {
  namespaced: true,
  modules: {
    capacity,
    logRequest,
    statistics,
    offerEditWebmastersAvailableList,
    capacityAnalyseExternalWebmastersList: new CapacityAnalyseExternalWebmastersList(),
    allowedOfferParameters,
    offersFilters: new OfferFilters(),
    managersFilters: new ManagersFilters(),
    webmastersFilters: new WebmastersFilters(),
    advertisersFilters: new AdvertisersFilters()
  }
};
