import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { LogRequestModalState } from "@core/store/types/admin/offers/bampsAndCaps";
import {
  SET_EMPTY, UPDATE_CURRENT_WEBMASTER,
  UPDATE_MODAL_ACTIVE,
  UPDATE_OFFER_AVAILABLE_FILTERS
} from "@core/store/mutation-constants";
import {
  SEND_REQUEST,
  SET_EMPTY_WEBMASTERS,
  UPDATE_MODAL_FORM
} from "@core/store/action-constants";
import { typeEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";
import { TypeEnum } from "@core/store/types/admin/offers/bampsAndCaps";
import { offerEditWebmastersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersAvailableList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";
import { webmastersOfferList } from "@core/store/modules/admin/lists/webmastersOfferList";
import _camelCase from "lodash/camelCase";
import { datepicker } from "@core/helpers/datepicker";

class LogRequestModal implements Module<LogRequestModalState, RootState> {
  public namespaced: boolean;
  public modules: ModuleTree<RootState>;
  public state: LogRequestModalState;
  
  public getters: GetterTree<LogRequestModalState, RootState> = {
    getOfferId: (state) => state.form.offerId
  }
  
  public mutations: MutationTree<LogRequestModalState> = {
    [UPDATE_MODAL_ACTIVE] (state, payload: boolean): void {
      state.isModalActive = payload;
    },

    [UPDATE_CURRENT_WEBMASTER] (state, payload): void {
      state.currentWebmaster = payload;
    },
    
    [UPDATE_OFFER_AVAILABLE_FILTERS] (state, payload: string): void {
      state.availableWebmasterId = payload;
    },
  
    [UPDATE_MODAL_FORM] (state, payload): void {
      state.form = { ...state.form, ...payload };
    }
  }
  
  public actions: ActionTree<LogRequestModalState, RootState> = {
    [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
      commit(UPDATE_MODAL_ACTIVE, payload);
    },

    [UPDATE_CURRENT_WEBMASTER] ({ commit }, payload: boolean): void {
      commit(UPDATE_CURRENT_WEBMASTER, payload);
    },
  
    [UPDATE_OFFER_AVAILABLE_FILTERS] ({ commit }, payload: string): void {
      commit(UPDATE_OFFER_AVAILABLE_FILTERS, payload);
    },
    
    [UPDATE_MODAL_FORM] ({ commit }, payload): void {
      commit(UPDATE_MODAL_FORM, payload);
    },
    
    async [SEND_REQUEST] ({ state, dispatch }, params = {}): Promise<void> {
      const moduleNamespace = "admin/capacityAndBumps/logRequest";
      let { type, newValue, datepicker, ...form } = state.form;
      
      if (type === typeEnum.PAYOUT) {
        form = { ...form, newPayoutValue: newValue, ...params };
      } else if (type === typeEnum.GUARANTEE) {
        form = { ...form, startDateForApprove: datepicker.dateStart, endDateForApprove: datepicker.dateEnd, ...params };
      } else {
        form = { ...form, newCapacityValue: newValue, ...params };
      }
      
      dispatch(
        `admin/capacityAndBumps/capacity/webmastersCapacity/${ _camelCase(type) }Request/UPDATE_${ type }_REQUEST`,
        { type, ...form },
        { root: true }
      );
      await dispatch(
        `admin/capacityAndBumps/capacity/webmastersCapacity/${ _camelCase(type) }Request/CREATE_${ type }_REQUEST`,
        { moduleNamespace, ...form },
        { root: true }
      );
      
      dispatch(SET_EMPTY);
    },
    
    [SET_EMPTY_WEBMASTERS] ({ commit }): void {
      commit(`webmastersOfferList/${ SET_EMPTY }`);
      commit(UPDATE_MODAL_FORM, { webmasterId: null });
    },
    
    [SET_EMPTY] ({ commit }): void {
      commit(SET_EMPTY);
      commit(`offerEditWebmastersAvailableList/${ SET_EMPTY }`);
      commit(`webmastersOfferList/${ SET_EMPTY }`);
      commit(`webmastersList/${ SET_EMPTY }`);
      commit(`offersList/${ SET_EMPTY }`);
    }
  }
  
  private readonly initState: () => LogRequestModalState;
  
  constructor (modules: ModuleTree<RootState>) {
    this.namespaced = true;
    this.initState = (): LogRequestModalState => {
      return {
        form: {
          type: typeEnum.PAYOUT as TypeEnum,
          offerId: null,
          webmasterId: null,
          externalWebmasterId: null,
          newValue: null,
          oldValue: null,
          webmasterRate: null,
          reason: null,
          creatorComment: null,
          datepicker: datepicker({ amount: 10, unit: "days" }),
          guaranteePercent: null,
          dayCount: null
        },
        isModalActive: false,
        currentWebmaster: null,
        availableWebmasterId: null
      };
    };
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
  
    this.state = this.initState();
    this.modules = modules;
    
    return this;
  }
}

export const logRequestModal = new LogRequestModal({
  offerEditWebmastersAvailableList,
  webmastersOfferList,
  webmastersList,
  offersList: new OfferFilters()
});