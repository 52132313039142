import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";

export default function allowedOfferParametersGenerator (
  offerId: string,
  webmasterId: string,
  externalWebmasterId?: string
): ASTGeneratedOutput {
  const { isShowCommissions } = useOffers();
  const exclude = !isShowCommissions ? [
    "minimalCommission/value",
    "minimalCommission/currency",
    "webmasterIndividualRate/value",
    "webmasterIndividualRate/currency"
  ] : [];
  const money = ["value", "currency"];

  const params = { exclude };

  const gqlObject = {
    operation: "allowedOfferParameters",

    fields: [
      { minimalPayout: money },
      { webmasterPayout: money },
      { webmasterIndividualRate: money },
      { minimalCommission: money },
      "softCapacity"
    ],

    variables: {
      offerId: {
        type: "Uid!",
        value: offerId
      },
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      },
      externalWebmasterId: {
        type: "String",
        value: externalWebmasterId
      }
    }
  };

  return Generator.query(gqlObject, params);
}