












































































import { ref, computed, onMounted, ComponentPublicInstance, watch } from "@vue/composition-api";
import { withDefaults, defineProps, defineEmits } from "@vue/runtime-dom";
import FieldGroup from "@/components/Common/Fields/FieldGroup.vue";
import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";
import { i18n } from "@core/plugins";
type Country = {
  name: string;
  code: number;
};
interface Props {
  value: number | null;
  country: Country | null;
  countries: Country[] | never[];
  label: string;
  required: boolean;
  message: string | null;
  requiredField: boolean;
  pattern: string | RegExp | null;
  placeholder: string | null;
  disabled: boolean;
  noticeType: string | null;
  notice: string | null;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: [Number, null],
    default: null
  },
  country: {
    key: "country",
    required: true,
    type: null
  },
  countries: {
    key: "countries",
    required: true,
    type: Array
  },
  label: {
    key: "label",
    required: false,
    type: String,
    default: ""
  },
  required: {
    key: "required",
    required: false,
    type: Boolean,
    default: false
  },
  message: {
    key: "message",
    required: false,
    type: [String, null],
    default: null
  },
  requiredField: {
    key: "requiredField",
    required: false,
    type: Boolean,
    default: false
  },
  pattern: {
    key: "pattern",
    required: false,
    type: [String, null],
    default: null
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: [String, null],
    default: null
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  },
  noticeType: {
    key: "noticeType",
    required: false,
    type: [String, null],
    default: "is-warning"
  },
  notice: {
    key: "notice",
    required: false,
    type: [String, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const validateNumber = "return (event.charCode !== 8 && event.charCode === 0 || event.charCode >= 48 && event.charCode <= 57)";
  const props = __props;
  const emit = __ctx.emit;
  onMounted(() => {
    if (props.country) {
      const {
        code
      } = props.country!;
      currentCode.value = code;
      searchCode.value = code;
    } else {
      const unwatch = watch(() => props.country, () => {
        const {
          code
        } = props.country!;
        currentCode.value = code;
        searchCode.value = code;
        unwatch();
      });
    }
    if (props.value) {
      numberValue.value = replaceCurrentCode(props.value);
    } else {
      const unwatch = watch(() => props.value, () => {
        numberValue.value = replaceCurrentCode(props.value);
        unwatch();
      });
    }
  });
  const input = ref<ComponentPublicInstance<any> | null>(null);
  const dropdown = ref<ComponentPublicInstance<any> | null>(null);
  const currentCode = ref<number | null>(null);
  const searchCode = ref<number | null>(null);
  const isActive = ref(false);
  const numberValue = ref<number | null>(null);
  const validationMassage = ref<string | null>(null);
  const currentCodeLength = computed(() => currentCode.value?.toString().length);
  const filteredCodes = computed(() => {
    return searchCode.value ? filteredDataBySearch(props.countries, searchCode.value.toString(), ["code"], 0.4).items : props.countries;
  });
  function updateValue(value?: string) {
    if (value) {
      emit("input", +`${searchCode.value}${value?.replace(/\D/g, "")}`);
    } else {
      emit("input", null);
    }
  }
  function checkValidation({
    target
  }: {
    target: HTMLOutputElement;
  }) {
    if (filteredCodes.value.some(({
      code
    }) => code === searchCode.value)) {
      validationMassage.value = !target.validity.valid ? props.message ?? target.validationMessage : null;
    }
  }
  function onChangeCurrentNumberCode(value: string) {
    if (filteredCodes.value.some(({
      code
    }) => code === +value)) {
      currentCode.value = +value;
    }
  }
  function validateCurrentNumberCode() {
    if (filteredCodes.value.some(({
      code
    }) => code === searchCode.value)) {
      validationMassage.value = null;
    } else {
      validationMassage.value = i18n.t("common.auth.signUp.error.invalidCodeCountry") as string;
      searchCode.value = null;
      currentCode.value = null;
    }
  }
  function onInputSelectionChange() {
    input.value.$refs.input.selectionStart = searchCode.value?.toString().length;
    input.value.$refs.input.selectionEnd = searchCode.value?.toString().length;
  }
  function changeDropdownToFocus() {
    if (isActive.value) {
      dropdown.value.toggle();
    }
  }
  function clearInvalidNumber({
    target: {
      value
    }
  }: {
    target: HTMLOutputElement;
  }) {
    if (isNaN(+value) || +value === 0) {
      numberValue.value = null;
    }
  }
  function replaceCurrentCode(value: number | null) {
    if (currentCode.value) {
      return value ? +value?.toString()?.replace(currentCode.value.toString(), "") : value;
    }
    return value;
  }
  watch(() => props.value, value => {
    if (!value) {
      numberValue.value = null;
    }
  });
  return {
    validateNumber,
    input,
    dropdown,
    currentCode,
    searchCode,
    isActive,
    numberValue,
    validationMassage,
    currentCodeLength,
    filteredCodes,
    updateValue,
    checkValidation,
    onChangeCurrentNumberCode,
    validateCurrentNumberCode,
    onInputSelectionChange,
    changeDropdownToFocus,
    clearInvalidNumber
  };
};
__sfc_main.components = Object.assign({
  FieldGroup
}, __sfc_main.components);
export default __sfc_main;
