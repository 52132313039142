import { dashboard } from "../modules/webmaster/dashboard";
import { dictionaries } from "@core/store/modules/webmaster/dictionaries";
import { finances } from "@core/store/modules/webmaster/finances";
import { flowsOld } from "@core/store/modules/webmaster/flows";
import { flow } from "@core/store/modules/webmaster/flows/flow";
import { flows } from "@core/store/modules/webmaster/flows/flows";
import { news } from "@core/store/modules/webmaster/news";
import { subAccounts } from "@core/store/modules/webmaster/subAccounts/subAccounts";
import { profile } from "@core/store/modules/webmaster/profile";
import { statistic } from "@core/store/modules/webmaster/statistic";
import { Module } from "@core/store/logic/Module";
import { offerFlows } from "@core/store/modules/webmaster/offer/offerFlows";
import { WebmasterOfferModule } from "@core/store/modules/webmaster/offer/WebmasterOfferModule";
import { offers } from "@core/store/modules/webmaster/offers/offers";
import { domains } from "@core/store/modules/webmaster/domains";
import { analytic } from "@core/store/modules/webmaster/analytic";
import { discounts } from "@core/store/modules/webmaster/discounts/discounts";
import { referrals } from "@core/store/modules/webmaster/referrals/referrals";
import { competitionRating } from "@core/store/modules/webmaster/competition/competitionRating";

export class CoreWebmaster<S, R> extends Module<S, R> {
  constructor () {
    super();

    this.namespaced = true;

    this.modules({
      // * Statistic
      statistic,
      analytic,
      dashboard,
      // * Offers
      offers,
      offer: new WebmasterOfferModule().toModule(),
      // * Flows
      flows,
      flow,
      flowsOld,
      offerFlows,
      // * Other
      profile,
      dictionaries,
      competitionRating,
      news,
      subAccounts,
      domains,
      referrals,
      finances,
      discounts
    });
  }
}
