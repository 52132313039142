import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { FETCH_USER, EDIT_USER, SET_EMPTY } from "@core/store/action-constants";
import { ProfileService } from "@core/services/admin/profile/ProfileService";
import {
  UPDATE_ADMIN_PROFILE_FORM,
  SET_EMPTY_PARTIAL,
  UPDATE_MODAL,
  SAVE_USER, UPDATE_USER
} from "@core/store/mutation-constants";
import { AdminForState, ProfileState } from "@core/store/types/admin/profile";
import { password } from "./modules/password";

const initialState = (): ProfileState => {
  return {
    user: null,
    form: {},
    modal: {
      isActive: false
    }
  };
};

const state: () => ProfileState = initialState;

const mutations: MutationTree<ProfileState> = {
  [SAVE_USER] (state: ProfileState, user): void {
    state.user = user;
  },

  [UPDATE_USER] (state, user) {
    state.user = { ...state.user, ...user };
  },

  [UPDATE_ADMIN_PROFILE_FORM] (state: ProfileState, form): void {
    state.form = { ...state.form, ...form };
  },

  [UPDATE_MODAL] (state: ProfileState, modal): void {
    state.modal = { ...state.modal , ...modal };
  },

  [SET_EMPTY] (state: ProfileState): void {
    Object.assign(state, initialState());
  },

  [SET_EMPTY_PARTIAL] (state: ProfileState): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, ...emptyFields } = initialState();    
    Object.assign(state, emptyFields);
  }
};

const actions: ActionTree<ProfileState, RootState> = {
  async [FETCH_USER] ({ commit }): Promise<AdminForState> {
    const { data: { profile } } = await ProfileService.getProfile();
    commit(SAVE_USER, profile);
  
    return profile;
  },
  
  async [EDIT_USER] ({ commit, state: { form } }): Promise<void> {
    const { data: { editAdminProfile } } = await ProfileService.editProfile({ ...form });
    commit(SAVE_USER, editAdminProfile);
  },

  [UPDATE_USER] ({ commit }, payload) {
    commit(UPDATE_USER, payload);
  },

  [UPDATE_MODAL] ({ commit }, modal): void {
    commit(UPDATE_MODAL, modal);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    password
  }
};
