import { encodeFilters } from "@core/filters";

export function setQuery (intId: number, createdAt: Date, type: string) {
  return {
    filters: encodeFilters({
      intId,
      datepicker: {
        dateStart: createdAt, dateEnd: createdAt
      },
      type: [type.toUpperCase()]
    })
  };
}