import { UPDATE_ADMIN_BY_ID, GET_ADMIN_BY_ID } from "@core/store/action-constants";
import { SET_EMPTY } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AdminDataById } from "@core/store/types/admin/common/AdminOutput";
import { AdminByIdService } from "@core/services/admin/common/AdminByIdService";

const initialState = (): AdminDataById => {
  return {
    data: null
  };
};

const state: () => AdminDataById = initialState;

const getters: GetterTree<AdminDataById, RootState> = {};

const mutations: MutationTree<AdminDataById> = {
  [UPDATE_ADMIN_BY_ID] (state: AdminDataById, data: AdminDataById["data"]): void {
    state.data = { ...state.data, ...data };
  },
  
  [SET_EMPTY]: (state: AdminDataById): AdminDataById => Object.assign(state, initialState())
};

const actions: ActionTree<AdminDataById, RootState> = {
  async [GET_ADMIN_BY_ID] ({ commit }, id: string): Promise<void> {
    const adminById = await AdminByIdService.getAdminById(id);
    commit(UPDATE_ADMIN_BY_ID, { [id]: adminById });
  },
  
  [SET_EMPTY]: ({ commit }): void => {
    commit(SET_EMPTY);
  }
};

export const adminDataById: Module<AdminDataById, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
