import lemonad from "./configs/lemonad.config.json";
import cultureMedia from "./configs/cultureMedia.config.json";
import { UnknownObject } from "@core/store/types/common";
import Vue from "vue";

const configs: UnknownObject = {
  lemonad,
  cultureMedia
};
const name: string = process.env.VUE_APP_SAAS_CONFIGURATION_NAME || "lemonad";

Vue.prototype.$saas = configs[name];

document.title = configs[name].name;

let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.head.appendChild(link);
}
link.href = require(`@/assets/${ configs[name].favicon }`);

export default function useSaas () {
  return configs[name];
}