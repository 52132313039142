import {
  SET_EMPTY,
  UPDATE_SELECTED,
  UPDATE_SELECTED_VUETABLE,
  FILTER_SELECTED
} from "@core/store/action-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { UnknownObject } from "@core/store/types/common";
import { SelectedItem, SnackbarActionsState } from "@core/store/types/common/snackbarActions";

const initialState = (): SnackbarActionsState => {
  return {
    selected: []
  };
};

const state: () => SnackbarActionsState = initialState;

const getters: GetterTree<SnackbarActionsState, RootState> = {};

const mutations: MutationTree<SnackbarActionsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [UPDATE_SELECTED] (state, payload) {
    state.selected = payload;
  }
};

const actions: ActionTree<SnackbarActionsState, RootState> = {
  [UPDATE_SELECTED] ({ commit }, items) {
    commit(UPDATE_SELECTED, items);
  },
  
  [UPDATE_SELECTED_VUETABLE] ({ commit, state }, { isChecked, selectedItems, toggleAll }) {
    // * убрать когда в проекте перепишем vuetable на таблицу buefy
    let filteredItems = state.selected?.length ? [ ...state.selected ] : [];
  
    if (!toggleAll) {
      // Toggle one
      if (isChecked) {
        filteredItems.push(selectedItems);
      } else {
        filteredItems = filteredItems.filter(({ id }) => id !== selectedItems.id);
      }
    } else {
      // Toggle all
      if (isChecked) {
        filteredItems = [...filteredItems, ...selectedItems];
      } else {
        const itemsIds = (selectedItems || []).map(({ id }: UnknownObject) => id);
        filteredItems = filteredItems.filter(({ id }) => !itemsIds.includes(id));
      }
    }
    commit(UPDATE_SELECTED, filteredItems);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  },

  [FILTER_SELECTED] ({ state, commit }, ids) {
    const filteredSelected = state.selected?.filter((item: SelectedItem) => !ids.includes(item.id));
    commit(UPDATE_SELECTED, filteredSelected);
  }
};

export const snackbarActions: Module<SnackbarActionsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
