import Service from "@core/services/common/Service";
import orderInTransaction from "@core/services/advertiser/payments/graphql/orderInTransaction.graphql";

type HTTPMethod = "GET" | "POST";

interface WalletRequest {
  paymentSystemId: string;
  amount: number;
}

interface WalletResponse {
  "id": number;
  "amount": number;
  "formSetting": {
    "attribute": any[];
    "link": string;
    "method": HTTPMethod;
  };
  "createdAt": string;
}

export class RefillService extends Service {
  public static async getRefillInfo (input: WalletRequest): Promise<WalletResponse> {
    try {
      return await this.api.post("", {
        query: orderInTransaction,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
