import { PasswordService } from "@core/services/common/PasswordService";
import { CHANGE_USER_PASSWORD } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_PASSWORD_FORM } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ChangePasswordInput, PasswordState } from "@core/store/types/common/password";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): PasswordState => {
  return {
    form: {}
  };
};

const state: () => PasswordState = initialState;

const mutations: MutationTree<PasswordState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_PASSWORD_FORM] (state, form: PasswordState["form"]) {
    state.form = { ...state.form, ...form };
  }
};

const actions: ActionTree<PasswordState, RootState> = {
  async [CHANGE_USER_PASSWORD] ({ state: { form: { oldPassword, newPassword } } }) {
    const input: ChangePasswordInput = { oldPassword, newPassword } as ChangePasswordInput;
    await PasswordService.changePassword(input);
  },

  [UPDATE_PASSWORD_FORM] ({ commit }, password) {
    commit(UPDATE_PASSWORD_FORM, password);
  }
};

export const password: Module<PasswordState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
