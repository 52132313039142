import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { OfferLandingsResponse } from "@core/store/types/admin/offer/OfferLandings";

export default function offerLanding (
  { limit, offset, filters }: OfferLandingsResponse,
  user: string
): ASTGeneratedOutput {
  const dataSet = {
    advertiser: [],
    webmaster: [
      "items/isExistCurrentFile"
    ],
    admin: [
      "items/integrationId",
      "items/isPublic",
      "items/lastFile/status",
      "items/lastFile/reasonForFail",
      "items/lastFile/fileId",
      "items/currentFile/status",
      "items/currentFile/fileId",
      "items/isReady",
      "items/external/hostname",
      "items/external/path",
      "items/external/query",
      "items/external/isSecure",
      "items/webmasters/intId",
      "items/webmasters/login",
      "items/webmasters/id",
      "items/createdAt"
    ]
  };
  // @ts-ignore
  const params = { include: dataSet[user] };
  
  const gqlObject = {
    operation: "landingPages",
    
    fields: [
      {
        items: [
          "name",
          "link",
          "createdAt",
          "designer",
          "isTikTok",
          "previewLink",
          "id",
          {
            language: [
              "alias",
              "name"
            ]
          }
        ]
      },
      "count"
    ],
    
    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      filters: {
        type: "PageListFiltersInput!",
        value: filters
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}
