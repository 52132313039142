<template>
    <LTooltip
        :label="$t(`common.navBar.theme.${ themes[currentTheme] }`)"
        position="is-bottom"
        type="is-dark">
        <img
            class="is-clickable"
            :src="themeIconSrc"
            alt=""
            @click="changeTheme">
    </LTooltip>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { UPDATE_THEME } from "@core/store/action-constants";

  export default {
    name: "ThemeToggle",

    created () {
      this.UPDATE_THEME(localStorage.getItem("theme") || "light");
    },

    computed: {
      ...mapState("theme", {
        currentTheme: ({ currentTheme }) => currentTheme
      }),

      themeIconSrc () {
        return require(`@/assets/NavigationBar/socials/${ this.themes[this.currentTheme] }.svg`);
      },

      themes () {
        return {
          "dark": "light",
          "light": "dark"
        };
      }
    },

    methods: {
      ...mapActions("theme", {
        UPDATE_THEME
      }),

      changeTheme () {
        const htmlEl = document.getElementsByTagName("html")[0];

        if (this.currentTheme === "light") {
          htmlEl.classList.add("dark");
        } else {
          htmlEl.classList.remove("dark");
        }

        this.UPDATE_THEME(this.themes[this.currentTheme]);
        localStorage.setItem("theme", this.currentTheme);

      }
    }
  };

</script>
<style scoped lang="scss">

</style>