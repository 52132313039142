import {
  UPDATE_OPTIONS
} from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { UploadingSettingsState } from "@core/store/types/webmaster/offer/UploadingSettingsModal";
import { DomonetizationTypes } from "@core/store/types/webmaster/offer/domonetizationTypes";

const initialState = (): UploadingSettingsState => {
  return {
    options: {
      isDomonetizationDisable: true,
      domonetizationType: DomonetizationTypes.WHITE
    }
  };
};

const state: () => UploadingSettingsState = initialState;

const mutations: MutationTree<UploadingSettingsState> = {
  SET_EMPTY: (state: UploadingSettingsState): UploadingSettingsState => Object.assign(state, initialState()),

  [UPDATE_OPTIONS] (state: UploadingSettingsState, form: UploadingSettingsState["options"]): void {
    state.options = { ...state.options, ...form };
  }
};

const actions: ActionTree<UploadingSettingsState, RootState> = {
  [UPDATE_OPTIONS] ({ commit }, form: UploadingSettingsState["options"]): void {
    commit(UPDATE_OPTIONS, form);
  }
};

export const uploadingSettings: Module<UploadingSettingsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
