import store from "../../../store";
import { Money } from "@core/store/types/common";

export function sortByPreferredCurrency (array: Money[] | string[]) {
  const preferredCurrency = store.state.auth.preferredCurrency?.toUpperCase();
  if (array && Array.isArray(array)) {
    return [...array].sort((a) => {
      if (typeof a === "object") {
        return a.currency.toUpperCase() === preferredCurrency ? -1 : 1;
      } else {
        return a.toUpperCase() === preferredCurrency ? -1 : 1;
      }
    });
  }
}
