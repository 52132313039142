import { GET_ALLOWED_OFFER_PARAMETERS } from "@core/store/action-constants";
import { UPDATE_ALLOWED_OFFER_PARAMETERS } from "@core/store/mutation-constants";

import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AllowedOfferParametersService } from "@core/services/admin/capacityAndBumps/logRequest/AllowedOfferParametersService";
import { AllowedOfferParametersData } from "@core/store/types/admin/offers/bampsAndCaps";

const initialState = (): AllowedOfferParametersData => {
  return {
    data: null
  };
};

const state: () => AllowedOfferParametersData = initialState;

const getters: GetterTree<AllowedOfferParametersData, RootState> = {};

const mutations: MutationTree<AllowedOfferParametersData> = {
  SET_EMPTY: (state): AllowedOfferParametersData => Object.assign(state, initialState()),
  
  [UPDATE_ALLOWED_OFFER_PARAMETERS] (state, data: AllowedOfferParametersData["data"]): void {
    state.data = data;
  }
};

const actions: ActionTree<AllowedOfferParametersData, RootState> = {
  async [GET_ALLOWED_OFFER_PARAMETERS] ({ commit }, { webmasterId, offerId, externalWebmasterId }): Promise<void> {
    const data = await AllowedOfferParametersService.getAllowedOfferParameters(webmasterId, offerId, externalWebmasterId);
    
    commit(UPDATE_ALLOWED_OFFER_PARAMETERS, data);
  }
};

export const allowedOfferParameters: Module<AllowedOfferParametersData, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
