import { CRMService } from "@core/services/admin/crm/CRMService";
import {
  CHECK_VALID_OPTIONS,
  CREATE_CRM,
  DEFAULT_OPTIONS,
  EDIT_CRM,
  GET_FILTERED_CUSTOM_MACROS,
  PROXY_CRM,
  UPDATE_FLOW_REDIRECT,
  UPDATE_IS_WITHOUT_REQUEST,
  UPDATE_IS_AUTO_REQUESTS, SET_EMPTY
} from "@core/store/action-constants";
import { flowRedirectMacrosList } from "@core/store/modules/admin/lists/flowRedirectMacrosList";
import { macrosList } from "@core/store/modules/admin/crm/lists/macrosList";
import { methodsList } from "@core/store/modules/admin/crm/lists/methodsList";
import { recoveryStrategyList } from "@core/store/modules/admin/crm/lists/recoveryStrategyList";
import { requestTypesList } from "@core/store/modules/admin/crm/lists/requestTypesList";
import { responseTypesList } from "@core/store/modules/admin/crm/lists/responseTypesList";
import {
  UPDATE_CUSTOM_MACROS,
  UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL,
  UPDATE_INTEGRATION_DETAILS,
  UPDATE_RECOVERY_DETAILS,
  UPDATE_IS_VALID,
  UPDATE_MODAL_ACTIVE,
  UPDATE_MODAL_IS_EDIT,
  UPDATE_MODAL_OPTIONS,
  UPDATE_POSTBACK_STATUS_MAP
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  PartialCreateCRMState,
  IntegrationDetail,
  RecoveryDetail,
  CustomMacros,
  CRMOptions,
  StatusMap, DefaultMacros
} from "@core/store/types/admin/crm/CreateCRMState";
import _uniq from "lodash/uniq";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { strategyList } from "@core/store/modules/admin/crm/lists/strategyList";
import { transferRulesUrlList } from "@core/store/modules/admin/crm/lists/passingRulesIdUrlList";
import { responseFormatTypesList } from "@core/store/modules/admin/crm/lists/responseFormatTypesList";
import { getCustomMacros, getMacrosJson, getMacrosString } from "@core/store/modules/admin/crm/crmUpdateMacros";
import { crmFailRule } from "@core/store/modules/admin/crm/crmFailRule";

const initialState = (): PartialCreateCRMState => {
  return {
    options: {
      name: null,
      alias: null,
      integrationDetail: {
        requestUrl: null,
        requestMethod: "GET",
        requestBodyType: "EMPTY",
        requestHeaders: null,
        requestBody: null,
        responseType: null,
        responseXpath: null,
        requireRemoteId: false
      },
      postbackDetail: {
        strategy: "TOKEN_AND_REMOTE_ID",
        statusMap: {
          accept: [],
          spam: [],
          cancel: [],
          redemption: [],
          returnField: []
        }
      },
      recoveryDetail: {
        isBulkRequests: false,
        transferIdKey: null,
        maxBulkRequests: null,
        requestUrl: null,
        transferIdStrategy: null,
        responseType: null,
        requestHeaders: null,
        responseXpath: null
      },
      isAutoRequests: false,
      flowRedirect: null
    },
    customMacros: {
      customMacros: [],
      customFields: {}
    },
    
    isCRMCreateModalActive: false,
    isEdit: false,
    isValidOptions: true,
    isWithoutRequest: false
  };
};

const state: () => PartialCreateCRMState = initialState;

const getters: GetterTree<PartialCreateCRMState, RootState> = {
  [DEFAULT_OPTIONS]: state => function (): DefaultMacros {
    const macros = {};
    state.customMacros.customMacros.forEach((customMacros: string) => {
      Vue.set(macros, customMacros, state.customMacros.customFields[customMacros] || "");
    });
    return macros;
  },
  
  [GET_FILTERED_CUSTOM_MACROS]: state => function (): any {
    const macrosRequestUrl = getMacrosString(state.options.integrationDetail.requestUrl) || [],
      macrosRequestBody = getMacrosJson(state.options.integrationDetail.requestBody) || [],
      macrosRequestHeaders = getMacrosJson(state.options.integrationDetail.requestHeaders) || [],
      macrosRecoveryDetailRequestHeaders = getMacrosJson(state.options.recoveryDetail.requestHeaders) || [],
      macrosRecoveryRequestUrl = getMacrosString(state.options.recoveryDetail.requestUrl) || [],
      macrosFlowRedirect = getMacrosJson(state.options.flowRedirect) || [];
    return _uniq(getCustomMacros(
      [
        ...macrosRequestUrl,
        ...macrosRequestBody,
        ...macrosFlowRedirect,
        ...macrosRequestHeaders,
        ...macrosRecoveryRequestUrl,
        ...macrosRecoveryDetailRequestHeaders
      ],
      [...new Set([...state.macrosList.data, ...state.flowRedirectMacrosList.data])]));
  }
};

const mutations: MutationTree<PartialCreateCRMState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_OPTIONS] (state, option: CRMOptions) {
    if (option.integrationDetail === null) {
      option.integrationDetail = initialState().options.integrationDetail;
    }
    Object.assign(state.options, state.options, option);
  },
  
  [UPDATE_INTEGRATION_DETAILS] (state, option: IntegrationDetail) {
    state.options.integrationDetail = { ...state.options.integrationDetail, ...option };
  },
  
  [UPDATE_RECOVERY_DETAILS] (state, option: RecoveryDetail) {
    state.options.recoveryDetail = { ...state.options.recoveryDetail, ...option };
  },
  
  [UPDATE_POSTBACK_STATUS_MAP] (state, option: StatusMap) {
    state.options.postbackDetail.statusMap = { ...state.options.postbackDetail.statusMap, ...option };
  },
  
  [UPDATE_CUSTOM_MACROS] (state, macros: CustomMacros) {
    state.customMacros = { ...state.customMacros, ...macros };
  },
  
  [UPDATE_FLOW_REDIRECT] (state, payload: { flowRedirect: string }) {
    state.options.flowRedirect = payload.flowRedirect;
  },
  
  [UPDATE_MODAL_IS_EDIT] (state, payload: boolean) {
    state.isEdit = payload;
  },
  
  [UPDATE_IS_VALID] (state, payload: boolean) {
    state.isValidOptions = payload;
  },
  
  [UPDATE_MODAL_ACTIVE] (state, payload: boolean) {
    state.isCRMCreateModalActive = payload;
  },
  
  [UPDATE_IS_WITHOUT_REQUEST] (state, payload: boolean) {
    state.isWithoutRequest = payload;
  },
  
  [UPDATE_IS_AUTO_REQUESTS] (state, payload: boolean) {
    state.options.isAutoRequests = payload;
  }
};

const actions: ActionTree<PartialCreateCRMState, RootState> = {
  async [PROXY_CRM] ({
    state,
    dispatch
  }) {
    try {
      if (state.isEdit) {
        await dispatch(EDIT_CRM);
      } else {
        await dispatch(CREATE_CRM);
      }
    } catch (e) {
      throw e;
    }
  },
  
  async [CREATE_CRM] ({
    state,
    getters,
    commit
  }) {
    const defaultOptions = getters[DEFAULT_OPTIONS]();
    const created = await CRMService.createCRM(
      {
        ...state.options,
        defaultOptions
      },
      state.isWithoutRequest
    );
  
    commit("admin/crm/LOCAL_ADD", { items: created, target: "CRM", param: "name", infiniteScroll: true }, { root: true });
  },
  
  async [EDIT_CRM] ({
    state,
    getters,
    commit
  }) {
    const {
      alias,
      ...options
    } = state.options;
    const defaultOptions = getters[DEFAULT_OPTIONS]();
    try {
      const editedCRM = await CRMService.editCRM(
        alias,
        {
          ...options,
          defaultOptions
        },
        state.isWithoutRequest
      );

      commit("admin/crm/LOCAL_UPDATE", { items: editedCRM, target: "CRM", param: "alias", infiniteScroll: true }, { root: true });
    } catch (e) {
      throw e;
    }
  },
  
  [CHECK_VALID_OPTIONS] ({
    state,
    commit
  }) {
    if (!state.isValidOptions) {
      commit(UPDATE_IS_VALID, true);
    }
    const statusMap = state.options.postbackDetail.statusMap;
    
    for (const status in statusMap) {
      if (statusMap.hasOwnProperty(status)) {
        
        if (statusMap[status].length === 0) {
          commit(UPDATE_IS_VALID, false);
        }
      }
    }
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
    commit("crmFailRule/SET_EMPTY_OPTIONS");
  },
  
  [UPDATE_IS_WITHOUT_REQUEST] ({ commit }, payload: boolean) {
    commit(UPDATE_IS_WITHOUT_REQUEST, payload);
  },
  
  [UPDATE_IS_AUTO_REQUESTS] ({ commit }, payload: boolean) {
    commit(UPDATE_IS_AUTO_REQUESTS, payload);
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, payload: CRMOptions) {
    commit(UPDATE_MODAL_OPTIONS, payload);
  },
  
  [UPDATE_INTEGRATION_DETAILS] ({ commit }, payload: IntegrationDetail) {
    commit(UPDATE_INTEGRATION_DETAILS, payload);
  },
  
  [UPDATE_RECOVERY_DETAILS] ({ commit }, payload: RecoveryDetail) {
    Object.keys(payload).forEach(key => {
      // @ts-ignore
      if (payload[key] === "") {
        // @ts-ignore
        payload[key] = null;
      }
    });
    if (payload?.isBulkRequests === false) {
      payload.maxBulkRequests = null;
    }
    
    commit(UPDATE_RECOVERY_DETAILS, payload);
  },
  
  [UPDATE_CUSTOM_MACROS] ({
    commit,
    getters
  }, payload: CustomMacros) {
    commit(UPDATE_INTEGRATION_DETAILS, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL] ({
    commit,
    getters,
    dispatch
  }, payload: RecoveryDetail) {
    dispatch(UPDATE_RECOVERY_DETAILS, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_FLOW_REDIRECT] ({
    commit,
    getters
  }, payload: { flowRedirect: string }) {
    commit(UPDATE_FLOW_REDIRECT, payload);
    commit(UPDATE_CUSTOM_MACROS, { customMacros: getters[GET_FILTERED_CUSTOM_MACROS]() });
  },
  
  [UPDATE_POSTBACK_STATUS_MAP] ({ commit }, payload: StatusMap) {
    commit(UPDATE_POSTBACK_STATUS_MAP, payload);
  }
};

export const crmCreateModal: Module<PartialCreateCRMState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    crmFailRule,
    methodsList,
    requestTypesList,
    responseTypesList,
    strategyList,
    macrosList,
    recoveryStrategyList,
    transferRulesUrlList,
    responseFormatTypesList,
    flowRedirectMacrosList
  }
};
