import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { PaginationState } from "@core/store/types/PaginationState";

export const updateList = (state: PaginationState<{ data: PaginationOutput<any> | null }>, data: PaginationOutput<any>) => {
  if (state.pagination.page !== 1 && state?.data?.items && data?.items) {
    state.data.items = Array.from(new Set([...state.data.items, ...data.items]));
  } else {
    state.data = data;
  }
};
