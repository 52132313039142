import { RegionsResponse } from "@core/store/types/admin/statistic/common/regionsItem";
import Service from "@core/services/common/Service";
import axios, { AxiosPromise } from "axios";

export class RegionsListService extends Service {
  public static async getRegionsList (): Promise<AxiosPromise> {
    try {
      return await axios.get("/dictionaries/regions");
    } catch (e) {
      throw e;
    }
  }
}
