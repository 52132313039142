import { incomes } from "@core/store/modules/admin/finances/modules/financeIncomes/financesIncomes";
import { costs } from "@core/store/modules/admin/finances/modules/financesCosts/financesCosts";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { referral } from "@core/store/modules/admin/finances/modules/referral/referral";
import { advertisersDuty } from "@core/store/modules/admin/finances/modules/advertisersDuty/advertisersDuty";
import { requisitesList } from "@core/store/modules/admin/lists/requisitesList";
import { webAccrual } from "./modules/webAccrual";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { webmastersList } from "@core/store/modules/admin/lists/webmastersList";

export const finances = {
  namespaced: true,
  modules: {
    costs,
    requisitesList,
    paymentSystemsList,
    webAccrual,
    referral,
    advertisersDuty,
    incomes,
    // TODO: Удалить как только перепишем фильтры ан pinia
    advertisersList,
    webmastersList
  }
};
