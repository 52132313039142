import { SetupStoreDefinition, StoreDefinition } from "pinia";
import { Params, ProcessedData } from "@core/store/types/common/StateUpdater";
import _keyBy from "lodash/keyBy";
import { UnknownObject } from "@core/store/types/common";
import router from "@/router";

function processData ({ items, target = "data", param = "id" }: Params): ProcessedData {
  items = Array.isArray(items) ? items : [items];
  return { target, param, items, groupItems: _keyBy(items, param) };
}

export function useStateUpdater (defineStore: StoreDefinition | SetupStoreDefinition<string, UnknownObject>) {
  return {
    LOCAL_ADD: (payload: Params) => {
      const store = defineStore();
      const { items, target } = processData(payload);

      const updateData = () => {
        store[target] = {
          ...store[target],
          items: [...items, ...store[target].items],
          count: store[target].count + items.length,
          aggregates: store[target].aggregates
        };
        if (store.pagination?.perPage) {
          store.pagination.perPage += 1;
        }
      };

      if (store[target]) {
        if (payload?.infiniteScroll) {
          updateData();
        } else {
          if (store.pagination?.page > 1) {
            store.pagination.page = 1;
          } else if (+router.currentRoute.query.page > 1) {
            router.push({
              // @ts-ignore
              query: { ...router.currentRoute.query, page: 1 }
            }).catch(_ => _);
          } else {
            updateData();
          }
        }
      }
    },

    LOCAL_UPDATE: (payload: Params) => {
      const store = defineStore();
      const { groupItems, target, param } = processData(payload);

      if (store[target]) {
        store[target].items = store[target].items.map((item: UnknownObject) =>
          groupItems[item[param]] ? { ...item, ...groupItems[item[param]] } : item);
      }
    },

    LOCAL_DELETE: (payload: Params) => {
      const store = defineStore();
      const { groupItems, target, param } = processData(payload);

      if (store[target]) {
        const items = store[target].items.filter((item: UnknownObject) => !groupItems[item[param]]);

        store[target] = {
          items,
          count: store[target].count - Object.keys(groupItems).length
        };
        if (store.pagination?.perPage) {
          store.pagination.perPage -= 1;
        }
      }
    }
  };
}
