import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { CapacityOrder, CapacitySort } from "@core/store/types/admin/offers/capacity";

export default function externalWebmasterCapacity (
  limit: number,
  offset: number,
  webmasterId: string,
  offerId: string,
  sort: CapacitySort,
  order: CapacityOrder,
  isRequest: boolean,
  isIndividualRate: boolean,
  isShowRedemption: boolean,
  isShowCommission: boolean
): ASTGeneratedOutput {
  
  const include = [];
  
  if (isShowRedemption) {
    include.push("items/monthRedemption");
  }

  if (isShowCommission) {
    include.push("items/payoutRequest/webmasterRate");
  }
  
  if (isIndividualRate && isShowCommission) {
    include.push(
      "items/webmasterIndividualRate/value",
      "items/webmasterIndividualRate/currency"
    );
  }
  const params = {
    include
  };
  
  const uniqId = `${ webmasterId }-${ offerId }`;
  const moneyType = ["value", "currency"];
  const userType = ["name", "id"];
  
  const gqlObject = {
    operation: "externalWebmasterCapacity",
    
    fields: [
      {
        items: [
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "isBindedToCurrentAdmin"
            ]
          },
          {
            payoutRequest: [
              "createdAt",
              "updatedAt",
              {
                creator: userType
              },
              "creatorComment",
              {
                executor: userType
              },
              "executorComment",
              "externalWebmaster",
              "goal",
              "newPayoutValue",
              "oldPayoutValue",
              "status",
              "id"
            ]
          },
          {
            webmasterPayout: moneyType
          },
          {
            requiredApprove: [
              "requiredApprove",
              "updatedAt"
            ]
          },
          "externalWebmasterId",
          "yesterdayLeads",
          "rateUpdatedAt",
          "payoutUpdatedAt",
          "weekLeads",
          "monthLeads"
        ]
      },
      "count"
    ],
  
    variables: {
      limit: {
        type: "Int!",
        value: limit
      },
      offset: {
        type: "Int!",
        value: offset
      },
      webmasterId: {
        type: "Uid!",
        value: webmasterId
      },
      offerId: {
        type: "Uid!",
        value: offerId
      },
      sort: {
        type: "ExternalWebmasterCapacitySort",
        value: sort[uniqId] ?? "yesterdayLeads"
      },
      order: {
        type: "SortOrder",
        value: order[uniqId] ?? "ASC"
      },
      isRequest: {
        type: "Boolean!",
        value: isRequest
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}