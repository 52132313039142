import { telegramService } from "@core/services/common/TelegramService";
import {
  TELEGRAM_CONNECT
} from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module } from "vuex";

const actions: ActionTree<RootState, RootState> = {
  async [TELEGRAM_CONNECT] () {
    const form = sessionStorage.getItem("telegram_connect");
  
    try {
      if (form) {
        const {
          id,
          auth_date,
          ...params
        } = JSON.parse(form);
  
        const { data: { result } } = await telegramService.connect(
          {
            id: Number(id),
            auth_date: Number(auth_date),
            ...params
          }
        );
        return result;
      }
    } catch (e) {
      throw e;
    } finally {
      sessionStorage.removeItem("telegram_connect");
    }
  }
};

export const telegram: Module<RootState, RootState> = {
  namespaced: true,
  actions
};