import {
  SET_DEPOSIT_HISTORY,
  UPDATE_MODAL,
  UPDATE_PAGINATION,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import {
  GET_DEPOSIT_HISTORY,
  SET_EMPTY
} from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  DetailingDepositHistoryModalService
} from "@core/services/common/statistic/detailing/detailingDepositHistoryModalService";
import {
  DetailingDepositHistoryModalState
} from "@core/store/types/common/statistic/detailing/detailingDepositHistoryModal";
import { Pagination } from "@core/store/types/Pagination";

const initialState = (): DetailingDepositHistoryModalState => {
  return {
    data: null,
    modal: {
      isActive: false,
      leadId: null
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => DetailingDepositHistoryModalState = initialState;

const mutations: MutationTree<DetailingDepositHistoryModalState> = {
  [SET_DEPOSIT_HISTORY] (state, data: DetailingDepositHistoryModalState["data"]): void {
    state.data = data;
  },

  [UPDATE_MODAL] (state, options: DetailingDepositHistoryModalState["modal"]): void {
    state.modal = { ...state.modal, ...options };
  },

  [UPDATE_PAGINATION] (state, pagination: Pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [SET_EMPTY]: (state): DetailingDepositHistoryModalState => Object.assign(state, initialState())
};

const actions: ActionTree<DetailingDepositHistoryModalState, RootState> = {
  async [GET_DEPOSIT_HISTORY] ({ state, commit }): Promise<void> {
    const { modal: { leadId }, pagination: { perPage: limit, page } } = state;
    const offset = (page - 1) * limit;

    const data = await DetailingDepositHistoryModalService.getDepositHistory(leadId, limit, offset);

    commit(SET_DEPOSIT_HISTORY, data);
  },

  [UPDATE_MODAL] ({ commit }, options: DetailingDepositHistoryModalState["modal"]): void {
    commit(UPDATE_MODAL, options);
  },

  [UPDATE_SORTINGS] ({ commit }, payload): void {
    commit(UPDATE_SORTINGS, payload);
  },

  [UPDATE_PAGINATION] ({ commit }, pagination: Pagination): void {
    commit(UPDATE_PAGINATION, pagination);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const detailingDepositHistoryModal: Module<DetailingDepositHistoryModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
