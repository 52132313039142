// common
export const SET_MODAL_ACTIVE = "SET_MODAL_ACTIVE";
export const UPDATE_INPUT = "UPDATE_INPUT";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_EXCLUDED_FILTERS = "UPDATE_EXCLUDED_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const UPDATE_PAGINATION = "UPDATE_PAGINATION";
export const UPDATE_FLOWS_DOMAINS = "UPDATE_FLOWS_DOMAINS";
export const SET_EMPTY = "SET_EMPTY";
export const SET_EMPTY_PARTIAL = "SET_EMPTY_PARTIAL";
export const SET_EMPTY_DATA = "SET_EMPTY_DATA";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const UPDATE_SORTINGS = "UPDATE_SORTINGS";
export const UPDATE_SORTING = "UPDATE_SORTING";
export const UPDATE_USER_CONTEXT_STORAGE = "UPDATE_USER_CONTEXT_STORAGE";
export const UPDATE_DEVISE_ORIENTATION = "UPDATE_DEVISE_ORIENTATION";
export const WATCHED_PARAMS = "WATCHED_PARAMS";

// locale
export const SET_LOCALE = "SET_LOCALE";
export const SET_SETTINGS_LENG = "SET_SETTINGS_LENG";

// error
export const SHOW_ERROR = "SHOW_ERROR";
export const ERROR = "ERROR";

// success
export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const SUCCESS = "SUCCESS";

// loading
export const SHOW_LOADING = "SHOW_LOADING";

// auth
export const SAVE_USER = "SAVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_ACTIVE_SECTIONS = "SET_ACTIVE_SECTIONS";
export const SET_FINGER_PRINT = "SET_FINGER_PRINT";
export const LOGOUT = "LOGOUT";
export const SET_WHO_AM_I = "SET_WHO_AM_I";

// statistics
export const SET_STATISTIC_DATEPICKER = "SET_STATISTIC_DATEPICKER";
export const SET_OLD_STATISTIC = "SET_OLD_STATISTIC";
export const SET_NEW_STATISTIC = "SET_NEW_STATISTIC";
export const UPDATE_STATISTIC_FILTERS = "UPDATE_STATISTIC_FILTERS";
export const UPDATE_STATISTIC_EXCLUDED_FILTERS = "UPDATE_STATISTIC_EXCLUDED_FILTERS";
export const UPDATE_DASHBOARD_STATISTIC_FILTERS = "UPDATE_DASHBOARD_STATISTIC_FILTERS";
export const UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS = "UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS";
export const SET_DASHBOARD_STATISTICS = "SET_DASHBOARD_STATISTICS";
export const SET_DASHBOARD_EFFICIENCY_RATE_WIDGET = "SET_DASHBOARD_EFFICIENCY_RATE_WIDGET";
export const SET_DASHBOARD_LEAD_STATISTIC_WIDGET = "SET_DASHBOARD_LEAD_STATISTIC_WIDGET";
export const SET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET = "SET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET";
export const SET_DASHBOARD_SUMMARY_CLIENTS_WIDGET = "SET_DASHBOARD_SUMMARY_CLIENTS_WIDGET";
export const SET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET = "SET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET";
export const SET_DASHBOARD_STATISTICS_CHART_WIDGET = "SET_DASHBOARD_STATISTICS_CHART_WIDGET";
export const SET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET = "SET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET";
export const UPDATE_DASHBOARD_LAUNCH_ACTIVITY_WIDGET = "UPDATE_DASHBOARD_LAUNCH_ACTIVITY_WIDGET";
export const SET_DASHBOARD_CAPACITY_WIDGET = "SET_DASHBOARD_CAPACITY_WIDGET";
export const SET_DASHBOARD_PAYOUT_WIDGET = "SET_DASHBOARD_PAYOUT_WIDGET";
export const SET_DASHBOARD_INCIDENCE_WIDGET = "SET_DASHBOARD_INCIDENCE_WIDGET";
export const SET_DETAIL = "SET_DETAIL";
export const SET_STATS_STATUS = "SET_STATS_STATUS";
export const SET_SELECTED_LEADS = "SET_SELECTED_LEADS";
export const SET_SELECTED_OFFERS_REQUEST = "SET_SELECTED_OFFERS_REQUEST";
export const UPDATE_DETAILING_FILTERS = "UPDATE_DETAILING_FILTERS";
export const UPDATE_DETAILING_PAGINATION = "UPDATE_DETAILING_PAGINATION";
export const UPDATE_KPI_BONUS_FILTERS = "UPDATE_KPI_BONUS_FILTERS";
export const UPDATE_CAPACITY_BY_COUNTRY_FILTERS = "UPDATE_CAPACITY_BY_COUNTRY_FILTERS";
export const UPDATE_DETAILING_FIELDS= "UPDATE_DETAILING_FIELDS";
export const SET_USER_TOTAL= "SET_USER_TOTAL";
export const SET_TODAY_STATISTIC = "SET_TODAY_STATISTIC";

export const SET_FLOWS_DICTIONARY = "SET_FLOWS_DICTIONARY";
export const UPDATE_FLOWS_DICTIONARY = "UPDATE_FLOWS_DICTIONARY";

export const SET_OFFERS_DICTIONARY = "SET_OFFERS_DICTIONARY";
export const UPDATE_OFFERS_DICTIONARY = "UPDATE_OFFERS_DICTIONARY";

export const SET_WEBMASTERS_DICTIONARY = "SET_WEBMASTERS_DICTIONARY";
export const UPDATE_WEBMASTERS_DICTIONARY = "UPDATE_WEBMASTERS_DICTIONARY";

export const SET_EXECUTORS_DICTIONARY = "SET_EXECUTORS_DICTIONARY";
export const UPDATE_EXECUTORS_DICTIONARY = "UPDATE_EXECUTORS_DICTIONARY";

export const SET_ADVERTISERS_DICTIONARY = "SET_ADVERTISERS_DICTIONARY";
export const UPDATE_ADVERTISERS_DICTIONARY = "UPDATE_ADVERTISERS_DICTIONARY";

export const SET_ADMINS_DICTIONARY = "SET_ADMINS_DICTIONARY";

export const SET_CURRENCIES = "SET_CURRENCIES";

export const UPDATE_FILTER_DATA = "UPDATE_FILTER_DATA";

export const UPDATE_GLOBAL_SOURCE = "UPDATE_GLOBAL_SOURCE";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";

// detailing
export const UPDATE_LEAD_STATUSES_LIST = "UPDATE_LEAD_STATUSES_LIST";
export const SET_DEPOSIT_HISTORY = "SET_DEPOSIT_HISTORY";

// analytic
export const SET_ANALYTIC = "SET_ANALYTIC";
export const SET_SUB_ANALYTIC = "SET_SUB_ANALYTIC";
export const UPDATE_SUB_ANALYTIC = "UPDATE_SUB_ANALYTIC";
export const SET_CHART_FILTERS = "SET_CHART_FILTERS";
export const SET_EMPTY_SUB_ANALYTIC = "SET_EMPTY_SUB_ANALYTIC";
export const SET_ANALYTIC_FILTERS = "SET_ANALYTIC_FILTERS";
export const FILTERED_CHART_LIST = "FILTERED_CHART_LIST";
export const SHARE_ORDERS_BY_AGE = "SHARE_ORDERS_BY_AGE";
export const DATA_TABLE_BY_PERIOD = "DATA_TABLE_BY_PERIOD";
export const SHARE_ORDERS_BY_GENDER = "SHARE_ORDERS_BY_GENDER";
export const REDEMPTION_SHARE_BY_AGE = "REDEMPTION_SHARE_BY_AGE";
export const REDEMPTION_SHARE_BY_GENDER = "REDEMPTION_SHARE_BY_GENDER";
export const SET_ANALYTIC_GROUP_FILTERS = "SET_ANALYTIC_GROUP_FILTERS";
export const SET_ANALYTIC_GROUP_FIELDS = "SET_ANALYTIC_GROUP_FIELDS";
export const SET_INCIDENCE_DETAILS = "SET_INCIDENCE_DETAILS";

export const UPDATE_ACTIONS_LIST = "UPDATE_ACTIONS_LIST";

// trafficAnalysis
export const SET_TRAFFIC_ANALYSIS = "SET_TRAFFIC_ANALYSIS";
export const UPDATE_TRAFFIC_ANALYSIS = "UPDATE_TRAFFIC_ANALYSIS";

//landingsHistory
export const SET_LANDINGS_HISTORY = "SET_LANDINGS_HISTORY";

//Domonetization
export const SET_DOMONETIZATION = "SET_DOMONETIZATION";

// analytic getters
export const FILTERED_ACTIONS_LIST = "FILTERED_ACTIONS_LIST";
export const TARGETED_ACTIONS_LIST = "TARGETED_ACTIONS_LIST";
export const FILTERED_TARGETED_LEADS = "FILTERED_TARGETED_LEADS";
export const FILTERED_TARGETED_APPROVES = "FILTERED_TARGETED_APPROVES";
export const FILTERED_TARGETED_SPAMS = "FILTERED_TARGETED_SPAMS";
export const FILTERED_TARGETED_REDEMPTION = "FILTERED_TARGETED_REDEMPTION";

// profile
export const SET_STATISTIC = "SET_STATISTIC";
export const UPDATE_STATISTIC = "UPDATE_STATISTIC";
export const UPDATE_STATISTIC_SORTING = "UPDATE_STATISTIC_SORTING";
export const SET_PERSONAL_MANAGER = "SET_PERSONAL_MANAGER";
export const SET_PERSONAL_AGENT = "SET_PERSONAL_AGENT";
export const SET_API_TOKENS = "SET_API_TOKENS";
export const SET_REQUISITES = "SET_REQUISITES";
export const SET_PAYMENT_SYSTEMS = "SET_PAYMENT_SYSTEMS";
export const SET_TRAFFIC_TYPES = "SET_TRAFFIC_TYPES";

export const SET_OFFER_CATEGORIES = "SET_OFFER_CATEGORIES";
export const SET_UTM_TAGS = "SET_UTM_TAGS";
export const SET_TIMEZONES = "SET_TIMEZONES";
export const SET_REGIONS = "SET_REGIONS";

// finance
export const SET_ADVERTISERS_DUTY = "SET_ADVERTISERS_DUTY";
export const SET_USER_BALANCE = "SET_USER_BALANCE";
export const SET_FINANCES = "SET_FINANCES";
export const SET_STATUS = "SET_STATUS";

// referral
export const SET_REFERRAL_BONUS = "SET_REFERRAL_BONUS";
export const UPDATE_REFERRAL_BONUS_SORTINGS = "UPDATE_REFERRAL_BONUS_SORTINGS";
export const UPDATE_REFERRALS_SORTINGS = "UPDATE_REFERRALS_SORTINGS";

// costs
export const SET_COSTS = "SET_COSTS";
export const UPDATE_COSTS_FILTERS = "UPDATE_COSTS_FILTERS";
export const UPDATE_COSTS_SORTINGS = "UPDATE_COSTS_SORTINGS";

// refill
export const SET_INCOMES = "SET_INCOMES";

// logRequest
export const UPDATE_ALLOWED_OFFER_PARAMETERS = "UPDATE_ALLOWED_OFFER_PARAMETERS";

// correction
export const SET_CORRECTIONS = "SET_CORRECTIONS";
export const UPDATE_CORRECTIONS_FILTERS = "UPDATE_CORRECTIONS_FILTERS";
export const UPDATE_CORRECTIONS_PAGINATION = "UPDATE_CORRECTIONS_PAGINATION";

// offers
export const SET_OFFERS = "SET_OFFERS";
export const SET_TOP_OFFERS = "SET_TOP_OFFERS";

// Complaints
export const SET_COMPLAINTS = "SET_COMPLAINTS";

// CapacityAndBumps
export const SET_ADVERTISERS_CAPACITY = "SET_ADVERTISERS_CAPACITY";
export const SET_CAPACITY_HISTORY = "SET_CAPACITY_HISTORY";
export const SET_ANALYSE_CAPACITY = "SET_ANALYSE_CAPACITY";
export const SET_CAPACITY_REQUEST = "SET_CAPACITY_REQUEST";
export const SET_BOOKING_REQUEST = "SET_BOOKING_REQUEST";
export const SET_GUARANTEE_REQUEST = "SET_GUARANTEE_REQUEST";
export const SET_GUARANTEE_STATISTIC = "SET_GUARANTEE_STATISTIC";
export const SET_SUBSECTION_CAPACITY = "SET_SUBSECTION_CAPACITY";
export const SET_SUBSECTION_REQUEST = "SET_SUBSECTION_REQUEST";
export const SET_PAYOUT = "SET_PAYOUT";
export const SET_INDIVIDUAL_RATE = "SET_INDIVIDUAL_RATE";
export const SET_FILLED_CAPACITY = "SET_FILLED_CAPACITY";
export const SET_DISTRIBUTED_CAPACITY = "SET_DISTRIBUTED_CAPACITY";
export const SUBSECTION_CAPACITY_DATA = "SUBSECTION_CAPACITY_DATA";
export const SUBSECTION_CAPACITY_PAGINATION = "SUBSECTION_CAPACITY_PAGINATION";
export const SUBSECTION_CAPACITY_SORT = "SUBSECTION_CAPACITY_SORT";
export const SUBSECTION_CAPACITY_ORDER = "SUBSECTION_CAPACITY_ORDER";
export const SET_PAYOUT_REQUEST = "SET_PAYOUT_REQUEST";
export const SET_CAPACITY_STATISTIC = "SET_CAPACITY_STATISTIC";
export const SET_MINIMAL_PAYOUT = "SET_MINIMAL_PAYOUT";
export const SET_CAPACITY_REQUEST_EFFICIENCY = "SET_CAPACITY_REQUEST_EFFICIENCY";
export const CREATE_INITIAL_STRUCTURE = "CREATE_INITIAL_STRUCTURE";

// LogRequest
export const SET_OFFER_REQUEST = "SET_OFFER_REQUEST";
export const SET_UNPROCESSED_REQUESTS= "SET_UNPROCESSED_REQUESTS";

// offer
export const SET_OFFER = "SET_OFFER";
export const SET_OFFER_WEBMASTERS = "SET_OFFER_WEBMASTERS";
export const UPDATE_OFFER_WEBMASTERS_PAGINATION = "UPDATE_OFFER_WEBMASTERS_PAGINATION";
export const SET_OFFER_WEBMASTERS_AVAILABLE = "SET_OFFER_WEBMASTERS_AVAILABLE";
export const UPDATE_OFFER_AVAILABLE_FILTERS = "UPDATE_OFFER_AVAILABLE_FILTERS";
export const UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION = "UPDATE_OFFER_WEBMASTERS_AVAILABLE_PAGINATION";
export const SET_OFFER_ADVERTISERS_AVAILABLE = "SET_OFFER_ADVERTISERS_AVAILABLE";
export const UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION = "UPDATE_OFFER_ADVERTISERS_AVAILABLE_PAGINATION";
export const SET_OFFER_ADVERTISERZ = "SET_OFFER_ADVERTISERZ";
export const UPDATE_OFFER_FILTERS = "UPDATE_OFFER_FILTERS";
export const SET_OFFER_ADVERTISER_WEBMASTERS = "SET_OFFER_ADVERTISER_WEBMASTERS";
export const SET_OFFER_TRAFFIC_TYPES = "SET_OFFER_TRAFFIC_TYPES";
export const UPDATE_INTEGRATIONS_PAGINATION = "UPDATE_INTEGRATIONS_PAGINATION";
export const UPDATE_OFFER_WEBMASTERS = "UPDATE_OFFER_WEBMASTERS";
export const UPDATE_OFFER_WEBMASTERS_FILTERS = "UPDATE_OFFER_WEBMASTERS_FILTERS";
export const UPDATE_OFFER_ADVERTISERZ = "UPDATE_OFFER_ADVERTISERZ";
export const SET_OFFER_RATES_REQUIREMENTS = "SET_OFFER_RATES_REQUIREMENTS";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_PAGINATION";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES_FILTERS";
export const SET_LANDINGS = "SET_LANDINGS";
export const SET_TRANSITS = "SET_TRANSITS";
export const UPDATE_SELECTED_ADVERTISERS_IDS = "UPDATE_SELECTED_ADVERTISERS_IDS";
export const UPDATE_MODAL_ACTIVE = "UPDATE_MODAL_ACTIVE";
export const UPDATE_MODAL_PARAMS = "UPDATE_MODAL_PARAMS";
export const UPDATE_CALLCENTERS_MODAL_ACTIVE = "UPDATE_CALLCENTERS_MODAL_ACTIVE";
export const UPDATE_MODAL_OPTIONS = "UPDATE_MODAL_OPTIONS";
export const UPDATE_SELECTED_WEBMASTERS_BALANCE = "UPDATE_SELECTED_WEBMASTERS_BALANCE";
export const UPDATE_OFFER_WEBMASTERS_REWARD = "UPDATE_OFFER_WEBMASTERS_REWARD";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_OFFER_ADVERTISER_RATE = "UPDATE_OFFER_ADVERTISER_RATE";
export const SET_OFFER_ADVERTISER_INDIVIDUAL_RATES = "SET_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const UPDATE_OFFER_OPTIONS = "UPDATE_OFFER_OPTIONS";
export const UPDATE_CURRENT_WEBMASTER = "UPDATE_CURRENT_WEBMASTER";
export const UPDATE_LANDING_PARAMETERS = "UPDATE_LANDING_PARAMETERS";
export const UPDATE_ANALYSE_DATA = "UPDATE_ANALYSE_DATA";

//Detail
export const SET_WEBMASTER_ID = "SET_WEBMASTER_ID";

// Rates
export const SET_WEBMASTER_RATES = "SET_WEBMASTER_RATES";
export const SET_WEBMASTER_RATES_FILTERS = "SET_WEBMASTER_RATES_FILTERS";
export const SET_WEBMASTER_RATES_PAGINATION = "SET_WEBMASTER_RATES_PAGINATION";

// Offer webmasters
export const UPDATE_SELECTED_WEBMASTERS_IDS = "UPDATE_SELECTED_WEBMASTERS_IDS";
export const EMPTY_WEBMASTERS_LIST = "EMPTY_WEBMASTERS_LIST";
export const UPDATE_WEBMASTERS_FILTERS = "UPDATE_WEBMASTERS_FILTERS";
export const UPDATE_WEBMASTERS_LIST_PAGINATION = "UPDATE_WEBMASTERS_LIST_PAGINATION";
export const UPDATE_OFFER_WEBMASTER_RATE = "UPDATE_OFFER_WEBMASTER_RATE";
export const INCLUDE_WEBMASTERS_OFFERS = "INCLUDE_WEBMASTERS_OFFERS";
export const EXCLUDE_WEBMASTERS_FORM_OFFERS = "EXCLUDE_WEBMASTERS_FORM_OFFERS";
export const EXCLUDE_MULTIPLE_WEBMASTERS_FROM_MULTIPLE_OFFERS = "EXCLUDE_MULTIPLE_WEBMASTERS_FROM_MULTIPLE_OFFERS";

// Admin. Offer. Landings
export const SET_OFFER_LANDINGS_ID = "SET_OFFER_LANDINGS_ID";
export const SET_OFFER_LANDINGS = "SET_OFFER_LANDINGS";
export const SET_OFFER_CONVERSION = "SET_OFFER_CONVERSION";
export const UPDATE_OFFER_LANDINGS = "UPDATE_OFFER_LANDINGS";
export const REMOVE_PAGE = "REMOVE_PAGE";
export const UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT = "UPDATE_OFFER_LANDINGS_ADD_MODAL_INPUT";
export const UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN = "UPDATE_OFFER_LANDINGS_ADD_MODAL_EXTERNAL_DOMAIN";
export const UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD = "UPDATE_OFFER_LANDINGS_ADD_MODAL_UPLOAD";
export const SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID = "SET_OFFER_LANDINGS_ADD_MODAL_PAGE_ID";
export const SET_OFFER_LANDINGS_READY = "SET_OFFER_LANDINGS_READY";

// flow
export const SET_NAME = "SET_NAME";
export const SET_OPTION_DOMAIN = "SET_OPTION_DOMAIN";
export const SET_IS_LAUNCHED = "SET_IS_LAUNCHED";
export const SET_OFFER_ID = "SET_OFFER_ID";
export const SET_CHECKED_LANDINGS = "SET_CHECKED_LANDINGS";
export const SET_CHECKED_PRELANDINGS = "SET_CHECKED_PRELANDINGS";
export const SET_CHECKED_BUNCHES = "SET_CHECKED_BUNCHES";
export const UPDATE_ANALYTICS = "UPDATE_ANALYTICS";

//audit
export const SET_AUDIT = "SET_AUDIT";

//Competition
export const SET_COMPETITION = "SET_COMPETITION";
export const SET_COMPETITION_RATING = "SET_COMPETITION_RATING";
export const EDIT_COMPETITION = "EDIT_COMPETITION";
export const SET_COMPETITION_CURRENT = "SET_COMPETITION_CURRENT";
export const COMPETITION_FORMATTED_DATE = "COMPETITION_FORMATTED_DATE";
export const CREATE_COMPETITION = "CREATE_COMPETITION";
export const SET_COMPETITION_TOP = "SET_COMPETITION_TOP";
export const SET_CURRENT_COMPETITION = "SET_CURRENT_COMPETITION";
export const UPDATE_COMPETITION_FORM = "UPDATE_COMPETITION_FORM";
export const UPDATE_COMPETITION_CONDITION = "UPDATE_COMPETITION_CONDITION";
export const UPDATE_COMPETITION_DETAIL_DATA = "UPDATE_COMPETITION_DETAIL_DATA";
export const UPDATE_COMPETITION_CONDITION_GOAL = "UPDATE_COMPETITION_CONDITION_GOAL";

//subAccounts
export const SET_SUBACCOUNTS = "SET_SUBACCOUNTS";

// postback detail
export const SET_STATISTIC_POSTBACKS = "SET_STATISTIC_POSTBACKS";
export const SET_GLOBAL_POSTBACKS = "SET_GLOBAL_POSTBACKS";
export const ADD_GLOBAL_POSTBACK = "ADD_GLOBAL_POSTBACK";
export const UPDATE_GLOBAL_POSTBACK_URL = "UPDATE_GLOBAL_POSTBACK_URL";
export const UPDATE_GLOBAL_POSTBACK_STATUSES = "UPDATE_GLOBAL_POSTBACK_STATUSES";
export const UPDATE_GLOBAL_POSTBACK_DUPLICATES = "UPDATE_GLOBAL_POSTBACK_DUPLICATES";

// admin
export const SET_UNINTEGRATION_OFFERS = "SET_UNINTEGRATION_OFFERS";
export const SET_CRM_ALIAS = "SET_CRM_ALIAS";
export const SET_ADVERTISER_ID = "SET_ADVERTISER_ID";
export const SET_ADVERTISER_ID_CALLCENTERS = "SET_ADVERTISER_ID_CALLCENTERS";
export const UPDATE_INTEGRATION_FILTERS = "UPDATE_INTEGRATION_FILTERS";
export const UPDATE_INTEGRATION_OPTIONS = "UPDATE_INTEGRATION_OPTIONS";
export const SET_INTEGRATION_OPTIONS = "SET_INTEGRATION_OPTIONS";
export const SET_INTEGRATIONS = "SET_INTEGRATIONS";
export const UPDATE_INTEGRATIONS_FILTERS = "UPDATE_INTEGRATIONS_FILTERS";
export const SET_CRM = "SET_CRM";
export const UPDATE_CRM = "UPDATE_CRM";
export const UPDATE_INTEGRATION_CRM_LIST = "UPDATE_INTEGRATION_CRM_LIST";
export const SET_INTEGRATION_CRM_LIST = "SET_INTEGRATION_CRM_LIST";
export const UPDATE_TOP_CARD_FILTERS = "UPDATE_TOP_CARD_FILTERS";
export const UPDATE_TOP_CARD_AMOUNT_TYPE = "UPDATE_TOP_CARD_AMOUNT_TYPE";
export const SET_ACTIVE_COUNTS = "SET_ACTIVE_COUNTS";

// users
export const SET_WEBMASTERS = "SET_WEBMASTERS";
export const SET_ADVERTISERS = "SET_ADVERTISERS";
export const SET_ADMINISTRATORS = "SET_ADMINISTRATORS";
export const UPDATE_ADMINISTRATORS_FILTERS = "UPDATE_ADMINISTRATORS_FILTERS";
export const UPDATE_ADMINISTRATORS_PAGINATION = "UPDATE_ADMINISTRATORS_PAGINATION";
export const UPDATE_ADMINISTRATORS_SORTS = "UPDATE_ADMINISTRATORS_SORTS";
export const UPDATE_WEBMASTERS_SORTINGS = "UPDATE_WEBMASTERS_SORTINGS";
export const SET_WEBMASTER_BALANCE = "SET_WEBMASTER_BALANCE";
export const SET_ADVERTISER_BALANCE = "SET_ADVERTISER_BALANCE";
export const SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE = "SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE";
export const UPDATE_ADVERTISERS_EDIT_FORM = "UPDATE_ADVERTISERS_EDIT_FORM";
export const UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS = "UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS";
export const SET_ADVERTISERS_EDIT_FORM = "SET_ADVERTISERS_EDIT_FORM";
export const SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE = "SET_IS_ADMINISTRATORS_EDIT_MODAL_ACTIVE";
export const SET_ADMINISTRATORS_EDIT_ID = "SET_ADMINISTRATORS_EDIT_ID";
export const SET_ADMINISTRATORS_EDIT_FORM = "SET_ADMINISTRATORS_EDIT_FORM";
export const UPDATE_ADMINISTRATORS_EDIT_FORM = "UPDATE_ADMINISTRATORS_EDIT_FORM";
export const UPDATE_WEBMASTER_EDITABLE_COMMENT = "UPDATE_WEBMASTER_EDITABLE_COMMENT";
export const EMPTY_WEBMASTER_EDITABLE_COMMENT = "EMPTY_WEBMASTER_EDITABLE_COMMENT";
export const UPDATE_ADVERTISERS_SORTS = "UPDATE_ADVERTISERS_SORTS";
export const UPDATE_ADVERTISER_EDITABLE_COMMENT = "UPDATE_ADVERTISER_EDITABLE_COMMENT";
export const EMPTY_ADVERTISER_EDITABLE_COMMENT = "EMPTY_ADVERTISER_EDITABLE_COMMENT";
export const UPDATE_WEBMASTER_SUBTYPE = "UPDATE_WEBMASTER_SUBTYPE";

// Admin. Users. Advertisers
export const UPDATE_ADVERTISER_DETAIL_DATA = "UPDATE_ADVERTISER_DETAIL_DATA";
export const SET_ADVERTISER_RATES = "SET_ADVERTISER_RATES";

//callCenters
export const SET_AVAILABLE_CALLCENTERS_FOR_OFFER = "SET_AVAILABLE_CALLCENTERS_FOR_OFFER";
export const SET_CALLCENTERS = "SET_CALLCENTERS";

// Roles
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_ROLES = "SET_ROLES";

// Domains
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_DOMAINS_BY_NAME = "SET_DOMAINS_BY_NAME";

// Admin. Efficiency
export const SET_EFFICIENCY_CHART_FILTERS = "SET_EFFICIENCY_CHART_FILTERS";
export const SET_EFFICIENCY_GRAPH = "SET_EFFICIENCY_GRAPH";
export const SET_EFFICIENCY_TOTAL = "SET_EFFICIENCY_TOTAL";
export const UPDATE_EFFICIENCY_FILTERS = "UPDATE_EFFICIENCY_FILTERS";
export const UPDATE_EFFICIENCY_SORTINGS = "UPDATE_EFFICIENCY_SORTINGS";

// Admin. IncidenceAnalyse
export const SET_INCIDENCE_ANALYSE = "SET_INCIDENCE_ANALYSE";
export const SET_INCIDENCE = "SET_INCIDENCE";

// Admin. AnalyticTargetAudience
export const SET_ANALYTIC_TARGET_AUDIENCE = "SET_ANALYTIC_TARGET_AUDIENCE";

// Admin. AnalyticReason
export const SET_ANALYTIC_REASON = "SET_ANALYTIC_REASON";

//Lists. Verticals
export const SET_VERTICALS = "SET_VERTICALS";

//Lists. Verticals
export const SET_WEBMASTER_LABELS = "SET_WEBMASTER_LABELS";

// Lists. admins
export const EMPTY_ADMINS_LIST = "EMPTY_ADMINS_LIST";
export const UPDATE_ADMINS_FILTERS = "UPDATE_ADMINS_FILTERS";
export const UPDATE_ADMINS_LIST_PAGINATION = "UPDATE_ADMINS_LIST_PAGINATION";

// Lists. admins
export const EMPTY_REQUISITES_LIST = "EMPTY_REQUISITES_LIST";
export const UPDATE_REQUISITES_FILTERS = "UPDATE_REQUISITES_FILTERS";
export const UPDATE_REQUISITES_LIST_PAGINATION = "UPDATE_REQUISITES_LIST_PAGINATION";

// Lists. statuses
export const UPDATE_STATUSES_LIST = "UPDATE_STATUSES_LIST";

// Lists. Statistic
export const UPDATE_REASONS_LIST = "UPDATE_REASONS_LIST";

// Lists. PaymentSystems
export const EMPTY_PAYMENT_SYSTEMS_LIST = "EMPTY_PAYMENT_SYSTEMS_LIST";
export const UPDATE_PAYMENT_SYSTEMS_FILTERS = "UPDATE_PAYMENT_SYSTEMS_FILTERS";
export const UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION = "UPDATE_PAYMENT_SYSTEMS_LIST_PAGINATION";

// Lists. Advertisers
export const EMPTY_ADVERTISERS_LIST = "EMPTY_ADVERTISERS_LIST";
export const UPDATE_ADVERTISERS_FILTERS = "UPDATE_ADVERTISERS_FILTERS";
export const UPDATE_ADVERTISERS_LIST_PAGINATION = "UPDATE_ADVERTISERS_LIST_PAGINATION";

// Lists. CapacityAndBumps
export const EMPTY_OFFERS_LIST = "EMPTY_OFFERS_LIST";
export const UPDATE_OFFERS_FILTERS = "UPDATE_OFFERS_FILTERS";
export const UPDATE_OFFERS_LIST_PAGINATION = "UPDATE_OFFERS_LIST_PAGINATION";

// Lists. Offer Advertisers
export const UPDATE_OFFER_ADVERTISERS = "UPDATE_OFFER_ADVERTISERS";
export const UPDATE_OFFER_ADVERTISERS_PAGINATION = "UPDATE_OFFER_ADVERTISERS_PAGINATION";
export const UPDATE_OFFER_ADVERTISERS_FILTERS = "UPDATE_OFFER_ADVERTISERS_FILTERS";

//Lists. PaymentIntegrations
export const EMPTY_PAYMENT_INTEGRATIONS_LIST = "EMPTY_PAYMENT_INTEGRATIONS_LIST";
export const UPDATE_PAYMENT_INTEGRATIONS_FILTERS = "UPDATE_PAYMENT_INTEGRATIONS_FILTERS";
export const UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION = "UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION";

//Lists. Approved
export const UPDATE_APPROVED_LIST = "UPDATE_APPROVED_LIST";

//Lists. EmailConfirmed
export const UPDATE_EMAIL_CONFIRMED_LIST = "UPDATE_EMAIL_CONFIRMED_LIST";

//Lists. Blocked
export const UPDATE_BLOCKED_LIST = "UPDATE_BLOCKED_LIST";

//Lists. PartnerNetwork
export const UPDATE_PARTNER_NETWORK_LIST = "UPDATE_PARTNER_NETWORK_LIST";

//Lists. Drawdown
export const UPDATE_DRAWDOWN = "UPDATE_DRAWDOWN";

//Lists. Currency
export const UPDATE_CURRENCY_LIST = "UPDATE_CURRENCY_LIST";
export const SET_CURRENCY = "SET_CURRENCY";

//Lists. Roles
export const UPDATE_ROLES_LIST = "UPDATE_ROLES_LIST";

//Lists. CRM
export const EMPTY_CRM_LIST = "EMPTY_CRM_LIST";
export const UPDATE_CRM_FILTERS = "UPDATE_CRM_FILTERS";
export const UPDATE_CRM_LIST_PAGINATION = "UPDATE_CRM_LIST_PAGINATION";

//Lists. WithdrawTypes
export const UPDATE_WITHDRAW_TYPES_LIST = "UPDATE_WITHDRAW_TYPES_LIST";

//Lists. WithdrawTypes
export const UPDATE_USER_TYPE_LIST = "UPDATE_USER_TYPE_LIST";

//Lists. Domestic Webmasters
export const UPDATE_DOMESTIC_WEBMASTERS_LIST = "UPDATE_DOMESTIC_WEBMASTERS_LIST";

//Lists. OfferTypes
export const UPDATE_OFFER_TYPES_LIST = "UPDATE_OFFER_TYPES_LIST";

// Lists. Languages
export const EMPTY_LANGUAGES_LIST = "EMPTY_LANGUAGES_LIST";
export const SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST";

// Lists. Countries
export const EMPTY_COUNTRIES_LIST = "EMPTY_COUNTRIES_LIST";
export const SET_COUNTRIES_LIST = "SET_COUNTRIES_LIST";

// Lists. Designers
export const SET_DESIGNERS = "SET_DESIGNERS";

// Payments
export const UPDATE_PAYMENT_SYSTEMS = "UPDATE_PAYMENT_SYSTEMS";
export const UPDATE_PAYMENTS_PAGINATION = "UPDATE_PAYMENTS_PAGINATION";

// Webmaster. Profile
export const UPDATE_WEBMASTER_PROFILE_FORM = "UPDATE_WEBMASTER_PROFILE_FORM";

// Webmaster. Profile. Requisites
export const UPDATE_WEBMASTER_REQUISITES_ADD_FORM = "UPDATE_WEBMASTER_REQUISITES_ADD_FORM";
export const UPDATE_REQUISITES_DELETE = "UPDATE_REQUISITES_DELETE";

// Advertiser. Profile
export const UPDATE_ADVERTISER_FORM = "UPDATE_ADVERTISER_FORM";

// Admin. Profile
export const UPDATE_ADMIN_PROFILE_FORM = "UPDATE_ADMIN_PROFILE_FORM";

// Password
export const UPDATE_PASSWORD_FORM = "UPDATE_PASSWORD_FORM";

// Webmaster. Flows
export const SET_FLOWS = "SET_FLOWS";
export const SET_FIELDS = "SET_FIELDS";
export const SET_FLOW_ID = "SET_FLOW_ID";
export const SET_OPTIONS = "SET_OPTIONS";
export const UPDATE_URL = "UPDATE_URL";
export const UPDATE_FLOWS_FILTERS = "UPDATE_FLOWS_FILTERS";
export const SET_AVAILABLE_DOMONETIZATIONS = "SET_AVAILABLE_DOMONETIZATIONS";

// CRM
export const UPDATE_METHODS_LIST = "UPDATE_METHODS_LIST";
export const UPDATE_REQUEST_TYPES_LIST = "UPDATE_REQUEST_TYPES_LIST";
export const UPDATE_RESPONSE_TYPES_LIST = "UPDATE_RESPONSE_TYPES_LIST";
export const UPDATE_STRATEGY_LIST = "UPDATE_STRATEGY_LIST";
export const UPDATE_MACROS_LIST = "UPDATE_MACROS_LIST";
export const UPDATE_INTEGRATION_DETAILS = "UPDATE_INTEGRATION_DETAILS";
export const UPDATE_RECOVERY_DETAILS = "UPDATE_RECOVERY_DETAILS";
export const UPDATE_CUSTOM_MACROS = "UPDATE_CUSTOM_MACROS";
export const UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL = "UPDATE_CUSTOM_MACROS_RECOVERY_DETAIL";
export const UPDATE_FAIL_RULE_OPTIONS = "UPDATE_FAIL_RULE_OPTIONS";
export const UPDATE_RECOVERY_STRATEGY_LIST = "UPDATE_RECOVERY_STRATEGY_LIST";
export const UPDATE_TRANSFER_RULES_URL_LIST = "UPDATE_TRANSFER_RULES_URL_LIST";
export const UPDATE_POSTBACK_STATUS_MAP = "UPDATE_POSTBACK_STATUS_MAP";
export const UPDATE_MODAL_IS_EDIT = "UPDATE_MODAL_IS_EDIT";
export const UPDATE_IS_VALID = "UPDATE_IS_VALID";
export const CREATE_RULES = "CREATE_RULES";
export const UPDATE_FLOW_REDIRECT_MACROS_LIST = "UPDATE_FLOW_REDIRECT_MACROS_LIST";

// Admin. Finances. WebAccrual
export const SET_WEB_ACCRUAL = "SET_WEB_ACCRUAL";

// Correction
export const UPDATE_CORRECTION_TYPES_LIST = "UPDATE_CORRECTION_TYPES_LIST";
export const UPDATE_CORRECTION_TARGET_LIST = "UPDATE_CORRECTION_TARGET_LIST";

// Uploads
export const SET_UPLOADS = "SET_UPLOADS";
export const UPDATE_UPLOADS_FILTERS = "UPDATE_UPLOADS_FILTERS";
export const UPDATE_UPLOADS_PAGINATION = "UPDATE_UPLOADS_PAGINATION";
export const UPDATE_FORMAT_FILE_LIST = "UPDATE_FORMAT_FILE_LIST";
export const UPDATE_UPLOADS_FIELDS_LIST = "UPDATE_UPLOADS_FIELDS_LIST";
export const UPDATE_FILES_UPLOADED = "UPDATE_FILES_UPLOADED";
export const UPDATE_FILE = "UPDATE_FILE";

// Webmaster. Offer
export const SET_OFFER_FIELDS = "SET_OFFER_FIELDS";
export const UPDATE_OFFER_URL = "UPDATE_OFFER_URL";

// Webmaster. Offer. Postbacks
export const SET_OFFER_POSTBACKS = "SET_OFFER_POSTBACKS";
export const ADD_OFFER_POSTBACK = "ADD_OFFER_POSTBACK";
export const UPDATE_OFFER_POSTBACK = "UPDATE_OFFER_POSTBACK";
export const UPDATE_OFFER_POSTBACK_URL = "UPDATE_OFFER_POSTBACK_URL";
export const UPDATE_OFFER_POSTBACK_VERTICAL = "UPDATE_OFFER_POSTBACK_VERTICAL";
export const UPDATE_POSTBACK_OPTIONS = "UPDATE_POSTBACK_OPTIONS";
export const UPDATE_OFFER_POSTBACK_STATUSES = "UPDATE_OFFER_POSTBACK_STATUSES";
export const UPDATE_OFFER_POSTBACK_DUPLICATES = "UPDATE_OFFER_POSTBACK_DUPLICATES";

// Advertiser. Finances
export const UPDATE_INCOMES_FILTERS = "UPDATE_INCOMES_FILTERS";
export const UPDATE_INCOMES_PAGINATION = "UPDATE_INCOMES_PAGINATION";

// Badges
export const SET_BADGES = "SET_BADGES";
export const SET_OPERATION_BADGES = "SET_OPERATION_BADGES";

// Discounts
export const SET_DISCOUNTS = "SET_DISCOUNTS";

// Webmasters ID History Modal
export const UPDATE_HISTORY = "UPDATE_HISTORY";

// Registrations
export const SET_REGISTRATIONS = "SET_REGISTRATIONS";