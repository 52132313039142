import { ADD_DOMAIN, UPDATE_OPTIONS } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
import { NullableDomainsModalState, Options } from "@core/store/types/common/domains/domains";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { DomainsService } from "@core/store/logic/admin/domains/domainsService";
import { WebmastersFilters } from "@core/store/modules/common/lists/webmastersFilters";

const initialState = (): NullableDomainsModalState => {
  return {
    options: {
      name: null,
      sslAuthMethod: null,
      webmasterId: null
    },
    isActiveModal: false
  };
};

const state: () => NullableDomainsModalState = initialState;

const mutations: MutationTree<NullableDomainsModalState> = {
  [SET_EMPTY] (state: NullableDomainsModalState): void {
    Object.assign(state, initialState());
  },
  
  [UPDATE_OPTIONS] (state: NullableDomainsModalState, option: Options): void {
    state.options = { ...state.options, ...option };
  },

  [UPDATE_MODAL_ACTIVE] (state: NullableDomainsModalState, payload: boolean): void {
    state.isActiveModal = payload;
  }
};

const actions: ActionTree<NullableDomainsModalState, RootState> = {
  async [ADD_DOMAIN] ({ state: { options: input }, commit }): Promise<void> {
    const domains = await DomainsService.addDomain(input);
    commit("admin/domains/LOCAL_ADD", { items: domains, target: "domains" }, { root: true });
  },
  
  [UPDATE_OPTIONS] ({ commit }, options: Options): void {
    commit(UPDATE_OPTIONS, options);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
    commit("webmastersFilters/SET_EMPTY");
  }
};

export const domainModal: Module<NullableDomainsModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    webmastersFilters: new WebmastersFilters()
  }
};
