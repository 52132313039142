import { RootState } from "@core/store/root-state";
import { offerEditExternalWebmastersModal } from "./offerEditExternalWebmastersModal";
import {
  OfferEditWebmasters
} from "@core/store/modules/admin/offer/modules/edit/modules/offerEditWebmasters";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import {
  GET_OFFER_ID,
  GET_OFFER_EXTERNAL_WEBMASTERS,
  BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  SET_WEBMASTER_REWARD,
  WEBMASTER_REWARD,
  DELETE_OFFER_WEBMASTERS,
  UPDATE_MINIMAL_ALLOWED_REWARD
} from "@core/store/action-constants";
import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import {
  SET_OFFER_WEBMASTERS,
  UPDATE_OFFER_WEBMASTERS,
  UPDATE_OFFER_WEBMASTERS_PAGINATION
} from "@core/store/mutation-constants";
import Vue from "vue";
import { PartialOfferExternalWebmastersState, OfferWebmaster } from "@core/store/types/admin/offer/OfferExternalWebmastersState";
import { capitalizeFirstLetter } from "@core/filters";

// @ts-ignore
export class offerEditExternalWebmasters extends OfferEditWebmasters<PartialOfferExternalWebmastersState, RootState> {
  private readonly initState: () => PartialOfferExternalWebmastersState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): PartialOfferExternalWebmastersState => {
      return {
        webmasters: {},
        filters: {
          webmasterId: null,
          hasExternalWebmaster: true
        },
        pagination: {
          page: 1,
          perPage: 25
        }
      };
    };

    this.state(this.initState());

    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      },

      [SET_WEBMASTER_REWARD]: (state, { maximalAllowedWebmasterReward, externalWebmasterId }): void => {

        if (state.webmasters?.items) {
          const webmasterIndex = state.webmasters.items?.findIndex((webmaster: OfferWebmaster) => webmaster.externalWebmasterId === externalWebmasterId);

          Vue.set(state.webmasters.items, webmasterIndex, {
            ...state.webmasters?.items[webmasterIndex],
            maximalAllowedWebmasterReward
          });
        }
      }
    });

    this.actions({
      async [GET_OFFER_EXTERNAL_WEBMASTERS] ({
        commit,
        rootGetters,
        state,
        dispatch
      }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          const { pagination, filters } = state;
          if (offerId) {
            const limit = pagination.perPage;
            const offset = (pagination.page - 1) * limit;
            const { data: { webmasterRates: webmasters } } = await OfferEditWebmastersServices.getOfferExternalWebmasters(
              filters,
              offerId,
              limit,
              offset
            );
            webmasters.items = webmasters.items.map((item: OfferWebmaster) => {
              if (item.individualRate) {
                const isLoading: { [key: string]: unknown } = {};
                for (let key in item.individualRate) {
                  if (item.individualRate.hasOwnProperty(key) && key !== "id") {
                    if (key === "moneyReward") {
                      key = "rate";
                    }
                    const prop = capitalizeFirstLetter(key);
                    isLoading[`is${ prop }Loading`] = false;
                  }
                }
                return {
                  ...item,
                  ...isLoading
                };
              } else {
                return item;
              }
            });

            if (offset === 0) {
              commit(SET_OFFER_WEBMASTERS, webmasters);
            } else {
              commit(UPDATE_OFFER_WEBMASTERS, webmasters);
            }

            dispatch(UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD);

            return webmasters;
          }
        } catch (e) {
          throw e;
        }
      },

      [UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD] ({
        state,
        dispatch
      }) {

        state.webmasters?.items?.forEach((item: OfferWebmaster) => {
          dispatch(WEBMASTER_REWARD, {
            webmasterId: item.webmaster.id,
            externalWebmasterId: item.externalWebmasterId
          });
        });
      },

      async [WEBMASTER_REWARD] ({ commit, rootGetters }, { webmasterId, externalWebmasterId }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];
        try {
          const { data: { maximalAllowedWebmasterReward } } = await OfferEditWebmastersServices.getMaximalAllowedWebmastersReward(
            offerId,
            webmasterId,
            externalWebmasterId
          );
          commit(SET_WEBMASTER_REWARD, { maximalAllowedWebmasterReward, externalWebmasterId });
        } catch (error) {
          throw error;
        }
      },

      async [DELETE_OFFER_WEBMASTERS] ({
        rootGetters,
        dispatch
      }, payload) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

        try {
          if (offerId) {
            const { data: { offerExcludeMultipleWebmasters } } =
              await OfferEditWebmastersServices.removeOfferWebmasterParameters(offerId, payload);

            dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
              {}, { root: true });


            return offerExcludeMultipleWebmasters;
          }
        } catch (e) {
          throw e;
        }
      },

      [BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS] ({
        commit,
        dispatch,
        state
      }) {
        const {
          page,
          perPage
        } = state.pagination;

        commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
          page: 1,
          perPage: perPage * page
        });

        dispatch(GET_OFFER_EXTERNAL_WEBMASTERS);

        commit(UPDATE_OFFER_WEBMASTERS_PAGINATION, {
          page,
          perPage
        });
      }
    });

    this.modules({
      offerEditExternalWebmastersModal,
      withdrawTypesList
    });

    return this;
  }
}