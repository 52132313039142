import { User } from "@core/store/types/common/User";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

export class AdminForm {
  public countries: string[];
  public timeZone: string;
  public email: string;
  public telegram: string;
  public name: string;
  public workedYear?: string;
  public description?: string;
  public inHouse: boolean;
  public preferredVertical: VerticalsEnum;
  
  public constructor (payload: User) {
    this.countries = payload.countries.map(item => item.id);
    this.timeZone = payload.timeZone.alias;
    this.email = payload.contact.email;
    this.telegram = payload.contact.telegram;
    this.name = payload.name;
    this.workedYear = payload.workedYear;
    this.description = payload.description;
    this.inHouse = payload.inHouse;
    this.preferredVertical = payload.preferredVertical;
  }
}
