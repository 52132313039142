import store from "@/store";
import { currencyAvailableToSort } from "@core/flowMethods";
import { hasPermissions } from "@core/mixins/permissions";
import {
  CostsListItem,
  CostsListState
} from "@core/store/types/admin/finances/financesCosts/financesCosts";

export function outTransactionsMapper (outTransactions: CostsListState["costs"]): CostsListState["costs"] {
  outTransactions.items = outTransactions.items?.map((item: CostsListItem) => processData(item));
  return outTransactions;
}

export function CreateOutTransactionMapper (outTransaction: CostsListItem): CostsListItem {
  return processData(outTransaction);
}

export function outTransactionMapper (outTransaction: CostsListItem[]): CostsListItem[] {
  return outTransaction.map((item) => processData(item));
}

function canBeAutoPay (item: CostsListItem): boolean {
  const { paymentSystem, status, requisite } = item;
  const isAutoPayable = paymentSystem.isAutoPayable;

  return status === "created" && !!requisite && isAutoPayable;
}

function processData (item: CostsListItem): CostsListItem {
  return {
    ...item,
    user: {
      ...item.user,
      totalPayments: currencyAvailableToSort(item.user.totalPayments)
    },
    isCheckable: isCheckable(item),
    canBeAutoPay: canBeAutoPay(item)
  };
}

function isCheckable (item: CostsListItem): boolean {
  const rootPermissions = store?.getters?.permissions;
  if (item.status === "created"){
    if (hasPermissions(["OUT_TRANSACTIONS.MANAGE"],rootPermissions)) {
      return true;
    }
    else if (hasPermissions(["OUT_TRANSACTIONS.AUTO_PAY.OWN"], rootPermissions) && item.canBeAutoPay ||
      hasPermissions(["OUT_TRANSACTIONS.PROCESSING.OWN", "OUT_TRANSACTIONS.REJECT.OWN"],rootPermissions)){
      return item.user.isBindedToCurrentAdmin;
    }
    return false;
  }
  else {
    if (item.status === "hand_processing") {
      if (hasPermissions(["OUT_TRANSACTIONS.MANAGE"], rootPermissions)) {
        return true;
      } else if (hasPermissions(["OUT_TRANSACTIONS.PROCESSING.OWN",
        "OUT_TRANSACTIONS.PROCESSED.OWN", "OUT_TRANSACTIONS.REJECT.OWN"], rootPermissions)) {
        return item.user.isBindedToCurrentAdmin;
      }
    }
    return false;
  }
}
