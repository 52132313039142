import addSubWebmasterParameters from "./graphql/addSubWebmasterParameters.graphql";
import editSubWebmasterParameter from "./graphql/editSubWebmasterParameter.graphql";
import excludeSubWebmastersToOffer from "./graphql/excludeSubWebmastersToOffer.graphql";
import includeSubWebmastersToOffer from "./graphql/includeSubWebmastersToOffer.graphql";
import webmasterRates from "./graphql/webmasterRates.graphql";
import Service from "@core/services/common/Service";
import {
  IndividualRate, RatesWebmastersFilters,
  ResponseOfferWebmaster,
  Webmaster
} from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";

import { AxiosPromise, AxiosResponse } from "axios";
import WebmasterEditDataMapper from "@core/store/logic/webmaster/offer/WebmasterEditDataMapper";
import { OfferWebmaster } from "@core/store/types/admin/offer/webmasters";

export class OfferEditWebmastersServices extends Service {
  public static async getOfferWebmasters (
    filters: RatesWebmastersFilters,
    offerId: string,
    limit: number,
    offset = 0
  ): Promise<AxiosResponse<any>> {
    try {
      const response = await this.api.get("", {
        params: {
          query: webmasterRates,
          variables: {
            limit,
            offset,
            filters,
            offerId
          }
        }
      });
      const webmasters = response.data.offer.webmasters;
      
      webmasters.items = webmasters.items.map((item: ResponseOfferWebmaster) => {
        if (item.individualRate) {
          return {
            ...item,
            individualRate: {
              id: item.individualRate?.id,
              moneyReward: item.individualRate?.moneyReward
            }
          };
        }
        return item;
      });
      
      return response;
    } catch (e) {
      throw e;
    }
  }
  
  public static async excludeSubWebmastersToOffer (offerId: string, webmasters: string[]): Promise<AxiosPromise> {
    try {
      const { data: { excludeSubWebmastersToOffer: data } } = await this.api.post("", {
        query: excludeSubWebmastersToOffer,
        variables: {
          offerId,
          webmasters
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async includeSubWebmastersToOffer (
    offerId: string,
    webmasters: IndividualRate[],
    limit: number,
    filters: { id: string[] },
    offset= 0
  ): Promise<OfferWebmaster[]> {
    try {
      const { data: { includeSubWebmastersToOffer: data } } = await this.api.post("", {
        query: includeSubWebmastersToOffer,
        variables: {
          offerId,
          input: { webmasters },
          limit,
          offset,
          filters
        }
      });
      
      return WebmasterEditDataMapper(data.webmasters.items);
    } catch (e) {
      throw e;
    }
  }
  
  public static async addSubWebmasterParameters (
    offerId: string,
    webmasters: IndividualRate[]
  ): Promise<AxiosPromise> {
    
    try {
      return await this.api.post("", {
        query: addSubWebmasterParameters,
        variables: {
          offerId,
          input: { webmasters }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async editSubWebmasterParameter (
    offerId: string,
    params: IndividualRate,
    webmaster: Webmaster
  ): Promise<AxiosPromise> {
    const webmasterId = webmaster?.id;
    
    try {
      return await this.api.post("", {
        query: editSubWebmasterParameter,
        variables: {
          offerId,
          webmasterId,
          input: { ...params }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
