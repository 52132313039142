import { AdminStatisticByGroups } from "@core/logic/admin/statistic/AdminStatisticByGroups";
import { StatisticsService } from "@core/logic/admin/statistic/statistic";
import { RootState } from "@core/store/root-state";
import moment from "moment";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { sortFieldGroupsStatistic } from "@core/helpers/sortFieldGroupsStatistic";
import { StatisticResponse, StatisticState } from "@core/store/types/common/statistic/common/statistic";
import {
  UPDATE_STATISTIC_EXCLUDED_FILTERS,
  UPDATE_STATISTIC_FILTERS
} from "@core/store/mutation-constants";
import { SET_EMPTY, UPDATE_GROUP } from "@core/store/action-constants";
import Vue from "vue";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { hasPermissions } from "@core/mixins/permissions";
import { StatisticGroupsEnum, StatisticOtherGroupsEnum } from "@core/store/types/admin/statistic/enums/StatisticEnums";
import { datepicker } from "@core/helpers/datepicker";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";
import {
  statisticsOffersRatingModal
} from "@core/store/modules/admin/statistic/modules/statistics/StatisticsOffersRatingModal";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const initialState = (): StatisticState => {
  return {
    statistics: null,
    statisticFilters: {
      filters: {
        datepicker: datepicker({ amount: 1, unit: "weeks" }),
        registeredDatepicker: {
          registeredDateStart: null,
          registeredDateEnd: null
        },
        groups: [StatisticGroupsEnum.OFFER, null],
        currency: null,
        withBlockedWebmaster: false,
        offerId: null,
        countryId: null,
        withdrawType: null,
        advertiserId: null,
        webmasterId: null,
        managerId: null,
        flowId: null,
        bunchId: null,
        agentId: null,
        utmSource: null,
        utmTerm: null,
        utmContent: null,
        utmCampaign: null,
        utmMedium: null,
        externalWebmasterId: null,
        regionId: null,
        isInHouse: null,
        isLaunched: null,
        isDomesticWebmaster: null,
        isPartnerNetwork: null,
        categoryId: null,
        webmasterLabel: null,
        globalSource: null,
        vertical: null
      },
      excludedFilters: {
        excludedOfferId: null,
        excludedWebmasterId: null,
        excludedCountryId: null,
        excludedAdvertiserId: null,
        excludedExternalWebmasterId: null,
        excludedManagerId: null,
        excludedBunchId: null,
        excludedFlowId: null,
        excludedWebmasterLabel: null,
        excludedRegionId: null,
        excludedWithdrawType: null,
        excludedIsPartnerNetwork: null,
        excludedAgentId: null,
        excludedGlobalSource: null,
        excludedUtmSource: null,
        excludedUtmTerm: null,
        excludedUtmContent: null,
        excludedUtmCampaign: null,
        excludedUtmMedium: null,
        excludedCategoryId: null
      }
    },
    subStatistics: null,
    statisticsOld: null,
    cards: {
      leads: null,
      cashFlow: null,
      debit: null,
      prepaid: null,
      commission: null,
      approve: null
    }
  };
};

const state: () => StatisticState = initialState;

const getters: GetterTree<StatisticState, RootState> = {
  enabledStatistic: (state, getters, rootState, rootGetters) =>
    hasPermissions(["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN"], rootGetters.permissions)
};

const mutations: MutationTree<StatisticState> = {
  SET_STATISTIC (state: StatisticState, payload: AdminStatisticByGroups): void {
    state.statistics = payload;
  },

  SET_OLD_STATISTIC (state: StatisticState, payload: AdminStatisticByGroups): void {
    state.statisticsOld = payload;
  },

  SET_CARDS (state, { type, ...payload }) {
    // @ts-ignore
    state.cards[type] = { ...state.cards[type], ...payload };
  },

  [UPDATE_STATISTIC_FILTERS]: (state: StatisticState, filters: BaseFiltersInput): void => {
    state.statisticFilters.filters = { ...state.statisticFilters.filters, ...filters };
  },

  [UPDATE_STATISTIC_EXCLUDED_FILTERS]: (state: StatisticState, excludedFilters: BaseFiltersInput): void => {
    state.statisticFilters.excludedFilters = { ...state.statisticFilters.excludedFilters, ...excludedFilters };
  },

  [UPDATE_GROUP]: (state, { event, idx }): void => {
    Vue.set(state.statisticFilters.filters.groups, idx, event);
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], filters: { ...initialState()[target].filters, ...filters } };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<StatisticState, RootState> = {
  async GET_STATISTIC ({ commit, rootState: { auth: { preferredVertical }, verticals: { currentVertical } } }, params): Promise<StatisticResponse> {
    const { filters, excludedFilters, groups } = params;
    const statistics = await StatisticsService.getAdminStatistic({ ...filters, vertical: currentVertical || preferredVertical }, excludedFilters, groups);

    sortFieldGroupsStatistic(statistics.items, "country", "region", "category");
    
    commit("SET_STATISTIC", statistics);

    // @ts-ignore
    return statistics;
  },

  //* Данные для основного раздела статистики
  async GET_STATISTICS ({ state, commit, rootState: { verticals: { currentVertical: vertical } } }): Promise<void> {
    const { groups, datepicker, registeredDatepicker, ...otherFilters } = state.statisticFilters.filters;
    const [withdrawType, subGoalType ] = otherFilters.withdrawType?.split("-") || [null, null];
    const preparedExcludedFilters = prepareExcludedFilters(state.statisticFilters.excludedFilters);
    const [excludedWithdrawType, excludedSubGoalType ] = preparedExcludedFilters.withdrawType?.split("-") || [null, null];
    const excludedFilters = { ...preparedExcludedFilters, withdrawType: excludedWithdrawType, subGoalType: excludedSubGoalType };
    const filters = { ...datepicker, ...registeredDatepicker, ...otherFilters, withdrawType, subGoalType, vertical };

    const statistics = await StatisticsService.getAdminStatistic(filters, excludedFilters, groups.filter(Boolean));
    sortFieldGroupsStatistic(statistics.items, "country", "region", "category");
    commit("SET_STATISTIC", statistics);
  },

  async GET_DEBIT_COMPARE ({ commit, rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminDebit(now.toDate(), vertical),
      StatisticsService.getAdminDebit(now.subtract(1, "day").toDate(), vertical)
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },

  async GET_PREPAID_COMPARE ({ commit, rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminPrepaid(now.toDate(), vertical),
      StatisticsService.getAdminPrepaid(now.subtract(1, "day").toDate(), vertical)
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },

  async GET_COMMISSION_COMPARE ({ commit, rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminCommission(
        now.clone().startOf("day").toDate(),
        now.clone().toDate(),
        vertical
      ),
      StatisticsService.getAdminCommission(
        now.clone().startOf("day").subtract(1, "day").toDate(),
        now.clone().subtract(1, "day").toDate(),
        vertical
      )
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },

  async GET_APPROVE_COMPARE ({ commit , rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();
    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminApproves(
        now.clone().startOf("day").toDate(),
        now.clone().toDate(),
        vertical
      ),
      StatisticsService.getAdminApproves(
        now.clone().startOf("day").subtract(1, "day").toDate(),
        now.clone().subtract(1, "day").toDate(),
        vertical
      )
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },

  async GET_LEADS_COMPARE ({ commit, rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();

    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminLeadsCompare({
        dateStart: now.clone().startOf("day").toDate(),
        dateEnd: now.clone().toDate(),
        vertical
      },
      [StatisticOtherGroupsEnum.CURRENCY]),
      StatisticsService.getAdminLeadsCompare({
        dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
        dateEnd: now.clone().subtract(1, "day").toDate(),
        vertical
      },
      [StatisticOtherGroupsEnum.CURRENCY])
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },

  async GET_CASHFLOW_COMPARE ({ commit, rootState: { verticals: { currentVertical: vertical } } }, type) {
    const now = moment();

    const [today, yesterday] = await Promise.all([
      StatisticsService.getAdminCashFlowCompare({
        dateStart: now.clone().startOf("day").toDate(),
        dateEnd: now.clone().toDate(),
        vertical
      },
      [StatisticOtherGroupsEnum.CURRENCY]),
      StatisticsService.getAdminCashFlowCompare({
        dateStart: now.clone().startOf("day").subtract(1, "day").toDate(),
        dateEnd: now.clone().subtract(1, "day").toDate(),
        vertical
      },
      [StatisticOtherGroupsEnum.CURRENCY])
    ]);
    commit("SET_CARDS", { today, yesterday, type });
  },
  
  [UPDATE_STATISTIC_FILTERS] ({ commit }, filters) {
    commit(UPDATE_STATISTIC_FILTERS, filters);
  },
  
  [UPDATE_GROUP] ({ commit }, payload): void {
    commit(UPDATE_GROUP, payload);
  },
  
  [UPDATE_STATISTIC_EXCLUDED_FILTERS] ({ commit }, excludedFilter) {
    commit(UPDATE_STATISTIC_EXCLUDED_FILTERS, excludedFilter);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
  }
};

export const statistic: Module<StatisticState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    statisticsOffersRatingModal
  }
};
