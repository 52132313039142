<template>
    <b-modal
        :active.sync="isModalActive"
        :on-cancel="onCancelAction"
        has-modal-card
        :width="width"
        :custom-class="customClass"
        :full-screen="fullScreen"
        @close="$emit('close', $event)"
        @after-enter="$emit('after-enter', $event)"
        @after-leave="$emit('after-leave', $event)">
        <form
            :autocomplete="autocomplete"
            :class="{ 'is-full': fullScreen }"
            class="modal-card"
            @submit.prevent.stop="$emit('submit', $event)">
            <header class="modal-card-head">
                <slot name="header"></slot>
            </header>
            <section class="modal-card-body">
                <slot></slot>
            </section>
            <footer class="modal-card-foot column is-multiline">
                <slot name="footer"></slot>
            </footer>
            <LLoading
                :active="isLoading"
                :is-full-page="false"></LLoading>
        </form>
    </b-modal>
</template>

<script>
  import { Modal } from "@core/mixins";
  import LLoading from "@/components/Common/LLoading.vue";

  export default {
    name: "ModalBox",
    mixins: [Modal],
    components: { LLoading },
    props: {
      autocomplete: {
        type: Boolean,
        default: false
      },

      fullScreen: {
        type: Boolean,
        default: false
      },

      width: {
        type: [Number, String],
        default: 960
      },
      customClass: {
        type: String,
        default: null
      }
    },

    created () {
      this.$emit("created");
    },

    computed: {
      onCancelAction () {
        return this.onCancel ? this.onCancel : () => {};
      }
    },

    methods: {
      cancel () {
        this.$emit("cancel");
      },

      confirm () {
        this.$emit("confirm");
      }
    },

    beforeDestroy () {
      this.$emit("beforeDestroy");
    }
  };
</script>

<style lang="scss" scoped>
    @keyframes error {
        $error: #fc5c5c;
        0% {
            border: 2px solid white;
        }

        25% {
            border: 2px solid $error;
        }

        50% {
            border: 2px solid white;
        }

        75% {
            border: 2px solid $error;
        }

        100% {
            border: 2px solid white;
        }
    }

    .modal-card {
        background-color: white;

        &.error {
            animation: 1500ms error;
        }

        ::v-deep {
            .common-table {
                thead {
                    tr {
                        th {
                            top: 0 !important;
                        }
                    }
                }
            }
        }
    }
</style>
