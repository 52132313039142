import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { useOffers } from "../../../../../../../stores/admin/offers/offersStore";
import { OffersFilters } from "@core/store/types/webmaster/offers";

export default function getSelectedOffersQueryGenerator (
  limit: number,
  offset: number,
  filters: OffersFilters
): ASTGeneratedOutput {
  const exclude = [];
  const { isShowCommissions } = useOffers();

  if (!isShowCommissions) {
    exclude.push(
      "items/finance/webmasterReward/value",
      "items/finance/webmasterReward/currency"
    );
  }

  const params = { exclude };

  const gqlObject = {
    operation: "offers",

    fields: [
      {
        items: [
          "id",
          "intId",
          "vertical",
          { detail: ["name"] },
          {
            finance: [
              {
                webmasterReward: ["value", "currency"]
              }
            ]
          },
          { landing: ["cost","currency"] },
          { categories: ["name"] }
        ]
      },
      "count"
    ],

    variables: {
      filters: {
        type: "OfferListCriteriaFilters",
        value: filters
      },
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      }
    }

  };

  return Generator.query(gqlObject, params);
}