import User from "@core/logic/user/User";
import { AxiosResponse } from "axios";
import Service from "@core/services/common/Service";

export default class Advertiser extends User {
  public hasManager = true;
  public geo = "";
  public sizeLeads = 0;
  public comment = "";
  public admin = "";
  
  public constructor (response?: Advertiser) {
    super(response);
    
    if (response) {
      this.hasManager = response.hasManager;
      this.geo = response.geo;
      this.sizeLeads = response.sizeLeads;
      this.comment = response.comment;
      this.admin = response.admin;
    }
  }
}

export class AdvertiserService extends Service{
  public static getAdvertiserPersonalManager (): Promise<AxiosResponse> {
    return this.rest.get("/advertiser/personal-manager");
  }
}
