<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :is-loading="isLoading"
            @after-enter="afterEnter"
            @submit="saveUser">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.profile.modal.header.title`) }}
                </h5>
            </template>

            <AdminProfile
                is-edit
                is-currency
                :is-vertical="isVisibleByAllVerticals"
                :user.sync="localForm">
            </AdminProfile>

            <ActiveSessions
                v-if="!asAdmin"
                @userLogout="isActive = false">
            </ActiveSessions>

            <template #footer>
                <div class="container">
                    <div class="columns">
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-info is-light"
                                expanded
                                @click="openPasswordModal">
                                {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                            </b-button>
                        </div>
                        <div class="column">
                            <b-button
                                class="is-fullwidth is-success is-light"
                                native-type="submit">
                                {{ $t(`common.buttons.save`) }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </ModalBox>
    </div>
</template>

<script>
  import ActiveSessions from "@/components/Common/Profile/ActiveSessions";
  import AdminProfile from "@/components/Common/Admin/AdminProfile";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { EDIT_USER, FETCH_USER } from "@core/store/action-constants";
  import {
    UPDATE_ADMIN_PROFILE_FORM,
    SET_EMPTY_PARTIAL,
    UPDATE_MODAL
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

  export default {
    name: "AdminProfileModal",

    components: {
      ModalBox,
      AdminProfile,
      ActiveSessions
    },

    data () {
      return {
        localForm: null
      };
    },

    computed: {
      ...mapFields("admin/profile", {
        fields: ["isActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("admin/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapState("admin/profile", [
        "user", "form"
      ]),

      ...mapGetters("verticals", ["isVisibleByAllVerticals"]),

      ...mapGetters(["hasForcedPasswordChange"]),

      isLoading () {
        return this.$wait(`admin/profile/${ FETCH_USER }`)
          || this.$wait(`admin/profile/${ EDIT_USER }`);
      },

      ...mapGetters(["asAdmin"])
    },

    methods: {
      ...mapActions("admin/profile", [
        FETCH_USER,
        EDIT_USER
      ]),

      ...mapMutations("admin/profile", [
        UPDATE_ADMIN_PROFILE_FORM,
        SET_EMPTY_PARTIAL
      ]),

      afterEnter () {
        this.$watch("isPasswordModalActive", (value) => {
          this.isActive = !value;
        });
      },

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      convertUserToForm () {
        const { contact, name, inHouse, preferredCurrency, preferredVertical, timeZone: { alias: timeZone } } = this.user;

        const form = {
          ...contact,
          preferredCurrency,
          preferredVertical,
          name,
          inHouse,
          timeZone
        };

        this.UPDATE_ADMIN_PROFILE_FORM(form);
        this.localForm = _cloneDeep(this.form);
      },

      async saveUser () {
        try {
          await this.EDIT_USER();

          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });

          this.SET_EMPTY_PARTIAL();
        } catch ({ errors }) {
          const { message } = errors[0];
          this.$buefy.toast.open({
            message: this.$t(`admin.profile.modal.messages.${ message }`, { email: this.form.email }),
            type: "is-danger"
          });
        }
      }
    },

    watch: {
      async isActive (value) {
        if (value) {
          await this.FETCH_USER();
        }
        this.convertUserToForm();
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_ADMIN_PROFILE_FORM(value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .modal-card {
            max-width: 700px;
            width: 100vw;
        }
    }

    input {
        &[type="checkbox"] {
            display: none;

            & + span {
                color: $success !important;
                border: 1px solid $success !important;
                background-color: transparent !important;
            }

            &:checked {
                & + span {
                    color: white !important;
                    background-color: $success !important;
                }
            }
        }
    }
</style>
