import Service from "@core/services/common/Service";
import { DrawdownReasonListItem } from "@core/store/types/admin/lists/drawdownReason";
import analytic from "./graphql/analytic.graphql";
import analyticAnalyse from "./graphql/analyticAnalyse.graphql";
import addIncidence from "./graphql/addIncidence.graphql";
import webmastersContacts from "./graphql/webmastersContacts.graphql";
import webmastersIds from "./graphql/webmastersIds.graphql";
import analyticDetail from "./graphql/analyticDetail.graphql";
import incidenceAnalyse from "./graphql/incidenceAnalyse.graphql";
import incidenceSummary from "./graphql/incidenceSummary.graphql";
import getDrawdown from "./graphql/getDrawdown.graphql";
import incidenceDetails from "./graphql/incidenceDetails.graphql";
import incidenceContextQuery from "./graphql/incidenceContext.graphql";
import {
  AdminSubAnalyticAnalyseResponse,
  AnalyticFilters,
  AnalyticFiltersRequest,
  AnalyticResponse
} from "@core/store/types/webmaster/analytic/analytic";
import { AxiosPromise } from "axios";
import {
  SubAnalyticDrawdownReasonModalWebmaster,
  SubAnalyticReasonModalInputResponse
} from "@core/store/types/webmaster/analytic/subAnalyticDrawdownReasonModal";
import {
  AnalyticAnalyseChartFiltersRequest,
  AnalyticAnalyseChartResponse
} from "@core/store/types/webmaster/analytic/analyticAnalyseChart";
import { AnalyticReasonResponse } from "@core/store/types/webmaster/analytic/analyticReasons";
import { AnalyticAnalyseChartDetailFilters, AnalyticAnalyseChartDetailResponse } from "@core/store/types/webmaster/analytic/AnalyticAnalyseChartDetail";
import { ReasonEnum } from "@core/store/types/admin/common/enums/ReasonEnum";
import { IncidenceDetailsSortEnum } from "@core/store/types/webmaster/analytic/enums/IncidenceDetailsSortEnum";
import { incidenceContextMapper } from "@core/services/webmaster/analytic/dataset/incidenceContextMapper";

export class AnalyticService extends Service {
  /**
   * @param {number} limit
   * @param {number} offset
   * @param filters
   * @param mainGroup
   * @param additionalGroup
   * @return {Promise<AnalyticResponse>}
   */
  public static async getAnalytic (
    limit: number,
    offset: number,
    filters: AnalyticFiltersRequest,
    mainGroup: string,
    additionalGroup: string
  ): Promise<AnalyticResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: analytic,
          variables: {
            limit,
            offset,
            filters,
            mainGroup,
            additionalGroup
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param {number} limit
   * @param {number} offset
   * @param group
   * @param contextId
   * @param threshold
   * @param filters
   * @return {Promise<AdminSubAnalyticAnalyseResponse>}
   */
  public static async getAnalyticAnalyse (
    limit: number,
    offset: number,
    group: string,
    contextId: string,
    threshold: number,
    filters: AnalyticFilters
  ): Promise<AdminSubAnalyticAnalyseResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: analyticAnalyse,
          variables: {
            limit,
            offset,
            group,
            contextId,
            threshold,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param filters
   * @return {Promise<AnalyticResponse>}
   */
  public static async getWebmastersContacts (filters: { id?: string | null; }): Promise<string> {
    try {
      const { data: { webmasters } } = await this.api.get("", {
        params: {
          query: webmastersContacts,
          variables: {
            filters
          }
        }
      });

      return webmasters.items?.map((item: SubAnalyticDrawdownReasonModalWebmaster) => item.contact.telegram)[0];
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param filters
   * @return {Promise<AnalyticResponse>}
   */
  public static async getWebmastersIds (filters: { manager?: string }): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: webmastersIds,
          variables: {
            limit: 1000,
            offset: 0,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param filters
   * @return {Promise<AnalyticResponse>}
   */
  public static async getIncidenceAnalyse (filters: AnalyticAnalyseChartFiltersRequest): Promise<AnalyticAnalyseChartResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: incidenceAnalyse,
          variables: {
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param input
   * @return {Promise<AnalyticResponse>}
   */
  public static async addIncidence (input: SubAnalyticReasonModalInputResponse): Promise<AnalyticResponse> {
    try {
      return await this.api.post("", {
        query: addIncidence,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param filters
   * @param mainGroup
   * @param additionalGroup
   * @return {Promise<AnalyticResponse>}
   */
  public static async getDetailAnalytic (
    filters: any,
    mainGroup: string,
    additionalGroup: string
  ): Promise<AnalyticResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: analyticDetail,
          variables: {
            filters,
            mainGroup,
            additionalGroup
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getIncidenceContext (
    webmasterId: string,
    offerId: string,
    date: Date | string
  ) {
    try {
      const { data: { incidenceContext } } = await this.api.get("", {
        params: {
          query: incidenceContextQuery,
          variables: {
            webmasterId,
            offerId,
            date
          }
        }
      });
      return incidenceContextMapper(incidenceContext);
    } catch (e) {
      throw e;
    }
  }
  
  /**
   * @param {number} limit
   * @param {number} offset
   * @param filters
   * @param {string} threshold
   * @param {string} sort
   * @param {string} order
   * @return {Promise<AnalyticResponse>}
   */
  public static async getAnalyticReason (
    limit: number,
    offset: number,
    filters: any,
    threshold: number,
    sort: Nullable<string | string[]>,
    order: Nullable<string | string[]>
  ): Promise<AnalyticReasonResponse> {
    try {
      const { data: { incidenceSummary: data } } = await this.api.get("", {
        params: {
          query: incidenceSummary,
          variables: {
            limit,
            offset,
            filters,
            threshold,
            sort,
            order
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getDrawdown (): Promise<DrawdownReasonListItem[]> {
    try {
      const { data: { incidenceReasons } } = await this.api.get("", {
        params: {
          query: getDrawdown
        }
      });
      
      return incidenceReasons;
    } catch (e) {
      throw e;
    }
  }

  public static async getIncidenceDetails (
    threshold: number,
    incidences: ReasonEnum[],
    filters: AnalyticAnalyseChartDetailFilters,
    sort: Nullable<IncidenceDetailsSortEnum>,
    order: Nullable<string>,
    limit: number,
    offset: number
  ): Promise<AnalyticAnalyseChartDetailResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: incidenceDetails,
          variables: {
            threshold,
            incidences,
            filters,
            sort,
            order,
            limit,
            offset
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
