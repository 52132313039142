import { OfferLandingsAddModalInput, Query } from "@core/store/types/admin/offer/OfferLandingsAddModal";
import { LandingTypeEnum } from "@core/store/types/admin/offer/enums/LandingTypeEnum";
import _cloneDeep from "lodash/cloneDeep";

export default function PrepareDataOfferMapper (input: NullableRecord<OfferLandingsAddModalInput>): NullableRecord<OfferLandingsAddModalInput> {
  const { typeLanding, ...fields } = input;
  const newOptions: NullableRecord<OfferLandingsAddModalInput> = _cloneDeep(fields);
  const queryOptions: Query = {};
  
  if (typeLanding === LandingTypeEnum.INTERIOR) {
    newOptions.externalDomain = null;
    return newOptions;
  }
  
  if (newOptions?.externalDomain?.path?.length === 0) {
    newOptions.externalDomain.path = null;
  }
  
  if (newOptions?.externalDomain?.query?.[0]?.key?.length === 0) {
    newOptions.externalDomain.query = null;
  }
  
  if (newOptions.externalDomain?.query) {
    newOptions.externalDomain.query.forEach(item => {
      queryOptions[item.key] = item.value;
    });
    // @ts-ignore
    newOptions.externalDomain.query = queryOptions;
  }
  
  return newOptions;
}