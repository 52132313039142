<template>
    <form
        class="form"
        @submit.prevent.stop="updateUser">
        <b-notification
            v-if="!isSubAgent && contacts.length"
            :closable="false"
            has-icon
            type="is-info">
            <span v-html="$t('common.auth.signUp.message')"></span>
            <span class="is-flex">
                <span
                    :key="contact.href"
                    v-for="contact in contacts"
                    class="mr-2">
                    <img
                        class="mr-1"
                        style="margin-bottom: -2px"
                        :src="require(`@/assets/Contacts/logo_${ _camelCase(contact.name) }.svg`)">
                    <a
                        :href="contact.href"
                        target="_blank"
                        rel="noopener">{{ contact.name }}
                    </a>
                </span>
            </span>
        </b-notification>
        <WebmasterProfile
            :user.sync="localForm">
            <template #buttons>
                <b-button
                    :loading="isSaveLoading"
                    :disabled="isDisabled"
                    class="is-success"
                    expanded
                    native-type="submit"
                    rounded>
                    {{ $t(`webmaster.profile.values.save`) }}
                </b-button>
            </template>
        </WebmasterProfile>
        <LLoading :active="isSaveLoading"></LLoading>
    </form>
</template>

<script>
  import _camelCase from "lodash/camelCase";
  import WebmasterProfile from "@/components/Common/Webmaster/WebmasterProfile";
  import { EDIT_USER } from "@core/store/action-constants";
  import { UPDATE_WEBMASTER_PROFILE_FORM } from "@core/store/mutation-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading";
  import fbqInit from "@core/plugins/facebookPixel";
  import { capitalizeFirstLetter } from "@core/filters";

  export default {
    name: "SignUpDetailWebmaster",
    components: { LLoading, WebmasterProfile },

    created () {
      if (process.env.NODE_ENV === "production") {
        this.fbqInit();
      }
    },

    data () {
      return {
        localForm: null
      };
    },

    computed: {
      contacts () {
        return ["email", "telegram", "skype", "whatsApp"].map(contact => {
          if (this.$saas.contacts[contact]) {
            return {
              name: capitalizeFirstLetter(contact),
              href: this.$saas.contacts[contact]
            };
          }
          return null;
        }).filter(Boolean);
      },

      ...mapState("webmaster/profile", [
        "user",
        "form"
      ]),

      isDisabled () {
        return !(this.localForm.telegram?.length || !!this.localForm.whatsApp || !!this.localForm.viber || this.localForm.skype?.length);
      },
      
      isSubAgent () {
        return this.$store.state.auth.subType === "SUBWEBMASTER";
      },

      isSaveLoading () {
        return this.$wait(`webmaster/profile/${ EDIT_USER }`);
      }
    },

    methods: {
      ...mapActions("webmaster/profile", [
        EDIT_USER
      ]),

      ...mapMutations("webmaster/profile", [
        UPDATE_WEBMASTER_PROFILE_FORM
      ]),

      fbqInit,
      _camelCase,

      convertUserToForm () {
        const { user } = this;

        const form = {
          timeZone: user.timeZone.alias,
          telegram: user.contact.telegram,
          email: user.contact.email,
          categoriesPreferences: user.detail.categoriesPreferences.map(({ id }) => id),
          trafficTypes: user.detail.trafficTypes.map(({ id }) => id),
          login: user.login,
          skype: user.contact.skype,
          viber: user.contact.viber,
          whatsApp: user.contact.whatsApp,
          birthday: user.detail.birthday,
          preferredCurrency: user.preferredCurrency,
          preferredVertical: user.preferredVertical
        };

        this.UPDATE_WEBMASTER_PROFILE_FORM(form);
        this.localForm = _cloneDeep(this.form);
      },

      async updateUser () {
        try {
          await this.EDIT_USER();

          this.$buefy.toast.open({
            message: this.$t("webmaster.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("webmaster.profile.saveProfileErrorConfirmation"),
            type: "is-danger"
          });
        }
        
        if (this.isSubAgent) {
          this.$router.push({ name: "dashboard" }).catch(_ => {});
        }
      }
    },

    watch: {
      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_WEBMASTER_PROFILE_FORM(value);
        }
      },

      user () {
        this.convertUserToForm();
      }
    }
  };
</script>

<style lang="scss" scoped>
    form {
        max-width: 800px;
    }
</style>
