import {
  GET_WRITE_OFFS_BY_LEADS,
  GET_WRITE_OFFS_BY_LEADS_GROUPED,
  GET_WRITE_OFFS_PROXY, SET_EMPTY
} from "@core/store/action-constants";
import financesCostsFieldsList
  from "@core/store/modules/advertiser/finances/modules/costs/lists/financesCostsFieldsList.json";
import { writeOffsByLeads } from "@core/store/modules/advertiser/finances/modules/costs/writeOffsByLeads";
import { writeOffsByLeadsGrouped } from "@core/store/modules/advertiser/finances/modules/costs/writeOffsByLeadsGrouped";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CostsState } from "@core/store/types/advertiser/finances/modules/costs";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";

const initialState = (): CostsState => ({
  filters: {
    datepicker: datepicker(),
    offer: null,
    remoteId: null,
    currency: CurrencyEnum.USD,
    group: null
  },
  selectedFields: financesCostsFieldsList
});

const state = initialState;

const getters: GetterTree<CostsState, RootState> = {
  isGrouped: ({ filters }) => !!filters.group,
  getWriteOffs: (state, getters, rootState) => getters.isGrouped ?
    rootState.advertiser.finances.costs.writeOffsByLeadsGrouped.data :
    rootState.advertiser.finances.costs.writeOffsByLeads.data
};

const mutations: MutationTree<CostsState> = {
  [UPDATE_FILTERS]: (state, filters): void => {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state, target?: keyof CostsState) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<CostsState, RootState> = {
  async [GET_WRITE_OFFS_PROXY] ({ state, getters, dispatch }) {
    const { limit, offset } = pagination(100);
    const { datepicker, remoteId, group, ...filters } = state.filters;

    if (getters.isGrouped) {
      await dispatch(`writeOffsByLeadsGrouped/${ GET_WRITE_OFFS_BY_LEADS_GROUPED }`,
        { filters: { ...datepicker, ...filters }, group, pagination: { limit, offset } });
    } else {
      await dispatch(`writeOffsByLeads/${ GET_WRITE_OFFS_BY_LEADS }`,
        { filters: {
          ...datepicker,
          ...filters,
          remoteId
        },
        pagination: { limit, offset }
        });
    }
  },

  [UPDATE_FILTERS] ({ commit }, filters: CostsState["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`writeOffsByLeads/${ SET_EMPTY }`);
    commit(`writeOffsByLeadsGrouped/${ SET_EMPTY }`);
  }
};

export const costs: Module<CostsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    writeOffsByLeads,
    writeOffsByLeadsGrouped
  }
};
