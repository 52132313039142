import { AdvertisersDutyService } from "@core/services/admin/finances/advertisersDuty/advertisersDutyService";
import { AdvertisersDutyState } from "@core/store/types/admin/finances/advertisersDuty/advertisersDuty";
import {
  advertisersDutyAnalyseModal
} from "@core/store/modules/admin/finances/modules/advertisersDuty/advertisersDutyAnalyseModal";
import { GET_ADVERTISERS_DUTY } from "@core/store/action-constants";
import {
  SET_ADVERTISERS_DUTY,
  UPDATE_SORTING,
  UPDATE_FILTERS,
  SET_EMPTY
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import { CurrencyEnum } from "@core/store/types/common/enums/currencyEnum";
import {
  efficiencyDataMapper
} from "@core/store/modules/admin/capacityAndBumps/modules/efficiency/dataset/efficiencyDataMapper";
import { advertisersDutyDetail } from "@core/store/modules/admin/finances/modules/advertisersDuty/advertisersDutyDetail";

const initialState = (): AdvertisersDutyState => {
  return {
    advertisersDuty: null,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "weeks" }),
      currency: CurrencyEnum.USD,
      userId: null,
      groups: ["ADVERTISER"]
    },
    sort: null,
    order: null
  };
};

const state: () => AdvertisersDutyState = initialState;

const mutations: MutationTree<AdvertisersDutyState> = {
  [SET_ADVERTISERS_DUTY]: (state, advertisersDuty: AdvertisersDutyState["advertisersDuty"]): void => {
    state.advertisersDuty = advertisersDuty;
  },

  [UPDATE_FILTERS] (state, filter: AdvertisersDutyState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_SORTING] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<AdvertisersDutyState, RootState> = {
  async [GET_ADVERTISERS_DUTY] ({ commit, state }) {
    const { currency, datepicker, groups, ...filters } = state.filters;
    const { limit, offset } = pagination();

    const data = await AdvertisersDutyService.getAdvertisersDuty(
      { ...datepicker, ...filters },
      limit,
      offset,
      state.sort,
      state.order,
      [],
      currency
    );

    commit(SET_ADVERTISERS_DUTY, efficiencyDataMapper(data, groups[0]));
  },

  [UPDATE_FILTERS] ({ commit }, filter) {
    commit(UPDATE_FILTERS, filter);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    commit(`advertisersDutyDetail/${ SET_EMPTY }`);
  }
};

export const advertisersDuty: Module<AdvertisersDutyState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    advertisersDutyAnalyseModal,
    advertisersDutyDetail
  }
};
