import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import { EDIT_ADVERTISER } from "@core/store/action-constants";
import {
  SET_ADVERTISERS_EDIT_FORM,
  UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS,
  SET_EMPTY,
  SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE,
  UPDATE_ADVERTISERS_EDIT_FORM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { Advertiser, AdvertisersEditState } from "@core/store/types/admin/users/advertisers/Edit";
import { ActionTree, Module, MutationTree } from "vuex";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import { AdvertiserForm } from "@core/store/logic/admin/users/AdvertiserForm";

const initialState = (): AdvertisersEditState => {
  return {
    isActive: false,
    form: {}
  };
};

const mutations: MutationTree<AdvertisersEditState> = {
  [SET_IS_ADVERTISERS_EDIT_MODAL_ACTIVE] (state, value: boolean) {
    state.isActive = value;
  },
  
  [UPDATE_ADVERTISERS_EDIT_FORM] (state, form) {
    state.form = { ...state.form, ...form };
  },
  
  [UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS] (state, commission) {
    state.form.minimalCommission = state.form.minimalCommission
      ?.map(item => item.currency === commission.currency ? commission : item);
  },
  
  [SET_ADVERTISERS_EDIT_FORM] (state, advertiser: Advertiser) {
    state.form = new AdvertiserForm(advertiser);
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<AdvertisersEditState, RootState> = {
  async [EDIT_ADVERTISER] ({ state: { form }, commit }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, avatarId, systemVertical, advertiserId, ...input } = form;
    if (!input.isFineDebt) {
      input.fineDebtDays = null;
      input.fineDebtPercent = null;
    }
    const editedAdvertiser = await AdvertisersEditService
      .editAdvertiser(advertiserId as string, { ...input, avatarId });
    commit("admin/users/advertisers/LOCAL_UPDATE", { items: editedAdvertiser, target: "advertisers" }, { root: true });
  }
};

export const edit: Module<AdvertisersEditState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  modules: {
    currencyList
  }
};
