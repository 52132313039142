import { RootState } from "@core/store/root-state";
import { PartialOfferWebmastersState, ResponseOfferWebmaster } from "@core/store/types/admin/offer/OfferWebmasters";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { offerEditWebmastersModal } from "@core/store/modules/admin/offer/modules/edit/modules/offerEditWebmastersModal";
import {
  DELETE_OFFER_WEBMASTERS,
  GET_OFFER_ID,
  UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD,
  UPDATE_MINIMAL_ALLOWED_REWARD
} from "@core/store/action-constants";
import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import { OfferEditWebmasters } from "@core/store/modules/admin/offer/modules/edit/modules/offerEditWebmasters";
import { WATCHED_PARAMS } from "@core/store/mutation-constants";
import { webmastersIdHistoryModal } from "@core/store/modules/admin/offer/modules/edit/modules/webmastersIdHistoryModal";

export class offerEditInternalWebmasters extends OfferEditWebmasters<PartialOfferWebmastersState, RootState> {
  private readonly initState: () => PartialOfferWebmastersState;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): PartialOfferWebmastersState => {
      return {
        webmasters: {},
        filters: {
          loginOrIntId: null,
          manager: null
        },
        pagination: {
          page: 1,
          perPage: 25
        }
      };
    };
    
    this.getters({
      [WATCHED_PARAMS]: ({ filters }) => JSON.stringify(filters)
    });

    this.actions({
      async [UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD] ({
        state,
        rootGetters,
        commit
      }) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

        try {
          if (state.webmasters?.items?.length && offerId) {
            const limit = state.pagination.perPage * state.pagination.page;
            const webmastersIds = state.webmasters.items.map(user => user.webmaster.id);

            const { data: { offer: { webmasters: { items } } } } =
              await OfferEditWebmastersServices.getMaximalAllowedInternalWebmastersReward(
                offerId,
                webmastersIds,
                limit
              );

            const webmastersReward: { [key: string]: unknown } = {};

            items.forEach((item: ResponseOfferWebmaster) => {
              webmastersReward[item.webmaster.id] = item.maximalAllowedWebmasterReward;
            });

            commit(UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD, webmastersReward);
          }
        } catch (e: any) {
          throw new Error(e);
        }
      },

      async [DELETE_OFFER_WEBMASTERS] ({
        rootGetters,
        dispatch
      }, payload) {
        const offerId = rootGetters[`admin/offer/edit/common/${ GET_OFFER_ID }`];

        try {
          if (offerId) {
            const { data: { offerExcludeMultipleWebmasters } } =
              await OfferEditWebmastersServices.offerExcludeMultipleWebmasters(offerId, payload);

            dispatch(`admin/offer/edit/${ UPDATE_MINIMAL_ALLOWED_REWARD }`,
              {}, { root: true });

            return offerExcludeMultipleWebmasters;
          }
        } catch (e) {
          throw e;
        }
      }
    });

    this.state(this.initState());

    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      }
    });

    this.modules({
      webmastersIdHistoryModal,
      offerEditWebmastersModal,
      withdrawTypesList
    });

    return this;
  }
}