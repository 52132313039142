import Service from "@core/services/common/Service";
import { FlowRedirectMacrosListResponse } from "@core/store/types/admin/common/FlowRedirectMacrosListState";
import enumValues from "@core/services/common/graphql/enumValues.graphql";

export class FlowRedirectMacrosService extends Service {
  public static async getFlowRedirectMacros (enumListName: string): Promise<FlowRedirectMacrosListResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: enumValues,
          variables: {
            enumListName
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}