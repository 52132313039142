export default [
  {
    path: "integrationsLog",
    name: "integrationsLog",
    redirect: { name: "admin:integrationsLog:log" },
    component: (): Promise<any> => import(/* webpackChunkName: "admin:integrationsLog:log" */ "@/views/Admin/IntegrationsLogView.vue"),
    children: [
      {
        path: "log",
        name: "log",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:integrationsLog:log" */ "@/components/Admin/IntegrationsLog/IntegrationsLog.vue")
      },
      {
        path: "advertiserPostbackLogs",
        name: "advertiserPostbackLogs",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:integrationsLog:advertiserPostbackLogs" */ "@/components/Admin/IntegrationsLog/AdvertiserPostbackLogs.vue")
      }
    ]
  }
];