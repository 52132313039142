// @ts-ignore
import dev from "../../../locales/dev.js";
// @ts-ignore
import en from "../../../locales/en.js";
// @ts-ignore
import ru from "../../../locales/ru.js";
import Vue from "vue";
import VueI18n, { IVueI18n, TranslateResult, Values } from "vue-i18n";

Vue.use(VueI18n);

const fallbackLocale = "en";

const locale: string = navigator.language.slice(0, 2) || fallbackLocale;

const messages: Record<string, any> = {
  ru,
  en
};

if (process.env.NODE_ENV !== "production") {
  messages.dev = dev;
}

Vue.prototype.$t = function (key: string, options: Values) {
  if (!this.$i18n.te(key)) {
    const path = key.split(".");
    path[path.length - 1] = "default";
    if (!this.$i18n.te(path.join("."))) {
      path.pop();
    }
    const translate = this.$i18n.t(path.join("."), options);
    return typeof translate !== "object" ? translate : null;
  } else {
    return this.$i18n.t(key, options).default || this.$i18n.t(key, options);
  }
};

declare module "vue/types/vue" {
  interface Vue {
    readonly $i18n: VueI18n & IVueI18n;
    // @ts-ignore
    $t: (key: VueI18n.Path, values?: VueI18n.Values) => string & TranslateResult;
    $tc: typeof VueI18n.prototype.tc;
    $te: typeof VueI18n.prototype.te;
    $d: typeof VueI18n.prototype.d;
    $n: typeof VueI18n.prototype.n;
  }
}

export default new VueI18n({
  locale,
  fallbackLocale,
  messages
});
