

























































// Простой компонет отображения значка (или иконки если передана в слот)
import { withDefaults, defineProps } from "@vue/runtime-dom";
interface Props {
  position: "before" | "after" | "both";
  type: any;
  color: string | null;
  tooltip: string | null;
  showStatus: boolean;
  tooltipPosition: string;
  tooltipType: string;
  boundary: "scrollParent" | "window" | "viewport" | HTMLElement;
}
const __sfc_main = {};
__sfc_main.props = {
  position: {
    key: "position",
    required: false,
    type: String,
    default: "before"
  },
  type: {
    key: "type",
    required: false,
    type: null,
    default: null
  },
  color: {
    key: "color",
    required: false,
    type: [String, null],
    default: null
  },
  tooltip: {
    key: "tooltip",
    required: false,
    type: [String, null],
    default: null
  },
  showStatus: {
    key: "showStatus",
    required: false,
    type: Boolean,
    default: true
  },
  tooltipPosition: {
    key: "tooltipPosition",
    required: false,
    type: String,
    default: "tooltipPosition"
  },
  tooltipType: {
    key: "tooltipType",
    required: false,
    type: String,
    default: "is-primary"
  },
  boundary: {
    key: "boundary",
    required: false,
    type: [String, null],
    default: "viewport"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
export default __sfc_main;
