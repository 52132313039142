import { AuditResponse, Data, Initiator, Offer } from "@core/store/types/admin/audit";

export abstract class Audit {
  public action?: string;
  public createdAt?: string;
  public initiator?: Initiator;
  public offer?: Offer;
  public newData?: Data;
  public oldData?: Data;
  
  protected constructor (payload?: AuditResponse) {
    if (payload) {
      this.action = payload.action;
      this.createdAt = payload.createdAt;
      this.initiator = payload.initiator;
      this.offer = payload.offer;
      this.newData = {
        name: payload.newData["detail.name"],
        holdMinutes: payload.newData["finance.holdTime"]?.holdMinutes,
        webmasterReward: payload.newData["finance.webmasterReward"]?.value,
        minimalCommission: payload.newData["finance.minimalCommission"],
        withdrawType: payload.newData["finance.withdrawType"]?.value,
        updatedAt: payload.newData?.updatedAt,
        deletedAt: payload.newData?.deletedAt,
        description: payload.newData?.detail?.description,
        type: payload.newData?.type,
        cost: payload.newData?.landing?.cost,
        isLaunched: payload.newData?.isLaunched,
        rules: payload.newData?.detail?.rules,
        isInHouse: payload.newData?.detail?.isInHouse,
        preCost: payload.newData?.landing?.preCost
      };
      this.oldData = {
        name: payload.oldData["detail.name"],
        holdMinutes: payload.oldData["finance.holdTime"]?.holdMinutes,
        webmasterReward: payload.oldData["finance.webmasterReward"]?.value,
        minimalCommission: payload.oldData["finance.minimalCommission"],
        withdrawType: payload.oldData["finance.withdrawType"]?.value,
        updatedAt: payload.oldData?.updatedAt,
        deletedAt: payload.oldData?.deletedAt,
        description: payload.oldData?.detail?.description,
        type: payload.oldData?.type,
        cost: payload.oldData?.landing?.cost,
        isLaunched: payload.oldData?.isLaunched,
        rules: payload.oldData?.detail?.rules,
        isInHouse: payload.oldData?.detail?.isInHouse,
        preCost: payload.oldData?.landing?.preCost
      };
    }
  }
}
