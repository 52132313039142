<template>
    <div
        class="virtual-loading-wrapper">
        <div class="virtual-loading">
            <InlineLoader
                size="is-large is-gray"
                active></InlineLoader>
            <!--            <span>-->
            <!--                {{ $t("common.table.virtualLoading") }}-->
            <!--            </span>-->
        </div>
    </div>
</template>

<script>
  import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
  
  export default {
    name: "TableVirtualLoading",
    components: { InlineLoader }
  };
</script>

<style lang="scss" scoped>
  .virtual-loading-wrapper {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    .virtual-loading {
      margin-bottom: 10px;
      position: sticky;
      display: flex;
      flex-direction: column;
      top: 47%;
      button {
        background: none;
      }
    }
  }
</style>