import { GET_REFERRALS, SET_REFERRALS, SET_EMPTY } from "@core/store/action-constants";
import {
  UPDATE_REFERRALS_SORTINGS,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { Referrals, ReferralsFilters, ReferralsState } from "@core/store/types/webmaster/referrals";
import { ReferralService } from "@core/services/webmaster/referrals/referralsService";
import { asideReferrals } from "@core/store/modules/webmaster/referrals/asideReferrals";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): ReferralsState => {
  return {
    referrals: null,
    filters: {
      datepicker: {
        dateStart: null,
        dateEnd: datepicker().dateEnd
      },
      login: null
    },
    sort: null,
    order: null
  };
};

const state: () => ReferralsState = initialState;

const mutations: MutationTree<ReferralsState> = {
  [SET_REFERRALS] (state, referrals: Referrals[]): void {
    state.referrals = referrals;
  },
  
  [UPDATE_REFERRALS_SORTINGS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [UPDATE_FILTERS] (state, filter: ReferralsFilters): void {
    state.filters = { ...state.filters, ...filter };
  },

  [SET_EMPTY] (state, target?: keyof ReferralsState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<ReferralsState, RootState> = {
  async [GET_REFERRALS] ({ state, commit }): Promise<void> {
    const { limit, offset } = pagination();
    const { sort, order, filters } = state;
    const { datepicker, ...fields } = filters;
    
    const { data: { referralBonus } } = await ReferralService.getReferrals(
      limit,
      offset,
      sort,
      order,
      {
        ...datepicker,
        ...fields
      }
    );
    
    commit(SET_REFERRALS, referralBonus);
  },
  
  [UPDATE_FILTERS] ({ commit }, filters: ReferralsState["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const referrals: Module<ReferralsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    asideReferrals
  }
};
