import {
  AriaComponent,
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from "echarts/components";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import Vue from "vue";
import ECharts from "vue-echarts";

use([
  CanvasRenderer,
  
  GridComponent,
  AriaComponent,
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  ToolboxComponent,
  DataZoomComponent
]);

// register globally (or you can do it locally)
Vue.component("VChart", ECharts);
