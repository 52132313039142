import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  DashboardStatisticWidget,
  DashboardPayoutRequestWidget,
  DashboardCapacityRequestWidget,
  DashboardCapacityByCountryWidget,
  DashboardStatisticWidgetLeadStatistic,
  DashboardStatisticWidgetEfficiencyRate,
  DashboardStatisticWidgetIncidenceWidget,
  DashboardStatisticWidgetEfficiencyKpiBonus,
  DashboardStatisticWidgetSummaryClientsWidget
} from "@core/store/types/admin/dashboard/dashboardStatisticWidget";
import {
  SET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET,
  SET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET,
  SET_DASHBOARD_SUMMARY_CLIENTS_WIDGET,
  SET_DASHBOARD_EFFICIENCY_RATE_WIDGET,
  SET_DASHBOARD_LEAD_STATISTIC_WIDGET,
  UPDATE_CAPACITY_BY_COUNTRY_FILTERS,
  SET_DASHBOARD_INCIDENCE_WIDGET,
  SET_DASHBOARD_CAPACITY_WIDGET,
  SET_DASHBOARD_PAYOUT_WIDGET,
  UPDATE_KPI_BONUS_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET,
  GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET,
  GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET,
  GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET,
  GET_DASHBOARD_EFFICIENCY_RATE_WIDGET,
  GET_DASHBOARD_LEAD_STATISTIC_WIDGET,
  GET_DASHBOARD_STATISTICS_WIDGET,
  GET_DASHBOARD_INCIDENCE_WIDGET,
  GET_DASHBOARD_CAPACITY_WIDGET,
  GET_DASHBOARD_PAYOUT_WIDGET,
  SET_EMPTY
} from "@core/store/action-constants";
import { DashboardStatisticWidgetService } from "@core/services/admin/dashboard/dashboardStatisticWidgetService";
import { dashboardLaunchActivityWidget } from "@core/store/modules/admin/dashboard/modules/dashboardLaunchActivityWidget";
import { datepicker } from "@core/helpers/datepicker";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { dashboardStatisticChartWidget } from "@core/store/modules/admin/dashboard/modules/dashboardStatisticChartWidget";

const initialState = (): DashboardStatisticWidget => {
  return {
    efficiencyRateWidget: null,
    leadStatisticWidget: null,
    incidenceWidget: null,
    dashboardSummaryClientsWidget: null,
    efficiencyKpiBonusWidget: {
      bonusTotal: {},
      commission: {
        moneyCommission: {},
        current: {},
        currentGrade: {},
        nextGrade: {}
      },
      lead: {
        leads: null,
        current: {},
        currentGrade: {},
        nextGrade: {}
      }
    },
    capacityRequestWidget: null,
    payoutRequestWidget: null,
    capacityByCountryWidget: null,
    kpiBonusFilters: {
      bonusPeriod: "currentMonth"
    },
    capacityByCountryFilters: {
      datepicker: datepicker({}, "dateTime", { startOf: "month" })
    }
  };
};

const state: () => DashboardStatisticWidget = initialState;

const mutations: MutationTree<DashboardStatisticWidget> = {
  [SET_DASHBOARD_EFFICIENCY_RATE_WIDGET] (state, efficiencyRateWidget: DashboardStatisticWidgetEfficiencyRate): void {
    state.efficiencyRateWidget = efficiencyRateWidget;
  },

  [SET_DASHBOARD_LEAD_STATISTIC_WIDGET] (state, leadStatisticWidget: DashboardStatisticWidgetLeadStatistic): void {
    state.leadStatisticWidget = leadStatisticWidget;
  },

  [SET_DASHBOARD_SUMMARY_CLIENTS_WIDGET] (state, dashboardSummaryClientsWidget: DashboardStatisticWidgetSummaryClientsWidget): void {
    state.dashboardSummaryClientsWidget = dashboardSummaryClientsWidget;
  },

  [SET_DASHBOARD_INCIDENCE_WIDGET] (state, incidenceWidget: DashboardStatisticWidgetIncidenceWidget): void {
    state.incidenceWidget = incidenceWidget;
  },

  [SET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET] (state, efficiencyKpiBonusWidget: DashboardStatisticWidgetEfficiencyKpiBonus): void {
    state.efficiencyKpiBonusWidget = efficiencyKpiBonusWidget;
  },

  [SET_DASHBOARD_CAPACITY_WIDGET] (state, capacityRequestWidget: DashboardCapacityRequestWidget): void {
    state.capacityRequestWidget = capacityRequestWidget;
  },

  [SET_DASHBOARD_PAYOUT_WIDGET] (state, payoutRequestWidget: DashboardPayoutRequestWidget): void {
    state.payoutRequestWidget = payoutRequestWidget;
  },

  [SET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET] (state, capacityByCountryWidget: DashboardCapacityByCountryWidget): void {
    state.capacityByCountryWidget = capacityByCountryWidget;
  },

  [UPDATE_KPI_BONUS_FILTERS] (state, kpiBonusFilters): void {
    state.kpiBonusFilters = { ...state.kpiBonusFilters, ...kpiBonusFilters };
  },

  [UPDATE_CAPACITY_BY_COUNTRY_FILTERS] (state, capacityByCountryFilters): void {
    state.capacityByCountryFilters = { ...state.capacityByCountryFilters, ...capacityByCountryFilters };
  },

  [SET_EMPTY] (state, target?: DashboardStatisticWidget) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DashboardStatisticWidget, RootState> = {
  async [GET_DASHBOARD_STATISTICS_WIDGET] ({ dispatch }): Promise<void> {
    await Promise.all([
      dispatch(`dashboardLaunchActivityWidget/${ GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET }`),
      dispatch(GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET),
      dispatch(GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET),
      dispatch(GET_DASHBOARD_EFFICIENCY_RATE_WIDGET),
      dispatch(GET_DASHBOARD_LEAD_STATISTIC_WIDGET),
      dispatch(GET_DASHBOARD_INCIDENCE_WIDGET),
      dispatch(GET_DASHBOARD_CAPACITY_WIDGET),
      dispatch(GET_DASHBOARD_PAYOUT_WIDGET)
    ]);
  },

  async [GET_DASHBOARD_EFFICIENCY_RATE_WIDGET] ({ commit }): Promise<void> {
    const data = await DashboardStatisticWidgetService.getEfficiencyRateWidget();
    commit(SET_DASHBOARD_EFFICIENCY_RATE_WIDGET, data);
  },

  async [GET_DASHBOARD_LEAD_STATISTIC_WIDGET] ({ commit, rootState: { verticals: { currentVertical: vertical } } }): Promise<void> {
    const data = await DashboardStatisticWidgetService.getLeadStatisticWidget({ vertical });
    commit(SET_DASHBOARD_LEAD_STATISTIC_WIDGET, data);
  },

  async [GET_DASHBOARD_INCIDENCE_WIDGET] ({ commit }): Promise<void> {
    const data = await DashboardStatisticWidgetService.getIncidenceWidget(20);
    commit(SET_DASHBOARD_INCIDENCE_WIDGET, data);
  },

  async [GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET] ({ commit }): Promise<void> {
    const lastThirtyDays = datepicker({ amount: 1, unit: "month" });
    const previousThirtyDate = datepicker({ amount: 2, unit: "month", amountEnd: 1, unitEnd: "month" });

    const [thirtyDays, stillThirtyDate] = await Promise.all([
      DashboardStatisticWidgetService.getDashboardSummaryClientsWidget(lastThirtyDays, true),
      DashboardStatisticWidgetService.getDashboardSummaryClientsWidget(previousThirtyDate, true)
    ]);

    const {
      thirtyDays: {
        totalWebmasters, totalAdvertisers, totalOffers,
        activeWebmasters: thirtyDaysActiveWebmasters,
        activeAdvertisers: thirtyDaysActiveAdvertisers,
        activeOffers: thirtyDaysActiveOffers
      },
      stillThirtyDate: {
        activeWebmasters: previousThirtyDateActiveWebmasters,
        activeAdvertisers: previousThirtyDateActiveAdvertisers,
        activeOffers: previousThirtyDateActiveOffers
      }
    } = { thirtyDays, stillThirtyDate };

    commit(SET_DASHBOARD_SUMMARY_CLIENTS_WIDGET, {
      totalWebmasters, totalAdvertisers, totalOffers,
      thirtyDaysActiveWebmasters, thirtyDaysActiveAdvertisers,
      previousThirtyDateActiveWebmasters, previousThirtyDateActiveAdvertisers,
      previousThirtyDateActiveOffers, thirtyDaysActiveOffers
    });
  },

  async [GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET] ({ state: { kpiBonusFilters: { bonusPeriod } }, commit }): Promise<void> {
    const currentMonth = datepicker({}, "dateTime", { startOf: "month" });
    const previousMonth = datepicker({ amount: 1, unit: "month", amountEnd: 1, unitEnd: "month" }, "dateTime", { startOf: "month", endOf: "month" });

    const data = await DashboardStatisticWidgetService
      .getEfficiencyKpiBonusWidget(bonusPeriod === "currentMonth" ? currentMonth : previousMonth);

    commit(SET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET, data);
  },

  async [GET_DASHBOARD_CAPACITY_WIDGET] ({ commit }): Promise<void> {
    const lastThirtyDays = datepicker({ amount: 1, unit: "month" });

    const data = await DashboardStatisticWidgetService.getCapacityRequestWidget(lastThirtyDays);
    commit(SET_DASHBOARD_CAPACITY_WIDGET, data);
  },

  async [GET_DASHBOARD_PAYOUT_WIDGET] ({ commit }): Promise<void> {
    const lastThirtyDays = datepicker({ amount: 1, unit: "month" });

    const data = await DashboardStatisticWidgetService.getPayoutRequestWidget(lastThirtyDays);
    commit(SET_DASHBOARD_PAYOUT_WIDGET, data);
  },

  async [GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET] ({ state, commit }): Promise<void> {
    const { datepicker } = state.capacityByCountryFilters;

    const data = await DashboardStatisticWidgetService.getCapacityByCountryWidget(datepicker);
    commit(SET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET, data);
  },

  [UPDATE_KPI_BONUS_FILTERS] ({ commit, dispatch }, filters): void {
    commit(UPDATE_KPI_BONUS_FILTERS, filters);
    dispatch(GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET);
  },

  [UPDATE_CAPACITY_BY_COUNTRY_FILTERS] ({ commit, dispatch }, filters): void {
    commit(UPDATE_CAPACITY_BY_COUNTRY_FILTERS, filters);
    dispatch(GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`dashboardLaunchActivityWidget/${ SET_EMPTY }`);
    commit(`dashboardStatisticChartWidget/${ SET_EMPTY }`);
  }
};

export const dashboardStatisticWidget: Module<DashboardStatisticWidget, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    dashboardLaunchActivityWidget,
    dashboardStatisticChartWidget
  }
};
