import { DiscountsService } from "@core/services/webmaster/discounts/discountsService";
import { SET_DISCOUNTS } from "@core/store/mutation-constants";
import { GET_DISCOUNTS } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { DiscountsState } from "@core/store/types/webmaster/discounts";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): DiscountsState => {
  return {
    discounts: null
  };
};

export const state: () => DiscountsState = initialState;

const mutations: MutationTree<DiscountsState> = {
  [SET_DISCOUNTS] (state, discounts) {
    state.discounts = discounts;
  }
};

const actions: ActionTree<DiscountsState, RootState> = {
  [GET_DISCOUNTS] ({ commit }) {
    const discounts = DiscountsService.getDiscounts();
    commit(SET_DISCOUNTS, discounts);
  }
};

export const discounts: Module<DiscountsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};