<script>
  import { hasPermissionsStrict } from "@core/mixins/permissions";
  import store from "../../store";
  
  // :permissions
  // "permission" - строка с пермишенном
  // ["permission1", "permission2"] - список пермишеннов
  // { "group1": ["permission1", "permission2"], "group2": ["permission2", "permission3"] } - группировка пермишеннов
  
  // :validators
  // { "permission": Function: Boolean }


  /**
   * @vue-prop {Number} initialCounter - Initial counter's value
   * @vue-prop {Number} [step=1] - Step
   * @vue-data {Number} counter - Current counter's value
   * @vue-computed {String} message
   * @vue-event {Number} increment - Emit counter's value after increment
   * @vue-event {Number} decrement - Emit counter's value after decrement
   */
  export default {
    name: "Permissions",
    render: function (createElement, context) {
      let permittedArray = [];
      
      const { props, slots } = context;
      const resolvedSlots = slots();

      const isPermissionsArray = Array.isArray(props.permissions);
      const isPermissionsString = typeof props.permissions === "string";
      const isPermissionsObject = typeof props.permissions === "object" && !isPermissionsArray;

      const permissions = store?.getters?.permissions;
      const passedPermissions = isPermissionsString ? [props.permissions] : props.permissions;

      const validators = props.validators ?? {};

      // Массив прошедших проверку валидаторов
      const validated = Object
        .entries(validators)
        .reduce(
          (array, [validated, validator]) => {
            if (validator()) {
              array.push(validated);
            }

            return array;
          }, []
        );
  
      // TODO: подумать над неймингом и описать
      function permitted (array) {
        return array.filter(item => validated.includes(item) ? true : !props.validators?.hasOwnProperty(item));
      }
  
      // TODO: написать комментарий что тут происходит
      if (isPermissionsObject) {
        permittedArray = Object
          .entries(props.permissions)
          .reduce(
            (array, [group, groupPermissions]) => {
              if (hasPermissionsStrict(groupPermissions, permissions)) {
                array.push(group);
              }

              return array;
            }, permittedArray
          );
      } else {
        // TODO: написать комментарий что тут происходит
        permittedArray = passedPermissions.filter(el => hasPermissionsStrict([el], permissions));
      }
  
      // TODO: написать комментарий что тут происходит
      const slotsNames = isPermissionsObject ? Object.keys(props.permissions) : passedPermissions;

      // TODO: написать комментарий что тут происходит
      if (slotsNames.length === 0 && hasPermissionsStrict("", permissions)) {
        return resolvedSlots.default;
      } else {
        // TODO: написать комментарий что тут происходит
        if (slotsNames.length !== 0 && slotsNames.some(item => permitted(permittedArray).includes(item))) {
          permittedArray.push("default");
        }
        
        return permittedArray.map(item => resolvedSlots[item]);
      }
    },

    functional: true,

    props: {
      permissions: {
        type: [Array, String, Object],
        required: true
      },
      
      validators: {
        type: Object,
        default: () => {}
      }
    }
  };
</script>
