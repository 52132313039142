import addOfferWebmasterParameters from "@core/services/admin/offer/edit/graphql/addOfferWebmasterParameters.graphql";
import editOfferWebmasterParameters from "@core/services/admin/offer/edit/graphql/editOfferWebmasterParameters.graphql";
import offer from "@core/services/admin/offer/edit/graphql/getMaximalAllowedWebmastersReward.graphql";
import offerExcludeMultipleWebmasters from "@core/services/admin/offer/edit/graphql/offerExcludeMultipleWebmasters.graphql";
import offerIncludeMultipleWebmasters from "@core/services/admin/offer/edit/graphql/offerIncludeMultipleWebmasters.graphql";
import removeOfferWebmasterParameters from "@core/services/admin/offer/edit/graphql/removeOfferWebmasterParameters.graphql";
import webmasterRates from "@core/services/admin/offer/edit/graphql/webmasters.graphql";
import webmastersWithIndividualRate from "@core/services/admin/offer/edit/graphql/webmastersWithIndividualRate.graphql";
import getMaximalAllowedInternalWebmastersReward from "@core/services/admin/offer/edit/graphql/getMaximalAllowedInternalWebmastersReward.graphql";
import Service from "@core/services/common/Service";
import {
  AddOfferWebmasterParameters,
  CreateIndividualRate,
  PartialOfferWebmastersState,
  RemoveWebmasterParametersPayload,
  RequestAvailableWebmasters,
  RequestIndividualRate,
  ResponseIndividualRate,
  ResponseOfferExternalWebmaster,
  ResponseOfferWebmaster,
  Webmaster
} from "@core/store/types/admin/offer/OfferWebmasters.d";
import { AxiosPromise, AxiosResponse } from "axios";
import { OfferExternalWebmastersState } from "@core/store/types/admin/offer/OfferExternalWebmastersState";
import { useOffers } from "../../../../../../stores/admin/offers/offersStore";
import webmasterRatesGenerator from "@core/services/admin/offer/edit/graphql/webmasterRates.generator";

export class OfferEditWebmastersServices extends Service {
  
  public static async getOfferWebmasters (
    filters: PartialOfferWebmastersState["filters"],
    offerId: string,
    limit: number,
    offset = 0
  ): Promise<AxiosResponse<any>> {
    try {
      const { isShowCommissions } = useOffers();

      const response = await this.api.get("", {
        params: {
          query: isShowCommissions ? webmastersWithIndividualRate : webmasterRates,
          variables: {
            limit,
            offset,
            filters,
            offerId
          }
        }
      });
      const webmasters = response.data.offer.webmasters;
      
      webmasters.items = webmasters.items.map((item: ResponseOfferWebmaster) => {
        if (item.individualRate) {
          return {
            ...item,
            individualRate: {
              id: item.individualRate?.id,
              moneyReward: item.individualRate?.moneyReward,
              holdTime: item.individualRate?.hold?.holdMinutes || null
            }
          };
        }
        return item;
      });
      
      return response;
    } catch (e) {
      throw e;
    }
  }

  public static async getOfferExternalWebmasters (
    filters: OfferExternalWebmastersState["filters"],
    offerId: string,
    limit: number,
    offset = 0
  ): Promise<AxiosResponse> {
    try {
      const response = await this.api.get("", {
        params: webmasterRatesGenerator(limit, offset, offerId, filters)
      });
      const webmasters = response.data.webmasterRates;

      webmasters.items = webmasters.items.map((item: ResponseOfferExternalWebmaster) => {
        return {
          ...item,
          individualRate: {
            id: item.id,
            moneyReward: item.moneyReward,
            holdTime: item.hold?.holdMinutes || null
          }
        };
      });

      return response;
    } catch (e) {
      throw e;
    }
  }

  public static offerExcludeMultipleWebmasters (
    offerId: string,
    webmasters: string[]
  ): AxiosPromise {
    try {
      return this.api.post("", {
        query: offerExcludeMultipleWebmasters,
        variables: {
          offerId,
          input: { ...webmasters }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static offerIncludeMultipleWebmasters (
    offerId: string,
    webmasters: RequestAvailableWebmasters[]
  ): AxiosPromise {
    try {
      return this.api.post("", {
        query: offerIncludeMultipleWebmasters,
        variables: {
          offerId,
          input: { webmasters }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async addOfferWebmasterParameters (
    offerId: string,
    parameters: CreateIndividualRate[]
  ): Promise<AxiosPromise<AddOfferWebmasterParameters>> {

    try {
      // const { data: { addOfferWebmasterParameters } } =
      return await this.api.post("", {
        query: addOfferWebmasterParameters,
        variables: {
          offerId,
          input: { parameters }
        }
      });
      // const {
      //   individualRate,
      //   ...rate
      // } = rates;
      // return {
      //   ...individualRate,
      //   ...rate,
      //   holdTime: individualRate?.hold?.holdMinutes
      // };
      // return addOfferWebmasterParameters;
    } catch (e) {
      throw e;
    }
  }
  
  public static async editOfferWebmasterParameters (
    offerId: string,
    params: RequestIndividualRate,
    webmaster: Webmaster
  ): Promise<Partial<ResponseIndividualRate>> {
    const webmasterId = webmaster?.id;

    try {
      const {
        data: {
          editOfferWebmasterParameters: {
            individualRate,
            ...rates
          }
        }
      } =
        await this.api.post("", {
          query: editOfferWebmasterParameters,
          variables: {
            offerId,
            webmasterId,
            input: { ...params }
          }
        });
      return {
        ...individualRate,
        ...rates
      };
    } catch (e) {
      throw e;
    }
  }
  
  public static removeOfferWebmasterParameters (offerId: string, payload: RemoveWebmasterParametersPayload): AxiosPromise {
    try {
      return this.api.post("", {
        query: removeOfferWebmasterParameters,
        variables: {
          offerId,
          input: { ...payload }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getMaximalAllowedWebmastersReward (
    offerId: string,
    webmasterId: string,
    externalWebmasterId: string
  ): Promise<AxiosPromise> {
    try {
      return await this.api.post("", {
        query: offer,
        variables: {
          offerId,
          webmasterId,
          externalWebmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getMaximalAllowedInternalWebmastersReward (
    offerId: string,
    webmastersIds: string[],
    limit: number,
    offset = 0
  ): AxiosPromise {
    try {
      return this.api.post("", {
        query: getMaximalAllowedInternalWebmastersReward,
        variables: {
          offerId,
          webmastersIds,
          limit,
          offset
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
