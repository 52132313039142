import { AdminsListService } from "@core/services/admin/lists/AdminsListService";
import { GET_ADMINS_LIST, GET_INITIAL_ADMINS, UPDATE_ADMINS_LIST } from "@core/store/action-constants";
import { EMPTY_ADMINS_LIST, UPDATE_ADMINS_FILTERS, UPDATE_ADMINS_LIST_PAGINATION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { AdminsListResponse, PartialAdminsListState } from "@core/store/types/admin/lists/AdminsListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialAdminsListState => {
  return {
    data: {},
    filters: {
      id: null,
      loginOrIntId: null,
      excludedId: null
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => PartialAdminsListState = initialState;

const getters: GetterTree<PartialAdminsListState, RootState> = {};

const mutations: MutationTree<PartialAdminsListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_ADMINS_LIST] (state, admins: AdminsListResponse["admins"]) {
    if (state.pagination.page !== 1 && state.data?.items && admins) {
      state.data.items = Array.from(new Set([...state.data.items, ...admins.items as []]));
    } else {
      state.data = admins;
    }
  },

  [UPDATE_ADMINS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_ADMINS_FILTERS] (state, filters: PartialAdminsListState["filters"]) {
    if (filters?.loginOrIntId !== state.filters?.loginOrIntId) {
      state.pagination.page = 1;
    }
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_ADMINS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialAdminsListState, RootState> = {
  async [GET_ADMINS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    const { data: { admins } } =
      await AdminsListService.getAdminsList({
        loginOrIntId: state.filters?.loginOrIntId,
        hasActiveWebmasters: state.filters?.hasActiveWebmasters,
        hasActiveAdvertisers: state.filters?.hasActiveAdvertisers
      }, limit, offset);

    commit(UPDATE_ADMINS_LIST, admins);
    return admins;
  },

  async [GET_INITIAL_ADMINS] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const { data: { admins, initialAdmins } } = await AdminsListService.getAdminsList(
      {
        excludedId: ids,
        hasActiveWebmasters: state.filters?.hasActiveWebmasters,
        hasActiveAdvertisers: state.filters?.hasActiveAdvertisers
      },
      state.pagination.perPage,
      0,
      { id: ids });
    admins.items = [...initialAdmins.items, ...admins.items as []];

    commit(UPDATE_ADMINS_LIST, admins);
    return initialAdmins?.items?.length ? initialAdmins.items.map(user => user.id) : null;
  }
};

export const adminsList: Module<PartialAdminsListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
