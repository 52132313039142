import { Advertiser, Contact } from "@core/store/types/common/User";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

export interface UserFormInterface {
  login: string,
  geo: string;
  contact?: Contact,
  sizeLeads: number;
  comment: string;
  timeZone: string,
  preferredCurrency: string;
}

export class UserForm implements UserFormInterface {
  public login!: string;
  public geo!: string;
  public email?: string;
  public telegram?: string;
  public skype?: string;
  public whatsApp?: number;
  public viber?: number;
  public sizeLeads!: number;
  public comment!: string;
  public timeZone!: string;
  public verticals!: string[];
  public preferredCurrency!: string;
  public systemVertical!: VerticalsEnum;

  constructor (user?: Advertiser) {
    // const { login, geo, sizeLeads, contact, comment, timeZone: { alias: timeZone }, preferredCurrency } = this.user;
    if (user) {
      this.login = user.login;
      this.geo = user.geo;
      this.sizeLeads = user.sizeLeads;
      this.telegram = user.contact?.telegram;
      this.email = user.contact?.email;
      this.skype = user.contact?.skype;
      this.whatsApp = user.contact?.whatsApp;
      this.viber = user.contact?.viber;
      this.comment = user.comment;
      this.timeZone = user.timeZone?.alias;
      this.verticals = user.verticals?.map(({ id }) => id);
      this.preferredCurrency = user.preferredCurrency;
      this.systemVertical = user.systemVertical;
    }
  }
  
  isForm () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { login, email, ...input } = this;
    return input;
  }
}