import {
  PartialTrafficAnalysisState, ResponseData, TrafficFilters
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysis";
import Service from "@core/services/common/Service";
import trafficByPeriods from "@core/services/admin/statistic/trafficAnalysis/graphql/trafficByPeriods.graphql";
import trafficTopWebmasters from "@core/services/admin/statistic/trafficAnalysis/graphql/trafficTopWebmasters.graphql";
import traffic from "@core/services/admin/statistic/trafficAnalysis/graphql/traffic.graphql";
import {
  TrafficAnalysisChangeApproveHistoryFilters
} from "@core/store/types/admin/statistic/trafficAnalysis/trafficAnalysisChangeApproveHistoryModal";

export class TrafficAnalysisService extends Service {
  public static async getTraffic (
    firstPeriod: PartialTrafficAnalysisState["filters"]["firstPeriod"],
    secondPeriod: PartialTrafficAnalysisState["filters"]["secondPeriod"],
    filters: PartialTrafficAnalysisState["filters"]
  ): Promise<ResponseData> {
    try {
      const query = secondPeriod ? trafficByPeriods : traffic;
      const key = secondPeriod ? "trafficByPeriods" : "traffic";
      const variables = secondPeriod
        ? { firstPeriod, secondPeriod, filters }
        : { filters: { ...filters, ...firstPeriod } };

      const { data: { [key]: data } } = await this.api.get("", {
        params: {
          query,
          variables
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
  public static async getTrafficAnalysisTopWebmasters (
    excludedWebmasterId: string,
    secondPeriod: TrafficFilters["secondPeriod"],
    firstPeriod: TrafficFilters["firstPeriod"],
    offerId: string,
    offset: TrafficAnalysisChangeApproveHistoryFilters["offset"],
    limit: TrafficAnalysisChangeApproveHistoryFilters["limit"]
  ): Promise<ResponseData> {
    try {
      const { data: { trafficTopWebmasters: data } } = await this.api.get("", {
        params: {
          query: trafficTopWebmasters,
          variables: {
            excludedWebmasterId,
            secondPeriod,
            firstPeriod,
            offerId,
            offset,
            limit
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}
