import i18n from "@core/plugins/i18n";
import { LanguagesListService } from "@core/services/admin/lists/LanguagesListService";
import { GET_LANGUAGES_INTERNATIONALIZED, GET_LANGUAGES_LIST } from "@core/store/action-constants";
import { EMPTY_LANGUAGES_LIST, SET_LANGUAGES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { LanguagesListItem, PartialLanguagesListState } from "@core/store/types/admin/lists/LanguagesListState";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialLanguagesListState => {
  return {
    languages: {
      items: [],
      count: null
    },
    pagination: {
      page: 1,
      perPage: 177
    }
  };
};

const state: () => PartialLanguagesListState = initialState;

const getters: GetterTree<PartialLanguagesListState, RootState> = {
  [GET_LANGUAGES_INTERNATIONALIZED]: state => {
    return {
      count: state.languages?.count,
      items: state.languages?.items.map((lang: LanguagesListItem) => {
        return {
          alias: lang.alias,
          name: lang.alias ? i18n.t(`common.languages.${ lang.alias }`) : null
        };
      })
    };
  }
};

const mutations: MutationTree<PartialLanguagesListState> = {
  [SET_LANGUAGES_LIST] (state, payload) {
    state.languages = { ...state.languages, ...payload };
  },

  [EMPTY_LANGUAGES_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialLanguagesListState, RootState> = {
  async [GET_LANGUAGES_LIST] ({ state, commit }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { data: { languages } } =
        await LanguagesListService.getLanguagesList(
          limit,
          offset
        );

    const languagesList: PaginationOutput<Array<LanguagesListItem>> = languages;

    //TODO: Как на бЭке починят поиск по name, переписать на общий class

    // if (languages.count && languages.count > limit) {
    //   let page = 1;
    //   let responseCount = 0;
    //   do {
    //     const offset = page * limit;
    //     const { data: { languages: { items } } } =
    //               await LanguagesListService.getLanguagesList(
    //                 limit,
    //                 offset
    //               );
    //
    //     if (languagesList?.items && items) {
    //       languagesList.items = [...languagesList.items, ...items];
    //       responseCount = items.length;
    //     }
    //     page++;
    //   } while (responseCount === limit);
    // }

    commit(SET_LANGUAGES_LIST, languagesList);
  }
};

export const languagesList: Module<PartialLanguagesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
