import { OfferEditWebmastersServices } from "@core/services/webmaster/offer/OfferRatesWebmastersService";
import {
  ADD_OFFER_WEBMASTERS_AVAILABLE,
  GET_AVAILABLE_WEBMASTERS_BY_ID, GET_OFFER_ID,
  GET_SELECTED_WEBMASTERS, SET_EMPTY,
  UPDATE_SELECTED_WEBMASTERS,
  UPDATE_SELECTED_WEBMASTERS_RATE
} from "@core/store/action-constants";
import { offerEditWebmastersAvailableList } from "@core/store/modules/admin/offer/lists/offerEditWebmastersAvailableList";
import { UPDATE_MODAL_ACTIVE, UPDATE_SELECTED_WEBMASTERS_IDS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { IndividualRate } from "@core/store/types/webmaster/offer/OfferRatesWebmastersState.d";
import { OfferWebmastersModalState } from "@core/store/types/webmaster/offer/OfferRatesWebmastersModalState.d";
import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): OfferWebmastersModalState => {
  return {
    selectWebmastersIds: [],
    selectedWebmasters: {},
    isWebmastersModalActive: false
  };
};

const state: () => OfferWebmastersModalState = initialState;

const getters: GetterTree<OfferWebmastersModalState, RootState> = {
  [GET_SELECTED_WEBMASTERS]: (state: OfferWebmastersModalState) => Object.values(state.selectedWebmasters)
};

const mutations: MutationTree<OfferWebmastersModalState> = {
  [SET_EMPTY]: (state: OfferWebmastersModalState): OfferWebmastersModalState => Object.assign(state, initialState()),
  
  [UPDATE_MODAL_ACTIVE] (state: OfferWebmastersModalState, payload: boolean): void {
    state.isWebmastersModalActive = payload;
  },
  
  [UPDATE_SELECTED_WEBMASTERS_RATE] (state: OfferWebmastersModalState, payload: IndividualRate): void {
    const { id, rate } = payload;
    Vue.set(state.selectedWebmasters[id], "rate", rate);
  },
  
  [UPDATE_SELECTED_WEBMASTERS] (state: OfferWebmastersModalState, webmasters: OfferWebmastersModalState["selectedWebmasters"]): void {
    state.selectedWebmasters = webmasters;
  },
  
  [UPDATE_SELECTED_WEBMASTERS_IDS] (state: OfferWebmastersModalState, ids: string[]): void {
    state.selectWebmastersIds = ids;
  }
};

const actions: ActionTree<OfferWebmastersModalState, RootState> = {
  async [ADD_OFFER_WEBMASTERS_AVAILABLE] ({ state, commit, rootGetters }): Promise<void> {
    const offerId = rootGetters[`webmaster/offer/${ GET_OFFER_ID }`];
    try {
      if (offerId) {
        const webmasters = Object.values(state.selectedWebmasters)
          .map(({ id, rate }: IndividualRate) => ({ id, rate }));
        const webmasterIds = Object.values(state.selectedWebmasters)
          .map(({ id }: IndividualRate) => id);
        const addedSubWebmasters = await OfferEditWebmastersServices
          .includeSubWebmastersToOffer(offerId, webmasters, webmasterIds.length, { id: webmasterIds });
        commit("webmaster/offer/rates/LOCAL_ADD", { items: addedSubWebmasters, target: "webmasters" }, { root: true });
      }
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_SELECTED_WEBMASTERS_RATE] ({ commit }, payload: IndividualRate): void {
    commit(UPDATE_SELECTED_WEBMASTERS_RATE, payload);
  },
  
  [UPDATE_SELECTED_WEBMASTERS] ({ getters, state, commit }, ids: string[]): void {
    commit(UPDATE_SELECTED_WEBMASTERS_IDS, ids);
    const newWebmasters: { [key: string]: IndividualRate } = {};
    for (const id of state.selectWebmastersIds) {
      if (state.selectedWebmasters[id]) {
        newWebmasters[id] = state.selectedWebmasters[id];
      } else {
        newWebmasters[id] =
          JSON.parse(
            JSON.stringify(
              getters[`offerEditWebmastersAvailableList/${ GET_AVAILABLE_WEBMASTERS_BY_ID }`]([id])[0]
            )
          );
      }
    }
    commit(UPDATE_SELECTED_WEBMASTERS, newWebmasters);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    if (payload) {
      commit("SET_EMPTY");
      commit("offerEditWebmastersAvailableList/SET_EMPTY");
    }
    commit(UPDATE_MODAL_ACTIVE, payload);
  },
  
  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
    commit(`offerEditWebmastersAvailableList/${ SET_EMPTY }`);
  }
};

export const offerEditWebmastersModal: Module<OfferWebmastersModalState, RootState> = {
  namespaced: true,
  modules: {
    offerEditWebmastersAvailableList
  },
  getters,
  state,
  mutations,
  actions
};
