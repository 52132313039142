<!--

Пропы:

isEdit: флаг для редактирования (по умолчанию false -> компонент используется для создания админа)

-->

<template>
    <div class="columns is-multiline">
        <div
            v-if="!isEdit"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.login')">
                <LInput
                    v-if="profileLoaded"
                    v-model.trim="_user.login"
                    name="login"
                    required
                    pattern="[\d\w+]{2,}"
                    :validation-message="$t(`common.auth.signUp.loginHelp`)"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.login')">
                </LInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.name')">
                <LInput
                    v-if="profileLoaded"
                    v-model.trim="_user.name"
                    name="name"
                    autocomplete="name"
                    required
                    :pattern="fullNameValidator"
                    :validation-message="$t(`common.auth.signUp.nameHelp`)"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.name')">
                </LInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.telegram')">
                <TelegramInput
                    v-if="profileLoaded"
                    v-model="_user.telegram"
                    required>
                </TelegramInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.email')">
                <LInput
                    v-if="profileLoaded"
                    v-model.trim="_user.email"
                    type="email"
                    name="email"
                    autocomplete="email"
                    :pattern="emailValidator"
                    required
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.email')">
                </LInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="isCountries"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.country')">
                <CountriesSelect
                    v-if="profileLoaded"
                    v-model="_user.countries"
                    multiple
                    hide-null
                    :placeholder="$t(`webmaster.offers.filters.labels.country`)">
                </CountriesSelect>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="isWorkedYear"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.workedYear')">
                <LInput
                    v-if="profileLoaded"
                    v-model.number="_user.workedYear"
                    type="number"
                    min="1904"
                    :max="new Date().getFullYear()"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.workedYear')">
                </LInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="isDescription"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.description')">
                <LInput
                    v-if="profileLoaded"
                    v-model="_user.description"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.description')">
                </LInput>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="isCurrency"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.preferredCurrency')">
                <Select
                    v-if="profileLoaded"
                    v-model="_user.preferredCurrency"
                    :get-data="getCurrency"
                    :clearable="false"
                    required>
                </Select>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div class="column is-12-mobile is-6-tablet ">
            <b-field :label="$t('webmaster.profile.labels.timezone')">
                <b-select
                    v-if="profileLoaded"
                    v-model="_user.timeZone"
                    required
                    expanded>
                    <optgroup
                        :key="continent"
                        v-for="(zones, continent) in timeZones"
                        :label="continent">
                        <option
                            :key="i"
                            v-for="(zone, i) in zones"
                            :value="zone.alias">
                            {{ zone.alias }} {{ zone.offset }}
                        </option>
                    </optgroup>
                </b-select>
                <b-skeleton
                    v-else
                    width="100%"
                    height="46px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="isVertical"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.preferredVertical')">
                <VerticalsSelect
                    v-if="profileLoaded"
                    v-model="_user.preferredVertical"
                    is-local
                    :clearable="false"
                    :searchable="false"
                    required>
                </VerticalsSelect>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="inHouse"
            class="column is-6">
            <b-field :label="$t('admin.users.administrators.modal.addAdmin.body.inHouse')">
                <Select
                    v-if="profileLoaded"
                    v-model="_user.inHouse"
                    :get-data="getAdminType"
                    :placeholder="$t('admin.users.administrators.modal.addAdmin.body.inHouse')"
                    :searchable="false"
                    field="label"
                    prop="value">
                </Select>
                <b-skeleton
                    v-else
                    width="100%"
                    height="35px"
                    animated>
                </b-skeleton>
            </b-field>
        </div>

        <div
            v-if="!isEdit && withPasswordEdit"
            class="column is-6">
            <EditUserPasswordField
                v-if="profileLoaded"
                v-model.trim="_user.password"
                :label="$t('admin.users.administrators.modal.addAdmin.body.password')"
                :placeholder="$t('admin.users.administrators.modal.addAdmin.body.password')"
                :message="$t('common.validation.password')">
            </EditUserPasswordField>
            <b-skeleton
                v-else
                width="100%"
                height="35px"
                animated>
            </b-skeleton>
        </div>
    </div>
</template>

<script>
  import { EMAIL_VALIDATOR, FULL_NAME_VALIDATOR } from "@core/validators";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { mapState } from "vuex";
  import TelegramInput from "@/components/Common/Select/TelegramInput.vue";
  import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";

  export default {
    name: "AdminProfile",
    components: {
      VerticalsSelect,
      TelegramInput,
      EditUserPasswordField,
      CountriesSelect,
      Select
    },
    props: {
      user: {
        type: Object,
        default: null
      },

      isEdit: {
        type: Boolean,
        default: false
      },

      withPasswordEdit: {
        type: Boolean,
        default: true
      },

      isCurrency: {
        type: Boolean,
        default: false
      },

      isCountries: {
        type: Boolean,
        default: false
      },

      isWorkedYear: {
        type: Boolean,
        default: false
      },

      isDescription: {
        type: Boolean,
        default: false
      },

      inHouse: {
        type: Boolean,
        default: false
      },

      isVertical: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState({
        currencyList: ({ auth }) => auth?.availableCurrencies,
        timeZones: ({ dictionaries }) => dictionaries.timeZones
      }),

      emailValidator () {
        return EMAIL_VALIDATOR;
      },

      fullNameValidator () {
        return FULL_NAME_VALIDATOR;
      },

      profileLoaded () {
        return !!this.user;
      },

      _user: {
        get () {
          return this.user;
        },

        set (value) {
          this.$emit("update:user", value);
        }
      }
    },

    methods: {
      getCurrency () {
        const items = this.currencyList;
        return {
          items,
          count: items.length
        };
      },

      getAdminType () {
        const items = [
          {
            value: true,
            label: this.$t("common.entity.inHouse.true")
          },
          {
            value: false,
            label: this.$t("common.entity.inHouse.false")
          }
        ];
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
