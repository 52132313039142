<!--

Пропы:

isEdit: флаг редактирования профиля. По умолчанию false (создание профиля). Отключает инпуты, если true

-->

<template>
    <div>
        <div class="columns is-multiline">
            <div class="column is-6">
                <LTooltip
                    :active="profileLoaded"
                    :label="$t(`advertiser.profile.labels.loginMessage`)"
                    class="is-block"
                    position="is-bottom"
                    type="is-danger">
                    <b-field :label="$t(`advertiser.profile.labels.login`)">
                        <LInput
                            v-if="profileLoaded"
                            v-model="_user.login"
                            disabled>
                        </LInput>
                        <b-skeleton
                            v-else
                            width="100%"
                            height="46px"
                            animated>
                        </b-skeleton>
                    </b-field>
                </LTooltip>
            </div>
            <div class="column is-6">
                <LTooltip
                    :active="!isSuperEdit && profileLoaded"
                    :label="$t(`advertiser.profile.labels.emailMessage`)"
                    class="is-block"
                    :max-width="250"
                    position="is-bottom"
                    type="is-danger">
                    <b-field :label="$t(`advertiser.profile.labels.email`)">
                        <LInput
                            v-if="profileLoaded"
                            v-model="_user.email"
                            :placeholder="$t(`advertiser.profile.labels.email`)"
                            :pattern="emailValidator"
                            type="email"
                            required
                            :disabled="!isSuperEdit">
                        </LInput>
                        <b-skeleton
                            v-else
                            width="100%"
                            height="46px"
                            animated>
                        </b-skeleton>
                    </b-field>
                </LTooltip>
            </div>
        </div>
        <ContactsBox
            :telegram.sync="_user.telegram"
            :whats-app.sync="_user.whatsApp"
            :viber.sync="_user.viber"
            :skype.sync="_user.skype"
            :disabled="isEdit">
            <template
                v-if="!profileLoaded"
                #skeleton>
                <b-skeleton
                    width="100%"
                    height="46px"
                    animated>
                </b-skeleton>
            </template>
        </ContactsBox>
        <div class="columns is-multiline">
            <div class="column is-6">
                <b-field :label="$t(`advertiser.profile.labels.geo`)">
                    <LInput
                        v-if="profileLoaded"
                        v-model="_user.geo"
                        :disabled="isEdit"
                        :placeholder="$t(`advertiser.profile.labels.geo`)">
                    </LInput>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </b-field>
            </div>

            <div class="column is-6">
                <b-field :label="$t(`advertiser.profile.labels.leads`)">
                    <LInput
                        v-if="profileLoaded"
                        v-model.number="_user.sizeLeads"
                        :disabled="isEdit"
                        :placeholder="$t(`advertiser.profile.labels.leads`)"
                        min="0"
                        type="number">
                    </LInput>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </b-field>
            </div>
            <div
                v-if="role === 'advertiser'"
                class="column is-6">
                <b-field :label="$t('advertiser.profile.labels.preferredCurrency')">
                    <Select
                        v-if="profileLoaded"
                        v-model="_user.preferredCurrency"
                        :searchable="false"
                        :clearable="false"
                        :disabled="isEdit"
                        class="white"
                        :get-data-vuex="getCurrencies">
                        <template #selected-option="{ option }">
                            {{ $t(`common.currency.${ option.name }`) }}
                        </template>
                    </Select>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </b-field>
            </div>
            <div class="column is-6">
                <b-field :label="$t('advertiser.profile.labels.verticals')">
                    <VerticalsSelect
                        v-if="profileLoaded"
                        v-model="_user.verticals"
                        :clearable="false"
                        :disabled="isEdit"
                        hide-null
                        :placeholder="$t('common.entity.filters.verticals')"
                        multiple
                        :searchable="false">
                    </VerticalsSelect>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </b-field>
            </div>
            <div
                v-if="!($route.name || '').startsWith('auth')"
                class="column is-6">
                <b-field :label="$t('advertiser.profile.labels.systemVertical')">
                    <b-input
                        v-if="profileLoaded"
                        :value="translateVertical(_user.systemVertical)"
                        disabled>
                    </b-input>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </b-field>
            </div>
        </div>

        <div v-if="role !== 'advertiser'">
            <h2 class="subtitle card-header-title mb-0 pb-0 column is-12">
                {{ $t(`advertiser.profile.labels.minCommission`) }}
            </h2>

            <div class="columns is-multiline">
                <div
                    :key="idx"
                    v-for="(item, idx) in _minimalCommission"
                    class="column is-6">
                    <b-field v-if="profileLoaded">
                        <p
                            class="control">
                            <a class="button is-static">{{ item.currency }}</a>
                        </p>
                        <p class="control is-expanded">
                            <LInput
                                :value="item.value"
                                :disabled="isEdit"
                                :placeholder="$t(`advertiser.profile.labels.minCommission`)"
                                required
                                min="0"
                                step="0.01"
                                type="number"
                                @input="changeMinimalCommissions($event, item)">
                            </LInput>
                        </p>
                    </b-field>
                    <b-skeleton
                        v-else
                        width="100%"
                        height="46px"
                        animated>
                    </b-skeleton>
                </div>
            </div>
        </div>
        <div v-if="role !== 'advertiser'">
            <h2 class="subtitle card-header-title mb-0 pb-0 column is-12">
                {{ $t(`advertiser.profile.labels.penalties`) }}
            </h2>

            <div class="column px-1 pb-1">
                <b-field>
                    <CustomCheckbox
                        v-if="profileLoaded"
                        v-model="_user.isFineDebt"
                        :label="$t('advertiser.profile.labels.isFineDebt')">
                    </CustomCheckbox>
                </b-field>
            </div>
            <div class="columns is-multiline">
                <div class="column">
                    <b-field>
                        <template #label>
                            <span class="default-white-space">
                                <LabelWithAnnotation
                                    :tooltip="$t('advertiser.profile.labels.fineDebtDaysTooltip')"
                                    tooltip-multilined
                                    class="default-white-space is-flex"
                                    tooltip-position="is-top">
                                    {{ $t('advertiser.profile.labels.fineDebtDays') }}
                                </LabelWithAnnotation>
                            </span>
                        </template>
                        <LInput
                            v-if="profileLoaded"
                            v-model.number="_user.fineDebtDays"
                            :disabled="isEdit || !_user.isFineDebt"
                            :placeholder="$t('advertiser.profile.labels.fineDebtDays')"
                            :required="_user.isFineDebt"
                            min="1"
                            type="number">
                        </LInput>
                        <b-skeleton
                            v-else
                            width="100%"
                            height="46px"
                            animated>
                        </b-skeleton>
                    </b-field>
                </div>
                <div class="column">
                    <b-field :label="$t('advertiser.profile.labels.fineDebtPercent')">
                        <div
                            v-if="profileLoaded"
                            class="field has-addons">
                            <p class="control">
                                <a class="button is-static">%</a>
                            </p>
                            <p class="control is-expanded">
                                <LInput
                                    v-model.number="_user.fineDebtPercent"
                                    :disabled="isEdit || !_user.isFineDebt"
                                    :placeholder="$t('advertiser.profile.labels.fineDebtPercent')"
                                    :required="_user.isFineDebt"
                                    step="0.01"
                                    min="0.01"
                                    max="100"
                                    type="number">
                                </LInput>
                            </p>
                        </div>
                        <b-skeleton
                            v-else
                            width="100%"
                            height="46px"
                            animated>
                        </b-skeleton>
                    </b-field>
                </div>
            </div>
        </div>
        <div
            v-if="$slots.buttons"
            class="column is-12">
            <slot name="buttons">
            </slot>
        </div>
    </div>
</template>

<script>
  import { mapState } from "vuex";
  import { EMAIL_VALIDATOR } from "@core/validators";
  import Select from "@/components/Common/Select/Select.vue";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";
  import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";
  import _startCase from "lodash/startCase";
  import { formatEmptyString } from "@core/filters";
  import { useAdvertisersEdit } from "@/stores/admin/users/advertisers/advertisersEditStore";
  import ContactsBox from "@/components/Common/ContactsBox.vue";

  export default {
    name: "AdvertiserProfile",

    components: {
      ContactsBox,
      VerticalsSelect,
      Select,
      CustomCheckbox,
      LabelWithAnnotation
    },

    props: {
      isEdit: {
        type: Boolean,
        default: false
      },

      isSuperEdit: {
        type: Boolean,
        default: false
      },

      user: {
        type: Object,
        default: null
      }
    },

    setup () {
      return {
        editStore: useAdvertisersEdit()
      };
    },

    computed: {
      ...mapState({
        timeZones: ({ dictionaries }) => dictionaries.timeZones,
        role: state => state.auth.role,
        currencyList: ({ auth }) => auth?.availableCurrencies
      }),

      profileLoaded () {
        return !!this.user;
      },

      emailValidator () {
        return EMAIL_VALIDATOR;
      },

      _minimalCommission () {
        if (this.role !== "advertiser") {
          if (this.profileLoaded) {
            return this.user.minimalCommission;
          } return ["USD","RUB"];
        } return [];
      },

      _user: {
        get () {
          return this.user;
        },

        set (value) {
          this.$emit("update:user", value);
        }
      }
    },

    methods: {
      changeMinimalCommissions (value, item) {
        const commission = {
          ...item,
          value: Number(value)
        };
        this.editStore.UPDATE_ADVERTISERS_MINIMAL_COMMISSIONS(commission);
      },

      getCurrencies () {
        const items = this.currencyList;
        return { items, count: items.length };
      },

      translateVertical (vertical) {
        return vertical ? this.$t(`dictionaries.verticals.${ _startCase(vertical.toLowerCase()) }`) : formatEmptyString(vertical);
      }
    }
  };
</script>

<style lang="scss" scoped>
::v-deep{
    .default-white-space span {
        white-space: normal;
    }

    .danger{
        border-color: #f14668;
    }
}
</style>
