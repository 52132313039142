import { ActionTree, Module, MutationTree } from "vuex";
import {
  ComplaintsChatModalForm,
  ComplaintsChatModalInput,
  ComplaintsChatModalState
} from "@core/store/types/admin/complaints/complaintsChatModal";
import { RootState } from "@core/store/root-state";
import { SET_EMPTY, UPDATE_INPUT, UPDATE_MODAL } from "@core/store/mutation-constants";
import {
  ADD_TRAFFIC_COMPLAINT_COMMENT,
  GET_TRAFFIC_COMPLAINT_COMMENTS,
  UPDATE_FORM, UPDATE_MODAL_ACTIVE,
  UPDATE_COMMENTS
} from "@core/store/action-constants";
import { ComplaintsService } from "@core/services/admin/complaints/ComplaintsService";

const initialState = (): ComplaintsChatModalState => {
  return {
    isActive: false,
    complaintComments: null,
    input: {
      comment: null,
      trafficComplaintId: null
    },
    form: {
      offer: null,
      webmaster: null,
      intId: null,
      positivePeriod: null,
      negativePeriod: null,
      conversions: null,
      softCapacity: null
    }
  };
};

const state: () => ComplaintsChatModalState = initialState;

const mutations: MutationTree<ComplaintsChatModalState> = {
  [SET_EMPTY]: (state) => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload) {
    state.isActive = payload;
  },

  [UPDATE_COMMENTS] (state, { complaintComments }) {
    state.complaintComments = complaintComments;
  },

  [UPDATE_INPUT] (state, payload) {
    state.input = { ...state.input, ...payload };
  },

  [UPDATE_FORM] (state, payload) {
    state.form = { ...state.form, ...payload };
  }
};

const actions: ActionTree<ComplaintsChatModalState, RootState> = {
  async [ADD_TRAFFIC_COMPLAINT_COMMENT] ({ state: { input, complaintComments }, commit }) {
    const comment = await ComplaintsService.addTrafficComplaintComment(input);
    const newComments = { complaintComments: [...complaintComments || [], { ...comment } ] };
    commit(UPDATE_COMMENTS, newComments);
  },

  async [GET_TRAFFIC_COMPLAINT_COMMENTS] ({ state: { input: { trafficComplaintId } }, commit }) {
    const complaintComments = await ComplaintsService.getComplaintComments(trafficComplaintId as string);
    commit(UPDATE_COMMENTS, complaintComments);
  },

  [UPDATE_MODAL] ({ commit }, payload) {
    commit(UPDATE_MODAL, payload);
  },

  [UPDATE_INPUT] ({ commit }, payload: ComplaintsChatModalInput) {
    commit(UPDATE_INPUT, payload);
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [UPDATE_FORM] ({ commit }, payload: ComplaintsChatModalForm) {
    commit(UPDATE_FORM, payload);
  }
};

export const complaintsChatModal: Module<ComplaintsChatModalState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};