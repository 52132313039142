import { AxiosPromise } from "axios";
import recoveryPostback from "@core/services/common/statistic/detailing/graphql/recoveryPostback.graphql";
import Service from "@core/services/common/Service";

export class DetailingPostbacksService extends Service {
  public static sendRecoveryPostbacks (leadIds: string): AxiosPromise {
    return this.api.post("", {
      query: recoveryPostback,
      variables: { leadIds }
    });
  }
}