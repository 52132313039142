import {
  CreateOutTransactionMapper
} from "@core/services/admin/finances/financesCosts/FinancesCostsMapper";
import Service from "@core/services/common/Service";
import { CostsListItem } from "@core/store/types/admin/finances/financesCosts/financesCosts";
import { RequestAdvertiser } from "@core/store/types/admin/offer/OfferAdvertiser.d";
import addOutTransaction from "./graphql/addOutTransaction.graphql";

export class CreatePaymentCostsService extends Service {
  
  public static async createCosts (options: RequestAdvertiser[]): Promise<CostsListItem> {
    try {
      const { data: { addOutTransaction: newTransaction } } = await this.api.post("", {
        query: addOutTransaction,
        variables: { input: { ...options } }
      });
      return CreateOutTransactionMapper(newTransaction);
    } catch (e) {
      throw e;
    }
  }
}
