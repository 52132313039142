import { RolesService } from "@core/services/admin/roles/RolesService";
import {
  DELETE_ROLE,
  EDIT_ADMIN_ROLE, GET_PERMISSIONS,
  GET_ROLES
} from "@core/store/action-constants";
import { SET_EMPTY, SET_PERMISSIONS, SET_ROLES } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { RolesState } from "@core/store/types/admin/roles/roles";
import { rolesModal } from "@core/store/modules/admin/roles/rolesModal";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";

const initialState = (): RolesState => {
  return {
    permissions: [],
    filters: {
      name: null,
      id: null
    },
    roles: []
  };
};

const state: () => RolesState = initialState;

const mutations: MutationTree<RolesState> = {
  [SET_ROLES] (state, roles): void {
    state.roles = roles;
  },
  
  [SET_PERMISSIONS] (state, permissions): void {
    state.permissions = permissions;
  },

  [SET_EMPTY] (state): void {
    Object.assign(state, initialState());
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<RolesState, RootState> = {
  async [GET_ROLES] ({ state, commit }) {
    const { filters } = state;
    const limit = 500;
    const offset = 0;
    
    const getRoles = await RolesService.getRoles(limit, offset, filters);
    commit(SET_ROLES, getRoles);
    return getRoles;
  },
  
  async [GET_PERMISSIONS] ({ commit }) {
    const data = await RolesService.getPermissions();
    commit(SET_PERMISSIONS, data);

    return data;
  },

  async [EDIT_ADMIN_ROLE] ({ commit }, { admins }) {
    const addOrEditMultipleAdminsRoles = await RolesService.addOrEditMultipleAdminsRoles(admins);

    commit(
      "admin/users/administrators/LOCAL_UPDATE",
      { items: addOrEditMultipleAdminsRoles[0], target: "administrators" },
      { root: true }
    );
  },

  async [DELETE_ROLE] ({ commit }, roleId) {
    const deleteRole = await RolesService.deleteRole(roleId);
    commit("LOCAL_DELETE", { items: deleteRole, target: "roles" });
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const roles: Module<RolesState, RootState> = {
  namespaced: true,
  modules: {
    rolesModal
  },
  state,
  actions,
  mutations
};
