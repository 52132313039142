import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  SET_EMPTY,
  SET_INCIDENCE_DETAILS,
  UPDATE_FILTERS,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import { GET_INCIDENCE_DETAILS } from "@core/store/action-constants";
import { datepickerFormat } from "@core/helpers/datepicker";
import { IncidenceService } from "@core/services/advertiser/incidence/IncidenceService";
import reasonSelectedFields from "@core/store/modules/advertiser/incidence/reasonSelectedFields.json";
import {
  IncidenceDetailItem,
  IncidenceDetailState,
  IncidenceItem
} from "@core/store/types/advertiser/incidence/incidence";
import { pagination } from "@core/helpers/pagination";

const initialState = (): IncidenceDetailState => {
  return {
    data: null,
    filters: {
      incidences: null,
      threshold: 20
    },
    selectedFields: reasonSelectedFields,
    sort: null,
    order: null
  };
};

const state: () => IncidenceDetailState = initialState;

const getters: GetterTree<IncidenceDetailState, RootState> = {
  reasons: (state, getters, rootState) => rootState.advertiser.incidence.data?.items
    ?.slice()
    .sort((a: IncidenceDetailItem, b: IncidenceDetailItem) => b.value - a.value)
    .map(({ reason }: IncidenceItem) => reason),
  datepicker: (state, getters, rootState) => datepickerFormat(rootState.advertiser.incidence.filters.datepicker, "date"),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filters: ({ filters }) => JSON.stringify(filters)
};

const mutations: MutationTree<IncidenceDetailState> = {
  [UPDATE_FILTERS] (state, payload): void {
    state.filters = { ...state.filters, ...payload };
  },

  [SET_INCIDENCE_DETAILS] (state, payload): void {
    state.data = { ...state.data, ...payload };
  },

  [UPDATE_SORTINGS] (state, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [SET_EMPTY] (state, target?: keyof IncidenceItem): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target] as never;
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<IncidenceDetailState, RootState> = {
  async [GET_INCIDENCE_DETAILS] ({ commit, state, getters, rootState }): Promise<void> {
    const { limit, offset } = pagination(25);
    const { incidences, threshold, ...filters } = state.filters;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { datepicker: date, webmasterId, ...incidenceFilters } = rootState.advertiser.incidence.filters;
    const { datepicker } = getters;

    if (incidences?.length) {
      const { data: { incidenceDetails } } = await IncidenceService.getIncidenceDetails(
        Number(threshold || 20),
        incidences,
        {
          ...incidenceFilters,
          ...filters,
          ...datepicker,
          webmasterId: webmasterId ? [webmasterId] : null
        },
        state.sort,
        state.order,
        limit,
        offset
      );

      commit(SET_INCIDENCE_DETAILS, incidenceDetails);
    }
  },

  [UPDATE_FILTERS] ({ commit }, payload): void {
    commit(UPDATE_FILTERS, payload);
  },

  [UPDATE_SORTINGS] ({ commit }, payload): void {
    commit(UPDATE_SORTINGS, payload);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const incidenceDetail: Module<IncidenceDetailState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};