export default [
  {
    path: "capacityAndBumps",
    name: "capacityAndBumps",
    redirect: { name: "admin:capacityAndBumps:capacity" },
    component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps" */ "@/views/Admin/CapacityView.vue"),
    children: [
      {
        path: "capacity",
        name: "capacity",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps:capacity" */ "@/components/Admin/CapacityAndBumps/Capacity.vue")
      },
      {
        path: "log",
        name: "log",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps:log" */ "@/components/Admin/CapacityAndBumps/LogRequest.vue")
      },
      {
        path: "statistics",
        name: "statistics",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps:capacityStatistics" */ "@/components/Admin/CapacityAndBumps/CapacityStatistics.vue")
      },
      {
        path: "efficiency",
        name: "efficiency",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps:capacityRequest" */ "@/components/Admin/CapacityAndBumps/CapacityRequestEfficiency.vue")
      },
      {
        path: "actualCapacity",
        name: "actualCapacity",
        component: (): Promise<any> => import(/* webpackChunkName: "admin:capacityAndBumps:actualCapacity" */ "@/components/Admin/CapacityAndBumps/ActualCapacity.vue")
      }
    ]
  }
];