import { GET_ROLES_LIST } from "@core/store/action-constants";
import { UPDATE_ROLES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RolesListState } from "@core/store/types/common/lists/RolesList";
import { RolesListService } from "@core/services/common/lists/RolesListService";

const initialState = (): RolesListState => {
  return {
    roles: {},
    filters: {
      name: null,
      id: null
    }
  };
};

const state: () => RolesListState = initialState;

const getters: GetterTree<RolesListState, RootState> = {};

const mutations: MutationTree<RolesListState> = {
  [UPDATE_ROLES_LIST] (state, roles: RolesListState["roles"]): void {
    state.roles.items = roles.items;
    state.roles.count = roles.count;
  }
};

const actions: ActionTree<RolesListState, RootState> = {
  async [GET_ROLES_LIST] ({ state, commit }): Promise<void> {
    const { filters } = state;
    const limit = 500;
    const offset = 0;

    const roles = await RolesListService.getRolesList(limit, offset, filters);
    commit(UPDATE_ROLES_LIST, roles);
  }
};

export const rolesList: Module<RolesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
