import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  SET_EMPTY, 
  BLOCK_WEBMASTER,
  GET_SUB_ACCOUNTS,
  UNBLOCK_WEBMASTER,
  UPDATE_SUB_ACCOUNTS,
  UPDATE_MODAL_ACTIVE,
  UPDATE_SUB_ACCOUNTS_BLOCK
} from "@core/store/action-constants";
import { SubAccountsModalState } from "@core/store/types/admin/users/webmasters/SubAccount";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { SubAccountsService } from "@core/services/admin/users/webmasters/SubAccountsService";

const initialState = (): SubAccountsModalState => {
  return {
    subAccounts: null,
    isSubAccountsModalActive: false,
    subWebmasterBlock: {
      isSubAccountsBlockAccountModal: false,
      subWebmasterBlockData: null
    }
  };
};

const state: () => SubAccountsModalState = initialState;

const mutations: MutationTree<SubAccountsModalState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_SUB_ACCOUNTS] (state, subAccounts: SubAccountsModalState["subAccounts"]): void {
    state.subAccounts = subAccounts;
  },

  [UPDATE_SUB_ACCOUNTS_BLOCK] (state, subWebmasterBlockData: any): void {
    state.subWebmasterBlock = subWebmasterBlockData;
  },

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean): void {
    state.isSubAccountsModalActive = payload;
  },

  ...stateUpdater.mutations
};

const actions: ActionTree<SubAccountsModalState, RootState> = {
  async [GET_SUB_ACCOUNTS] ({ commit }, id: string): Promise<void> {
    const data = await SubAccountsService.getSubAccountWebmasters(1, 0, { id });
    commit(UPDATE_SUB_ACCOUNTS, data);
  },

  async [BLOCK_WEBMASTER] ({ commit }, { id, transferBalance }): Promise<void> {
    const blockedWeb = await WebmastersService.blockWebmaster(id, transferBalance);
    commit("LOCAL_UPDATE", { items: blockedWeb, target: "subAccounts" });
  },

  async [UNBLOCK_WEBMASTER] ({ commit }, { id }): Promise<void> {
    const unblockedWeb = await WebmastersService.unblockWebmaster(id);
    commit("LOCAL_UPDATE", { items: unblockedWeb, target: "subAccounts" });
  },

  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [SET_EMPTY] ({ commit }): void {
    commit(SET_EMPTY);
  }
};

export const subAccountsModal: Module<RootState, SubAccountsModalState> = {
  namespaced: true,
  state,
  actions,
  mutations
};