import leadDepositHistory from "@core/services/common/statistic/detailing/graphql/leadDepositHistory.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import {
  DetailingDepositHistoryModalOptions
} from "@core/store/types/common/statistic/detailing/detailingDepositHistoryModal";

export class DetailingDepositHistoryModalService extends Service {
  public static async getDepositHistory (
    leadId: DetailingDepositHistoryModalOptions["leadId"],
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    const { data: { leadDepositHistory: data } } = await this.api.get("", {
      params: {
        query: leadDepositHistory,
        variables: {
          leadId,
          limit,
          offset
        }
      }
    });

    return data;
  }
}