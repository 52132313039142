import { Filters } from "@core/store/modules/common/generators/filters";
import { UtmItem } from "@core/store/types/common/statistic/common/utmFilters";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class AgentFilters extends Filters<UtmItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput) => {
    const { data: { webmasters } } = await WebmastersService.getWebmasters(limit, offset, { webmasterSubType: "AGENT", ...filters });
    return {
      data: {
        items: webmasters.items,
        count: webmasters?.count
      }
    };
  }
  
  getInitialFilters = (ids?: string): FilterInput | undefined => {
    if (ids) {
      const agent = ids;
      return { agent };
    }
  };
}

export const agentFilters = new AgentFilters();
