import { advertisersCapacity } from "@core/store/modules/admin/capacityAndBumps/modules/capacity/advertisersCapacity";
import { externalWebmastersCapacity } from "@core/store/modules/admin/capacityAndBumps/modules/capacity/externalWebmastersCapacity";
import { offersCapacity } from "@core/store/modules/admin/capacityAndBumps/modules/capacity/offersCapacity";
import { webmastersCapacity } from "@core/store/modules/admin/capacityAndBumps/modules/capacity/webmastersCapacity";
import { Module } from "vuex";
import { RootState } from "@core/store/root-state";

export const capacity: Module<any, RootState> = {
  namespaced: true,
  modules: {
    advertisersCapacity,
    offersCapacity,
    webmastersCapacity,
    externalWebmastersCapacity
  }
};
