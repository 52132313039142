import { SubAnalyticIncidenceContextResponse } from "@core/store/types/admin/analytic/subAnalyticDrawdownReasonModal";

export function incidenceContextMapper ({ reason, date, creatorComment, trafficComplaint }: SubAnalyticIncidenceContextResponse) {
  const { positiveStartDate, positiveEndDate, negativeStartDate, negativeEndDate, ...other } = trafficComplaint || {};
  return {
    reason,
    date,
    creatorComment,
    createComplaint: trafficComplaint,
    positiveDatepicker: {
      positiveStartDate,
      positiveEndDate
    },
    negativeDatepicker: {
      negativeStartDate,
      negativeEndDate
    },
    ...other
  };
}