import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import availableAdvertisers from "@core/services/admin/common/offer/graphql/availableAdvertisers.graphql";
import Service from "@core/services/common/Service";
import { PartialAdvertisersListState } from "@core/store/types/admin/offer/lists/OfferEditAdvertisersList";
import { AxiosPromise } from "axios";

export class OfferEditAdvertisersAvailableService extends Service {
  public static getOfferAdvertisers (limit: number, offset: number,
    filters: PartialAdvertisersListState["filters"],
    id: string): AxiosPromise {
    try {
      return GraphQLServer.get("", {
        params: {
          query: availableAdvertisers,
          variables: {
            limit,
            offset,
            filters,
            id
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
