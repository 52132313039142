<template>
    <div v-if="items && items.length">
        <template v-if="!full && items.length > listLength">
            <div class="is-flex is-justify-content-flex-start is-align-items-center">
                <slot name="shortList">
                    <span
                        :key="index"
                        v-for="(item, index) in items.slice(0, listLength)"
                        :class="{ 'mr-1': listLength > 1 }">
                        <!--suppress HtmlUnknownTag -->
                        <slot
                            name="listItem"
                            :item="item">
                            {{ item.name }}
                        </slot>
                    </span>
                </slot>
                <LTooltip
                    :active="active"
                    :position="tooltipPosition"
                    :interactive="interactive"
                    type="is-white">
                    <b-tag class="has-text-weight-bold has-text-primary ml-1 is-clickable">
                        +{{ items.length - listLength }}
                    </b-tag>
                    <template #content>
                        <div
                            :style="{ 'max-height': `${ maxHeight }px` }"
                            class="tooltip-content-list">
                            <div
                                :key="index"
                                v-for="(item, index) in items"
                                class="my-1">
                                <!--suppress HtmlUnknownTag -->
                                <slot
                                    :item="item"
                                    name="tooltipItem">
                                    {{ item.name }}
                                </slot>
                            </div>
                        </div>
                    </template>
                </LTooltip>
            </div>
        </template>
        <template v-else>
            <div
                :key="index"
                v-for="(item, index) in items"
                class="is-flex is-justify-content-flex-start is-align-items-center">
                <slot
                    name="singleItem"
                    :item="item">
                    <!--suppress HtmlUnknownTag -->
                    {{ item.name }}
                </slot>
            </div>
        </template>
    </div>
    <div v-else>
        {{ empty ? empty : $options.methods.formatEmptyString() }}
    </div>
</template>

<script>
  import { ListView } from "@core/mixins";

  export default {
    name: "CompactList",
    mixins: [ListView],
    props: {
      full: {
        type: Boolean,
        default: false
      },
      tooltipPosition: {
        type: String,
        default: "is-top"
      },
      listLength: {
        type: Number,
        default: 1
      },
      maxHeight: {
        type: [Number, String],
        default: 200
      },
      active: {
        type: Boolean,
        default: true
      },
      interactive: {
        type: Boolean,
        default: true
      }
    }
  };
</script>

<style lang="scss" scoped>
    .tooltip-content-list {
        margin: -0.35rem -0.75rem;
        padding: 0.35rem 0.75rem;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 2;
    }
</style>
