import { BaseFilter, BaseFilterList } from "./BaseFilter";

export class FlowFilter extends BaseFilter {
  public name = "";
  
  constructor (flow: any) {
    super();
    
    this.id = flow.id;
    this.name = flow.name;
  }
}

export interface FlowsFilterList extends BaseFilterList {
  items: FlowFilter[];
}