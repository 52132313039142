<template>
    <Select
        v-model="selectedCategories"
        v-bind="$attrs"
        :get-data-vuex="getCategories"
        :placeholder="placeholder"
        :required="required"
        class="white"
        :seed="_seed"
        :multiple="multiple">
    </Select>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import { mapState, mapActions } from "vuex";
  import { GET_CATEGORIES, SET_EMPTY } from "@core/store/action-constants";
  import { filteredDataBySearch } from "@core/helpers/filteredDataBySearch";

  export default {
    name: "CategoriesFilter",
    components: { Select },

    props: {
      value: {
        type: [Array, String, null],
        default: null
      },

      placeholder: {
        type: String,
        default: ""
      },

      required: {
        type: Boolean,
        default: false
      },

      multiple: {
        type: Boolean,
        default: false
      },

      vertical: {
        type: [Boolean, String],
        default: false
      },

      seed: {
        type: null,
        default: null
      }
    },

    created () {
      this.SET_EMPTY();
    },

    data () {
      return {
        namespace: "categories"
      };
    },

    computed: {
      ...mapState("categories", {
        categories: state => state.data
      }),

      currentVertical () {
        return this.$store.state.verticals.currentVertical;
      },

      _seed () {
        return this.seed ? this.seed : this.vertical ? this.currentVertical : "";
      },

      selectedCategories: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit("input", value);
        }
      },

      translatedCategories () {
        return this.categories?.items?.map((item) => {
          const name = `common.entity.offer.categoriesTypes.${ item.name.replace(/\s/g, "_") }`;
          if (this.$te(name)) {
            return {
              ...item,
              name: this.$t(name)
            };
          }

          return item;
        });
      }
    },

    methods: {
      ...mapActions("categories", [
        GET_CATEGORIES,
        SET_EMPTY
      ]),

      async getCategories (label) {
        if (!this.categories.items || this.vertical) {
          const { vertical, currentVertical } = this;
          await this.GET_CATEGORIES({ vertical: vertical ? typeof vertical === "boolean" ? currentVertical : vertical : null });
        }
        if (label) {
          return filteredDataBySearch(this.translatedCategories, label);
        } else {
          return {
            items: this.translatedCategories,
            count: this.categories.count
          };
        }
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>
