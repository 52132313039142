import {
  GET_STATISTIC_POSTBACKS,
  SET_EMPTY,
  SET_EMPTY_CONTEXT,
  SYNC_STATISTIC_FILTERS
} from "@core/store/action-constants";
import {
  SET_STATISTIC_POSTBACKS,
  UPDATE_PAGINATION,
  SET_EMPTY_DATA,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PostbackFilters, Postbacks, PostbackState } from "@core/store/types/webmaster/statistic/modules/postbacks";
import { ActionTree, Module, MutationTree } from "vuex";
import { offersList } from "@core/store/modules/admin/lists/offersList";
import { utmCampaignFilters } from "@core/store/modules/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/utmTermFilters";
import { PostbacksService } from "@core/services/webmaster/statistic/postbacks/PostbacksService";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { Pagination } from "@core/store/types/Pagination";
import moment from "moment/moment";
import { datepicker } from "@core/helpers/datepicker";
import { pagination } from "@core/helpers/pagination";

const initialState = (): PostbackState => {
  return {
    postbacks: null,
    filters: {
      datepicker: datepicker(),
      offerId: null,
      status: null,
      clickId: null,
      leadId: null,
      utmSource: null,
      utmTerm: null,
      utmContent: null,
      utmCampaign: null,
      utmMedium: null
    }
  };
};

const state: () => PostbackState = initialState;

const mutations: MutationTree<PostbackState> = {
  [SET_STATISTIC_POSTBACKS]: (state, postbacks: Postbacks): void => {
    state.postbacks = postbacks;
  },

  [UPDATE_FILTERS] (state, filters: PostbackFilters): void {
    state.filters = { ...state.filters, ...filters };
  },

  [SET_EMPTY] (state, target?: keyof PostbackState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<PostbackState, RootState> = {
  async [GET_STATISTIC_POSTBACKS] ({ state: { filters: { datepicker, ...otherFilters } }, commit }): Promise<void> {
    const { limit, offset } = pagination(100);

    const getPostbackQueue = await PostbacksService.getPostbackQueue(limit, offset, { ...datepicker, ...otherFilters });
    commit(SET_STATISTIC_POSTBACKS, getPostbackQueue);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters): void {
    const baseFilters = prepareBaseFilters(filters);
    
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmTermFilters/${ UPDATE_FILTERS }`, baseFilters);
  },

  [SET_EMPTY] ({ commit, dispatch }, target?: string): void {
    commit(SET_EMPTY, target);
    dispatch(SET_EMPTY_DATA);
  },
  
  [SET_EMPTY_CONTEXT] ({ dispatch }): void {
    dispatch(SET_EMPTY_DATA);
  },

  [UPDATE_FILTERS] ({ commit }, filters: PostbackFilters): void {
    commit(UPDATE_FILTERS, filters);
  },

  [SET_EMPTY_DATA] ({ commit }): void {
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
    commit("offersList/SET_EMPTY");
  }
};

export const postbacks: Module<PostbackState, RootState> = {
  namespaced: true,
  modules: {
    offersList,
    utmCampaignFilters,
    utmContentFilters,
    utmMediumFilters,
    utmSourceFilters,
    utmTermFilters
  },
  state,
  actions,
  mutations
};
