import { OfferModule } from "@core/store/modules/common/offer/OfferModule";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";
import { InputPbCreate, InputPbEdit, ModifedPostback } from "@core/store/logic/webmaster/postbacks/postback";
import {
  offerRatesWebmasters
} from "@core/store/modules/webmaster/offer/offerRates/offerRatesWebmasters";
import {
  ADD_OFFER_POSTBACK,
  SET_OFFER_POSTBACKS,
  UPDATE_OFFER_POSTBACK_URL,
  UPDATE_OFFER_POSTBACK_STATUSES,
  UPDATE_OFFER_POSTBACK_DUPLICATES, UPDATE_POSTBACK_OPTIONS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  GET_OFFER_ID,
  GET_OFFER_POSTBACKS,
  REMOVE_OFFER_POSTBACK,
  SAVE_OFFER_POSTBACKS,
  JOIN_TO_OFFER,
  REMOVE_FLOW,
  UPDATE_OFFER,
  EXCLUDE_OFFER,
  SET_EMPTY,
  JOIN_PRIVATE_OFFER
} from "@core/store/action-constants";
import { OfferService } from "@core/services/common/offer/OfferService";
import { OfferService as OfferServiceWebmaster } from "@core/services/webmaster/OfferService";
import { PostbackService } from "@core/logic/webmaster/postbacks/postback";
import { PostbacksService } from "@core/services/webmaster/postbacks/PostbacksService";
import { CreateInput, EditInput } from "@core/store/types/webmaster/postbacks/postback";
import { OfferLandings } from "@core/store/modules/webmaster/offers/modules/offerLandings";
import { offerEditLinkModal } from "@core/store/modules/webmaster/offer/modules/offerEditLinkModal";
import { offerConversionList } from "@core/store/modules/admin/offer/lists/offerConversionList";
import { uploadingSettings } from "@core/store/modules/webmaster/offer/modules/uploadingSettings";
import { CallCenter } from "@core/logic/common/callCenters/callCenters";
import Vue from "vue";

export interface OfferState {
  offer: Nullable<WebmasterOffer>;
  callCenters: Nullable<CallCenter>;
  postbacks: Array<ModifedPostback>;
}

export class WebmasterOfferModule extends OfferModule<OfferState, RootState> {
  private readonly initState: () => OfferState;

  constructor () {
    super();

    this.namespaced = true;

    this.modules({
      landings: new OfferLandings("landing").toModule(),
      transits: new OfferLandings("transit").toModule(),
      offerConversionList,
      rates: offerRatesWebmasters,
      offerEditLinkModal,
      uploadingSettings
    });

    this.initState = (): OfferState => {
      return {
        offer: null,
        callCenters: null,
        // Один постбек, чтобы сразу редактировать, если у оффера их нет
        postbacks: []
      };
    };
    this.state(this.initState());

    this.actions({
      SET_EMPTY ({ commit }) {
        commit(`rates/${ SET_EMPTY }`);
        commit(SET_EMPTY);
      },
      async [JOIN_TO_OFFER] ({ commit }, id) {
        try {
          const data = await OfferService.webmasterJoinToOffer(id);

          commit(UPDATE_OFFER, data);
          commit("webmaster/offers/LOCAL_UPDATE", { items: data }, { root: true });
        } catch (e) {
          throw e;
        }
      },

      async [EXCLUDE_OFFER] ({ commit }, id) {
        try {
          const data = await OfferService.excludeWebmasterFromOffer(id);

          commit(UPDATE_OFFER, data);
          commit("webmaster/offers/LOCAL_UPDATE", { items: data }, { root: true });
        } catch (e) {
          throw e;
        }
      },

      async [JOIN_PRIVATE_OFFER] ({ commit }, id) {
        const { data: { webmasterRequestToJoinToOffer } } = await OfferServiceWebmaster.joinPrivateOffer(id);

        commit(UPDATE_OFFER, webmasterRequestToJoinToOffer);
        commit("webmaster/offers/LOCAL_UPDATE", { items: webmasterRequestToJoinToOffer }, { root: true });
        return webmasterRequestToJoinToOffer;
      },

      async [REMOVE_FLOW] ({ commit }, id) {
        const deletedFlow = await OfferService.deleteFlow(id);
        commit("webmaster/offerFlows/LOCAL_DELETE", { items: deletedFlow, target: "flows" }, { root: true });
        commit("webmaster/flows/LOCAL_DELETE", { items: deletedFlow, target: "flows" }, { root: true });
      },

      async ENABLE_OFFER_POSTBACK ({ commit, state }, id: string) {
        await PostbackService.enableOfferPostback(id);
        const data = { ...state.offer, isPostbackEnable: true };
        commit("SET_OFFER", data);

        return data;
      },

      async DISABLE_OFFER_POSTBACK ({ commit, state }, id: string) {
        await PostbackService.disableOfferPostback(id);
        const data = { ...state.offer, isPostbackEnable: false };
        commit("SET_OFFER", data);

        return data;
      },

      async [GET_OFFER_POSTBACKS] ({ commit, getters }) {
        const id = getters[GET_OFFER_ID];
        const { data: { postbackUrlList } } = await PostbacksService.getPostbacks(id);

        const postbacks = postbackUrlList.map((pb) => new ModifedPostback(pb));

        commit(SET_OFFER_POSTBACKS, postbacks);
      },

      async [REMOVE_OFFER_POSTBACK] ({ dispatch, commit, state }, index) {
        const pb = state.postbacks[index];

        if (pb.id) {
          await PostbacksService.removePostback(pb.id as string);
          dispatch(GET_OFFER_POSTBACKS);
        } else {
          const filteredPostbacks = state.postbacks.filter((_, i) => i !== index);
          commit(SET_OFFER_POSTBACKS, filteredPostbacks);
        }
      },

      async [SAVE_OFFER_POSTBACKS] ({ dispatch, state }) {
        // Делим постбеки на новые и редактируемые
        const { create, edit } = state.postbacks.reduce<{ create: CreateInput; edit: EditInput }>(
          (acc, pb) => {
            if (pb.offerId) {
              acc.edit.push(new InputPbEdit(pb));
            } else {
              acc.create.push(new InputPbCreate(pb, state.offer?.id as string));
            }
            return acc;
          },
          { create: [], edit: [] }
        );

        if (edit.length) {
          await PostbacksService.editPostbacks(edit);
        }

        if (create.length) {
          await PostbacksService.createPostbacks(create);
        }

        dispatch(GET_OFFER_POSTBACKS);
      },

      [UPDATE_OFFER_POSTBACK_URL] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_URL, payload);
      },

      [UPDATE_OFFER_POSTBACK_STATUSES] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_STATUSES, payload);
      },

      [UPDATE_OFFER_POSTBACK_DUPLICATES] ({ commit }, payload) {
        commit(UPDATE_OFFER_POSTBACK_DUPLICATES, payload);
      },

      [UPDATE_POSTBACK_OPTIONS] ({ commit }, payload) {
        commit(UPDATE_POSTBACK_OPTIONS, payload);
      }
    });

    this.mutations({
      SET_EMPTY: (state): void => {
        Object.assign(state, this.initState());
      },

      [SET_OFFER_POSTBACKS]: (state, postbacks) => {
        state.postbacks = postbacks;
      },

      [ADD_OFFER_POSTBACK]: (state) => {
        // @ts-ignore
        state.postbacks = [new ModifedPostback({ vertical: state.offer?.vertical }), ...state.postbacks];
      },

      [UPDATE_OFFER]: (state, data) => {
        if (state.offer){
          state.offer = { ...state.offer, ...data };
        }
      },

      [UPDATE_OFFER_POSTBACK_URL] (state, { value, index }): void {
        Vue.set(state.postbacks[index], "url", value);
      },

      [UPDATE_POSTBACK_OPTIONS] (state, { value, index }): void {
        Vue.set(state.postbacks, index, { ...state.postbacks[index], ...value });
      },

      [UPDATE_OFFER_POSTBACK_STATUSES] (state, { statusIndex, postbackIndex }): void {
        state.postbacks[postbackIndex].statuses[statusIndex].isActive = !state.postbacks[postbackIndex].statuses[statusIndex].isActive;
      },

      [UPDATE_OFFER_POSTBACK_DUPLICATES] (state, index): void {
        state.postbacks[index].isSendDuplicates = !state.postbacks[index].isSendDuplicates;
      }
    });

    return this;
  }
}

