// @vue/components
export const OffersFilters = {
  methods: {
    getOffersByActionName (func, label, offset, nameFilterType = "nameOrIntId", filters = {}) {
      label = label.length > 0 ? label : null;
      offset = +offset;
      return func({
        limit: 25,
        offset,
        filters: {
          ...filters,
          [nameFilterType]: label
        }
      });
    }
  }
};