<template>
    <section>
        <b-collapse
            :key="index"
            v-for="(item, index) of menu"
            animation=""
            :open="activeCollapse === index"
            :aria-id="'contentId-' + index"
            @open="openCollapse(item, index)">
            <template #trigger="trigger">
                <slot name="trigger">
                    <template v-if="!item.isHidden">
                        <Permissions
                            v-if="permissions(item)"
                            :key="index"
                            :permissions="permissions(item)">
                            <div class="trigger-wrapper">
                                <div :style="{ width: `${ isShowCollapse(item) ? 90 : 100 }%` }">
                                    <AsideMenuItem :item="item"></AsideMenuItem>
                                </div>
                                <slot
                                    :trigger="trigger"
                                    name="icon">
                                    <div
                                        v-if="isShowCollapse(item)"
                                        class="trigger-icon card-header-icon">
                                        <b-icon :icon="!trigger.open ? 'angle-down' : 'angle-up'"></b-icon>
                                    </div>
                                </slot>
                            </div>
                        </Permissions>
                    </template>
                </slot>
            </template>

            <template>
                <div v-show="isShowCollapse(item)">
                    <div
                        :key="index"
                        v-for="(children, index) in item.children"
                        class="is-relative"
                        @click="openCollapse(item, index, true)">
                        <Permissions
                            v-if="permissions(children) && !children.isHidden"
                            :key="index"
                            :permissions="permissions(children)">
                            <AsideMenuItem
                                :item="children"
                                inner>
                            </AsideMenuItem>
                        </Permissions>
                    </div>
                </div>
            </template>
        </b-collapse>
    </section>
</template>

<script>
  import AsideMenuItem from "@/components/Common/AsideMenuItem";
  import Permissions from "@/components/Common/Permissions.vue";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapState } from "vuex";

  export default {
    name: "AsideMenuList",
    components: {
      Permissions,
      AsideMenuItem
    },
    props: {
      menu: {
        type: Array,
        default: () => []
      },
      isReduce: {
        type: Boolean,
        default: null
      }
    },

    created () {
      if (!this.isReduce) {
        this.setActiveTabByRouteName();
      }
    },

    data () {
      return {
        activeCollapse: 0
      };
    },

    computed: {
      ...mapState({
        permittedPaths ({ auth }) {
          return this.menu.map(({ children }) => children?.filter(p => hasPermissions(p.permissions, auth.userPermissions)) || []);
        }
      })
    },

    methods: {
      setActiveTabByRouteName () {
        const index = this.permittedPaths.findIndex(el => {
          return el.find(item => item.to?.name === this.$route.name);
        });
        this.activeCollapse = index === -1 ? 0 : index;
      },

      permissions (item) {
        return item.permissions ?? "ANY";
      },

      isShowCollapse (item) {
        const { children = [] } = item;
        return children.length > 0;
      },

      openCollapse ({ children }, index, isInner = false) {
        if (!isInner) {
          this.activeCollapse = index;
        }
        if (children) {
          this.$emit("resetReduce");
        }
        if ((isInner || !children) && window.innerWidth < 1023) {
          this.resetActiveCollapse();
          this.$emit("closeAsideMenu");
        }
      },

      resetActiveCollapse () {
        this.activeCollapse = null;
      }
    },

    watch: {
      "$route.name" (value) {
        const { activeCollapse, menu } = this;
        const { children = [] } = menu[activeCollapse] ?? {};
        const activeCollapseChildren = children.find(item => item.to.name === value) ?? {};

        if (activeCollapseChildren.to?.name !== value) {
          this.resetActiveCollapse();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .collapse-trigger {
            display: block !important;
            margin: .4rem .6rem;
        }
    }

    .trigger {
        &-wrapper {
            display: flex;
            align-items: center;
            position: relative;

            &:hover {
                a.external-item::before {
                    background-color: rgba(255, 186, 65, 0.2);
                }
            }
        }

        &-icon {
            width: 10%;
            padding: 0 .6rem 0 0;
        }
    }
</style>
