<template>
    <section class="hero confirm-mail is-fullheight">
        <div class="hero-body">
            <div class="container">
                <!--suppress HtmlUnknownTarget -->
                <img
                    v-if="$saas.main"
                    alt="logo"
                    :src="require(`@/assets/${ $saas.logo }`)">
                <!--suppress HtmlUnknownTarget -->
                <img
                    alt="mail-image"
                    src="@/assets/MailConfirmation/send.png">
                <h1 class="has-text-black is-title has-text-centered">
                    {{ $t(`common.mailConfirmation.send.title`) }}
                </h1>
                <a
                    v-if="$saas.main"
                    :href="link"
                    class="button is-success is-rounded is-fullwidth">
                    {{ $t(`common.mailConfirmation.send.link`) }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "MailConfirmationSend",
    created () {
      if (process.env.NODE_ENV === "production" && this.userType === "webmaster") {
        this.$gtag.pageview("/confirmation/send");
      }
    },
    computed: {
      ...mapState("signUp", {
        userType: ({ form }) => form.userType
      }),

      link () {
        return process.env.VUE_APP_LANDING_PAGE_URL;
      }
    }
  };
</script>

<style scoped lang="scss">

</style>
