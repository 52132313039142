




















































































































































































































import CompactList from "@/components/Common/Lists/CompactList.vue";
import Countries from "@/components/Common/Lists/Countries";
import { momentWithEmpty } from "@core/flowMethods";
import { currencySign, formatEmptyString } from "@core/filters";
import { withDefaults, defineProps } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
import { i18n } from "@core/plugins";
import { WebmasterProfileViewModalData } from "@core/store/types/common/webmasterProfileViewModal";
import UserContacts from "@/components/Common/Output/UserContacts.vue";
interface Props {
  data: WebmasterProfileViewModalData | null;
}
const __sfc_main = {};
__sfc_main.props = {
  data: {
    key: "data",
    required: false,
    type: null,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const namespace = "common.entity.webmasterCard.fullCard";
  const props = __props;
  const isTeam = computed(() => props.data?.detail.isTeam);
  const requisites = computed(() => props.data?.requisites.map(({
    purse,
    paymentSystem: {
      name,
      currency
    }
  }) => ({
    customCaption: `${name} (${currencySign(currency)})`,
    icon: "wallet",
    content: purse
  })));
  const mainInfo = computed(() => {
    if (!props.data) return [];
    const {
      detail: {
        isPartnerNetwork,
        birthday,
        label,
        verticals,
        isDomestic,
        residenceCity,
        residenceCountry
      },
      contact: {
        email,
        telegram,
        skype,
        whatsApp,
        viber
      },
      timeZone: {
        alias,
        offset
      },
      isShowDomonetizationStatistics,
      preferredCurrency,
      agentType,
      subType,
      referrals,
      webmasters
    } = props.data;
    const referrer = props.data.referrer?.login;
    return [{
      title: `${namespace}.body.main.title`,
      items: [{
        caption: `${namespace}.body.main.mail`,
        link: `mailto:${email}`,
        icon: "envelope-alt",
        content: email
      }, {
        caption: "common.entity.contacts",
        contacts: {
          telegram,
          skype,
          whatsApp,
          viber
        }
      }, {
        caption: `${namespace}.body.main.timezone`,
        icon: "clock-five",
        content: `${alias} ${offset}`
      }, {
        caption: `${namespace}.body.main.dateBirth`,
        icon: "calender",
        content: momentWithEmpty(birthday, "DD.MM.YYYY")
      }, {
        caption: `${namespace}.body.main.preferredCurrency`,
        icon: "usd-circle",
        content: i18n.t(`common.currency.${preferredCurrency}`)
      }]
    }, {
      title: `${namespace}.body.requisites.title`,
      items: requisites.value
    }, {
      title: `${namespace}.body.other.title`,
      items: [{
        caption: `${namespace}.body.domestic`,
        icon: "house-user",
        content: conditionType(isDomestic)
      }, {
        caption: `${namespace}.body.other.partnerNetwork`,
        icon: "share-alt",
        content: conditionType(isPartnerNetwork)
      }, {
        caption: `${namespace}.body.other.agentOffice`,
        icon: "check",
        content: conditionType(subType === "AGENT")
      }, {
        caption: `${namespace}.body.other.referrals`,
        icon: "link",
        content: referrals?.length,
        tooltip: {
          title: `${namespace}.body.tooltip.referrals`,
          content: referrals?.length > 0 ? referrals : null
        }
      }, {
        caption: `${namespace}.body.other.referrer`,
        img: "Referrer.svg",
        content: referrer
      }, {
        caption: `${namespace}.body.other.typeAgentOffice`,
        icon: "money-bill",
        content: i18n.t(`${namespace}.body.detail.typeAgentOffice.${agentType}`)
      }, {
        caption: `${namespace}.body.other.vertical`,
        namespace: "dictionaries.verticals",
        icon: "list-ul",
        compactList: verticals
      }, {
        caption: `${namespace}.body.other.typeOffice`,
        flag: label === "CIS" ? ["RU"] : label === "EXTERNAL" ? ["EU"] : label === "MULTI" ? ["EU", "RU"] : ["null"],
        content: i18n.t(`dictionaries.webmasterLabel.${label}`)
      }, {
        caption: `${namespace}.body.other.subAccounts`,
        img: "SubAccount.svg",
        content: webmasters?.length,
        tooltip: {
          title: `${namespace}.body.tooltip.subAccounts`,
          content: webmasters?.length > 0 ? webmasters : null
        }
      }, {
        caption: `${namespace}.body.domonetizationStatistic`,
        icon: "coins",
        content: conditionType(isShowDomonetizationStatistics)
      }, {
        caption: "common.entity.webmasterCard.fullCard.body.other.residenceCity",
        icon: "building",
        content: residenceCity?.name
      }, {
        caption: "common.entity.webmasterCard.fullCard.body.other.residenceCountry",
        icon: !residenceCountry?.name && "globe",
        countries: residenceCountry
      }]
    }];
  });
  function conditionType(value: unknown, name = "partnerNetwork") {
    const translateKey = `${namespace}.body.detail.${name}`;
    return value ? i18n.t(`${translateKey}.confirm`) : i18n.t(`${translateKey}.cancel`);
  }
  function avatar(item: WebmasterProfileViewModalData) {
    return item?.avatar?.url ?? require("@/assets/Avatar.svg");
  }
  function countryToArray(country: string | string[]) {
    return country ? [country] : [];
  }
  return {
    formatEmptyString,
    namespace,
    isTeam,
    mainInfo,
    avatar,
    countryToArray
  };
};
__sfc_main.components = Object.assign({
  CompactList,
  Countries,
  UserContacts
}, __sfc_main.components);
export default __sfc_main;
