<template>
    <button
        v-show="active"
        :class="size"
        class="button is-white is-loading"
        disabled>
    </button>
</template>

<script>
  export default {
    name: "InlineLoader",
    props: {
      active: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "is-small"
      }
    }
  };
</script>

<style scoped>

</style>
