import { GET_WEBMASTER_SUBTYPE } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_WEBMASTER_SUBTYPE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WebmasterSubTypeState } from "@core/store/types/admin/lists/WebmasterSubType";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";

const initialState = (): WebmasterSubTypeState => {
  return {
    data: {}
  };
};

const state: () => WebmasterSubTypeState = initialState;

const getters: GetterTree<WebmasterSubTypeState, RootState> = {};

const mutations: MutationTree<WebmasterSubTypeState> = {
  [UPDATE_WEBMASTER_SUBTYPE] (state, statuses: WebmasterSubTypeState["data"]) {
    state.data = statuses;
  },
  
  [SET_EMPTY]: state => Object.assign(state, initialState())
};

const actions: ActionTree<WebmasterSubTypeState, RootState> = {
  [GET_WEBMASTER_SUBTYPE] ({ commit }) {
    const items = [
      {
        value: "WEBMASTER",
        label: i18n.t("admin.users.webmasters.filters.values.webmaster")
      },
      {
        value: "AGENT",
        label: i18n.t("admin.users.webmasters.filters.values.agent")
      },
      {
        value: "SUB_WEBMASTER",
        label: i18n.t("admin.users.webmasters.filters.values.sub_webmaster")
      }
    ];
    
    commit(UPDATE_WEBMASTER_SUBTYPE, {
      items,
      count: items.length
    });
  }
};

export const webmasterSubType: Module<WebmasterSubTypeState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
