import { PaymentSystemFilter, PaymentSystemResponse } from "@core/logic/common/dictionaries/PaymentSystemFilter";
import landingFlows from "@core/services/webmaster/flows/graphql/landingFlows.graphql";
import { AxiosPromise, AxiosResponse } from "axios";
import Service from "@core/services/common/Service";

export class DictionariesService extends Service {
  public static async getOffers (params: any): Promise<AxiosResponse<any>> {
    const response = await this.rest.get("/webmaster/offers", { params });
    response.data.items = response.data.items.map(({
      id,
      name,
      intId
    }: any) => {
      return {
        id,
        name,
        intId
      };
    });

    return response;
  }

  public static async getDetailsFlows ({ limit, offset, filters }: any): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: landingFlows,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getBunches (params: any): AxiosPromise {
    return this.rest.get("/webmaster/statistics/filters/bunches", { params });
  }

  public static async getPaymentSystems (): Promise<AxiosResponse<PaymentSystemResponse>> {
    const { data } = await this.rest.get("/webmaster/dictionaries/payment-systems");
    const items = data.map((item: any) => new PaymentSystemFilter(item));
    const count = items.length;

    return {
      data: {
        items,
        count
      }
    } as any;
  }
}
