export enum AdvertisersDutyAnalyseGroup {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH"
}

export enum AdvertisersDutySort {
  DATE_START_BALANCE = "DATE_START_BALANCE",
  LEAD_WRITE_OFF = "LEAD_WRITE_OFF",
  CORRECTION = "CORRECTION",
  REFILL = "REFILL",
  TOTAL = "TOTAL",
  DATE_END_BALANCE = "DATE_END_BALANCE",
  PERCENT_BALANCE_CHANGE = "PERCENT_BALANCE_CHANGE"
}
