import { WebmasterDataByIdDataResponse, WebmasterDataByIdDataValue } from "@core/store/types/common/webmasterDataById";
import { Country } from "@core/store/types/common/statistic/common/statistic";
import { AdminsListItem } from "@core/store/types/admin/lists/AdminsListState";
import { Contact } from "@core/store/types/common/User";

export class WebmasterMiniCard implements WebmasterDataByIdDataResponse {
  public preferredCurrency?: string;
  public admins?: AdminsListItem;
  public registeredAt?: string;
  public avatarUrl?: string;
  public nameTeam?: string;
  public country?: Country;
  public contacts?: Contact;
  public isTeam?: boolean;
  public login?: string;
  public teamName?: string;
  public intId?: number;
  public id?: string;
  
  constructor (user?: WebmasterDataByIdDataValue) {
    if (user) {
      this.id = user.id;
      this.intId = user.intId;
      this.login = user.login;
      this.admins = user.admins;
      this.contacts = user.contact;
      this.country = user.country;
      this.isTeam = user.detail.isTeam;
      this.avatarUrl = user.avatar?.url;
      this.teamName = user.detail.teamName;
      this.registeredAt = user.activity?.registeredAt;
    }
  }
}
