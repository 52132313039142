<template>
    <ModalBox
        :is-active.sync="_isActive">
        <template #header>
            <h2
                class="title is-5">
                {{ $t("common.auth.signIn.component.signUpRoleModal.title") }}
            </h2>
        </template>
        <section class="columns">
            <div class="column is-flex-mobile is-justify-content-center">
                <Card>
                    <template #card-image>
                        <img
                            width="165"
                            alt="Advertiser"
                            src="@/assets/Auth/advertiser.svg">
                    </template>
                    <template
                        #card-content>
                        <h5 class="title is-5 mb-2">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.advertiser.title") }}
                        </h5>
                        <span class="mb-3">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.advertiser.description") }}
                        </span>
                        <b-button
                            size="is-small"
                            type="is-info"
                            @click="updateForm('advertiser')">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.button") }}
                        </b-button>
                    </template>
                </Card>
            </div>
            <div class="column is-flex-mobile is-justify-content-center">
                <Card>
                    <template #card-image>
                        <img
                            alt="Webmaster"
                            src="@/assets/Auth/webmaster.svg">
                    </template>
                    <template #card-content>
                        <h5 class="title is-5 mb-2">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.webmaster.title") }}
                        </h5>
                        <span class="mb-3">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.webmaster.description") }}
                        </span>
                        <b-button
                            size="is-small"
                            type="is-info"
                            @click="updateForm('webmaster')">
                            {{ $t("common.auth.signIn.component.signUpRoleModal.button") }}
                        </b-button>
                    </template>
                </Card>
            </div>
        </section>
    </ModalBox>
</template>
<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Card from "@/components/Common/Card";
  import { mapActions } from "vuex";
  import { UPDATE_FORM } from "@core/store/action-constants";

  export default {
    name: "SignUpRoleModal",
    components:{
      ModalBox,
      Card
    },
    props:{
      isActive:{
        type: Boolean,
        default: false
      }
    },
    computed: {
      _isActive: {
        get () {
          return this.isActive;
        },
        set (value) {
          this.$emit("update:isActive", value);
        }
      }
    },
    methods: {
      ...mapActions("signUp", [
        UPDATE_FORM
      ]),
      updateForm (userType){
        this.$router.push({ name: "auth:signUp" });
        this.UPDATE_FORM({ userType });
      }
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style scoped lang="scss">
    .card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background: $white-gray;
        height: 330px;
        max-width: 400px;
    }
    .card-image img {
        display: block;
        margin: 0 auto;
        width: 165px;
        height: 141px;
        padding-top: 20px;
    }
    ::v-deep {
        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          text-align: center;
        }
    }
    .card-content button {
        padding: 1.25rem 1em;
    }

    ::v-deep {
        .modal-card {
            width: calc(300px + 30vw);
            max-width: 650px;
        }
    }
</style>