<template>
    <section class="payment-section section has-text-centered has-text-right-desktop">
        <div class="columns is-vcentered is-variable is-5">
            <div class="column content is-marginless">
                <div class="level is-marginless">
                    <div class="level-left"></div>

                    <div class="level-right is-hidden-touch">
                        <figure class="image is-3by1 is-marginless">
                            <!--suppress HtmlUnknownTarget -->
                            <img
                                :alt="$saas.name"
                                :src="require(`@/assets/${ $saas.logo }`)">
                        </figure>
                    </div>

                    <div class="level-right is-hidden-desktop">
                        <figure class="image is-marginless">
                            <!--suppress HtmlUnknownTarget -->
                            <img
                                :alt="$saas.name"
                                :src="require(`@/assets/${ $saas.logo }`)">
                        </figure>
                    </div>
                </div>

                <h2 class="title is-2 has-text-danger is-marginless">
                    {{ $t(`common.transactionStatus.errorPayment.header`) }}
                </h2>
    
                <p>
                    <i18n path="common.transactionStatus.errorPayment.body">
                        <template #linebreak>
                            <br>
                        </template>
                    </i18n>
                </p>
    
                <b-button
                    :to="{ name: 'main' }"
                    rounded
                    tag="router-link"
                    type="is-success">
                    {{ $t(`common.transactionStatus.errorPayment.button`) }}
                </b-button>
            </div>
            <div class="column is-narrow">
                <figure class="image">
                    <!--suppress HtmlUnknownTarget -->
                    <img
                        alt="failed image"
                        src="icons/transactions/failed.svg">
                </figure>
            </div>
        </div>
    </section>
</template>

<script>
  export default {
    name: "ErrorPayment"
  };
</script>

<style scoped lang="scss">
    .title {
        line-height: inherit;
    }

    .image {
        //noinspection SassScssUnresolvedMixin
        @include touch {
            max-width: 60%;
            margin: auto !important;
        }

        &.is-1by1 {
            width: 175px;
        }

        &.is-3by1 {
            width: 124px;
        }
    }
</style>
