import { ReferralsFiltersService, ReferralsState } from "@core/store/types/webmaster/referrals";
import referrals from "@core/services/webmaster/referrals/graphql/referrals.graphql";
import myReferral from "@core/services/webmaster/referrals/graphql/myReferrals.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class ReferralService extends Service {
  public static getReferrals (
    limit: number,
    offset: number,
    sort: ReferralsState["sort"],
    order: ReferralsState["order"],
    filters: ReferralsFiltersService
  ): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: referrals,
          variables: {
            limit,
            offset,
            sort,
            order,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getMyReferrals (): AxiosPromise {
    try {
      return this.api.get("", {
        params: {
          query: myReferral
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
