import { detailing } from "@core/store/modules/admin/statistic/modules/detailing/detailing";
import { statistics as statisticsModule } from "@core/store/modules/admin/statistic/modules/statistics";
import { landingsHistory } from "@core/store/modules/admin/statistic/modules/landingsHistory";
import { AnalyticTargetAudience } from "@core/store/modules/admin/analytic/modules/analyticTargetAudience";
import { domonetization } from "@core/store/modules/admin/statistic/modules/domonetization";
import { registrations } from "@core/store/modules/admin/statistic/modules/registrations";

export const statistics = {
  namespaced: true,
  modules: {
    detailing,
    statistics: statisticsModule,
    landingsHistory,
    domonetization,
    registrations
  }
};