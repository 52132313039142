import { defineStore } from "pinia";
import { ref } from "@vue/composition-api";
import type { Centrifuge, Subscription } from "centrifuge";

export const useSystemOnline = (centrifuge: Centrifuge) => defineStore("", () => {
  const channel = ref<Subscription | null>(null);

  function subscribe () {
    if (channel.value) {
      centrifuge.removeSubscription(channel.value as Subscription);
      channel.value = null;
    }

    const sub = centrifuge.newSubscription("system:online");

    sub.on("subscribed", () => {
      channel.value = sub;
    });
    sub.on("unsubscribed", () => {
      $reset();
      centrifuge.removeSubscription(sub);
    });

    sub.subscribe();
  }

  function unsubscribe () {
    if (!channel.value) return;
    channel.value.unsubscribe();
  }

  function $reset () {
    channel.value = null;
  }

  return {
    subscribe,
    unsubscribe,
    $reset
  };
})();