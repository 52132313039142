import { WebmasterTableOfferModule } from "@core/store/modules/common/offer/webmasterTable";
import { webmastersFilters } from "@core/store/modules/admin/offer/lists/webmastersFilters";
import { State as StateWebmasters } from "@core/store/types/admin/offer/webmasters";
import { SET_EMPTY, UPDATE_FILTERS, UPDATE_OFFER_WEBMASTERS_FILTERS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";

class AdvertiserOfferWebmasterTableModule extends WebmasterTableOfferModule<StateWebmasters, RootState> {
  readonly initState: () => StateWebmasters;

  constructor () {
    super();

    this.namespaced = true;

    this.initState = (): StateWebmasters => {
      return {
        data: null,
        filters: {
          webmasterId: []
        },
        pagination: {
          page: 1,
          perPage: 10
        }
      };
    };

    this.state(this.initState());

    this.mutations({
      [SET_EMPTY]: (state: StateWebmasters): void => {
        Object.assign(state, this.initState());
      }
    });

    this.actions({
      [UPDATE_OFFER_WEBMASTERS_FILTERS] ({ dispatch }, filters) {
        dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filters);
      }
    });

    this.modules({
      webmastersFilters
    });

    return this;
  }
}

export default new AdvertiserOfferWebmasterTableModule().toModule();
