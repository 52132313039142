import { defineStore } from "pinia";
import { pagination } from "@core/helpers/pagination";
import { AdvertisersService } from "@core/services/admin/users/advertisers/AdvertisersService";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import {
  AdvertisersItem,
  AdvertisersState,
  EditableComment
} from "@core/store/types/admin/users/advertisers/AdvertisersState";
import { UsersService } from "@core/services/admin/users/common/UsersService";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { OrderEnum } from "@core/store/types/common/enums/OrderEnum";
import { AdvertisersSortEnum } from "@core/store/types/admin/users/advertisers/enums/AdvertisersSortEnum";
import { GET_ADVERTISERS, GET_ADVERTISERS_WITH_BALANCE } from "@core/store/action-constants";
import { Ref, unref } from "@vue/composition-api";
import { advertisersAdvancedFilters } from "@/stores/admin/users/advertisers/datasets/advertisersAdvancedFilters";

const initialEditableComment: AdvertisersState["editableComment"] = {
  userId: null,
  comment: null
};

export const useAdvertisers = defineStore("admin/users/advertisers", {
  state: () => ({
    data: null as PaginationOutput<AdvertisersItem[]> | null,
    filters: {
      isBlocked: false,
      loginOrIntId: null,
      isApproved: null,
      email: null,
      manager: null,
      ...advertisersAdvancedFilters
    },
    sort: null as AdvertisersSortEnum | null,
    order: null as OrderEnum | null,
    editableComment: initialEditableComment
  }),

  actions: {
    async [GET_ADVERTISERS] () {
      const { limit, offset } = pagination();

      this.data = await AdvertisersService.getAdvertisers(
        limit,
        offset,
        this.filters,
        this.sort,
        this.order
      );
    },

    async [GET_ADVERTISERS_WITH_BALANCE] () {
      await this.GET_ADVERTISERS();
    },

    UPDATE_ADVERTISERS_BALANCE () {
      this.data?.items?.forEach(({ id }) => this.ADVERTISER_BALANCE(id));
    },

    async ADVERTISER_BALANCE (id: string) {
      const balance = await UsersService.balance(id);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [{ balance, id }] });
    },

    async ADD_APPROVED_ADVERTISER (advertiserId: string, vertical: VerticalsEnum) {
      const item = await AdvertisersService.approveAdvertiser(advertiserId, vertical);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async ADD_ADVERTISER_PERSONAL_MANAGER ({ userId, adminIds }: { userId: string, adminIds: string[] | Ref<string[]> }) {
      const item = await AdvertisersService.bindAdvertiser(userId, { adminId: unref(adminIds)[0] });
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async DELETE_ADVERTISER_PERSONAL_MANAGER (userId: string) {
      const item = await AdvertisersService.bindAdvertiser(userId, { adminId: null });
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async BLOCK_ADVERTISER (advertiserId: string) {
      const item = await AdvertisersService.blockAdvertiser(advertiserId);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async UNBLOCK_ADVERTISER (advertiserId: string) {
      const item = await AdvertisersService.unblockAdvertiser(advertiserId);
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    async TOGGLE_BLOCK_ADVERTISER (isBlocked: boolean, advertiserId: string) {
      isBlocked ? await this.UNBLOCK_ADVERTISER(advertiserId) : await this.BLOCK_ADVERTISER(advertiserId);
    },

    async RESEND_EMAIL_BY_ADMIN (userId: string) {
      await UsersService.resendEmailByAdmin(userId);
    },

    async EDIT_ADVERTISER_ADMIN_COMMENT () {
      const { userId, comment } = this.editableComment as EditableComment;
      const item = await UsersService.updateComment(userId, { comment });
      useStateUpdater(useAdvertisers).LOCAL_UPDATE({ items: [item] });
    },

    EMPTY_ADVERTISER_EDITABLE_COMMENT () {
      this.editableComment = initialEditableComment;
    }
  }
});