import webmasters from "@core/store/modules/advertiser/offer/webmaster/advertiserOfferWebmastersTableModule";
import { offerConversionList } from "@core/store/modules/admin/offer/lists/offerConversionList";
import { AdvertiserOfferLandings } from "@core/store/modules/advertiser/offer/offerLandings";
import { OfferModule } from "@core/store/modules/common/offer/OfferModule";
import { OfferState } from "@core/store/types/advertiser/offer";
import { SET_EMPTY } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";

export class AdvertiserOfferModule extends OfferModule<OfferState, RootState> {
  private readonly initState: () => OfferState;
  
  constructor () {
    super();
    
    this.namespaced = true;

    this.initState = (): OfferState => {
      return {
        offer: null
      };
    };
    
    this.state(this.initState());

    this.mutations({
      SET_EMPTY: (state: OfferState): void => {
        Object.assign(state, this.initState());
      }
    });

    this.actions({
      SET_EMPTY ({ commit }): void {
        commit(SET_EMPTY);
        commit(`landings/${ SET_EMPTY }`);
        commit(`transits/${ SET_EMPTY }`);
        commit(`webmasters/${ SET_EMPTY }`);
        commit(`offerConversionList/${ SET_EMPTY }`);
      }
    });

    this.modules({
      landings: new AdvertiserOfferLandings("landing").toModule(),
      transits: new AdvertiserOfferLandings("transit").toModule(),
      offerConversionList,
      webmasters
    });
    
    return this;
  }
}

