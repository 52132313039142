import { UserContextStorage } from "@core/services/common/UserContextStorage";
import {
  SET_USER_CONTEXT_STORAGE,
  GET_USER_CONTEXT_STORAGE,
  SET_EMPTY, REMOVE_USER_CONTEXT_STORAGE
} from "@core/store/action-constants";
import { UPDATE_USER_CONTEXT_STORAGE } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { SetData, UserContextStorageState } from "@core/store/types/common/UserContextStorage";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Vue from "../../../../../main";
import _isEmpty from "lodash/isEmpty";

const initialState = (): UserContextStorageState => {
  return {
    data: {}
  };
};

const state: () => UserContextStorageState = initialState;

const getters: GetterTree<UserContextStorageState, RootState> = {
  storageFilters: (state) => state.data[`${ Vue.$route.path }/filters` as keyof UserContextStorageState["data"]],
  storageTable: (state) => state.data[`${ Vue.$route.path }/table` as keyof UserContextStorageState["data"]]
};

const mutations: MutationTree<UserContextStorageState> = {
  [GET_USER_CONTEXT_STORAGE] (state, postfix) {
    const namespace = `${ Vue.$route.path }/${ postfix }`;
    const parseData = JSON.parse(UserContextStorage.getItem(namespace) ?? "null");

    // if (parseData) {
    Vue.$set(state.data, namespace, parseData);
    // }
  },

  [UPDATE_USER_CONTEXT_STORAGE] (state, { data, postfix }) {
    const namespace = `${ Vue.$route.path }/${ postfix }`;
    if (data) {
      UserContextStorage.setItem(namespace, JSON.stringify(data));
    }
  },

  [REMOVE_USER_CONTEXT_STORAGE] (_, postfix) {
    const namespace = `${ Vue.$route.path }/${ postfix }`;
    UserContextStorage.removeItem(namespace);
  },

  [SET_EMPTY] (state, postfix) {
    const namespace = `${ Vue.$route.path }/${ postfix }`;
    delete state.data[namespace as keyof UserContextStorageState["data"]];
  }
};

const actions: ActionTree<UserContextStorageState, RootState> = {
  [GET_USER_CONTEXT_STORAGE] ({ commit }, postfix) {
    commit(GET_USER_CONTEXT_STORAGE, postfix);
  },
  
  [SET_USER_CONTEXT_STORAGE] ({ commit }, { data, postfix }: SetData) {
    commit(UPDATE_USER_CONTEXT_STORAGE, { data, postfix });
  },

  [REMOVE_USER_CONTEXT_STORAGE] ({ commit }, postfix) {
    commit(REMOVE_USER_CONTEXT_STORAGE, postfix);
  }
};

export const userContextStorage: Module<UserContextStorageState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
