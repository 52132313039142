import WebmastersIdHistory from "./graphql/WebmastersIdHistory.graphql";
import generateNewId from "./graphql/generateNewId.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class WebmastersIdHistoryService extends Service {
  public static async getHistory (offerId: string, webmasterId: string): Promise<AxiosPromise> {
    try {
      const { data: { offer: { webmasters: { items: [ { webmasterIntegrationIdHistory } ] } } } } =
        await this.api.post("", {
          query: WebmastersIdHistory,
          variables: {
            offerId,
            webmasterId
          }
        });
      return webmasterIntegrationIdHistory;
    } catch (e) {
      throw e;
    }
  }
  
  public static async generateNewId (offerId: string, webmasterId: string) {
    try {
      await this.api.post("", {
        query: generateNewId,
        variables: {
          offerId,
          webmasterId
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
