import { AxiosError } from "axios";

function generateErrorWithFormattedMessage (error: { type: string; message: string }) {
  return {
    type: error.type,
    message: error.message
      .replace(/[\s]+/gm, "_")
      .replace(/[\W\d]*/gm, "")
      .toUpperCase()
      
  };
}

export class BackendError extends Error {
  constructor (error: AxiosError) {
    super(error.message);
    
    if (error?.response) {
      const { data, status, statusText } = error.response;
      let errors;
  
      // TODO: удалить эту проверку после полного перехода на gQL
      // Проверяем что ошибка в gQL запросе и форматируем ошибку в привычный вид
      if (data?.errors) {
        errors = data.errors;
      } else {
        const { type, message } = data;
        
        // Если ошибка в ресте приводим ее к виду gQL
        errors = [{
          type,
          message
        }];
      }
      
      const newError = {
        status, statusText,
        errors: errors.map(generateErrorWithFormattedMessage)
      };
      
      return Object.assign(this, newError);
    }
  }
}
