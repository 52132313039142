import Service from "@core/services/common/Service";
import postbackQueueQuery from "./graphql/postbackQueue.graphql";
import { StatisticPostback } from "@core/logic/webmaster/statistic/StatisticPostback";
import {
  PostbackFiltersRequest,
  PostbackItemResponse,
  PostbackResponse
} from "@core/store/types/webmaster/statistic/modules/postbacks";

export class PostbacksService extends Service {
  public static async getPostbackQueue (limit: number, offset: number, filters: PostbackFiltersRequest): Promise<PostbackResponse> {
    try {
      const { data: { postbackQueue } } = await this.api.get("", {
        params: {
          query: postbackQueueQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      postbackQueue.items = postbackQueue.items.map((item: PostbackItemResponse) => new StatisticPostback(item));
      return postbackQueue;
    } catch (e) {
      throw e;
    }
  }
}
