import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";

export class UpdateService extends Service{
  public static getVersion (): AxiosPromise {
    return this.rest.get("/version.json", {
      baseURL: ""
    });
  }
}
