import { AdminOfferModule, OfferState } from "@core/store/modules/admin/offer/AdminOfferModule";
import { Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { edit } from "@core/store/modules/admin/offer/modules/edit";
import { OfferLandingsList } from "@core/store/modules/admin/offer/modules/offerLandings/offerLandingsList";
import webmasters from "@core/store/modules/admin/offer/webmastersTable/AdminOfferWebmastersTableModule";
import { offerConversionList } from "@core/store/modules/admin/offer/lists/offerConversionList";

const offer = new AdminOfferModule().toModule();
offer.namespaced = false;

export const AdminOffer: Module<OfferState, RootState> = {
  namespaced: true,
  modules: {
    offer,
    edit,
    webmasters,
    offerConversionList,
    landings: new OfferLandingsList("landing").toModule(),
    transits: new OfferLandingsList("transit").toModule()
  }
};
