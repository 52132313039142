<template>
    <router-link
        :to="{ name: hasAdmin ? setActiveSection : 'main' }"
        class="logo">
        <!--suppress HtmlUnknownTarget -->
        <img
            :alt="$saas.name"
            class="logo-image is-main"
            :src="logo">
    </router-link>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "Logo",

    computed: {
      ...mapState("theme", {
        currentTheme: ({ currentTheme }) => currentTheme
      }),

      ...mapState({
        hasAdmin: ({ auth }) => auth.role === "admin",
        activeSections: state => state.auth.activeSections
      }),

      logo () {
        const name = this.currentTheme === "dark" ? "logo_dark" : "logo";
        return require(`@/assets/${ this.$saas[name] }`);
      },

      setActiveSection () {
        return this.activeSections?.[0];
      }
    }
  };
</script>

<style scoped lang="scss">
    .logo {
        position: relative;
        height: $navbar-height;
        display: flex;
        align-items: center;
        padding-right: 16px;

        .is-main, &-image-square {
            width: 100%;
            object-fit: cover;
        }

        .is-main {
            //height: 1.45rem;
        }

        &-image-square {
            height: $navbar-height;
        }
    }
</style>
