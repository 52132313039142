import i18n from "@core/plugins/i18n";
import { ToastProgrammatic as Toast } from "buefy";
import { BNotificationConfig } from "buefy/types/components";
import { Action, ValuesI18N } from "@/store/types/common/actionWithToast";
import Vue from "vue";

export async function actionWithToast (action: Action | Promise<void>, namespace: string, options?: BNotificationConfig, values?: ValuesI18N): Promise<Action | void> {
  try {
    const response = await action;
    const fullPath = `${ namespace }.success`;
    const message = i18n.te(fullPath)
      ? i18n.t(fullPath, values)
      : i18n.t("common.messages.requestSuccess");
    
    Toast.open({
      // @ts-ignore
      message,
      type: "is-success",
      ...options
    });
    
    return response;
    
  } catch (e: any) {
    const fullPath = Array.isArray(e?.errors) ?
      `${ namespace }.${ e.errors[0]?.message }` :
      `${ namespace }.error`;
    
    const message = i18n.te(fullPath) ?
      i18n.t(fullPath, values) :
      i18n.t("common.messages.requestFail");
    
    Toast.open({
      // @ts-ignore
      message,
      type: "is-danger",
      ...options
    });
    
    throw e;
  }
}

Vue.filter("actionWithToast", actionWithToast);