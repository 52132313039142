import { AnalyticService } from "@core/services/admin/analytic/AnalyticService";
import { SET_INCIDENCE_ANALYSE, UPDATE_FILTERS } from "@core/store/mutation-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { GET_INCIDENCE_ANALYSE, SET_EMPTY } from "@core/store/action-constants";
import { WebmastersFilters } from "@core/store/modules/admin/statistic/modules/common/analyticWebmastersFilters";
import { AnalyticAnalyseChartState } from "@core/store/types/admin/analytic/analyticAnalyseChart";
import { partnerNetworkList } from "@core/store/modules/admin/lists/PartnerNetworkList";
import { userTypeList } from "@core/store/modules/admin/lists/userType";
import { advertisersList } from "@core/store/modules/admin/lists/advertisersList";
import { OfferFilters } from "@core/store/modules/common/lists/offersList";
import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
import { analyticAnalyseChartDetail } from "@core/store/modules/admin/analytic/modules/analiticAnalyseChartDetail";
import { fixedFormatPercent } from "@core/flowMethods";

const initialState = (): AnalyticAnalyseChartState => {
  return {
    data: null,
    filters: {
      datepicker: datepicker({ amount: 2, unit: "day", amountEnd: 1, unitEnd: "day" }),
      withBlockedWebmaster: false,
      isDomesticWebmaster: null,
      isPartnerNetwork: null,
      advertiserId: null,
      webmasterId: null,
      managerId: null,
      countryId: null,
      isInHouse: null,
      regionId: null,
      offerId: null
    }
  };
};

const state: () => AnalyticAnalyseChartState = initialState;

const mutations: MutationTree<AnalyticAnalyseChartState> = {
  [SET_EMPTY] (state, target?: string) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  [SET_INCIDENCE_ANALYSE] (state, data: AnalyticAnalyseChartState["data"]): void {
    state.data = data;
  },
  
  [UPDATE_FILTERS]: (state, filters: AnalyticAnalyseChartState["filters"]): void => {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<AnalyticAnalyseChartState, RootState> = {
  async [GET_INCIDENCE_ANALYSE] ({ state, commit }): Promise<void> {
    const { filters: { datepicker, ...fields } } = state;
    const filters = {
      ...datepickerFormat(datepicker, "date", true),
      ...fields
    };
    
    const { data: { incidenceAnalyse } } = await AnalyticService.getIncidenceAnalyse(filters);
    
    const total = incidenceAnalyse.count;
    incidenceAnalyse.items = incidenceAnalyse.items?.map(({ diff, reason }) => {
      return {
        value: fixedFormatPercent(diff / total),
        diff,
        reason
      };
    });
  
    commit(SET_INCIDENCE_ANALYSE, incidenceAnalyse);
  },
  
  [UPDATE_FILTERS]: ({ commit }, filters): void => {
    commit(UPDATE_FILTERS, filters);
  },
  
  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
    commit(`webmastersFilters/${ SET_EMPTY }`);
    commit(`offersFilters/${ SET_EMPTY }`);
  }
};

export const analyticAnalyseChart: Module<RootState, AnalyticAnalyseChartState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    partnerNetworkList,
    webmastersFilters: new WebmastersFilters(),
    offersFilters: new OfferFilters(),
    userTypeList,
    advertisersList,
    analyticAnalyseChartDetail
  }
};