<template>
    <div>
        <section
            v-if="isActive"
            class="no-adblock section has-text-centered has-text-right-desktop">
            <div class="columns is-vcentered is-variable is-5 reverse-columns">
                <div class="column content is-marginless">
                    <div class="level is-marginless">
                        <div class="level-left"></div>
                    
                        <div class="level-right is-hidden-touch">
                            <figure class="image is-3by1 is-marginless">
                                <!--suppress HtmlUnknownTarget -->
                                <img
                                    :alt="$saas.name"
                                    :src="require(`@/assets/${ $saas.logo }`)">
                            </figure>
                        </div>

                        <div class="level-item is-hidden-desktop">
                            <figure class="image is-marginless">
                                <!--suppress HtmlUnknownTarget -->
                                <img
                                    :alt="$saas.name"
                                    :src="require(`@/assets/${ $saas.logo }`)">
                            </figure>
                        </div>
                    </div>

                    <h2 class="title is-2 has-text-danger is-marginless">
                        {{ $t("common.noAdBlock.header") }}
                    </h2>

                    <p>
                        <i18n path="common.noAdBlock.body.content">
                            <template #strong>
                                &ndash; <span
                                    v-t="'common.noAdBlock.body.particles.strong'"
                                    class="has-text-weight-bold"></span>
                            </template>
                            <template #actionFirst>
                                <span
                                    v-t="'common.noAdBlock.body.particles.actionFirst'"
                                    class="has-text-weight-medium"></span>
                            </template>
                            <template #actionSecond>
                                <span
                                    v-t="'common.noAdBlock.body.particles.actionSecond'"
                                    class="has-text-weight-medium"></span>
                            </template>
                            <template #linebreak>
                                <br>
                            </template>
                        </i18n>
                    </p>
                    <!--                    <section>-->
                    <!--                        <p>-->
                    <!--                            <b-button expanded type="is-success is-light" icon-left="redo-alt"-->
                    <!--                                      @click="reload">-->
                    <!--                                Я отключил AdBlock и хочу перезагрузить страницу-->
                    <!--                            </b-button>-->
                    <!--                        </p>-->
                    <!--                        <p>-->
                    <!--                            <b-button expanded type="is-warning is-light" icon-left="question-circle"-->
                    <!--                                      @click="reload">-->
                    <!--                                Я не знаю как отключить AdBlock и мне нужна помощь-->
                    <!--                            </b-button>-->
                    <!--                        </p>-->
                    <!--                    </section>-->
                </div>
                <div class="column is-narrow">
                    <figure class="image is-1by1 is-marginless">
                        <!--suppress HtmlUnknownTarget -->
                        <img
                            alt="AdBlock logo"
                            src="icons/adblock.svg">
                    </figure>
                </div>
            </div>
        </section>
        <div
            id="textAd"
            :style="baitCSS">
            Adblock detector
        </div>
    </div>
</template>

<script>
  export default {
    name: "NoAdBlock",

    computed: {
      baitCSS () {
        return {
          width: "1px !important",
          height: "1px !important",
          top: "-1000px !important",
          left: "-10000px !important",
          position: "absolute !important"
        };
      },

      isRequestBlocked: {
        get () {
          return this.$store.state.adblock.isRequestBlocked;
        },
        set (value) {
          this.$store.commit("SET_IS_REQUEST_BLOCKED", value);
        }
      },

      isActive: {
        get () {
          return this.$store.state.adblock.isActive;
        },
        set (value) {
          this.$store.commit("SET_IS_ACTIVE", value);
        }
      }
    },

    methods: {
      detectAdb () {
        const detector = document.getElementById("textAd");
        const elStyle = detector.currentStyle || window.getComputedStyle(detector);
        this.isActive = elStyle.display === "none";
        this.isRequestBlocked = false;
      }

      // reload () {
      //   location.reload();
      // }
    },

    watch: {
      isRequestBlocked (value, oldValue) {
        if (value && !oldValue) {
          this.detectAdb();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .no-adblock {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: #fefefe;
    }

    .title {
        line-height: inherit;
    }

    .image {
        //noinspection SassScssUnresolvedMixin
        @include touch {
            max-width: 60%;
            margin: auto !important;
        }

        &.is-1by1 {
            width: 175px;
        }

        &.is-3by1 {
            width: 124px;
        }
    }

    .reverse-columns {
        display: flex;
        flex-direction: column-reverse;

        //noinspection SassScssUnresolvedMixin
        @include desktop {
            flex-direction: row;
        }
    }
</style>
