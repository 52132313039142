import { defineStore } from "pinia";
import { computed, ref, watch } from "@vue/composition-api";
import { useWebsockets } from "@/stores/common/auth/socketsStore";
import { TrafficService } from "@core/services/admin/traffic/TrafficService";
import { TrafficRequestChatItem, TrafficRequestUnificationItem } from "@core/store/types/admin/traffic/trafficRequest";
import { TrafficRequestStatusEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStatusEnum";
import { useTrafficRequest } from "@/stores/admin/traffic/trafficRequestStore";

export const useTrafficRequestChatModal = defineStore("admin/efficiency/trafficRequestChat", () => {
  const socketStore = useWebsockets();
    
  const isActive = ref(false);
  const data = ref<TrafficRequestChatItem[]>([]);
  const modal = ref<Partial<TrafficRequestUnificationItem>>({});

  const chat = computed(() => [...data.value, ...socketStore.trafficChat.data]);
  const isReadonly = computed(() => modal.value.status !== TrafficRequestStatusEnum.AWAITING);

  function chatSubscribe () {
    socketStore.trafficChat.subscribe(modal.value.intId!);
  }

  function chatUnsubscribe () {
    socketStore.trafficChat.unsubscribe();
  }

  async function getHistoryChat () {
    const store = useTrafficRequest();
    const { datepicker, recommendationStatus, termsStatus, ...filters } = store.filters;
    const status = typeof recommendationStatus === "object" ? recommendationStatus : { recommendationStatus };
    data.value = await TrafficService.getTrafficRequestChat({ id: [modal.value.id!], ...datepicker, ...termsStatus, ...status, ...filters });
  }

  async function sendMessage (message: string) {
    await TrafficService.addNewMessageToChat(modal.value.id!, { message });
  }

  function $reset () {
    isActive.value = false;
    data.value = [];
    modal.value = {};
  }
  // Автоматическая подписка и отписка от чата
  watch(() => isActive.value, active => {
    active ? chatSubscribe() : chatUnsubscribe();
  });
  
  return {
    isActive,
    chat,
    modal,
    isReadonly,
    chatSubscribe,
    chatUnsubscribe,
    getHistoryChat,
    sendMessage,
    $reset,
    GET_HISTORY_CHAT: "getHistoryChat",
    SEND_MESSAGE: "sendMessage"
  };
});