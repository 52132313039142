<template>
    <span class="nowrap has-text-left user-wrapper">
        <MiniCardTooltip
            v-if="activeDropdown && offerIntId && role === 'admin'"
            :is-loading="isLoading"
            :current-item="item"
            @onShow="onShow"
            @imageClick="viewOffer">
            <template #image>
                <!-- Не будет выводиться если нет intId -->
                <img
                    v-if="isShowCommissions"
                    alt="offer"
                    :class="{ 'avatar-external': avatar }"
                    :src="hasAvatar">
            </template>

            <template #content>
                <div class="card-profile">
                    <div class="card-profile_header">
                        <div class="is-align-items-center">
                            <img
                                :src="logo"
                                alt=""
                                class="card-profile_header_avatar">
                            <span class="card-profile_header_name">
                                {{ item.name }}
                            </span>
                        </div>
                        <span
                            class="show-more ml-3"
                            @click="viewOffer">
                            {{ $t("common.buttons.more") }}
                        </span>
                    </div>

                    <div class="divider"></div>

                    <div
                        v-if="isShowCommissions"
                        class="card-profile_body_content">
                        <span class="card-profile_body_content_name">
                            {{ `${ $t('common.entity.offerCard.miniCard.webmasterReward') }:` }}
                        </span>
                        {{ fixedCurrency(item.webmasterReward) }}
                    </div>

                    <div class="card-profile_body">
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.offerCard.miniCard.countries') }:` }}
                            </span>
                            <Countries
                                :items="item.countries"
                                compact
                                :interactive="false"
                                tooltip-position="is-top">
                            </Countries>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.withdrawType.withdrawType') }:` }}
                            </span>
                            <WithdrawTypeOutput :value="{ withdrawType: item.withdrawType, subGoalType: item.subGoalType }"></WithdrawTypeOutput>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.offerCard.miniCard.type') }:` }}
                            </span>
                            {{ $t(`common.entity.offer.offerTypes.${ item.type }`) }}
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.offerCard.miniCard.launched') }:` }}
                            </span>
                            <ColoredStatus
                                :type="item.isLaunched ? 'is-success' : 'is-danger'"
                                position="before"
                                tooltip-position="is-bottom"
                                tooltip-type="is-dark">
                                {{ $t(`common.entity.offerCard.miniCard.isLaunched.${ item.isLaunched }`) }}
                            </ColoredStatus>
                        </div>
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.offerCard.miniCard.categories') }:` }}
                            </span>
                            <CategoriesCompactList :items="item.categories"></CategoriesCompactList>
                        </div>
                    </div>
                </div>
            </template>
        </MiniCardTooltip>

        <CanCopy
            :value="canCopy"
            :prevent-copy="!copy">
            <OfferOutput :offer="offer"></OfferOutput>
        </CanCopy>
    </span>
</template>

<script>
  import MiniCardTooltip from "@/components/Common/Tooltip/MiniCardTooltip.vue";
  import ColoredStatus from "@/components/Common/ColoredStatus.vue";
  import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
  import Countries from "@/components/Common/Lists/Countries.vue";
  import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
  import { GET_OFFER_BY_INT_ID } from "@core/store/action-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  import CategoriesCompactList from "@/components/Common/Lists/CategoriesCompactList.vue";
  import { fixedCurrency } from "@core/flowMethods";
  import WithdrawTypeOutput from "@/components/Common/Output/WithdrawTypeOutput.vue";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "CustomOfferOutput",
    components: {
      WithdrawTypeOutput,
      MiniCardTooltip,
      ColoredStatus,
      OfferOutput,
      Countries,
      CategoriesCompactList,
      CanCopy
    },
    props: {
      position: {
        type: String,
        default: "is-bottom"
      },
      offer: {
        type: Object,
        default: () => {}
      },
      activeDropdown: {
        type: Boolean,
        default: true
      },
      copy: {
        type: Boolean,
        default: true
      },
      avatar: {
        type: Boolean,
        default: false
      }
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapState("admin/offerDataByIntId", {
        data: ({ data }) => data
      }),

      ...mapGetters(["role"]),

      item () {
        const { offerIntId, data } = this;
        return data?.[offerIntId];
      },

      logo () {
        const { item } = this;
        const regex = /(\.mp4)/gi;

        if (item.logoUrl && !regex.test(item.logoUrl)) {
          return item.logoUrl;
        } else if(item.previewLogoUrl) {
          return item.previewLogoUrl;
        }
        return require(`@/assets/Offer/${ this.item.vertical }/Small.png`);
      },

      isLoading () {
        return this.$wait(`admin/offerDataByIntId/${ GET_OFFER_BY_INT_ID }`);
      },

      offerIntId () {
        return this.offer?.intId;
      },

      offerId () {
        return this.offer.id || this.item.id;
      },

      offerSlug () {
        return this.offer.slug || this.item.slug;
      },

      avatarExternal () {
        return this.offer.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      hasAvatar () {
        return this.avatar ? this.avatarExternal : require("@/assets/Notepad.svg");
      },

      canCopy () {
        const offer = this.offer;
        if (offer?.name) {
          return `${ offer.name }`;
        } return null;
      }
    },

    methods: {
      fixedCurrency,

      ...mapActions("admin/offerDataByIntId", {
        GET_OFFER_BY_INT_ID
      }),

      viewOffer () {
        if (this.offerSlug) {
          const { href } = this.$router.resolve(
            {
              name: "admin:offers:offer",
              params: {
                id: this.offerId,
                slug: this.offerSlug,
                backLink: this.$route.fullPath
              }
            });
          window.open(href, "_blank");
        }
      },

      onShow () {
        const { offerIntId, activeDropdown, item, isLoading } = this;

        if (!item && offerIntId && !isLoading && activeDropdown) {
          this.GET_OFFER_BY_INT_ID(offerIntId);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/user-mini-card";

    .show-more {
        color: $info;
        cursor: pointer;
    }
</style>
