import _debounce from "lodash/debounce";

// @vue/components
export const DebounceUpdateByWatchedParams = {
  computed: {
    debounceUpdate_ () {
      return _debounce(this.updated, 400, { trailing: true });
    },
    
    stringifyUpdateParams () {
      return JSON.stringify(this.updateParams);
    }
  },
  
  watch: {
    stringifyUpdateParams: {
      immediate: true,
      handler (value) {
        this.debounceUpdate_(value);
      }
    }
  }
};
