import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import {
  DashboardLeadLaunchActivityWidget
} from "@core/store/types/admin/dashboard/dashboardStatisticWidget";
import {
  UPDATE_DASHBOARD_LAUNCH_ACTIVITY_WIDGET,
  SET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET,
  UPDATE_PAGINATION,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import {
  GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET,
  SET_EMPTY
} from "@core/store/action-constants";
import { DashboardStatisticWidgetService } from "@core/services/admin/dashboard/dashboardStatisticWidgetService";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): DashboardLeadLaunchActivityWidget => {
  return {
    leadLaunchActivityWidget: null,
    filters: {
      datepicker: datepicker({}, "dateTime", { startOf: "month" })
    },
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => DashboardLeadLaunchActivityWidget = initialState;

const mutations: MutationTree<DashboardLeadLaunchActivityWidget> = {
  [SET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET] (state, leadLaunchActivityWidget: DashboardLeadLaunchActivityWidget["leadLaunchActivityWidget"]): void {
    state.leadLaunchActivityWidget = leadLaunchActivityWidget;
  },

  [UPDATE_DASHBOARD_LAUNCH_ACTIVITY_WIDGET] (state, payload: DashboardLeadLaunchActivityWidget["leadLaunchActivityWidget"]): void {
    state.leadLaunchActivityWidget = {
      count: payload?.count,
      // @ts-ignore
      items: [...state.leadLaunchActivityWidget?.items, ...payload?.items]
    };
  },

  [UPDATE_FILTERS] (state, filters): void {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_PAGINATION] (state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  },

  [SET_EMPTY] (state, target?: DashboardLeadLaunchActivityWidget) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<DashboardLeadLaunchActivityWidget, RootState> = {
  async [GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET] ({ state, commit }): Promise<void> {
    const { filters: { datepicker, ...otherFields }, pagination: { perPage: limit, page } } = state;
    const filters = { ...datepicker, ...otherFields };
    const offset = (page - 1) * limit;

    const data = await DashboardStatisticWidgetService.getLeadLaunchActivityWidget(limit, offset, filters);

    commit(offset === 0
      ? SET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET
      : UPDATE_DASHBOARD_LAUNCH_ACTIVITY_WIDGET, data);
  },

  [UPDATE_PAGINATION] ({ commit }, pagination) {
    commit(UPDATE_PAGINATION, pagination);
  },

  [UPDATE_FILTERS] ({ commit, dispatch }, options): void {
    const pagination = { page: 1 };

    commit(UPDATE_FILTERS, options);
    commit(UPDATE_PAGINATION, pagination);
    dispatch(GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const dashboardLaunchActivityWidget: Module<DashboardLeadLaunchActivityWidget, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
