import Service from "@core/services/common/Service";
import subAccountWebmasters from "./graphql/subAccountWebmasters.graphql";
import { WebmastersState } from "@core/store/types/admin/users/webmasters/WebmastersState";

export class SubAccountsService extends Service {
  public static async getSubAccountWebmasters (
    limit: number,
    offset: number,
    filters: WebmastersState["filters"],
    sort?: WebmastersState["sort"],
    order?: WebmastersState["order"]
  ) {
    try {
      const { data: { webmasters: { items } } } = await this.api.get("", {
        params: {
          query: subAccountWebmasters,
          variables: {
            limit,
            offset,
            filters,
            sort,
            order
          }
        }
      });
      
      const webmasters = items[0].webmasters;
      return { items: webmasters, count: webmasters.length };
    } catch (e) {
      throw e;
    }
  }
}
