import { Filters } from "../../common/generators/filters";
import {
  UserItem,
  FilterInput,
  BaseFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { CitiesService } from "@core/services/common/lists/CitiesService";

export class CitiesFilters extends Filters<UserItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { cities, initialCities } } = await CitiesService.getCitiesFilters(filters, initialFilters, limit, offset);

    return {
      data: { items: cities.items, count: cities.count },
      initialData: { items: initialCities.items, count: initialCities.items?.length }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}
