<template>
    <ModalBox
        :is-active="isModalActive"
        @close="onClose">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`common.entity.avatar.header.title`) }}
            </h5>
        </template>

        <template v-if="!localAvatarUrl">
            <b-upload
                v-if="!localAvatarUrl"
                accept="image/*"
                drag-drop
                @input="updateData">
                <section class="section">
                    <div class="content has-text-centered empty-file">
                        <InlineSvg
                            :src="image"
                            alt="upload-file"
                            aria-label="upload-file">
                        </InlineSvg>
                        <p>  {{ $t("common.entity.avatar.body.description") }}</p>
                    </div>
                </section>
            </b-upload>
            <div class="flex is-justify-content-center">
                <b-upload
                    accept="image/*"
                    @input="updateData">
                    <a class="button is-info">
                        {{ $t(`common.entity.avatar.body.selectFile`) }}
                    </a>
                </b-upload>
            </div>
        </template>
        <template v-else>
            <div class="flex is-align-items-center is-justify-content-center">
                <div class="cropper-wrapper">
                    <div
                        :style="{ backgroundImage: `url(${ localAvatarUrl })` }"
                        class="image-background">
                    </div>
                    <Cropper
                        ref="cropper"
                        class="cropper"
                        :src="localAvatarUrl"
                        :stencil-props="{
                            previewClass: 'preview'
                        }"
                        auto-zoom
                        stencil-component="circle-stencil">
                    </Cropper>
                    <div class="cropper-buttons">
                        <b-button
                            type="is-dark"
                            @click="rotate(-90)">
                            <img
                                src="@/assets/UploadAvatar/arrow-rotate.svg"
                                alt="">
                        </b-button>
                        <b-button
                            type="is-dark"
                            @click="rotate(90)">
                            <img
                                src="@/assets/UploadAvatar/arrow-rotate.svg"
                                alt="">
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="flex fle is-justify-content-center mt-5">
                <b-upload
                    accept="image/*"
                    @input="updateData">
                    <a class="button is-info is-light m-1">
                        {{ $t(`common.entity.avatar.body.downloadAvatar`) }}
                    </a>
                </b-upload>
                <b-button
                    type="is-info"
                    class="m-1"
                    :loading="isLoading"
                    @click="saveChanges">
                    {{ $t("common.entity.avatar.body.saveChanges") }}
                </b-button>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { Cropper } from "vue-advanced-cropper";
  import "vue-advanced-cropper/dist/theme.bubble.css";
  import "vue-advanced-cropper/dist/style.css";
  import InlineSvg from "vue-inline-svg";
  import { mapActions, mapState } from "vuex";
  import {
    UPDATE_AVATAR,
    UPDATE_MODAL_ACTIVE,
    UPDATE_MODAL_OPTIONS
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { i18n } from "@core/plugins";
  export default {
    name: "UserAvatarModal",

    components: {
      ModalBox,
      InlineSvg,
      Cropper
    },

    data () {
      return {
        localAvatarUrl: null
      };
    },

    computed: {
      ...mapState("userAvatarModal", {
        isModalActive: ({ isModalActive }) => isModalActive
      }),

      ...mapFields("userAvatarModal", {
        fields: ["avatarUrl"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      image () {
        return require("@/assets/UploadAvatar/upload-file.svg");
      },

      isLoading () {
        return this.$wait(`userAvatarModal/${ UPDATE_AVATAR }`);
      }
    },

    methods: {
      ...mapActions("userAvatarModal", {
        UPDATE_MODAL_ACTIVE,
        UPDATE_AVATAR
      }),

      rotate (angle) {
        this.$refs.cropper.rotate(angle);
      },

      saveChanges () {
        try {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob(async (blob) => {
              const form = new FormData();
              form.append("file", blob, "img.png");
              form.append("visibility", "public");
              await this.UPDATE_AVATAR(form);

              this.onClose();
              this.$buefy.toast.open({
                message: this.$t("common.entity.avatar.messages.saveChanges"),
                type: "is-success"
              });
            }, "image/jpeg", 0.7);
          }
        } catch ({ errors }) {
          //TODO: Вынести Метод translatedName из компонента NameTranslationOutput и подключить глобально
          const translatedName = `common.entity.avatar.messages.${ errors[0].message }`;

          if(i18n.te(translatedName)) {
            this.$buefy.toast.open({
              message: i18n.t(translatedName),
              type: "is-danger"
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("common.files.toasts.fail"),
              type: "is-danger"
            });
          }
        }
      },

      updateData (value) {
        if (value != null) {
          const reader = new FileReader();
          reader.readAsDataURL(value);

          setTimeout(() => {
            this.localAvatarUrl = reader?.result;
          }, 20);
        }
      },

      onClose () {
        this.UPDATE_MODAL_ACTIVE(false);
        this.localAvatarUrl = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .animation-content {
            width: 100%;
            max-width: 500px !important;

            .modal-card {
                width: 100%;
                max-width: none;
                border: none;
            }
        }

        .preview {
            border: 2px solid #FFBA41;
        }

        .vue-advanced-cropper {
            &__foreground {
                opacity: 0.32;
            }

            &__background {
                background: transparent;
            }
        }

        .vue-bounding-box div:nth-child(2) {
            display: none;
        }

        .upload {
            .upload-draggable {
                padding: 0;
                border: 0;

                .section {
                    padding: 1rem 3rem;
                }

                path, circle {
                    transition: all .1s ease-in-out;
                }

                &:hover.is-primary,
                &.is-hovered.is-primary {
                    background: transparent;
                }

                &:hover path,
                &.is-hovered path {
                    stroke: $sky-blue;
                }

                &:hover circle,
                &.is-hovered circle {
                    fill: #E7EBFD;
                }
            }
        }
    }

    .cropper,
    .cropper-wrapper {
        max-width: 280px;
        width: 100%;
        height: 330px;
    }

    .cropper-wrapper {
        position: relative;
        background: white;
        overflow: hidden;
    }

    .cropper-buttons {
        position: absolute;
        bottom: 6px;
        right: 6px;

        button {
            background-color: rgb(0 0 0 / 50%) !important;
            padding: 0.4rem;
            height: 1.8rem;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                img {
                    transform: scaleX(-1);
                }
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .image-background {
        position: absolute;
        left: -10px;
        top: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);

        background-size: cover;
        background-position: 50%;
        filter: blur(5px);
    }
</style>
