import { competitionRouletteModal } from "@core/store/modules/admin/competition/competitionRouletteModal";
import { CompetitionOptionsState } from "@core/store/types/admin/competition";
import { SET_EMPTY, UPDATE_OPTIONS } from "@core/store/action-constants";
import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";

const initialState = (): CompetitionOptionsState => {
  return {
    options: {
      mediaTick: null,
      mediaCelebration: null
    }
  };
};

export const state: () => CompetitionOptionsState = initialState;

const mutations: MutationTree<CompetitionOptionsState> = {
  [UPDATE_OPTIONS] (state, payload) {
    state.options = { ...state.options, ...payload };
  },
  
  [SET_EMPTY] (state, target?: keyof CompetitionOptionsState): void {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<CompetitionOptionsState, RootState> = {
  [UPDATE_OPTIONS] ({ commit }, payload) {
    commit(UPDATE_OPTIONS, payload);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit(SET_EMPTY, target);
  }
};

export const competition: Module<CompetitionOptionsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    competitionRouletteModal
  }
};
