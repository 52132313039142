import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import postbackUrlList from "./graphql/postbackUrlList.graphql";
import removePostbackUrl from "./graphql/removePostbackUrl.graphql";
import editPostbackUrl from "./graphql/editPostbackUrl.graphql";
import createPostbackUrl from "./graphql/createPostbackUrl.graphql";
import { CreateInput, CreateResponse, DeleteResponse, EditInput, EditResponse, PostbacksResponse } from "@core/store/types/webmaster/postbacks/postback";

export class PostbacksService extends Service {
  public static getPostbacks (offerId?: string): AxiosPromise<PostbacksResponse> {
    return this.api.get("", {
      params: {
        query: postbackUrlList,
        variables: {
          offerId
        }
      }
    });
  }

  public static removePostback (postbackId: string): AxiosPromise<DeleteResponse> {
    return this.api.post("", {
      query: removePostbackUrl,
      variables: {
        postbackId
      }
    });
  }

  public static editPostbacks (input: EditInput): AxiosPromise<EditResponse> {
    return this.api.post("", {
      query: editPostbackUrl,
      variables: {
        input
      }
    });
  }

  public static createPostbacks (input: CreateInput): AxiosPromise<CreateResponse> {
    return this.api.post("", {
      query: createPostbackUrl,
      variables: {
        input
      }
    });
  }
}
