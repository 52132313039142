import { Filters } from "@core/store/modules/common/generators/filters";
import { UtmItem } from "@core/store/types/common/statistic/common/utmFilters";
import { StatisticsService } from "@core/services/common/statistic/statisticService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class UtmCampaignFilters extends Filters<UtmItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { statisticFilters: { data, initialData } } } = await StatisticsService.getUtmCampaign(offset, limit, filters, initialFilters);
    const itemsData = data.items?.map((item: string) => ({ id: item }));
    const itemsInitialData = initialData?.items?.map((item: string) => ({ id: item }));
  
    return {
      data: {
        items: itemsData,
        count: data?.count
      },
      initialData: {
        items: itemsInitialData,
        count: initialData?.count
      }
    };
  }
  
  getInitialFilters = (ids?: string): FilterInput | undefined => {
    if (ids) {
      const utmCampaign = ids;
      return { utmCampaign };
    }
  };
}

export const utmCampaignFilters = new UtmCampaignFilters();
