import { FinancesCostsService } from "@core/services/advertiser/finances/costs/FinancesCostsService";
import { GET_WRITE_OFFS_BY_LEADS, SET_EMPTY } from "@core/store/action-constants";
import { SET_COSTS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WriteOffsByLeadsState } from "@core/store/types/advertiser/finances/writeOffsByLeadsState";
import { ActionTree, Module, MutationTree } from "vuex";

const initialState = (): WriteOffsByLeadsState => ({
  data: null
});

const state = initialState;

const mutations: MutationTree<WriteOffsByLeadsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_COSTS]: (state, costs): void => {
    state.data = costs;
  }
};

const actions: ActionTree<WriteOffsByLeadsState, RootState> = {
  async [GET_WRITE_OFFS_BY_LEADS] ({ commit }, { pagination, filters: { currency, ...filters } }) {
    const { offset, limit } = pagination;

    const res = await FinancesCostsService.getCosts(
      limit,
      offset,
      filters,
      currency
    );
    commit(SET_COSTS, res);
  }
};

export const writeOffsByLeads: Module<WriteOffsByLeadsState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
