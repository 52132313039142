import { Filters } from "@core/store/modules/common/generators/filters";
import { UtmItem } from "@core/store/types/common/statistic/common/utmFilters";
import { FilterInput, UserFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import { SubAccountsService } from "@core/services/webmaster/subAccounts/SubAccounts";
import { Data } from "@core/store/types/webmaster/subAccounts";

class SubAccountsFilters extends Filters<UtmItem, UserFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput) => {
    const { dateStart, dateEnd, currency, ...fields } = filters;
    const { data: { agentWebmasters } } = await SubAccountsService.getSubAccounts(limit, offset, { ...fields });
    const itemsData = agentWebmasters.items?.map((item: Data) => {
      return {
        ...item,
        email: item.contact?.email
      };
    });
    
    return {
      data: {
        items: itemsData,
        count: agentWebmasters.count
      }
    };
  }
  
  getInitialFilters = (ids?: string): FilterInput | undefined => {
    if (ids) {
      const subAccounts = ids;
      return { subAccounts };
    }
  };
}

export const subAccountsFilters = new SubAccountsFilters();
