import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import {
  GET_REGISTRATIONS,
  SET_EMPTY
} from "@core/store/action-constants";
import {
  SET_REGISTRATIONS,
  UPDATE_SORTING,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { RegistrationsState } from "@core/store/types/admin/statistic/registrations/registrationsState";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import { RegistrationsService } from "@core/services/admin/statistic/registrations/RegistrationsService";
import { partnerNetworkList } from "@core/store/modules/admin/lists/PartnerNetworkList";
import { webmasterLabels } from "@core/store/modules/admin/lists/webmasterLabels";

const initialState = (): RegistrationsState => {
  return {
    registrations: null,
    filters: {
      group: "DAY",
      isPartnerNetwork: null,
      webmasterLabel: null,
      hasAgentCabinet: null,
      managerId: null,
      datepicker: datepicker({ amount: 1, unit: "week" })
    },
    sort: null,
    order: null
  };
};

const state: () => RegistrationsState = initialState;

const getters: GetterTree<RegistrationsState, RootState> = {};

const mutations: MutationTree<RegistrationsState> = {
  [SET_REGISTRATIONS] (state: RegistrationsState, items: RegistrationsState["registrations"]): void {
    state.registrations = items;
  },

  [UPDATE_FILTERS] (state: RegistrationsState, filters: RegistrationsState["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_SORTING] (state: RegistrationsState, { sort, order }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },

  [SET_EMPTY] (state, target?: keyof RegistrationsState): void {
    if (target) {
      state[target] = initialState()[target] as never;
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<RegistrationsState, RootState> = {
  async [GET_REGISTRATIONS] ({ commit, state }): Promise<void> {
    const { filters: { datepicker, group, ...fields }, sort, order } = state;
    const filters = { ...fields, ...datepicker };
    const { limit, offset } = pagination();

    const data = await RegistrationsService.getRegistrations(
      limit,
      offset,
      sort,
      order,
      filters,
      group
    );
    commit(SET_REGISTRATIONS, data);
  },

  [UPDATE_FILTERS] ({ commit }, filters: RegistrationsState["filters"]): void {
    commit(UPDATE_FILTERS, filters);
  },

  [UPDATE_SORTING] ({ commit }, payload) {
    commit(UPDATE_SORTING, payload);
  },

  [SET_EMPTY] ({ commit }, target?: string): void {
    commit("SET_EMPTY", target);
  }
};

export const registrations: Module< RegistrationsState, RootState> = {
  namespaced: true,
  modules: {
    webmasterLabels,
    partnerNetworkList
  },
  state,
  mutations,
  getters,
  actions
};
