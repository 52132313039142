import { Category, Country } from "@core/store/types/common/statistic/common/statistic";
import { OfferDataByIntIdDataResponse, OfferDataByIntIdDataValue } from "@core/store/types/admin/common/OfferOutput";
import { Money } from "@core/store/types/common";

export class OfferMiniCard implements OfferDataByIntIdDataResponse {
  public logoUrl: string;
  public previewLogoUrl: string;
  public countries: Country[];
  public categories: Category[];
  public withdrawType: string;
  public webmasterReward: Money;
  public subGoalType: string;
  public isLaunched: boolean;
  public slug: string;
  public name: string;
  public type: string;
  public id: string;
  public vertical: string;

  constructor (user: OfferDataByIntIdDataValue) {
    this.id = user.id;
    this.type = user.type;
    this.slug = user.slug;
    this.isLaunched = user.isLaunched;
    this.withdrawType = user.finance.withdrawType;
    this.subGoalType = user.finance.subGoalType;
    this.webmasterReward = user.finance.webmasterReward;
    this.name = user.detail.name;
    this.countries = user.countries;
    this.categories = user.categories;
    this.logoUrl = user.logo?.url;
    this.previewLogoUrl = user.previewLogo?.url;
    this.vertical = user.vertical;
  }
}
