import _flow from "lodash/flow";
import _partialRight from "lodash/partialRight";
import { filterByAvailableCurrencies } from "@core/helpers/filterByAvailableCurrencies";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import { formatCurrency, moment, formatEmptyString, formatPercent, defaultFormatter } from "@core/filters";
import { Money } from "@core/store/types/common";

// Фильтрует и сортирует по доступным валютам
export const currencyAvailableToSort: CallableFunction = _flow([filterByAvailableCurrencies, sortByPreferredCurrency]);

// Округляет и форматирует валюту
export const fixedCurrency = ({ value, currency }: Money) =>
  _flow([_partialRight(formatCurrency, currency)])(value);

// Выводит дату или прочерк если значения не существует
export const momentWithEmpty: CallableFunction = _flow([moment, formatEmptyString]);

export const fixedFormatPercent = _flow([formatPercent, _partialRight(defaultFormatter)]);