<template>
    <div>
        <div
            :key="position"
            ref="track"
            v-for="(position, idx) in availablePositions"
            :style="{ opacity: isScroll ? 1 : 0 }"
            class="scrollbar-container"
            :class="[`is-${ position }`, { 'is-focus': isFocus && key === position }]"
            @mousedown="clickTrackHandler($event, position, idx)">
            <div
                ref="thumb"
                class="scrollbar-inner"
                :style="stylesThumb[position]"
                @mousedown="clickThumbHandler($event, position, idx)">
            </div>
        </div>
    </div>
</template>

<script>
  import _debounce from "lodash/debounce";
  import mapPositions from "./MAP_SCROLL.json";

  export default {
    name: "LScrollBar",

    props: {
      availablePositions: {
        type: Array,
        default: () => ["horizontal", "vertical"]
      }
    },

    mounted () {
      this.$nextTick(() => {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent)
        ) return;
        this.resizeObserver = new ResizeObserver(() => {
          this.availablePositions.forEach((position) => {
            const { offset, inner, scroll } = mapPositions[position];
            this[offset] = window[inner] / document.documentElement[scroll] * 100;
          });
        });
        this.resizeObserver.observe(this.$root.$el);
        window.addEventListener("scroll", this.handlerScroll);
      });
    },

    data () {
      return {
        isScroll: false,
        isFocus: false,
        axisX: 0,
        axisY: 0,
        moveScrollTop: 0,
        moveScrollLeft: 0,
        offsetHeight: 0,
        offsetWidth: 0,
        key: null,
        index: null,
        resizeObserver: null
      };
    },

    computed: {
      stylesThumb () {
        return {
          vertical: [
            { height: `${ this.offsetHeight }%` },
            { transform: `translateY(${ this.moveScrollTop }%)` },
            { display: this.offsetHeight === 100 ? "none" : "block" }
          ],
          horizontal: [
            { width: `${ this.offsetWidth }%` },
            { transform: `translateX(${ this.moveScrollLeft }%)` },
            { display: this.offsetWidth === 100 ? "none" : "block" }
          ]
        };
      },

      debounceScrollHide () {
        return _debounce(() => {
          this.isScroll = false;
        }, 1000);
      }
    },

    methods: {
      handlerScroll ({ target: { documentElement } }) {
        const { vertical, horizontal } = mapPositions;

        this.moveScrollTop = documentElement[vertical.position] * 100 / documentElement[vertical.client];
        this.moveScrollLeft = documentElement[horizontal.position] * 100 / documentElement[horizontal.client];

        this.isScroll = true;
        this.debounceScrollHide();
      },

      clickTrackHandler (event, position, idx) {
        const { direction, axis, offset } = mapPositions[position];
        const offsetWrap = Math.abs(event.target.getBoundingClientRect()[direction] - event[axis]);
        const innerHalf = this.$refs.thumb[idx][offset] / 2;
        const innerPositionPercentage = (offsetWrap - innerHalf) * 100 / this.$refs.track[idx][offset];

        window.scrollTo({
          [direction]: innerPositionPercentage * document.documentElement.scrollHeight / 100
        });
      },

      clickThumbHandler (event, position, idx) {
        // Предотвращает клик через ctrl или ПКМ
        if (event.ctrlKey || event.button === 2) {
          event.stopImmediatePropagation();
          return;
        }

        this.key = position;
        this.index = idx;

        this.startDrag(event);
        const { coord, offset, axis, direction } = mapPositions[position];
        this[coord] = event.currentTarget[offset] - (event[axis] - event.currentTarget.getBoundingClientRect()[direction]);
      },

      startDrag (event) {
        event.stopImmediatePropagation();
        this.isFocus = true;

        document.addEventListener("mousemove", this.mouseMoveDocumentHandler);
        document.addEventListener("mouseup", this.mouseUpDocumentHandler);
        document.onselectstart = () => false;
      },

      mouseMoveDocumentHandler (event) {
        const { coord, direction, axis, offset, scroll } = mapPositions[this.key];

        if (!this.isFocus || !this[coord]) {
          return;
        }

        const { track, thumb } = this.$refs;

        const offsetWrap = (track[this.index].getBoundingClientRect()[direction] - event[axis]) * -1;
        const innerClickPosition = thumb[this.index][offset] - this[coord];
        const innerPositionPercentage = (offsetWrap - innerClickPosition) * 100 / track[this.index][offset];

        window.scrollTo({
          [direction]: innerPositionPercentage * document.documentElement[scroll] / 100
        });
      },

      mouseUpDocumentHandler () {
        const { coord } = mapPositions[this.key];

        this.isFocus = false;
        this[coord] = 0;
        document.removeEventListener("mousemove", this.mouseMoveDocumentHandler);
        document.onselectstart = null;
      }
    },

    destroyed () {
      document.removeEventListener("mouseup", this.mouseUpDocumentHandler);
      window.removeEventListener("scroll", this.handlerScroll);
      this.resizeObserver?.disconnect();
    }
  };
</script>

<style lang="scss">
    // Скрывает скролл когда необходимо
    .is-clipped {
        .scrollbar-container {
            pointer-events: none;
        }
    }
</style>

<style lang="scss" scoped>
    .scrollbar {
        $self: &;
        $color: #bfd2ff;
        $width-scroll: 8px;
        $space-scroll: 2px;
        $margin-scroll: 6px;

        &-container {
            position: fixed;
            right: $space-scroll;
            bottom: $space-scroll;
            z-index: 100;
            border: 3px solid transparent;
            border-radius: 4px;
            transition: opacity 250ms ease-out;

            &:hover,
            &.is-focus {
                opacity: 1 !important;

                #{ $self }-inner {
                    background-color: $color;
                }
            }

            &.is-vertical {
                width: $width-scroll;
                top: $space-scroll;
                padding-right: $margin-scroll;

                #{$self}-inner {
                    width: $width-scroll;
                }
            }

            &.is-horizontal {
                height: $width-scroll;
                left: $space-scroll;
                padding-bottom: $margin-scroll;

                #{$self}-inner {
                    height: $width-scroll;
                }
            }
        }

        &-inner {
            position: relative;
            display: block;
            width: 0;
            height: 0;
            cursor: pointer;
            border-radius: inherit;
            background-color: $color;
            transition: opacity 250ms linear;
        }
    }
</style>