import {
  TrafficRequestAcceptRecommendation,
  TrafficRequestData,
  TrafficRequestDeleted,
  TrafficRequestItem,
  TrafficRequestUnificationData,
  TrafficRequestUnificationItem
} from "@core/store/types/admin/traffic/trafficRequest";
import { TrafficRequestStatusEnum } from "@core/store/types/admin/traffic/enums/trafficRequestStatusEnum";
import { RecommendationRequestStatusEnum } from "@core/store/types/admin/traffic/enums/recommendationRequestStatusEnum";

export function trafficRequestsMapper (data: TrafficRequestData): TrafficRequestUnificationData {
  return {
    ...data,
    items: data.items?.map(trafficRequestReplaceItem)
  };
}

function setStatus (item: TrafficRequestItem): TrafficRequestStatusEnum | RecommendationRequestStatusEnum {
  if (item.isDeleted) {
    return TrafficRequestStatusEnum.DELETED;
  } else if (item.isApplied) {
    return TrafficRequestStatusEnum.APPLIED;
  } else if (!item.isDeleted && item.isApplied === false) {
    return TrafficRequestStatusEnum.AWAITING;
  }
  return item.status as RecommendationRequestStatusEnum;
}

export function trafficRequestReplaceItem (item: TrafficRequestItem): TrafficRequestUnificationItem {
  return {
    ...item,
    expireDate: item.expireDate || item.applyDate,
    offer: { ...item.offer, name: item.offer.detail.name },
    status: setStatus(item),
    oldPayout: item.payout?.old,
    newPayout: item.payout?.new
  };
}

export function trafficRequestSetStatus (item: TrafficRequestAcceptRecommendation | TrafficRequestDeleted) {
  return {
    ...item,
    status: setStatus(item as TrafficRequestItem)
  };
}