import { GET_WITHDRAW_TYPES } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_WITHDRAW_TYPES_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { WithdrawTypesListState } from "@core/store/types/admin/lists/WithdrawTypesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import i18n from "@core/plugins/i18n";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { WithdrawTypeEnum } from "@core/store/types/common/enums/WithdrawTypeEnum";
import { WithdrawTypeGamblingEnum } from "@core/store/types/common/enums/WithdrawTypeGamblingEnum";

const withdrawTypes = {
  [VerticalsEnum.NUTRA]: Object.values(WithdrawTypeEnum),
  [VerticalsEnum.GAMBLING]: Object.values(WithdrawTypeGamblingEnum)
};

const initialState = (): WithdrawTypesListState => {
  return {
    data: {}
  };
};

const state: () => WithdrawTypesListState = initialState;

const getters: GetterTree<WithdrawTypesListState, RootState> = {};

const mutations: MutationTree<WithdrawTypesListState> = {
  [UPDATE_WITHDRAW_TYPES_LIST] (state, statuses: WithdrawTypesListState["data"]) {
    state.data = statuses;
  },
  
  [SET_EMPTY]: state => {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<WithdrawTypesListState, RootState> = {
  [GET_WITHDRAW_TYPES] ({ commit, rootState }, vertical?: VerticalsEnum) {
    const currentVertical: VerticalsEnum = vertical || rootState.verticals.currentVertical || VerticalsEnum.NUTRA;
    const items = withdrawTypes[currentVertical].map(value => ({ value, label: i18n.t(`common.entity.withdrawType.${ value }`) }));
    commit(UPDATE_WITHDRAW_TYPES_LIST, {
      items,
      count: items.length
    });
  }
};

export const withdrawTypesList: Module<WithdrawTypesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
