<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="124"
            fill="none"
            height="124">
            <g filter="url(#filter0_ddi)">
                <path
                    d="M62 34C77.4392 34 90 46.5607 90 62C90 77.4393 77.4392 90 62 90C46.5608 90 34 77.4393 34 62C34 46.5607 46.5608 34 62 34ZM62 30C44.356 30 30 44.3551 30 62C30 79.6447 44.356 94 62 94C79.6445 94 94 79.644 94 62C94 44.3551 79.6445 30 62 30Z"
                    fill="#D8D1EC"></path>
            </g>
            <g
                filter="url(#filter1_ddi)"
                :class="{animation: range.includes(7)}">
                <path
                    d="M66.4668 58.7496L82.901 51.9429C84.7663 51.1704 85.2152 50.1168 84.1586 48.3974C82.7679 46.1353 81.8202 45.0522 80.3841 43.6154C78.9481 42.1786 77.8643 41.2309 75.6014 39.8402C73.8835 38.7843 72.8299 39.2333 72.0575 41.0978L65.2499 57.5335C64.6317 59.0255 64.9763 59.3679 66.4668 58.7496Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter2_ddi)"
                :class="{animation: range.includes(6)}">
                <path
                    d="M61.1393 56.5435C61.7568 58.0354 62.2428 58.0354 62.861 56.5435L69.6693 40.1085C70.441 38.244 70.0147 37.1798 68.0511 36.7112C65.5275 36.1073 64.0839 36 62.0002 36C59.9164 36 58.4736 36.1081 55.9492 36.7112C53.9864 37.1798 53.5594 38.244 54.3318 40.1085L61.1393 56.5435Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter3_ddi)"
                :class="{animation: range.includes(5)}">
                <path
                    d="M41.0981 51.9429L57.533 58.7504C59.025 59.3686 59.3681 59.0255 58.7498 57.5343L51.9423 41.0978C51.1699 39.2333 50.1171 38.7843 48.3984 39.8402C46.1355 41.2301 45.0517 42.1779 43.6156 43.6154C42.1781 45.0522 41.2311 46.1345 39.8412 48.3974C38.7846 50.1168 39.2343 51.1697 41.0981 51.9429Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter4_ddi)"
                :class="{animation: range.includes(4)}">
                <path
                    d="M56.5432 62.8613C58.0352 62.243 58.0352 61.7586 56.5432 61.1396L40.1083 54.3321C38.2437 53.5604 37.1803 53.9867 36.7117 55.9495C36.1071 58.4731 36.0005 59.9167 36.0005 62.0004C36.0005 64.0842 36.1071 65.5278 36.7117 68.0514C37.1803 70.015 38.2437 70.4405 40.1083 69.6681L56.5432 62.8613Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter5_ddi)"
                :class="{animation: range.includes(3)}">
                <path
                    d="M57.5332 65.2511L41.0983 72.0579C39.2337 72.8296 38.784 73.8839 39.8406 75.6033C41.2313 77.8655 42.1783 78.9485 43.6159 80.3853C45.0512 81.8221 46.135 82.7684 48.3971 84.159C50.1165 85.2157 51.1709 84.7667 51.9426 82.9029L58.7501 66.4672C59.3676 64.9752 59.0244 64.6321 57.5332 65.2511Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter6_ddi)"
                :class="{animation: range.includes(2)}">
                <path
                    d="M62.861 67.4565C62.2435 65.9645 61.7583 65.9645 61.1401 67.4565L54.3318 83.8922C53.5594 85.7567 53.9864 86.8209 55.9492 87.2895C58.4728 87.8934 59.9164 88 62.0002 88C64.0839 88 65.5275 87.8919 68.0511 87.2895C70.0147 86.8209 70.4417 85.7567 69.6693 83.8922L62.861 67.4565Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter7_ddi)"
                :class="{animation: range.includes(1)}">
                <path
                    d="M82.9009 72.0578L66.4667 65.2503C64.974 64.6328 64.6317 64.9752 65.2499 66.4664L72.0589 82.9013C72.8306 84.7659 73.8834 85.2163 75.6029 84.159C77.865 82.7683 78.9481 81.8205 80.3841 80.3845C81.8201 78.9477 82.7679 77.8654 84.1586 75.6025C85.2144 73.8838 84.7662 72.8302 82.9009 72.0578Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <g
                filter="url(#filter8_ddi)"
                :class="{animation: range.includes(0)}">
                <path
                    d="M87.2894 55.9494C86.82 53.9866 85.7566 53.5588 83.8921 54.332L67.4564 61.1395C65.9644 61.7578 65.9644 62.243 67.4564 62.8612L83.8921 69.6695C85.7566 70.4412 86.82 70.0149 87.2894 68.0513C87.8933 65.5277 87.9999 64.0841 87.9999 62.0004C87.9999 59.9166 87.8933 58.473 87.2894 55.9494Z"
                    :fill="$saas.loader_color"></path>
            </g>
            <defs>
                <filter
                    id="filter0_ddi"
                    x="0"
                    y="0"
                    width="124"
                    height="124"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter1_ddi"
                    x="34.958"
                    y="9.28064"
                    width="79.7607"
                    height="79.7614"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter2_ddi"
                    x="23.9692"
                    y="6"
                    width="76.0622"
                    height="81.6624"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter3_ddi"
                    x="9.28125"
                    y="9.28064"
                    width="79.7608"
                    height="79.762"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter4_ddi"
                    x="6.00049"
                    y="23.9698"
                    width="81.6617"
                    height="76.0611"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter5_ddi"
                    x="9.28076"
                    y="34.9584"
                    width="79.7611"
                    height="79.7608"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter6_ddi"
                    x="23.9692"
                    y="36.3375"
                    width="76.0626"
                    height="81.6624"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter7_ddi"
                    x="34.9575"
                    y="34.9584"
                    width="79.7608"
                    height="79.7608"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
                <filter
                    id="filter8_ddi"
                    x="36.3374"
                    y="23.9691"
                    width="81.6624"
                    height="76.0626"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="10"
                        dy="10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.318229 0 0 0 0 0.358854 0 0 0 0 0.541667 0 0 0 0.3 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="-10"
                        dy="-10"></feOffset>
                    <feGaussianBlur stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"></feBlend>
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"></feBlend>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"></feColorMatrix>
                    <feOffset
                        dx="2"
                        dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"></feComposite>
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow"></feBlend>
                </filter>
            </defs>
        </svg>
        <div
            v-show="isLabelExist"
            style="text-align: center; margin-top: -20px; font-size: 0.85rem;">
            {{ $t("common.components.loader.status") }}
        </div>
    </div>
</template>

<script>
  export default {
    name: "Preloader",
    props: {
      isLabelExist: {
        type: Boolean,
        default: true
      }
    },
    mounted () {
      this.startAnimate();
    },
    data () {
      return {
        count: 0,
        interval: null,
        range: []
      };
    },
    methods: {
      startAnimate () {
        let reverse = true;
        this.interval = setInterval(() => {
          this.count++;
          reverse ? this.range.push(this.count) : this.range.shift();
          if (this.count === 8) {
            this.count = 0;
            reverse = !reverse;
          }
        }, 60);
      }
    },
    beforeDestroy () {
      clearInterval(this.interval);
    }
  };
</script>

<style scoped lang="scss">
.animation {
    animation-name: fadeOut;
    animation-duration: 60ms;
    animation-fill-mode: forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
