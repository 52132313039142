import { UsersService } from "@core/services/admin/users/common/UsersService";
import { WebmastersService } from "@core/services/admin/users/webmasters/WebmastersService";
import {
  ADD_APPROVED_WEBMASTER,
  ADD_WEBMASTER_PERSONAL_MANAGER,
  BLOCK_WEBMASTER,
  DELETE_WEBMASTER_PERSONAL_MANAGER,
  ENABLE_WEBMASTER_PUBLIC_API,
  DISABLE_WEBMASTER_PUBLIC_API,
  EDIT_WEBMASTER_ADMIN_COMMENT,
  GET_WEBMASTERS,
  GET_WEBMASTERS_WITH_BALANCE,
  RESEND_EMAIL_BY_ADMIN,
  UNBLOCK_WEBMASTER,
  UPDATE_WEBMASTERS_BALANCE,
  WEBMASTER_BALANCE
} from "@core/store/action-constants";
import { approvedList } from "@core/store/modules/admin/lists/ApprovedList";
import { blockedList } from "@core/store/modules/admin/lists/BlockedList";
import { emailConfirmedList } from "@core/store/modules/admin/lists/EmailConfirmedList";
import { partnerNetworkList } from "@core/store/modules/admin/lists/PartnerNetworkList";
import {
  EMPTY_WEBMASTER_EDITABLE_COMMENT,
  SET_EMPTY,
  SET_WEBMASTER_BALANCE,
  SET_WEBMASTERS,
  UPDATE_WEBMASTER_EDITABLE_COMMENT,
  UPDATE_WEBMASTERS_FILTERS,
  UPDATE_WEBMASTERS_SORTINGS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { UserCommentInput } from "@core/store/types/admin/users/common";
import {
  WebmastersState,
  WebmastersItem
} from "@core/store/types/admin/users/webmasters/WebmastersState";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { detail } from "./modules/detail";
import { referralList } from "@core/store/modules/admin/lists/referralList";
import { subAccountsModal } from "./modules/webmasterSubAccountsModal";
import { referralsModal } from "./modules/webmastersReferralsModal";
import { checkModal } from "./modules/webmasterCheckModal";
import _findIndex from "lodash/findIndex";
import momentjs from "moment";
import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { domesticWebmastersList } from "@core/store/modules/admin/lists/domesticWebmastersList";
import { ManagersFilters } from "@core/store/modules/common/lists/managersFilters";
import { webmasterSubType } from "@core/store/modules/admin/lists/webmasterSubType";
import moment from "moment/moment";
import { pagination } from "@core/helpers/pagination";
import { momentToDate } from "@core/helpers/momentToDate";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const initialEditableComment = (): WebmastersState["editableComment"] => ({
  userId: null,
  comment: null
});

const initialState = (): WebmastersState => {
  return {
    webmasters: null,
    filters: {
      isBlocked: false,
      refer: null,
      registeredDatepicker: {
        registeredAtStart: null,
        registeredAtEnd: moment(momentToDate(momentjs().endOf("day"))).format("YYYY-MM-DDTHH:mm:ssZ")
      }
    },
    sort: null,
    order: null,
    isWebmastersFindModalActive: false,
    editableComment: initialEditableComment()
  };
};

const state: () => WebmastersState = initialState;

const mutations: MutationTree<WebmastersState> = {
  [SET_WEBMASTERS]: (state, webmasters: WebmastersState["webmasters"]): void => {
    state.webmasters = webmasters;
  },

  [SET_WEBMASTER_BALANCE]: (state, { balance, webmasterId: id }): void => {
    if (state.webmasters?.items) {
      const { items } = state.webmasters;
      const webmasterIndex = _findIndex(items, { id });
      Vue.set(items, webmasterIndex, { ...items[webmasterIndex], balance });
    }
  },

  [UPDATE_WEBMASTERS_FILTERS] (state, filter: WebmastersState["filters"]): void {
    state.filters = { ...state.filters, ...filter };
  },
  
  [UPDATE_WEBMASTERS_SORTINGS] (state, {
    sort,
    order
  }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [UPDATE_WEBMASTER_EDITABLE_COMMENT] (state, editableComment: WebmastersState["editableComment"]): void {
    state.editableComment = { ...state.editableComment, ...editableComment };
  },
  
  [EMPTY_WEBMASTER_EDITABLE_COMMENT] (state): void {
    state.editableComment = initialEditableComment();
  },
  
  [SET_EMPTY] (state, target?: keyof WebmastersState) {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<WebmastersState, RootState> = {
  async [GET_WEBMASTERS] ({ state, commit }) {
    const { limit, offset } = pagination();

    let {
      registeredDatepicker,
      webmasterBirthday,
      refer,
      ...filters
    } = state.filters;
    
    webmasterBirthday = webmasterBirthday ? Number(momentjs(webmasterBirthday).format("MM")) : null;

    try {
      const { data: { webmasters } } = await WebmastersService.getWebmasters(
        limit,
        offset,
        {
          [refer]: refer ? true : null,
          webmasterBirthday,
          ...registeredDatepicker,
          ...filters
        },
        state.sort,
        state.order
      );

      commit(SET_WEBMASTERS, webmasters);
    } catch (error) {
      throw error;
    }
  },

  async [GET_WEBMASTERS_WITH_BALANCE] ({ dispatch }) {
    await dispatch(GET_WEBMASTERS);
    // dispatch(UPDATE_WEBMASTERS_BALANCE);
  },

  [UPDATE_WEBMASTERS_BALANCE] ({ state, dispatch }) {
    state.webmasters?.items?.forEach((item: WebmastersItem) => {
      dispatch(WEBMASTER_BALANCE, item.id);
    });
  },

  async [ADD_APPROVED_WEBMASTER] ({ commit }, webmasterId: string) {
    const approvedWeb = await WebmastersService.approveWebmaster(webmasterId);
    commit("LOCAL_UPDATE", { items: approvedWeb, target: "webmasters" });
  },

  async [ADD_WEBMASTER_PERSONAL_MANAGER] ({ commit }, { userId, adminIds }) {
    const bindWeb = await WebmastersService.bindWebmaster(userId, { adminIds });
    commit("LOCAL_UPDATE", { items: bindWeb, target: "webmasters" });
  },

  async [DELETE_WEBMASTER_PERSONAL_MANAGER] ({ commit }, userId: string) {
    const bindWeb = await WebmastersService.bindWebmaster(userId, { adminIds: [] });
    commit("LOCAL_UPDATE", { items: bindWeb, target: "webmasters" });
  },
  
  async [BLOCK_WEBMASTER] ({ commit }, { id, transferBalance }) {
    const blockedWeb = await WebmastersService.blockWebmaster(id, transferBalance);
    commit("LOCAL_UPDATE", { items: blockedWeb, target: "webmasters" });
  },

  async [UNBLOCK_WEBMASTER] ({ commit }, { id }) {
    const unblockedWeb = await WebmastersService.unblockWebmaster(id);
    commit("LOCAL_UPDATE", { items: unblockedWeb, target: "webmasters" });
  },
  
  async [ENABLE_WEBMASTER_PUBLIC_API] ({ commit }, webmasterId: string) {
    const enabledApi = await WebmastersService.enableAccessToApiForWebmaster(webmasterId);
    commit("LOCAL_UPDATE", { items: enabledApi, target: "webmasters" });
  },
  
  async [DISABLE_WEBMASTER_PUBLIC_API] ({ commit }, webmasterId: string) {
    const disabledApi = await WebmastersService.disableAccessToApiForWebmaster(webmasterId);
    commit("LOCAL_UPDATE", { items: disabledApi, target: "webmasters" });
  },
  
  async [WEBMASTER_BALANCE] ({ commit }, webmasterId: string) {
    try {
      const balance = await UsersService.balance(webmasterId);
      commit("SET_WEBMASTER_BALANCE", { balance, webmasterId });
    } catch (error) {
      throw error;
    }
  },

  async [RESEND_EMAIL_BY_ADMIN] (_, userId: string) {
    await UsersService.resendEmailByAdmin(userId);
  },

  [UPDATE_WEBMASTERS_FILTERS] ({ commit }, filter) {
    commit(UPDATE_WEBMASTERS_FILTERS, filter);
  },

  async [EDIT_WEBMASTER_ADMIN_COMMENT] ({ state, commit }) {
    if (state.editableComment) {
      const { editableComment: { userId, comment } } = state;
      const input = { comment } as UserCommentInput;
      const updatedComment = await UsersService.updateComment(userId as string, input);
      commit("LOCAL_UPDATE", { items: updatedComment, target: "webmasters" });
    }
  },
  
  [UPDATE_WEBMASTER_EDITABLE_COMMENT] ({ commit }, editableComment) {
    commit(UPDATE_WEBMASTER_EDITABLE_COMMENT, editableComment);
  },
  
  [SET_EMPTY] ({ commit }, target?: string) {
    commit(SET_EMPTY, target);
  },
  
  LOCAL_UPDATE ({ commit }, params) {
    commit("LOCAL_UPDATE", params);
  }
};

export const webmasters: Module<WebmastersState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    detail,
    approvedList,
    domesticWebmastersList,
    emailConfirmedList,
    partnerNetworkList,
    blockedList,
    webmasterSubType,
    subAccountsModal,
    checkModal,
    referralsModal,
    referralList,
    managersFilters: new ManagersFilters()
  }
};