import { RootState } from "@core/store/root-state";
import { ActionTree, Module } from "vuex";
import { subAccountsFilters } from "@core/store/modules/common/statistic/common/subAccountsFilters";
import { utmCampaignFilters } from "@core/store/modules/common/utmCampaignFilters";
import { utmContentFilters } from "@core/store/modules/common/utmContentFilters";
import { utmMediumFilters } from "@core/store/modules/common/utmMediumFilters";
import { utmSourceFilters } from "@core/store/modules/common/utmSourceFilters";
import { utmTermFilters } from "@core/store/modules/common/utmTermFilters";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { SET_EMPTY, SYNC_STATISTIC_FILTERS } from "@core/store/action-constants";
import { prepareBaseFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { bunchesFilters } from "@core/store/modules/common/statistic/common/bunchesFilters";
import { currencyList } from "@core/store/modules/common/lists/currencyList";
import { flowsFilters } from "@core/store/modules/common/statistic/common/flowsFilters";

const actions: ActionTree<RootState, RootState> = {
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    const baseFilters = prepareBaseFilters(filters);
    
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`bunchesFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmCampaignFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmContentFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmMediumFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmSourceFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`utmTermFilters/${ UPDATE_FILTERS }`, baseFilters);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit("bunchesFilters/SET_EMPTY");
    commit("subAccountsFilters/SET_EMPTY");
    commit("utmCampaignFilters/SET_EMPTY");
    commit("utmContentFilters/SET_EMPTY");
    commit("utmMediumFilters/SET_EMPTY");
    commit("utmSourceFilters/SET_EMPTY");
    commit("utmTermFilters/SET_EMPTY");
  }
};

export const statistics: Module<RootState, RootState> = {
  namespaced: true,
  modules: {
    bunchesFilters,
    subAccountsFilters,
    currencyList,
    flowsFilters,
    utmCampaignFilters,
    utmContentFilters,
    utmMediumFilters,
    utmSourceFilters,
    utmTermFilters
  },
  actions
};
