import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import statistic from "@core/services/admin/statistic/statistic/graphql/statistic.graphql";
import statisticsNewQuery from "@core/services/admin/statistic/statistic/graphql/statisticsNew.generator";
import approveDashboardQuery from "@core/services/admin/dashboard/graphql/approveDashboard.graphql";
import dashboardSummary from "@core/services/admin/dashboard/generatorDashboardSummary";
import dashboardUserTotal from "@core/services/admin/dashboard/generatorDashboardUserTotal";
import commissionDashboardQuery from "@core/services/admin/dashboard/graphql/commissionDashboard.graphql";
import debitDashboardQuery from "@core/services/admin/dashboard/graphql/debitDashboard.graphql";
import prepaidExpenseDashboardQuery from "@core/services/admin/dashboard/graphql/prepaidExpenseDashboard.graphql";
import { DashboardTopCardFilters, DashboardTopCardState } from "@core/store/types/admin/lists/DashboardListState";
import statisticCompare from "@core/services/admin/dashboard/graphql/statisticCompare.graphql";
import leadsCompare from "@core/services/admin/dashboard/graphql/leadsCompare.graphql";
import cashFlowCompare from "@core/services/admin/dashboard/graphql/cashFlowCompare.graphql";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import {
  StatisticData,
  StatisticResponse,
  StatisticResponseData
} from "@core/store/types/common/statistic/common/statistic";
import { currencyMapper } from "@core/store/modules/admin/statistic/currencyMapper";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import statisticVerticalsMapper from "@core/services/admin/statistic/statisticVerticalsMapper";
import StatisticGraphGenerator from "@core/services/admin/statistic/statistic/graphql/statisticGraph.generator";

export class StatisticsService extends Service {
  public static async getAdminStatistic (
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    groups: Nullable<string[]>
  ): Promise<StatisticData> {
    try {
      const { data: { statistics } } = await this.api.get("", {
        params: {
          query: statistic,
          variables: {
            filters,
            excludedFilters,
            groups
          }
        }
      });
      return filters.vertical === VerticalsEnum.GAMBLING ? statisticVerticalsMapper(statistics) : statistics;
    } catch (e) {
      throw e;
    }
  }

  public static async getAdminNewStatistic (
    limit: number,
    offset: number,
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    sort: string | null,
    order: string | null,
    groups: string[],
    hasPayout: boolean,
    payoutAnalyze: boolean
  ): Promise<StatisticData> {
    try {
      const { data: { statisticsNew } } = await this.api.get("", {
        params: statisticsNewQuery(
          limit,
          offset,
          filters,
          excludedFilters,
          sort,
          order,
          groups,
          hasPayout,
          payoutAnalyze
        )
      });
      return filters.vertical === VerticalsEnum.GAMBLING ? statisticVerticalsMapper(statisticsNew) : statisticsNew;
    } catch (e) {
      throw e;
    }
  }

  public static async getAdminStatisticGraph (
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: StatisticGraphGenerator(filters, excludedFilters, groups)
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminStatisticCompare (
    filters: BaseFiltersInput,
    excludedFilters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: {
          query: statisticCompare,
          variables: {
            filters,
            groups
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminLeadsCompare (
    filters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponse["items"]> {
    try {
      const { data: { statistics } } = await this.api.get("", {
        params: {
          query: leadsCompare,
          variables: {
            filters,
            groups
          }
        }
      });

      return currencyMapper(statistics.items);
    } catch (e) {
      throw e;
    }
  }

  public static async getAdminCashFlowCompare (
    filters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponse["items"]> {
    try {
      const { data: { statistics } } = await this.api.get("", {
        params: {
          query: cashFlowCompare,
          variables: {
            filters,
            groups
          }
        }
      });

      return currencyMapper(statistics.items);
    } catch (e) {
      throw e;
    }
  }
  
  public static async getDashboardSummaryUser (
    limit: number,
    offset: number,
    filters: DashboardTopCardFilters["managerId"],
    periodType: DashboardTopCardFilters["periodType"],
    groupType: DashboardTopCardState["groupType"],
    sort?: DashboardTopCardState["sort"],
    order?: DashboardTopCardState["order"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: dashboardSummary(
          limit,
          offset,
          filters,
          periodType,
          groupType,
          sort,
          order
        )
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static async getDashboardUserTotal (groupType: DashboardTopCardState["groupType"]): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: dashboardUserTotal(groupType)
      });
    } catch (e) {
      throw e;
    }
  }
  
  public static getAdminStatisticByLeads (params: any): AxiosPromise {
    return this.rest.get("/admin/leads", { params });
  }
  
  public static async getAdminDebit (
    dueTo: Date,
    vertical: VerticalsEnum
  ): Promise<AxiosPromise> {
    try {
      const { data: { debitDashboard } } = await this.api.get("", {
        params: {
          query: debitDashboardQuery,
          variables: {
            dueTo,
            vertical
          }
        }
      });

      return debitDashboard;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminPrepaid (
    dueTo: Date,
    vertical: VerticalsEnum
  ): Promise<AxiosPromise> {
    try {
      const { data: { prepaidExpenseDashboard } } = await this.api.get("", {
        params: {
          query: prepaidExpenseDashboardQuery,
          variables: {
            dueTo,
            vertical
          }
        }
      });

      return prepaidExpenseDashboard;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminCommission (
    dateStart: Date,
    dateEnd: Date,
    vertical: VerticalsEnum
  ): Promise<AxiosPromise> {
    try {
      const { data: { commissionDashboard } } = await this.api.get("", {
        params: {
          query: commissionDashboardQuery,
          variables: {
            dateStart,
            dateEnd,
            vertical
          }
        }
      });

      return commissionDashboard;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getAdminApproves (
    dateStart: Date,
    dateEnd: Date,
    vertical: VerticalsEnum
  ): Promise<AxiosPromise> {
    try {
      const { data: { approveDashboard } } = await this.api.get("", {
        params: {
          query: approveDashboardQuery,
          variables: {
            dateStart,
            dateEnd,
            vertical
          }
        }
      });

      return approveDashboard;
    } catch (e) {
      throw e;
    }
  }

  public static updateAdminStatisticLeadsStatuses (params: any): AxiosPromise<any> {
    return this.rest.post("/admin/integrations/postback/recovery", params);
  }
}
