<template>
    <b-notification
        v-if="isShown"
        :closable="false"
        has-icon
        icon="exclamation-circle"
        type="is-primary content">
        <h5 class="title is-5 has-text-weight-bold">
            {{ $t(`common.releaseUpdate.header`) }}
        </h5>
        <p>
            <i18n path="common.releaseUpdate.body.content">
                <template #strong>
                    <span
                        v-t="'common.releaseUpdate.body.particles.strong'"
                        class="has-text-weight-semibold"></span>
                </template>
            </i18n>
        </p>
        <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
                <b-button
                    icon-left="redo"
                    size="is-small"
                    type="is-white"
                    @click="refreshPage">
                    {{ $t(`common.releaseUpdate.button`) }}
                </b-button>
            </div>
        </div>
    </b-notification>
</template>

<script>
  import { UpdateService } from "@core/services/common/Update/update";
  import { mapState } from "vuex";

  export default {
    name: "ReleaseUpdate",
    created () {
      this.checkUpdate();
    },

    mounted () {
      this.interval = setInterval(this.checkUpdate, 15 * 1000);
    },

    data () {
      return {
        isShown: false,
        interval: null
      };
    },

    computed: {
      ...mapState(["version"])
    },

    methods: {
      async checkUpdate () {
        if (process.env.VUE_APP_RELEASE_UPDATE) {
          const { data } = await UpdateService.getVersion();
          this.isShown = this.version !== data.version;
          this.isShown && clearInterval(this.interval);
        }
      },

      refreshPage () {
        parent.window.location.reload();
      }
    },

    beforeDestroy () {
      clearInterval(this.interval);
    }
  };
</script>

<style lang="scss" scoped>
    .notification {
        width: 80%;
        left: 10px;
        z-index: 40;
        bottom: 10px;
        position: fixed;
        max-width: 600px;
    }
</style>
