import { AdministratorsEditService } from "@core/services/admin/users/administrators/AdministratorsEditService";
import { EDIT_PASSWORD } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_MODAL } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  AdministratorsPasswordEditInput,
  AdministratorsPasswordState
} from "@core/store/types/admin/users/administrators/Edit";
import { ActionTree, Module, MutationTree } from "vuex";
import { AdvertisersEditService } from "@core/services/admin/users/advertisers/AdvertisersEditService";
import { WebmastersEditService } from "@core/services/admin/users/webmasters/WebmastersEditService";

const initialState = (): AdministratorsPasswordState => {
  return {
    modal: {
      isPasswordModalActive: false,
      password: null
    }
  };
};

const mutations: MutationTree<AdministratorsPasswordState> = {
  [SET_EMPTY] (state): void {
    Object.assign(state, initialState());
  },

  [UPDATE_MODAL] (state, modal): void {
    state.modal = { ...state.modal, ...modal };
  }
};

const actions: ActionTree<AdministratorsPasswordState, RootState> = {
  async [EDIT_PASSWORD] ({ state: { modal: { password } }, rootState }, user: string) {
    const dataSet = {
      admin: rootState.admin.users.administrators.edit.administratorId ?? rootState.admin.profile.user.id,
      webmaster: rootState.webmasterDataById.webmasterProfileViewModal.data?.id,
      advertiser: rootState.admin.users.advertisers.edit.advertiserId
    };

    const editService = {
      admin: AdministratorsEditService,
      webmaster: WebmastersEditService,
      advertiser: AdvertisersEditService
    };

    if (user in editService) {
      // @ts-ignore
      await editService[user].editPassword(dataSet[user], { password } as AdministratorsPasswordEditInput);
    }
  },

  [UPDATE_MODAL] ({ commit }, modal): void {
    commit(UPDATE_MODAL, modal);
  }
};

export const password: Module<AdministratorsPasswordState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
