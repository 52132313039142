

























import { ComponentPublicInstance, computed, onMounted, ref } from "@vue/composition-api";
import { defineProps, defineEmits, defineOptions } from "@vue/runtime-dom";
import autosize from "autosize";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: null,
    required: true
  },
  icon: {
    type: String,
    default: null
  },
  iconRight: {
    type: String,
    default: null
  },
  iconPack: {
    type: String,
    default: "fas"
  },
  lazy: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const input = ref<ComponentPublicInstance<any> | null>(null);
  defineOptions({
    inheritAttrs: false
  });
  const props = __props;
  const emit = __ctx.emit;
  const isValid = ref<boolean>(true);
  const isPassword = computed(() => props.type === "password");
  onMounted(() => {
    if (props.type && props.type === "textarea") {
      autosize(input.value.$el.querySelector(".textarea"));
    }
  });
  function update(value: string): void {
    emit("input", value === "" ? null : value);
  }
  function blur(event: any) {
    isValid.value = event.target.validity.valid;
    emit("blur", event);
  }
  return {
    input,
    emit,
    isPassword,
    update,
    blur
  };
};
export default __sfc_main;
