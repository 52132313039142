<template>
    <Card class="mt-4">
        <template #card-header-title>
            <div class="is-align-items-center is-justify-content-space-between is-fullwidth is-flex-wrap-wrap">
                <span class="pr-2 py-2">
                    {{ $t('common.profile.security.activeSessions.title') }}
                </span>
                <b-button
                    :loading="isLoading.forceLogout"
                    type="is-info"
                    @click="forceLogout">
                    {{ $t("common.profile.security.activeSessions.button.completeOtherSessions") }}
                </b-button>
            </div>
        </template>

        <TablePlaceholder
            class="mt-2"
            :data="dataTable">
            <template #default="{ items, count }">
                <LTable
                    :data="items"
                    :count="count"
                    hoverable
                    hide-pagination>
                    <b-table-column v-slot="{ row }">
                        {{ `IP:${ formatEmptyString(row.ip) }` }}<br>
                        <span
                            v-if="row.isCurrentLogin"
                            class="has-text-success">
                            {{ $t('common.profile.security.activeSessions.table.activeSessions') }}
                        </span>
                    </b-table-column>

                    <b-table-column v-slot="{ row }">
                        {{ formatEmptyString(row.browser) }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }">
                        <Countries
                            :items="countryToArray(row.country)"
                            compact>
                        </Countries>
                    </b-table-column>

                    <b-table-column v-slot="{ row }">
                        {{ momentWithEmpty(row.lastActivityDate, "DD.MM.YYYY HH:mm") }}
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        width="5%">
                        <b-button
                            rounded
                            size="is-small"
                            type="is-danger is-light"
                            icon-left="arrow-right-from-bracket"
                            :loading="isLoading.logoutProfileSession && logoutSessionId === row.loginId"
                            icon-pack="fas"
                            @click="logoutProfileSession(row)">
                        </b-button>
                    </b-table-column>
                </LTable>
            </template>

            <template #empty>
                <div class="hero is-medium has-text-centered">
                    <div class="hero-body py-6">
                        <h2 class="is-size-4 is-bold has-text-weight-medium has-text-grey">
                            <slot name="placeholder">
                                {{ $t("common.table.noData") }}
                            </slot>
                        </h2>
                    </div>
                </div>
            </template>
        </TablePlaceholder>
    </Card>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
  import Countries from "@/components/Common/Lists/Countries.vue";
  import LTable from "@/components/Common/LTable.vue";
  import Card from "@/components/Common/Card.vue";
  import { mapActions, mapGetters } from "vuex";
  import {
    LOGOUT_PROFILE_SESSION,
    FORCE_LOGOUT,
    USER_LOGOUT
  } from "@core/store/action-constants";
  import { formatEmptyString } from "@/filters";
  import { momentWithEmpty } from "@core/flowMethods";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { UPDATE_USER } from "@core/store/mutation-constants";

  export default {
    name: "ActiveSessions",
    components: { Countries, TablePlaceholder, LTable, Card },

    data () {
      return {
        logoutSessionId: null
      };
    },

    computed: {
      ...mapGetters(["role", "asAdmin"]),

      dataTable () {
        const { userLogins: items } = this;
        return { items, count: items?.length };
      },

      userLogins: {
        get () {
          return this.$store.state[this.role].profile.user.userLogins;
        },

        set (value) {
          this.$store.dispatch(`${ this.role }/profile/${ UPDATE_USER }`, { userLogins: value });
        }
      },

      isLoading () {
        return {
          forceLogout: this.$wait(`userLogout/${ FORCE_LOGOUT }`),
          logoutProfileSession: this.$wait(LOGOUT_PROFILE_SESSION)
        };
      }
    },

    methods: {
      ...mapActions("userLogout", {
        FORCE_LOGOUT
      }),

      ...mapActions([
        LOGOUT_PROFILE_SESSION,
        USER_LOGOUT
      ]),

      async forceLogout () {
        await this.FORCE_LOGOUT();
        this.USER_LOGOUT();
        this.$emit("userLogout");
      },

      async logoutProfileSession ({ isCurrentLogin, loginId }) {
        try {
          this.logoutSessionId = loginId;

          await actionWithToast(
            this.LOGOUT_PROFILE_SESSION(loginId),
            "common.profile.security.activeSessions.message.closeSession"
          );

          this.userLogins = this.userLogins.filter(el => el.loginId !== loginId);

          if (isCurrentLogin) {
            this.USER_LOGOUT();
            this.$emit("userLogout");
          }
        } finally {
          this.logoutSessionId = null;
        }
      },

      countryToArray (country) {
        return country ? [country] : [];
      },

      formatEmptyString,
      momentWithEmpty
    }
  };
</script>

<!--suppress SassScssUnresolvedVariable, SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
    ::v-deep {
        .table thead {
            display: none;
        }
    }
    .card {
        $self: &;

        background-color: $white-gray;

        .is-fullwidth {
            width: 100%;
        }

        ::v-deep {
            #{ $self }-header {
                position: sticky;
                top: 0;
                z-index: 1;

                padding-bottom: $size-7;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: $white-gray;

                &-title {
                    padding: $size-5 $size-5 0;
                    font-weight: $weight-semibold;
                }
            }

            #{ $self }-content {
                overflow: auto;
                max-height: 350px;

                padding: 0 $size-5 $size-5;

                span {
                    font-size: 0.85rem;
                }
            }
        }
    }
</style>