import i18n from "@core/plugins/i18n";
import { SET_LOCALE, SET_SETTINGS_LENG } from "@core/store/mutation-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { localize } from "vee-validate";
import { UPDATE_LOCALE } from "@core/store/action-constants";
import datepickerRu from "@core/store/modules/common/datasets/datepickerSettingsLang.ru.json";
import datepickerEn from "@core/store/modules/common/datasets/datepickerSettingsLang.en.json";

export interface DatePickerLeng {
  formatLocale: {
    months: Array<string>,
    monthsShort: Array<string>,
    weekdays: Array<string>,
    weekdaysShort: Array<string>,
    weekdaysMin: Array<string>,
    firstDayOfWeek: number,
    firstWeekContainsDate: number
  }
}

export interface LocaleState {
  locale: string | null;
  settingsLeng: DatePickerLeng | null;
}

const state: LocaleState = {
  locale: null,
  settingsLeng: null
};

const getters: GetterTree<LocaleState, RootState> = {
  locale: state => state.locale
};

const mutations: MutationTree<LocaleState> = {
  [SET_LOCALE] (state, value): void {
    state.locale = value;
  },
  
  [SET_SETTINGS_LENG] (state) {
    state.settingsLeng = state?.locale === "en" ? datepickerEn : datepickerRu;
  }
};

const actions: ActionTree<LocaleState, RootState> = {
  [UPDATE_LOCALE] ({ commit }, payload: string): void {
    i18n.locale = payload;
    localize(payload);
    window.localStorage.setItem("locale", payload);
    commit(SET_LOCALE, payload);
    commit(SET_SETTINGS_LENG);
  }
};

export const locale: Module<LocaleState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
