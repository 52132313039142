import { PaymentsService } from "@core/services/admin/payments/PaymentsService";
import {
  DELETE_PAYMENT_SYSTEM,
  GET_PAYMENT_BY_ID,
  GET_PAYMENT_SYSTEMS,
  ENABLE_PAYMENT_SYSTEM,
  DISABLE_PAYMENT_SYSTEM
} from "@core/store/action-constants";
import { paymentsModal } from "@core/store/modules/admin/payments/paymentsModal";
import { SET_EMPTY, UPDATE_PAYMENT_SYSTEMS } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialPaymentsState, Payment } from "@core/store/types/admin/payments/Payments";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { pagination } from "@core/helpers/pagination";

const initialState = (): PartialPaymentsState => {
  return {
    payments: null
  };
};

const state: () => PartialPaymentsState = initialState;

const getters: GetterTree<PartialPaymentsState, RootState> = {
  [GET_PAYMENT_BY_ID]: state => function (paymentId: string): Payment | undefined {
    return state.payments?.items?.find((payment: Payment) => payment.id === paymentId);
  }
};

const mutations: MutationTree<PartialPaymentsState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),

  [UPDATE_PAYMENT_SYSTEMS] (state, items: PaginationOutput<Payment[]>) {
    state.payments = items;
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<PartialPaymentsState, RootState> = {
  async [GET_PAYMENT_SYSTEMS] ({ commit }) {
    const { limit, offset } = pagination();
    // createdAt
    try {
      const paymentSystems = await PaymentsService.getPaymentSystems(
        limit,
        offset,
        "createdAt",
        "DESC"
      );
      
      commit(UPDATE_PAYMENT_SYSTEMS, paymentSystems);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [DELETE_PAYMENT_SYSTEM] ({ commit }, id: string) {
    try {
      const deletePaymentSystem = await PaymentsService.deletePaymentSystem(id);
      commit("LOCAL_DELETE", { items: deletePaymentSystem, target: "payments" });
    } catch (e: any) {
      throw new Error(e);
    }
  },
  
  async [ENABLE_PAYMENT_SYSTEM] ({ commit }, paymentSystemId: string) {
    try {
      const enablePaymentSystem = await PaymentsService.enablePaymentSystem(paymentSystemId);
      commit("LOCAL_UPDATE", { items: enablePaymentSystem, target: "payments" });
    } catch (e) {
      throw e;
    }
  },
  
  async [DISABLE_PAYMENT_SYSTEM] ({ commit }, paymentSystemId: string) {
    try {
      const disablePaymentSystem = await PaymentsService.disablePaymentSystem(paymentSystemId);
      commit("LOCAL_UPDATE", { items: disablePaymentSystem, target: "payments" });
    } catch (e) {
      throw e;
    }
  }
};

export const payments: Module<PartialPaymentsState, RootState> = {
  namespaced: true,
  modules: {
    paymentsModal
  },
  getters,
  state,
  mutations,
  actions
};
