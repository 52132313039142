export enum StatisticGroupsEnum {
  OFFER = "OFFER",
  HOURS = "HOURS",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
  COUNTRY = "COUNTRY",
  REGION = "REGION",
  FLOW = "FLOW",
  BUNCH = "BUNCH",
  WEBMASTER = "WEBMASTER",
  EXTERNAL_WEBMASTER = "EXTERNAL_WEBMASTER",
  ADVERTISER = "ADVERTISER",
  WEBMASTER_LABEL = "WEBMASTER_LABEL",
  MANAGER = "MANAGER",
  WITHDRAW = "WITHDRAW",
  CATEGORY = "CATEGORY",
  AGENT = "AGENT",
  GLOBAL_SOURCE = "GLOBAL_SOURCE",
  ACTUAL_WEBMASTER_ID = "ACTUAL_WEBMASTER_ID",
  ACTUAL_WEBMASTER_INT_ID = "ACTUAL_WEBMASTER_INT_ID",
  TARGET_SEX = "TARGET_SEX",
  TARGET_AGE = "TARGET_AGE",
  UTM_CAMPAIGN = "UTM_CAMPAIGN",
  UTM_CONTENT = "UTM_CONTENT",
  UTM_MEDIUM = "UTM_MEDIUM",
  UTM_SOURCE = "UTM_SOURCE",
  UTM_TERM = "UTM_TERM"
}

export enum StatisticOtherGroupsEnum {
  CANCEL_REASON = "CANCEL_REASON",
  SPAM_REASON = "SPAM_REASON",
  CURRENCY = "CURRENCY",
}

export enum StatisticGroupsCultureMediaEnum {
  OFFER = "OFFER",
  HOURS = "HOURS",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
  COUNTRY = "COUNTRY",
  REGION = "REGION",
  FLOW = "FLOW",
  BUNCH = "BUNCH",
  WEBMASTER = "WEBMASTER",
  EXTERNAL_WEBMASTER = "EXTERNAL_WEBMASTER",
  ADVERTISER = "ADVERTISER",
  WEBMASTER_LABEL = "WEBMASTER_LABEL",
  MANAGER = "MANAGER",
  WITHDRAW = "WITHDRAW",
  CATEGORY = "CATEGORY",
  AGENT = "AGENT",
  GLOBAL_SOURCE = "GLOBAL_SOURCE",
  ACTUAL_WEBMASTER_ID = "ACTUAL_WEBMASTER_ID",
  ACTUAL_WEBMASTER_INT_ID = "ACTUAL_WEBMASTER_INT_ID",
  UTM_CAMPAIGN = "UTM_CAMPAIGN",
  UTM_CONTENT = "UTM_CONTENT",
  UTM_MEDIUM = "UTM_MEDIUM",
  UTM_SOURCE = "UTM_SOURCE",
  UTM_TERM = "UTM_TERM"
}