<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @submit="validate">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.users.common.modal.password.header.title") }}
            </h5>
        </template>
        <ValidationProvider
            ref="validationProvider"
            rules="minStringLength">
            <EditUserPasswordField
                v-model="_password"
                :message="$t('common.validation.password')"
                :label="$t('admin.users.common.modal.password.body.password')"
                :placeholder="$t('admin.users.common.modal.password.body.password')">
            </EditUserPasswordField>
        </ValidationProvider>
        <template #footer>
            <div class="container">
                <div class="columns is-pulled-right">
                    <div class="buttons column is-4">
                        <b-button
                            class="is-success is-light"
                            :loading="isLoading"
                            native-type="submit">
                            {{ $t('common.buttons.save') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { Modal } from "@core/mixins/modal";
  import { extend, ValidationProvider } from "vee-validate";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";

  export default {
    name: "PasswordModal",

    mixins: [Modal],

    components: {
      ModalBox,
      ValidationProvider,
      EditUserPasswordField
    },

    props: {
      password: {
        type: String,
        default: null
      }
    },

    mounted () {
      extend("minStringLength", value => {
        if (value && `${ value }`.length > 5) {
          return true;
        }

        return this.$t("admin.users.common.modal.password.messages.minLength");
      });
    },

    computed: {
      _password: {
        get () {
          return this.password;
        },

        set (value) {
          this.$emit("update:password", value);
        }
      }
    },

    methods: {
      async validate () {
        const { errors } = await this.$refs.validationProvider.validate();

        if (errors.length) {
          this.$buefy.toast.open({
            message: errors[0],
            type: "is-danger"
          });
        } else {
          this.$emit("submit");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card-body {
      overflow: visible;
    }
  }
</style>
