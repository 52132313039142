import Service from "@core/services/common/Service";
import { BaseFiltersInput } from "@core/store/types/common/statistic/generic/statisticFilters";
import dashboardStatistic from "@core/services/advertiser/statistic/graphql/dashboardStatistic.graphql";
import statistic from "@core/services/advertiser/statistic/graphql/statistic.graphql";
import statisticNew from "@core/services/advertiser/statistic/graphql/statisticNew.graphql";
import { StatisticResponseData } from "@core/store/types/common/statistic/common/statistic";

export class StatisticService extends Service {
  public static getOffers (params: any): any {
    return this.rest.get("/advertiser/offers", { params });
  }

  public static async getAdvertiserStatistic (
    filters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: {
          query: statistic,
          variables: {
            filters,
            groups
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getAdvertiserStatisticNew (
    filters: BaseFiltersInput,
    groups: string[],
    limit: number,
    offset: number,
    sort: Nullable<string | string[]>,
    order: string | null
  ): Promise<StatisticResponseData> {
    try {
      return await this.api.get("", {
        params: {
          query: statisticNew,
          variables: {
            filters,
            groups,
            limit,
            offset,
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getAdvertiserDashboardStatistic (
    filters: BaseFiltersInput,
    groups: string[]
  ): Promise<StatisticResponseData> {
    try {
      const { data: { statistics: data } } = await this.api.get("", {
        params: {
          query: dashboardStatistic,
          variables: {
            filters,
            groups
          }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}
