import { Country } from "@core/logic/admin/finances/finances";
import { GET_AVAILABLE_DOMONETIZATIONS } from "@core/store/action-constants";
import { DomonetizationService } from "@core/services/webmaster/domonetization/domonetizationServise";
import { RootState } from "@core/store/root-state";
import { SET_AVAILABLE_DOMONETIZATIONS } from "@core/store/mutation-constants";
import { ActionTree, Module, MutationTree } from "vuex";

export type DomonetizationsItem = {
  alias: string
  name: string
  isAvailable: boolean
  countries: Country[],
  createdAt: Date
}

export type DomonetizationsState = {
  data: Array<DomonetizationsItem>
}


class AvailableDomonetizations implements Module<DomonetizationsState, RootState> {
  public namespaced: boolean;
  public state: DomonetizationsState | undefined;
  private readonly initState: () => DomonetizationsState;
  
  constructor () {
    this.namespaced = true;
    
    this.initState = (): DomonetizationsState => ({
      data: []
    });
  
    this.state = this.initState();
    
    return this;
  }
  
  public mutations: MutationTree<DomonetizationsState> = {
    [SET_AVAILABLE_DOMONETIZATIONS]: (state, { availableDomonetizations }): void => {
      state.data = availableDomonetizations;
    }
  }
  
  public actions: ActionTree<DomonetizationsState, RootState> = {
    [GET_AVAILABLE_DOMONETIZATIONS]: async ({ commit }, id): Promise<Array<DomonetizationsItem>> => {
      const { data } = await DomonetizationService.getAvailableDomonetization(id);
      commit(SET_AVAILABLE_DOMONETIZATIONS, data);
    
      return data;
    }
  }
}

export const availableDomonetizations = new AvailableDomonetizations();