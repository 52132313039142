import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import CRM from "@core/services/admin/lists/graphql/CRM.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class CRMService extends Service {
  public static getCRMList (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialCRM?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialCRMLimit = initialCRM?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: CRM,
          variables: {
            limit,
            offset,
            filters,
            initialCRM,
            initialCRMLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
