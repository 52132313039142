import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import offer from "../../webmaster/offer/graphql/offer.graphql";
import offerAdvertiser from "@core/services/advertiser/offer/graphql/offerAdvertiser.graphql";
import landingFlows from "../../webmaster/offer/graphql/landingFlows.graphql";
import removeFlow from "../../webmaster/offer/graphql/removeFlow.graphql";
import webmasterJoinToOfferQuery from "../../webmaster/offer/graphql/webmasterJoinToOffer.graphql";
import excludeWebmasterFromOfferQuery from "../../webmaster/offer/graphql/excludeWebmasterFromOffer.graphql";
import { FlowsState } from "@core/store/types/webmaster/flows";
import { WebmasterOffer } from "@core/store/logic/webmaster/offer/WebmasterOffer";
import offerByIntId from "@core/services/common/offer/OfferByIntId.generator";
import OfferLanding from "@core/services/common/offer/OfferLanding.generator";
import { OfferLandingsState } from "@core/store/types/admin/offer/OfferLandings";

export class OfferService extends Service {
  public static async getOffer (offerId: string, user: string): Promise<WebmasterOffer> {
    try {
      const { data } = await this.api.post("", {
        query: user === "advertiser" ? offerAdvertiser : offer,
        variables: {
          offerId
        }
      });
      return new WebmasterOffer(data.offer);
    } catch (e) {
      throw e;
    }
  }
  
  public static async getOfferByIndId (intId: number, user: string, isShowCommissions: boolean): Promise<WebmasterOffer> {
    try {
      const { data } = await this.api.get("", {
        params: offerByIntId(intId, user, isShowCommissions)
      });
      return new WebmasterOffer(data.offerByIntId);
    } catch (e) {
      throw e;
    }
  }
  
  public static async getLandingPages (payload: OfferLandingsState, user: string): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: OfferLanding(payload, user)
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getFlows (
    limit: number,
    offset: number,
    filters: FlowsState["filters"]
  ): Promise<AxiosPromise> {
    try {
      return await this.api.get("", {
        params: {
          query: landingFlows,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async deleteFlow (flowId: string): Promise<AxiosPromise> {
    try {
      const { data: { removeLandingFlow } } = await this.api.get("", {
        params: {
          query: removeFlow,
          variables: { flowId }
        }
      });

      return removeLandingFlow;
    } catch (e) {
      throw e;
    }
  }

  public static async webmasterJoinToOffer (offerId: string): Promise<AxiosPromise> {
    try {
      const { data: { webmasterJoinToOffer } } = await this.api.post("", {
        query: webmasterJoinToOfferQuery,
        variables: {
          offerId
        }
      });

      return webmasterJoinToOffer;
    } catch (e) {
      throw e;
    }
  }

  public static async excludeWebmasterFromOffer (offerId: string): Promise<AxiosPromise> {
    try {
      const { data: { excludeWebmasterFromOffer } } = await this.api.post("", {
        query: excludeWebmasterFromOfferQuery,
        variables: {
          offerId
        }
      });

      return excludeWebmasterFromOffer;
    } catch (e) {
      throw e;
    }
  }
}
