<template functional>
    <span
        v-if="props.user && props.user.login && props.user.intId"
        :class="['nowrap']">
        <template v-if="props.intId && props.login">
            {{ $options.methods.formatEmptyString(props.user.login) }}
            [{{ $options.methods.formatEmptyString(props.user.intId) }}]
        </template>
        <template v-else>
            <template v-if="!props.intId">
                {{ $options.methods.formatEmptyString(props.user.login) }}
            </template>
            <template v-if="!props.login && !props.intId">
                [{{ $options.methods.formatEmptyString(props.user.intId) }}]
            </template>
            <template v-if="props.intId">
                {{ $options.methods.formatEmptyString(props.user.intId) }}
            </template>
        </template>
    </span>
    <span v-else>
        {{ $options.methods.formatEmptyString(null) }}
    </span>
</template>

<script>
  import { formatEmptyString } from "@core/filters";

  export default {
    name: "UserOutput",
    props: {
      user: {
        type: Object,
        default: null
      },
      login: {
        type: Boolean,
        default: false
      },
      intId: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      formatEmptyString
    }
  };
</script>

<style scoped lang="scss">

</style>
