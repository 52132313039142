import { AnalyticTargetAudience as AnalyticTargetAudienceService } from
  "@core/services/common/statistic/AnalyticTargerAudience/analyticTargetAudience";
import {
  DATA_TABLE_BY_PERIOD, REDEMPTION_SHARE_BY_AGE, REDEMPTION_SHARE_BY_GENDER,
  SET_ANALYTIC_TARGET_AUDIENCE, SHARE_ORDERS_BY_AGE, SHARE_ORDERS_BY_GENDER,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { SET_EMPTY, GET_ANALYTIC_TARGET_AUDIENCE } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import { datepicker } from "@core/helpers/datepicker";
import {
  AnalyticAudienceDataByParams,
  AnalyticAudienceState,
  AnalyticAudienceTableData
} from "@core/store/types/common/statistic/analyticAudience/analyticAudience";
import { formatPercent, moment, toFixed } from "@core/filters";
import { Module } from "@core/store/logic/Module";
import i18n from "@core/plugins/i18n";
import { hasPermissions } from "@core/mixins/permissions";

const labelPeriod = (value: AnalyticAudienceState["filters"]["firstPeriod"]) => {
  const { dateStart, dateEnd } = value;
  return `${ formatDate(dateStart) } - ${ formatDate(dateEnd) }`;
};

const formatDate = (date: Date | string) => {
  return moment(date, "DD.MM.YYYY");
};

const defaultDataGraph = { count: 0, payout: 0, percent: 0, conversionPayout: 0 };

export class AnalyticTargetAudience extends Module<AnalyticAudienceState, RootState> {
  private readonly initialState: () => AnalyticAudienceState;

  private CountSumByValues (data: any, value: string[]) {
    const countSumByAge = {} as any;

    data.forEach((el: any) => {
      if (!countSumByAge[el[value[0]]]) {
        countSumByAge[el[value[0]]] = el[value[1]];
      } else {
        countSumByAge[el[value[0]]] += el[value[1]];
      }
    });
    return countSumByAge;
  }

  private formatDataByPeriods (data: any, firstValue: string, value: string[], defaultArr: string[]) {
    const defaultGender = defaultArr.map(el => ({ ...defaultDataGraph, [firstValue]: el }));
    const hasSecondPeriod = data.some((el: AnalyticAudienceDataByParams) => el.alias === "second");
    const hasFirstPeriod = data.some((el: AnalyticAudienceDataByParams) => el.alias === "first");

    data = [...data, (!hasSecondPeriod || !hasFirstPeriod) && { alias: hasFirstPeriod ? "second" : "first", data: defaultGender }].filter(Boolean);

    const sortedData = data.sort((a: AnalyticAudienceDataByParams, b: AnalyticAudienceDataByParams) => a.alias.localeCompare(b.alias));

    return sortedData?.map((item: AnalyticAudienceDataByParams) => {
      // @ts-ignore
      const formatData = (el = item.data) => this.CountSumByValues(defaultGender.map(i => el.find(el => el[firstValue] === i[firstValue]) || i), value);
      const aliasGender = data[+(item.alias === "first")];
      return {
        ...item,
        data: Object.entries(formatData()).filter((el, idx) => el[1] || Object.entries(formatData(aliasGender?.data ?? []))[idx][1])
      };
    });
  }

  private formatDataGraph (
    data: any,
    { firstPeriod, secondPeriod, comparisonPeriods }: AnalyticAudienceState["filters"],
    value: string[]
  ) {
    const firstValue = value[0];
    const hasAge = firstValue === "age";
    let formattedData;

    if (comparisonPeriods && data.length) {
      const defaultArr = hasAge ? ["18-25","26-35","36-45","46-51","51-55","61+"] : ["FEMALE","MALE"];
      formattedData = this.formatDataByPeriods(data, firstValue, value, defaultArr);
    } else {
      formattedData = data.map((el: AnalyticAudienceDataByParams) => ({ ...el, data: Object.entries(this.CountSumByValues(el.data, value)) }));
    }

    return formattedData.map((item: AnalyticAudienceDataByParams) => ({
      legendName: labelPeriod(item.alias === "first" ? firstPeriod : secondPeriod),
      // @ts-ignore
      data: item.data.map(([key, value]) => ({
        key: hasAge ? key : i18n.t(`common.entity.sex.${ key === "MALE" ? "man" : "woman" }`),
        value: this.FormatPercent(value)
      }))
    }));
  }

  private FormatPercent (value: unknown): string {
    return `${ toFixed(formatPercent(value), 2) }%`;
  }
  
  constructor () {
    super();
    
    this.namespaced = true;
    
    this.modules({});
    
    this.initialState = (): AnalyticAudienceState => {
      return {
        analyticAudience: {},
        filters: {
          firstPeriod: datepicker({ amount: 28, unit: "days", amountEnd: 15, unitEnd: "days" }),
          secondPeriod: datepicker({ amount: 42, unit: "days", amountEnd: 28, unitEnd: "days" }),
          offerId: null,
          countryId: null,
          categoryId: null,
          webmasterId: null,
          comparisonPeriods: false
        }
      };
    };

    this.state(this.initialState());

    this.getters({
      [DATA_TABLE_BY_PERIOD]: ({ analyticAudience: { table } }) => {
        const secondData = table?.items.find(el => el.alias === "second")?.data || [];
        const firstData = table?.items.find(el => el.alias === "first")?.data || [];

        const combinedArray = firstData.concat(secondData.filter(obj => {
          return !firstData.some(i => i.sex === obj.sex && i.age === obj.age);
        })) || [];

        return {
          items: combinedArray.map(el => {
            const secondDataByParams = secondData.find(i => i.age === el.age && i.sex === el.sex) ?? {} as AnalyticAudienceTableData;
            const firstDataByParams = firstData.find(i => i.age === el.age && i.sex === el.sex) ?? {} as AnalyticAudienceTableData;
            const leadsDifference = (secondDataByParams.conversionLeads || 0) - (firstDataByParams.conversionLeads || 0);

            return {
              ...el,
              firstConversionPayout: firstDataByParams.conversionPayout,
              firstColor: firstDataByParams.color,
              firstConversionLeads: firstDataByParams.conversionLeads,
              secondConversionPayout: secondDataByParams.conversionPayout,
              secondDiffConversionPayout: secondDataByParams.diffConversionPayout,
              secondColor: secondDataByParams.color,
              secondConversionLeads: secondDataByParams.conversionLeads,
              leadsDifference
            };
          })
            .sort((a, b) => {
              if (a.sex !== b.sex) {
                return a.sex.localeCompare(b.sex);
              } else {
                return a.age.localeCompare(b.age);
              }
            }),
          aggregates: table?.aggregates
        };
      },

      hasRedemption: (state, getters, rootState, rootGetters) =>
        hasPermissions(["STATISTICS.SHOW.REDEMPTION"], rootGetters.permissions),

      [SHARE_ORDERS_BY_AGE]: ({ analyticAudience: { dataByAge = [] }, filters }) => {
        return this.formatDataGraph(dataByAge, filters,["age", "percent"]);
      },

      [SHARE_ORDERS_BY_GENDER]: ({ analyticAudience: { dataBySex = [] }, filters }) => {
        return this.formatDataGraph(dataBySex, filters,["sex", "percent"]);
      },

      [REDEMPTION_SHARE_BY_GENDER]: ({ analyticAudience: { dataBySex = [] }, filters }) => {
        return this.formatDataGraph(dataBySex, filters,["sex", "conversionPayout"]);
      },

      [REDEMPTION_SHARE_BY_AGE]: ({ analyticAudience: { dataByAge = [] }, filters }) => {
        return this.formatDataGraph(dataByAge, filters, ["age", "conversionPayout"]);
      }
    });

    this.mutations({
      [SET_ANALYTIC_TARGET_AUDIENCE] (state, data: AnalyticAudienceState["analyticAudience"]): void {
        state.analyticAudience = data;
      },

      [UPDATE_FILTERS] (state, filter: AnalyticAudienceState["filters"]): void {
        state.filters = { ...state.filters, ...filter };
      }
    });
    
    this.actions({
      async [GET_ANALYTIC_TARGET_AUDIENCE] ({ commit, state: { filters: { firstPeriod, secondPeriod, offerId, webmasterId, comparisonPeriods, ...fields } }, rootGetters }) {
        const first = { alias: "first", ...firstPeriod };
        const second = { alias: "second", ...secondPeriod };
        const periods = comparisonPeriods ? [first, second] : [first];
        const includePayout = hasPermissions(["STATISTICS.SHOW.REDEMPTION"], rootGetters.permissions);

        const { table, dataByAge, dataBySex } = await AnalyticTargetAudienceService
          .getAnalyticTargetAudience(
            offerId,
            webmasterId,
            periods, 
            { ...fields },
            includePayout
          );

        commit(SET_ANALYTIC_TARGET_AUDIENCE, { table: table, dataByAge, dataBySex });
      },

      [UPDATE_FILTERS] ({ commit }, filter: AnalyticAudienceState["filters"]): void {
        commit(UPDATE_FILTERS, filter);
      },

      [SET_EMPTY] ({ commit }, target?: string) {
        commit(SET_EMPTY, target);
      }
    });
    
    this.mutations({
      [SET_EMPTY]: (state, target?: string) => {
        if (target) {
          // @ts-ignore
          state[target] = { ...this.initialState()[target], offerId: state[target].offerId };
        } else {
          Object.assign(state, this.initialState());
        }
      }
    });
    
    return this;
  }
}

