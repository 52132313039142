import { GraphQLServer } from "@core/plugins/axiosGraphQL";
import Service from "@core/services/common/Service";
import { PaymentIntegrationsListResponse } from "@core/store/types/admin/lists/paymentIntegrationsListState";
import { AxiosPromise } from "axios";
import PaymentIntegrations from "../../admin/lists/graphql/PaymentIntegrations.graphql";

export class PaymentIntegrationsListService extends Service {
  public static getPaymentIntegrationsList (
    // filters: PartialPaymentIntegrationsListState["filters"],
    limit: number, offset: number
    // initialPaymentIntegrations: PartialPaymentIntegrationsListState["filters"] = {}
  ): AxiosPromise<PaymentIntegrationsListResponse> {
    //
    // const initialPaymentIntegrationsLimit = initialPaymentIntegrations?.id?.length || 0;
    // const isInitialPaymentIntegrations = !!initialPaymentIntegrations?.id;
    // limit = Math.floor(initialPaymentIntegrationsLimit / limit + 1) * limit - initialPaymentIntegrationsLimit;
    return GraphQLServer.get("", {
      params: {
        query: PaymentIntegrations,
        variables: {
          limit,
          offset
          // filters,
          // initialPaymentIntegrations,
          // initialPaymentIntegrationsLimit,
          // isInitialPaymentIntegrations
        }
      }
    });
  }
}
