import cloneOffer from "@core/services/admin/offer/edit/graphql/cloneOffer.graphql";
import Service from "@core/services/common/Service";
import { EditableOffer } from "@core/store/logic/admin/offer/EditableOffer";
import { AxiosPromise } from "axios";
import getRequirements from "@core/services/admin/offer/edit/graphql/getRequirements.generator";
import offerGenerator from "@core/services/admin/offer/edit/graphql/offer.generator";

export class OfferEditService extends Service {
  public static getRequirements (offerId: string): AxiosPromise {
    try {
      return this.api.get("", {
        params: getRequirements(offerId)
      });
    } catch (e) {
      throw e;
    }
  }

  public static async getOffer (offerId: string, isClone: boolean): Promise<EditableOffer> {
    try {
      const params = isClone ? { query: cloneOffer, variables: { offerId } } : { params: offerGenerator(offerId) };
      const { data: { offer: responseOffer } } = await this.api[isClone ? "post" : "get"]("", { ...params });
      return new EditableOffer(responseOffer);
    } catch (e) {
      throw e;
    }
  }
}


