import {
  UPDATE_OFFER_LANDINGS,
  SET_OFFER_LANDINGS,
  UPDATE_PAGINATION,
  UPDATE_FILTERS
} from "@core/store/mutation-constants";
import { ADD_OFFER_CONVERSION } from "@core/store/action-constants";
import { Module } from "@core/store/logic/Module";
import { LandingPage, Pagination } from "@core/store/types/common";
import { OfferLandings, OfferLandingsFilters, OfferLandingsState } from "@core/store/types/admin/offer/OfferLandings";
import { OfferConversionList } from "@core/store/types/admin/offer/lists/offerConversionList";
import { OfferService } from "@core/services/common/offer/OfferService";

export class OfferLanding<S extends OfferLandingsState, R> extends Module<S, R> {
  constructor () {
    super();
    
    this.actions({
      async GET_LANDINGS ({ state, commit, dispatch, rootGetters }, offerId: string): Promise<void> {
        const limit = state.pagination.perPage;
        const offset = (state.pagination.page - 1) * state.pagination.perPage;
        const { pageType, ...fields } = state.filters;
        const filters = {
          ...fields,
          offerId,
          pageType: pageType?.toUpperCase()
        };
    
        const { data: { landingPages } } = await OfferService.getLandingPages({
          limit,
          offset,
          filters
        },
        rootGetters.role);
    
        if (offset === 0) {
          commit(SET_OFFER_LANDINGS, landingPages);
        } else {
          commit(UPDATE_OFFER_LANDINGS, landingPages);
          dispatch(ADD_OFFER_CONVERSION);
        }
      },
      
      [UPDATE_FILTERS] ({ commit }, filter: OfferLandingsFilters): void {
        commit(UPDATE_FILTERS, filter);
      },
  
      [UPDATE_PAGINATION] ({ commit }, pagination: Pagination): void {
        commit(UPDATE_PAGINATION, pagination);
      }
    });
    
    this.mutations({
      [ADD_OFFER_CONVERSION] (state: OfferLandingsState, conversionList: OfferConversionList[]): void {
        const pageType = state.filters.pageType?.toUpperCase();
        state.landings.items = state.landings?.items?.map((el: LandingPage) => {
          const offerConversion = conversionList
            .find((item: OfferConversionList) => item.pageType === pageType && item.pageId === el.id);
          const { cr, isNew } = offerConversion ?? {};

          return {
            ...el,
            cr,
            isNew
          };
        });
      },

      [UPDATE_OFFER_LANDINGS] (state: OfferLandingsState, payload): void {
        state.landings = {
          count: payload.count,
          items: state.landings?.items?.concat(payload.items as LandingPage[])
        };
      },
      
      [SET_OFFER_LANDINGS] (state: OfferLandingsState, landings: OfferLandings): void {
        state.landings = landings;
      },
  
      [UPDATE_FILTERS] (state: OfferLandingsState, filters: OfferLandingsFilters): void {
        state.filters = { ...state.filters, ...filters };
      },
      
      [UPDATE_PAGINATION] (state: OfferLandingsState, pagination: Pagination): void {
        state.pagination = { ...state.pagination, ...pagination };
      }
    });
    
    return this;
  }
}