import Service from "@core/services/common/Service";
import {
  WebmasterIndividualRatesResponse,
  WebmastersRatesFilters,
  WebmastersRatesState
} from "@core/store/types/admin/users/webmasters/Detail";
import summaryRateByWebmasterGenerator
  from "@core/services/admin/users/webmasters/graphql/summaryRateByWebmaster.generator";

export class WebmastersDetailService extends Service {
  public static getWebmasterIndividualRates (limit: number, offset: number,
    webmasterId: string[], filters: WebmastersRatesFilters, sort: WebmastersRatesState["sort"],
    order: WebmastersRatesState["order"]): Promise<WebmasterIndividualRatesResponse> {
    try {
      return this.api.get("", {
        params: summaryRateByWebmasterGenerator(
          limit,
          offset,
          webmasterId,
          filters,
          sort,
          order
        )
      });
    } catch (e) {
      throw e;
    }
  }
}
