<template>
    <div class="l-loading">
        <slot></slot>
        <b-loading
            :active="active"
            :animation="animation"
            :is-full-page="isFullPage"
            :can-cancel="canCancel"
            :on-cancel="onCancel"
            @close="close">
            <Preloader
                v-if="isCustomLoader"
                :is-label-exist="isLabelExist">
            </Preloader>
        </b-loading>
    </div>
</template>

<script>
  import BaseLoading from "@/components/Common/Base/BaseLoading";
  import Preloader from "@/components/Common/Preloader";

  export default {
    name: "LLoading",
    extends: BaseLoading,
    components: { Preloader }
  };
</script>

<style scoped lang="scss">
</style>
