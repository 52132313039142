import { Filters } from "../../../../common/generators/filters";
import { ExternalWebmasterItem } from "@core/store/types/admin/statistic/common/externalWebmasterFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";
import { BaseFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class ExternalWebmasterFilters extends Filters<ExternalWebmasterItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: BaseFiltersInput, initialFilters: BaseFiltersInput) => {
    const { data: { statisticFilters: { data } } } = await StatisticsService.getExternalWebmasterFilters(offset, limit, filters, initialFilters);
    
    return {
      data: {
        items: data.items.map((item: any) => ({ id: item })),
        count: data?.count
      },
      initialData: {
        items: [],
        count: 0
      }
    };
  }

  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const externalWebmasterId = ids;
      return { externalWebmasterId };
    }
  };
}

export const externalWebmasterFilters = new ExternalWebmasterFilters();
