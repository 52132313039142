import { UserFiltersInput } from "@core/store/types/admin/statistic/common/generic/statisticFilters";
import integrationsCRM from "@core/services/admin/lists/graphql/integrationsCRM.graphql";
import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";

export class IntegrationCRMListService extends Service {
  public static getIntegrationCRMList (
    offset = 0,
    limit = 25,
    filters?: UserFiltersInput,
    initialIntegrations?: UserFiltersInput
  ): Promise<AxiosPromise> {
    try {
      const initialIntegrationsLimit = initialIntegrations?.id?.length || 0;

      return this.api.get("", {
        params: {
          query: integrationsCRM,
          variables: {
            limit,
            offset,
            filters,
            initialIntegrations,
            initialIntegrationsLimit
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
