import { GET_OFFER_CONVERSION, SET_EMPTY } from "@core/store/action-constants";
import { SET_OFFER_CONVERSION } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { OfferConversionState } from "@core/store/types/admin/offer/lists/offerConversionList";
import { OfferConversionService } from "@core/services/common/offer/OfferConversionService";
import { fixedFormatPercent } from "@core/flowMethods";

const initialState = (): OfferConversionState => {
  return {
    offerConversion: []
  };
};

const state: () => OfferConversionState = initialState;

const getters: GetterTree<OfferConversionState, RootState> = {};

const mutations: MutationTree<OfferConversionState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_OFFER_CONVERSION] (state, offerConversion: OfferConversionState["offerConversion"]): void {
    state.offerConversion = offerConversion;
  }
};

const actions: ActionTree<OfferConversionState, RootState> = {
  async [GET_OFFER_CONVERSION] ({ commit }, offerId: string): Promise<void> {
    const { data: { offerConversion } } = await OfferConversionService.getOfferConversion(offerId);
    
    const data = offerConversion
      .map(({ cr, ...otherFields }) => ({ cr: fixedFormatPercent(cr) , ...otherFields }));
    
    commit(SET_OFFER_CONVERSION, data);
  }
};

export const offerConversionList: Module<OfferConversionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
