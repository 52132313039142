import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { StatisticService } from "@core/logic/webmaster/statistic/statistic";
import {
  WebmasterStatisticExcludedFilters,
  WebmasterStatisticFilters,
  WebmasterStatisticState
} from "@core/store/types/webmaster/statistic";
import {
  SET_EMPTY,
  SET_STATISTIC,
  SET_TODAY_STATISTIC,
  UPDATE_STATISTIC_FILTERS,
  UPDATE_STATISTIC_EXCLUDED_FILTERS
} from "@core/store/mutation-constants";
import { GET_STATISTIC } from "@core/store/action-constants";
import { detailing } from "./modules/detailing";
import { postbacks } from "./modules/postbacks";
import { statistics as statisticsModule } from "@core/store/modules/webmaster/statistic/modules/statistics";
import { sortFieldGroupsStatistic } from "@core/helpers/sortFieldGroupsStatistic";
import { datepicker } from "@core/helpers/datepicker";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";
import { domonetization } from "@core/store/modules/webmaster/statistic/modules/domonetization";
import _snakeCase from "lodash/snakeCase";

const initialState = (): WebmasterStatisticState => {
  return {
    statistics: null,
    statisticFilters: {
      filters: {
        datepicker: datepicker({ amount: 1, unit: "weeks" }),
        groups: ["offer", null],
        globalSource: null,
        utmCampaign: null,
        webmasterId: null,
        utmContent: null,
        currency: "USD",
        utmMedium: null,
        utmSource: null,
        countryId: null,
        regionId: null,
        bunchId: null,
        utmTerm: null,
        offerId: null,
        flowId: null
      },
      excludedFilters: {
        excludedUtmCampaign: null,
        excludedWebmasterId: null,
        excludedUtmContent: null,
        excludedCountryId: null,
        excludedUtmSource: null,
        excludedUtmMedium: null,
        excludedRegionId: null,
        excludedOfferId: null,
        excludedBunchId: null,
        excludedUtmTerm: null,
        excludedFlowId: null
      }
    },
    todayStatistic: {
      confirmed: [],
      income: [],
      leads: [],
      clicks: []
    }
  };
};

const state: () => WebmasterStatisticState = initialState;

const getters: GetterTree<WebmasterStatisticState, RootState> = {};

const mutations: MutationTree<WebmasterStatisticState> = {
  [SET_STATISTIC] (state, statistics) {
    state.statistics = statistics;
  },
  
  [SET_TODAY_STATISTIC] (state, todayStatistic) {
    state.todayStatistic = todayStatistic;
  },

  [UPDATE_STATISTIC_FILTERS] (state, filters: WebmasterStatisticFilters): void {
    state.statisticFilters.filters = { ...state.statisticFilters.filters, ...filters };
  },

  [UPDATE_STATISTIC_EXCLUDED_FILTERS] (state, excludedFilters: WebmasterStatisticExcludedFilters): void {
    state.statisticFilters.excludedFilters = { ...state.statisticFilters.excludedFilters, ...excludedFilters };
  },

  [SET_EMPTY] (state, { target, filters } = {}) {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], filters: { ...initialState()[target].filters, ...filters } };
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<WebmasterStatisticState, RootState> = {
  async [GET_STATISTIC] ({ state, commit, rootGetters: { isAgent }, rootState }): Promise<void> {
    const { verticals: { currentVertical: vertical } } = rootState;
    const { datepicker, groups, ...otherFilters } = state.statisticFilters.filters;
    const excludedFilters = state.statisticFilters.excludedFilters;
    const filters = { ...datepicker, ...otherFilters };

    const changedExcludedFilters = prepareExcludedFilters(excludedFilters);
    const changedGroups = groups.filter(Boolean).map((item: any) => _snakeCase(item).toUpperCase());

    const statistics = await StatisticService.getWebmasterStatistic(
      { ...filters, vertical },
      changedExcludedFilters,
      // @ts-ignore
      changedGroups,
      isAgent
    );
  
    sortFieldGroupsStatistic(statistics.items, "country", "region", "category");
    
    commit(SET_STATISTIC, statistics);
  },

  [SET_EMPTY] ({ commit, dispatch, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
    dispatch("statisticsFiltersModule/SET_EMPTY");
  },

  [UPDATE_STATISTIC_FILTERS] ({ commit }, payload) {
    commit(UPDATE_STATISTIC_FILTERS, payload);
  },

  [UPDATE_STATISTIC_EXCLUDED_FILTERS] ({ commit }, payload) {
    commit(UPDATE_STATISTIC_EXCLUDED_FILTERS, payload);
  }
};

export const statistic: Module<WebmasterStatisticState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    detailing,
    statisticsFiltersModule: statisticsModule,
    postbacks,
    domonetization
  }
};
