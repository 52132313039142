import User from "@core/logic/user/User";

export default class Admin extends User {
  
  public constructor (response?: Admin) {
    super(response);
    
    // if (response) {
    // }
  }
}
