import { statusEnum, actionsEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";
import iconRequest from "@/assets/Offer/request.svg";
import { formatEmptyString, moment } from "@core/filters";
import { mapState } from "vuex";

// @vue/components
export const DropdownCapacity = {
  props: {
    data: {
      type: Object,
      default: null
    },
    
    offer: {
      type: Object,
      default: null
    },
    
    webmaster: {
      type: Object,
      default: null
    },
    
    offerId: {
      type: String,
      default: null
    },
    
    webmasterId: {
      type: String,
      default: null
    },
    
    externalWebmasterId: {
      type: String,
      default: null
    },
    
    tooltipType: {
      type: String,
      default: "is-info"
    },
    
    stylesIcon: {
      type: null,
      default: null
    },
    
    moduleNamespace: {
      type: String,
      default: "admin/capacityAndBumps/capacity/webmastersCapacity"
    }
  },
  
  data () {
    return {
      isActive: false
    };
  },
  
  computed: {
    ...mapState("admin/profile", {
      selfId: ({ user }) => user.id
    }),
  
    statusColorIcon () {
      const statusColorMap = {
        [statusEnum.PENDING]: "warning",
        [statusEnum.APPROVED]: "success",
        [statusEnum.REJECTED]: "danger",
        [statusEnum.DELETED]: "danger",
        default: "black"
      };
    
      return `has-text-${ this.disabled ? "light" : statusColorMap[this.data?.status] || statusColorMap.default }`;
    },
  
    statusColor () {
      const statusColorMap = {
        [statusEnum.PENDING]: "warning",
        [statusEnum.APPROVED]: "success",
        default: "danger"
      };
    
      return `has-text-${ statusColorMap[this.status] || statusColorMap.default }`;
    },
  
    submitButtonText () {
      const buttonNameMap = {
        [statusEnum.DELETED]: "resendRequest",
        default: this.data ? "updateRequest" : "sendRequest"
      };
    
      const name = buttonNameMap[this.data?.status] || buttonNameMap.default;
    
      return this.$t(`admin.offers.capacity.table.dropdown.button.${ name }`);
    },
  
    createOrUpdateDate () {
      const { createdAt, updatedAt } = this;
      const format = `DD.MM.YY ${ this.$t("common.entity.tileItem.preposition") } HH:mm`;
      const date = updatedAt || createdAt;
      const key = updatedAt
        ? "admin.offers.capacity.table.dropdown.updatedAt"
        : "admin.offers.capacity.table.dropdown.createdAt";
    
      return `${ this.$t(key) } ${ moment(date, format) }`;
    },
  
    isEditRequest () {
      const { data, selfId, isApproveRequest } = this;
      return !this.status || data?.creator.id === selfId && !isApproveRequest;
    },
  
    userPermissions () {
      return (this.$store || { getters: {} }).getters.permissions;
    },
    
    iconRequest () {
      return iconRequest;
    },
  
    statusEnum () {
      return statusEnum;
    },
  
    actionsEnum () {
      return actionsEnum;
    }
  },
  
  methods: {
    submitFormMethod () {
      if (this.status && this.status !== statusEnum.DELETED) {
        return this.capacityRequestMethod(actionsEnum.EDIT);
      } else {
        return this.capacityRequestMethod(actionsEnum.CREATE);
      }
    },
  
    closeDropdown () {
      this.$refs.dropdown.toggle();
    },
  
    resendRequest () {
      this.SET_EMPTY();
    },
  
    formatEmptyString
  }
};
