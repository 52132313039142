import { RequisitesListService } from "@core/services/admin/lists/RequisitesListService";
import { GET_INITIAL_REQUISITES, GET_REQUISITES_LIST, UPDATE_REQUISITES_LIST } from "@core/store/action-constants";
import {
  EMPTY_REQUISITES_LIST,
  UPDATE_REQUISITES_FILTERS,
  UPDATE_REQUISITES_LIST_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { PartialRequisitesListState, RequisitesListResponse } from "@core/store/types/admin/lists/RequisitesListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialRequisitesListState => {
  return {
    data: {},
    filters: {
      id: null,
      excludedId: null,
      paymentSystemId: null,
      userId: null
    },
    pagination: {
      page: 1,
      perPage: 10
    }
  };
};

const state: () => PartialRequisitesListState = initialState;

const getters: GetterTree<PartialRequisitesListState, RootState> = {};

const mutations: MutationTree<PartialRequisitesListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_REQUISITES_LIST] (state, requisites: RequisitesListResponse["requisites"]) {
    if (state.pagination.page !== 1 && state.data?.items && requisites) {
      state.data.items = Array.from(new Set([...state.data.items, ...requisites.items as []]));
    } else {
      state.data = requisites;
    }
  },

  [UPDATE_REQUISITES_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [UPDATE_REQUISITES_FILTERS] (state, filters: PartialRequisitesListState["filters"]) {
    state.filters = { ...state.filters, ...filters };
  },

  [EMPTY_REQUISITES_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialRequisitesListState, RootState> = {
  async [GET_REQUISITES_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const filters = state.filters;

    const { data: { requisites } } =
            await RequisitesListService.getRequisitesList(filters, limit, offset);

    commit(UPDATE_REQUISITES_LIST, requisites);
    return requisites;
  },

  async [GET_INITIAL_REQUISITES] ({ commit, state }, ids) {
    if (ids) {
      ids = Array.isArray(ids) ? ids : [ids];
    }
    const filters = state.filters;

    const { data: { requisites, initialRequisites } } = await RequisitesListService.getRequisitesList(
      filters,
      state.pagination.perPage,
      0,
      { id: ids });
    requisites.items = [...initialRequisites.items, ...requisites.items as []];

    commit(UPDATE_REQUISITES_LIST, requisites);
    return initialRequisites?.items?.length ? initialRequisites.items.map(user => user.id) : null;
  }
};

export const requisitesList: Module<PartialRequisitesListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
