import { CRMService } from "@core/services/admin/crm/CRMService";
import {
  DELETE_CRM,
  GET_CRM,
  GET_CRM_BY_ALIAS,
  UPDATE_CRM_PAGINATION, UPDATE_FLOW_REDIRECT,
  UPDATE_IS_WITHOUT_REQUEST, UPDATE_MODAL_DATA
} from "@core/store/action-constants";
import { crmCreateModal } from "@core/store/modules/admin/crm/crmCreateModal";
import {
  SET_CRM,
  UPDATE_CUSTOM_MACROS,
  UPDATE_MODAL_OPTIONS,
  UPDATE_CRM
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { CRMListState, CRMState } from "@core/store/types/admin/crm/CRMState";
import Vue from "vue";
import { ActionTree, Module, MutationTree } from "vuex";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { Pagination } from "@core/store/types/common";
import _get from "lodash/get";
import _last from "lodash/last";

const initialState = (): CRMState => {
  return {
    CRM: null,
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => CRMState = initialState;

const mutations: MutationTree<CRMState> = {
  SET_EMPTY: (state): CRMState => Object.assign(state, initialState()),

  [SET_CRM] (state: CRMState, payload: CRMState["CRM"]): void {
    state.CRM = payload;
  },
  
  [UPDATE_CRM] (state: CRMState, { count, items }: CRMListState["CRM"]): void {
    state.CRM = {
      count,
      items: state.CRM?.items?.concat(items || [])
    };
  },

  [UPDATE_CRM_PAGINATION] (state: CRMState, pagination: Pagination): void {
    state.pagination = { ...state.pagination, ...pagination };
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<CRMState, RootState> = {
  async [GET_CRM] ({ state, commit }): Promise<void> {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;

    try {
      const { data: { crms } } = await CRMService.getCRMs(
        limit,
        offset
      );
  
      if (offset === 0) {
        commit(SET_CRM, crms);
      } else {
        commit(UPDATE_CRM, crms);
      }
    } catch (e) {
      throw e;
    }
  },
  
  [UPDATE_CRM_PAGINATION] ({ commit }, pagination: Pagination): void {
    commit(UPDATE_CRM_PAGINATION, pagination);
  },

  async [GET_CRM_BY_ALIAS] ({ commit, dispatch }, alias: string): Promise<void> {
    try {
      const { data: { crm } } = await CRMService.getCRMByAlias(alias);
      if (!crm.recoveryDetail) {
        crm.recoveryDetail = {};
      }

      const { failRules, ...otherFieldsCrm } = crm;
      dispatch("UPDATE_INTEGRATION_DETAIL", otherFieldsCrm);
      dispatch(`${ UPDATE_FLOW_REDIRECT }`, otherFieldsCrm);
      dispatch(`crmCreateModal/crmFailRule/${ UPDATE_MODAL_DATA }`, { items: failRules, count: failRules?.length });

      commit(`crmCreateModal/${ UPDATE_CUSTOM_MACROS }`, {
        customFields: crm.defaultOptions,
        customMacros: Object.keys(crm.defaultOptions)
      });
      commit(`crmCreateModal/${ UPDATE_MODAL_OPTIONS }`, otherFieldsCrm);
    } catch (e) {
      throw e;
    }
  },

  async [DELETE_CRM] ({ commit }, alias: string): Promise<void> {
    const deletedCRM = await CRMService.deleteCRM(alias);
    commit("LOCAL_DELETE", { items: deletedCRM, target: "CRM", param: "name" });
  },

  UPDATE_INTEGRATION_DETAIL ({ dispatch }, crm): void {
    const updateField = (field: string): void => {
      const fields = field.split(".");
      const lastField = _last(fields) as string;
      const firstFields = field.replace(/(\w+)$/, "").replace(/\.$/, "");
      if (_get(crm, field)) {
        Vue.set(_get(crm, firstFields), lastField, JSON.stringify(_get(crm, field)));
      }
    };
    const fields = ["integrationDetail.requestBody", "integrationDetail.requestHeaders", "recoveryDetail.requestHeaders"];
    fields.forEach(e => updateField(e));

    if (!crm.integrationDetail) {
      dispatch(`crmCreateModal/${ UPDATE_IS_WITHOUT_REQUEST }`, true);
    }
  },

  [UPDATE_FLOW_REDIRECT] (_, crm): void {
    if (crm.flowRedirect) {
      Vue.set(crm, "flowRedirect", JSON.stringify(crm.flowRedirect));
    }
  },

  SET_EMPTY ({ commit }): void {
    commit("SET_EMPTY");
  }
};

export const crm: Module<CRMState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    crmCreateModal
  }
};
