import { Filters } from "../../../../common/generators/filters";
import { WebmasterItem } from "@core/store/types/admin/statistic/common/webmastersFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";
import { UserFiltersInput, FilterInput } from "@core/store/types/common/statistic/generic/statisticFilters";

class WebmastersFilters extends Filters<WebmasterItem, UserFiltersInput> {
  getData = async (offset: number, limit: number, filters: UserFiltersInput, initialFilters: UserFiltersInput) => {
    const { data } = await StatisticsService.getWebmastersFilters(offset, limit, filters, initialFilters);
    
    return {
      data: {
        items: data.items,
        count: data.count
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const webmasterId = Array.isArray(ids) ? ids : [ids];
      return { webmasterId };
    }
  };
}

export const webmastersFilters = new WebmastersFilters();
