
























import LazySelect from "@/components/Common/Select/LazySelect";
import Select from "@/components/Common/Select/Select";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import _startCase from "lodash/startCase";
import { useVerticals } from "@/stores/common/auth/verticalsStore";
import { defineProps, defineEmits } from "@vue/runtime-dom";
import { computed, onBeforeUnmount, unref } from "@vue/composition-api";
import { storeToRefs } from "pinia";
import { useWait } from "@/stores/common/waitStore";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: [Array, String],
    default: null
  },
  event: {
    type: String,
    default: "input"
  },
  placeholder: {
    type: String,
    default: ""
  },
  multiple: {
    type: Boolean,
    default: false
  },
  lazy: {
    type: Boolean,
    default: false
  },
  isLocal: {
    type: Boolean,
    default: false
  },
  hideNull: {
    type: Boolean,
    default: false
  },
  withVertical: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const localVerticals = Object.values(VerticalsEnum).map(name => ({
    name: _startCase(name.toLowerCase()),
    id: name
  }));
  const props = __props;
  const emit = __ctx.emit;
  const verticalsStore = useVerticals();
  const {
    preferredVertical,
    verticals,
    gamblingOnly,
    allVerticals,
    nutraOnly
  } = storeToRefs(verticalsStore);
  if (props.isLocal && props.withVertical) {
    emit(props.event, props.multiple ? [unref(preferredVertical)] : unref(preferredVertical));
  }
  const verticalsList = computed(() => props.isLocal ? localVerticals : unref(verticals));
  const disabled = computed(() => props.isLocal && !(nutraOnly.value && gamblingOnly.value) && !allVerticals.value);
  const isLoading = useWait(verticalsStore, "getVerticals");
  function getVerticals() {
    !props.isLocal && verticalsStore.getVerticals();
    const items = unref(verticalsList);
    return {
      items,
      count: items.length
    };
  }
  onBeforeUnmount(() => {
    verticalsStore.$reset("verticals");
  });
  return {
    LazySelect,
    Select,
    verticalsList,
    disabled,
    isLoading,
    getVerticals
  };
};
export default __sfc_main;
