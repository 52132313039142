import { PaginationParams } from "@core/logic/common/pagination-params";
import Service from "@core/services/common/Service";
import callCenters from "@core/services/common/offer/graphql/callCenters.graphql";

interface Advertiser {
  id: string;
  name: string;
  login: string;
}

export interface CallCenter {
  id: string;
  name: string;
  isDeleted: null;
  createdAt: string;
  advertiser: Advertiser;
  TimeTables: TimeTables;
}

export interface GetCallCentersParams extends PaginationParams {
  filters: {
    id: string;
  };
  timeZone: string;
}

interface Time {
  timeStart: string;
  timeFinish: string;
}

export interface TimeTables {
  monday: Time[];
  tuesday: Time[];
  wednesday: Time[];
  thursday: Time[];
  friday: Time[];
  saturday: Time[];
  sunday: Time[];
}

export interface CallCentersResponse {
  data: { callCenters: CallCenter }
}

class CallCentersService extends Service {
  // public static getCallCenters (params: GetCallCentersParams): Promise<AxiosResponse<CallCenter>> {
  //   return this.rest.get("/webmaster/callcenters", { params });
  // }
  public static async getCallCenters (
    limit: number,
    offset: number,
    filters: { id: string },
    timeZone: string
  ): Promise<CallCentersResponse> {
    try {
      return await this.api.get("", {
        params: {
          query: callCenters,
          variables: {
            limit,
            offset,
            filters,
            timeZone
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}

export default CallCentersService;