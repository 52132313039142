import i18n from "@core/plugins/i18n";
import { GET_APPROVED } from "@core/store/action-constants";
import { SET_EMPTY, UPDATE_APPROVED_LIST } from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ApprovedListState } from "@core/store/types/admin/lists/ApprovedListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): ApprovedListState => {
  return {
    data: {}
  };
};

const state: () => ApprovedListState = initialState;

const getters: GetterTree<ApprovedListState, RootState> = {};

const mutations: MutationTree<ApprovedListState> = {
  [UPDATE_APPROVED_LIST] (state, approved: ApprovedListState["data"]) {
    state.data = approved;
  },
  
  [SET_EMPTY] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<ApprovedListState, RootState> = {
  [GET_APPROVED] ({ commit }) {
    const items = [
      { label: i18n.t("admin.users.webmasters.filters.values.confirmed"), value: true },
      { label: i18n.t("admin.users.webmasters.filters.values.unconfirmed"), value: false }
    ];
    commit(UPDATE_APPROVED_LIST, {
      items,
      count: items.length
    });
  }
};

export const approvedList: Module<ApprovedListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
