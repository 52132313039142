import CallCentersService, {
  AddCallCenter,
  AddTimetable,
  CallCenter,
  CallCenterId,
  GetCallCentersParams
} from "@core/logic/admin/callCenters/callCenters";
import {
  CREATE_CALLCENTER,
  CREATE_TIMETABLE,
  DELETE_CALLCENTERS,
  EDIT_TIMETABLE,
  GET_AVAILABLE_CALLCENTERS_FOR_OFFER,
  GET_AVAILABLE_CALLCENTERS_UTC,
  GET_CALLCENTERS,
  GET_CALLCENTERS_UTC,
  GET_TIMETABLE
} from "@core/store/action-constants";
import { getCallCentersUTC } from "@core/store/modules/common/callCenters";
import {
  SET_AVAILABLE_CALLCENTERS_FOR_OFFER,
  UPDATE_CALLCENTERS_MODAL_ACTIVE,
  SET_ADVERTISER_ID_CALLCENTERS,
  SET_CALLCENTERS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

export interface CallCentersState {
  availableCallCenters: Nullable<CallCenter>;
  callCenters: Nullable<CallCenter>;
  advertiserIdCallCenters: Nullable<string>;
  isCallCentersModalActive: boolean;
}


const state: CallCentersState = {
  callCenters: null,
  advertiserIdCallCenters: null,
  availableCallCenters: null,
  isCallCentersModalActive: false
};

const getters: GetterTree<CallCentersState, RootState> = {
  [GET_CALLCENTERS_UTC]: state => getCallCentersUTC(state.callCenters),
  [GET_AVAILABLE_CALLCENTERS_UTC]: state => getCallCentersUTC(state.availableCallCenters)
};

const mutations: MutationTree<CallCentersState> = {
  [SET_CALLCENTERS]: (state, callCenters) => {
    state.callCenters = callCenters;
  },
  [SET_AVAILABLE_CALLCENTERS_FOR_OFFER]: (state, callCenters) => {
    state.availableCallCenters = callCenters;
  },
  [SET_ADVERTISER_ID_CALLCENTERS]: (state, advertiserId) => {
    state.advertiserIdCallCenters = advertiserId;
  },
  [UPDATE_CALLCENTERS_MODAL_ACTIVE] (state, payload) {
    state.isCallCentersModalActive = payload;
  }
};

const actions: ActionTree<CallCentersState, RootState> = {
  async [GET_CALLCENTERS] ({ commit }, params: GetCallCentersParams) {
    try {
      const { data } = await CallCentersService.getCallCenters(params);

      commit(SET_CALLCENTERS, data);
    } catch (error) {
      throw error;
    }
  },

  async [GET_AVAILABLE_CALLCENTERS_FOR_OFFER] ({ commit }, params: GetCallCentersParams) {
    try {
      const { data } = await CallCentersService.getCallCenters(params);
      commit(SET_AVAILABLE_CALLCENTERS_FOR_OFFER, data);
    } catch (error) {
      throw error;
    }
  },

  async [CREATE_CALLCENTER] (_, payload: AddCallCenter) {
    try {
      await CallCentersService.createCallCenter(payload);
    } catch (error) {
      throw error;
    }
  },

  async [DELETE_CALLCENTERS] (_, payload: CallCenterId) {
    try {
      await CallCentersService.deleteCallCenter(payload);
    } catch (error) {
      throw error;
    }
  },

  async [CREATE_TIMETABLE] (_, payload: AddTimetable) {
    try {
      await CallCentersService.addTimetable(payload);
    } catch (error) {
      throw error;
    }
  },

  async [GET_TIMETABLE] (_, payload: CallCenterId) {
    try {
      const { data } = await CallCentersService.getTimetable(payload);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async [EDIT_TIMETABLE] (_, payload: AddTimetable) {
    try {
      await CallCentersService.editTimetable(payload);
    } catch (error) {
      throw error;
    }
  }
};

export const callCenters: Module<CallCentersState, RootState> = {
  state,
  getters,
  actions,
  mutations
};
