<template>
    <ModalBox
        :is-active.sync="isPasswordModalActive"
        @closed="SET_EMPTY"
        @submit="updatePassword">
        <template #header>
            {{ $t(`advertiser.profile.values.changingPassword`) }}
        </template>
        <div class="columns">
            <div class="column">
                <b-field :label="$t(`advertiser.profile.labels.oldPassword`)">
                    <LInput
                        v-model="oldPassword"
                        :placeholder="$t(`advertiser.profile.labels.oldPassword`)"
                        type="password"
                        expanded
                        password-reveal>
                    </LInput>
                </b-field>
            </div>
            <div class="column">
                <EditUserPasswordField
                    v-model="newPassword"
                    :label="$t(`advertiser.profile.labels.newPassword`)"
                    :placeholder="$t(`advertiser.profile.labels.newPassword`)"
                    :message="$t('common.validation.password')">
                </EditUserPasswordField>
            </div>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            :loading="isPasswordUpdateLoading"
                            class="is-fullwidth is-info is-light"
                            native-type="submit"
                            expanded>
                            {{ $t(`advertiser.profile.values.changePassword`) }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth is-light"
                            expanded
                            @click="SET_EMPTY">
                            {{ $t(`advertiser.profile.values.cancel`) }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { CHANGE_USER_PASSWORD } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_MODAL, UPDATE_PASSWORD_FORM } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations } from "vuex";
  import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";

  export default {
    name: "AdvertiserProfilePasswordModal",
    
    components: {
      ModalBox,
      EditUserPasswordField
    },
    
    computed: {
      ...mapFields("advertiser/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),
      
      ...mapFields("password", {
        fields: ["oldPassword", "newPassword"],
        base: "form",
        action: UPDATE_PASSWORD_FORM
      }),
      
      isPasswordUpdateLoading () {
        return this.$wait(`password/${ CHANGE_USER_PASSWORD }`);
      }
    },
    
    methods: {
      ...mapMutations("advertiser/profile/password", {
        emptyModalState: SET_EMPTY
      }),
      
      ...mapMutations("password", {
        emptyPasswordState: SET_EMPTY
      }),
      
      ...mapActions("password", [
        CHANGE_USER_PASSWORD
      ]),

      [SET_EMPTY] () {
        this.emptyModalState();
        this.emptyPasswordState();
      },

      async updatePassword () {
        try {
          const { oldPassword, newPassword } = this;

          if (this.validatePassword(oldPassword, newPassword)) {
            await this.CHANGE_USER_PASSWORD();
          
            this.SET_EMPTY();
            this.$buefy.toast.open({ message: this.$t("advertiser.profile.modal.message"), type: "is-success" });
          }
        } catch (e) {
          let translation = "admin.profile.modal.password.messages.danger";
          switch (e.status) {
            case 400:
              translation = "common.profile.password.messages.incorrect";
              break;
          }
          
          this.$buefy.toast.open({ message: this.$t(translation), type: "is-danger" });
        }
      },

      validatePassword (oldPassword, newPassword) {
        let error = null;

        if (!oldPassword) {
          error = this.$t("advertiser.profile.modal.error.old");
        } else if (!newPassword) {
          error = this.$t("advertiser.profile.modal.error.new");
        } else if (newPassword === oldPassword) {
          error = this.$t("advertiser.profile.modal.error.equality");
        } else if (`${ newPassword }`.length < 6 || `${ oldPassword }`.length < 6) {
          error = this.$t("admin.users.common.modal.password.messages.minLength");
        }

        if (error) {
          this.$buefy.toast.open({ message: error, type: "is-danger" });
          return false;
        } else return true;
      }
    }
  };
</script>

<style>

</style>
