<template>
    <component
        :is="$listeners.save ? 'form' : 'div'"
        @submit.prevent="$emit('save')">
        <div class="columns is-multiline">
            <div class="column is-12">
                <BlockBox :title="$t(`webmaster.profile.labels.title`)">
                    <div class="columns is-multiline is-flex-mobile">
                        <div class="column is-6-tablet is-12-mobile">
                            <LTooltip
                                :label="$t(`webmaster.profile.labels.emailMessage`)"
                                class="is-block"
                                position="is-bottom"
                                type="is-danger">
                                <b-field :label="$t(`webmaster.profile.labels.email`)">
                                    <LInput
                                        v-if="profileLoaded"
                                        v-model="_user.email"
                                        disabled>
                                    </LInput>
                                    <b-skeleton
                                        v-else
                                        width="100%"
                                        height="46px"
                                        animated>
                                    </b-skeleton>
                                </b-field>
                            </LTooltip>
                        </div>
                        <div class="column is-6-tablet is-12-mobile">
                            <LTooltip
                                :label="$t(`webmaster.profile.labels.emailMessage`)"
                                class="is-block"
                                position="is-bottom"
                                type="is-danger">
                                <b-field :label="$t(`webmaster.profile.labels.login`)">
                                    <LInput
                                        v-if="profileLoaded"
                                        v-model="_user.login"
                                        disabled
                                        expanded>
                                    </LInput>
                                    <b-skeleton
                                        v-else
                                        width="100%"
                                        height="46px"
                                        animated>
                                    </b-skeleton>
                                </b-field>
                            </LTooltip>
                        </div>
                    </div>

                    <ContactsBox
                        :skype.sync="_user.skype"
                        :telegram.sync="_user.telegram"
                        :whats-app.sync="_user.whatsApp"
                        :viber.sync="_user.viber">
                        <template
                            v-if="!profileLoaded"
                            #skeleton>
                            <b-skeleton
                                width="100%"
                                height="46px"
                                animated>
                            </b-skeleton>
                        </template>
                    </ContactsBox>

                    <div class="columns is-multiline is-flex-mobile">
                        <div class="column is-12-mobile is-6-tablet ">
                            <b-field :label="$t('webmaster.profile.labels.timezone')">
                                <b-select
                                    v-if="profileLoaded"
                                    v-model="_user.timeZone"
                                    expanded>
                                    <optgroup
                                        :key="continent"
                                        v-for="(zones, continent) in timeZones"
                                        :label="continent">
                                        <option
                                            :key="i"
                                            v-for="(zone, i) in zones"
                                            :value="zone.alias">
                                            {{ zone.alias }} {{ zone.offset }}
                                        </option>
                                    </optgroup>
                                </b-select>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>

                        <div
                            v-if="role === 'webmaster'"
                            class="column is-12-mobile is-6-tablet">
                            <b-field :label="$t('webmaster.profile.labels.preferredCurrency')">
                                <LazySelect
                                    v-if="profileLoaded"
                                    v-model="_user.preferredCurrency"
                                    :clearable="false"
                                    :searchable="false"
                                    :get-data-vuex="getCurrencies">
                                    <template #selected-option="{ option }">
                                        {{ $t(`common.currency.${ option.name }`) }}
                                    </template>
                                </LazySelect>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                        <div
                            class="column is-12-mobile is-6-tablet"
                            :class="{'is-12': role === 'admin'}">
                            <b-field :label="$t(`webmaster.profile.labels.birthday`)">
                                <BirthDatepicker
                                    v-if="profileLoaded"
                                    v-model="_user.birthday"
                                    required>
                                </BirthDatepicker>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                        <div
                            v-if="role === 'webmaster'"
                            class="column is-12-mobile is-6-tablet">
                            <b-field :label="$t(`webmaster.profile.labels.preferredVertical`)">
                                <LazySelect
                                    v-if="profileLoaded"
                                    v-model="_user.preferredVertical"
                                    :clearable="false"
                                    :searchable="false"
                                    :get-data-vuex="getVerticals">
                                    <template #selected-option="{ option }">
                                        {{ $t(`common.entity.verticals.${ option.name }`) }}
                                    </template>
                                    <template #text="{ option }">
                                        {{ $t(`common.entity.verticals.${ option.name }`) }}
                                    </template>
                                </LazySelect>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                        <div
                            class="column is-12-mobile is-6-tablet">
                            <b-field :label="$t('common.auth.signUp.residenceCountry')">
                                <CountriesSelect
                                    v-if="profileLoaded"
                                    v-model="_user.residenceCountry"
                                    :placeholder="$t(`common.auth.signUp.residenceCountry`)">
                                </CountriesSelect>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                        <div
                            class="column is-12-mobile is-6-tablet">
                            <b-field :label="$t('common.auth.signUp.residenceCity')">
                                <CitiesSelect
                                    v-if="profileLoaded"
                                    v-model="_user.residenceCity"
                                    :disabled="!_user.residenceCountry"
                                    :filter-options="{ countryId: _user.residenceCountry }"
                                    :placeholder="$t(`common.auth.signUp.residenceCity`)">
                                </CitiesSelect>
                                <b-skeleton
                                    v-else
                                    width="100%"
                                    height="46px"
                                    animated>
                                </b-skeleton>
                            </b-field>
                        </div>
                    </div>
                    <div
                        v-if="isConfirmed"
                        class="column is-12 px-0">
                        <b-button
                            class="is-success is-light is-fullwidth"
                            expanded
                            @click="resendEmail">
                            {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
                        </b-button>
                    </div>
                </BlockBox>
            </div>
            <div class="column is-12">
                <EditTrafficTypes
                    v-if="profileLoaded"
                    v-model="_user.trafficTypes"
                    :title="$t(`webmaster.profile.labels.traffic`)">
                </EditTrafficTypes>
            </div>
        </div>
        <div class="columns is-multiline">
            <div class="column is-12">
                <BlockBox :title="$t(`webmaster.profile.labels.offers`)">
                    <div
                        v-if="profileLoaded"
                        class="row-grid row-grid--checkbox-cover">
                        <div
                            :key="index"
                            v-for="(item, index) in offerCategories">
                            <b-checkbox
                                v-model="_user.categoriesPreferences"
                                :native-value="item.id">
                                <NameTranslationOutput
                                    :name="item.name.replace(/\s/g, '_')"
                                    namespace="common.entity.offer.categoriesTypes">
                                </NameTranslationOutput>
                            </b-checkbox>
                        </div>
                    </div>
                    <LLoading
                        v-else
                        :active="true"
                        :is-full-page="false">
                    </LLoading>
                </BlockBox>
                <slot name="buttons">
                    <b-button
                        v-if="profileLoaded"
                        class="is-success is-light"
                        expanded
                        native-type="submit">
                        {{ $t(`webmaster.profile.values.save`) }}
                    </b-button>
                </slot>
            </div>
        </div>
        <slot name="other"></slot>
    </component>
</template>

<script>
  import BlockBox from "@/components/Common/BlockBox";
  import EditTrafficTypes from "@/components/Common/EditTrafficTypes";
  import { GET_OFFER_CATEGORIES, RESEND_EMAIL } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading";
  import NameTranslationOutput from "@/components/Common/Output/NameTranslationOutput.vue";
  import LazySelect from "@/components/Common/Select/Select.vue";
  import BirthDatepicker from "@/components/Common/Date/BirthDatepicker.vue";
  import TelegramInput from "@/components/Common/Select/TelegramInput.vue";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import RequiredField from "@/components/Common/RequiredField.vue";
  import CitiesSelect from "@/components/Common/Select/CitiesSelect.vue";
  import ContactsBox from "@/components/Common/ContactsBox.vue";

  export default {
    name: "WebmasterProfile",

    components: {
      ContactsBox,
      CitiesSelect,
      RequiredField,
      CountriesSelect,
      TelegramInput,
      LazySelect,
      LLoading,
      BlockBox,
      EditTrafficTypes,
      BirthDatepicker,
      NameTranslationOutput
    },

    props: {
      user: {
        type: Object,
        default: null
      }
    },
    created () {
      this.GET_OFFER_CATEGORIES();
    },

    computed: {
      ...mapState({
        timeZones: ({ dictionaries }) => dictionaries.timeZones,
        offerCategories: ({ dictionaries }) => dictionaries.offerCategories,
        currencyList: ({ auth }) => auth?.availableCurrencies,
        role: state => state.auth.role
      }),

      profileLoaded () {
        return !!this.user;
      },

      isConfirmed () {
        return !this.$store.state.auth.isConfirmed;
      },

      _user: {
        get () {
          return this.user;
        },

        set (value) {
          this.$emit("update:user", value);
        }
      }
    },

    methods: {
      ...mapActions({
        GET_OFFER_CATEGORIES
      }),

      ...mapActions("webmaster/profile", [
        RESEND_EMAIL
      ]),

      async resendEmail () {
        try {
          await this.RESEND_EMAIL();

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      },

      getCurrencies () {
        const items = this.currencyList;
        return { items, count: items.length };
      },

      getVerticals () {
        const items = ["NUTRA", "GAMBLING"];
        return { items, count: items.length };
      }
    },

    watch: {
      "_user.residenceCountry" (value, oldValue) {
        if (oldValue && value !== oldValue) {
          this._user.residenceCity = null;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .control-label {
      font-size: 0.875em;
    }
  }
</style>
