import { SET_EMPTY, UPDATE_USER_TYPE_LIST } from "@core/store/mutation-constants";
import { UserTypeListState } from "@core/store/types/admin/lists/userTypeList";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { GET_USER_TYPE } from "@core/store/action-constants";
import { RootState } from "@core/store/root-state";
import i18n from "@core/plugins/i18n";

const initialState = (): UserTypeListState => {
  return {
    data: {}
  };
};

const state: () => UserTypeListState = initialState;

const getters: GetterTree<UserTypeListState, RootState> = {};

const mutations: MutationTree<UserTypeListState> = {
  [UPDATE_USER_TYPE_LIST] (state, statuses: UserTypeListState["data"]) {
    state.data = statuses;
  },
  
  [SET_EMPTY]: state => {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<UserTypeListState, RootState> = {
  [GET_USER_TYPE] ({ commit }) {
    const items = [
      {
        value: true,
        label: i18n.t("common.entity.filters.userType.true")
      },
      {
        value: false,
        label: i18n.t("common.entity.filters.userType.false")
      }
    ];
    commit(UPDATE_USER_TYPE_LIST, {
      items,
      count: items.length
    });
  }
};

export const userTypeList: Module<UserTypeListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
