import { AvatarService } from "@core/services/common/AvatarService";
import { SET_MODAL_ACTIVE } from "@core/store/mutation-constants";
import {
  UPDATE_MODAL_OPTIONS,
  UPDATE_MODAL_ACTIVE,
  UPDATE_AVATAR,
  SET_EMPTY
} from "@core/store/action-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { AvatarState } from "@core/store/types/common/User";
import { ProfileService } from "@core/services/common/AvatarProfile";

const initialState = (): AvatarState => {
  return {
    options: {
      avatarUrl: null
    },
    isModalActive: false
  };
};

const state: () => AvatarState = initialState;

const getters: GetterTree<AvatarState, RootState> = {};

const mutations: MutationTree<AvatarState> = {
  [SET_EMPTY]: state => Object.assign(state, initialState()),
  
  [SET_MODAL_ACTIVE] (state, isModalActive: boolean) {
    state.isModalActive = isModalActive;
  },
  
  [UPDATE_MODAL_OPTIONS] (state, options: AvatarState["options"]) {
    state.options = { ...state.options, ...options };
  }
};

const actions: ActionTree<AvatarState, RootState> = {
  async [UPDATE_AVATAR] ({ commit }, form) {
    const { data: { fileId: avatarId } } = await AvatarService.getAvatarId(form);
    const { data: { changeAvatar: { avatar: { url: avatarUrl } } } } = await ProfileService.editAvatarProfile({ avatarId });
    
    commit(UPDATE_MODAL_OPTIONS, { avatarUrl });
  },
  
  [UPDATE_MODAL_OPTIONS] ({ commit }, options: AvatarState["options"]) {
    commit(UPDATE_MODAL_OPTIONS, options);
  },
  
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean) {
    commit(SET_MODAL_ACTIVE, payload);
  },
  
  [SET_EMPTY] ({ commit }) {
    commit(SET_EMPTY);
  }
};

export const userAvatarModal: Module<AvatarState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
