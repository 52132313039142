import { BaseFilter, BaseFilterList } from "@core/logic/common/dictionaries/BaseFilter";

export class PaymentSystemFilter extends BaseFilter {
  public name = "";
  public integrationAlias = "";
  
  constructor (paymentSystem: any) {
    super();
    
    this.id = paymentSystem.id;
    this.integrationAlias = paymentSystem.integrationAlias;
    this.name = paymentSystem.name;
  }
}

export interface PaymentSystemList extends BaseFilterList {
  items: Array<PaymentSystemFilter>;
}

export type PaymentSystemResponse = {
  items: Array<PaymentSystemFilter>;
  count: number;
};
