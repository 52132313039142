import Service from "@core/services/common/Service";
import { OfferLandingsAddModalInput } from "@core/store/types/admin/offer/OfferLandingsAddModal";
import { FileResponse } from "@core/store/types/common";
import { AxiosPromise } from "axios";
import createPageQuery from "./graphql/createPage.graphql";
import updatePageQuery from "./graphql/updatePage.graphql";
import PrepareDataOfferMapper from "@core/services/admin/offer/PrepareDataOfferMapper";

export class OfferLandingsAddModalService extends Service {
  public static async createPage (input: OfferLandingsAddModalInput): Promise<AxiosPromise> {
    try {
      // FIXME: Убрать, когда бек приведёт pageType списка лендингов и создания лендингов к одному виду
      const modifiedInput = {
        ...input,
        pageType: input.pageType.toLocaleUpperCase()
      };
      // @ts-ignore
      const preparedOptions = PrepareDataOfferMapper(modifiedInput);
      
      const { data: { createPage } } = await this.api.post("", {
        query: createPageQuery,
        variables: {
          input: preparedOptions
        }
      });
      
      return createPage;
    } catch (e) {
      throw e;
    }
  }

  public static async updatePage (pageId: string, fields: OfferLandingsAddModalInput): Promise<AxiosPromise> {
    try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { offerId, pageType, ...input } = fields;
      // @ts-ignore
      const preparedOptions = PrepareDataOfferMapper(input);

      const { data: { updatePage } } = await this.api.post("", {
        query: updatePageQuery,
        variables: {
          pageId,
          input: preparedOptions
        }
      });
      
      return updatePage;
    } catch (e) {
      throw e;
    }
  }

  public static uploadLandingFile (fd: FormData): AxiosPromise<FileResponse> {
    return this.files.post("", fd);
  }
}
