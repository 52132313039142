import { Filters } from "@core/store/modules/common/generators/filters";
import { OfferDetailItem, OfferItem } from "@core/store/types/admin/statistic/common/offersFilters";
import {
  FilterInput,
  BaseFiltersInput,
  OfferFiltersInput
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { StatisticsService } from "@core/services/admin/statistic/StatisticService";

export class OfferFilters extends Filters<OfferItem, BaseFiltersInput> {
  getData = async (offset: number, limit: number, filters: OfferFiltersInput, initialFilters: BaseFiltersInput) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { nameOrIntId, ...otherFilters } = filters;
    const { data: { offers, initialOffers } } =
      await StatisticsService.getAnalyticOffersFilters(offset, limit, { nameOrIntId, ...otherFilters }, initialFilters);
    
    const dataItems = offers?.items?.map(({ detail, ...otherFields }: OfferDetailItem): OfferItem => ({ name: detail.name, ...otherFields }));
    const dataInitialItems = initialOffers?.items?.map(({ detail, ...otherFields }: OfferDetailItem): OfferItem => ({ name: detail.name, ...otherFields }));
    
    return {
      data: {
        items: dataItems,
        count: offers.count
      },
      initialData: {
        items: dataInitialItems,
        count: initialOffers?.length
      }
    };
  }
  
  getInitialFilters = (ids?: Array<string> | string): FilterInput | undefined => {
    if (ids) {
      const id = Array.isArray(ids) ? ids : [ids];
      return { id };
    }
  };
}

// export const offersFilters = new OfferFilters();
