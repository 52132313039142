import Service from "@core/services/common/Service";
import { AxiosPromise } from "axios";
import { OffersFilters } from "@core/store/types/webmaster/offers";
import getSelectedOffersQueryGenerator
  from "@core/services/admin/statistic/statistic/graphql/getSelectedOffersQuery.generator";

export class StatisticsRatingService extends Service {
  public static async getSelectedOffers (
    filters: OffersFilters,
    limit: number,
    offset: number
  ): Promise<AxiosPromise> {
    try {
      const { data: { offers } } = await this.api.get("", {
        params: getSelectedOffersQueryGenerator(
          limit,
          offset,
          filters
        )
      });
      return offers;
    } catch (e) {
      throw e;
    }
  }
}