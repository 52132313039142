import { BadgesListValues } from "@core/store/types/common/badges";
import { hasPermissionsStrict } from "@core/mixins/permissions";
import store from "../../../../../../store";
import { Country } from "@core/logic/common/common";
import { typeEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";

export function replaceOfferParameterRequest (value: BadgesListValues) {
  const { permissions, user } = store?.getters;
  const country = user?.countries.map(({ id }: Country) => id);
    
  return {
    ...value,
    type: hasPermissionsStrict(["CAPACITY.REQUEST.APPROVE", "PAYOUT.REQUEST.APPROVE"], permissions)
      ? null
      : hasPermissionsStrict(["CAPACITY.REQUEST.APPROVE"], permissions)
        ? typeEnum.CAPACITY
        : hasPermissionsStrict(["PAYOUT.REQUEST.APPROVE"], permissions)
          ? typeEnum.PAYOUT
          : null,
    country: country.length ? country : null
  };
}