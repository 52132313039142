import { FETCH_USER, SET_EMPTY, SET_EMPTY_USER_PROFILE } from "@core/store/action-constants";
import { Admin } from "@/store/modules/admin";
import { Advertiser } from "@/store/modules/advertiser";
import { Webmaster } from "@/store/modules/webmaster";
import { RootState } from "@core/store/root-state";
import { CoreStore } from "@core/store";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export class Store extends CoreStore<RootState, RootState> {
  constructor () {
    super();
    
    this.actions({
      async [FETCH_USER] ({ dispatch, getters }) {
        try {
          if (!["webmaster", "advertiser", "admin"].includes(getters.role)) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error(`Role [${ getters.role }] does not valid`);
          }
          
          const user = await dispatch(`${ getters.role }/profile/${ FETCH_USER }`);
          
          const { id, login: username, timeZone: { offset } } = user;
          if (Sentry) {
            Sentry.setUser({ id, username, Role: getters.role });
          }

          const localeTimeZone = localStorage.getItem("timeZone");
          if (localeTimeZone !== offset) {
            localStorage.setItem("timeZone", offset);
          }
          
          return user;
        } catch (error) {
          throw error;
        }
      },

      [SET_EMPTY_USER_PROFILE] ({ dispatch }) {
        dispatch(`webmaster/profile/${ SET_EMPTY }`);
        dispatch(`advertiser/profile/${ SET_EMPTY }`);
        dispatch(`admin/profile/${ SET_EMPTY }`);
      }
    });
    
    this.getters({
      profile: (state, getters) => {
        switch (getters.role) {
          case "webmaster":
          case "advertiser":
          case "admin":
            return state[getters.role].profile;
          default:
            return {};
        }
      },
      
      role: (state): string => {
        return state.auth.role;
      },
      
      user: (state, getters): string => {
        return getters.profile.user;
      },
      
      timezone: (state, getters): string => {
        const offset = { offset: localStorage.getItem("timeZone") };
        return getters.profile.user?.timeZone || offset;
      }
    });
    
    this.state({
      version: process.env.VUE_APP_VERSION
    });
    
    this.strict = process.env.NODE_ENV === "development";
    
    this.modules({
      advertiser: new Advertiser().toModule(),
      webmaster: new Webmaster().toModule(),
      admin: new Admin().toModule()
    });
  }
}

export default new Vuex.Store<RootState>(new Store().toModule());
