import { FinancesCostsService } from "@core/services/admin/finances/financesCosts/FinancesCostsService";
import {
  ACCEPT_COST,
  AUTO_COST,
  MARK_EXPRESS_COST,
  FILTER_SELECTED,
  GET_COSTS,
  GET_COSTS_BY_TYPE,
  PROCESS_COST,
  REJECT_COST,
  SET_EMPTY,
  UNMARK_EXPRESS_COST
} from "@core/store/action-constants";
import { confirmPayoutModal } from "@core/store/modules/admin/finances/modules/financesCosts/FinancesCostsConfirmPayoutModal";
import { createPaymentModal } from "@core/store/modules/admin/finances/modules/financesCosts/financesCostsCreatePaymentModal";
import { paymentSystemsList } from "@core/store/modules/common/lists/paymentSystemsList";
import { statusesList } from "@core/store/modules/admin/lists/statusesList";
import { hasPermissions } from "@core/mixins/permissions";
import {
  SET_COSTS,
  UPDATE_COSTS_FILTERS,
  UPDATE_COSTS_SORTINGS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  CostsListItem,
  CostsListState,
  PartialFinancesCostsState
} from "@core/store/types/admin/finances/financesCosts/financesCosts";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import { UnknownObject } from "@core/store/types/common";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";

const initialState = (): PartialFinancesCostsState => {
  return {
    costs: null,
    filters: {
      intId: null,
      userId: null,
      isExpress: null,
      status: ["CREATED", "AUTO_PROCESSING"],
      paymentSystemId: null,
      executorId: null,
      currency: null,
      executedAtDatepicker: {
        dateStart: null,
        dateEnd: null
      },
      datepicker: {
        dateStart: null,
        dateEnd: datepicker().dateEnd
      }
    },
    sort: null,
    order: null
  };
};

const state: () => PartialFinancesCostsState = initialState;

const getters: GetterTree<PartialFinancesCostsState, RootState> = {
  [GET_COSTS_BY_TYPE]: (_,__, rootState, rootGetters) => function (type: string): CostsListItem[] | undefined {
    const selectedCosts = rootState.snackbarActions.selected;
    switch (type) {
      case "auto":
        return selectedCosts?.filter((cost: UnknownObject) => cost.canBeAutoPay && hasPermissions(["OUT_TRANSACTIONS.MANAGE","OUT_TRANSACTIONS.AUTO_PAY.OWN"], rootGetters.permissions));
      case "created":
        return selectedCosts?.filter((cost: UnknownObject) => cost.status === "created" && hasPermissions(["OUT_TRANSACTIONS.MANAGE","OUT_TRANSACTIONS.PROCESSING.OWN"], rootGetters.permissions));
      case "accept":
        return selectedCosts?.filter((cost: UnknownObject) => cost.status === "hand_processing" && hasPermissions(["OUT_TRANSACTIONS.MANAGE","OUT_TRANSACTIONS.PROCESSED.OWN"], rootGetters.permissions));
      case "reject":
        return selectedCosts?.filter((cost: UnknownObject) =>
          (cost.status === "created"
                    || cost.status === "hand_processing"
                    || cost.canBeAutoPay) && hasPermissions(["OUT_TRANSACTIONS.MANAGE","OUT_TRANSACTIONS.REJECT.OWN"], rootGetters.permissions));
    }
  }
};

const mutations: MutationTree<PartialFinancesCostsState> = {
  [SET_COSTS] (state, items: CostsListState["costs"]) {
    state.costs = items;
  },

  [UPDATE_COSTS_FILTERS] (state, filter: PartialFinancesCostsState["filters"]) {
    state.filters = { ...state.filters, ...filter };
  },

  [UPDATE_COSTS_SORTINGS] (state, {
    sort,
    order
  }): void {
    state.sort = sort;
    state.order = order.toUpperCase();
  },
  
  [SET_EMPTY] (state, target?: keyof PartialFinancesCostsState) {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  ...stateUpdater.mutations
};

const actions: ActionTree<PartialFinancesCostsState, RootState> = {
  async [GET_COSTS] ({ state, commit }) {
    const {
      datepicker: { dateStart, dateEnd },
      executedAtDatepicker: { dateStart: executedAtDateStart, dateEnd: executedAtDateEnd },
      ...filters
    } = state.filters;
    const { limit, offset } = pagination();
    const { sort, order } = state;

    try {
      const outTransactions = await FinancesCostsService.getCosts(
        limit,
        offset,
        {
          executedAtDateStart,
          executedAtDateEnd,
          dateEnd,
          dateStart,
          ...filters
        },
        sort,
        order
      );
      
      commit(SET_COSTS, outTransactions);
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [AUTO_COST] ({ dispatch }, transactionIds) {
    //TODO: реализовать LOCAL_UPDATE когда бекенд начнет возвращать сущность в ответе
    try {
      await FinancesCostsService.autoCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [PROCESS_COST] ({ dispatch, commit }, transactionIds) {
    try {
      const transitCreatedToHandProcessing = await FinancesCostsService.processCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
      commit("LOCAL_UPDATE", { items: transitCreatedToHandProcessing, target: "costs" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [ACCEPT_COST] ({ dispatch, commit }, transactionIds) {
    try {
      const transitHandProcessingToProcessed = await FinancesCostsService.acceptCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
      commit("LOCAL_UPDATE", { items: transitHandProcessingToProcessed, target: "costs" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [REJECT_COST] ({ dispatch, commit }, transactionIds) {
    try {
      const transitToReject = await FinancesCostsService.rejectCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
      commit("LOCAL_UPDATE", { items: transitToReject, target: "costs" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [MARK_EXPRESS_COST] ({ dispatch, commit }, transactionIds) {
    try {
      const express = await FinancesCostsService.expressCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
      commit("LOCAL_UPDATE", { items: express, target: "costs" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  async [UNMARK_EXPRESS_COST] ({ dispatch, commit }, transactionIds) {
    try {
      const unmarkExpress = await FinancesCostsService.unmarkExpressCost(transactionIds);
      dispatch(`snackbarActions/${ FILTER_SELECTED }`, transactionIds, { root: true });
      commit("LOCAL_UPDATE", { items: unmarkExpress, target: "costs" });
    } catch (e: any) {
      throw new Error(e);
    }
  },

  [UPDATE_COSTS_FILTERS] ({ commit }, option) {
    commit(UPDATE_COSTS_FILTERS, option);
  },
  
  [SET_EMPTY] ({ commit }, target?: string) {
    commit("SET_EMPTY", target);
    commit("statusesList/SET_EMPTY");
    commit("paymentSystemsList/SET_EMPTY");
  }
};

export const costs: Module<PartialFinancesCostsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    statusesList,
    paymentSystemsList,
    createPaymentModal,
    confirmPayoutModal
  }
};
