<template>
    <b-field>
        <template
            v-if="label"
            #label>
            {{ label }}
            <LTooltip
                v-if="note"
                :label="note"
                class="is-inline-block"
                position="is-top"
                :max-width="400"
                type="is-info">
                <!--suppress HtmlUnknownTarget -->
                <b-icon
                    icon="question-circle"
                    pack="far"
                    type="is-info"
                    size="is-small"></b-icon>
            </LTooltip>
        </template>
        <b-field>
            <slot>
                <div
                    :class="{ 'is-expanded': expanded }"
                    class="control">
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <input
                        v-bind="$attrs"
                        :placeholder="placeholder"
                        :type="inputType"
                        class="input"
                        :readonly="readonly"
                        v-on="listeners">
                </div>
            </slot>
            <template v-if="!('default' in this.$slots)">
                <div
                    v-if="isPassword"
                    class="control">
                    <b-button
                        :class="{ 'is-disabled': $attrs.disabled != null }"
                        @click="isHide = !isHide">
                        <b-icon
                            :alt="isHide ? $t(`common.components.customInput.icons.showIcon`) : $t(`common.components.customInput.icons.hideIcon`)"
                            :icon="isHide ? 'eye' : 'eye-slash'"
                            pack="far"></b-icon>
                    </b-button>
                </div>
                <div
                    v-if="canCopy && copy"
                    class="control">
                    <b-button
                        :class="{ 'is-disabled': $attrs.disabled != null }"
                        @click.prevent="copyToClipboard">
                        <b-icon
                            :alt="$t(`common.components.customInput.icons.copy`)"
                            icon="copy"
                            pack="far"></b-icon>
                    </b-button>
                </div>
            </template>
        </b-field>
    </b-field>
</template>

<script>
  import { CopyToClipboard } from "@core/mixins/copyToClipboard";
  
  export default {
    name: "CustomInput",
    inheritAttrs: false,
    mixins: [CopyToClipboard],
    props: {
      copy: {
        type: Boolean,
        default: false
      },

      hide: {
        type: Boolean,
        default: false
      },

      readonly: {
        type: Boolean,
        default: false
      },

      expanded: {
        type: Boolean,
        default: false
      },

      label: {
        type: [String, Boolean],
        // eslint-disable-next-line no-irregular-whitespace
        default: " " //  	&#8195;	широкий пробел (em-шириной в букву m)
      },

      placeholder: {
        type: String,
        default: ""
      },

      type: {
        type: String,
        default: "text"
      },

      note: {
        type: String,
        default: ""
      },

      isValid: {
        type: Boolean,
        default: true
      }
    },
    data () {
      const isHide = this.hide;

      return {
        isHide
      };
    },
    computed: {
      listeners () {
        return {
          ...this.$listeners,
          input: event => this.$emit("input", event.target.value)
        };
      },

      isPassword () {
        return this.type === "password";
      },

      canCopy () {
        // If neither https nor localhost
        return navigator && navigator.clipboard;
      },

      inputType () {
        return this.isPassword && !this.isHide ? "text" : this.type;
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "src/scss/theme-default";

    .input {
        text-overflow: ellipsis;

        &:focus {
            box-shadow: initial;
            border-color: #e9e9e9;
        }
    }

    .button {
        &.is-disabled {
            border: none !important;
            //noinspection SassScssUnresolvedVariable
            background-color: $background;
        }

        .icon {
            color: rgba(0, 0, 0, 0.4);
        }
    }
</style>
