<template>
    <form
        class="content"
        @submit.prevent.stop="captchaExecute">
        <h3 class="title is-4 has-text-left">
            {{ $t(`common.auth.signUp.title`) }}
        </h3>

        <b-notification
            v-show="isError"
            :closable="false"
            type="is-danger">
            <i18n path="common.auth.signUp.error.content">
                <template #login>
                    <span
                        v-t="'common.auth.signUp.error.particles.login'"
                        class="has-text-weight-semibold"></span>
                </template>
                <template #email>
                    <span
                        v-t="'common.auth.signUp.error.particles.email'"
                        class="has-text-weight-semibold"></span>
                </template>
            </i18n>
        </b-notification>

        <div class="columns is-multiline is-variable is-2">
            <RequiredField
                v-if="!isInviteLink"
                class="column is-marginless is-12"
                :label="$t('common.entity.role.entityName')"
                required>
                <Select
                    v-model="userType"
                    :get-data-vuex="getUserType"
                    :placeholder="$t(`common.auth.signUp.role`)"
                    expanded
                    required
                    :clearable="false"
                    :searchable="false"
                    field="label"
                    prop="value">
                </Select>
            </RequiredField>
            <RequiredField
                v-if="!hasEmailInQuery"
                class="column is-marginless is-12"
                :label="$t('common.auth.signUp.email')"
                required>
                <LInput
                    v-model="email"
                    :pattern="emailValidator"
                    :placeholder="$t(`common.auth.signUp.email`)"
                    required
                    type="email">
                </LInput>
            </RequiredField>

            <RequiredField
                :message="$t(`common.auth.signUp.loginHelp`)"
                :label="$t('common.auth.signIn.login')"
                class="column is-marginless is-12"
                required>
                <LInput
                    v-model="login"
                    :placeholder="$t(`common.auth.signUp.login`)"
                    minlength="2"
                    pattern="[\d\w+]{2,}"
                    autocomplete="on"
                    required>
                </LInput>
            </RequiredField>

            <b-field
                :label="$t('common.auth.signUp.requiredMessenger')"
                class="column is-marginless is-12">
                <Select
                    v-model="requiredMessenger"
                    :get-data-vuex="getMessengers"
                    :clearable="false"
                    :searchable="false"
                    field="label"
                    prop="value"
                    @input="clearMessengers()">
                </Select>
            </b-field>

            <RequiredField
                v-if="requiredMessenger === MessengerTypeEnum.TELEGRAM"
                :message="$t(`common.auth.signUp.${ isValidTelegram ? 'checkingCorrectMessage' : 'telegramHelp' }`)"
                :type="isValidTelegram ? 'is-warning' : undefined"
                class="column is-marginless is-12"
                required>
                <template #label>
                    <img
                        :src="telegramIcon"
                        alt="telegram logo">
                    {{ $t('common.auth.signUp.telegram') }}
                </template>
                <TelegramInput
                    ref="telegram"
                    v-model="telegram"
                    :placeholder="$t(`common.auth.signUp.telegram`)"
                    required
                    @blur="isValidTelegram = $event.target.validity.valid">
                </TelegramInput>
            </RequiredField>
            <NumberCodeInput
                v-else-if="requiredMessenger === MessengerTypeEnum.WHATS_APP"
                :key="requiredMessenger"
                v-model="whatsApp"
                :notice="$t(`common.auth.signUp.checkingCorrectMessage`)"
                required-field
                required
                class="column is-marginless is-12"
                :country="currentCountry"
                :countries="countriesList">
                <template #label>
                    <img
                        :src="whatsAppIcon"
                        alt="whatsApp logo">
                    {{ $t('common.auth.signUp.whatsApp') }}
                </template>
            </NumberCodeInput>
            <NumberCodeInput
                v-else-if="requiredMessenger === MessengerTypeEnum.VIBER"
                :key="requiredMessenger"
                v-model="viber"
                :notice="$t(`common.auth.signUp.checkingCorrectMessage`)"
                required-field
                class="column is-marginless is-12"
                required
                :country="currentCountry"
                :countries="countriesList">
                <template #label>
                    <img
                        :src="viberIcon"
                        alt="skype logo">
                    {{ $t('common.auth.signUp.viber') }}
                </template>
            </NumberCodeInput>
            <RequiredField
                v-else
                class="column is-marginless is-12"
                :message="skype && isValidSkype ? $t(`common.auth.signUp.checkingCorrectMessage`) : undefined"
                :type="skype && isValidSkype ? 'is-warning' : undefined"
                required>
                <template #label>
                    <img
                        :src="skypeIcon"
                        alt="skype logo">
                    {{ $t('common.auth.signUp.skype') }}
                </template>
                <LInput
                    v-model="skype"
                    pattern="[^а-яё]+"
                    required
                    :validation-message="$t(`common.auth.signUp.skypeHelp`)"
                    :placeholder="$t('common.auth.signUp.skype')"
                    @blur="isValidSkype = $event.target.validity.valid">
                </LInput>
            </RequiredField>
            <RequiredField
                v-if="userType === 'webmaster'"
                class="column is-marginless is-12"
                :label="$t('common.auth.signUp.vertical')"
                required>
                <Select
                    v-model="availableVerticals"
                    :searchable="false"
                    required
                    :placeholder="$t(`common.auth.signUp.vertical`)"
                    :get-data-vuex="getVerticals"
                    field="label"
                    prop="value">
                    <template #selected-option="{ option }">
                        {{ $t(`common.entity.verticals.${ option.label }`) }}
                    </template>
                    <template #text="{ option }">
                        {{ $t(`common.entity.verticals.${ option.label }`) }}
                    </template>
                </Select>
            </RequiredField>
            <RequiredField
                class="column is-marginless is-12"
                :label="$t('common.auth.signUp.password')"
                required>
                <EditUserPasswordField
                    v-model="password"
                    :message="$t('common.validation.password')"
                    :placeholder="$t(`common.auth.signUp.password`)">
                </EditUserPasswordField>
            </RequiredField>

            <b-checkbox
                v-if="$saas.main"
                v-model="iAgree"
                class="column">
                <i18n
                    path="common.auth.signUp.iAgree"
                    style="font-size: .9rem">
                    <template #privacyPolice>
                        <a
                            href="https://limonad.com/privacy-policy/"
                            target="_blank">
                            {{ $t(`common.auth.signUp.privacyPolice`) }}
                        </a>
                    </template>
                    <template #userAgreement>
                        <a
                            href="https://limonad.com/user-agreement/"
                            target="_blank">
                            {{ $t(`common.auth.signUp.userAgreement`) }}
                        </a>
                    </template>
                </i18n>
            </b-checkbox>
        </div>

        <Captcha
            ref="captcha"
            @onVerify="submit($event)">
        </Captcha>

        <b-button
            :loading="isLoading"
            :disabled="!iAgree && $saas.main"
            class="is-success"
            expanded
            native-type="submit"
            rounded>
            {{ $t(`common.auth.signUp.submit`) }}
        </b-button>
        <b-button
            tag="router-link"
            type="is-text"
            :to="{ name: 'auth:signIn' }"
            class="form-subbutton">
            {{ $t(`common.auth.signIn.signIn`) }}
        </b-button>
        <SignUpRoleModal :is-active="!userType && !isInviteLink"></SignUpRoleModal>
    </form>
</template>



<script >
import { useCountries } from "@/stores/common/auth/countriesStore";
import { storeToRefs } from "pinia";
import { onUnmounted, ref } from "@vue/composition-api";
import { MessengerTypeEnum } from "@core/store/types/common/enums/signUp/MessengerTypeEnum";
import EditUserPasswordField from "@/components/Common/Fields/EditUserPasswordField";
import Captcha from "@/components/Common/Auth/Captcha";
import RequiredField from "@/components/Common/RequiredField";
import SignUpRoleModal from "@/components/Common/Auth/SignUpRoleModal.vue";
import NumberCodeInput from "@/components/Common/Fields/NumberCodeInput.vue";
import TelegramInput from "@/components/Common/Select/TelegramInput.vue";
import Select from "@/components/Common/Select/Select.vue";
import { i18n as i18nTranslate } from "@core/plugins";
import _camelCase from "lodash/camelCase";
import whatsAppIcon from "@/assets/Contacts/logo_whatsApp.svg";
import viberIcon from "@/assets/Contacts/logo_viber.svg";
import telegramIcon from "@/assets/Contacts/logo_telegram.svg";
import skypeIcon from "@/assets/Contacts/logo_skype.svg";
import { EMAIL_VALIDATOR } from "@core/validators";
import { FETCH_USER, UPDATE_FORM, USER_REGISTRATION } from "@core/store/action-constants";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "@vasiliyrusin/vue-mapfields";
const __sfc_main = {
  name: "SignUp",
  created() {
    if (this.isInviteLink) {
      this.email = this.$route.query.email || null;
      this.userType = "webmaster";
    }
    this.manager = this.$route.query.manager || null;
    this.referrerId = Number(this.$route.query.rId) || null;
    this.agentId = this.$route.query.agentId || null;
  },
  data() {
    return {
      iAgree: false,
      isLoading: false,
      errors: [],
      isError: false,
      isValidSkype: false,
      isValidTelegram: false
    };
  },
  computed: {
    ...mapGetters(["user"]),
    telegramLogin() {
      return `@${this.form.telegram}`;
    },
    isCompetition() {
      return this.$store.state.dictionaries.currentCompetition?.id;
    },
    ...mapFields("signUp", {
      fields: ["referrerId", "manager", "agentId", "login", "password", "email", "userType", "telegram", "whatsApp", "viber", "skype", "availableVerticals"],
      base: "form",
      action: UPDATE_FORM
    }),
    emailValidator() {
      return EMAIL_VALIDATOR;
    },
    isInviteLink() {
      const {
        agentId,
        rId
      } = this.$route.query;
      return agentId != null || rId != null;
    },
    hasEmailInQuery() {
      return this.$route.query.email != null;
    }
  },
  methods: {
    ...mapActions("signUp", [USER_REGISTRATION, UPDATE_FORM]),
    async submit(captcha) {
      this.isError = false;
      this.isLoading = true;
      if (this.isInviteLink) {
        this.agentId = this.$route.query.agentId;
      }
      try {
        await this.USER_REGISTRATION(captcha);
        if (process.env.NODE_ENV === "production" && this.userType === "webmaster") {
          this.$gtag.event("conversion", {
            send_to: "AW-11099480283/0OBsCILT9I4YENvB0qwp"
          });
        }
        this.$ym?.reachGoal("LIMONAD_REGISTRATION");
        if (!this.$store.state.auth.isConfirmed) {
          this.$router.push({
            name: "mail:send"
          }).catch(_ => {});
        } else if (this.$store.state.auth.isConfirmed && this.$store.state.auth.isApproved) {
          // Если после регистрации юзер и подтвержден и аппрувнут это значит что он
          // агент и мы отправляем его на второй этап регистрации для заполнения
          // оставшихся данных.
          this.$router.push({
            name: "auth:signUpProfile"
          });
          this.$store.dispatch(FETCH_USER);
        }
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    getVerticals() {
      const items = [{
        value: "common",
        label: "default"
      }, {
        value: "nutra",
        label: "NUTRA"
      }, {
        value: "gambling",
        label: "GAMBLING"
      }];
      return {
        items,
        count: items.length
      };
    },
    captchaExecute() {
      this.$refs.captcha.$refs.hcaptcha.execute();
    },
    clearMessengers() {
      this.telegram = null;
      this.whatsApp = null;
      this.viber = null;
      this.skype = null;
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const users = ["advertiser", "webmaster"];
  const store = useCountries();
  const {
    data: countriesList,
    currentCountry
  } = storeToRefs(store);
  store.getCountries();
  store.getCurrentCountry();
  const requiredMessenger = ref(MessengerTypeEnum.TELEGRAM);
  function getMessengers() {
    const items = Object.values(MessengerTypeEnum).map(value => ({
      label: i18nTranslate.t(`common.auth.signUp.${_camelCase(value)}`),
      value
    }));
    return {
      items,
      count: items.length
    };
  }
  function getUserType() {
    const items = Object.values(users).map(value => ({
      label: i18nTranslate.t(`common.auth.signUp.${value}`),
      value
    }));
    return {
      items,
      count: items.length
    };
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    MessengerTypeEnum,
    whatsAppIcon,
    viberIcon,
    telegramIcon,
    skypeIcon,
    countriesList,
    currentCountry,
    requiredMessenger,
    getMessengers,
    getUserType
  };
};
__sfc_main.components = Object.assign({
  RequiredField,
  Select,
  TelegramInput,
  NumberCodeInput,
  EditUserPasswordField,
  Captcha,
  SignUpRoleModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
  ::v-deep {
    .vs__dropdown-menu {
      margin: 5px 0 0 0 !important;
    }
  }
</style>
