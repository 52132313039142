import { PaymentIntegrationsListService } from "@core/services/admin/lists/paymentIntegrationsListService";
import { GET_PAYMENT_INTEGRATIONS_LIST, UPDATE_PAYMENT_INTEGRATIONS_LIST } from "@core/store/action-constants";
import {
  EMPTY_PAYMENT_INTEGRATIONS_LIST,
  UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import {
  PartialPaymentIntegrationsListState,
  PaymentIntegrationsListResponse
} from "@core/store/types/admin/lists/paymentIntegrationsListState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

const initialState = (): PartialPaymentIntegrationsListState => {
  return {
    data: {},
    pagination: {
      page: 1,
      perPage: 25
    }
  };
};

const state: () => PartialPaymentIntegrationsListState = initialState;

const getters: GetterTree<PartialPaymentIntegrationsListState, RootState> = {};

const mutations: MutationTree<PartialPaymentIntegrationsListState> = {
  SET_EMPTY: state => Object.assign(state, initialState()),

  [UPDATE_PAYMENT_INTEGRATIONS_LIST] (state, paymentIntegrations: PaymentIntegrationsListResponse["paymentSystemIntegrations"]) {
    if (state.pagination.page !== 1 && state?.data?.items && paymentIntegrations) {
      state.data.items = Array.from(new Set([...state.data.items, ...paymentIntegrations.items as []]));
    } else {
      state.data = paymentIntegrations;
    }
  },

  [UPDATE_PAYMENT_INTEGRATIONS_LIST_PAGINATION] (state) {
    if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
      state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
    }
  },

  [EMPTY_PAYMENT_INTEGRATIONS_LIST] (state) {
    Object.assign(state, initialState());
  }
};

const actions: ActionTree<PartialPaymentIntegrationsListState, RootState> = {
  async [GET_PAYMENT_INTEGRATIONS_LIST] ({ commit, state }) {
    const limit = state.pagination.perPage;
    const offset = (state.pagination.page - 1) * limit;
    const { data: { paymentSystemIntegrations } } =
            await PaymentIntegrationsListService.getPaymentIntegrationsList(
              limit,
              offset
            );

    commit(UPDATE_PAYMENT_INTEGRATIONS_LIST, paymentSystemIntegrations);
    return paymentSystemIntegrations;
  }
};

export const paymentIntegrationsList: Module<PartialPaymentIntegrationsListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
