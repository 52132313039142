import Vue from "vue";
import Metrika from "./vue/MetrikaPlugin";

Vue.use(Metrika, {
  id: process.env.VUE_APP_YANDEX_METRIKA_ID,
  debug: process.env.NODE_ENV !== "production",
  disable: process.env.VUE_APP_YANDEX_METRIKA_ON !== "true",
  options: {
    webvisor: true,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true
  }
});
