export default [
  {
    path: "offers",
    name: "offers",
    component: (): Promise<any> => import(/* webpackChunkName: "advertiser:offers" */ "@/views/Advertiser/OffersView.vue")
  },
  {
    path: "offers/:slug",
    name: "offer",
    props: true,
    component: (): Promise<any> => import(/* webpackChunkName: "advertiser:offer" */ "@/views/Advertiser/OfferView.vue")
  }
];
