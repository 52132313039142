import { Role, RolesFilters } from "@core/store/types/admin/roles/roles";
import Service from "@core/services/common/Service";
import getRolesListQuery from "@core/services/common/lists/graphql/getRolesListQuery.graphql";
export class RolesListService extends Service {
  public static async getRolesList (
    limit: number,
    offset: number,
    filters: RolesFilters
  ): Promise<Role> {
    try {
      const { data: { roles } } = await this.api.get("", {
        params: {
          query: getRolesListQuery,
          variables: {
            limit,
            offset,
            filters
          }
        }
      });
      
      return roles;
    } catch (e) {
      throw e;
    }
  }
}