












































































import { ComponentPublicInstance, computed, ref, watch } from "@vue/composition-api";
import { defineEmits, defineProps, withDefaults } from "@vue/runtime-dom";
import i18n from "@core/plugins/i18n";
import router from "@/router";
interface Props {
  value: number;
  onlyHour: boolean;
  placeholder: string | null;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: false,
    type: Number,
    default: 15
  },
  onlyHour: {
    key: "onlyHour",
    required: false,
    type: Boolean,
    default: false
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: [String, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const timepicker = ref<ComponentPublicInstance<any> | null>(null);
  const emit = __ctx.emit;
  const selectedDay = ref(props.onlyHour ? null : 0) as any;
  const selectedHour = ref(props.onlyHour ? null : 0) as any;
  const selectedMinute = ref(props.onlyHour ? null : 0) as any;
  const totalTimeConvertedToMinutes = computed(() => {
    const daysToMinutes = selectedDay.value * 1440;
    const hoursToMinutes = selectedHour.value * 60;
    return props.onlyHour ? hoursToMinutes : daysToMinutes + hoursToMinutes + selectedMinute.value;
  });
  const hasValue = computed(() => props.value || props.value === 0);
  const unitsTime = computed(() => ({
    ...(!props.onlyHour && {
      days: 31
    }),
    hours: 24,
    ...(!props.onlyHour && {
      minutes: 60
    })
  }));
  const totalTime = computed(() => {
    return hasValue.value ? props.onlyHour ? `${convertColumn(selectedHour.value)}:00` : `${convertColumn(selectedDay.value)}:${convertColumn(selectedHour.value)}:${convertColumn(selectedMinute.value)}` : props.placeholder ?? i18n.t("common.components.datepicker.values.selectTime");
  });
  function convertColumn(item: number | null): string | number | null {
    return item && item < 10 ? `0${item}` : item;
  }
  function convertTime(time: number): void {
    const DAY = 1440;
    const HOUR = 60;
    selectedDay.value = Math.floor(time / DAY);
    selectedHour.value = Math.floor((time - selectedDay.value * DAY) / HOUR);
    selectedMinute.value = Math.round(time % HOUR);
  }
  function selectValue() {
    emit("input", totalTimeConvertedToMinutes.value);
  }
  function closePopup() {
    timepicker.value.toggle();
  }
  watch(() => props.value, () => {
    if (hasValue.value) {
      convertTime(props.value);
    }
  }, {
    immediate: true
  });
  if (hasValue.value && !router.app.$route.params.id) {
    selectValue();
  }
  return {
    timepicker,
    selectedDay,
    selectedHour,
    selectedMinute,
    hasValue,
    unitsTime,
    totalTime,
    convertColumn,
    selectValue,
    closePopup
  };
};
export default __sfc_main;
