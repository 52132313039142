// auth
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const USER_LOGIN_BY_PASSWORD = "USER_LOGIN_BY_PASSWORD";
export const USER_LOGIN_BY_TELEGRAM = "USER_LOGIN_BY_TELEGRAM";
export const USER_LOGOUT = "USER_LOGOUT";
export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const ADMIN_LOGIN_AS_WEBMASTER = "ADMIN_LOGIN_AS_WEBMASTER";
export const ADMIN_LOGIN_AS_ADVERTISER = "ADMIN_LOGIN_AS_ADVERTISER";
export const ADMIN_LOGIN_AS_ADMINISTRATOR = "ADMIN_LOGIN_AS_ADMINISTRATOR";
export const ADMIN_LOGOUT_ROLE = "ADMIN_LOGOUT_ROLE";
export const GET_TOKEN = "GET_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SEND_NEW_PASSWORD = "SEND_NEW_PASSWORD";
export const GET_WHO_AM_I = "GET_WHO_AM_I";
export const GET_FINGER_PRINT= "GET_FINGER_PRINT";
export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const UPDATE_PREFERRED_CURRENCY = "UPDATE_PREFERRED_CURRENCY";
export const UPDATE_PREFERRED_VERTICAL = "UPDATE_PREFERRED_VERTICAL";
export const OAUTH2_LOGIN = "OAUTH2_LOGIN";

//signUp
export const UPDATE_FORM = "UPDATE_FORM";
export const SET_FORM = "SET_FORM";

// locale
export const UPDATE_LOCALE = "UPDATE_LOCALE";

// statistics
export const GET_STATISTIC = "GET_STATISTIC";
export const GET_STATISTICS = "GET_STATISTICS";
export const GET_USER_TOTAL = "GET_USER_TOTAL";
export const GET_STATISTIC_GRAPH = "GET_STATISTIC_GRAPH";
export const GET_SELECTED_OFFERS = "GET_SELECTED_OFFERS";
export const GET_SELECTED_OFFERS_REQUEST = "GET_SELECTED_OFFERS_REQUEST";
export const GET_OLD_STATISTIC = "GET_OLD_STATISTIC";
export const GET_DASHBOARD_STATISTICS = "GET_DASHBOARD_STATISTICS";
export const GET_DASHBOARD_STATISTICS_WIDGET = "GET_DASHBOARD_STATISTICS_WIDGET";
export const GET_DASHBOARD_EFFICIENCY_RATE_WIDGET = "GET_DASHBOARD_EFFICIENCY_RATE_WIDGET";
export const GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET = "GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET";
export const GET_DASHBOARD_LEAD_STATISTIC_WIDGET = "GET_DASHBOARD_LEAD_STATISTIC_WIDGET";
export const GET_DASHBOARD_INCIDENCE_WIDGET = "GET_DASHBOARD_INCIDENCE_WIDGET";
export const GET_DASHBOARD_CAPACITY_WIDGET = "GET_DASHBOARD_CAPACITY_WIDGET";
export const GET_DASHBOARD_PAYOUT_WIDGET = "GET_DASHBOARD_PAYOUT_WIDGET";
export const GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET = "GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET";
export const GET_DASHBOARD_STATISTICS_CHART_WIDGET = "GET_DASHBOARD_STATISTICS_CHART_WIDGET";
export const GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET = "GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET";
export const GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET = "GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET";
export const UPDATE_OFFERS_DICTIONARY = "UPDATE_OFFERS_DICTIONARY";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_DAYS_STATS = "GET_DAYS_STATS";
export const GET_DAY_STATISTIC = "GET_DAY_STATISTIC";
export const GET_STATISTIC_TOP_CARD_TABLE_DATA = "GET_STATISTIC_TOP_CARD_TABLE_DATA";
export const GET_ACTIVE_COUNTS = "GET_ACTIVE_COUNTS";
export const GET_FILTER_DATA = "GET_FILTER_DATA";
export const GET_FILTER_INITIAL_DATA = "GET_FILTER_INITIAL_DATA";
export const SYNC_STATISTIC_FILTERS = "SYNC_STATISTIC_FILTERS";
export const GET_SUB_ANALYTIC = "GET_SUB_ANALYTIC";
export const GET_SUB_ANALYTIC_ANALYSE = "GET_SUB_ANALYTIC_ANALYSE";
export const GET_SUB_ANALYTIC_BY_INDEX = "GET_SUB_ANALYTIC_BY_INDEX";
export const GET_GLOBAL_SOURCE = "GET_GLOBAL_SOURCE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_COMPETITION_CURRENT = "GET_COMPETITION_CURRENT";
export const GET_REASONS = "GET_REASONS";

// detailing
export const GET_LEAD_STATUSES = "GET_LEAD_STATUSES";
export const GET_DEPOSIT_HISTORY = "GET_DEPOSIT_HISTORY";
export const UPDATE_SELECTED_LEADS = "UPDATE_SELECTED_LEADS";
export const GET_SENT_TO_CRM_LEADS = "GET_SENT_TO_CRM_LEADS";
export const GET_RE_REQUESTED_LEADS = "GET_RE_REQUESTED_LEADS";
export const GET_PAID_UP_COUNT = "GET_PAID_UP_COUNT";
export const EXTEND_HOLD_TIME = "EXTEND_HOLD_TIME";
export const SELECTED_LEADS = "SELECTED_LEADS";
export const CANCEL_LEADS = "CANCEL_LEADS";
export const COPY_LEADS = "COPY_LEADS";

// analytic
export const GET_ANALYTIC = "GET_ANALYTIC";
export const ADD_INCIDENCE = "ADD_INCIDENCE";
export const SET_USER_TELEGRAM = "SET_USER_TELEGRAM";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const UPDATE_OUR_WEBMASTER = "UPDATE_OUR_WEBMASTER";
export const UPDATE_UNIQUE_KEY = "UPDATE_UNIQUE_KEY";
export const UPDATE_SUBSECTION = "UPDATE_SUBSECTION";
export const UPDATE_ANALYTIC_GROUP_BY_INDEX = "UPDATE_ANALYTIC_GROUP_BY_INDEX";
export const UPDATE_ANALYTIC_FILTERS = "UPDATE_ANALYTIC_FILTERS";
export const SYNC_ANALYTIC_FILTERS = "SYNC_ANALYTIC_FILTERS";
export const UPDATE_CHART_FILTERS = "UPDATE_CHART_FILTERS";
export const UPDATE_ANALYTIC_PAGINATION = "UPDATE_ANALYTIC_PAGINATION";
export const UPDATE_ANALYTIC_GROUP_FILTERS = "UPDATE_ANALYTIC_PAGINATION";
export const UPDATE_ANALYTIC_GROUP_FIELDS = "UPDATE_ANALYTIC_GROUP_FIELDS";
export const GET_INCIDENCE_DETAILS = "GET_INCIDENCE_DETAILS";
export const GET_TOTAL_ANALYTIC_APPROVE = "GET_TOTAL_ANALYTIC_APPROVE";
export const GET_ANALYTIC_REQUIRED_APPROVE = "GET_ANALYTIC_REQUIRED_APPROVE";

// trafficAnalysis
export const GET_TRAFFIC_ANALYSIS = "GET_TRAFFIC_ANALYSIS";
export const GET_ANALYSIS_CHANGE_HISTORY = "GET_ANALYSIS_CHANGE_HISTORY";
export const SYNC_TRAFFIC_ANALYSIS_FILTERS = "SYNC_TRAFFIC_ANALYSIS_FILTERS";

// analyticsTargetAudience
export const GET_ANALYTIC_TARGET_AUDIENCE = "GET_ANALYTIC_TARGET_AUDIENCE";

// landingsHistory
export const GET_LANDINGS_HISTORY = "GET_LANDINGS_HISTORY";
export const SYNC_LANDINGS_HISTORY_FILTERS = "SYNC_LANDINGS_HISTORY_FILTERS";

// Domonetization
export const GET_DOMONETIZATION = "GET_DOMONETIZATION";

// profile
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const LOGOUT_PROFILE_SESSION = "LOGOUT_PROFILE_SESSION";
export const GET_WEBMASTER_PERSONAL_MANAGER = "GET_WEBMASTER_PERSONAL_MANAGER";
export const GET_ADVERTISER_PERSONAL_MANAGER = "GET_ADVERTISER_PERSONAL_MANAGER";
export const GET_WEBMASTER_PERSONAL_AGENT = "GET_WEBMASTER_PERSONAL_AGENT";
export const GET_API_TOKENS = "GET_API_TOKENS";
export const GET_REQUISITES = "GET_REQUISITES";
export const ADD_REQUISITE = "ADD_REQUISITE";
export const REMOVE_REQUISITE = "REMOVE_REQUISITE";
export const USER_EXIST_IN_ZALEYCASH = "USER_EXIST_IN_ZALEYCASH";
export const VALID_TRON_ADDRESS = "VALID_TRON_ADDRESS";
export const GET_PAYMENT_SYSTEMS = "GET_PAYMENT_SYSTEMS";
export const DELETE_PAYMENT_SYSTEM = "DELETE_PAYMENT_SYSTEM";
export const ENABLE_PAYMENT_SYSTEM = "ENABLE_PAYMENT_SYSTEM";
export const DISABLE_PAYMENT_SYSTEM = "DISABLE_PAYMENT_SYSTEM";
export const EDIT_PAYMENT_SYSTEM = "EDIT_PAYMENT_SYSTEM";
export const ADD_PAYMENT_SYSTEM = "ADD_PAYMENT_SYSTEM";
export const GET_TRAFFIC_TYPES = "GET_TRAFFIC_TYPES";
export const GET_OFFER_CATEGORIES = "GET_OFFER_CATEGORIES";
export const REQUEST_TO_CHANGE_MANAGER = "REQUEST_TO_CHANGE_MANAGER";

export const GET_UTM_TAGS = "GET_UTM_TAGS";

export const GET_DETAIL = "GET_DETAIL";
export const REREQUEST_LEAD_STATUSES = "REREQUEST_LEAD_STATUSES";

export const GET_TIMEZONES = "GET_TIMEZONES";
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_REGIONS = "GET_REGIONS";

export const RESEND_EMAIL = "RESEND_EMAIL";
export const UPDATE_WEBMASTER_NOTIFICATIONS = "UPDATE_WEBMASTER_NOTIFICATIONS";

export const GET_USER_CONTEXT_STORAGE = "GET_USER_CONTEXT_STORAGE";
export const SET_USER_CONTEXT_STORAGE = "SET_USER_CONTEXT_STORAGE";
export const REMOVE_USER_CONTEXT_STORAGE = "REMOVE_USER_CONTEXT_STORAGE";

// postback detail
export const GET_STATISTIC_POSTBACKS = "GET_STATISTIC_POSTBACKS";
export const RESEND_DETAILING_POSTBACKS = "RESEND_DETAILING_POSTBACKS";

// admin
export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const GET_UNINTEGRATION_OFFERS = "GET_UNINTEGRATION_OFFERS";
export const SET_INTEGRATION_FILTERS = "SET_INTEGRATION_FILTERS";
export const VALIDATE_CRM_LEAD_GROUP = "VALIDATE_CRM_LEAD_GROUP";
export const GET_CRM = "GET_CRM";
export const UPDATE_CRM_PAGINATION = "UPDATE_CRM_PAGINATION";
export const CREATE_CRM = "CREATE_CRM";
export const CREATE_INTEGRATION = "CREATE_INTEGRATION";
export const DELETE_INTEGRATION = "DELETE_INTEGRATION";
export const LAUNCH_INTEGRATION = "LAUNCH_INTEGRATION";
export const STOP_INTEGRATION = "STOP_INTEGRATION";
export const EDIT_INTEGRATION = "EDIT_INTEGRATION";
export const GET_INTEGRATION_CRM = "GET_INTEGRATION_CRM";
export const UPDATE_INTEGRATION_CRM_LIST_PAGINATION = "UPDATE_INTEGRATION_CRM_LIST_PAGINATION";
export const UPDATE_INTEGRATION_CRM_FILTERS = "UPDATE_INTEGRATION_CRM_FILTERS";
export const UPDATE_FLOW_REDIRECT = "UPDATE_FLOW_REDIRECT";
export const UPDATE_OPTIONS = "UPDATE_OPTIONS";
export const UPDATE_ADMIN_BY_ID = "UPDATE_ADMIN_BY_ID";
export const UPDATE_ADVERTISER_BY_ID = "UPDATE_ADVERTISER_BY_ID";
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";
export const UPDATE_ALL_FILTERS = "UPDATE_ALL_FILTERS";

// Admins list
export const GET_ADMINS_LIST = "GET_ADMINS_LIST";
export const UPDATE_ADMINS_LIST = "UPDATE_ADMINS_LIST";
export const GET_INITIAL_ADMINS = "GET_INITIAL_ADMINS";

// users
export const GET_ADMINISTRATORS = "GET_ADMINISTRATORS";
export const GET_WEBMASTERS = "GET_WEBMASTERS";
export const GET_ADVERTISERS = "GET_ADVERTISERS";
export const WEBMASTER_BALANCE = "WEBMASTER_BALANCE";
export const UPDATE_WEBMASTERS_BALANCE = "UPDATE_WEBMASTERS_BALANCE";
export const UPDATE_ADVERTISERS_BALANCE = "UPDATE_ADVERTISERS_BALANCE";
export const GET_WEBMASTERS_WITH_BALANCE = "GET_WEBMASTERS_WITH_BALANCE";
export const GET_ADVERTISERS_WITH_BALANCE = "GET_ADVERTISERS_WITH_BALANCE";
export const ADVERTISER_BALANCE = "ADVERTISER_BALANCE";
export const EDIT_ADVERTISER = "EDIT_ADVERTISER";
export const EDIT_WEBMASTER = "EDIT_WEBMASTER";
export const EDIT_ADMINISTRATOR = "EDIT_ADMINISTRATOR";
export const CREATE_ADMINISTRATOR = "CREATE_ADMINISTRATOR";
export const EDIT_PASSWORD = "EDIT_PASSWORD";
export const RESEND_EMAIL_BY_ADMIN = "RESEND_EMAIL_BY_ADMIN";
export const GET_WEBMASTER_SUBTYPE = "GET_WEBMASTER_SUBTYPE";
export const CHECK_WEBMASTER = "CHECK_WEBMASTER";
export const FORCE_LOGOUT = "FORCE_LOGOUT";
export const FORCE_LOGOUT_BY_ADMIN = "FORCE_LOGOUT_BY_ADMIN";
export const UPDATE_SUB_ACCOUNTS_BLOCK = "UPDATE_SUB_ACCOUNTS_BLOCK";
export const UPDATE_SUB_ACCOUNTS = "UPDATE_SUB_ACCOUNTS";
export const GET_SUB_ACCOUNTS = "GET_SUB_ACCOUNTS";
export const SWITCH_WRITE_OF = "SWITCH_WRITE_OF";

// Admin. ChangeManager
export const GET_REQUESTS_CHANGE_MANAGERS = "GET_REQUESTS_CHANGE_MANAGERS";
export const RESOLVE_WEBMASTER_REQUEST = "RESOLVE_WEBMASTER_REQUEST";

// Admin.Users
export const EDIT_WEBMASTER_ADMIN_COMMENT = "EDIT_WEBMASTER_ADMIN_COMMENT";
export const EDIT_ADVERTISER_ADMIN_COMMENT = "EDIT_ADVERTISER_ADMIN_COMMENT";

// Admin. Users. Advertisers
export const GET_ADVERTISER_RATES = "GET_ADVERTISER_RATES";

// Admin. Users. Webmasters
export const GET_WEBMASTER_RATES = "GET_WEBMASTER_RATES";
export const UPDATE_WEBMASTER_RATES_FILTERS = "UPDATE_WEBMASTER_RATES_FILTERS";
export const UPDATE_WEBMASTER_RATES_PAGINATION = "UPDATE_WEBMASTER_RATES_PAGINATION";
export const UPDATE_WEBMASTER_ID = "UPDATE_WEBMASTER_ID";

// Admin. Offers
export const GET_OFFERS = "GET_OFFERS";
export const GET_TOP_OFFERS = "GET_TOP_OFFERS";
export const ADD_APPROVED_WEBMASTER = "ADD_APPROVED_WEBMASTER";
export const ADD_APPROVED_ADVERTISER = "ADD_APPROVED_ADVERTISER";
export const ADD_WEBMASTER_PERSONAL_MANAGER = "ADD_WEBMASTER_PERSONAL_MANAGER";
export const DELETE_WEBMASTER_PERSONAL_MANAGER = "DELETE_WEBMASTER_PERSONAL_MANAGER";
export const ADD_ADVERTISER_PERSONAL_MANAGER = "ADD_ADVERTISER_PERSONAL_MANAGER";
export const DELETE_ADVERTISER_PERSONAL_MANAGER = "DELETE_ADVERTISER_PERSONAL_MANAGER";
export const BLOCK_ADVERTISER = "BLOCK_ADVERTISER";
export const UNBLOCK_ADVERTISER = "UNBLOCK_ADVERTISER";
export const BLOCK_WEBMASTER = "BLOCK_WEBMASTER";
export const UNBLOCK_WEBMASTER = "UNBLOCK_WEBMASTER";
export const BLOCK_ADMINISTRATOR = "BLOCK_ADMINISTRATOR";
export const UNBLOCK_ADMINISTRATOR = "UNBLOCK_ADMINISTRATOR";
export const DELETE_OFFER = "DELETE_OFFER";
export const LAUNCH_OFFER = "LAUNCH_OFFER";
export const STOP_OFFER = "STOP_OFFER";
export const PLAN_TO_STOP_OFFER = "PLAN_TO_STOP_OFFER";
export const GET_WEBMASTER_BALANCE = "GET_WEBMASTER_BALANCE";
export const GET_WEBMASTERS_LIST = "GET_WEBMASTERS_LIST";
export const UPDATE_WEBMASTERS_LIST = "UPDATE_WEBMASTERS_LIST";
export const UPDATE_SELECTED_ADVERTISERS = "UPDATE_SELECTED_ADVERTISERS";
export const DISABLE_WEBMASTER_PUBLIC_API = "DISABLE_WEBMASTER_PUBLIC_API";
export const ENABLE_WEBMASTER_PUBLIC_API = "ENABLE_WEBMASTER_PUBLIC_API";

// Admin. CapacityAndBumps
export const GET_ADVERTISERS_CAPACITY = "GET_ADVERTISERS_CAPACITY";
export const GET_ADVERTISERS_CAPACITY_HISTORY = "GET_ADVERTISERS_CAPACITY_HISTORY";
export const GET_SUBSECTION_CAPACITY_HISTORY = "GET_SUBSECTION_CAPACITY_HISTORY";
export const GET_SUBSECTION_CAPACITY = "GET_SUBSECTION_CAPACITY";
export const GET_ANALYSE_CAPACITY = "GET_ANALYSE_CAPACITY";
export const UPDATE_CAPACITY_REQUEST = "UPDATE_CAPACITY_REQUEST";
export const CREATE_CAPACITY_REQUEST = "CREATE_CAPACITY_REQUEST";
export const EDIT_CAPACITY_REQUEST = "EDIT_CAPACITY_REQUEST";
export const DELETE_CAPACITY_REQUEST = "DELETE_CAPACITY_REQUEST";
export const REJECT_CAPACITY_REQUEST = "REJECT_CAPACITY_REQUEST";
export const APPROVE_CAPACITY_REQUEST = "APPROVE_CAPACITY_REQUEST";
export const UPDATE_CAPACITY = "UPDATE_CAPACITY";
export const UPDATE_APPROVE = "UPDATE_APPROVE";
export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_GUARANTEE_REQUEST = "UPDATE_GUARANTEE_REQUEST";
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_GUARANTEE_REQUEST = "CREATE_GUARANTEE_REQUEST";
export const EDIT_BOOKING_REQUEST = "EDIT_BOOKING_REQUEST";
export const EDIT_GUARANTEE_REQUEST = "EDIT_GUARANTEE_REQUEST";
export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_GUARANTEE_REQUEST = "DELETE_GUARANTEE_REQUEST";
export const REJECT_BOOKING_REQUEST = "REJECT_BOOKING_REQUEST";
export const REJECT_GUARANTEE_REQUEST = "REJECT_GUARANTEE_REQUEST";
export const FORCE_FINISH_GUARANTEE_REQUEST = "FORCE_FINISH_GUARANTEE_REQUEST";
export const APPROVE_BOOKING_REQUEST = "APPROVE_BOOKING_REQUEST";
export const APPROVE_GUARANTEE_REQUEST = "APPROVE_GUARANTEE_REQUEST";
export const GET_GUARANTEE_STATISTIC = "GET_GUARANTEE_STATISTIC";
export const UPDATE_SUBSECTIONS = "UPDATE_SUBSECTIONS";
export const UPDATE_SUB_FILTERS = "UPDATE_SUB_FILTERS";
export const UPDATE_PAYOUT_REQUEST = "UPDATE_PAYOUT_REQUEST";
export const CREATE_PAYOUT_REQUEST = "CREATE_PAYOUT_REQUEST";
export const EDIT_PAYOUT_REQUEST = "EDIT_PAYOUT_REQUEST";
export const DELETE_PAYOUT_REQUEST = "DELETE_PAYOUT_REQUEST";
export const REJECT_PAYOUT_REQUEST = "REJECT_PAYOUT_REQUEST";
export const APPROVE_PAYOUT_REQUEST = "APPROVE_PAYOUT_REQUEST";
export const ACTUALIZE_PAYOUT_REQUEST = "ACTUALIZE_PAYOUT_REQUEST";
export const UPDATE_SUBSECTION_REQUEST = "UPDATE_SUBSECTION_REQUEST";
export const GET_CAPACITY_STATISTIC = "GET_CAPACITY_STATISTIC";
export const GET_MINIMAL_PAYOUT = "GET_MINIMAL_PAYOUT";
export const GET_CAPACITY_REQUEST_EFFICIENCY = "GET_CAPACITY_REQUEST_EFFICIENCY";
export const GET_ISSUED_CAPACITIES_WITH_PAGINATION = "GET_ISSUED_CAPACITIES_WITH_PAGINATION";
export const GET_ISSUED_CAPACITIES_GRAPH = "GET_ISSUED_CAPACITIES_GRAPH";
export const UPDATE_CANCEL_CAPACITY_REQUEST = "UPDATE_CANCEL_CAPACITY_REQUEST";
export const CREATE_CANCEL_CAPACITY_REQUEST = "CREATE_CANCEL_CAPACITY_REQUEST";

// Admin. Complaints
export const GET_COMPLAINTS = "GET_COMPLAINTS";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const UPDATE_COMPLAINT_CONSIDERATE = "UPDATE_COMPLAINT_CONSIDERATE";
export const UPDATE_COMPLAINT_FINALIZE = "UPDATE_COMPLAINT_FINALIZE";
export const DELETE_TRAFFIC_COMPLAINT = "DELETE_TRAFFIC_COMPLAINT";
export const SET_PRIORITY_TO_TRAFFIC_COMPLAINT = "SET_PRIORITY_TO_TRAFFIC_COMPLAINT";

// Admin. CapacityAndBumps. LogRequest
export const GET_OFFER_REQUEST = "GET_OFFER_REQUEST";
export const RENEWAL_GUARANTEE_REQUEST = "RENEWAL_GUARANTEE_REQUEST";
export const GET_ALLOWED_OFFER_PARAMETERS = "GET_ALLOWED_OFFER_PARAMETERS";
export const UPDATE_OFFER_REQUEST_EFFECTIVE = "UPDATE_OFFER_REQUEST_EFFECTIVE";
export const UPDATE_OFFER_REQUEST_INEFFECTIVE = "UPDATE_OFFER_REQUEST_INEFFECTIVE";

// Admin. Offer
export const GET_OFFER = "GET_OFFER";
export const GET_OFFER_ID = "GET_OFFER_ID";
export const UPDATE_OFFER_RATES_REQUIREMENTS = "UPDATE_OFFER_RATES_REQUIREMENTS";
export const BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS = "BEFORE_GET_OFFER_EXTERNAL_WEBMASTERS";
export const UPDATE_OFFER_TRAFFIC_TYPES = "UPDATE_OFFER_TRAFFIC_TYPES";
export const GET_OFFER_WEBMASTERS = "GET_OFFER_WEBMASTERS";
export const GET_OFFER_EXTERNAL_WEBMASTERS = "GET_OFFER_EXTERNAL_WEBMASTERS";
export const WEBMASTER_REWARD = "WEBMASTER_REWARD";
export const SET_WEBMASTER_REWARD = "SET_WEBMASTER_REWARD";
export const UPDATE_MINIMAL_ALLOWED_REWARD = "UPDATE_MINIMAL_ALLOWED_REWARD";
export const GET_OFFER_ADVERTISER_WEBMASTERS = "GET_OFFER_ADVERTISER_WEBMASTERS";
export const GET_OFFER_WEBMASTERS_AVAILABLE = "GET_OFFER_WEBMASTERS_AVAILABLE";
export const ADD_OFFER_WEBMASTERS_AVAILABLE = "ADD_OFFER_WEBMASTERS_AVAILABLE";
export const GET_AVAILABLE_WEBMASTERS_BY_ID = "GET_AVAILABLE_WEBMASTERS_BY_ID";
export const GET_OFFER_ADVERTISERS_AVAILABLE = "GET_OFFER_ADVERTISERS_AVAILABLE";
export const UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD = "UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD";
export const ADD_OFFER_ADVERTISERS = "ADD_OFFER_ADVERTISERS";
export const GET_AVAILABLE_ADVERTISERS_BY_ID = "GET_AVAILABLE_ADVERTISERS_BY_ID";
export const UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE = "UPDATE_MINIMAL_ALLOWED_ADVERTISER_RATE";
export const ADD_OFFER_EXTERNAL_WEBMASTERS = "ADD_OFFER_EXTERNAL_WEBMASTERS";
export const GET_WEBMASTERS_BY_ID = "GET_WEBMASTERS_BY_ID";
export const GET_ADVERTISER_BY_ID = "GET_ADVERTISER_BY_ID";
export const ADD_OFFER_CALLCENTERS = "ADD_OFFER_CALLCENTERS";
export const ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE = "ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE = "UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const DELETE_OFFER_ADVERTISERS = "DELETE_OFFER_ADVERTISERS";
export const DELETE_OFFER_WEBMASTERS = "DELETE_OFFER_WEBMASTERS";
export const GET_OFFER_ADVERTISERS = "GET_OFFER_ADVERTISERS";
export const GET_OFFER_ADVERTISERS_LIST = "GET_OFFER_ADVERTISERS_LIST";
export const ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE = "ADD_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const GET_OFFER_ADVERTISER_INDIVIDUAL_RATES = "GET_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE = "DELETE_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATE";
export const CREATE_OFFER = "CREATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const GET_LANDINGS = "GET_LANDINGS";
export const PROXY_OFFER_LANDING = "PROXY_OFFER_LANDING";
export const GET_TRANSITS = "GET_TRANSITS";
export const UPDATE_OFFER_ADVERTISER = "UPDATE_OFFER_ADVERTISER";
export const GET_SELECTED_ADVERTISERS = "GET_SELECTED_ADVERTISERS";
export const GET_INITIAL_WEBMASTERS = "GET_INITIAL_WEBMASTERS";
export const UPDATE_SELECTED_WEBMASTERS = "UPDATE_SELECTED_WEBMASTERS";
export const UPDATE_SELECTED_WEBMASTERS_RATE = "UPDATE_SELECTED_WEBMASTERS_RATE";
export const UPDATE_RATES = "UPDATE_RATES";
export const GET_SELECTED_WEBMASTERS = "GET_SELECTED_WEBMASTERS";
export const GET_OFFER_WEBMASTERS_REWARD = "GET_OFFER_WEBMASTERS_REWARD";
export const GET_WEBMASTER_BY_ID = "GET_WEBMASTER_BY_ID";
export const GET_WEBMASTER_PROFILE_BY_ID = "GET_WEBMASTER_PROFILE_BY_ID";
export const UPDATE_LOCAL_DATA_PARAMS = "UPDATE_LOCAL_DATA_PARAMS";
export const UPDATE_WEBMASTER_BY_ID = "UPDATE_WEBMASTER_BY_ID";
export const UPDATE_OFFER_BY_INT_ID = "UPDATE_OFFER_BY_INT_ID";
export const GET_OFFER_BY_INT_ID = "GET_OFFER_BY_INT_ID";
export const UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF = "UPDATE_MINIMAL_ALLOWED_ADVERTISER_WRITE_OFF";
export const PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE = "PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE = "DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE";
export const BEFORE_GET_OFFER_WEBMASTERS = "BEFORE_GET_OFFER_WEBMASTERS";
export const BEFORE_GET_OFFER_ADVERTISERS = "BEFORE_GET_OFFER_ADVERTISERS";
export const UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES = "UPDATE_OFFER_ADVERTISER_INDIVIDUAL_RATES";
export const BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES = "BEFORE_GET_OFFER_ADVERTISERS_INDIVIDUAL_RATES";
export const PROXY_OFFER = "PROXY_OFFER";
export const GET_LANDING_PARAMETERS = "GET_LANDING_PARAMETERS";

// Admin. Offer. Landings
export const GET_OFFER_CONVERSION = "GET_OFFER_CONVERSION";
export const ADD_OFFER_CONVERSION = "ADD_OFFER_CONVERSION";
export const CREATE_OFFER_LANDING = "CREATE_OFFER_LANDING";
export const EDIT_OFFER_LANDING = "EDIT_OFFER_LANDING";
export const UPLOAD_LANDING_FILE = "UPLOAD_LANDING_FILE";
export const GET_OFFER_LANDINGS_READY = "GET_OFFER_LANDINGS_READY";
export const UPDATE_MANUAL_LANDINGS = "UPDATE_MANUAL_LANDINGS";
export const GET_ANALYSE_DATA = "GET_ANALYSE_DATA";

//audit
export const GET_AUDIT = "GET_AUDIT";

//Competition
export const GET_COMPETITION_DETAIL_DATA = "GET_COMPETITION_DETAIL_DATA";
export const DELETE_COMPETITION = "DELETE_COMPETITION";
export const DRAFT_COMPETITION = "DRAFT_COMPETITION";
export const PLAN_COMPETITION = "PLAN_COMPETITION";
export const GET_COMPETITION = "GET_COMPETITION";
export const GET_COMPETITION_RATING = "GET_COMPETITION_RATING";
export const GET_COMPETITION_PARTICIPANTS = "GET_COMPETITION_PARTICIPANTS";
export const GET_COMPETITION_TOP = "GET_COMPETITION_TOP";
export const GET_COMPETITION_PLACE = "GET_COMPETITION_PLACE";
export const GET_CURRENT_COMPETITION = "GET_CURRENT_COMPETITION";

//subAccounts
export const GET_SUBACCOUNTS = "GET_SUBACCOUNTS";
export const INVITE_WEBMASTER = "INVITE_WEBMASTER";

//Admin. CallCenters
export const CREATE_CALLCENTER = "CREATE_CALLCENTER";
export const CREATE_TIMETABLE = "CREATE_TIMETABLE";
export const GET_CALLCENTERS_UTC = "GET_CALLCENTERS_UTC";
export const GET_AVAILABLE_CALLCENTERS_UTC = "GET_AVAILABLE_CALLCENTERS_UTC";
export const GET_CALLCENTERS = "GET_CALLCENTERS";
export const GET_AVAILABLE_CALLCENTERS_FOR_OFFER = "GET_AVAILABLE_CALLCENTERS_FOR_OFFER";
export const GET_TIMETABLE = "GET_TIMETABLE";
export const EDIT_TIMETABLE = "EDIT_TIMETABLE";
export const DELETE_CALLCENTERS = "DELETE_CALLCENTERS";

// Complaints
export const CREATE_TRAFFIC_COMPLAINT = "CREATE_TRAFFIC_COMPLAINT";
export const ADD_TRAFFIC_COMPLAINT_COMMENT = "ADD_TRAFFIC_COMPLAINT_COMMENT";
export const GET_TRAFFIC_COMPLAINT_COMMENTS = "GET_TRAFFIC_COMPLAINT_COMMENTS";
export const CLOSE_TRAFFIC_COMPLAINT = "CLOSE_TRAFFIC_COMPLAINT";

// Admin. Roles
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLES_BY_ID = "GET_ROLES_BY_ID";
export const EDIT_ADMIN_ROLE = "EDIT_ADMIN_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLES_FORM = "UPDATE_ROLES_FORM";
export const UPDATE_ROLES_PERMISSIONS = "UPDATE_ROLES_PERMISSIONS";
export const UPDATE_ROLES_FORM_MODAL = "UPDATE_ROLES_FORM_MODAL";
export const DELETE_ROLE = "DELETE_ROLE";

// Admin. Domains
export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAINS_BY_NAME = "GET_DOMAINS_BY_NAME";
export const UPDATE_DNS_DOMAIN = "UPDATE_DNS_DOMAIN";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const UPDATE_DOMAINS_PAGINATION = "UPDATE_DOMAINS_PAGINATION";

// Admin. Finances
export const CREATE_INCOME = "CREATE_INCOME";
export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const CREATE_COST = "CREATE_COST";
export const REMOVE_COST = "REMOVE_COST";
export const CREATE_CORRECTION = "CREATE_CORRECTION";
export const GET_CORRECTIONS = "GET_CORRECTIONS";
export const UPDATE_CORRECTIONS = "UPDATE_CORRECTIONS";
export const GET_REFERRAL_BONUS = "GET_REFERRAL_BONUS";
export const SYNC_REFERRAL_FILTERS = "SYNC_REFERRAL_FILTERS";
export const GET_REFERRALS = "GET_REFERRALS";
export const GET_REFERRER = "GET_REFERRER";
export const GET_MY_REFERRALS = "GET_MY_REFERRALS";
export const ENABLE_REFERRAL = "ENABLE_REFERRAL";
export const DiSABLE_REFERRAL = "DiSABLE_REFERRAL";
export const UPDATE_WEBMASTER_REFERRALS = "UPDATE_WEBMASTER_REFERRALS";
export const SET_MY_REFERRALS = "SET_MY_REFERRALS";
export const SET_REFERRALS = "SET_REFERRALS";
export const SET_REFERRER = "SET_REFERRER";
export const GET_ADVERTISERS_DUTY = "GET_ADVERTISERS_DUTY";
export const GET_ADVERTISERS_DUTY_DETAIL = "GET_ADVERTISERS_DUTY_DETAIL";
export const SET_ADVERTISERS_DUTY_DETAIL = "SET_ADVERTISERS_DUTY_DETAIL";
export const GET_ADVERTISERS_DUTY_ANALYSE = "GET_ADVERTISERS_DUTY_ANALYSE";
export const CANCEL_IN_TRANSACTION = "CANCEL_IN_TRANSACTION";

// Admin. IncidenceAnalyse
export const GET_INCIDENCE_ANALYSE = "GET_INCIDENCE_ANALYSE";

// Admin. AnalyticReason
export const GET_ANALYTIC_REASONS = "GET_ANALYTIC_REASONS";

// Admin. Efficiency
export const GET_EFFICIENCY = "GET_EFFICIENCY";
export const GET_EFFICIENCY_GRAPH = "GET_EFFICIENCY_GRAPH";
export const GET_EFFICIENCY_TOTAL = "GET_EFFICIENCY_TOTAL";
export const GET_EFFICIENCY_ADVERTISER = "GET_EFFICIENCY_ADVERTISER";
export const UPDATE_EFFICIENCY_CHART_FILTERS = "UPDATE_EFFICIENCY_CHART_FILTERS";

// Lists. Costs
export const GET_COSTS = "GET_COSTS";
export const PROCESS_COST = "PROCESS_COST";
export const ACCEPT_COST = "ACCEPT_COST";
export const REJECT_COST = "REJECT_COST";
export const MARK_EXPRESS_COST = "MARK_EXPRESS_COST";
export const UNMARK_EXPRESS_COST = "UNMARK_EXPRESS_COST";
export const AUTO_COST = "AUTO_COST";
export const GET_COSTS_BY_TYPE = "GET_COSTS_BY_TYPE";
export const UPDATE_COSTS_TYPE = "UPDATE_COSTS_TYPE";
export const PROXY_COST = "PROXY_COST";

// Lists. Verticals
export const GET_VERTICALS = "GET_VERTICALS";
export const UPDATE_CURRENT_VERTICAL = "UPDATE_CURRENT_VERTICAL";

// Lists. Verticals
export const GET_WEBMASTER_LABELS = "GET_WEBMASTER_LABELS";

// Lists. Refill
export const GET_INCOMES = "GET_INCOMES";

// Lists. Statuses
export const GET_STATUSES = "GET_STATUSES";

// Lists. PaymentSystems
export const GET_PAYMENT_SYSTEMS_LIST = "GET_PAYMENT_SYSTEMS_LIST";
export const UPDATE_PAYMENT_SYSTEMS_LIST = "UPDATE_PAYMENT_SYSTEMS_LIST";
export const GET_INITIAL_PAYMENT_SYSTEMS = "GET_INITIAL_PAYMENT_SYSTEMS";

// Lists. Requisites
export const GET_REQUISITES_LIST = "GET_REQUISITES_LIST";
export const UPDATE_REQUISITES_LIST = "UPDATE_REQUISITES_LIST";
export const GET_INITIAL_REQUISITES = "GET_INITIAL_REQUISITES";

// Lists. Advertisers
export const GET_ADVERTISERS_LIST = "GET_ADVERTISERS_LIST";
export const UPDATE_ADVERTISERS_LIST = "UPDATE_ADVERTISERS_LIST";
export const GET_INITIAL_ADVERTISERS = "GET_INITIAL_ADVERTISERS";

// Lists. Offers
export const GET_OFFERS_LIST = "GET_OFFERS_LIST";
export const GET_INITIAL_OFFERS = "GET_INITIAL_OFFERS";
export const UPDATE_OFFERS_LIST = "UPDATE_OFFERS_LIST";

//Lists. PaymentIntegrations
export const GET_PAYMENT_INTEGRATIONS_LIST = "GET_PAYMENT_INTEGRATIONS_LIST";
export const UPDATE_PAYMENT_INTEGRATIONS_LIST = "UPDATE_PAYMENT_INTEGRATIONS_LIST";
export const GET_PAYMENT_BY_ID = "GET_PAYMENT_BY_ID";
export const UPDATE_PAYMENT_FORM = "UPDATE_PAYMENT_FORM";
export const UPDATE_EDITABLE_PAYMENTS_ID = "UPDATE_EDITABLE_PAYMENTS_ID";
export const UPDATE_PAYMENT_SYSTEM = "UPDATE_PAYMENT_SYSTEM";
export const UPDATE_PAYMENT_FORM_MODAL = "UPDATE_PAYMENT_FORM_MODAL";

//Lists. CRM
export const GET_CRM_LIST = "GET_CRM_LIST";
export const UPDATE_CRM_LIST = "UPDATE_CRM_LIST";
export const GET_INITIAL_CRM = "GET_INITIAL_CRM";

//Lists. Approved
export const GET_APPROVED = "GET_APPROVED";

//Lists. EmailConfirmed
export const GET_EMAIL_CONFIRMED = "GET_EMAIL_CONFIRMED";

//Lists. Blocked
export const GET_BLOCKED = "GET_BLOCKED";

//Lists. PartnerNetwork
export const GET_PARTNER_NETWORK = "GET_PARTNER_NETWORK";

//Lists. Drawdown
export const GET_DRAWDOWN = "GET_DRAWDOWN";

//Lists. WithdrawTypes
export const GET_WITHDRAW_TYPES = "GET_WITHDRAW_TYPES";

//Lists. Domestic Webmasters
export const GET_DOMESTIC_WEBMASTERS = "GET_DOMESTIC_WEBMASTERS";

//Lists. User Type
export const GET_USER_TYPE = "GET_USER_TYPE";

//Lists. OfferTypes
export const GET_OFFER_TYPES = "GET_OFFER_TYPES";

// Lists. Languages
export const GET_LANGUAGES_LIST = "GET_LANGUAGES_LIST";
export const GET_LANGUAGES_INTERNATIONALIZED = "GET_LANGUAGES_INTERNATIONALIZED";

// Lists. Designers
export const GET_DESIGNERS = "GET_DESIGNERS";

// Webmaster. Flow
export const CREATE_FLOW = "CREATE_FLOW";
export const UPDATE_FLOW = "UPDATE_FLOW";
export const SET_EMPTY = "SET_EMPTY";
export const SET_EMPTY_USER_PROFILE = "SET_EMPTY_USER_PROFILE";
export const SET_EMPTY_CONTEXT = "SET_EMPTY_CONTEXT";
export const GET_FLOW = "GET_FLOW";
export const GET_FLOW_PARAMETERS = "GET_FLOW_PARAMETERS";
export const GENERATE_URL = "GENERATE_URL";
export const CREATE_BASE_LINK = "CREATE_BASE_LINK";
export const CREATE_LINK = "CREATE_LINK";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const GET_AVAILABLE_DOMONETIZATIONS = "GET_AVAILABLE_DOMONETIZATIONS";

// Webmaster. Flows
export const GET_FLOWS = "GET_FLOWS";
export const GET_FLOWS_WITH_DOMAINS = "GET_FLOWS_WITH_DOMAINS";

// Webmaster. Offer
export const REMOVE_FLOW = "REMOVE_FLOW";
export const JOIN_OFFER = "JOIN_OFFER";
export const JOIN_PRIVATE_OFFER = "JOIN_PRIVATE_OFFER";
export const JOIN_TO_OFFER = "JOIN_TO_OFFER";
export const EXCLUDE_OFFER = "EXCLUDE_OFFER";
export const GENERATE_OFFER_URL = "GENERATE_OFFER_URL";
export const UPDATE_OFFER_FIELDS = "UPDATE_OFFER_FIELDS";
export const GET_OFFER_INT_ID = "GET_OFFER_INT_ID";

// Webmaster. Finances
export const GET_FINANCES = "GET_FINANCES";
export const ORDER_PAYMENT = "ORDER_PAYMENT";

// CRM
export const GET_METHODS = "GET_METHODS";
export const GET_REQUEST_TYPES = "GET_REQUEST_TYPES";
export const GET_RESPONSE_TYPES = "GET_RESPONSE_TYPES";
export const GET_STRATEGY = "GET_STRATEGY";
export const GET_MACROS = "GET_MACROS";
export const GET_FILTERED_CUSTOM_MACROS = "GET_FILTERED_CUSTOM_MACROS";
export const GET_RECOVERY_STRATEGY = "GET_RECOVERY_STRATEGY";
export const GET_TRANSFER_RULES_URL = "GET_TRANSFER_RULES_URL";
export const GET_CRM_BY_ALIAS = "GET_CRM_BY_ALIAS";
export const PROXY_CRM = "PROXY_CRM";
export const EDIT_CRM = "EDIT_CRM";
export const DELETE_CRM = "DELETE_CRM";
export const DEFAULT_OPTIONS = "DEFAULT_OPTIONS";
export const CHECK_VALID_OPTIONS = "CHECK_VALID_OPTIONS";
export const GET_CRM_OPTIONS = "GET_CRM_OPTIONS";
export const UPDATE_IS_WITHOUT_REQUEST = "UPDATE_IS_WITHOUT_REQUEST";
export const UPDATE_IS_AUTO_REQUESTS = "UPDATE_IS_AUTO_REQUESTS";
export const GET_FLOW_REDIRECT_MACROS = "GET_FLOW_REDIRECT_MACROS";

// Avatar
export const UPDATE_AVATAR = "UPDATE_AVATAR";

// Admin. Finances. WebAccrual
export const GET_WEB_ACCRUAL = "GET_WEB_ACCRUAL";

// Correction
export const GET_CORRECTION_TYPES = "GET_CORRECTION_TYPES";
export const UPDATE_CORRECTION_TYPE = "UPDATE_CORRECTION_TYPE";
export const GET_CORRECTION_TARGET = "GET_CORRECTION_TARGET";
export const GET_FILTERED_CORRECTION = "GET_FILTERED_CORRECTION";
export const GET_CORRECTION_BY_VALUE = "GET_CORRECTION_BY_VALUE";

// Uploads
export const GET_UPLOADS = "GET_UPLOADS";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const GET_FORMAT_FILE = "GET_FORMAT_FILE";
export const GET_UPLOADS_FIELDS = "GET_UPLOADS_FIELDS";
export const GET_UPLOADS_FILE_READY_PERCENT = "GET_UPLOADS_FILE_READY_PERCENT";
export const SET_UPLOAD_FILE_ID = "SET_UPLOAD_FILE_ID";
export const GET_LINK_FOR_DOWNLOAD = "GET_LINK_FOR_DOWNLOAD";

// Webmaster. Offer. Postbacks
export const GET_OFFER_POSTBACKS = "GET_OFFER_POSTBACKS";
export const REMOVE_OFFER_POSTBACK = "REMOVE_OFFER_POSTBACK";
export const SAVE_OFFER_POSTBACKS = "SAVE_OFFER_POSTBACKS";

// Webmaster. Profile. GlobalPostback
export const GET_GLOBAL_POSTBACKS = "GET_GLOBAL_POSTBACKS";
export const SAVE_GLOBAL_POSTBACKS = "SAVE_GLOBAL_POSTBACKS";
export const REMOVE_GLOBAL_POSTBACK = "REMOVE_GLOBAL_POSTBACK";
export const UPDATE_GLOBAL_POSTBACK_STATUSES = "UPDATE_GLOBAL_POSTBACK_STATUSES";

// Advertiser. Finances
export const GET_WRITE_OFFS_PROXY = "GET_WRITE_OFFS_PROXY";
export const GET_WRITE_OFFS_BY_LEADS = "GET_WRITE_OFFS_BY_LEADS";
export const GET_WRITE_OFFS_BY_LEADS_GROUPED = "GET_WRITE_OFFS_BY_LEADS_GROUPED";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_ADVERTISER_BALANCE = "GET_ADVERTISER_BALANCE";

// Advertiser. Incidence
export const GET_INCIDENCE = "GET_INCIDENCE";

// Modal
export const UPDATE_MODAL_ACTIVE = "UPDATE_MODAL_ACTIVE";
export const UPDATE_MODAL_OPTIONS = "UPDATE_MODAL_OPTIONS";
export const UPDATE_MODAL_FORM = "UPDATE_MODAL_FORM";
export const UPDATE_MODAL_DATA = "UPDATE_MODAL_DATA";
export const UPDATE_EXPAND_FULLSCREEN = "UPDATE_EXPAND_FULLSCREEN";
export const SEND_REQUEST = "SEND_REQUEST";
export const SET_EMPTY_WEBMASTERS = "SET_EMPTY_WEBMASTERS";
export const UPDATE_EFFECTIVE = "UPDATE_EFFECTIVE";

// Badges
export const GET_BADGES = "GET_BADGES";
export const UPDATE_OPERATIONS_BADGES = "UPDATE_OPERATIONS_BADGES";

// Discounts
export const GET_DISCOUNTS = "GET_DISCOUNTS";

// Telegram
export const TELEGRAM_CONNECT = "TELEGRAM_CONNECT";

// Filters confirm
export const UPDATE_ACTUAL_FILTERS = "UPDATE_ACTUAL_FILTERS";

// snackbarActions
export const UPDATE_SELECTED = "UPDATE_SELECTED";
export const FILTER_SELECTED = "FILTER_SELECTED";
export const UPDATE_SELECTED_VUETABLE = "UPDATE_SELECTED_VUETABLE";

// Webmasters ID History Modal
export const GET_HISTORY = "GET_HISTORY";
export const GENERATE_NEW_ID = "GENERATE_NEW_ID";

// Registrations
export const GET_REGISTRATIONS = "GET_REGISTRATIONS";
// Theme
export const UPDATE_THEME = "UPDATE_THEME";
