import { RootState } from "@core/store/root-state";
import { ActionTree, MutationTree } from "vuex";
import { AdvertiserOfferInterface } from "@core/store/types/advertiser/offer";
import { OffersResponseDataInterface, OffersState } from "@core/store/types/advertiser/offers";
import { Module } from "@core/store/logic/Module";
import { SET_EMPTY, SET_OFFERS, UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";
import { GET_OFFERS } from "@core/store/action-constants";
import { OffersService } from "@core/services/advertiser/offers/OffersService";
import { OffersAdvertiserOffer } from "@core/logic/advertiser/offers/offers";

export class AdvertiserOffersModule extends Module<OffersState, RootState> {
  private readonly initialState: () => OffersState;
  
  constructor () {
    super();
    
    this.namespaced = true;
  
    this.initialState = (): OffersState => {
      return {
        data: null,
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {},
        sort: null,
        order: null
      };
    };
  
    this.state(this.initialState());
    
    this.mutations(<MutationTree<OffersState>>{
      [SET_EMPTY]: (state): void => {
        Object.assign(state, this.initialState());
      },
      
      [UPDATE_FILTERS]: (state, filters): void => {
        state.filters = { ...state.filters, ...filters };
      },
      
      [UPDATE_PAGINATION]: (state, pagination): void => {
        state.pagination = { ...state.pagination, ...pagination };
      },
      
      [SET_OFFERS]: (state, offers: OffersResponseDataInterface): void => {
        state.data = offers;
      }
    });
    
    this.actions(<ActionTree<OffersState, RootState>>{
      async [GET_OFFERS] ({ commit, state, rootState }) {
        const { verticals: { currentVertical: vertical } } = rootState;
        const { filters, pagination: { perPage: limit, page }, sort, order } = state;
        const offset = (page - 1) * limit;

        const { data } = await OffersService.getOffers({ ...filters, vertical }, limit, offset, sort, order);

        const offers = { ...data.offers, items: data.offers.items.map((offer: AdvertiserOfferInterface) => new OffersAdvertiserOffer(offer)) };
        commit(SET_OFFERS, offers);
        return offers;
      }
    });
    
    return this;
  }
}

