import { AxiosPromise } from "axios";
import Service from "@core/services/common/Service";
import changeProfilePassword from "./graphql/changeProfilePassword.graphql";
import {
  ChangePasswordInput,
  ChangePasswordResponse
} from "@core/store/types/common/password";

export class PasswordService extends Service {
  public static changePassword (input: ChangePasswordInput): AxiosPromise<ChangePasswordResponse> {
    return this.api.post("", {
      query: changeProfilePassword,
      variables: {
        input
      }
    });
  }
}
