import { formatEmptyString } from "@core/filters";

// @vue/components
export const ListView = {
  props: {
    items: {
      type: Array,
      required: true
    },
    empty: {
      type: String,
      default: null
    }
  },
  methods: {
    formatEmptyString
  }
};
