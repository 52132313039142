import { ActionTree, Module } from "vuex";
import { RootState } from "@core/store/root-state";
import { RESEND_DETAILING_POSTBACKS } from "@core/store/action-constants";
import { DetailingPostbacksService } from "@core/services/common/statistic/detailing/detailingPostbacksService";
import { DetailingPostbacksResendingState } from "@core/store/types/common/statistic/detailing/DetailingPostbacksResendingState";

const initialState = (): DetailingPostbacksResendingState => {
  return {
    data: null
  };
};

const state: () => DetailingPostbacksResendingState = initialState;

const actions: ActionTree<DetailingPostbacksResendingState, RootState> = {
  async [RESEND_DETAILING_POSTBACKS] (_, leadIds) {
    
    try {
      await DetailingPostbacksService.sendRecoveryPostbacks(
        leadIds
      );
      
    } catch (e) {
      throw e;
    }
  }
};

export const detailingPostbacksResending: Module<DetailingPostbacksResendingState, RootState> = {
  namespaced: true,
  state,
  actions
};