<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :is-loading="isModalLoading"
        @after-enter="afterEnter">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`webmaster.finances.table.labels.order.payouts`) }}
            </h5>
        </template>
        <FormWithAsideList
            :data="_requisites"
            :selected.sync="requisite"
            :is-loading="isLoadingRequisites">
            <template #buttons="{ item }">
                <LTooltip
                    :active="item.isDisabled"
                    type="is-dark"
                    :label="$t('webmaster.finances.modal.currentlyUnavailable')"
                    position="is-bottom"
                    size="is-large"
                    style="width:100%">
                    <div
                        class="flex">
                        <InlineSvg
                            v-if="item.isDisabled"
                            :src="image"
                            class="mt-1 mr-1">
                        </InlineSvg>
                        <div
                            style="width: 99%">
                            <div class="shouldShrink">
                                {{ item.name }}
                                <span
                                    style="font-size: 13px"
                                    class="has-text-success ml-1">
                                    {{ item.name === "Webmoney" ? $t("webmaster.finances.modal.bonus") : "" }}
                                </span>
                            </div>
                            <div class="shouldShrink">
                                <small>
                                    {{ item.purse }}
                                </small>
                            </div>
                        </div>
                    </div>
                </LTooltip>
            </template>
            <template #content>
                <div>
                    <b-field :label="$t('webmaster.finances.modal.accountNumber') + ` ${ requisiteName || '' } :`">
                        <LInput
                            :value="requisitePurse"
                            disabled
                            type="text">
                        </Linput>
                    </b-field>
                    <div class="columns">
                        <div class="column is-8">
                            <b-field
                                :label="$t('webmaster.finances.modal.amount')"
                                :message="validationAmount"
                                :type="{'is-danger': validationAmount !== ''}">
                                <LInput
                                    v-model.number="amount"
                                    :min="minimalPayout"
                                    :max="maxAmount"
                                    required
                                    :placeholder="$t(`webmaster.finances.table.labels.amount`)"
                                    step="0.01"
                                    type="number">
                                </LInput>
                            </b-field>
                        </div>
                        <div class="column is-4">
                            <b-field :label="$t('webmaster.finances.modal.currency')">
                                <LInput
                                    :value="currency"
                                    disabled
                                    type="text">
                                </LInput>
                            </b-field>
                        </div>
                    </div>
                </div>

                <b-button
                    :loading="isModalLoading"
                    :disabled="!requisiteId || validationAmount !== '' || !amount"
                    class="is-light is-success is-fullwidth"
                    @click="addPayment">
                    {{ $t(`webmaster.finances.table.labels.order.add`) }}
                </b-button>
            </template>
        </FormWithAsideList>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import FormWithAsideList from "@/components/Common/Lists/FormWithAsideList.vue";
  import InlineSvg from "vue-inline-svg";
  import { Modal } from "@core/mixins";
  import { GET_REQUISITES, ORDER_PAYMENT } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import { currencySign } from "@core/filters";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    mixins: [Modal],

    components: {
      InlineSvg,
      ModalBox,
      FormWithAsideList
    },

    data () {
      return {
        translationKey: "webmaster.finances.financeOrder.messages",
        image: require("@/assets/Modal/not-available.svg"),
        amount: null,
        requisite: null
      };
    },

    computed: {
      ...mapState({
        requisites: ({ webmaster }) => webmaster.profile.requisites.requisites,
        balance: ({ balance }) => balance.balance
      }),

      _requisites () {
        const items = this.requisites.items?.map(item => ({
          ...item,
          name: item.paymentSystem.name,
          isDisabled: item.paymentSystem.isDisabled,
          minimalPayout: item.paymentSystem.minimalPayout
        }));

        return {
          items,
          count: this.requisites.count
        };
      },

      balanceInt () {
        const findValueCurrency = this.balance?.find(el => el.currency === this.currency)?.value;
        return parseInt(findValueCurrency);
      },

      isModalLoading () {
        return this.$wait(`webmaster/finances/${ ORDER_PAYMENT }`);
      },

      maxAmount () {
        return Math.max(this.minimalPayout, this.balanceInt);
      },

      requisiteName () {
        return this.requisite?.name;
      },

      requisitePurse () {
        return this.requisite?.purse;
      },

      requisiteId () {
        return this.requisite?.id;
      },
        
      minimalPayout () {
        return this.requisite?.minimalPayout;
      },

      currency () {
        return this.requisite?.paymentSystem.currency;
      },

      isLoadingRequisites () {
        return this.$wait(`webmaster/profile/requisites/${ GET_REQUISITES }`);
      },

      validationAmount () {
        if (this.amount === "") {
          return this.$t(this.translationKey + ".errorAmount");
        } else if (this.amount !== null && this.amount < this.minimalPayout) {
          return this.$t(
            this.translationKey + ".errorMinAmount",
            { amount: this.minimalPayout, currency: currencySign(this.currency) }
          );
        } else if (this.amount !== null && this.amount > this.balanceInt) {
          return this.$t(this.translationKey + ".errorMaxAmount");
        } return "";
      }
    },

    methods: {
      ...mapActions("webmaster", {
        [GET_REQUISITES]: `profile/requisites/${ GET_REQUISITES }`,
        [ORDER_PAYMENT]: `finances/${ ORDER_PAYMENT }`
      }),

      afterEnter () {
        this.GET_REQUISITES();
      },

      closeModal () {
        this.isModalActive = false;
      },

      async addPayment () {
        const { amount, requisiteId } = this;
        const payload = { amount, requisiteId };

        await actionWithToast(this.ORDER_PAYMENT(payload), this.translationKey);

        this.closeModal();
      }
    },
    
    watch: {
      isModalActive (value) {
        if (value) {
          this.amount = null;
          this.requisite = null;
        }
      },

      minimalPayout (value, oldValue) {
        if (value !== oldValue) {
          this.amount = this.minimalPayout;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        footer {
            padding: 0;
        }

        .modal-card-body {
            padding: 0 12px;
        }

        .modal-card {
            width: 650px;
            border: none;

            &-head {
                border-bottom: 1px solid #ECECEC;
            }
        }

        .v-popover, .trigger {
            width: 100%;
        }
    }
    
    .shouldShrink {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
        white-space: nowrap;
    }

    .block {
        $self: &;

        &-box {
            max-width: 280px;

            //noinspection CssInvalidPseudoSelector
            &::v-deep #{ $self }-title {
                display: block;
                margin: 0 0 10px;
                padding: 0 0 0 20px;
                font-size: 18px;
                line-height: 21px;
                color: #212121;
            }
        }
    }

    .order {
        &-btn {
            width: 100%;
            height: 47px;
            font-weight: 600;
            line-height: 16px;
        }
    }
</style>
