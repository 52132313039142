import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import {
  AdvertisersCapacityFilters,
  CapacityOrder,
  CapacitySort
} from "@core/store/types/admin/offers/capacity";

export default function offerWebmastersCapacity (
  limit: number,
  offset: number,
  offerId: string,
  filters: AdvertisersCapacityFilters,
  sort: CapacitySort,
  order: CapacityOrder,
  isRequest: boolean,
  isIndividualRate: boolean,
  isShowRedemption: boolean,
  isShowCommission: boolean
): ASTGeneratedOutput {
  const include = [];
  const exclude = [];

  if (isShowRedemption) {
    include.push("items/statisticCapacity/monthRedemption");
  }

  if (isShowCommission) {
    include.push("items/payoutRequest/webmasterRate");
  }

  if (isIndividualRate && isShowCommission) {
    include.push(
      "items/webmasterIndividualRate/value",
      "items/webmasterIndividualRate/currency"
    );
  }

  if (!isShowCommission) {
    exclude.push(
      "items/capacityRequest/oldCapacityValue",
      "items/capacityRequest/oldCapacityValue"
    );
  }
  
  const params = { include, exclude };
  
  const moneyType = ["value", "currency"];
  const userType = ["name", "id"];
  
  const gqlObject = {
    operation: "offerWebmastersCapacity",
  
    fields: [
      {
        items: [
          {
            requiredApprove: [
              "requiredApprove",
              "updatedAt"
            ]
          },
          {
            webmaster: [
              "id",
              "intId",
              "login",
              "isBindedToCurrentAdmin"
            ]
          },
          {
            capacityRequest: [
              "oldCapacityValue",
              "newCapacityValue",
              "status",
              {
                creator: userType
              },
              {
                executor: userType
              },
              "creatorComment",
              "executorComment",
              "createdAt",
              "updatedAt",
              "id"
            ]
          },
          {
            bookingCapacityRequest : [
              "newCapacityValue",
              "status",
              {
                creator: userType
              },
              {
                executor: userType
              },
              "createdAt",
              "updatedAt",
              "id"
            ]
          },
          {
            payoutRequest: [
              "createdAt",
              "updatedAt",
              {
                creator: userType
              },
              "creatorComment",
              {
                executor: userType
              },
              "executorComment",
              "externalWebmaster",
              "reason",
              "goal",
              "oldPayoutValue",
              "newPayoutValue",
              "status",
              "id"
            ]
          },
          "softCapacity",
          "bookingCapacity",
          "rateUpdatedAt",
          "capacityUpdatedAt",
          "payoutUpdatedAt ",
          {
            statisticCapacity: [
              "yesterdayLeads",
              "yesterdayCapacityIndicator",
              "weekLeads",
              "weekCapacityIndicator",
              "monthLeads",
              "monthCapacityIndicator",
              "yesterdayCapacityEfficiency",
              "weekCapacityEfficiency",
              "monthCapacityEfficiency"
            ]
          },
          {
            webmasterPayout: moneyType
          }
        ]
      },
      "count",
      "aggregates",
      "extras"
    ],
  
    variables: {
      limit: {
        type: "Int",
        value: limit
      },
      offset: {
        type: "Int",
        value: offset
      },
      offerId: {
        type: "Uid!",
        value: offerId
      },
      filters: {
        type: "OfferWebmasterCapacityListCriteriaFilters",
        value: filters
      },
      sort: {
        type: "OfferWebmasterCapacitySort",
        value: sort[offerId] ?? "yesterdayLeads"
      },
      order: {
        type: "SortOrder",
        value: order[offerId] ?? "ASC"
      },
      isRequest: {
        type: "Boolean!",
        value: isRequest
      }
    }
  };
  
  return Generator.query(gqlObject, params);
}