<template>
    <form
        v-if="localUser"
        class="form"
        @submit.prevent.stop="updateUser">
        <b-notification
            v-if="contacts.length"
            :closable="false"
            has-icon
            type="is-info">
            <span v-html="$t('common.auth.signUp.message')"></span>
            <span class="is-flex">
                <span
                    :key="contact.href"
                    v-for="contact in contacts"
                    class="mr-2">
                    <img
                        class="mr-1"
                        style="margin-bottom: -2px"
                        :src="require(`@/assets/Contacts/logo_${ _camelCase(contact.name) }.svg`)">
                    <a
                        :href="contact.href"
                        target="_blank"
                        rel="noopener">{{ contact.name }}
                    </a>
                </span>
            </span>
        </b-notification>
        <AdvertiserProfile :user.sync="localUser">
            <template #buttons>
                <b-button
                    :loading="isLoading"
                    :disabled="isDisabled"
                    class="is-success mt-6"
                    expanded
                    native-type="submit"
                    rounded>
                    {{ $t(`advertiser.profile.values.save`) }}
                </b-button>
            </template>
        </AdvertiserProfile>
    </form>
    <LLoading
        v-else
        :active="true"
        :is-full-page="false"></LLoading>
</template>

<script>
  import _camelCase from "lodash/camelCase";
  import AdvertiserProfile from "@/components/Common/Advertiser/AdvertiserProfile";
  import { capitalizeFirstLetter, moment } from "@core/filters";
  import { Advertiser } from "@core/services/common/user/model";
  import { EDIT_USER } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_ADVERTISER_FORM } from "@core/store/mutation-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  import LLoading from "@/components/Common/LLoading";

  export default {
    name: "SignUpDetailAdvertiser",
    components: { LLoading, AdvertiserProfile },

    data () {
      return {
        localUser: new Advertiser()
      };
    },

    computed: {
      contacts () {
        return ["email", "telegram", "skype", "whatsApp"].map(contact => {
          if (this.$saas.contacts[contact]) {
            return {
              name: capitalizeFirstLetter(contact),
              href: this.$saas.contacts[contact]
            };
          }
          return null;
        }).filter(Boolean);
      },

      ...mapState("advertiser/profile", ["user", "form"]),

      isDisabled () {
        return !(this.localUser.telegram?.length || !!this.localUser.whatsApp || !!this.localUser.viber || this.localUser.skype?.length);
      },

      registeredDate () {
        return moment(this.localUser.activity.registeredAt);
      },

      isLoading () {
        return this.$wait(`advertiser/profile/${ EDIT_USER }`);
      }
    },

    methods: {
      ...mapActions("advertiser/profile", [
        EDIT_USER
      ]),

      ...mapMutations("advertiser/profile", [
        UPDATE_ADVERTISER_FORM,
        SET_EMPTY
      ]),
      _camelCase,

      async updateUser () {
        try {
          await this.EDIT_USER();

          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileSuccessConfirmation"),
            type: "is-success"
          });

          this.SET_EMPTY();
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("advertiser.profile.saveProfileErrorConfirmation"),
            type: "is-danger"
          });
        }
      },

      convertUserToForm () {
        const { login, geo, sizeLeads, contact, comment, timeZone: { alias: timeZone } } = this.user;

        const form = {
          ...contact,
          login,
          geo,
          sizeLeads,
          timeZone,
          comment
        };

        this.UPDATE_ADVERTISER_FORM(form);
        this.localUser = _cloneDeep(this.form);
      }
    },

    watch: {
      user () {
        this.convertUserToForm();
      },

      localUser: {
        deep: true,
        handler (value) {
          this.UPDATE_ADVERTISER_FORM(value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    form {
        max-width: 800px;
    }
</style>
