import Service from "@core/services/common/Service";
import offerConversion from "../../common/offer/graphql/offerConversion.graphql";
import { OfferConversionResponse } from "@core/store/types/admin/offer/lists/offerConversionList";

export class OfferConversionService extends Service {
  public static getOfferConversion (offerId: string): Promise<OfferConversionResponse> {
    return this.api.get("", {
      params: {
        query: offerConversion,
        variables: { offerId }
      }
    });
  }
}
