import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { UPDATE_FORM, USER_REGISTRATION } from "@core/store/action-constants";
import { Form, SignUpState } from "../../../types/common/signup/signUp";
import AuthenticationProvider from "@core/logic/auth/AuthenticationProvider";
import { Registration } from "@core/store/types/common/auth";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";

const state: SignUpState = {
  form: {
    referrerId:  null,
    manager: null,
    login: null,
    password: null,
    email: null,
    userType: null,
    availableVerticals: null,
    fingerPrint: localStorage.getItem("finger_print") || null,
    telegram: null,
    whatsApp: null,
    viber: null,
    skype: null
  }
};

const mutations: MutationTree<SignUpState> = {
  [UPDATE_FORM] (state, payload: Form){
    state.form = { ...state.form, ...payload };
  }
};

const actions: ActionTree<SignUpState, RootState> = {
  [UPDATE_FORM] ({ commit }, form){
    commit(UPDATE_FORM, form);
  },
  async [USER_REGISTRATION] ({ state }, captcha): Promise<void> {
    const fingerPrint = localStorage.getItem("finger_print");
    const verticalsList = Object.values(VerticalsEnum).map(el => el.toLowerCase());

    const { availableVerticals, ...fields } = state.form;
    const data = {
      ...fields,
      fingerPrint,
      availableVerticals: state.form.userType === "webmaster"
        ? availableVerticals === "common" ? verticalsList : availableVerticals ? [availableVerticals] : null
        : null,
      captcha
    };
    await AuthenticationProvider.loginByRegistration(data as Registration);
  }
};

export const signUp: Module<SignUpState, RootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};