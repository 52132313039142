<template>
    <b-field
        :message="isEveryValueValidDate && hasFilledValue ? invalidMessage : null"
        :type="{ 'is-danger' : isEveryValueValidDate && hasFilledValue }">
        <div
            v-click-outside="updateValidation"
            class="columns m-0 p-0 border"
            :class="{'invalid': isEveryValueValidDate && hasFilledValue }">
            <div class="column p-0 is-4">
                <LInput
                    v-model="day"
                    class="no-border"
                    type="number"
                    :required="isValidate"
                    :placeholder="$t(`common.components.datepicker.birthday.day`)"
                    min="1"
                    :max="daysInSelectedMonth">
                </LInput>
            </div>
            <div class="column p-0 is-4 l-select">
                <Select
                    v-model="month"
                    class="white no-border"
                    :get-data="getMonths"
                    prop="value"
                    :required="isValidate"
                    :placeholder="$t(`common.components.datepicker.birthday.month`)">
                </Select>
            </div>

            <div class="column p-0 is-4">
                <LInput
                    v-model="year"
                    class="no-border"
                    :placeholder="$t(`common.components.datepicker.birthday.year`)"
                    :required="isValidate"
                    type="number"
                    :min="minAcceptableYear"
                    :max="maxAcceptableYear">
                </LInput>
            </div>
        </div>
    </b-field>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";
  import momentjs from "moment";
  import { momentInst } from "@core/filters";
  import ClickOutside from "vue-click-outside";
  
  export default {
    name: "BirthDatepicker",
    components: { Select },
    props: {
      value: {
        type: String,
        default: null
      },
      
      required: {
        type: Boolean,
        default: false
      }
    },

    directives: {
      ClickOutside
    },

    mounted () {
      if (this.value) {
        this.setDate();
      }
      if (!this.day) {
        const dateWatcher = this.$watch("value", () => {
          this.setDate();
          dateWatcher();
        });
      }
    },

    data () {
      return {
        day: null,
        month: null,
        year: null,
        invalidMessage: null,
        isValidate: false
      };
    },
      
    updated () {
      if (this.isValidDate) {
        this.$emit("input", this.date);
      } else {
        this.$emit("input", null);
      }
    },
      
    computed: {
      daysInSelectedMonth () {
        return new Date(this.year, this.month, 0).getDate();
      },
      
      maxAcceptableYear () {
        const UNTIL_ADULTHOOD = 18;
        return new Date().getFullYear() - UNTIL_ADULTHOOD;
      },

      minAcceptableYear () {
        const LIFESPAN = 100;
        return this.maxAcceptableYear - LIFESPAN;
      },
      
      date () {
        const { year, month, day } = this;

        if (+year && month !== null && +day) {
          const date = `${ year }-${ ("0" + month).slice(-2) }-${ day }`;
          return momentInst(date, this.local).format();
        } return null;
      },

      hasInvalidDay () {
        return +this.day < 1 || +this.day > this.daysInSelectedMonth;
      },

      hasInvalidMonth () {
        return !this.month;
      },

      hasInvalidYear () {
        return +this.year < this.minAcceptableYear || +this.year > this.maxAcceptableYear;
      },
      
      isValidDate () {
        return this.date ? momentjs(this.date).isValid() : false;
      },

      isEveryValueValidDate () {
        return this.isValidate && this.hasInvalidDay || this.isValidate && this.hasInvalidMonth || this.isValidate && this.hasInvalidYear;
      },
        
      hasFilledValue () {
        return !!this.day || !!this.month || !!this.year;
      }
    },
    
    methods: {
      getMonths () {
        const months = this.$t("common.months").map((month, index) => {
          return {
            name: month,
            value: index + 1
          };
        });

        return {
          items: months,
          count: months.length
        };
      },
      
      setDate () {
        const [ day, month, year ] = momentjs(this.value)
          .format("D.M.YYYY")
          .split(".");
        this.year = year;
        this.month = Number(month);
        this.day = day;
      },

      updateValidation () {
        if (this.hasFilledValue || this.required) {
          this.isValidate = true;
        }
        if (this.hasInvalidDay) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.day", {
            maxAcceptableDay: this.daysInSelectedMonth
          });
        } else if (this.hasInvalidMonth) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.month");
        } else if (this.hasInvalidYear) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.year", {
            minAcceptableYear: this.minAcceptableYear,
            maxAcceptableYear: this.maxAcceptableYear
          });
        } else if (this.hasFilledValue) {
          this.invalidMessage = this.$t("common.components.datepicker.birthday.messages.date");
        } else {
          this.invalidMessage = null;
        }
      }
    },
    watch: {
      hasFilledValue (value) {
        if (!value) {
          this.isValidate = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    $border-color: #dbdbdb;
    $border-color-invert: #b5b5b5;

    .border {
        border: 1px solid $border-color;
        border-radius: 4px;

        &:hover:not(.invalid) {
            border-color: $border-color-invert;

            .l-select {
                border-color: $border-color-invert;
            }
        }
    }

    .invalid {
      border-color: $danger;
    }

    ::v-deep {
        .columns {
            @media screen and (min-width: 465px) {
                display: flex;
            }
        }

        .column {
            min-width: 90px;
        }

        .input {
            min-width: 90px;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        .no-border input, .vs__dropdown-toggle {
            border: none;
        }

        .control {
            .icon {
               display: none;
            }

            &:hover .vs__dropdown-toggle {
                border: none;
            }
        }

        .l-select {
            border-radius: 0 !important;
            border-right: 1px solid $border-color;
            border-left: 1px solid $border-color;

            &:hover {
                border-color: $border-color-invert;
            }

            @media screen and (max-width: 465px) {
                border: none;
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
            }
        }

        .invalid {
            .l-select {
                border-color: $danger;
            }
        }
    }
</style>