import { GraphQLServer } from "@core/plugins/axios";
import Service from "@core/services/common/Service";
import { RequestAdvertiser } from "@core/store/types/admin/offer/OfferAdvertiser.d";
import { AxiosPromise } from "axios";

import offers from "./graphql/advertisers.graphql";
import editOfferAdvertiser from "./graphql/editOfferAdvertiser.graphql";
import getMinimalAllowedAdvertisersWriteOff from "./graphql/getMinimalAllowedAdvertisersWriteOff.graphql";
import offerExcludeMultipleAdvertisers from "./graphql/offerExcludeMultipleAdvertisers.graphql";
import offerIncludeMultipleAdvertisers from "./graphql/offerIncludeMultipleAdvertisers.graphql";

export class OfferEditAdvertisersService extends Service {

  public static getOfferAdvertisers (limit: number, offset: number, offerId: string): AxiosPromise {
    try {
      return GraphQLServer.get("", {
        params: {
          query: offers,
          variables: {
            limit,
            offset,
            offerId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getMinimalAllowedAdvertisersWriteOff (limit: number, offset: number,
    offerId: string, advertisersIds: string[]): AxiosPromise {
    try {
      return GraphQLServer.get("", {
        params: {
          query: getMinimalAllowedAdvertisersWriteOff,
          variables: {
            limit,
            offset,
            offerId,
            advertisersIds
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static offerExcludeMultipleAdvertisers (offerId: string, advertisers: RequestAdvertiser[]): AxiosPromise {
    try {
      return GraphQLServer.post("", {
        query: offerExcludeMultipleAdvertisers,
        variables: { offerId, input: { advertisers } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static offerIncludeMultipleAdvertisers (offerId: string, advertisers: RequestAdvertiser[]): AxiosPromise {
    try {
      return GraphQLServer.post("", {
        query: offerIncludeMultipleAdvertisers,
        variables: { offerId, input: { advertisers } }
      });
    } catch (e) {
      throw e;
    }
  }

  public static editOfferAdvertiser (offerId: string, params: RequestAdvertiser): AxiosPromise {
    try {
      return GraphQLServer.post("", {
        query: editOfferAdvertiser,
        variables: { offerId, input: { ...params } }
      });
    } catch (e) {
      throw e;
    }
  }
}
