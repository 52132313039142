import Service from "@core/services/common/Service";
import {
  CostsFilters,
  CostsState,
  ResponseCostsItem
} from "@core/store/types/advertiser/finances/modules/costs";
import { AxiosResponse } from "axios";
import getCosts from "./graphql/writeOffsByLeads.graphql";
import getCostsByLeadsGrouped from "./graphql/writeOffsByLeadsGrouped.graphql";
import { Currencies } from "@core/store/types/common/enums/currenciesEnum";

export class FinancesCostsService extends Service {
  public static async getCosts (
    limit: number,
    offset: number,
    filters: CostsState["filters"],
    currency: Currencies
  ): Promise<AxiosResponse<any>> {
    try {
      const { data: { writeOffsByLeads } } = await this.api.get("", {
        params: {
          query: getCosts,
          variables: {
            limit,
            offset,
            filters,
            currency
          }
        }
      });
      
      writeOffsByLeads.items = writeOffsByLeads.items.map((item: ResponseCostsItem) => {
        return {
          ...item,
          id: item.lead?.id,
          remoteId: item.lead?.remoteId
        };
      });
      
      return writeOffsByLeads;
    } catch (e) {
      throw e;
    }
  }
  
  public static async getCostsByLeadsGrouped (
    limit: number,
    offset: number,
    filters: CostsState["filters"],
    group: CostsFilters["group"],
    currency: Currencies
  ): Promise<any> {
    try {
      return await this.api.get("", {
        params: {
          query: getCostsByLeadsGrouped,
          variables: {
            limit,
            offset,
            filters,
            group,
            currency
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
