import { OfferLandingsFilters, OfferLandingsState } from "@core/store/types/common/offerLandings";
import { OfferLanding } from "@core/store/logic/common/offer/OfferLanding";
import { OfferService } from "@core/services/webmaster/OfferService";
import { RootState } from "@core/store/root-state";
import { ADD_OFFER_CONVERSION, SET_EMPTY } from "@core/store/action-constants";

export class OfferLandings extends OfferLanding<OfferLandingsState, RootState> {
  private readonly initState: () => OfferLandingsState;

  constructor (pageType: OfferLandingsFilters["pageType"]) {
    super();
    
    this.namespaced = true;
    
    this.initState = (): OfferLandingsState => {
      return {
        landings: {
          items: [],
          count: 0
        },
        pagination: {
          perPage: 25,
          page: 1
        },
        filters: {
          pageType
        }
      };
    };
    
    this.state(this.initState());
  
    this.actions({
      async GET_FILE_ID ({ rootState }, id): Promise<void> {
        const { isDomonetizationDisable, domonetizationType } = rootState.webmaster.offer.uploadingSettings.options;
        const { data: { landingArchive: fileId } } = await OfferService.getFileId(id, !isDomonetizationDisable, domonetizationType);

        const url = `${ process.env.VUE_APP_API_URL }/landing/file/${ fileId }`;
        window.open(url);
      },

      [ADD_OFFER_CONVERSION] ({ commit, rootState }): void {
        // @ts-ignore
        const offerConversionList = rootState.webmaster.offer.offerConversionList.offerConversion;
        commit(ADD_OFFER_CONVERSION, offerConversionList);
      }
    });
    
    this.mutations({
      [SET_EMPTY]: (state: OfferLandingsState): void => {
        Object.assign(state, this.initState());
      }
    });

    return this;
  }
}
