import { AxiosPromise, AxiosResponse } from "axios";
import Service from "@core/services/common/Service";
import deleteOffer from "@core/services/admin/offers/graphql/deleteOffer.graphql";
import launchOfferQuery from "@core/services/admin/offers/graphql/launchOffer.graphql";
import stopOfferQuery from "@core/services/admin/offers/graphql/stopOffer.graphql";
import planToStopOffer from "@core/services/admin/offers/graphql/planToStopOffer.graphql";
import includeWebmastersToOffers from "@core/services/admin/offers/graphql/includeWebmastersToOffers.graphql";
import excludeWebmastersFromOffers from "@core/services/admin/offers/graphql/excludeWebmastersFromOffers.graphql";
import excludeMultipleWebmastersFromMultipleOffers
  from "@core/services/admin/offers/graphql/excludeMultipleWebmastersFromMultipleOffers.graphql";
import { OffersRequest, OffersResponse, PlanToStopOffer, StopOfferCommandInput } from "@core/store/types/admin/offers";

// tslint:disable-next-line:max-classes-per-file
export class OffersService extends Service {
  public static getOffers (params: OffersRequest): Promise<AxiosResponse<OffersResponse>> {
    return this.rest.get("/admin/offers", { params });
  }

  public static async deleteOffer (offerId: string): Promise<AxiosPromise> {
    try {
      const { data: { removeOffer } } = await this.api.post("", {
        query: deleteOffer,
        variables: { offerId }
      });
      
      return removeOffer;
    } catch (e) {
      throw e;
    }
  }

  public static async launchOffer (offerId: string): Promise<AxiosResponse<OffersResponse>> {
    try {
      const { data: { launchOffer } } = await this.api.post("", {
        query: launchOfferQuery,
        variables: { offerId }
      });
    
      return launchOffer;
    } catch (e) {
      throw e;
    }
  }

  public static async stopOffer (id: string, input: StopOfferCommandInput): Promise<AxiosResponse<OffersResponse>> {
    try {
      const { data: { stopOffer } } = await this.api.post("", {
        query: stopOfferQuery,
        variables: { id, input }
      });
    
      return stopOffer;
    } catch (e) {
      throw e;
    }
  }

  public static async planToStopOffer (id: string, input: PlanToStopOffer): Promise<AxiosResponse<OffersResponse>> {
    try {
      const { data: { planToStopOffer: data } } = await this.api.post("", {
        query: planToStopOffer,
        variables: { id, input }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }

  public static async includeWebmastersToOffers (offerIds: string[], webmasterIds: string[]): Promise<AxiosResponse<any>> {
    try {
      return await this.api.post("", {
        query: includeWebmastersToOffers,
        variables: {
          offerIds,
          webmasterIds
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async excludeWebmastersFromOffers (offerIds: string[], webmasterIds: string[]): Promise<AxiosResponse<any>> {
    try {
      return await this.api.post("", {
        query: excludeWebmastersFromOffers,
        variables: {
          offerIds,
          webmasterIds
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static excludeMultipleWebmastersFromMultipleOffers (input: { offers: string[]}): Promise<AxiosResponse<any>> {
    try {
      return this.api.post("", {
        query: excludeMultipleWebmastersFromMultipleOffers,
        variables: {
          input
        }
      });
    } catch (e) {
      throw e;
    }
  }
}
