import { ASTGeneratedOutput, Generator } from "@core/services/common/Generator";
import { PayoutRequestInput } from "@core/store/types/admin/offers/capacity";

export default function createPayoutRequest (
  input: PayoutRequestInput,
  isShowCommission: boolean

): ASTGeneratedOutput {

  const include = [];
  if (isShowCommission) {
    include.push(
      "webmasterRate"
    );
  }
  const params = {
    include
  };

  const gqlObject = {
    operation: "createPayoutRequest",

    fields: [
      "newPayoutValue",
      "oldPayoutValue",
      "creatorComment",
      "status",
      "externalWebmaster",
      "goal",
      "createdAt",
      "updatedAt",
      "id",
      "intId",
      {
        creator: [
          "login",
          "id",
          "intId"
        ]
      },
      {
        executor: [
          "name",
          "id"
        ]
      },
      {
        webmaster: [
          "login",
          "id",
          "isBindedToCurrentAdmin",
          "intId"
        ]
      }
    ],

    variables: {
      input: {
        type: "CreatePayoutRequestInput!",
        value: input
      }
    }
  };

  return Generator.mutation(gqlObject, params);
}