import Service from "@core/services/common/Service";
import adminById from "@core/services/admin/common/graphql/adminById.graphql";
import { AdminDataByIdDataResponse } from "@core/store/types/admin/common/AdminOutput";

export class AdminByIdService extends Service {
  public static async getAdminById (adminId: string): Promise<AdminDataByIdDataResponse> {
    try {
      const { data: { adminById: data } } = await this.api.get("", {
        params: {
          query: adminById,
          variables: {
            adminId
          }
        }
      });
      
      return data;
    } catch (e) {
      throw e;
    }
  }
}
