import { ActionTree, Module, MutationTree } from "vuex";
import { RootState } from "@core/store/root-state";
import { StatisticService } from "@core/logic/webmaster/statistic/statistic";
import {
  SET_EMPTY,
  SET_STATISTIC,
  UPDATE_FILTERS,
  SET_TODAY_STATISTIC
} from "@core/store/mutation-constants";
import {
  GET_DAY_STATISTIC,
  GET_STATISTIC,
  GET_CURRENCY
} from "@core/store/action-constants";
import { StatisticItems } from "@core/store/types/common/statistic/common/statistic";
import { currencyAvailableToSort } from "@core/flowMethods";
import { datepicker } from "@core/helpers/datepicker";
import { WebmasterStatistic, TodayStatistic } from "@core/store/types/webmaster/dashboard";
import { CompetitionPeriodEnum } from "@core/store/types/admin/competition/enum/competitionPeriodEnum";

const initialState = (): WebmasterStatistic => {
  return {
    chart: null,
    filters: {
      datepicker: datepicker({ amount: 1, unit: "month" }),
      currency: null,
      period: CompetitionPeriodEnum.CURRENT
    },
    todayStatistic: {
      confirmed: [],
      income: [],
      leads: [],
      clicks: [],
      commission: []
    }
  };
};

const state: () => WebmasterStatistic = initialState;

const mutations: MutationTree<WebmasterStatistic> = {
  [SET_EMPTY] (state, { target, filters } = {}): void {
    if (target) {
      // @ts-ignore
      state[target] = { ...initialState()[target], ...filters };
    } else {
      Object.assign(state, initialState());
    }
  },

  [SET_TODAY_STATISTIC] (state, todayStatistic: WebmasterStatistic["todayStatistic"]): void {
    state.todayStatistic = todayStatistic;
  },

  [SET_STATISTIC] (state, chart: WebmasterStatistic["chart"]): void {
    state.chart = chart;
  },

  [UPDATE_FILTERS] (state, filters: WebmasterStatistic["filters"]): void {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions: ActionTree<WebmasterStatistic, RootState> = {
  async [GET_STATISTIC] ({ state, commit, rootState, rootGetters: { isAgent } }): Promise<void> {
    const { verticals: { currentVertical: vertical } } = rootState;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { datepicker, period, ...otherFilters } = state.filters;

    const { data: { statistics } } = await StatisticService.getWebmasterStatisticDashboard(
      { ...datepicker, ...otherFilters, vertical },
      ["DAY"],
      isAgent
    );

    commit(SET_STATISTIC, statistics);
  },

  async [GET_DAY_STATISTIC] ({ commit, dispatch, rootState, rootGetters: { isAgent } }): Promise<void> {
    const { verticals: { currentVertical: vertical } } = rootState;
    const { data: { statistics } } = await StatisticService.getWebmasterStatisticDashboard(
      { ...datepicker(), vertical },
      ["DAY", "CURRENCY"],
      isAgent
    );
    const currencies = await dispatch(`currencyList/${ GET_CURRENCY }`, undefined, { root: true });

    const zeroStatisticItems: Array<StatisticItems> = currencies
      .map((currency: string) => {
        return {
          countLeadsWhite: 0,
          countLeadsAccepted: 0,
          moneyWebmasterAccrued: 0,
          moneyAgentCommission: 0,
          currency
        };
      });

    const items = statistics.items.length
      ? zeroStatisticItems.map((item) => {
        const findElement = statistics.items.find((el) => el.currency === item.currency);
        if (findElement) {
          return findElement;
        }
        return item;
      })
      : zeroStatisticItems;

    const payload = currencyAvailableToSort(items)?.reduce((acc: TodayStatistic, obj: StatisticItems) => {
      acc.leads.push({ value: obj.countLeadsWhite, currency: obj.currency });
      acc.confirmed.push({ value: obj.countLeadsAccepted, currency: obj.currency });
      acc.income.push({ value: obj.moneyWebmasterAccrued, currency: obj.currency });
      acc.commission.push({ value: obj.moneyAgentCommission, currency: obj.currency });
      return acc;
    }, {
      leads: <TodayStatistic["leads"]>[],
      confirmed: <TodayStatistic["confirmed"]>[],
      income: <TodayStatistic["income"]>[],
      commission: <TodayStatistic["commission"]>[]
    });

    commit(SET_TODAY_STATISTIC, payload);
  },

  [UPDATE_FILTERS] ({ commit }, payload: WebmasterStatistic["filters"]): void {
    commit(UPDATE_FILTERS, payload);
  },

  [SET_EMPTY] ({ commit, rootState: { auth: { preferredCurrency: currency } } }, target?: string): void {
    commit(SET_EMPTY, { target, filters: { currency } });
  }
};

export const statistic: Module<WebmasterStatistic, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
