













































































import VueDatePicker from "vue2-datepicker";
import momentjs from "moment";
import { defineEmits, defineProps, withDefaults } from "@vue/runtime-dom";
import { computed, ref } from "@vue/composition-api";
import storeInstance from "@/store";
import { i18n } from "@core/plugins";
import { TranslateResult } from "vue-i18n";
import { momentToDate } from "@core/helpers/momentToDate";
import { momentInst } from "@core/filters";
interface Props {
  value: Date;
  types: Array<"month" | "year">;
  clearable: boolean;
  multiDate: boolean;
  placeholder: TranslateResult | string;
  disabled: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: true,
    type: null
  },
  types: {
    key: "types",
    required: false,
    type: Array,
    default: () => ["month"]
  },
  clearable: {
    key: "clearable",
    required: false,
    type: Boolean,
    default: false
  },
  multiDate: {
    key: "multiDate",
    required: false,
    type: Boolean,
    default: false
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: [null, String],
    default: () => i18n.t("common.entity.filters.date")
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const currentType = ref(props.multiDate ? "date" : "month");
  const _value = computed({
    get: () => props.value,
    set: value => emit("input", value)
  });
  const open = ref(false);
  const lang = computed(() => storeInstance.state.locale.settingsLeng);
  const sortedValue = computed(() => _value.value?.toSorted((a: number, b: number) => a - b));
  const allMonth = computed(() => {
    const month: Record<string, string> = {};
    const months = i18n.t("common.months") as unknown as string[];
    months.forEach((item, idx) => {
      const num = idx + 1;
      month[num.toString().padStart(2, "0")] = item;
    });
    return month;
  });
  function notBeforeToday(date: Date) {
    return props.types.length > 1 ? date < momentToDate(momentjs().startOf("month").add(1, "month")) : null;
  }
  return {
    momentjs,
    momentInst,
    currentType,
    _value,
    open,
    lang,
    sortedValue,
    allMonth,
    notBeforeToday
  };
};
__sfc_main.components = Object.assign({
  VueDatePicker
}, __sfc_main.components);
export default __sfc_main;
