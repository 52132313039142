// @vue/components
export const CRM = {
  methods: {
    addMacro (macro, ref = "requestUrl", type = "input") {
      const selectionStart = this.$refs[ref].$refs[type].selectionStart;
      const selectionEnd = this.$refs[ref].$refs[type].selectionEnd;
      
      this.$refs[ref].$refs[type].setRangeText(macro, selectionStart, selectionEnd, "end");
      this[ref] = this.$refs[ref].$refs[type].value;
    },
    
    isJsonString (str) {
      if (str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
      }
      return true;
    }
  }
};
