import {
  UPDATE_MODAL_ACTIVE
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { ActionTree, Module, MutationTree } from "vuex";
import { GET_SELECTED_OFFERS_REQUEST } from "@core/store/action-constants";
import { SET_SELECTED_OFFERS_REQUEST } from "@core/store/mutation-constants";
import { typeEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";
import { CapacityRequestRatingModalState } from "@core/store/types/admin/capacityAndBumps/capacityRequestRatingModal";
import { BampsAndCapsItem } from "@core/store/types/admin/offers/bampsAndCaps";

const initialState = (): CapacityRequestRatingModalState => {
  return {
    offerParameterRequests: null,
    isModalActive: false
  };
};

const state: () => CapacityRequestRatingModalState = initialState;

const mutations: MutationTree<CapacityRequestRatingModalState> = {
  SET_EMPTY: (state): CapacityRequestRatingModalState => Object.assign(state, initialState()),

  [UPDATE_MODAL_ACTIVE] (state, payload: boolean): void {
    state.isModalActive = payload;
  },

  [SET_SELECTED_OFFERS_REQUEST] (state, offerParameterRequests): void {
    state.offerParameterRequests = offerParameterRequests;
  }
};

const actions: ActionTree<CapacityRequestRatingModalState, RootState> = {
  [UPDATE_MODAL_ACTIVE] ({ commit }, payload: boolean): void {
    commit(UPDATE_MODAL_ACTIVE, payload);
  },

  [GET_SELECTED_OFFERS_REQUEST] ({ commit, rootState }): void {
    const { selected } = rootState.snackbarActions;

    const items = selected.map((el: BampsAndCapsItem) => ({
      webmaster: el.webmaster.intId,
      countries: el.offer.countries.map(item => item.name).join(", "),
      offer: `${ el.offer.detail.name } [${ el.offer.intId }]`,
      capacity: el.type === typeEnum.CAPACITY || el.type === typeEnum.BOOKING ? el.newCapacityValue : el.newPayoutValue ?? 0
    }));

    commit(SET_SELECTED_OFFERS_REQUEST, items);
  }
};

export const capacityRequestRatingModal: Module<CapacityRequestRatingModalState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
