import { ActionTree, Module, MutationTree } from "vuex";
import { SET_FLOWS_DICTIONARY, SET_OFFERS_DICTIONARY, UPDATE_FLOWS_DICTIONARY, SET_PAYMENT_SYSTEMS } from "../../mutation-constants";
import { RootState } from "../../root-state";
import { UPDATE_OFFERS_DICTIONARY, GET_PAYMENT_SYSTEMS } from "../../action-constants";
import { DictionariesService } from "@core/logic/webmaster/dictionaries/dictionaries";
import { OfferFilter, OffersFilterList } from "@core/logic/common/dictionaries/OfferFilter";
import { FlowFilter } from "@core/logic/common/dictionaries/FlowFilter";
import { PaymentSystemList } from "@core/logic/common/dictionaries/PaymentSystemFilter";

export interface DictionariesState {
  offers: OffersFilterList | null;
  flows: OffersFilterList | null;
  paymentSystems: PaymentSystemList | null;
}

const state: DictionariesState = {
  offers: null,
  flows: null,
  paymentSystems: null
};

const mutations: MutationTree<DictionariesState> = {
  [SET_PAYMENT_SYSTEMS] (state, payload) {
    state.paymentSystems = payload;
  },

  [SET_OFFERS_DICTIONARY] (state, payload: OffersFilterList) {
    state.offers = payload;
  },
  
  [UPDATE_OFFERS_DICTIONARY] (state, payload: OffersFilterList) {
    const items = state.offers ? state.offers.items : [];

    state.offers = {
      count: payload.count,
      items: items.concat(payload.items)
    };
  },
  
  [SET_FLOWS_DICTIONARY] (state, payload: OffersFilterList) {
    state.flows = payload;
  },
  
  [UPDATE_FLOWS_DICTIONARY] (state, payload: OffersFilterList) {
    const items = state.flows ? state.flows.items : [];
    
    state.flows = {
      count: payload.count ? items.length + payload.items.length : 0,
      items: items.concat(payload.items)
    };
  }
};

const actions: ActionTree<DictionariesState, RootState> = {
  async [GET_PAYMENT_SYSTEMS] ({ commit }) {
    const { data } = await DictionariesService.getPaymentSystems();

    commit(SET_PAYMENT_SYSTEMS, data);
  },

  async [UPDATE_OFFERS_DICTIONARY] ({ commit }, payload): Promise<OffersFilterList> {
    const { data } = await DictionariesService.getOffers(payload);
    data.items.forEach((item: any) => new OfferFilter(item));
    if (payload.offset === 0) {
      commit(SET_OFFERS_DICTIONARY, data);
    } else {
      commit(UPDATE_OFFERS_DICTIONARY, data);
    }
    
    return data;
  },
  
  async UPDATE_BUNCHES_DICTIONARY ({ commit }, payload): Promise<OffersFilterList> {
    const { data } = await DictionariesService.getBunches(payload);
    data.items.forEach((item: any) => new OfferFilter(item));
    if (payload.offset === 0) {
      commit(SET_OFFERS_DICTIONARY, data);
    } else {
      commit(UPDATE_OFFERS_DICTIONARY, data);
    }
    
    return data;
  },
  
  async [UPDATE_FLOWS_DICTIONARY] ({ commit }, payload) {
    const { data: { landingFlows: data } } = await DictionariesService.getDetailsFlows(payload);
    data.items.forEach((item: any) => new FlowFilter(item));
    if (payload.offset === 0) {
      commit(SET_FLOWS_DICTIONARY, data);
    } else {
      commit(UPDATE_FLOWS_DICTIONARY, data);
    }
    
    return data;
  }
};

export const dictionaries: Module<DictionariesState, RootState> = {
  state,
  mutations,
  actions
};
