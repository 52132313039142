import { CRMOptions } from "@core/store/types/admin/crm/CreateCRMState";
import _cloneDeep from "lodash/cloneDeep";
import { hasEmptyObject } from "@core/helpers/hasEmptyObject";

export default function requestOptionsMapper (
  options: CRMOptions,
  isWithoutRequest: boolean
): CRMOptions {
  const newOptions: CRMOptions = _cloneDeep(options);
  
  if (isWithoutRequest) {
    // @ts-ignore
    newOptions.integrationDetail = null;
  } else if (newOptions?.integrationDetail) {
    newOptions.integrationDetail = {
      ...newOptions.integrationDetail,
      // @ts-ignore
      requestBody: toJSON(newOptions.integrationDetail?.requestBody),
      // @ts-ignore
      requestHeaders: toJSON(newOptions.integrationDetail?.requestHeaders)
    };
  }
  if (hasEmptyObject(newOptions?.recoveryDetail)) {
    // @ts-ignore
    newOptions.recoveryDetail = null;
  } else {
    newOptions.recoveryDetail = {
      ...newOptions.recoveryDetail,
      // @ts-ignore
      requestHeaders: toJSON(newOptions.recoveryDetail.requestHeaders)
    };
  }

  if (newOptions?.postbackDetail) {
    const { statusMap: { returnField, ...otherPostbackDetail }, ...fields } = newOptions.postbackDetail ?? {};
    // @ts-ignore
    newOptions.postbackDetail = { ...fields, statusMap: { return: returnField, ...otherPostbackDetail } };
  }
  
  // @ts-ignore
  newOptions.flowRedirect = toJSON(newOptions?.flowRedirect);
  
  return newOptions;
}

function toJSON (
  str: string | undefined
): Record<string, unknown> | null {
  return str?.length ? JSON.parse(str) : null;
}
