import { AxiosResponse } from "axios";
import { Flow, Flows } from "@core/store/modules/webmaster/flows";
import Service from "@core/services/common/Service";

export class FlowsService extends Service{
  public static getFlows (params: any): Promise<AxiosResponse<Flows>> {
    return this.rest.get("/webmaster/flows", { params });
  }
  
  // public static getFlow (id: string): Promise<AxiosResponse<Flow>> {
  //   return this.rest.get(`/webmaster/`);
  // }
  
  public static createFlow (params: Flow): Promise<AxiosResponse<Flow>> {
    return this.rest.post("/webmaster/flows", params);
  }
  
  // public static updateFlow (id: string, params: Flow): Promise<AxiosResponse<Flow>> {
  //   return this.rest.put(`/webmaster/`, params);
  // }
  
  public static deleteFlow (id: string): Promise<AxiosResponse<Flow>> {
    return this.rest.delete(`/webmaster/flows/${ id }`);
  }
}
