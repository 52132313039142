import { ActionTree, Module, MutationTree } from "vuex";
import { CapacityHistoryState } from "@core/store/types/admin/offers/capacity";
import { RootState } from "@core/store/root-state";
import {
  GET_ADVERTISERS_CAPACITY_HISTORY,
  GET_SUBSECTION_CAPACITY_HISTORY
} from "@core/store/action-constants";
import { OffersService as GqlOffersService } from "@core/services/admin/offers/OffersService";
import {
  SET_CAPACITY_HISTORY,
  SET_EMPTY,
  UPDATE_MODAL,
  UPDATE_PAGINATION
} from "@core/store/mutation-constants";

class CapacityHistory implements Module<CapacityHistoryState, RootState> {
  public namespaced: boolean;
  public state: CapacityHistoryState;
  
  public mutations: MutationTree<CapacityHistoryState> = {
    [SET_CAPACITY_HISTORY]: (state, payload): void => Object.assign(state.data, payload),
    
    [UPDATE_MODAL] (state, modal) {
      state.modal = { ...state.modal , ...modal };
    },
  
    [UPDATE_PAGINATION] (state, pagination) {
      state.pagination = { ...state.pagination, ...pagination };
    }
  }
  
  public actions: ActionTree<CapacityHistoryState, RootState> = {
    async [GET_ADVERTISERS_CAPACITY_HISTORY] ({ state, commit }) {
      const limit = state.pagination?.perPage || 10;
      const offset = limit * ((state.pagination?.page || 1) - 1);
      const userId = state.modal.userId;
      
      const { data: { advertiserCapacityHistory } } = await GqlOffersService.getAdvertiserCapacityHistory(
        limit,
        offset,
        userId
      );
      
      commit(SET_CAPACITY_HISTORY, advertiserCapacityHistory);
      return advertiserCapacityHistory;
    },
    
    async [GET_SUBSECTION_CAPACITY_HISTORY] ({ state, commit, dispatch }) {
      const limit = state.pagination?.perPage || 10;
      const offset = limit * ((state.pagination?.page || 1) - 1);
      const userId = state.modal.userId;
      const offerId = state.modal.offerId;
  
      dispatch(UPDATE_MODAL, { isActive: true });
      
      const { data: { userOfferCapacityHistory } } = await GqlOffersService.getUserOfferCapacityHistory(
        limit,
        offset,
        userId,
        offerId
      );
  
      commit(SET_CAPACITY_HISTORY, userOfferCapacityHistory);
    },
  
    [UPDATE_MODAL] ({ commit }, modal) {
      commit(UPDATE_MODAL, modal);
    },
  
    [UPDATE_PAGINATION] ({ commit }, pagination) {
      commit(UPDATE_PAGINATION, pagination);
    }
  }
  
  private readonly initState: () => CapacityHistoryState;
  
  constructor () {
    this.namespaced = true;
    this.initState = (): CapacityHistoryState => {
      return {
        data: {},
        pagination: {
          perPage: 10,
          page: 1
        },
        modal: {
          isActive: false,
          userId: null,
          offerId: null
        }
      };
    };
  
    this.mutations[SET_EMPTY] = (state) => Object.assign(state, this.initState());
    
    this.state = this.initState();
    
    return this;
  }
}

export const capacityHistory = new CapacityHistory();