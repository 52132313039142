<template>
    <span class="nowrap has-text-left user-wrapper">
        <MiniCardTooltip
            v-if="activeDropdown && adminId"
            :is-loading="isLoading"
            :current-item="item"
            @onShow="onShow">
            <template #image>
                <!-- Не будет выводиться если нет id -->
                <img
                    alt="avatar"
                    :class="{ 'avatar-external': avatar }"
                    :src="hasAvatar">
            </template>

            <template #content>
                <div class="card-profile">
                    <div class="card-profile_header">
                        <div class="is-align-items-center">
                            <img
                                :src="logo"
                                alt=""
                                class="card-profile_header_avatar">
                            <span class="card-profile_header_name">
                                {{ item.login }}
                            </span>
                            <span class="card-profile_header_name pl-1">
                                [{{ item.intId }}]
                            </span>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="card-profile_body">
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.adminCard.miniCard.country') }:` }}
                            </span>
                            <Countries
                                :items="item.countries"
                                compact
                                :interactive="false"
                                tooltip-position="is-top">
                            </Countries>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.adminCard.miniCard.role') }:` }}
                            </span>
                            <CompactList
                                :items="item.roles"
                                :interactive="false">
                                <template #listItem="{ item }">
                                    {{ item.name }}
                                </template>
                                <template #tooltipItem="{ item }">
                                    {{ item.name }}
                                </template>
                                <template #singleItem="{ item }">
                                    {{ item.name }}
                                </template>
                            </CompactList>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.webmasterCard.miniCard.telegram') }:` }}
                            </span>
                            <ContactOutput
                                :value="item.contact.telegram"
                                type="telegram">
                            </ContactOutput>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name is-align-items-flex-start">
                                {{ `${ $t('common.entity.adminCard.miniCard.registeredAt') }:` }}
                            </span>
                            <div class="flex is-flex-direction-column">
                                {{ momentWithEmpty(item.activity.registeredAt, "DD.MM.YYYY") }}
                                <span
                                    class="has-text-grey has-text-centered"
                                    style="font-size: 12px">
                                    {{ momentFrom(item.activity.registeredAt) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </MiniCardTooltip>

        <CanCopy
            :value="canCopy"
            :prevent-copy="!copy">
            <UserOutput
                :user="admin"
                :login="login">
            </UserOutput>
        </CanCopy>
    </span>
</template>

<script>
  import MiniCardTooltip from "@/components/Common/Tooltip/MiniCardTooltip.vue";
  import CompactList from "@/components/Common/Lists/CompactList.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import Countries from "@/components/Common/Lists/Countries.vue";
  import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
  import { GET_ADMIN_BY_ID } from "@core/store/action-constants";
  import { momentWithEmpty } from "@core/flowMethods";
  import { formatEmptyString, momentFrom } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import ContactOutput from "@/components/Common/Output/ContactOutput.vue";

  export default {
    name: "AdminOutput",
    components: {
      ContactOutput,
      MiniCardTooltip,
      CompactList,
      UserOutput,
      Countries,
      CanCopy
    },
    props: {
      position: {
        type: String,
        default: "is-bottom"
      },
      admin: {
        type: Object,
        default: null
      },
      activeDropdown: {
        type: Boolean,
        default: true
      },
      copy: {
        type: Boolean,
        default: true
      },
      login: {
        type: Boolean,
        default: false
      },
      avatar: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("admin/adminDataById", {
        data: ({ data }) => data
      }),

      item () {
        const { adminId, data } = this;
        return data?.[adminId];
      },

      logo () {
        return this.item?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      isLoading () {
        return this.$wait(`admin/adminDataById/${ GET_ADMIN_BY_ID }`);
      },

      adminId () {
        return this.admin?.id;
      },

      avatarExternal () {
        return this.admin?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      hasAvatar () {
        return this.avatar ? this.avatarExternal : require("@/assets/Admin.svg");
      },

      canCopy () {
        const admin = this.admin;
        if (admin && admin.login && admin.intId) {
          return `${ admin.login } ${ admin.intId }`;
        } return null;
      }
    },

    methods: {
      formatEmptyString,
      momentWithEmpty,
      momentFrom,

      ...mapActions("admin/adminDataById", {
        GET_ADMIN_BY_ID
      }),

      onShow () {
        const { adminId, activeDropdown, item, isLoading } = this;

        if (!item && adminId && !isLoading && activeDropdown) {
          this.GET_ADMIN_BY_ID(adminId);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/user-mini-card";

    img {
        margin-bottom: -4px !important;
    }
</style>
