import { VerticalsService } from "@core/services/common/VerticalsService";
import { VerticalsState } from "@core/store/types/common/varticals";
import { SET_EMPTY, SET_VERTICALS } from "@core/store/mutation-constants";
import { GET_VERTICALS, UPDATE_CURRENT_VERTICAL } from "@core/store/action-constants";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { hasPermissions } from "@core/mixins/permissions";
import { RootState } from "@core/store/root-state";

const initialState = (): VerticalsState => {
  return {
    verticals: [],
    currentVertical: null
  };
};

const state: () => VerticalsState = initialState;

const getters: GetterTree<VerticalsState, RootState> = {
  gamblingOnly: (state, getters, rootState, rootGetters) => hasPermissions("VERTICALS.GAMBLING", rootGetters.permissions),
  allVerticals: (state, getters, rootState, rootGetters) => hasPermissions("VERTICALS.ALL", rootGetters.permissions),
  nutraOnly: (state, getters, rootState, rootGetters) => hasPermissions("VERTICALS.NUTRA", rootGetters.permissions),
  isVisibleByAllVerticals: (state, getters) => getters.allVerticals || getters.nutraOnly || getters.gamblingOnly,
  nutra: (state, getters) => getters.allVerticals || getters.nutraOnly
};

const mutations: MutationTree<VerticalsState> = {
  [SET_VERTICALS] (state, payload) {
    state.verticals = payload;
  },

  [UPDATE_CURRENT_VERTICAL] (state, payload) {
    state.currentVertical = payload;
  },

  [SET_EMPTY] (state, target?: string) {
    if (target) {
      // @ts-ignore
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  }
};

const actions: ActionTree<VerticalsState, RootState> = {
  async [GET_VERTICALS] ({ commit }) {
    const verticals = await VerticalsService.getVerticals();
    commit(SET_VERTICALS, verticals);
  },

  [UPDATE_CURRENT_VERTICAL] ({ commit }, payload) {
    commit(UPDATE_CURRENT_VERTICAL, payload.currentVertical);
  }
};

export const verticals: Module<VerticalsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
