<template>
    <NewPassword v-if="isNewPassword"></NewPassword>
    <ResetPassword v-else></ResetPassword>
</template>

<script>
  import ResetPassword from "@/components/Common/Auth/ResetPassword/ResetPassword";
  import NewPassword from "@/components/Common/Auth/ResetPassword/NewPassword";
  
  export default {
    name: "ResetPasswordWrapper",
    components: {
      ResetPassword,
      NewPassword
    },
    computed: {
      isNewPassword () {
        // В письме, которое приходит на почту лежит ссылка, которая содержит в себе токен, который мы тут используем
        // для определения этапа восстановления пароля. Костыль? Ну... Да.
        return this.$route.query.token;
      }
    }
  };
</script>

<style scoped>
</style>
